import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Saving } from '../../../domain/entities/Saving'
import { SavingFirebaseImplementation } from '../../../data/repositories/savings/savings.firebase'
import {
    TeamMember,
    teamMemberInitialState,
} from '../../../domain/entities/Team'
import { SavingService } from '../../../domain/usecases/savings.usecase'

interface AddDataSavingsType extends TeamMember {
    show: boolean
    showUserSavingsBreakdown: boolean
    frequency: string
}
interface InitState {
    showAddModal: boolean
    addData: AddDataSavingsType
    savings: Saving[]
    ready: boolean
}
export const initialSavingState: InitState = {
    showAddModal: false,
    addData: {
        ...teamMemberInitialState,
        memberId: '',
        status: 'pending',
        isRemoved: false,
        dateAccepted: 0,
        show: false,
        showUserSavingsBreakdown: false,
        frequency: '',
    },
    savings: [],
    ready: false,
}

const localImpl = new SavingFirebaseImplementation()

export const addSaving = createAsyncThunk(
    'savings/addSaving',
    async (payload: Omit<Saving, 'id'>) => {
        const savingService = new SavingService(localImpl)
        return await savingService.AddSaving(payload)
    }
)

export const getAllMemberSaving = createAsyncThunk(
    'savings/getAllMemberSaving',
    async (payload: { memberId: string; partnerCode: string }) => {
        const savingService = new SavingService(localImpl)
        return await savingService.GetAllMemberSaving(payload)
    }
)

export const getTeamSavings = createAsyncThunk(
    'savings/getAllMemberSaving',
    async (partnerCode: string) => {
        const savingService = new SavingService(localImpl)
        return await savingService.getTeamSavings(partnerCode)
    }
)

export const getSaving = async (savingId: string) => {
    const savingService = new SavingService(localImpl)
    return await savingService.GetSaving(savingId)
}

export const deleteSaving = createAsyncThunk(
    'savings/deleteSaving',
    async (id: string) => {
        const savingService = new SavingService(localImpl)
        return await savingService.DeleteSaving(id)
    }
)

export const updateSaving = createAsyncThunk(
    'savings/updateSaving',
    async (updatePayload: {
        payload: Saving & {
            id: string
        }
    }) => {
        const savingService = new SavingService(localImpl)
        return await savingService.UpdateSaving(updatePayload.payload)
    }
)

export const savingSlice = createSlice({
    name: 'savings',
    initialState: initialSavingState,
    reducers: {
        updateSavingsState: (
            state: { [key: string]: any },
            action: PayloadAction<{
                name: string
                value: any
            }>
        ) => {
            state[action.payload.name] = action.payload.value
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllMemberSaving.fulfilled, (state, action) => {
            state.savings = action.payload
            state.ready = true
        })
        builder.addCase(getAllMemberSaving.pending, (state) => {
            state.ready = false
        })
        builder.addCase(addSaving.fulfilled, (state, action) => {
            if (!action.payload) return
            state.savings = [...state.savings, action.payload]
        })
        builder.addCase(deleteSaving.fulfilled, (state, action) => {
            const idx = state.savings.findIndex(
                (saving) => saving.id === action.payload
            )
            if (idx >= 0) {
                state.savings.splice(idx, 1)
            }
        })
        builder.addCase(updateSaving.fulfilled, (state, action) => {
            const idx = state.savings.findIndex(
                (saving) => saving.id === action?.payload?.id
            )
            if (idx >= 0 && action.payload) {
                state.savings[idx] = action.payload
            }
        })
    },
})

export const { updateSavingsState } = savingSlice.actions
