import React from 'react'
import { GaugeChart } from 'bizcharts'
import { Typography } from 'antd'

interface DashboardMetricProps {
    value: number
    title: string
    description: string
    suffix?: string
    prefix?: string
}
function DashboardMetric({
    value,
    title,
    description,
    suffix = '',
    prefix = '',
}: DashboardMetricProps) {
    return (
        <GaugeChart
            axis={{
                label: {
                    formatter: (text, item, index) => {
                        return parseFloat(text) * 100
                    },
                },
            }}
            title={
                <Typography.Paragraph
                    style={{ textAlign: 'center', marginBottom: 0 }}
                >
                    {title}
                </Typography.Paragraph>
            }
            description={
                <Typography.Paragraph
                    style={{ textAlign: 'center', marginBottom: 0 }}
                >
                    {description}
                </Typography.Paragraph>
            }
            width={300}
            height={250}
            value={value / 100}
            // @ts-ignore
            // min={0}
            // max={100}
            isMaterial
            type="meter"
            range={{
                ticks: [0, 0.65, 0.9, 1],
                color: ['#fab472', '#f0ec14', '#43E089'],
            }}
            statistic={{
                title: {
                    content: `${prefix}${
                        Math.round((value + Number.EPSILON) * 10) / 10
                    }${suffix}`,
                    style: {
                        fontSize: 24,
                    },
                },
                // content: {
                //     content: '内容'
                // },
            }}
            percent={Math.round((value + Number.EPSILON) * 10) / 10}
        />
    )
}

export default DashboardMetric
