import { EditFilled, EditOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Button, List, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { db } from '@/app/boot'
import { PERSONNEL_ATTENDANCE_REPOSITORY } from '@/data/repositories/constants'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { useAppSelector } from '@/app/store'
import { shallowEqual } from 'react-redux'
import {
    HiDocument,
    HiDocumentArrowDown,
    HiOutlineDocumentArrowDown,
} from 'react-icons/hi2'
import { ProForm, ProFormText } from '@ant-design/pro-components'

const BusDailyCollectionMobileView = () => {
    const [schedules, setSchedules] = useState<any[]>([])

    const { email, activeTeam } = useAppSelector(
        (state) => ({
            email: state.auth.user.email,
            activeTeam: state.auth.user.activeTeam,
            drivers: state.team.drivers,
            assistants: state.team.assistants,
            vehiclesEntities: state.vehicle.vehicles,
        }),
        shallowEqual
    )
    useEffect(() => {
        const mainSched = async () => {
            const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)
            // eslint-disable-next-line no-console
            // console.log(currentVehicleId);

            const q = query(
                ref,
                where('status', '==', 'pending'),
                where('partnerCode', '==', activeTeam)
            )

            const docs = await getDocs(q)
            const schedulesData: any = []

            docs.forEach((docData) => {
                schedulesData.push({
                    ...docData.data(),
                    id: docData.id,
                })
            })
            setSchedules(schedulesData)
        }
        mainSched()
    }, [])

    const [loading, setLoading] = useState(false)
    const [form] = ProForm.useForm()
    const [modalSchedule, setModalSchedule] = useState(false)
    const [currentSchedule, setCurrentSchedule] = useState<any | undefined>(
        undefined
    )
    const openModal = (item: any) => {
        setModalSchedule(true)
        setCurrentSchedule(item)
    }
    const closeModal = () => {
        setModalSchedule(false)
    }

    const onConfirmCollection = async () => {}
    console.log(currentSchedule)
    return (
        <div>
            <Modal
                confirmLoading={loading}
                open={modalSchedule}
                onCancel={closeModal}
                footer={false}
            >
                <ProForm form={form} onFinish={onConfirmCollection}>
                    <ProFormText
                        label="Total Gross"
                        name="totalGross"
                        rules={[
                            {
                                required: true,
                                type: 'number',
                            },
                        ]}
                    />

                    <ProFormText
                        label="Short"
                        name="totalShort"
                        rules={[
                            {
                                required: true,
                                type: 'number',
                            },
                        ]}
                    />
                </ProForm>
            </Modal>

            <List
                dataSource={schedules}
                bordered
                renderItem={(item) => {
                    return (
                        <List.Item
                            key={item.id}
                            actions={[
                                <Button
                                    type="primary"
                                    size="large"
                                    key={'submit'}
                                    onClick={() => openModal(item)}
                                    icon={<PlusCircleFilled />}
                                />,
                            ]}
                        >
                            <List.Item.Meta
                                title={
                                    <div>
                                        {item.unit} -{' '}
                                        {moment
                                            .unix(item.creation)
                                            .format('MMM DD, YYYY')}
                                    </div>
                                }
                                description={
                                    <div>
                                        {item.driverName} - {item.assistantName}
                                    </div>
                                }
                            />
                        </List.Item>
                    )
                }}
            />
        </div>
    )
}

export default BusDailyCollectionMobileView
