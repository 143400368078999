import { createTeam } from '../../../redux/team/team.action'
import { Button, Col, Input, message, Row, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { updateTeamState } from '../../../redux/team/team.slice'
import { useAppDispatch, useAppSelector } from '../../../store'
// import { updateUser } from '../../../../app/redux/auth/auth.slice'
import { rainbowColors } from '../../Reports/MembersReceivableReport/helper'
// import { useNavigate } from 'react-router-dom'
// import moment from 'moment'
import {
    Team,
    teamInitialState,
    // teamMemberInitialState,
} from '../../../../domain/entities/Team'

interface CreateTeamModalProps {
    onBoard?: boolean
}
const CreateTeamModal = ({ onBoard }: CreateTeamModalProps) => {
    const { showCreateModal, user } = useAppSelector(
        (state) => ({
            showCreateModal: state.team.showCreateModal,
            user: state.auth.user,
        }),
        shallowEqual
    )
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [isValid, setIsValid] = useState(false)
    const dispatch = useAppDispatch()
    // const navigate = useNavigate()
    // const params = useParams()
    const closeTeamModal = (e?: any) => {
        if (onBoard && !e) return
        dispatch(
            updateTeamState({
                showCreateModal: false,
            })
        )
        setName('')
        setLoading(false)
    }

    const handleNameChange = (val: string) => {
        if (!val) {
            setName('')
            return
        }
        if (val.length > 20) {
            return
        }
        const newStr = val.match(/^[a-zA-Z ]*$/)?.join('')
        setName(newStr || name || '')
    }
    const handleCreateTeam = async () => {
        setLoading(true)
        const sluggifiedName = name.toLowerCase().split(' ').join('-')
        const randomIdx = Math.floor(Math.random() * rainbowColors.length)
        const teamPayload: Team = {
            backgroundUrl: '',
            folders: [],
            files: {
                parentFolder: [],
            },
            address: '',
            contactInfo: '',
            dividendFrequency: 'annual',
            dividendPayoutRatio: 0,
            email: '',
            phone: '',
            headline: '',
            logoUrl: '',
            retainedEarnings: 0,
            savingsAccount: [],
            coreValues: [],
            socialGoals: [],
            mission: '',
            vision: '',
            registrationNumber: '',
            registrationDate: 0,
            dividendPayoutDate: 0,
            loanInterestType: 'reducing-balance',
            totalPersonnel: 0,
            ...teamInitialState,
            name,
            partnerCode: sluggifiedName,
            owner: user.email,
            ownerUid: user.id as string,
            avatar: rainbowColors[randomIdx],
            cardPercentage: 0,
            totalMembers: 0,
            busPersonnelSalaryPercentage: false,
        }
        const resp = await createTeam(teamPayload)
        if (!resp) {
            message.error('Team already exists. Use another name', 10)
            setLoading(false)
            return
        }
        // const myTeams = user.myTeams || []

        // await addTeamMember(
        //     {
        //         ...teamMemberInitialState,
        //         // status: 'accepted',
        //         role: 'regular_member',
        //         // dateAccepted: moment().unix(),
        //         // memberId: params?.userId || (user.id as string),
        //         isRemoved: false,
        //         creation: moment().unix(),
        //         modified: moment().unix(),
        //         modifiedBy: user.email,
        //         firstName: user.firstName,
        //         lastName: user.lastName,
        //         name: user.displayName,
        //         tags: [],
        //         accountNumber: `${moment().format('YYYY')}C00001`,
        //         partnerCode: teamPayload.partnerCode,
        //     },
        //     teamPayload.partnerCode
        // )

        // await dispatch(
        //     updateUser({
        //         myTeams: [...myTeams, teamPayload.partnerCode],
        //         id: params?.userId || (user.id as string),
        //         activeTeam: teamPayload.partnerCode,
        //     })
        // )

        // dispatch(updateUs)
        message.success('Successfully created team. Switching...')
        // setLoading(false)
        // navigate(
        //     `/teams/${teamPayload.partnerCode}?onBoard=${onBoard || false}`,
        //     {
        //         replace: true,
        //     }
        // )
        dispatch(
            updateTeamState({
                showCreateModal: false,
                selectedTeamId: teamPayload.partnerCode,
                selectedTeamData: teamPayload,
            })
        )
        setName('')
        setLoading(false)
    }
    useEffect(() => {
        if (name && name.length >= 3) setIsValid(true)
        if (!name) setIsValid(false)
    }, [name])

    return showCreateModal ? (
        <div className="ant-modal-root">
            <div className="ant-modal-mask"></div>
            <div
                className="ant-modal-wrap ant-modal-centered"
                onClick={() => closeTeamModal()}
            >
                <div
                    className="ant-modal"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: 10,
                        width: 500,
                        padding: '20px 0 24px',
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div
                        tabIndex={0}
                        aria-hidden="true"
                        style={{
                            width: 0,
                            height: 0,
                            overflow: 'hidden',
                            outline: 'none',
                        }}
                    />
                    <div
                        className="ant-modal-content"
                        style={{ boxShadow: 'none', padding: 0 }}
                    >
                        <div style={{ padding: '0 24px' }}>
                            <Typography.Title level={2}>
                                Create Team
                                <p
                                    style={{
                                        marginBottom: 0,
                                        fontSize: '0.9rem',
                                        lineHeight: '1.25rem',
                                        fontWeight: 400,
                                    }}
                                >
                                    Collaborate with your members and take
                                    advantage of online cooperative.
                                </p>
                            </Typography.Title>
                        </div>

                        <div
                            style={{
                                backgroundColor: '#fafafa',
                                padding: '12px 24px',
                                zIndex: 9999,
                            }}
                        >
                            <Typography.Title level={4} style={{}}>
                                To get started, name your new team:
                            </Typography.Title>
                            <Typography.Paragraph
                                style={{
                                    fontSize: 12,
                                    marginBottom: 10,
                                    color: '#666',
                                }}
                            >
                                TEAM NAME{' '}
                                <Typography.Text type="secondary">
                                    (maximum of 20 letters)
                                </Typography.Text>
                            </Typography.Paragraph>
                            <Input
                                allowClear
                                size={'large'}
                                style={{ borderRadius: 5 }}
                                bordered
                                onChange={(e) =>
                                    handleNameChange(e.target.value)
                                }
                                value={name}
                                status={isValid ? '' : 'error'}
                            />
                            <Typography.Paragraph
                                type="secondary"
                                style={{
                                    fontSize: 12,
                                    marginBottom: 10,
                                }}
                            >
                                Team slug:{' '}
                                <Typography.Text>
                                    {name.toLowerCase().split(' ').join('-')}
                                </Typography.Text>
                            </Typography.Paragraph>
                        </div>

                        <div style={{ height: 40 }}>
                            <Row>
                                <Col span={12}>
                                    <Button
                                        loading={loading}
                                        block
                                        style={{
                                            border: 'none',
                                            height: 64,
                                            borderRadius: '0 0 0 10px',
                                        }}
                                        onClick={() => closeTeamModal()}
                                    >
                                        CANCEL
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        loading={loading}
                                        onClick={handleCreateTeam}
                                        disabled={!isValid}
                                        block
                                        style={{
                                            border: 'none',
                                            height: 64,
                                            borderRadius: '0px 0px 10px 0',
                                        }}
                                        type="primary"
                                    >
                                        CREATE
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Modal
                closeIcon={<></>}
                visible={showCreateModal}
                footer={null}
                style={{ padding: 0, backgroundColor: 'transparent' }}
                bodyStyle={{
                    backgroundColor: 'transparent',
                    padding: 0,
                    borderRadius: 24,
                }}
                centered
                title={null}
                onCancel={closeTeamModal}
            >
                <div
                    style={{
                        backgroundColor: '#eaeaea',
                        borderRadius: 24,
                    }}
                >
                    <Typography.Title level={2} style={{ textAlign: 'center' }}>
                        Create Team
                    </Typography.Title>
                    CreateTeamModal
                </div>
            </Modal> */}
        </div>
    ) : null
}

export default CreateTeamModal
