import React, { useState } from 'react'
import { Form, message, Typography } from 'antd'
import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
} from '@ant-design/pro-components'
import { useAppSelector } from '../../../store'
import { shallowEqual } from 'react-redux'
import { Share } from '../../../../domain/entities/Share'
import moment from 'moment'
import { updateDoc, doc } from 'firebase/firestore'
import { SHARES_REPOSITORY } from '../../../../data/repositories/constants'
import { db } from '../../../boot'

interface UpdateShareProp extends Share {
    memberName: string
    frequency: string
}
function UpdateShare({
    data,
    onClose,
    onSuccess,
}: {
    data: UpdateShareProp
    onClose: () => void
    onSuccess?: () => void
}) {
    // const dispatch = useAppDispatch()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(true)
    // const [value, setValue] = useState({
    //
    // })
    const { user, styles } = useAppSelector(
        (state) => ({
            user: state.auth.user,
            styles: state.styles,
        }),
        shallowEqual
    )

    const handleSubmit = async (dt: any) => {
        setLoading(true)
        const payload: any = {
            ...dt,
            amount: dt.amount,
            creation: moment(dt.creation).unix(),
            modified: moment().unix(),
            modifiedBy: user.email,
        }

        await updateDoc(doc(db, SHARES_REPOSITORY, data.id), payload)
        message.success(`Success`)
        onSuccess && onSuccess()
        setLoading(false)
        handleCancel()
    }

    const handleCancel = () => {
        onClose()
        form.resetFields()
    }

    return (
        <ModalForm<Share>
            form={form}
            onFinishFailed={(err) => {
                if (err.errorFields.length) {
                    const firstError = err.errorFields[0]
                    message.destroy()
                    message.error(firstError.errors)
                    form.scrollToField(firstError.name, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }}
            title={
                <>
                    <Typography.Title level={3}>
                        <Typography.Text style={{ fontWeight: 400 }}>
                            Update share:{' '}
                        </Typography.Text>
                        {data.displayName}
                    </Typography.Title>
                    <div>
                        <Typography.Text
                            style={{ fontWeight: 700, fontSize: 18 }}
                        >
                            {data.frequency}
                        </Typography.Text>
                    </div>
                </>
            }
            open
            autoFocusFirstInput
            modalProps={{
                onCancel: handleCancel,
                okText: 'Submit',
                centered: true,
                okButtonProps: {
                    loading,
                },
            }}
            submitTimeout={2000}
            onFinish={handleSubmit}
            width={540}
            initialValues={{
                creation: moment.unix(data.creation),
                amount: data.amount,
                type: data.type,
            }}
            submitter={{
                submitButtonProps: {
                    style: {
                        backgroundColor: styles.primary,
                        color: 'white',
                    },
                },
            }}
            // @ts-ignore
        >
            <ProForm.Group>
                <ProFormDigit
                    rules={[
                        {
                            required: true,
                            type: 'number',
                            message: 'This field is required',
                        },
                    ]}
                    width="sm"
                    name="amount"
                    label="Amount"
                    placeholder="360.50"
                />
                <ProFormDatePicker
                    name={'creation'}
                    label={'Date added'}
                    width={'sm'}
                    fieldProps={{
                        format: 'MMM D, YYYY',
                    }}
                    rules={[
                        {
                            required: true,
                            type: 'date',
                            message: 'This field is required',
                        },
                    ]}
                />
            </ProForm.Group>

            {/* <ProFormSelect
                disabled
                rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                ]}
                options={[
                    {
                        value: 'common',
                        label: 'Common Share',
                    },
                    {
                        value: 'preferred',
                        label: 'Preferred Share',
                    },
                ]}
                name="type"
                label={'Type'}
            /> */}
        </ModalForm>
    )
}

export default UpdateShare
