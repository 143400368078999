import { SystemRepository } from '../../../domain/repositories/system.repository'
import { MemberLocalImplementation } from './member.localImp'
import moment from 'moment'
import { Member } from '../../../domain/entities/Member'
import { initialSystemState } from '../../../app/redux/system.slice'

export class SystemLocalImplementation
    extends MemberLocalImplementation
    implements SystemRepository
{
    systemInit() {
        const key = 'system-settings'
        const sysSettings = this.getStorage(key)
        if (!sysSettings) {
            this.setStorage(key, initialSystemState)
        } else {
            this.setStorage(key, {
                ...sysSettings,
                currentYear: moment().format('YYYY'),
            })
        }

        const shareKey = 'shares'
        const shares = this.getStorage(shareKey)
        if (!shares) {
            this.setStorage(shareKey, [])
        }

        const loanKey = 'loans'
        const loans = this.getStorage(loanKey)
        if (!loans) {
            this.setStorage(loanKey, [])
        }

        const member = this.GetMember('default')
        if (!member) {
            const memberData: Member = {
                name: 'default',
                firstName: '',
                lastName: '',
                creation: moment().unix(),
                modified: moment().unix(),
                modifiedBy: 'system',
                tags: [],
                id: 'default',
                memberPreferredShares: 0,
                memberShares: 0,
                totalShare: 0,
                totalLoan: 0,
                totalIncomeContribution: 0,
                totalRemainingBalance: 0,
                accountNumber: '',
                // weightedMemberShare: {
                //     [moment().format('YYYY')]: {
                //         [moment().format('MMMM')]: 0,
                //         Annual: 0,
                //     },
                // },
                // memberShare: {
                //     [moment().format('YYYY')]: {
                //         [moment().format('MMMM')]: 0,
                //         Annual: 0,
                //     },
                // },
                // memberLoan: {
                //     [moment().format('YYYY')]: {
                //         [moment().format('MMMM')]: 0,
                //         Annual: 0,
                //     },
                // },
                // incomeContribution: {
                //     [moment().format('YYYY')]: {
                //         [moment().format('MMMM')]: 0,
                //         Annual: 0,
                //     },
                // },
                // memberLoanRemainingBalance: {
                //     [moment().format('YYYY')]: {
                //         [moment().format('MMMM')]: 0,
                //         Annual: 0,
                //     },
                // },
                isRemoved: false,
                dateOfBirth: 0,
                gender: 'male',
                phone: '',
                presentAddress: '',
                overallUnpaidInterest: 0,
                overallLoan: 0,
                currentSavingsBalance: 0,
                overallWithdrawals: 0,
                overallDeposits: 0,
                monthlyShareCapitalDeductions: 0,
                sickLeaveBalance: 5,
                vacationLeaveBalance: 5,
                paternityLeaveBalance: 7,
                maternityLeaveBalance: 105,
                role: 'regular_member',
                partnerCode: '',
                displayName: '',
            }
            const resp = this.AddMember(memberData)
            if (resp) {
                return resp
            }
        }
    }
    setStorage(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data))
    }
    getStorage(key: string): undefined | any {
        const item = localStorage.getItem(key)
        if (item) {
            return JSON.parse(item)
        } else {
            return undefined
        }
    }
    Export(): any {
        return localStorage
    }
    Import(payload: any): any {
        const parsed = JSON.parse(payload)
        // const localStorageKeys = Object.keys(localStorage)
        // if (localStorageKeys.length) return 'data-exists'
        Object.keys(parsed).forEach((key: string) => {
            localStorage.setItem(key, parsed[key])
        })
    }

    getSystemConfig(): any {
        const keys = 'system-settings'
        const sysData = this.getStorage(keys)
        if (sysData) {
            return sysData
        }
        return false
    }

    updateSystemConfig(payload: any): any {
        const keys = 'system-settings'
        let sysData = this.getStorage(keys)
        if (sysData) {
            sysData = {
                ...sysData,
                ...payload,
            }
            this.setStorage(keys, sysData)
            return payload
        }
        return undefined
    }
}
