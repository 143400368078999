import type { ProColumns } from '@ant-design/pro-components'
import { EditableProTable } from '@ant-design/pro-components'
import { Col, Row, Statistic, Table, Typography } from 'antd'
import { ExpandableConfig } from 'antd/lib/table/interface'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
// import { shallowEqual } from 'react-redux';
// import { useAppSelector } from '../store';

type DataSourceType = {
    id: React.Key
    title?: string
    decs?: string
    state?: string
    created_at?: number
    children?: DataSourceType[]
}

interface EditableTableProps {
    data: any[]
    columns: ProColumns[]
    onChange: (data: any) => void
    title?: any
    summary?: any
    expandable?: ExpandableConfig<DataSourceType>
    withActions?: boolean
    recordCreatorProps?: boolean
}

const EditableTable = ({
    data,
    columns,
    onChange,
    title,
    summary,
    expandable,
    withActions,
    recordCreatorProps,
}: EditableTableProps) => {
    // const { styles } = useAppSelector(
    //     (state) => ({
    //         styles: state.styles,

    //     }),
    //     shallowEqual
    // )
    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([])
    const [dataSource, setDataSource] = useState<DataSourceType[]>(data)

    // eslint-disable-next-line no-unused-vars
    const columns2: ProColumns<DataSourceType>[] = [
        {
            title: '活动名称',
            dataIndex: 'title',
            width: '30%',
            formItemProps: {
                rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: '此项是必填项',
                    },
                    {
                        message: '必须包含数字',
                        pattern: /[0-9]/,
                    },
                    {
                        max: 16,
                        whitespace: true,
                        message: '最长为 16 位',
                    },
                    {
                        min: 6,
                        whitespace: true,
                        message: '最小为 6 位',
                    },
                ],
            },
        },
        {
            title: '状态',
            key: 'state',
            dataIndex: 'state',
            valueType: 'select',
            valueEnum: {
                all: { text: '全部', status: 'Default' },
                open: {
                    text: '未解决',
                    status: 'Error',
                },
                closed: {
                    text: '已解决',
                    status: 'Success',
                },
            },
        },
        {
            title: '描述',
            dataIndex: 'decs',
        },
        {
            title: '操作',
            valueType: 'option',
            width: 250,
            render: () => {
                return null
            },
        },
    ]

    const handleChange = (data: any) => {
        // eslint-disable-next-line no-console
        // console.log(data)

        setDataSource(data)
        onChange(data)
    }

    useEffect(() => {
        setDataSource(data.sort((a, b) => a.name.localeCompare(b.name)))
    }, [data])
    return (
        <EditableProTable<DataSourceType>
            summary={summary}
            expandable={{
                expandRowByClick: editableKeys.length ? false : true,
                showExpandColumn: false,

                expandedRowClassName: () => 'expanded-row',
                expandedRowRender: (record: any) => {
                    const isBusPersonnel = record.isBusPersonnel
                    const busColumns = [
                        {
                            title: 'No.',
                            dataIndex: 'rowNumber',
                            key: 'rowNumber',
                            render: (text: any, record: any, index: any) =>
                                index + 1,
                            // The render function displays the row number (index + 1) for each row
                        },
                        {
                            title: 'Date',
                            dataIndex: 'date',
                            key: 'date',
                            render(value: any, record: any) {
                                // const [hours, extraHours] = calculateTime(record.shiftStartTime as number, record.shiftEndTime as number)
                                return (
                                    <div>
                                        <span>
                                            {moment(
                                                value,
                                                'MMM DD, YYYY'
                                            ).format('ddd • MM/DD • ')}
                                            {moment
                                                .unix(record.timeIn)
                                                .format('h:mmA-')}
                                            {moment
                                                .unix(record.timeOut)
                                                .format('h:mmA')}
                                        </span>
                                    </div>
                                )
                            },
                        },
                        // {
                        //     title: 'Name',
                        //     dataIndex: 'name',
                        //     key: 'name',
                        // },
                        {
                            title: 'Partner',
                            dataIndex: 'partnerName',
                            key: 'partnerName',
                        },
                        {
                            title: 'Title',
                            dataIndex: 'title',
                            key: 'title',
                        },
                        {
                            title: 'Incentives',
                            dataIndex: 'incentives',
                            key: 'incentives',
                            render(value: any) {
                                return (
                                    <Statistic
                                        prefix={'₱'}
                                        value={value}
                                        precision={2}
                                        prefixCls="₱"
                                    />
                                )
                            },
                        },
                        {
                            title: 'Overtime Pay/Hours',
                            dataIndex: 'overtimePay',
                            key: 'overtimePay',
                            render(value: any, record: any) {
                                return (
                                    <Row>
                                        <Col span={24}>
                                            <Statistic
                                                prefix={'₱'}
                                                value={value}
                                                valueRender={(node) => {
                                                    return (
                                                        <Typography.Text>
                                                            {node} /{' '}
                                                            {
                                                                record.overtimeHours
                                                            }
                                                            h
                                                        </Typography.Text>
                                                    )
                                                }}
                                                precision={2}
                                                prefixCls="₱"
                                            />
                                        </Col>
                                    </Row>
                                )
                            },
                        },
                        {
                            title: 'Fare Revenue',
                            dataIndex: 'revenue',
                            key: 'revenue',
                            render(value: any) {
                                return (
                                    <Statistic
                                        prefix={'₱'}
                                        value={value}
                                        precision={2}
                                        prefixCls="₱"
                                    />
                                )
                            },
                        },
                        {
                            title: 'Cash Remittance',
                            dataIndex: 'cashReceived',
                            key: 'cashReceived',
                            render(value: any) {
                                return (
                                    <Statistic
                                        prefix={'₱'}
                                        value={value}
                                        precision={2}
                                        prefixCls="₱"
                                    />
                                )
                            },
                        },
                    ]
                    const employeeColumns = [
                        {
                            title: 'No.',
                            dataIndex: 'rowNumber',
                            key: 'rowNumber',
                            render: (text: any, record: any, index: any) =>
                                index + 1,
                            // The render function displays the row number (index + 1) for each row
                        },
                        {
                            title: 'Date',
                            dataIndex: 'date',
                            key: 'date',
                            render(value: any, record: any) {
                                // const [hours, extraHours] = calculateTime(record.shiftStartTime as number, record.shiftEndTime as number)
                                return (
                                    <div>
                                        <span>
                                            {moment(
                                                value,
                                                'MMM DD, YYYY'
                                            ).format('ddd • MM/DD • ')}
                                            {moment
                                                .unix(record.timeIn)
                                                .format('h:mmA-')}
                                            {moment
                                                .unix(record.timeOut)
                                                .format('h:mmA')}
                                        </span>
                                    </div>
                                )
                            },
                        },
                        {
                            title: 'Hours Rendered',
                            dataIndex: 'hoursRendered',
                            key: 'hoursRendered',
                        },
                        {
                            title: 'Minutes Late',
                            dataIndex: 'minutesLate',
                            key: 'minutesLate',
                        },
                        {
                            title: 'Incentives',
                            dataIndex: 'incentives',
                            key: 'incentives',
                            render(value: any) {
                                return (
                                    <Statistic
                                        prefix={'₱'}
                                        value={value}
                                        precision={2}
                                        prefixCls="₱"
                                    />
                                )
                            },
                        },
                        {
                            title: 'Overtime Pay/Hours',
                            dataIndex: 'overtimePay',
                            key: 'overtimePay',

                            render(value: any, record: any) {
                                return (
                                    <Row>
                                        <Col span={24}>
                                            <Statistic
                                                prefix={'₱'}
                                                value={value}
                                                valueRender={(node) => {
                                                    return (
                                                        <Typography.Text>
                                                            {node} /{' '}
                                                            {
                                                                record.overtimeHours
                                                            }
                                                            h
                                                        </Typography.Text>
                                                    )
                                                }}
                                                precision={2}
                                                prefixCls="₱"
                                            />
                                        </Col>
                                    </Row>
                                )
                            },
                        },
                    ]

                    const columns = isBusPersonnel
                        ? busColumns
                        : employeeColumns
                    return (
                        <div>
                            <Table
                                bordered
                                pagination={{
                                    pageSize: 100,
                                }}
                                rowClassName={'expanded-row'}
                                dataSource={record.data}
                                columns={columns}
                            />
                        </div>
                    )
                },
                expandIcon: () => null,
                // rowExpandable: (record: any) => {
                //     // eslint-disable-next-line no-console
                //     console.log(record);

                //     return record.name !== 'Not Expandable'
                // },
            }}
            columns={[
                ...columns,
                {
                    title: 'Actions',
                    valueType: 'option',
                    hideInTable: withActions ? false : true,
                    render: (text, record, _, action) => [
                        <Typography.Link
                            key="editable"
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                action?.startEditable?.(record.id)
                            }}
                        >
                            Edit
                        </Typography.Link>,
                        //   <Typography.Link
                        //     key="delete"
                        //     type='danger'
                        //     onClick={() => {
                        //       setDataSource(dataSource.filter((item) => item.id !== record.id));
                        //     }}
                        //   >
                        //     Delete
                        //   </Typography.Link>,
                    ],
                },
            ]}
            rowKey="id"
            bordered
            headerTitle={title}
            scroll={{
                x: 2000,
                y: 600,
            }}
            // rowSelection={{
            //     onChange(selectedRowKeys, rows, info) {
            //         // eslint-disable-next-line no-console
            //         // console.log(selectedRowKeys)
            //         // setSelectedRows(rows)
            //         setEditableRowKeys(selectedRowKeys)
            //     },
            //     selectedRowKeys: editableKeys,
            // }}
            // onRow={(row: any) => {
            //     return {
            //         onDoubleClick: (event) => {
            //             const newKeys = [...editableKeys]
            //             if (newKeys.includes(row.id)) {
            //                 let idx = newKeys.findIndex(
            //                     (id) => id === row.id
            //                 )

            //                 // const newRows = [...selectedRows]
            //                 // let rowIdx = newRows.findIndex(
            //                 //     (rw) => rw.id === row.id
            //                 // )
            //                 newKeys.splice(idx, 1)
            //                 // newRows.splice(rowIdx, 1)
            //                 setEditableRowKeys(newKeys)
            //                 // setSelectedRows(newRows)
            //                 return
            //             } else {
            //                 setEditableRowKeys([
            //                     ...editableKeys,
            //                     row.id,
            //                 ])
            //                 // setSelectedRows([...selectedRows, row])
            //             }
            //         }, // click row
            //     }
            // }}
            value={dataSource}
            onValuesChange={handleChange}
            recordCreatorProps={
                recordCreatorProps === undefined
                    ? false
                    : {
                          newRecordType: 'dataSource',
                          record: () => ({
                              id: Date.now(),
                          }),
                      }
            }
            // style={{
            //     width: 1200
            // }}
            rowClassName={'hoverRow'}
            // toolBarRender={() => {
            //     if(!editableKeys.length) return []
            //     return [
            //         <Button
            //         style={{
            //             backgroundColor: styles.primary,
            //             color: "white"
            //         }}
            //         key="save"
            //         shape={"round"}
            //         size='large'
            //         onClick={() => {
            //             // eslint-disable-next-line no-console
            //             setEditableRowKeys([])
            //         }}
            //         >
            //         Save
            //         </Button>,
            //     ];
            // }}

            editable={{
                type: 'multiple',
                editableKeys,
                actionRender: (row, config, dom) => [dom.save, dom.cancel],
                onValuesChange: (record, recordList) => {
                    // eslint-disable-next-line no-console
                    // console.log(record, recordList)

                    setDataSource(recordList)
                },
                onChange: setEditableRowKeys,
            }}
        />
    )
}

export default EditableTable
