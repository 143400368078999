import React, { useEffect, useState } from 'react'
import { memberLoansColumn } from '../../../Member/loans/utils'
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Tooltip,
    Typography,
} from 'antd'
import {
    CaretDownFilled,
    DeleteOutlined,
    EditOutlined,
    HistoryOutlined,
    PlusOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import { updateMemberState } from '../../../../redux/members/members.slice'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { Loan } from '../../../../../domain/entities/Loan'
import {
    deleteLoan,
    updateLoanState,
} from '../../../../redux/loans/loans.slice'
import UpdateLoan from '../../../Member/loans/components/UpdateLoan'
import PayLoan from '../../../Member/loans/components/PayLoan'
import { shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Table from '../../../../components/Table'
// import { getSystemConfig } from '../../../../redux/system.slice'
import SelectMember from './SelectMember'
import { updateTeamMember } from '../../../../redux/team/team.action'
import PreviewLoanAmortizationSchedule from '../../../Member/loans/PreviewLoanAmortizationSchedule'
import { deleteDoc, doc } from 'firebase/firestore'
import { LOANS_REPOSITORY } from '../../../../../data/repositories/constants'
import { db } from '../../../../boot'
// import { TeamMember } from '../../../../../domain/entities/Team'
// import { updateTeamMember } from '../../../../redux/team/team.action'

interface LoanTableProps {
    dataSource: Loan[]
    headerTitle?: React.ReactNode
    // onUpdateLoanSuccess: () => void
    noName?: boolean
    isDefault?: boolean
    isViewer?: boolean
}

const LoanTable = ({
    dataSource,
    headerTitle,
    noName = false,
    isDefault,
    isViewer,
}: // onUpdateLoanSuccess,
LoanTableProps) => {
    const [toSearch, setToSearch] = useState('')
    // const [activeFilter, setActiveFilter] = useState('all')
    const [filteredMembers, setFilteredMembers] = useState<any>(dataSource)
    const [loading, setLoading] = useState(false)

    const [updateLoanData, setData] = useState<any>({
        show: false,
    })
    const [payLoanData, setPayLoanData] = useState<any>({
        show: false,
    })

    const {
        styles,
        uniformLoanInterest,
        showSelectMember,
        previewAmortization,
    } = useAppSelector(
        (state) => ({
            styles: state.styles,
            uniformLoanInterest: state.systemConfig.uniformLoanInterest,
            showSelectMember: state.loans.showSelectMember,
            previewAmortization: state.members.previewAmortization,
        }),
        shallowEqual
    )
    const cols = [...memberLoansColumn]
    if (noName && !isDefault) {
        const idx = cols.findIndex((col) => col.dataIndex === 'title')
        cols.splice(idx, 1)
    }
    if (uniformLoanInterest) {
        const idx = cols.findIndex((col) => col.dataIndex === 'interest')
        cols.splice(idx, 1)
    }

    const route = useLocation()
    const dispatch = useAppDispatch()

    // const getSystemData = () => {
    //     dispatch(getSystemConfig())
    // }

    const handleEditLoan = async (loan: Loan) => {
        // const memberData = getMember(loan.memberId)
        // const member = await getTeamMember({
        //     partnerCode: loan.partnerCode,
        //     memberId: loan.memberId,
        // })
        // if (!member) {
        //     message.error('Cant find member.')
        //     return
        // }
        setData({
            ...loan,
            // memberName: member.displayName,
            show: true,
        })
    }
    const handlePayLoan = async (loan?: Loan) => {
        // const member = members.find((member) => member.id === loan.memberId)
        // if (!member) {
        //     message.error('Cant find member.')
        //     return
        // }
        if (!loan) {
            setPayLoanData({
                ...payLoanData,
                show: true,
                name: undefined,
            })
            return
        }
        setPayLoanData({
            ...loan,
            name: loan.title,
            show: true,
        })
    }
    const handleDeleteLoan = async (loan: Loan) => {
        setLoading(true)
        if (loan.payments.length) {
            await dispatch(deleteLoan(loan.id as string))
        } else {
            await deleteDoc(doc(db, LOANS_REPOSITORY, loan.id as string))
        }
        setLoading(false)
        message.success('Successfully deleted loan')
        // getSystemData()
    }
    const handleClosePayLoan = () => {
        setPayLoanData({
            show: false,
        })
    }
    const handleCloseEditLoan = () => {
        setData({
            show: false,
        })
    }

    const handleUpdateSuccess = () => {
        // dispatch(getMemberLoans(updateLoanData.memberId))
        // getSystemData()
    }

    const handlePaySuccess = () => {
        // dispatch(getMemberLoans(payLoanData.memberId))
        // getSystemData()
        if (route.pathname.includes('reports')) {
            // dispatch(getAllMemberLoans())
        }
    }

    // const handleNewLoan = (e: any) => {
    //     if (e.key === 'non-member-loan') {
    //         const member = getMember('default')
    //         dispatch(
    //             updateMemberState({
    //                 name: 'applyLoanData',
    //                 value: {
    //                     ...member,
    //                     show: true,
    //                     memberId: 'default',
    //                 },
    //             })
    //         )
    //     }

    //     if (e.key === 'member-loan') {
    //         handleShowMemberSelection()
    //     }
    // }

    const handleSearch = (e: string) => {
        setToSearch(e)
    }

    const handleClickView = (row: Loan) => {
        // const member = members.find((member) => member.id === row.memberId)

        // if (!member) {
        //     message.error('Cant find member.')
        //     return
        // }
        dispatch(
            updateLoanState({
                name: 'memberLoanData',
                value: {
                    ...row,
                    // name: row.displayName,
                    showLoanStatus: true,
                },
            })
        )
    }

    const handleCancelMemberSelection = () => {
        dispatch(
            updateLoanState({
                name: 'showSelectMember',
                value: false,
            })
        )
    }

    const handleFinishMemberSelection = async (member: any) => {
        try {
            await updateTeamMember({
                ...member,
            })
        } catch (err) {
            if (err instanceof Error) {
                message.error(err.message)
            } else {
                message.error('An unknown error occurred.')
            }
        }

        dispatch(
            updateMemberState({
                name: 'applyLoanData',
                value: {
                    ...member,
                    show: true,
                },
            })
        )
        // setShowSelectMember(false)
    }

    const handleShowMemberSelection = () => {
        dispatch(
            updateLoanState({
                name: 'showSelectMember',
                value: true,
            })
        )
    }

    useEffect(() => {
        let timeout: any
        if (toSearch) {
            timeout = setTimeout(() => {
                const filtered = [...dataSource].filter((loan: Loan) => {
                    const splittedTitle = loan.title
                        .toLowerCase()
                        .replace(/ /g, '')
                    return splittedTitle.includes(toSearch)
                })
                setFilteredMembers(filtered)
            }, 100)
        } else {
            setFilteredMembers(dataSource)
        }
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [dataSource, toSearch])

    // useEffect(() => {
    //     if (activeFilter === 'non-member') {
    //         setFilteredMembers(
    //             dataSource.filter((data) => data.memberId === 'default')
    //         )
    //     }
    //     if (activeFilter === 'member') {
    //         setFilteredMembers(
    //             dataSource.filter((data) => data.memberId !== 'default')
    //         )
    //     }
    //     if (activeFilter === 'all') {
    //         setFilteredMembers(dataSource)
    //     }
    // }, [activeFilter, dataSource])
    const handlePreviewAmortization = (row: Loan) => {
        dispatch(
            updateMemberState({
                name: 'previewAmortization',
                value: row.amortization,
            })
        )
    }
    const handleCloseAmortization = () => {
        dispatch(
            updateMemberState({
                name: 'previewAmortization',
                value: undefined,
            })
        )
    }

    // const handlePreviewAmortization = async () => {
    //     // await form.current.validateFields()
    //     // const formData = form.current.getFieldsValue()

    //     dispatch(
    //         updateMemberState({
    //             name: 'previewAmortization',
    //             value: true,
    //         })
    //     )
    // }

    return (
        <>
            {previewAmortization && (
                <PreviewLoanAmortizationSchedule
                    handleClose={handleCloseAmortization}
                    data={previewAmortization}
                />
            )}
            {updateLoanData.show && (
                <UpdateLoan
                    id={updateLoanData.id}
                    onClose={handleCloseEditLoan}
                    onSuccess={handleUpdateSuccess}
                />
            )}
            {payLoanData.show && (
                <PayLoan
                    data={payLoanData.name ? payLoanData : undefined}
                    onCancel={handleClosePayLoan}
                    handleSuccess={handlePaySuccess}
                />
            )}

            {showSelectMember && (
                <SelectMember
                    onCancel={handleCancelMemberSelection}
                    handleSuccess={handleFinishMemberSelection}
                />
            )}
            {!isViewer && (
                <div style={{ padding: '16px 0px' }}>
                    <Row
                        align={'middle'}
                        gutter={[
                            {
                                xs: 6,
                                md: 12,
                            },
                            {
                                xs: 6,
                                md: 12,
                            },
                        ]}
                        wrap={false}
                    >
                        <Col>
                            <Button
                                size="large"
                                shape={'round'}
                                style={{
                                    backgroundColor: styles.primary,
                                    color: 'white',
                                }}
                                onClick={() => handleShowMemberSelection()}
                            >
                                <PlusOutlined /> Loan
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                size="large"
                                shape={'round'}
                                style={{
                                    borderColor: styles.primary,
                                    color: styles.primary,
                                }}
                                onClick={() => handlePayLoan()}
                            >
                                Pay Loan
                            </Button>
                        </Col>
                        <Col>
                            <Input
                                value={toSearch}
                                onChange={(e) => handleSearch(e.target.value)}
                                allowClear
                                placeholder={'Search by title'}
                                suffix={
                                    <Button
                                        icon={<SearchOutlined />}
                                        type="link"
                                    />
                                }
                                // addonBefore={xs ? null : <>Search name</>}
                                size="large"
                            />
                        </Col>
                    </Row>
                </div>
            )}

            {/* <div style={{ overflow: 'auto' }}> */}
            <Table
                scroll={{ x: 800 }}
                pagination={{
                    pageSize: 25,
                }}
                dataSource={filteredMembers}
                columns={[
                    ...cols,
                    // {
                    //     title: '',
                    //     valueType: 'option',
                    //     hideInTable: isViewer,
                    //     render: (_, row, _index) => {
                    //         return [
                    //             <Tooltip
                    //                 title={'View Amortization'}
                    //                 key={'amortization'}
                    //                 placement={'topLeft'}
                    //             >
                    //                 <Typography.Link
                    //                     onClick={() => handlePreviewAmortization(row)}
                    //                 >
                    //                     Payment Schedule
                    //                 </Typography.Link>
                    //             </Tooltip>]
                    //     }
                    // },
                    {
                        title: '',
                        valueType: 'option',
                        // hideInTable: isViewer,
                        render: (_, row, _index) => {
                            let toReturn
                            if (row.remainingBalance === 0) {
                                toReturn = [
                                    {
                                        icon: (
                                            <HistoryOutlined
                                                key={'eduiit-je'}
                                                style={{
                                                    fontSize: 16,
                                                    color: '#1890ff',
                                                }}
                                            />
                                        ),
                                        title: 'View Payment Logs',
                                        key: 'eduiit-je',
                                        onClick: () => handleClickView(row),
                                    },

                                    {
                                        icon: (
                                            <EditOutlined
                                                key={'edit-loan'}
                                                onClick={() =>
                                                    handleEditLoan(row)
                                                }
                                                style={{
                                                    fontSize: 16,
                                                    color: '#1890ff',
                                                }}
                                            />
                                        ),
                                        title: 'Update loan details',
                                        key: 'edit-loan',
                                        onClick: () => handleEditLoan(row),
                                    },
                                    {
                                        icon: (
                                            <DeleteOutlined
                                                key={'delete-loan'}
                                                style={{
                                                    color: 'red',
                                                    fontSize: 16,
                                                }}
                                            />
                                        ),
                                        title: 'Delete Loan',
                                        key: 'delete-loan',
                                        loading,
                                        onClick: () => {
                                            const title =
                                                'Are you sure to delete this loan?'

                                            Modal.confirm({
                                                centered: true,
                                                title,
                                                onOk: () =>
                                                    handleDeleteLoan(row),
                                                autoFocusButton: 'cancel',
                                                okButtonProps: {
                                                    loading,
                                                },
                                                cancelButtonProps: {
                                                    loading,
                                                },
                                            })
                                        },
                                    },
                                ]
                            } else {
                                toReturn = [
                                    {
                                        key: 'eduiit-je',
                                        icon: (
                                            <HistoryOutlined
                                                key={'eduiit-je'}
                                                style={{
                                                    fontSize: 16,
                                                    color: '#1890ff',
                                                }}
                                            />
                                        ),
                                        title: 'Payment History',
                                        onClick: () => handleClickView(row),
                                    },

                                    {
                                        key: 'edit-loan',
                                        icon: (
                                            <EditOutlined
                                                key={'edit-loan'}
                                                style={{
                                                    fontSize: 16,
                                                    color: '#1890ff',
                                                }}
                                            />
                                        ),
                                        title: 'Update Loan',
                                        onClick: () => handleEditLoan(row),
                                    },
                                    {
                                        key: 'delete-loan-confirm',
                                        icon: (
                                            <DeleteOutlined
                                                key={'delete-loan'}
                                                style={{
                                                    color: 'red',
                                                    fontSize: 16,
                                                }}
                                            />
                                        ),
                                        title: 'Delete Loan',
                                        onClick: () => {
                                            const title =
                                                'Are you sure to delete this loan?'

                                            Modal.confirm({
                                                centered: true,
                                                title,
                                                onOk: () =>
                                                    handleDeleteLoan(row),
                                                autoFocusButton: 'cancel',
                                                okButtonProps: {
                                                    loading,
                                                },
                                            })
                                        },
                                    },
                                ]
                            }

                            return [
                                <Tooltip
                                    title={'Pay loan'}
                                    key={'edit-loan-payment1'}
                                    placement={'topLeft'}
                                >
                                    <Typography.Link
                                        onClick={() => handlePayLoan(row)}
                                        key={'edit-borrow-table-actions-xyz'}
                                    >
                                        Pay
                                    </Typography.Link>
                                </Tooltip>,
                                <Tooltip
                                    title={'View Amortization Schedule'}
                                    key={'edit-loan-payment1'}
                                    placement={'topLeft'}
                                >
                                    <Typography.Link
                                        onClick={() =>
                                            handlePreviewAmortization(row)
                                        }
                                        key={'edit-borrow-table-actions-xyz'}
                                    >
                                        Schedule
                                    </Typography.Link>
                                </Tooltip>,
                                <Divider
                                    key={'edit-loan-payment2-divider'}
                                    type="vertical"
                                    style={{ margin: 0 }}
                                />,
                                <Dropdown
                                    trigger={['click']}
                                    overlay={
                                        <Menu>
                                            {toReturn.map((action) => (
                                                <Menu.Item
                                                    key={action.key}
                                                    icon={action.icon}
                                                    onClick={action.onClick}
                                                >
                                                    {action.title}
                                                </Menu.Item>
                                            ))}
                                        </Menu>
                                    }
                                >
                                    <Typography.Link
                                        key={'edit-borrow-table-actions'}
                                    >
                                        <Row gutter={[4, 6]} wrap={false}>
                                            <Col>More</Col>
                                            <Col>
                                                <CaretDownFilled />
                                            </Col>
                                        </Row>
                                    </Typography.Link>
                                </Dropdown>,
                            ]
                        },
                    },
                ]}
                search={false}
                options={{
                    setting: false,
                    density: false,
                    reload: false,
                }}
                headerTitle={headerTitle ? headerTitle : null}
            />
            {/* </div> */}
        </>
    )
}

LoanTable.propTypes = {}

export default LoanTable
