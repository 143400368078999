export function formatNumberWithCommas(numberString: string) {
    const parts = (numberString || '').split('.')
    const integerPart = parts[0]
    const decimalPart = parts[1] || '00'

    const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
    )

    return `${formattedIntegerPart}.${decimalPart}`
}
