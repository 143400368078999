import { useEffect, useState } from 'react'
import { ModalForm } from '@ant-design/pro-components'
import {
    getLoanPayments,
    initialLoansState,
    updateLoanState,
    ViewLoan,
} from '../../../redux/loans/loans.slice'
import { useAppDispatch, useAppSelector } from '../../../store'
import { shallowEqual } from 'react-redux'
import { Loan } from '../../../../domain/entities/Loan'
import { Form, message, Typography } from 'antd'
import LoanPaymentTable from './components/LoanPaymentTable'

function ViewLoanPayments() {
    const dispatch = useAppDispatch()
    const [table, setTable] = useState<any>([])
    // const [columns, setColumns] = useState(amortizationTableColumn)
    const { memberLoanData }: { memberLoanData: ViewLoan } = useAppSelector(
        (state) => ({
            memberLoanData: state.loans.memberLoanData,
        }),
        shallowEqual
    )
    const [form] = Form.useForm()
    const handleCancel = () => {
        dispatch(
            updateLoanState({
                name: 'memberLoanData',
                value: initialLoansState.memberLoanData,
            })
        )
    }

    // const handleEditPaymentSuccess = () => {}
    const removePayment = (id: string) => {
        const payments = [...table]
        const idx = payments.findIndex((payment) => payment.id === id)
        if (idx >= 0) {
            payments.splice(idx, 1)
            setTable(payments)
            message.success('Successfully removed loan payment')
            // getSystemData()
        }
    }
    useEffect(() => {
        const getLoanData = async () => {
            if (!memberLoanData.showLoanStatus || !memberLoanData.id) return
            const data = await getLoanPayments(memberLoanData.id)
            if (data) {
                setTable(data.sort((a, b) => b.creation - a.creation))
            } else {
                setTable([])
            }
        }
        getLoanData()
    }, [memberLoanData])

    return (
        <ModalForm<Loan>
            form={form}
            onFinishFailed={(err) => {
                if (err.errorFields.length) {
                    const firstError = err.errorFields[0]
                    message.destroy()
                    message.error(firstError.errors)
                    form.scrollToField(firstError.name, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }}
            title={
                <>
                    <Typography.Title level={3}>
                        {memberLoanData.title}'s Payment History
                    </Typography.Title>
                </>
            }
            open={memberLoanData.showLoanStatus}
            autoFocusFirstInput
            modalProps={{
                onCancel: handleCancel,
                okText: 'Submit',
                centered: true,
            }}
            submitTimeout={2000}
            submitter={false}
            // submitter={{
            //     render: (_props, defaultDoms) => {
            //         return [
            //             defaultDoms[0],
            //             <Button
            //                 key="extra-preview"
            //                 onClick={handlePreviewLoanPayment}
            //                 style={{
            //                     backgroundColor: styles.primary,
            //                     color: 'white',
            //                 }}
            //             >
            //                 Preview
            //             </Button>,
            //             defaultDoms[1],
            //         ]
            //     },
            // }}
            // initialValues={{
            //     payback: 'monthly',
            //     termMonths: 0,
            //     termYears: 0,
            // }}
            // onValuesChange={handleFieldChange}
        >
            <LoanPaymentTable
                loanData={memberLoanData}
                data={table}
                handleCancel={handleCancel}
                onRemove={removePayment}
            />
        </ModalForm>
    )
}

export default ViewLoanPayments
