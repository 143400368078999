import { ProTable } from '@ant-design/pro-components'
import type { ProTableProps } from '@ant-design/pro-components'
import React from 'react'

interface TableProp extends ProTableProps<any, any> {}
function Table(props: TableProp) {
    return (
        <ProTable
            options={{
                setting: {
                    draggable: true,
                    checkable: true,
                },
                density: true,
            }}
            rowKey="id"
            dateFormatter="string"
            bordered
            tableRender={(_props, _defaultDom, domList) => {
                return (
                    <>
                        <div style={{ marginRight: 20 }}>{domList.toolbar}</div>
                        {domList.alert}
                        {domList.table}
                    </>
                )
            }}
            cardProps={{
                bodyStyle: {
                    padding: 0,
                },
            }}
            editable={{
                type: 'multiple',
            }}
            search={false}
            {...props}
        />
    )
}

export default Table
