import { StyledButton } from './styles'
import { ButtonProps } from '../types'

export const Button = ({
    color,
    fixedWidth,
    children,
    onClick,
    ...props
}: ButtonProps) => (
    <StyledButton
        color={color}
        fixedWidth={fixedWidth}
        onClick={onClick}
        {...props}
    >
        {children}
    </StyledButton>
)
