import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './redux/auth/auth.slice'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { styleSlice } from './redux/styles.slice'
import { memberSlice } from './redux/members/members.slice'
import { shareSlice } from './redux/shares/shares.slice'
import { systemSlice } from './redux/system.slice'
import { loansSlice } from './redux/loans/loans.slice'
import { teamSlice } from './redux/team/team.slice'
import { borrowSlice } from './redux/borrow/borrow.slice'
import { expenseSlice } from './redux/expense/expense.slice'
import { savingSlice } from './redux/savings/savings.slice'
import { transportSlice } from './redux/transport/transport.slice'
import { vehicleSlice } from './redux/vehicle/vehicle.slice'

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        systemConfig: systemSlice.reducer,
        styles: styleSlice.reducer,
        members: memberSlice.reducer,
        shares: shareSlice.reducer,
        loans: loansSlice.reducer,
        team: teamSlice.reducer,
        borrow: borrowSlice.reducer,
        expense: expenseSlice.reducer,
        savings: savingSlice.reducer,
        transport: transportSlice.reducer,
        vehicle: vehicleSlice.reducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
