import { AuthRepository } from '../repositories/auth.repository'
import { LoginProp, User } from '../entities/default/AdminUser'

export class AuthService {
    authRepo: AuthRepository

    constructor(ir: AuthRepository) {
        this.authRepo = ir
    }

    async Login(payload: LoginProp): Promise<User | undefined> {
        return await this.authRepo.Login(payload)
    }

    async Register(
        payload: User
    ): Promise<{ status: string; message: string; user?: User }> {
        return await this.authRepo.Register(payload)
    }

    async isLoggedIn(callback?: (resp?: User | null) => void) {
        return await this.authRepo.isLoggedIn(callback)
    }

    async updateUser(payload: Partial<User>, uid: string): Promise<boolean> {
        return await this.authRepo.updateUser(payload, uid)
    }

    async Logout(callback?: () => void): Promise<void> {
        return await this.authRepo.Logout(callback)
    }

    async verifyCode(code: string): Promise<boolean> {
        return await this.authRepo.verifyCode(code)
    }
}
