import { Loan, LoanPayment, LoanPaymentProps } from '../entities/Loan'
import { LoanFirebaseRepository } from '../repositories/loan.repository'
// import moment from "moment";

export class LoanService {
    LoanRepo: LoanFirebaseRepository

    constructor(ir: LoanFirebaseRepository) {
        this.LoanRepo = ir
    }

    calculateTotalPayment(data: LoanPayment[]) {
        let totalInterestPaid = 0
        let totalPrincipalPaid = 0
        data.forEach((loanPayment) => {
            totalInterestPaid += loanPayment.interest
            totalPrincipalPaid += loanPayment.principal
            totalInterestPaid -= loanPayment.paidPendingInterest
        })
        return [totalInterestPaid, totalPrincipalPaid]
    }

    // calculateNextDue(data: LoanPayment[]): number {
    //     const lastPay = data[data.length - 1]
    //     const paymentDate = moment.unix(lastPay.creation)
    //
    //
    //     return 0
    // }

    async AddLoan(payload: Loan) {
        return await this.LoanRepo.AddLoan(payload)
    }

    async GetMemberLoans(payload: { memberId: string; partnerCode: string }) {
        return await this.LoanRepo.GetMemberLoans(payload)
        // return loans.map((loan) => {
        //     const [totalInterestPaid, totalPrincipalPaid] =
        //         this.calculateTotalPayment(loan.payments)
        //     return {
        //         ...loan,
        //         totalInterestPaid,
        //         totalPrincipalPaid,
        //         remainingBalance: loan.amount - totalPrincipalPaid,
        //     }
        // })
    }

    async RemoveLoan(id: string) {
        return await this.LoanRepo.RemoveLoan(id)
    }

    async getLoanPayments(loanId: string) {
        return await this.LoanRepo.getLoanPayments(loanId)
    }

    async GetLoan(id: string) {
        return await this.LoanRepo.GetLoan(id)
        // if (!loan) return
        // const [totalInterestPaid, totalPrincipalPaid] =
        //     this.calculateTotalPayment(loan.payments)
        //
        // return {
        //     ...loan,
        //     totalInterestPaid,
        //     totalPrincipalPaid,
        //     remainingBalance: loan.amount - totalPrincipalPaid,
        // }
    }

    async UpdateLoan(payload: Loan, prevLoan: Loan) {
        return await this.LoanRepo.UpdateLoan(payload, prevLoan)
    }
    async GetAllMemberLoan(partnerCode: string) {
        return await this.LoanRepo.GetAllMemberLoan(partnerCode)

        // return loans.map((loan) => {
        //     const [totalInterestPaid, totalPrincipalPaid] =
        //         this.calculateTotalPayment(loan.payments)
        //     return {
        //         ...loan,
        //         totalInterestPaid,
        //         totalPrincipalPaid,
        //         remainingBalance: loan.amount - totalPrincipalPaid,
        //     }
        // })
    }

    async AddLoanPayment(
        loan: LoanPaymentProps & {
            id: string
        },
        payload: LoanPayment
    ) {
        return await this.LoanRepo.AddLoanPayment(loan, payload)
    }

    async RemovePayment(
        loan: LoanPaymentProps & {
            id: string
        },
        payment: LoanPayment
        // prevPayment: LoanPayment
    ) {
        return await this.LoanRepo.RemovePayment(loan, payment)
    }

    async UpdateLoanPayment(
        previousPayment: LoanPayment,
        payment: LoanPayment,
        loanProp: LoanPaymentProps & {
            id: string
        },
        newPendingInterest: number
    ) {
        return await this.LoanRepo.UpdateLoanPayment(
            previousPayment,
            payment,
            loanProp,
            newPendingInterest
        )
    }
}
