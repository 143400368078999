import React, { useState } from 'react'
import {
    Button,
    Col,
    Divider,
    Form,
    Grid,
    message,
    Row,
    Statistic,
    Steps,
    Typography,
} from 'antd'
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormText,
} from '@ant-design/pro-components'
import { useAppDispatch, useAppSelector } from '../../../store'
import { shallowEqual } from 'react-redux'
import { memberStateInitialValues } from '../UpdateMember'
import { updateMemberState } from '../../../redux/members/members.slice'
import { Loan, LoanProps } from '../../../../domain/entities/Loan'
import moment from 'moment'
import { updateLoanState } from '../../../redux/loans/loans.slice'
import PreviewLoanAmortizationSchedule from './PreviewLoanAmortizationSchedule'
// import { calculateMonthlyPayment } from './loanHelper'
import { SolutionOutlined } from '@ant-design/icons'
import { MdQuestionAnswer } from 'react-icons/md'
import { Frequency } from '../../../../domain/entities/SystemSettings'
import { capitalize } from '../../utils'
import { addDoc, collection } from 'firebase/firestore'
import { LOANS_REPOSITORY } from '../../../../data/repositories/constants'
import { db } from '../../../boot'
import { calculateAmortizationScheduleV2 } from './amoritzationUtils'
import { interestCalculations } from '../../../../domain/entities/Team'

export interface PaymentSchedule {
    beginningBalance: number
    loanDue: number
    interest: number
    principal: number
    endingBalance: number
    paymentNumber: number
    due: number
    id: string
    year?: number
}

export interface AmortizationSchedule {
    totalPrincipal: number
    payback: Frequency
    totalPayment: number
    totalMonthlyPayment: number
    totalInterest: number
    creation: number
    numberOfPayments: number
    schedules: PaymentSchedule[]
}
// number of weeks after previous payment
const AddLoan = () => {
    // const [previewAmortization, setPreviewAmortization] = useState<{
    //     show: boolean
    //     data?: Loan
    // }>({
    //     show: false,
    // })
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    // const [monthlyPayment, setMonthlyPayment] = useState(0)
    // const [disableSelectPayback, setDisableSelectPayback] = useState(true)
    const {
        applyLoanData,
        draftLoanData,
        currentUser,
        systemLoanInterest,
        uniformLoanInterest,
        withInitialLoanPayment,
        compoundInterest,
        loanPenalty,
        styles,
        paymentFrequency,
        loanInterestType,
    } = useAppSelector(
        (state) => ({
            loanInterestType: state.team.selectedTeamData.loanInterestType,
            applyLoanData: state.members.applyLoanData,
            draftLoanData: state.members.draftLoanData,
            draftLoanAmortization: state.members.draftLoanAmortization,
            currentUser: state.auth.user,
            paymentFrequency: state.team.selectedTeamData.paymentFrequency,
            systemLoanInterest: state.team.selectedTeamData.loanInterestRate,
            uniformLoanInterest:
                state.team.selectedTeamData.uniformLoanInterest,
            withInitialLoanPayment:
                state.team.selectedTeamData.withInitialLoanPayment,
            compoundInterest: state.team.selectedTeamData.compoundInterest,
            loanPenalty: state.team.selectedTeamData.loanPenalty,
            styles: state.styles,
            // loanPenaltyUniformity: state.systemConfig.loanPenaltyUniformity,
        }),
        shallowEqual
    )
    const [initValues, setInitValues] = useState<LoanProps>({
        payback: 'monthly',
        termYears: 0,
        termMonths: 0,
        interest: systemLoanInterest,
        amount: 0,
        compound: 'continuous',
        creation: 0,
        amortization: {
            totalPrincipal: 0,
            payback: 'monthly',
            totalPayment: 0,
            totalMonthlyPayment: 0,
            totalInterest: 0,
            creation: 0,
            numberOfPayments: 0,
            schedules: [],
        },
    })

    const [form] = Form.useForm()
    const { xs } = Grid.useBreakpoint()

    // const handleLoanPenalties = async (ln: Loan) => {
    //     let due = ln.due
    //     if (!ln.loanPenalty) return

    //     const daysElapsed = calculateDaysElapsed(due, moment().unix())
    //     const numberOfMonths = parseInt((daysElapsed / 30).toFixed())
    //     let n = 0
    //     while (n <= numberOfMonths) {
    //         const loan = {
    //             ...ln,
    //         }
    //         // while (isDue(date)) {
    //         const latestPay = loan.payments[loan.payments.length - 1]
    //         const endingBalance = latestPay?.endingBalance || ln.amount
    //         const interestRate = loan.interest / 100
    //         const penalty = interestRate * endingBalance
    //         const newPay: LoanPayment = {
    //             endingBalance: endingBalance,
    //             beginningBalance: endingBalance,
    //             principal: 0,
    //             interest: penalty,
    //             amountPaid: 0,
    //             creation: due,
    //             modified: moment().unix(),
    //             modifiedBy: 'system',
    //             paidPendingInterest: 0,
    //             due,
    //             pendingInterest: loan.pendingInterest,
    //             currentInterest: loan.pendingInterest + penalty,
    //             loanPenalty: loan.loanPenalty,
    //             isRemoved: false,
    //         }

    //         await dispatch(
    //             payLoan({
    //                 loan: {
    //                     id: loan.id as string,
    //                     partnerCode: loan.partnerCode,
    //                     memberId: loan.memberId,
    //                     creation: loan.creation,
    //                 },
    //                 payment: newPay,
    //             })
    //         )
    //         due = moment.unix(due).add(1, 'month').unix()

    //         n += 1
    //     }
    // }
    const handleSubmit = async () => {
        if (!draftLoanData) return
        // setLoading(true)
        const payload: Loan = {
            ...draftLoanData,
        }

        await addDoc(collection(db, LOANS_REPOSITORY), payload)
        // // await handleLoanPenalties(payload)
        setLoading(false)

        // if (loansReport) {
        //     dispatch(getAllMemberLoans())
        // } else {
        //     dispatch(getMembers())
        // }
        message.success('Loan successfully submitted.')

        // dispatch(getSystemConfig())
        handleCancel()
    }
    // const handlePreviewLoanPayment = async () => {
    //     await form.current.validateFields()
    //     const formData = form.current.getFieldsValue()
    //     setPreviewAmortization({
    //         data: formData,
    //         show: true,
    //     })
    // }
    const handlePreviewAmortization = async () => {
        // await form.current.validateFields()
        // const formData = form.current.getFieldsValue()

        dispatch(
            updateMemberState({
                name: 'previewAmortization',
                value: draftLoanData?.amortization,
            })
        )
    }
    const handleClosePreviewLoanPayment = () => {
        dispatch(
            updateMemberState({
                name: 'previewAmortization',
                value: undefined,
            })
        )
    }
    const handleCancel = () => {
        dispatch(
            updateMemberState({
                name: 'applyLoanData',
                value: {
                    ...memberStateInitialValues,
                    show: false,
                },
            })
        )
        form.resetFields()
    }
    // useEffect(() => {
    //     const { termYears, termMonths, amount, interest, payback, compound } =
    //         initValues
    //     if (!((termYears || termMonths) && amount && interest)) return

    //     // setDisableSelectPayback(compound === 'fix')
    //     const numberOfPaymentsEveryYear = 12
    //     // const arrayOfArrays = []
    //     const interestRate = interest / 100
    //     const monthlyInterestRate = interestRate / numberOfPaymentsEveryYear
    //     // const monthsToPay = termYears * numberOfPaymentsEveryYear + termMonths

    //     // const n = monthsToPay
    //     // const i = monthlyInterestRate
    //     // const u = i * (1 + i) ** n
    //     // const d = (1 + i) ** n - 1
    //     const monthlyPayment = calculateMonthlyPayment(amount, interest, {
    //         termYears,
    //         termMonths,
    //         payback,
    //         compound,
    //     })
    //     let loanAmount = amount
    //     // let totalInterestPayment = 0
    //     for (let y = 1; y <= termYears; y++) {
    //         // const yearData = []
    //         for (let m = 1; m <= 12; m++) {
    //             const beginningLoanBalance = loanAmount
    //             const interestPayment = monthlyInterestRate * loanAmount
    //             let principalPayment = monthlyPayment - interestPayment

    //             loanAmount = loanAmount - principalPayment
    //             if (loanAmount < 0) {
    //                 loanAmount = 0
    //                 principalPayment = beginningLoanBalance
    //             }
    //             // totalInterestPayment += interestPayment

    //             // const monthlyData: any = {
    //             //     beginningBalance: beginningLoanBalance,
    //             //     interest: interestPayment,
    //             //     principal: principalPayment,
    //             //     endingBalance: loanAmount,
    //             //     year: y,
    //             //     month: m,
    //             //     id: randomId(5),
    //             // }
    //             // yearData.push(monthlyData)
    //         }
    //         // }
    //         // arrayOfArrays.push(yearData)
    //     }
    //     // const yearDataExtra = []
    //     if (termMonths > 0) {
    //         for (let m = 1; m <= termMonths; m++) {
    //             const beginningLoanBalance = loanAmount
    //             const interestPayment = monthlyInterestRate * loanAmount
    //             let principalPayment = monthlyPayment - interestPayment

    //             loanAmount = loanAmount - principalPayment

    //             if (loanAmount < 0) {
    //                 loanAmount = 0
    //                 principalPayment = beginningLoanBalance
    //             }
    //             // totalInterestPayment += interestPayment

    //             // const monthlyData: any = {
    //             //     beginningBalance: beginningLoanBalance,
    //             //     interest: interestPayment,
    //             //     principal: principalPayment,
    //             //     endingBalance: loanAmount,
    //             //     year: termYears + 1,
    //             //     month: m,
    //             //     id: randomId(12),
    //             // }
    //             // yearDataExtra.push(monthlyData)
    //         }
    //         // arrayOfArrays.push(yearDataExtra)
    //     }
    //     // setTableData(arrayOfArrays)
    //     // setTotalInterest(parseInt(totalInterestPayment.toFixed(2), 10))
    //     // setTotalPayment(parseInt((monthlyPayment * monthsToPay).toFixed()))
    //     setMonthlyPayment(monthlyPayment)
    //     // setNumberOfPayments(monthsToPay)
    // }, [initValues])

    const handleFieldChange = (changedFields: any) => {
        // if (changedFields.compound) {
        //     setDisableSelectPayback(changedFields.compound === 'fix')
        //     if (changedFields.compound === 'fix') {
        //         changedFields.payback = 'monthly'
        //         form.setFieldsValue({
        //             payback: 'monthly',
        //         })
        //     }
        // }
        setInitValues({
            ...initValues,
            ...changedFields,
        })
    }

    const handleBackToMemberSelection = () => {
        handleCancel()
        dispatch(
            updateLoanState({
                name: 'showSelectMember',
                value: true,
            })
        )
    }

    const [steps, setSteps] = useState(1)

    const handleNext = async (datum: any) => {
        if (steps === 2) {
            handleSubmit()
            return
        }

        setSteps((step) => (step += 1))
        const creationDate =
            currentUser.type !== 'staff'
                ? moment(datum.startDate, 'MMMM DD, YYYY').unix()
                : moment().unix()
        // const interestRate = datum.interest / 100
        // const interestToPay = interestRate * datum.amount
        const payload: Loan = {
            ...datum,
            memberId: applyLoanData.id,
            creation: creationDate,
            modified: moment().unix(),
            payments: [],
            // payments: withInitialLoanPayment
            //     ? [
            //         {
            //             beginningBalance: datum.amount + interestToPay,
            //             interest: interestToPay,
            //             principal: 0,
            //             endingBalance: datum.amount,
            //             amountPaid: interestToPay,
            //             //   id: randomId(10),
            //             creation: creationDate,
            //             modified: moment().unix(),
            //             modifiedBy: 'automated',
            //             paidPendingInterest: 0,
            //             due: moment.unix(creationDate).add(1, 'month').unix(),
            //             pendingInterest: 0,
            //             currentInterest: interestToPay,
            //             loanPenalty: datum.loanPenalty,
            //             isRemoved: false,
            //             partnerCode: currentUser.activeTeam
            //         },
            //     ]
            //     : [],
            amount: datum.amount,
            interest: datum.interest,
            payback: paymentFrequency || 'monthly',
            termMonths: datum.termMonths || 0,
            termYears: datum.termYears || 0,
            modifiedBy: currentUser.email,
            monthlyPayment: 0,
            // id: randomId(10),
            compound: compoundInterest,
            title: datum.title || applyLoanData.displayName,
            totalInterestPaid: 0,
            totalPrincipalPaid: 0,
            remainingBalance: datum.amount,
            due: moment.unix(creationDate).add(1, 'month').unix(),
            pendingInterest: 0,
            loanPenalty: datum.loanPenalty || true,
            partnerCode: currentUser.activeTeam,
            isRemoved: false,
            approvalDate: datum.approvalDate || 0,
            disbursementDate: datum.disbursementDate || 0,
            withInitialLoanPayment: withInitialLoanPayment,
            numberOfPaymentsMade: 0,
            loanInterestType: datum.loanInterestType || loanInterestType,
            unpaidInterest: datum.amount,
        }

        payload.amortization = calculateAmortizationScheduleV2(payload)
        dispatch(
            updateMemberState({
                name: 'draftLoanData',
                value: {
                    ...payload,
                },
            })
        )
    }

    const handlePrev = async (datum: any) => {
        setSteps((step) => (step -= 1))
    }
    return (
        <>
            <ModalForm<Loan>
                form={form}
                onFinishFailed={(err) => {
                    if (err.errorFields.length) {
                        const firstError = err.errorFields[0]
                        message.destroy()
                        message.error(firstError.errors)
                        form.scrollToField(firstError.name, {
                            behavior: 'smooth',
                            block: 'center',
                        })
                    }
                }}
                title={
                    applyLoanData.memberId === 'default' ? (
                        <Typography.Title level={3}>
                            Non-Member
                        </Typography.Title>
                    ) : (
                        false
                    )
                }
                open={applyLoanData.show}
                autoFocusFirstInput
                modalProps={{
                    okText: 'Next',
                    centered: true,
                    closable: !xs,
                    cancelButtonProps: {
                        style: {
                            display: 'none',
                        },
                    },
                    bodyStyle: {
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                }}
                // onKeyDown={(event) => {
                //     if (event.key === 'Enter') {
                //         form.submit()
                //     }
                // }}
                submitTimeout={2000}
                onFinish={handleNext}
                submitter={false}
                initialValues={{
                    interest: systemLoanInterest,
                    startDate: moment(),
                    loanPenalty,
                    loanInterestType: 'reducing-balance',
                }}
                onValuesChange={handleFieldChange}
            >
                <>
                    <Steps
                        style={{
                            padding: 0,
                        }}
                        current={steps}
                        type={xs ? 'default' : 'navigation'}
                        progressDot={xs}
                        size={xs ? 'small' : 'default'}
                    >
                        <Steps.Step
                            stepIndex={0}
                            status={'finish'}
                            title={'Select a member'}
                            description={
                                <Typography.Text
                                    type={'secondary'}
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    {applyLoanData.displayName}
                                </Typography.Text>
                            }
                            wrapperStyle={{
                                paddingBottom: 0,
                            }}
                        />
                        <Steps.Step
                            stepIndex={1}
                            icon={<SolutionOutlined />}
                            status={'process'}
                            title={'Fill in details'}
                        />
                        <Steps.Step
                            stepIndex={2}
                            icon={<MdQuestionAnswer />}
                            status={'process'}
                            title={'Review'}
                        />
                    </Steps>
                    <Divider
                        style={{
                            margin: xs ? '0 0 14px' : undefined,
                        }}
                    />
                </>
                {steps === 2 && draftLoanData ? (
                    <div>
                        <div style={{ padding: '0 24px 32px 24px' }}>
                            <Row>
                                <Col span={24}>
                                    <Row justify="center" gutter={[24, 0]}>
                                        <Col md={12} xs={24}>
                                            <table
                                                cellPadding={'5px 10px'}
                                                style={{ width: '100%' }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <th
                                                            colSpan={2}
                                                            align={'center'}
                                                        >
                                                            BORROWER'S
                                                            INFORMATION
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td>Name:</td>
                                                        <td align={'right'}>
                                                            {
                                                                applyLoanData?.lastName
                                                            }
                                                            ,{' '}
                                                            {
                                                                applyLoanData?.firstName
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Address:</td>
                                                        <td align={'right'}>
                                                            {
                                                                applyLoanData?.presentAddress
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email:</td>
                                                        <td align={'right'}>
                                                            {
                                                                applyLoanData?.email
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Phone:</td>
                                                        <td align={'right'}>
                                                            {
                                                                applyLoanData?.phone
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>

                                        <Col md={12} xs={24}>
                                            <table
                                                cellPadding={'5px 10px'}
                                                style={{ width: '100%' }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <th
                                                            colSpan={2}
                                                            align={'center'}
                                                        >
                                                            INSTALLMENT SCHEDULE
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Number of payments:
                                                        </td>
                                                        <td align={'right'}>
                                                            {
                                                                draftLoanData.termMonths
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            First payment due:
                                                        </td>
                                                        <td align={'right'}>
                                                            {moment
                                                                .unix(
                                                                    draftLoanData.creation
                                                                )
                                                                .format(
                                                                    'MMMM DD, YYYY'
                                                                )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Estimated last due:
                                                        </td>
                                                        <td align={'right'}>
                                                            {moment
                                                                .unix(
                                                                    draftLoanData.creation
                                                                )
                                                                .add(
                                                                    draftLoanData.termMonths,
                                                                    'M'
                                                                )
                                                                .format(
                                                                    'MMMM DD, YYYY'
                                                                )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <Button
                                                block
                                                onClick={
                                                    handlePreviewAmortization
                                                }
                                                style={{
                                                    color: '#1890ff',
                                                    marginTop: 10,
                                                }}
                                                size="large"
                                                shape="round"
                                            >
                                                View Amortization Schedule
                                            </Button>
                                        </Col>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={12}>
                                                    <table
                                                        cellPadding={'5px 10px'}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <th
                                                                    colSpan={2}
                                                                    align={
                                                                        'center'
                                                                    }
                                                                >
                                                                    LOAN DETAILS
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Loan Type
                                                                </td>
                                                                <td
                                                                    align={
                                                                        'right'
                                                                    }
                                                                >
                                                                    {capitalize(
                                                                        draftLoanData.type
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            {draftLoanData.approvalDate ? (
                                                                <tr>
                                                                    <td>
                                                                        Approval
                                                                        Date
                                                                    </td>
                                                                    <td
                                                                        align={
                                                                            'right'
                                                                        }
                                                                    >
                                                                        {moment
                                                                            .unix(
                                                                                draftLoanData.approvalDate
                                                                            )
                                                                            .format(
                                                                                'MMM DD, YYYY'
                                                                            )}
                                                                    </td>
                                                                </tr>
                                                            ) : null}

                                                            {draftLoanData.disbursementDate ? (
                                                                <tr>
                                                                    <td>
                                                                        Disbursement
                                                                        Date
                                                                    </td>
                                                                    <td
                                                                        align={
                                                                            'right'
                                                                        }
                                                                    >
                                                                        {moment
                                                                            .unix(
                                                                                draftLoanData.disbursementDate
                                                                            )
                                                                            .format(
                                                                                'MMM DD, YYYY'
                                                                            )}
                                                                    </td>
                                                                </tr>
                                                            ) : null}
                                                            <tr>
                                                                <td>
                                                                    Percentage
                                                                </td>
                                                                <td
                                                                    align={
                                                                        'right'
                                                                    }
                                                                >
                                                                    <Statistic
                                                                        value={
                                                                            draftLoanData.interest
                                                                        }
                                                                        suffix={
                                                                            '%'
                                                                        }
                                                                        prefixCls={
                                                                            '%'
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Loan Amount
                                                                </td>
                                                                <td
                                                                    align={
                                                                        'right'
                                                                    }
                                                                >
                                                                    <Statistic
                                                                        value={
                                                                            draftLoanData.amount
                                                                        }
                                                                        prefix={
                                                                            '₱'
                                                                        }
                                                                        prefixCls={
                                                                            '₱'
                                                                        }
                                                                        precision={
                                                                            2
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Loan Terms
                                                                </td>
                                                                <td
                                                                    align={
                                                                        'right'
                                                                    }
                                                                >
                                                                    <Statistic
                                                                        value={
                                                                            draftLoanData.termMonths
                                                                        }
                                                                        prefixCls={
                                                                            '₱'
                                                                        }
                                                                        valueRender={(
                                                                            nd
                                                                        ) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        nd
                                                                                    }{' '}
                                                                                    {draftLoanData.payback ===
                                                                                    'monthly'
                                                                                        ? 'months'
                                                                                        : null}
                                                                                </>
                                                                            )
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Application
                                                                    Date
                                                                </td>
                                                                <td
                                                                    align={
                                                                        'right'
                                                                    }
                                                                >
                                                                    {moment
                                                                        .unix(
                                                                            draftLoanData.creation
                                                                        )
                                                                        .format(
                                                                            'MMMM DD, YYYY'
                                                                        )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={8}>
                                    {/*<br />*/}
                                    {/*<Row justify={'center'}>*/}
                                    {/*    <Col>*/}
                                    {/*        <Typography.Link*/}
                                    {/*            onClick={handlePreviewAmortization}*/}
                                    {/*            disabled={*/}
                                    {/*                memberLoanData && memberLoanData.id ? false : true*/}
                                    {/*            }*/}
                                    {/*        >*/}
                                    {/*            View Amortization Schedule*/}
                                    {/*        </Typography.Link>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                </Col>
                            </Row>
                        </div>
                        <div
                            style={{
                                backgroundColor: 'rgba(170, 170, 170, 0.1)',
                            }}
                        >
                            <Divider style={{ margin: 0 }} />
                            <Row style={{ padding: '18px 24px' }}>
                                <Col span={12}>
                                    <Row gutter={[12, 0]}>
                                        <Col></Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row justify="end" gutter={[12, 0]}>
                                        <Col>
                                            <Button
                                                loading={loading}
                                                key={'back-to-member'}
                                                onClick={handlePrev}
                                            >
                                                Back
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                loading={loading}
                                                key={'save-as-draft'}
                                                onClick={() => {
                                                    form.submit()
                                                }}
                                                type="primary"
                                                style={{
                                                    backgroundColor:
                                                        styles.primary,
                                                    color: 'white',
                                                }}
                                            >
                                                Save as Draft
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                ) : null}
                {steps === 1 ? (
                    <>
                        <div
                            style={{
                                padding: xs ? 0 : '24px 24px 0 24px',
                            }}
                        >
                            <Row justify="center">
                                <Col xs={24} sm={18}>
                                    <ProFormText
                                        placeholder={applyLoanData.displayName}
                                        name="title"
                                        label="Title"
                                    />

                                    <Row wrap={false} gutter={[12, 0]}>
                                        <Col span={12}>
                                            <ProFormDigit
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: 'number',
                                                        message:
                                                            'This field is required',
                                                    },
                                                ]}
                                                name="amount"
                                                label="Amount"
                                                placeholder="360.50"
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ProFormDigit
                                                name={'interest'}
                                                label={'Interest %'}
                                                max={100}
                                                min={1}
                                                disabled={uniformLoanInterest}
                                            />
                                        </Col>
                                    </Row>
                                    {/*<ProForm.Group*/}
                                    {/*    label={*/}
                                    {/*        <Typography.Text>*/}
                                    {/*            <Typography.Text style={{ color: 'red' }}>*/}
                                    {/*                **/}
                                    {/*            </Typography.Text>{' '}*/}
                                    {/*            Loan Term*/}
                                    {/*        </Typography.Text>*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/* <ProForm.Group direction="horizontal">
                        {currentUser.type !== 'staff' && (
                           
                        )}
                        <ProFormSwitch
                            formItemProps={{
                                labelAlign: 'left',
                            }}
                            proFieldProps={{}}
                            name={'loanPenalty'}
                            label={'Monthly Dues'}
                            checkedChildren={'Enabled'}
                            unCheckedChildren={'Disabled'}
                        />
                    </ProForm.Group> */}
                                    {/* <Row wrap={false} gutter={[12, 0]}>
                        <Col span={12}>
                            {currentUser.type !== 'staff' && (
                                <ProFormDatePicker
                                    name={'startDate'}
                                    label={'Application Date'}
                                    fieldProps={{
                                        format: 'MMMM DD, YYYY',
                                    }}
                                />
                            )}
                        </Col>
                        <Col span={12}>
                            <ProFormSwitch
                                formItemProps={{
                                    labelAlign: 'left',
                                }}
                                proFieldProps={{}}
                                name={'loanPenalty'}
                                label={'Monthly Dues'}
                                checkedChildren={'Enabled'}
                                unCheckedChildren={'Disabled'}
                            />
                        </Col>
                    </Row> */}
                                    {/* <Row wrap={false} gutter={[12, 0]}>
                        <Col span={12}>
                            {currentUser.type !== 'staff' && (
                                <ProFormDatePicker
                                    name={'approvalDate'}
                                    label={'Approval Date'}
                                    fieldProps={{
                                        format: 'MMMM DD, YYYY',
                                    }}
                                />
                            )}
                        </Col>
                        <Col span={12}>
                            {currentUser.type !== 'staff' && (
                                <ProFormDatePicker
                                    name={'disbursementDate'}
                                    label={'Disbursement Date'}
                                    fieldProps={{
                                        format: 'MMMM DD, YYYY',
                                    }}
                                />
                            )}
                        </Col>
                    </Row> */}
                                    <Row wrap={false} gutter={[12, 0]}>
                                        <Col span={12}>
                                            <ProFormSelect
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'This field is required.',
                                                    },
                                                ]}
                                                label={'Loan Type'}
                                                name={'type'}
                                                options={[
                                                    {
                                                        label: 'Personal Loan',
                                                        description:
                                                            'Loan for personal expenses',
                                                        value: 'personal',
                                                    },
                                                    {
                                                        label: 'Business Loan',
                                                        description:
                                                            'Loan for business purposes',
                                                        value: 'business',
                                                    },
                                                    {
                                                        label: 'Agricultural Loan',
                                                        description:
                                                            'Loan for agricultural needs',
                                                        value: 'agricultural',
                                                    },
                                                    {
                                                        label: 'Housing Loan',
                                                        description:
                                                            'Loan for housing-related purposes',
                                                        value: 'housing',
                                                    },
                                                    {
                                                        label: 'Emergency Loan',
                                                        description:
                                                            'Loan for unforeseen financial needs',
                                                        value: 'emergency',
                                                    },
                                                    {
                                                        label: 'Microfinance Loan',
                                                        description:
                                                            'Small loan for income-generating activities',
                                                        value: 'microfinance',
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ProFormDigit
                                                name={'termMonths'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: 'number',
                                                        message:
                                                            'This field is required',
                                                    },
                                                ]}
                                                fieldProps={{
                                                    addonAfter: (
                                                        <Typography.Text>
                                                            months
                                                        </Typography.Text>
                                                    ),
                                                }}
                                                min={1}
                                                width={216}
                                                label={'Loan Terms'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row wrap={false} gutter={[12, 0]}>
                                        <Col span={12}>
                                            <ProFormSelect
                                                width={'sm'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'This field is required.',
                                                    },
                                                ]}
                                                options={interestCalculations}
                                                name="loanInterestType"
                                                fieldProps={{
                                                    style: {
                                                        width: '100%',
                                                    },
                                                }}
                                                label={'Interest Calculation'}
                                                // disabled={disableSelectPayback}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ProFormDatePicker
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'This field is required.',
                                                    },
                                                ]}
                                                name={'startDate'}
                                                label={'Application Date'}
                                                fieldProps={{
                                                    format: 'MMMM DD, YYYY',
                                                    style: {
                                                        width: '100%',
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {/* </ProForm.Group> */}
                            {/* <ProForm.Group title={''}>
                        <ProFormSelect
                            width={'sm'}
                            options={compoundInterestTypeValues}
                            name="compound"
                            label={'Compound'}
                        />
                    </ProForm.Group> */}
                            {/*<Divider orientation={'left'}>Results</Divider>*/}
                            {/*<LoanResults values={initValues} />*/}
                        </div>
                        <div
                            style={{
                                backgroundColor: 'rgba(170, 170, 170, 0.1)',
                            }}
                        >
                            <Divider style={{ margin: 0 }} />
                            <Row style={{ padding: '18px 24px' }}>
                                <Col span={12}>
                                    <Row gutter={[12, 0]}>
                                        <Col>
                                            <Button
                                                loading={loading}
                                                key={'cancel'}
                                                danger
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row justify="end" gutter={[12, 0]}>
                                        <Col>
                                            <Button
                                                loading={loading}
                                                key={'back-to-member'}
                                                onClick={
                                                    handleBackToMemberSelection
                                                }
                                            >
                                                Back
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                loading={loading}
                                                key={'save-as-draft'}
                                                onClick={() => {
                                                    form.submit()
                                                }}
                                                type="primary"
                                                style={{
                                                    backgroundColor:
                                                        styles.primary,
                                                    color: 'white',
                                                }}
                                            >
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </>
                ) : null}
            </ModalForm>
            {/* {previewAmortization.show && ( */}
            {draftLoanData && draftLoanData.amortization && (
                <PreviewLoanAmortizationSchedule
                    handleClose={handleClosePreviewLoanPayment}
                    data={draftLoanData.amortization}
                    member={applyLoanData}
                    // monthlyPayment={monthlyPayment.toFixed(2)}
                    // numberOfPayments={numberOfPayments}
                    // totalPayment={totalPayment.toFixed(2)}
                    // totalInterest={totalInterest.toFixed(2)}
                    // tableData={tableData}
                />
            )}
            {/* )} */}
        </>
    )
}

export default AddLoan
