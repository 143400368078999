import {
    Card,
    Col,
    Row,
    Typography,
    Button as AntButton,
    Statistic,
    Descriptions,
    Rate,
    Divider,
    List,
    Avatar,
    Space,
} from 'antd'
import React, { useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../Home/common/Button'
import {
    ProForm,
    ProFormSelect,
    ProFormText,
    // ProFormSelect,
} from '@ant-design/pro-components'
import { Product, StoreProduct } from '../../../domain/entities/Store'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { LikeOutlined, UserOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet-async'
import { useAppSelector } from '../../store'
// import moment from 'moment'

const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
)

// const data = Array.from({ length: 23 }).map((_, i) => ({
//     href: 'https://ant.design',
//     title: `ant design part ${i}`,
//     avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
//     rating: 4,
//     creation: moment().unix(),
//     modified: moment().unix(),
//     description:
//         'Ant Design, a design language for background applications, is refined by Ant UED Team.',
//     content:
//         'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
// }))
// Sample full names for generating random names
const fullNames = [
    'John Doe',
    'Emily Smith',
    'Alex Johnson',
    'Sophia Williams',
    'Michael Brown',
    'Olivia Davis',
    'William Garcia',
    'Ava Rodriguez',
    'James Martinez',
    'Charlotte Wilson',
]

// Generating 10 rating objects
const ratingObjects: any = []
const ratingComments = [
    'Super-fast delivery! Received my order in just two days. Impressed with the prompt service.',
    'Great customer support! They were very helpful in resolving an issue with my order quickly and efficiently.',
    'High-quality products! The item I received matches the description perfectly. Very satisfied with the purchase.',
    'Easy returns process. Had to return an item, and the process was hassle-free. Got my refund without any issues.',
    'Excellent variety! The website offers a wide range of products to choose from. Found exactly what I needed.',
    "User-friendly interface. Navigating through the website was a breeze. It's well-organized and easy to find products.",
    'Reliable and trustworthy. Have been shopping here for a while, and they consistently deliver great service and products.',
    'Great deals and discounts! Always on the lookout for their sales. Amazing prices for quality items.',
    'Secure packaging! My order arrived in perfect condition, thanks to the careful packaging.',
    "Frequent updates on order status. Loved the regular updates about my order's progress. It kept me informed.",
]
const numberOfLikes = [25, 42, 18, 55, 37, 63, 29, 48, 21, 34]

export interface Rating {
    title: string
    avatar?: string
    href: string
    rating: number
    content: string
    fullName: string
    numberOfLikes: number
}
for (let i = 0; i < 10; i++) {
    const rating = (Math.random() * (5 - 4) + 4).toFixed(1) // Generating random rating between 4 and 5

    const ratingObject: Rating = {
        title: fullNames[i],
        avatar: undefined,
        href: `Link ${i + 1}`,
        rating: parseFloat(rating), // Converting rating to a float number
        content: ratingComments[i],
        fullName: fullNames[i], // Assigning the generated full name
        numberOfLikes: numberOfLikes[i],
    }

    ratingObjects.push(ratingObject)
}

function ProductPage() {
    const params = useParams()
    const [product, setProduct] = useState<StoreProduct | undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(1)
    const [itemSelected, setItemSelected] = useState<Product | undefined>(
        undefined
    )

    const { teamName } = useAppSelector((state) => ({
        teamName: state.team.selectedTeamData.name || '',
    }))
    // eslint-disable-next-line no-console
    console.log(ratingObjects)
    const getProduct = async () => {
        setLoading(true)
        try {
            const resp = await fetch(
                `https://getproductinformation-azkdhzpsoa-uc.a.run.app`,
                {
                    body: new URLSearchParams({
                        productId: params.productId || '',
                    }),
                    method: 'POST',
                }
            )
            // eslint-disable-next-line no-console
            console.log(resp)
            // let responseData: any = await response()
            const data = await resp.json()
            // eslint-disable-next-line no-console
            console.log(data, resp)
            setProduct(data)
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err)
        }
        setLoading(false)
    }
    useEffect(() => {
        if (!params.productId) return
        getProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])
    useEffect(() => {
        form.setFieldsValue({
            quantity: 1,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const navigate = useNavigate()
    const [form] = ProForm.useForm()
    const handleSubmit = async (data: any) => {
        const payload = {
            ...product,
            ...data,
            ...itemSelected,
            productId: params.productId,
            quantity,
        }
        // eslint-disable-next-line no-console
        console.log(payload)
        navigate(`/store/${params.productId}/order`, {
            state: payload,
        })
    }
    // eslint-disable-next-line no-console
    console.log(window.location, product)

    return (
        <div style={{ backgroundColor: 'rgb(241, 242, 243)' }}>
            <br />
            <br />
            <Row>
                <Col span={24}>
                    <Row justify={'center'}>
                        <Col lg={16} xs={24}>
                            <Card loading={loading} bodyStyle={{ padding: 0 }}>
                                {product && (
                                    <div
                                        className="page-layout"
                                        style={{ width: '100%' }}
                                    >
                                        <Helmet
                                            async
                                            title={`${teamName}-${product.title}`}
                                            defaultTitle={`${teamName}-${product.title}`}
                                            link={[
                                                <link
                                                    rel="canonical"
                                                    href={product.coverImage}
                                                />,
                                                <link
                                                    rel="icon"
                                                    href={product.coverImage}
                                                />,
                                            ]}
                                        >
                                            <title>
                                                {`${teamName}-${product.title}`}
                                            </title>

                                            <link
                                                rel="icon"
                                                href={product.coverImage}
                                            />

                                            <link
                                                rel="canonical"
                                                href={product.coverImage}
                                            />
                                            <meta
                                                property="og:url"
                                                content={window.location.href}
                                            />
                                            <meta
                                                property="og:title"
                                                content={product.title}
                                            />
                                            <meta
                                                property="og:description"
                                                content={product.description}
                                            />
                                            <meta
                                                property="og:image"
                                                content={product.coverImage}
                                            />
                                        </Helmet>
                                        <br />
                                        <ProForm
                                            form={form}
                                            submitter={false}
                                            onFinish={handleSubmit}
                                        >
                                            <Row gutter={[12, 12]}>
                                                <Col lg={10} xs={24}>
                                                    <Carousel
                                                        showThumbs
                                                        showArrows
                                                    >
                                                        {product &&
                                                            product.images &&
                                                            product.images.map(
                                                                (url) => (
                                                                    <div
                                                                        style={{
                                                                            maxHeight: 500,
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                url
                                                                            }
                                                                            alt={
                                                                                url
                                                                            }
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                    </Carousel>
                                                </Col>
                                                <Col lg={14} xs={24}>
                                                    <div
                                                        style={{ padding: 24 }}
                                                    >
                                                        <Typography.Title
                                                            style={{
                                                                fontSize:
                                                                    '1.4rem',
                                                            }}
                                                        >
                                                            {product?.title}
                                                        </Typography.Title>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Row
                                                                    align={
                                                                        'middle'
                                                                    }
                                                                    wrap={false}
                                                                >
                                                                    <Col>
                                                                        <Row
                                                                            style={{
                                                                                height: '100%',
                                                                                marginBottom: 5,
                                                                            }}
                                                                            gutter={[
                                                                                24,
                                                                                24,
                                                                            ]}
                                                                            align={
                                                                                'middle'
                                                                            }
                                                                            wrap={
                                                                                false
                                                                            }
                                                                        >
                                                                            <Col>
                                                                                <Statistic
                                                                                    value={
                                                                                        (product &&
                                                                                            product.sellerRatings) ||
                                                                                        0
                                                                                    }
                                                                                    precision={
                                                                                        1
                                                                                    }
                                                                                    valueStyle={{
                                                                                        marginTop: 6,
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <Rate
                                                                                    allowHalf
                                                                                    value={
                                                                                        (product &&
                                                                                            product.sellerRatings) ||
                                                                                        0
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col>
                                                                        <div
                                                                            style={{
                                                                                height: '100%',
                                                                            }}
                                                                        >
                                                                            <Divider
                                                                                type="vertical"
                                                                                style={{
                                                                                    height: 30,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {(product &&
                                                                            product.sellerReviews) ||
                                                                            ''}
                                                                        {'+ '}
                                                                        Reviews
                                                                    </Col>
                                                                    <Col>
                                                                        <div
                                                                            style={{
                                                                                height: '100%',
                                                                            }}
                                                                        >
                                                                            <Divider
                                                                                type="vertical"
                                                                                style={{
                                                                                    height: 30,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {(product &&
                                                                            product.sellerProductsSold) ||
                                                                            ''}
                                                                        {'+ '}
                                                                        Sold
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Divider />
                                                        <Row
                                                            wrap={false}
                                                            gutter={[24, 24]}
                                                        >
                                                            <Col
                                                                span={4}
                                                                xs={0}
                                                            >
                                                                <Typography.Paragraph type="secondary">
                                                                    Shipping
                                                                </Typography.Paragraph>
                                                            </Col>
                                                            <Col flex={1}>
                                                                <div
                                                                    style={{
                                                                        minHeight: 100,
                                                                    }}
                                                                >
                                                                    <Descriptions
                                                                        layout={
                                                                            'horizontal'
                                                                        }
                                                                        colon={
                                                                            false
                                                                        }
                                                                        style={{
                                                                            textAlign:
                                                                                'left',
                                                                        }}
                                                                    >
                                                                        <Descriptions.Item
                                                                            label={
                                                                                'Ships From'
                                                                            }
                                                                            labelStyle={{
                                                                                color: 'rgba(0, 0, 0, 0.45)',
                                                                                width: 110,
                                                                            }}
                                                                            span={
                                                                                24
                                                                            }
                                                                        >
                                                                            {product &&
                                                                                product.sellerAddress}
                                                                        </Descriptions.Item>
                                                                        <Descriptions.Item
                                                                            label={
                                                                                'Shipping Fee'
                                                                            }
                                                                            labelStyle={{
                                                                                color: 'rgba(0, 0, 0, 0.45)',
                                                                                width: 110,
                                                                            }}
                                                                            span={
                                                                                24
                                                                            }
                                                                        >
                                                                            ₱0 -
                                                                            Free
                                                                        </Descriptions.Item>
                                                                    </Descriptions>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={[24, 24]}>
                                                            <Col md={4} xs={24}>
                                                                <Typography.Paragraph
                                                                    type="secondary"
                                                                    style={{
                                                                        marginBottom: 0,
                                                                    }}
                                                                >
                                                                    Color/Variant
                                                                </Typography.Paragraph>
                                                            </Col>
                                                            <Col flex={1}>
                                                                <div
                                                                    style={{
                                                                        minHeight: 100,
                                                                    }}
                                                                >
                                                                    <ProFormSelect
                                                                        name="color"
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    true,
                                                                                message:
                                                                                    'Required',
                                                                            },
                                                                        ]}
                                                                        fieldProps={{
                                                                            size: 'large',
                                                                            onSelect(
                                                                                val,
                                                                                opt
                                                                            ) {
                                                                                // eslint-disable-next-line no-console
                                                                                console.log(
                                                                                    val,
                                                                                    opt
                                                                                )
                                                                                setItemSelected(
                                                                                    opt.extraProps
                                                                                )
                                                                            },
                                                                        }}
                                                                        style={{
                                                                            width: '70%',
                                                                        }}
                                                                        options={
                                                                            product &&
                                                                            product.products &&
                                                                            product?.products.map(
                                                                                (
                                                                                    prd,
                                                                                    idx
                                                                                ) => ({
                                                                                    label: `${prd.productName} - ₱${prd.productPrice}`,
                                                                                    value: idx,
                                                                                    extraProps:
                                                                                        prd,
                                                                                })
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row
                                                            wrap={false}
                                                            gutter={[24, 24]}
                                                            style={{
                                                                minHeight: 80,
                                                            }}
                                                        >
                                                            <Col
                                                                span={4}
                                                                xs={0}
                                                            >
                                                                <Typography.Paragraph type="secondary">
                                                                    Quantity
                                                                </Typography.Paragraph>
                                                            </Col>
                                                            <Col flex={1}>
                                                                <Row
                                                                    align={
                                                                        'middle'
                                                                    }
                                                                    wrap={false}
                                                                    gutter={[
                                                                        12, 12,
                                                                    ]}
                                                                >
                                                                    <Col>
                                                                        <ProFormText
                                                                            name="quantity"
                                                                            addonAfter={
                                                                                <AntButton
                                                                                    type="link"
                                                                                    style={{
                                                                                        border: '1px solid rgba(0,0,0,.09)',
                                                                                    }}
                                                                                    size="large"
                                                                                    onClick={() =>
                                                                                        setQuantity(
                                                                                            (
                                                                                                quanty
                                                                                            ) =>
                                                                                                quanty +
                                                                                                1
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <FaPlus />
                                                                                </AntButton>
                                                                            }
                                                                            addonBefore={
                                                                                <AntButton
                                                                                    type="link"
                                                                                    style={{
                                                                                        border: '1px solid rgba(0,0,0,.09)',
                                                                                    }}
                                                                                    size="large"
                                                                                    onClick={() =>
                                                                                        setQuantity(
                                                                                            (
                                                                                                quanty
                                                                                            ) =>
                                                                                                quanty -
                                                                                                1
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <FaMinus />
                                                                                </AntButton>
                                                                            }
                                                                            lightProps={{
                                                                                style: {
                                                                                    border: '1px solid rgba(0,0,0,.09)',
                                                                                },
                                                                            }}
                                                                            proFieldProps={{
                                                                                mode: 'read',
                                                                            }}
                                                                            formItemProps={{
                                                                                style: {
                                                                                    margin: 0,
                                                                                    fontSize: 24,
                                                                                },
                                                                            }}
                                                                            bordered
                                                                            fieldProps={{
                                                                                size: 'large',
                                                                                value: quantity,
                                                                                style: {
                                                                                    margin: 0,
                                                                                    fontSize: 24,
                                                                                },

                                                                                onChange:
                                                                                    (
                                                                                        val
                                                                                    ) =>
                                                                                        setQuantity(
                                                                                            val
                                                                                                .target
                                                                                                .valueAsNumber ||
                                                                                                0
                                                                                        ),
                                                                            }}
                                                                            placeholder={
                                                                                '0'
                                                                            }
                                                                            style={{
                                                                                margin: 0,
                                                                            }}
                                                                            rules={[
                                                                                {
                                                                                    required:
                                                                                        true,
                                                                                    message:
                                                                                        'Required',
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <Typography.Paragraph
                                                                            type="secondary"
                                                                            style={{
                                                                                margin: 0,
                                                                            }}
                                                                        >
                                                                            <Typography.Text>
                                                                                {itemSelected &&
                                                                                    itemSelected.productQuantity}
                                                                            </Typography.Text>{' '}
                                                                            pieces
                                                                            available
                                                                        </Typography.Paragraph>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Card
                                                            bodyStyle={{
                                                                backgroundColor:
                                                                    '#FAFAFA',
                                                            }}
                                                        >
                                                            <Typography.Text
                                                                style={{
                                                                    fontSize:
                                                                        '1.875rem',
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                <Statistic
                                                                    value={
                                                                        quantity *
                                                                        (itemSelected?.productPrice ||
                                                                            0)
                                                                    }
                                                                    precision={
                                                                        2
                                                                    }
                                                                    prefix={'₱'}
                                                                    valueStyle={{
                                                                        fontSize: 30,
                                                                    }}
                                                                />
                                                            </Typography.Text>
                                                        </Card>
                                                        <br />
                                                        <Button
                                                            type="submit"
                                                            htmlType="submit"
                                                        >
                                                            BUY NOW
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ProForm>
                                    </div>
                                )}
                            </Card>
                            <br />
                            {product && (
                                <Card
                                    bordered
                                    title={'Product Description'}
                                    loading={loading}
                                >
                                    <div
                                        style={
                                            {
                                                // fontSize: 20,
                                                // color: 'rgba(63, 63, 70, 1)',
                                                // fontWeight: 400,
                                            }
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: product?.description || '',
                                        }}
                                    />
                                </Card>
                            )}
                            <br />
                            {
                                <Card
                                    bordered
                                    title={'Product Ratings'}
                                    loading={loading}
                                >
                                    <List
                                        itemLayout="vertical"
                                        size="large"
                                        pagination={{
                                            onChange: (page) => {
                                                // eslint-disable-next-line no-console
                                                console.log(page)
                                            },
                                            pageSize: 3,
                                        }}
                                        dataSource={ratingObjects}
                                        renderItem={(item: Rating) => (
                                            <List.Item
                                                key={item.title}
                                                actions={[
                                                    // <IconText
                                                    //     icon={StarOutlined}
                                                    //     text="156"
                                                    //     key="list-vertical-star-o"
                                                    // />,
                                                    <IconText
                                                        icon={LikeOutlined}
                                                        text={`${item.numberOfLikes}`}
                                                        key="list-vertical-like-o"
                                                    />,
                                                    // <IconText
                                                    //     icon={MessageOutlined}
                                                    //     text="2"
                                                    //     key="list-vertical-message"
                                                    // />,
                                                ]}

                                                // extra={
                                                //     <img
                                                //         width={272}
                                                //         alt="logo"
                                                //         src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                                //     />
                                                // }
                                            >
                                                <List.Item.Meta
                                                    avatar={
                                                        <Avatar
                                                            src={item.avatar}
                                                            icon={
                                                                <UserOutlined />
                                                            }
                                                        />
                                                    }
                                                    title={
                                                        <>
                                                            <a href={item.href}>
                                                                {item.title}
                                                            </a>
                                                            <div>
                                                                <Rate
                                                                    allowHalf
                                                                    value={
                                                                        item.rating
                                                                    }
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    }
                                                    // description={
                                                    //     <div>
                                                    //         <div>
                                                    //             {
                                                    //                 item.description
                                                    //             }
                                                    //         </div>
                                                    //     </div>
                                                    // }
                                                />
                                                {item.content}
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
        </div>
    )
}

export default ProductPage
