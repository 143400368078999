import { db } from '@/app/boot'
import { useAppSelector } from '@/app/store'
import {
    PERSONNEL_ATTENDANCE_REPOSITORY,
    PERSONNEL_REPOSITORY,
    VEHICLE_REPOSITORY,
} from '@/data/repositories/constants'
import { Attendance } from '@/domain/entities/Attendance'
import { Personnel } from '@/domain/entities/Transport'
import { EditFilled, PlusCircleFilled } from '@ant-design/icons'
import { ProForm, ProFormSelect } from '@ant-design/pro-components'
import { Button, Col, List, message, Modal, Row, Space } from 'antd'
import {
    addDoc,
    collection,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
const SchedulingMobileView = () => {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<any>({
        drivers: [],
        assistants: [],
        vehicles: [],
    })
    const [schedules, setSchedules] = useState<any[]>([])
    const [data, setData] = useState<any>({
        drivers: [],
        assistants: [],
        vehicles: [],
    })
    const { email, activeTeam } = useAppSelector(
        (state) => ({
            email: state.auth.user.email,
            activeTeam: state.auth.user.activeTeam,
            drivers: state.team.drivers,
            assistants: state.team.assistants,
            vehiclesEntities: state.vehicle.vehicles,
        }),
        shallowEqual
    )
    const onConfirmAssignment = async (formData: any) => {
        setLoading(true)
        const vehicle = data.vehicles.find(
            (vehicle: any) => vehicle.id === formData.vehicleId
        )
        const driver = data.drivers.find(
            (driver: any) => driver.id === formData.driverId
        )
        const assistant = data.assistants.find(
            (assistant: any) => assistant.id === formData.assistantId
        )

        const payload: Attendance = {
            ...formData,
            withAssistant:
                formData.assistantId !== undefined ||
                formData.assistantId !== '',
            date: moment().format('MMM DD, YYYY'),
            timeIn: 0,
            timeOut: 0,
            vehicleId: formData.vehicleId,
            assistantName: assistant?.displayName || '',
            driverName: driver?.displayName || '',
            creation: moment().unix(),
            modified: moment().unix(),
            partnerCode: activeTeam,
            incentives: 0,
            salary: 0,
            employeeRole: '',
            dispatchingCost: 0,
            status: 'pending',
            driverSalary: 0,
            driverIncentives: 0,
            driverOvertimeHours: 0,
            driverOvertimePay: 0,
            assistantSalary: 0,
            assistantIncentives: 0,
            assistantOvertimeHours: 0,
            assistantOvertimePay: 0,
            currentTripId: vehicle?.activeTripId || '',
            shiftStartTime: 0,
            shiftEndTime: 0,
            modifiedBy: email,
            isRemoved: false,
            unit: vehicle?.title || '',
            routeName: vehicle?.routeName || '',
            totalCashReceived: 0,
            totalExpenses: 0,
            totalGasConsumed: 0,
            totalGasCost: 0,
            totalPassengers: 0,
            totalRevenue: 0,
            totalRoundTrips: 0,
            cardRevenue: 0,
            cashCollected: 0,
            signatureUrl: '',
            assistantSss: '',
            assistantPhilhealth: '',
            assistantPagibig: '',
            driverSss: '',
            driverPhilhealth: '',
            driverPagibig: '',
            overtimePay: 0,
            overtimeHours: 0,
            paymentStatus: 'pending',
            driverStatus: 'pending',
            assistantStatus: 'pending',
            employeeStatus: 'pending',
            isAssistantHalfDay: false,
            isDriverHalfDay: false,
            isEmployeeHalfDay: false,
        }

        const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)
        await addDoc(ref, payload)
        setLoading(false)
        // eslint-disable-next-line no-console
        message.success('Successfully assigned shifts to bus personnels.')
        form.resetFields()
        closeModal()
        // addEventToCalendar()
    }

    const onUpdateAssignment = async (formData: any) => {
        try {
            setLoading(true)
            const ref = doc(db, PERSONNEL_ATTENDANCE_REPOSITORY, updateData.id)
            const data = {
                ...formData,
            }
            await updateDoc(ref, data)
            closeUpdateModal()
        } catch (err: any) {
            message.error(err)
        }
        setLoading(false)
    }
    useEffect(() => {
        const main = async () => {
            const ref = collection(db, PERSONNEL_REPOSITORY)
            const q = await getDocs(
                query(
                    ref,
                    where('partnerCode', '==', activeTeam),
                    where('isRemoved', '==', false),
                    where('role', '==', 'driver')
                )
            )
            const drivers = q.docs.map((docSnap) => {
                const driverData = docSnap.data()
                return {
                    ...driverData,
                    id: docSnap.id,
                } as Personnel
            })
            const refAssistant = collection(db, PERSONNEL_REPOSITORY)
            const qAssistant = await getDocs(
                query(
                    refAssistant,
                    where('partnerCode', '==', activeTeam),
                    where('isRemoved', '==', false),
                    where('role', '==', 'assistant')
                )
            )
            const assistants = qAssistant.docs.map((docSnap) => {
                const assistantData = docSnap.data()
                return {
                    ...assistantData,
                    id: docSnap.id,
                } as Personnel
            })
            const vehiclesRef = collection(db, VEHICLE_REPOSITORY)
            const qVehicles = query(
                vehiclesRef,
                where('partnerCode', '==', activeTeam),
                where('isRemoved', '==', false)
            )
            const vehicleData = await getDocs(qVehicles)
            const vehiclePromises: any = []
            vehicleData.forEach((docData) => {
                vehiclePromises.push({
                    ...docData.data(),
                    id: docData.id,
                })
            })
            setOptions({
                vehicles: vehiclePromises.map((vehicle: any) => ({
                    label: vehicle.title,
                    value: vehicle.id,
                })),
                assistants: assistants.map((assistant) => ({
                    label: assistant.displayName,
                    value: assistant.id,
                })),
                drivers: drivers.map((driver) => ({
                    label: driver.displayName,
                    value: driver.id,
                })),
            })
            setData({
                drivers,
                vehicles: vehiclePromises,
                assistants,
            })
        }
        main()

        const mainSched = async () => {
            const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)
            // eslint-disable-next-line no-console
            // console.log(currentVehicleId);

            const q = query(
                ref,
                where('status', '==', 'pending'),
                where('partnerCode', '==', activeTeam)
            )

            const docs = await getDocs(q)
            const schedulesData: any = []

            docs.forEach((docData) => {
                schedulesData.push({
                    ...docData.data(),
                    id: docData.id,
                })
            })
            setSchedules(schedulesData)
        }
        mainSched()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam])
    const [form] = ProForm.useForm()
    const [updateForm] = ProForm.useForm()

    const [modalSchedule, setModalSchedule] = useState(false)
    const [updateModalSchedule, setUpdateModalSchedule] = useState(false)
    const [updateData, setUpdateData] = useState<any | undefined>({
        driverId: '',
        assistantId: '',
        vehicleId: '',
        id: '',
        title: '',
    })

    const openModal = () => {
        setModalSchedule(true)
    }
    const closeModal = () => {
        setModalSchedule(false)
    }
    const openUpdateModal = () => {
        setUpdateModalSchedule(true)
    }
    const closeUpdateModal = () => {
        setUpdateModalSchedule(false)
        setUpdateData({
            driverId: '',
            assistantId: '',
            vehicleId: '',
            title: '',
            id: '',
        })
    }
    const updateSchedule = (data: any) => {
        openUpdateModal()
        setUpdateData({
            driverId: data.driverId,
            assistantId: data.assistantId,
            vehicleId: data.vehicleId,
            title: `${data.unit} - ${data.date}`,
            id: data.id,
        })
        updateForm.setFieldsValue({
            driverId: data.driverId,
            assistantId: data.assistantId,
            vehicleId: data.vehicleId,
        })
    }

    return (
        <div className="page-layout">
            <Modal
                confirmLoading={loading}
                open={modalSchedule}
                onCancel={closeModal}
                footer={false}
            >
                <ProForm form={form} onFinish={onConfirmAssignment}>
                    <ProFormSelect
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        label="Bus"
                        name="vehicleId"
                        options={options.vehicles}
                    />
                    <ProFormSelect
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        label="Driver"
                        name="driverId"
                        options={options.drivers}
                    />
                    <ProFormSelect
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        label="PAO"
                        name="assistantId"
                        options={options.assistants}
                    />
                </ProForm>
            </Modal>

            <Modal
                confirmLoading={loading}
                open={updateModalSchedule}
                onCancel={closeUpdateModal}
                title={`Update: ${updateData?.title || ''}`}
                footer={false}
            >
                <ProForm form={updateForm} onFinish={onUpdateAssignment}>
                    <ProFormSelect
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        label="Bus"
                        name="vehicleId"
                        options={options.vehicles}
                    />
                    <ProFormSelect
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        label="Driver"
                        name="driverId"
                        options={options.drivers}
                    />
                    <ProFormSelect
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        label="PAO"
                        name="assistantId"
                        options={options.assistants}
                    />
                </ProForm>
            </Modal>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <Button
                                icon={<PlusCircleFilled />}
                                onClick={openModal}
                            >
                                New Schedule
                            </Button>
                            <br />
                            <br />
                            <List
                                dataSource={schedules}
                                bordered
                                renderItem={(item) => {
                                    return (
                                        <List.Item
                                            key={item.id}
                                            actions={[
                                                <Button
                                                    type="primary"
                                                    key={'edit'}
                                                    icon={<EditFilled />}
                                                    onClick={() =>
                                                        updateSchedule(item)
                                                    }
                                                />,
                                            ]}
                                        >
                                            <List.Item.Meta
                                                title={
                                                    <div>
                                                        {item.unit} -{' '}
                                                        {moment
                                                            .unix(item.creation)
                                                            .format(
                                                                'MMM DD, YYYY'
                                                            )}
                                                    </div>
                                                }
                                                description={
                                                    <div>
                                                        {item.driverName} -{' '}
                                                        {item.assistantName}
                                                    </div>
                                                }
                                            />
                                        </List.Item>
                                    )
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default SchedulingMobileView
