import { ProForm, ProFormText } from '@ant-design/pro-components'
import { Col, Row } from 'antd'

const DeleteAccountRequest = () => {
    return (
        <div className="page-layout">
            <Row justify={'center'}>
                <Col>
                    <ProForm>
                        <ProFormText name="email" label="Email" />
                    </ProForm>
                </Col>
            </Row>
        </div>
    )
}

export default DeleteAccountRequest
