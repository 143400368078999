import styled from 'styled-components'

export const StyledContainer = styled('div')`
    display: inline-block;
    width: 100%;
    padding: 10px 5px;
    margin-bottom: -0.625rem;
`

export const StyledTextArea = styled('textarea')`
    resize: none;
    font-size: 0.875rem;
    height: 185px;
`

export const Label = styled('label')`
    display: block;
    padding-bottom: 10px;
    text-transform: capitalize;
`
