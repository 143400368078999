import { createAsyncThunk } from '@reduxjs/toolkit'
import { BorrowFirebaseImplementation } from '../../../data/repositories/borrow/borrow.firebase'
import { Borrow, BorrowerPayment } from '../../../domain/entities/Borrow'
import { BorrowService } from '../../../domain/usecases/borrow.usecase'

export const borrow = createAsyncThunk(
    'borrow/borrow',
    async (payload: Borrow) => {
        const repo = new BorrowFirebaseImplementation()
        const service = new BorrowService(repo)
        return await service.borrow(payload)
    }
)

export const getBorrowers = createAsyncThunk(
    'borrow/getBorrowers',
    async (
        payload:
            | {
                  partnerCode: string
              }
            | string
    ) => {
        const repo = new BorrowFirebaseImplementation()
        const service = new BorrowService(repo)
        return await service.getBorrowers(payload)
    }
)

export const updateBorrower = createAsyncThunk(
    'borrow/updateBorrower',
    async (payload: { id: string; borrower: Partial<Borrow> }) => {
        const repo = new BorrowFirebaseImplementation()
        const service = new BorrowService(repo)
        return await service.updateBorrower(payload.id, payload.borrower)
    }
)

export const deleteBorrower = createAsyncThunk(
    'borrow/deleteBorrower',
    async (payload: Borrow) => {
        const repo = new BorrowFirebaseImplementation()
        const service = new BorrowService(repo)
        return await service.deleteBorrower(payload)
    }
)

export const payBorrower = async (payload: BorrowerPayment) => {
    const repo = new BorrowFirebaseImplementation()
    const service = new BorrowService(repo)
    return await service.payBorrower(payload)
}

export const getPayments = async (id: string) => {
    const repo = new BorrowFirebaseImplementation()
    const service = new BorrowService(repo)
    return await service.getPayments(id)
}

export const deleteBorrowerPayment = createAsyncThunk(
    'borrow/deleteBorrowerPayment',
    async (payload: { borrowerId: string; payment: BorrowerPayment }) => {
        const repo = new BorrowFirebaseImplementation()
        const service = new BorrowService(repo)
        return await service.deleteBorrowerPayment(payload)
    }
)
