// import { ProCard } from '@ant-design/pro-components'
import { useEffect, useState } from 'react'
import { Loan } from '../../../../domain/entities/Loan'
import { updateLoanState } from '../../../redux/loans/loans.slice'
// import { Col, Grid, Row, Statistic, Typography } from 'antd'
import LoanTable from './components/LoanTable'
import ViewLoanPayments from '../../Member/loans/ViewLoanPayments'
import { useAppDispatch, useAppSelector } from '../../../store'
import ApplyLoan from '../../Member/loans/ApplyLoan'
// import { MemberReceivableReportSummary } from '../MembersReceivableReport/MembersReceivableReport'
import { shallowEqual } from 'react-redux'
// import { getSystemConfig } from '../../../redux/system.slice'
// import moment from 'moment'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { db } from '../../../boot'
// import { getTeamMembers } from '../../../redux/team/team.action'

export interface MemberLoan extends Loan {
    name: string
    remainingBalance: number
}

function LoansReport() {
    const { loans, activeTeam } = useAppSelector(
        (state) => ({
            loans: state.loans.loans,
            activeTeam: state.auth.user.activeTeam,
        }),
        shallowEqual
    )
    const [filteredLoans, setFilteredLoans] = useState<Loan[]>([])
    // const { xs } = Grid.useBreakpoint()

    const dispatch = useAppDispatch()

    useEffect(() => {
        setFilteredLoans(loans)
    }, [loans])

    useEffect(() => {
        if (!activeTeam) return
        // dispatch(getTeamSavings(activeTeam))
        const ref = collection(db, 'loans')
        const q = query(
            ref,
            where('partnerCode', '==', activeTeam),
            where('isRemoved', '==', false)
        )
        const unsub = onSnapshot(q, (doc) => {
            const data = doc.docs.map((docSnap) => ({
                ...docSnap.data(),
                id: docSnap.id,
            }))
            const uniqueObjects = data.filter(
                (obj, index, self) =>
                    index === self.findIndex((o) => o.id === obj.id)
            )
            dispatch(
                updateLoanState({
                    name: 'loans',
                    value: uniqueObjects,
                })
            )
        })

        return () => unsub()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam])
    return (
        <div className="page-layout">
            <ApplyLoan />
            <ViewLoanPayments />

            <LoanTable dataSource={filteredLoans} />
        </div>
    )
}

export default LoansReport
