import { PlusOutlined } from '@ant-design/icons'
import { Row, Col, Upload, Button } from 'antd'
import React, { useState } from 'react'
import { SettingsTitle, SettingsDescription } from './template'
import { storage } from '../../../boot'
import { updateTeam } from '../../../../app/redux/team/team.action'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../../../app/store'
import { shallowEqual } from 'react-redux'

function ChangeBranchLogo({ activeTeam }: { activeTeam: string }) {
    const { logoUrl, backgroundUrl, email } = useAppSelector(
        (state) => ({
            logoUrl: state.team.selectedTeamData.logoUrl,
            backgroundUrl: state.team.selectedTeamData.backgroundUrl,
            email: state.auth.user.email,
        }),
        shallowEqual
    )
    const [fileUrl, setFileUrl] = useState(logoUrl)
    const [backUrl, setBackgroundUrl] = useState(backgroundUrl)
    const dispatch = useAppDispatch()
    const handleUploadChange = async (files: any, fieldName: any) => {
        const logoRef = ref(
            storage,
            `/${activeTeam}/${moment().unix()}/${files.file.name}`
        )
        const uploadTask = uploadBytesResumable(
            logoRef,
            files.file.originFileObj
        )
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                switch (snapshot.state) {
                    case 'paused':
                        // eslint-disable-next-line no-console
                        console.log('Upload is paused')
                        break
                    case 'running':
                        // eslint-disable-next-line no-console
                        console.log('Upload is running')
                        break
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then(
                    async (downloadURL) => {
                        if (fieldName === 'backgroundUrl') {
                            setBackgroundUrl(downloadURL)
                        } else {
                            setFileUrl(downloadURL)
                        }
                        await dispatch(
                            updateTeam({
                                [fieldName]: downloadURL,
                                modified: moment().unix(),
                                modifiedBy: email,
                                id: activeTeam,
                            })
                        )
                    }
                )
            }
        )
    }
    return (
        <div>
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <Row>
                        <Col span={24}>
                            <SettingsTitle>Logo</SettingsTitle>
                            <SettingsDescription>
                                Image to be displayed in landing page.
                            </SettingsDescription>
                            {fileUrl && (
                                <img
                                    style={{
                                        width: 'auto',
                                        maxHeight: 200,
                                        border: '1px solid #f3f3f3',
                                    }}
                                    src={fileUrl}
                                    alt={'logo company'}
                                    // src={"https://skillings-bucket-sea.s3.ap-southeast-1.amazonaws.com/company/1j1tifokn"}
                                />
                            )}
                            <br />
                            <Row>
                                <Upload
                                    multiple={false}
                                    accept="image/*"
                                    onChange={(info) =>
                                        handleUploadChange(info, 'logoUrl')
                                    }
                                    listType={'picture'}
                                    showUploadList={false}
                                >
                                    <Button>
                                        <PlusOutlined />{' '}
                                        {fileUrl ? 'Replace' : 'Upload'}
                                    </Button>
                                </Upload>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <Row>
                        <Col span={24}>
                            <SettingsTitle>Background Image</SettingsTitle>
                            <SettingsDescription>
                                Image to be displayed in landing page.
                            </SettingsDescription>
                            {backUrl && (
                                <img
                                    style={{
                                        width: 'auto',
                                        maxHeight: 200,
                                        border: '1px solid #f3f3f3',
                                    }}
                                    src={backUrl}
                                    alt={'background company'}
                                    // src={"https://skillings-bucket-sea.s3.ap-southeast-1.amazonaws.com/company/1j1tifokn"}
                                />
                            )}
                            <br />
                            <Row>
                                <Upload
                                    multiple={false}
                                    accept="image/*"
                                    onChange={(info) =>
                                        handleUploadChange(
                                            info,
                                            'backgroundUrl'
                                        )
                                    }
                                    listType={'picture'}
                                    showUploadList={false}
                                >
                                    <Button>
                                        <PlusOutlined />{' '}
                                        {fileUrl ? 'Replace' : 'Upload'}
                                    </Button>
                                </Upload>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default ChangeBranchLogo
