import React, { useEffect, useState } from 'react'
import {
    ModalForm,
    ProFormDatePicker,
    // ProFormDateTimePicker,
    // ProFormDigit,
    ProFormSelect,
    ProFormTextArea,
    ProFormTimePicker,
} from '@ant-design/pro-components'
import {
    Button,
    Card,
    Col,
    Form,
    // List,
    message,
    Row,
    Typography,
} from 'antd'
import moment from 'moment'
import { shallowEqual } from 'react-redux'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import {
    TRIPS_REPOSITORY,
    VEHICLE_REPOSITORY,
} from '../../../../data/repositories/constants'
import { db } from '../../../boot'
import { useAppSelector } from '../../../store'
import { Trip } from '../../../../domain/entities/Transport'

function TripContainer() {
    const {
        // categories,
        email,
        styles,
        activeTeam,
        drivers,
        assistants,
        data,
        selectedVehicleId,
    } = useAppSelector(
        (state) => ({
            showNewExpense: state.systemConfig.showNewExpense,
            // categories: state.systemConfig.tripCategories,
            email: state.auth.user.email,
            styles: state.styles,
            activeTeam: state.team.selectedTeamData.partnerCode,
            drivers: state.team.drivers,
            assistants: state.team.assistants,

            data: state.vehicle.selectedVehicleId
                ? state.vehicle.vehicles.entities[
                      state.vehicle.selectedVehicleId
                  ]
                : undefined,
            selectedVehicleId: state.vehicle.selectedVehicleId,
            // categories: state.team.selectedTeamData.tripCategories,
        }),
        shallowEqual
    )
    // const [tripCategories, setExpenseCategories] = useState<any>([])

    // const [tripsLabelKey, setExpensesLabelKey] = useState<any>([])
    // const [, setName] = useState('')
    const [availableSched, setAvailableSched] = useState<any>(undefined)
    const [loading, setLoading] = useState(false)
    // const dispatch = useAppDispatch()

    const [form] = Form.useForm()
    // const handleFieldChange = (field: any) => {
    //     const key = Object.keys(field)[0]
    //     if (key === 'loanSelection') {
    //         setSelectedLoan(field[key])
    //     }
    // }
    const handleCancel = () => {
        // dispatch(
        //     updateSystemState({
        //         showNewExpense: false,
        //     })
        // )
        setOpen(false)
    }

    const handleSubmit = async (datum: any, status?: Trip['status']) => {
        // setLoading(true)
        const {
            date,
            driverId,
            assistantId,
            notes,
            shiftStartTime,
            hours,
            totalCashReceived,
            overtimeHours,
        } = datum
        const dt = moment(date)
        const strt = moment(shiftStartTime, 'h:mmA')
            .month(dt.month())
            .date(dt.date())
        const payload: Trip = {
            creation: moment().unix(),
            modified: moment().unix(),
            timeOut: '',
            startOvertime: '',
            endOvertime: '',
            totalRoundTrips: 0,
            paymentStatus: 'pending',
            status: status || 'active',
            amountCollected: 0,
            isRemoved: false,
            modifiedBy: email,
            partnerCode: activeTeam,
            timeIn: '',
            date: moment.isMoment(date)
                ? date.format('ddd, MMMM DD, YYYY')
                : moment(date).format('ddd, MMMM DD, YYYY'),
            driverId,
            assistantId: assistantId || '',
            vehicleId: data?.id as string,
            notes,
            driverPayoutStatus: 'pending',
            assistantPayoutStatus: 'pending',
            hours,
            shiftStartTime: strt.unix(),
            totalGasConsumed: 0,
            totalGasCost: 0,
            distanceTraveled: 0,
            totalPassengers: 0,
            shiftEndTime: 0,
            totalExpenses: 0,
            cashCollected: 0,
            miscellaneousExpenses: 0,
            maintenanceFee: 0,
            totalRevenue: 0,
            cardRevenue: 0,
            driverSalary: 0,
            assistantSalary: 0,
            dailyQuotaIncentives: 0,
            dispatchingCost: 0,
            driverAllowance: 0,
            assistantAllowance: 0,
            shiftHistory: [],
            totalCashReceived: totalCashReceived || 0,
            overtimeHours: overtimeHours || 0,
            driverIncentives: 0,
            driverOvertimeHours: 0,
            driverOvertimePay: 0,
            assistantIncentives: 0,
            assistantOvertimeHours: 0,
            assistantOvertimePay: 0,
            withAssistant: false,
            isAssistantHalfDay: false,
            isDriverHalfDay: false,
            isEmployeeHalfDay: false,
        }
        const resp = await addDoc(collection(db, TRIPS_REPOSITORY), payload)
        if (payload.vehicleId && payload.status === 'active') {
            await updateDoc(doc(db, VEHICLE_REPOSITORY, payload.vehicleId), {
                activeTripId: resp.id,
                status: 'occupied',
                modified: moment().unix(),
                modifiedBy: email,
            })
        }
        // await dispatch(addExpense(payload))
        setLoading(false)
        form.resetFields()
        message.success('Trip successfully added')
        handleCancel()
    }

    const [open, setOpen] = useState(false)

    const handleShowAssignPersonnel = () => {
        if (availableSched) {
            form.setFieldsValue({
                assistantId: availableSched.assistantId,
                driverId: availableSched.driverId,
                date: moment(availableSched.date, 'MMM DD, YYYY'),
            })
        }
        setOpen(true)
    }
    // const handleReserve = async () => {
    //     await form.validateFields()
    //     const values = form.getFieldsValue()
    //     const payload = {
    //         ...values,
    //     }
    //     handleSubmit(payload, 'waiting')
    // }
    const filterReservationsByDate = (
        reservations: any[],
        unixTimestamp: number
    ) => {
        const targetDate = moment.unix(unixTimestamp).startOf('day')
        let driverId: any
        let assistantId: any
        let driverName = ''
        let assistantName = ''
        const filtered = reservations.filter((reservation) => {
            const start = moment(reservation.start).startOf('day')
            const end = moment(reservation.end).startOf('day')

            return start.isSame(targetDate) || end.isSame(targetDate)
        })
        const dateFrmt = targetDate.format('-YYYY-MM-DD')

        filtered.forEach((res) => {
            if (res.color !== '#CAC601' && !driverId) {
                driverId = res.custom.replace(dateFrmt, '')
                driverName = res.title
            }
        })
        filtered.forEach((res) => {
            if (res.color === '#CAC601' && !assistantId) {
                assistantId = res.custom.replace(dateFrmt, '')
                assistantName = res.title
            }
        })

        return {
            date: targetDate.format('MMM DD, YYYY'),
            driverId,
            assistantId,
            title: driverName + ' - ' + assistantName,
            assistantName,
            driverName,
        }
    }
    useEffect(() => {
        if (!data || !data.reservations || data.reservations.length === 0) {
            setAvailableSched(undefined)
            return
        }
        const filtered = filterReservationsByDate(
            data?.reservations as any[],
            moment().unix()
        )
        setAvailableSched(filtered)
    }, [data, selectedVehicleId])
    return (
        <div>
            <Card
            // actions={[
            //     <Skeleton.Button
            //         shape="round"
            //         size="large"
            //         key="start"
            //         style={{ color: '#52c41a', borderColor: '#52c41a' }}
            //     />,
            //     <Skeleton.Button shape="round" size="large" key="start" />,
            //     <Skeleton.Button shape="round" size="large" key="start" />,
            // ]}
            >
                {/* <Row>
                    <Col span={12}>
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Skeleton.Avatar size="large" />}
                                title={<Skeleton.Input />}
                                description={
                                    <div>
                                        <Row>
                                            <Skeleton.Input size="small" />
                                        </Row>
                                    </div>
                                }
                            />
                        </List.Item>
                    </Col>
                    <Col span={12}>
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Skeleton.Avatar size="large" />}
                                title={<Skeleton.Input />}
                                description={
                                    <div>
                                        <Row>
                                            <Skeleton.Input size="small" />
                                        </Row>
                                    </div>
                                }
                            />
                        </List.Item>
                    </Col>
                </Row> */}
                {availableSched ? (
                    <div>
                        <Typography.Title
                            level={3}
                            style={{ textAlign: 'center', marginBottom: 5 }}
                        >
                            Reserved Shift
                        </Typography.Title>
                        <Typography.Paragraph style={{ textAlign: 'center' }}>
                            Assign readily avilable shift. Assigning employee is
                            essential for maintaining efficient transportation
                            services.
                        </Typography.Paragraph>
                        <Row justify={'center'} gutter={[12, 12]}>
                            <Col
                                className="fc-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2"
                                style={
                                    {
                                        // backgroundColor: event.color,
                                        // borderColor: event.color,
                                        // cursor: 'pointer',
                                    }
                                }
                            >
                                <div
                                    // data-color={event.}
                                    // data-custom={event.custom}
                                    style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        padding: '10px 12px',
                                        borderRadius: 8,
                                    }}
                                >
                                    <div className="fc-event-main">
                                        <div>
                                            <strong>{`${availableSched.driverName}`}</strong>
                                        </div>
                                        <span
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            Driver
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col className="fc-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2">
                                <div
                                    style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        padding: '10px 12px',
                                        borderRadius: 8,
                                    }}
                                >
                                    <div className="fc-event-main">
                                        <div>
                                            <strong>{`${availableSched.assistantName}`}</strong>
                                        </div>
                                        <span
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            Assistant
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div>
                        <Typography.Title
                            level={3}
                            style={{ textAlign: 'center', marginBottom: 5 }}
                        >
                            Assign a personnel to this vehicle!
                        </Typography.Title>
                        <Typography.Paragraph style={{ textAlign: 'center' }}>
                            Assigning a personnel is essential for maintaining
                            efficient transportation services.
                        </Typography.Paragraph>
                    </div>
                )}

                <br />
                <Row justify={'center'}>
                    <Col>
                        <Button
                            size="large"
                            onClick={handleShowAssignPersonnel}
                            shape="round"
                            style={{
                                backgroundColor: styles.primary,
                                color: 'white',
                            }}
                        >
                            Assign now
                        </Button>
                    </Col>
                </Row>
            </Card>

            <ModalForm<Trip>
                form={form}
                onFinishFailed={(err) => {
                    if (err.errorFields.length) {
                        const firstError = err.errorFields[0]
                        message.destroy()
                        message.error(firstError.errors)
                        form.scrollToField(firstError.name, {
                            behavior: 'smooth',
                            block: 'center',
                        })
                    }
                }}
                title={
                    <div>
                        <Typography.Title level={3}>
                            {data?.title} | {data?.plate}{' '}
                        </Typography.Title>
                    </div>
                }
                open={open}
                autoFocusFirstInput
                modalProps={{
                    onCancel: handleCancel,
                    okText: 'Submit and Start',
                    centered: true,
                    onOk: () => {
                        form.submit()
                    },
                }}
                onFinish={handleSubmit}
                initialValues={{
                    notes: '',
                    date: moment(),
                    shiftStartTime: moment('5:00AM', 'h:mmA'),
                }}
                width={500}
                submitter={{
                    submitButtonProps: {
                        // style: {
                        //     backgroundColor: styles.primary,
                        //     color: 'white',
                        // },
                        loading: loading,
                        onClick: () => {
                            form.submit()
                        },
                    },
                    resetButtonProps: {
                        danger: true,
                    },
                    render(props, dom) {
                        const cancel = dom[0]
                        const submit = dom[1]
                        return [
                            cancel,
                            // <Button loading={loading} onClick={handleReserve}>
                            //     Save as Reservation
                            // </Button>,
                            submit,
                        ]
                    },
                }}

                // onValuesChange={handleFieldChange}
            >
                {/* <ProFormText
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                    name="title"
                    label="Title"
                    placeholder="Please Enter"
                /> */}

                <Row wrap={false} gutter={[24, 24]}>
                    <Col span={12}>
                        <ProFormDatePicker
                            name={'date'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            label={'Date'}
                            fieldProps={{
                                format: 'MMMM DD, YYYY',
                                style: {
                                    width: '100%',
                                },
                                minuteStep: 30,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormTimePicker
                            name={'shiftStartTime'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            label={'Vehicle Departure'}
                            fieldProps={{
                                format: 'h:mmA',
                                style: {
                                    width: '100%',
                                },
                                minuteStep: 15,
                                use12Hours: true,
                                showSecond: false,
                            }}
                        />
                        {/* <ProFormDigit
                            name="hours"
                            label="Total shift hours"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        /> */}
                    </Col>
                </Row>
                <Row wrap={false} gutter={[24, 24]}>
                    <Col span={12}>
                        <ProFormSelect
                            style={{
                                width: '100%',
                            }}
                            placeholder={'Select driver'}
                            label={'Driver'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            name={'driverId'}
                            options={drivers.map((driver) => ({
                                value: driver.id,
                                label:
                                    driver.displayName ||
                                    driver.firstName + ' ' + driver.lastName,
                            }))}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSelect
                            style={{
                                width: '100%',
                            }}
                            placeholder={'Select Assistant'}
                            label={'Assistant'}
                            name={'assistantId'}
                            options={assistants.map((driver) => ({
                                value: driver.id,
                                label:
                                    driver.displayName ||
                                    driver.firstName + ' ' + driver.lastName,
                            }))}
                        />
                    </Col>
                </Row>
                <ProFormTextArea
                    name={'notes'}
                    label={'Notes'}
                    style={{
                        width: '100%',
                    }}
                />
            </ModalForm>
        </div>
    )
}

export default TripContainer
