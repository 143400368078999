import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, message, Row, TimePicker } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../store'
import { shallowEqual } from 'react-redux'
import { SettingsDescription, SettingsTitle } from './template'
import moment, { Moment } from 'moment'
import { updateTeam } from '../../../../app/redux/team/team.action'

function HR() {
    const dispatch = useAppDispatch()

    const { styles, hr, partnerCode, email } = useAppSelector(
        (state) => ({
            partnerCode: state.team.selectedTeamData.partnerCode,
            email: state.auth.user.email,
            shareDividendFrequency: state.systemConfig.shareDividendFrequency,
            uniformLoanInterest: state.systemConfig.uniformLoanInterest,
            notice: state.systemConfig.notice,
            hr: state.team.selectedTeamData.hr,
            styles: state.styles,
        }),
        shallowEqual
    )
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState<{
        firstShiftIn: Moment | undefined
        firstShiftOut: Moment | undefined
        officeShiftIn: Moment | undefined
        officeShiftOut: Moment | undefined
    }>({
        firstShiftIn: undefined,
        firstShiftOut: undefined,
        officeShiftIn: undefined,
        officeShiftOut: undefined,
    })

    // const handleChange = (val: number) => {
    //     setValues({
    //         ...values,
    //         shareDividendFrequency: val,
    //     })
    // }
    //
    // const handleToggleUniformLoanInterest = (val: boolean) => {
    //     dispatch(
    //         updateSystemConfig({
    //             shareDividendFrequency: val,
    //             notice: {
    //                 type: 'info',
    //                 description:
    //                     'Interests for loan application can be edited. This will only affect incoming loan application, it will NOT affect PREVIOUS loans.',
    //                 message: 'Loan interest uniformity was changed.',
    //                 show: true,
    //             },
    //         })
    //     )
    // }

    const handleSave = async () => {
        setLoading(true)
        // eslint-disable-next-line no-console
        console.log(partnerCode, {
            firstShiftIn: values.firstShiftIn?.format('HH:mm') || '',
            firstShiftOut: values.firstShiftOut?.format('HH:mm') || '',
            officeShiftIn: values.officeShiftIn?.format('HH:mm') || '',
            officeShiftOut: values.officeShiftOut?.format('HH:mm') || '',
        })

        await dispatch(
            updateTeam({
                // shareDividendFrequency: value,
                id: partnerCode,
                hr: {
                    firstShiftIn: values.firstShiftIn?.format('HH:mm') || '',
                    firstShiftOut: values.firstShiftOut?.format('HH:mm') || '',
                    officeShiftIn: values.officeShiftIn?.format('HH:mm') || '',
                    officeShiftOut:
                        values.officeShiftOut?.format('HH:mm') || '',
                },
                modified: moment().unix(),
                modifiedBy: email,
            })
        )
        message.success('Successfully updated HR settings.')
        setLoading(false)
    }

    const handleChange = (name: string, val: any) => {
        setValues({
            ...values,
            [name]: val,
        })
    }

    useEffect(() => {
        if (!hr) return
        setValues({
            firstShiftIn: hr?.firstShiftIn
                ? moment(hr.firstShiftIn, 'HH:mm')
                : undefined,
            firstShiftOut: hr.firstShiftIn
                ? moment(hr.firstShiftOut, 'HH:mm')
                : undefined,
            officeShiftIn: hr.officeShiftIn
                ? moment(hr.officeShiftIn, 'HH:mm')
                : undefined,
            officeShiftOut: hr.officeShiftOut
                ? moment(hr.officeShiftOut, 'HH:mm')
                : undefined,
        })
    }, [hr])
    // eslint-disable-next-line no-console
    console.log(hr)

    return (
        <>
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <SettingsTitle level={4}>Bus Personnel</SettingsTitle>
                    <Row>
                        <Col span={12}>
                            <SettingsTitle level={5}>Time In</SettingsTitle>
                            <SettingsDescription>
                                Time required for bus personnel to clock in.
                            </SettingsDescription>
                            <TimePicker
                                format={'h:mmA'}
                                value={values.firstShiftIn as Moment}
                                onChange={(val) =>
                                    handleChange(
                                        'firstShiftIn',
                                        val || undefined
                                    )
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <SettingsTitle level={5}>Time Out</SettingsTitle>
                            <SettingsDescription>
                                Time required for bus personnel to clock out.
                            </SettingsDescription>
                            <TimePicker
                                format={'h:mmA'}
                                value={values.firstShiftOut as Moment}
                                onChange={(val) =>
                                    handleChange(
                                        'firstShiftOut',
                                        val || undefined
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </div>
                <Divider style={{ margin: 0 }} />
                <div className={'settings-card-content'}>
                    <SettingsTitle level={4}>Office Staff</SettingsTitle>
                    <Row>
                        <Col span={12}>
                            <SettingsTitle level={5}>Time In</SettingsTitle>
                            <SettingsDescription>
                                Time required for office employees to clock in.
                            </SettingsDescription>
                            <TimePicker
                                format={'h:mmA'}
                                value={values.officeShiftIn as Moment}
                                onChange={(val) =>
                                    handleChange(
                                        'officeShiftIn',
                                        val || undefined
                                    )
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <SettingsTitle level={5}>Time Out</SettingsTitle>
                            <SettingsDescription>
                                Time required for office employees to clock out.
                            </SettingsDescription>
                            <TimePicker
                                format={'h:mmA'}
                                value={values.officeShiftOut as Moment}
                                onChange={(val) =>
                                    handleChange(
                                        'officeShiftOut',
                                        val || undefined
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </div>

                <div className={'settings-card-footer'}>
                    <Row justify={'space-between'}>
                        <Col>
                            {/*<Row>*/}
                            {/*    <Col span={24}>*/}
                            {/*        <Typography.Text type={'secondary'}>*/}
                            {/*            Please use whole numbers only. The system*/}
                            {/*            will automatically calculate it for you.*/}
                            {/*        </Typography.Text>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </Col>
                        <Col>
                            <Button
                                loading={loading}
                                onClick={handleSave}
                                style={{
                                    backgroundColor: styles.primary,
                                    color: 'white',
                                }}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <br />
        </>
    )
}

export default HR
