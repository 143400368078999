import { db } from '../../boot'
import { useAppSelector } from '../../../app/store'
import { PERSONNEL_ATTENDANCE_REPOSITORY } from '../../../data/repositories/constants'
import { Attendance } from '../../../domain/entities/Attendance'
import { ProFormDateRangePicker } from '@ant-design/pro-components'
import { Card, Col, Row, Statistic, Typography } from 'antd'
import { collection, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { calculateWorkTime } from '../My/MyTeam/hrUtils'
import moment from 'moment'
import DashboardMetric from '../../../app/components/DashboardMetric'
// import {
//     VictoryChart,
//     VictoryTheme,
//     VictoryVoronoiContainer,
//     VictoryGroup,
//     VictoryTooltip,
//     VictoryScatter,
//     VictoryBar,
//     VictoryLegend,
//     VictoryAxis,
//     VictoryLabel,
// } from 'victory'
import { Chart, Geom, Interaction, Interval, Tooltip } from 'bizcharts'
import PeratiChart from '../../../app/components/ExpenseChart'
// import { Axis } from 'victory'

function PerformanceContainer() {
    const [data, setData] = useState({
        punctualityRate: 0,
        averageLateness: 0,
        averageRevenueForLate: 0,
        averageRevenueForPunctual: 0,
        averageOvertimePay: 0,
        totalLate: 0,
        averageHoursRendered: 0,
        totalPunctual: 0,
        totalMiscExpenses: 0,
        averageGasCostPerVehicle: 0,
        totalOvertimeCost: 0,
        totalOvertimeHours: 0,
        totalSalaryExpense: 0,
        totalDriverSalary: 0,
        totalAssistantSalary: 0,
        totalOfficeStaffSalary: 0,
        totalCardRevenue: 0,
        totalCashRevenue: 0,
        totalRevenue: 0,
        totalTrips: 0,
    })

    const [datum, setDatum] = useState<any[]>([])
    const {
        hr,
        // shareDividendFrequency,
        // deductionFrequency,
        // deductionFormula,
        // systemData,
        // currentYear,
        activeTeam,
        // styles,
    } = useAppSelector(
        (state) => ({
            hr: state.team.selectedTeamData.hr,
            shareDividendFrequency: state.systemConfig.shareDividendFrequency,
            deductionFrequency: state.systemConfig.deductionFrequency,
            deductionFormula: state.systemConfig.deductionFormula,
            systemData: state.team.selectedTeamData,
            currentYear: state.systemConfig.currentYear,
            activeTeam: state.auth.user.activeTeam,
            styles: state.styles,
        }),
        shallowEqual
    )

    const [open, setOpen] = useState(false)
    const [dates, setDates] = useState({
        start: 0,
        end: 0,
    })
    const ref = useRef(null)
    // const [revenueHistData, setRevenueHistData] = useState<any>([])
    const [expenseHistData, setExpenseHistData] = useState<any>([])

    useEffect(() => {
        if (!dates.start) return
        if (!dates.end) return
        const main = async () => {
            const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)
            const q = query(
                ref,
                where('timeIn', '>=', dates.start),
                where('timeIn', '<=', dates.end),
                where('isRemoved', '==', false),
                where('partnerCode', '==', activeTeam),
                where('status', '==', 'done')
            )
            const snapshots = await getDocs(q)

            const objs: any = {}
            const objsExpenses: any = {}
            const reps: any = []
            const reps2: {
                category: string
                type: string
                value: number
            }[] = []
            let totalLateness = 0
            let totalLate = 0
            let totalPunctual = 0
            let totalRevenueForLate = 0
            let totalRevenueForPunctual = 0
            let totalHoursRendered = 0
            let totalMiscExpenses = 0
            let totalCardRevenue = 0
            let totalCashRevenue = 0
            let totalGasCost = 0
            let totalRevenue = 0
            let vehicleIds: any = []
            let totalTrips = 0
            // let totalCashReceived  = 0
            let attendanceArray = snapshots.docs.map((docSnap) => {
                const attendanceData = docSnap.data() as Attendance
                // if(attendanceData.trip && attendanceData.status === 'pending'){
                totalMiscExpenses += attendanceData.miscellaneousExpenses || 0
                // if (!tripIds.includes(attendanceData.tripId)) {
                //     tripIds.push(attendanceData.tripId)
                // }
                totalTrips += 1
                // totalCashReceived += attendanceData.totalCashReceived || 0
                const x = moment
                    .unix(attendanceData.timeIn)
                    .format('ddd • MMM DD')
                const y =
                    (attendanceData.cashCollected || 0) +
                    (attendanceData.cardRevenue || 0)
                totalRevenue += y
                const yExpense =
                    (attendanceData.totalGasCost || 0) +
                    (attendanceData.dispatchingCost || 0) +
                    (attendanceData.driverIncentives || 0) +
                    (attendanceData.assistantIncentives || 0) +
                    (attendanceData.miscellaneousExpenses || 0) +
                    (attendanceData.driverSalary || 0) +
                    (attendanceData.assistantSalary || 0) +
                    (attendanceData.assistantOvertimePay || 0) +
                    (attendanceData.driverOvertimePay || 0)
                totalGasCost += attendanceData.totalGasCost || 0
                totalCardRevenue += attendanceData.cardRevenue || 0
                totalCashRevenue += attendanceData.cashCollected || 0
                reps2.push({
                    category: x,
                    type: 'Card',
                    value: attendanceData.cardRevenue || 0,
                })
                reps2.push({
                    category: x,
                    type: 'Cash',
                    value: attendanceData.cashCollected || 0,
                })
                reps.push({
                    name: 'Fare Revenue',
                    month: x,
                    // type: "cash",
                    value: y,
                })
                // reps.push({
                //     name: 'Fare Revenue',
                //     month: x,
                //     type: 'card',
                //     value: attendanceData.cardRevenue || 0,
                // })
                reps.push({
                    name: 'Expense',
                    month: x,
                    value: yExpense || 0,
                })
                reps.push({
                    name: 'Cash Received',
                    month: x,
                    value: attendanceData.totalCashReceived || 0,
                })
                if (!objsExpenses[x]) {
                    objsExpenses[x] = yExpense
                } else {
                    objsExpenses[x] += yExpense
                }
                if (!objs[x]) {
                    objs[x] = y
                } else {
                    objs[x] += y
                }
                // }
                if (
                    attendanceData.driverId !== '' &&
                    attendanceData.assistantId !== ''
                ) {
                    const work = calculateWorkTime(
                        moment.unix(attendanceData.timeIn).format('HH:mm'),
                        moment.unix(attendanceData.timeOut).format('HH:mm'),
                        hr,
                        true
                    )
                    totalHoursRendered += work.hours
                    totalLateness += work.minutesLate
                    if (work.minutesLate > 0) {
                        // eslint-disable-next-line no-console
                        // console.log(attendanceData?.totalRevenue)

                        totalLate += 1
                        totalRevenueForLate += attendanceData?.totalRevenue || 0
                    }
                    if (work.minutesLate === 0) {
                        totalPunctual += 1
                        totalRevenueForPunctual +=
                            attendanceData?.totalRevenue || 0
                    }
                }
                return {
                    ...attendanceData,
                    id: docSnap.id,
                } as Attendance
            })

            // 1. Average Lateness
            // let totalLateness = attendanceArray.reduce((acc, record) => acc + (record.minutesLate || 0), 0);
            let averageLateness = totalLateness / totalLate
            let punctualityRate = (totalPunctual / attendanceArray.length) * 100
            let averageRevenueForLate = totalRevenueForLate / totalLate
            let averageRevenueForPunctual =
                totalRevenueForPunctual / totalPunctual
            // eslint-disable-next-line no-console
            console.log(totalGasCost, vehicleIds.length)

            let averageGasCostPerVehicle = (totalGasCost || 0) / totalTrips
            // 2. Attendance Rate
            // let attendedRecords = attendanceArray.filter(record => record.status === 'done').length;
            // let attendanceRate = attendedRecords / attendanceArray.length;

            // 3. Total Salary Expense
            let totalSalaryExpense = attendanceArray.reduce(
                (acc, record) =>
                    acc +
                    (record.salary || 0) +
                    (record.driverSalary || 0) +
                    (record.assistantSalary || 0),
                0
            )
            let totalDriverSalary = attendanceArray.reduce(
                (acc, record) => acc + (record.driverSalary || 0),
                0
            )
            let totalAssistantSalary = attendanceArray.reduce(
                (acc, record) => acc + (record.assistantSalary || 0),
                0
            )
            let totalOfficeStaffSalary = attendanceArray.reduce(
                (acc, record) => acc + (record.salary || 0),
                0
            )

            // 4. Average Overtime Pay
            let totalOvertimePay = attendanceArray.reduce(
                (acc, record) =>
                    acc +
                    (record.overtimePay || 0) +
                    (record.driverOvertimePay || 0) +
                    (record.assistantOvertimePay || 0),
                0
            )
            let totalOvertimeHours = attendanceArray.reduce(
                (acc, record) =>
                    acc +
                    (record.driverOvertimeHours || 0) +
                    (record.assistantOvertimeHours || 0) +
                    (record.overtimeHours || 0),
                0
            )
            let averageHoursRendered =
                totalHoursRendered / attendanceArray.length
            let averageOvertimePay = totalOvertimePay / attendanceArray.length
            // eslint-disable-next-line no-console
            // console.log(totalCashReceived);

            setData({
                punctualityRate,
                averageLateness,
                averageRevenueForLate,
                averageRevenueForPunctual,
                averageOvertimePay,
                totalLate,
                averageHoursRendered,
                totalPunctual,
                totalMiscExpenses,
                averageGasCostPerVehicle,
                totalOvertimeCost: totalOvertimePay,
                totalOvertimeHours,
                totalSalaryExpense,
                totalDriverSalary,
                totalAssistantSalary,
                totalOfficeStaffSalary,
                totalCardRevenue,
                totalCashRevenue,
                totalRevenue,
                totalTrips,
                // totalCashReceived
            })

            // const revenueArr = Object.keys(objs).map((key) => {
            //     return { x: key, y: objs[key] }
            // })
            // setRevenueHistData(revenueArr)
            // const expenseArr = Object.keys(objsExpenses).map((key) => {
            //     return { x: key, y: objsExpenses[key] }
            // })
            const combinedData: any = {}
            const combinedData2: any = {}

            reps.forEach(
                (entry: { name: string; month: string; value: number }) => {
                    const key = `${entry.name}-${entry.month}`
                    if (!combinedData[key]) {
                        combinedData[key] = {
                            ...entry,
                            value: 0,
                        }
                    }
                    combinedData[key].value += entry.value
                }
            )
            reps2.forEach(
                (entry: { category: string; type: string; value: number }) => {
                    const key = `${entry.category}-${entry.type}`
                    if (!combinedData2[key]) {
                        combinedData2[key] = {
                            ...entry,
                            value: 0,
                        }
                    }
                    combinedData2[key].value += entry.value
                }
            )
            // eslint-disable-next-line no-console
            // console.log(combinedData);
            setDatum(Object.values(combinedData2))

            setExpenseHistData(Object.values(combinedData))
        }
        main()
    }, [activeTeam, dates, hr])
    // function formatNumber(number: string) {
    //     // This is a basic example to add commas for thousands.
    //     // You can modify it as per your requirements.
    //     return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   }
    return (
        <div className="page-layout">
            <Row>
                <Col>
                    <ProFormDateRangePicker
                        fieldProps={{
                            ref: ref,
                            open: open,
                            onOpenChange: (val) => setOpen(val),
                            onChange: (vals, format) => {
                                // eslint-disable-next-line no-console
                                // console.log(vals, format, ref.current)
                                if (!vals) {
                                    return
                                }
                                setDates({
                                    start: vals[0]?.startOf('day').unix() || 0,
                                    end: vals[1]?.endOf('day').unix() || 0,
                                })
                                setOpen(false)
                                if (ref.current) {
                                    // @ts-ignore
                                    ref.current.blur()
                                }
                            },
                        }}
                    />
                </Col>
            </Row>
            <Typography.Title level={3}>Attendance Metrics</Typography.Title>
            <Row align={'middle'} justify={'center'}>
                <Col span={12}>
                    <Row justify={'center'}>
                        <Col>
                            <DashboardMetric
                                value={data.punctualityRate || 0}
                                suffix="%"
                                title={'Punctuality Rate'}
                                description="Percentage of Buses Departing On Time."
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Card
                        bodyStyle={{
                            padding: 12,
                        }}
                        bordered={false}
                    >
                        <Row wrap={false}>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    title={
                                        <p className="dashboard-title">
                                            Number of Buses Departing On Time
                                        </p>
                                    }
                                    className="dashboard-title"
                                    value={data.totalPunctual}
                                />
                            </Col>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    title={
                                        <p className="dashboard-title">
                                            Average Revenue of Buses Departing
                                            On Time
                                        </p>
                                    }
                                    precision={2}
                                    prefix={'₱'}
                                    className="dashboard-title"
                                    value={data.averageRevenueForPunctual || 0}
                                />
                            </Col>
                        </Row>

                        <Row wrap={false}>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    title={
                                        <p className="dashboard-title">
                                            Count of Buses with Late Departures
                                        </p>
                                    }
                                    className="dashboard-title"
                                    value={data.totalLate}
                                />
                            </Col>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    title={
                                        <p className="dashboard-title">
                                            Average Revenue of Buses with Late
                                            Departures
                                        </p>
                                    }
                                    prefix={'₱'}
                                    precision={2}
                                    className="dashboard-title"
                                    value={data.averageRevenueForLate}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br />
            <br />
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
                Operational Metrics
            </Typography.Title>

            <Row align="middle" justify={'center'}>
                <Col span={12}>
                    <Row>
                        <Col span={24}>
                            <Chart
                                height={400}
                                padding="auto"
                                data={expenseHistData}
                                autoFit
                            >
                                <Interval
                                    adjust={[
                                        {
                                            type: 'dodge',
                                            marginRatio: 0,
                                        },
                                    ]}
                                    color={['name', ['blue', 'red', 'green']]}
                                    position="month*value"
                                />
                                <Tooltip shared />
                                <Interaction type="active-region" />
                            </Chart>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Card
                        bodyStyle={{
                            padding: 12,
                        }}
                        bordered={false}
                    >
                        <Row wrap={false}>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    title={
                                        <p className="dashboard-title">
                                            Average Hours Rendered
                                        </p>
                                    }
                                    className="dashboard-title"
                                    value={data.averageHoursRendered}
                                    precision={1}
                                    suffix={'h'}
                                />
                            </Col>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    prefix={'₱'}
                                    title={
                                        <p className="dashboard-title">
                                            Average GASOLINE per Vehicle
                                        </p>
                                    }
                                    precision={2}
                                    className="dashboard-title"
                                    value={data.averageGasCostPerVehicle}
                                />
                            </Col>
                        </Row>

                        <Row wrap={false}>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    prefix={'₱'}
                                    title={
                                        <p className="dashboard-title">
                                            Total Overtime Cost
                                        </p>
                                    }
                                    className="dashboard-title"
                                    value={data.totalOvertimeCost}
                                />
                            </Col>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    title={
                                        <p className="dashboard-title">
                                            Total Overtime Hours
                                        </p>
                                    }
                                    className="dashboard-title"
                                    value={data.totalOvertimeHours}
                                    precision={1}
                                    suffix={'h'}
                                />
                            </Col>
                        </Row>
                        <Row wrap={false}>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    title={
                                        <p className="dashboard-title">
                                            Total Salary Cost
                                        </p>
                                    }
                                    prefix={'₱'}
                                    precision={2}
                                    className="dashboard-title"
                                    value={data.totalSalaryExpense}
                                />
                            </Col>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    prefix={'₱'}
                                    title={
                                        <p className="dashboard-title">
                                            Total Miscellaneous Expenses
                                        </p>
                                    }
                                    className="dashboard-title"
                                    precision={2}
                                    value={data.totalMiscExpenses}
                                />
                            </Col>
                        </Row>

                        <Row wrap={false}>
                            <Col span={12}>
                                <Statistic
                                    style={{
                                        border: '1px solid #f3f3f3',
                                        padding: 6,
                                    }}
                                    title={
                                        <p className="dashboard-title">
                                            Total Shifts
                                        </p>
                                    }
                                    // prefix={'₱'}
                                    className="dashboard-title"
                                    value={data.totalTrips}
                                />
                            </Col>
                            <Col span={12}></Col>
                        </Row>
                        <PeratiChart
                            legendTitle={'Salary Cost Breakdown'}
                            legendPosition="left-top"
                            legendY={80}
                            data={[
                                {
                                    item: 'Driver',
                                    percent:
                                        data.totalDriverSalary /
                                            data.totalSalaryExpense || 0,
                                },
                                {
                                    item: 'PAO',
                                    percent:
                                        data.totalAssistantSalary /
                                            data.totalSalaryExpense || 0,
                                },
                                {
                                    item: 'Office Staff',
                                    percent:
                                        data.totalOfficeStaffSalary /
                                            data.totalSalaryExpense || 0,
                                },
                            ]}
                        />
                    </Card>
                </Col>
            </Row>
            <Row align="middle">
                <Col span={12}>
                    <PeratiChart
                        title={
                            <div
                                style={{
                                    marginBottom: -80,
                                    textAlign: 'center',
                                }}
                            >
                                <Statistic
                                    title={
                                        <Typography.Title level={4}>
                                            Total Fare Revenue
                                        </Typography.Title>
                                    }
                                    value={data.totalRevenue}
                                    prefix={'₱'}
                                    precision={2}
                                />
                            </div>
                        }
                        level={3}
                        legendTitle={'Fare Revenue Breakdown'}
                        data={[
                            {
                                item: 'Card Revenue',
                                percent:
                                    data.totalCardRevenue / data.totalRevenue ||
                                    0,
                            },
                            {
                                item: 'Cash Revenue',
                                percent:
                                    data.totalCashRevenue / data.totalRevenue ||
                                    0,
                            },
                        ]}
                    />
                </Col>
                <Col span={12}>
                    <Chart height={400} data={datum} autoFit padding={'auto'}>
                        <Tooltip shared />
                        <Geom
                            type="interval"
                            position="category*value"
                            color={['type', ['#5555ff', '#3434ff']]}
                            adjust={[
                                {
                                    type: 'stack',
                                },
                            ]}
                        />
                    </Chart>
                </Col>
            </Row>
            <br />
            <br />
            {/* <Typography.Title level={3} style={{ marginBottom: 0 }}>
                Expense Metrics
            </Typography.Title>
            <Row>
                <Col span={12}>
                    <br />
                    <br />
                    <PeratiChart
                        title={
                            <div
                                style={{
                                    marginBottom: -80,
                                    textAlign: 'center',
                                }}
                            >
                                <Statistic
                                    title={
                                        <Typography.Title level={4}>
                                            Total Salary Expense
                                        </Typography.Title>
                                    }
                                    value={data.totalSalaryExpense}
                                    prefix={'₱'}
                                    precision={2}
                                />
                            </div>
                        }
                        data={[
                            {
                                item: 'Driver',
                                percent:
                                    data.totalDriverSalary /
                                        data.totalSalaryExpense || 0,
                            },
                            {
                                item: 'PAO',
                                percent:
                                    data.totalAssistantSalary /
                                        data.totalSalaryExpense || 0,
                            },
                            {
                                item: 'Office Staff',
                                percent:
                                    data.totalOfficeStaffSalary /
                                        data.totalSalaryExpense || 0,
                            },
                        ]}
                    />
                </Col>
            </Row> */}
            {/* <DashboardMetric value={data.punctualityRate || 0} suffix='%' title={"Average Late"} description='Percentage of employees who arrived on time.' /> */}
        </div>
    )
}

export default PerformanceContainer
