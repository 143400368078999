import { Team, TeamPermission } from '@/domain/entities/Team'
import { createSlice } from '@reduxjs/toolkit'
import { Borrow } from '../../../domain/entities/Borrow'
import { Personnel } from '../../../domain/entities/Transport'
import { initialSystemState } from '../system.slice'
import {
    getMyTeams,
    getTeamGroupMembers,
    // getTeamMemberPermission,
    getTeamMembers,
} from './team.action'
import { Member } from '@/domain/entities/Member'
import { TeamGroup } from '../../../domain/entities/Group'
import { StoreProduct } from '../../../domain/entities/Store'

interface TeamSlice {
    showBorrowersForm: boolean
    showPayBorrower: boolean
    borrower?: Borrow & {
        id: string
    }
    showCreateModal: boolean
    createModal: boolean
    selectedTeamId: string
    selectedTeamData: Team
    myTeams: Team[]
    currentTeamMembers: Member[]
    teamGroups: TeamGroup[]
    loading: boolean
    currentTeamPermission: TeamPermission
    drivers: Personnel[]
    assistants: Personnel[]
    otherPersonnel: Personnel[]
    currentEmployees: Personnel[]
    storeProducts: StoreProduct[]
}

export const initialTeamState: TeamSlice = {
    otherPersonnel: [],
    teamGroups: [],
    drivers: [],
    storeProducts: [],
    assistants: [],
    showPayBorrower: false,
    showCreateModal: false,
    showBorrowersForm: false,
    createModal: false,
    selectedTeamId: '',
    selectedTeamData: {
        folders: [],
        files: {
            parentFolder: [],
        },
        name: '',
        backgroundUrl: '',
        partnerCode: '',
        owner: '',
        headline: '',
        avatar: '',
        address: '',
        contactInfo: '',
        email: '',
        phone: '',
        savingsAccount: [],
        retainedEarnings: 0,
        coreValues: [],
        socialGoals: [],
        registrationNumber: '',
        registrationDate: 0,
        logoUrl: '',
        mission: '',
        vision: '',
        dividendFrequency: 'annual',
        dividendPayoutDate: 0,
        dividendPayoutRatio: 0,
        loanInterestType: 'reducing-balance',
        ...initialSystemState,
        totalPersonnel: 0,
        cardPercentage: 0,
        totalMembers: 0,
        ownerUid: '',
        busPersonnelSalaryPercentage: false,
        expenseCategories: []
    },
    myTeams: [],
    currentTeamMembers: [],
    loading: false,
    currentTeamPermission: {
        role: 'viewer',
        status: 'pending',
        dateAccepted: 0,
        isRemoved: false,
        memberId: '',
    },
    currentEmployees: [],
}

export const teamSlice = createSlice({
    name: 'team',
    initialState: initialTeamState,
    reducers: {
        updateTeamState: (
            state,
            action: {
                payload: Partial<TeamSlice>
            }
        ) => {
            return {
                ...state,
                ...action.payload,
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMyTeams.fulfilled, (state, action) => {
            state.myTeams = action.payload
        })

        builder.addCase(getTeamMembers.fulfilled, (state, action) => {
            state.currentTeamMembers = action.payload
        })

        builder.addCase(getTeamMembers.pending, (state) => {
            // console.trace('show')
            state.currentTeamMembers = []
        })

        builder.addCase(getTeamGroupMembers.fulfilled, (state, action) => {
            state.currentTeamMembers = action.payload
        })
        builder.addCase(getTeamGroupMembers.pending, (state) => {
            // console.trace('show')
            state.currentTeamMembers = []
        })

        // builder.addCase(getTeamMemberPermission.fulfilled, (state, action) => {
        //     state.currentTeamPermission = action.payload
        // })
    },
})

export const { updateTeamState } = teamSlice.actions
