import { TeamFirebaseImplementation } from '../../../data/repositories/team/team.firebase'
import { TeamService } from '../../../domain/usecases/team.usecase'
import { Team, TeamMember, TeamPermission } from '../../../domain/entities/Team'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Member } from '../../../domain/entities/Member'
import {
    EmployementStatus,
    FILES_REPOSITORY,
    FOLDERS_REPOSITORY,
    PERSONNEL_REPOSITORY,
    TRIPS_REPOSITORY,
    VEHICLE_REPOSITORY,
} from '../../../data/repositories/constants'
import { message } from 'antd'
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import { db } from '../../boot'
import { Personnel, Vehicle } from '@/domain/entities/Transport'
import { MoveFileProps } from '../../../domain/entities/File'
import moment from 'moment'
const localImpl = new TeamFirebaseImplementation()

export const UploadFiles = (payload: {
    uid: string
    files: any[]
    folderName: string
    company?: boolean
    callback?: any
}) => {
    // const tutorRepo = new tutorsFirebaseRepository()
    // const tutorService = new GetTutor(tutorRepo)
    // return tutorService.uploadTutorFiles(payload)
}

export const UploadFolders = async (payload: {
    uid: string
    folderName: string
    company?: boolean
    callback?: any
}) => {
    // const tutorRepo = new tutorsFirebaseRepository()
    // const tutorService = new GetTutor(tutorRepo)
    // return await tutorService.uploadTutorFolders(payload)
}

export const MoveFile = (
    payload: string,
    folderMoved: {
        from: string
        to: string
        company?: boolean
        callback?: any
    },
    selectedFile: MoveFileProps,
    studentOrTutor: string
) => {
    // const tutorRepo = new tutorsFirebaseRepository()
    // const tutorService = new GetTutor(tutorRepo)
    // return tutorService.moveTutorFile(payload, folderMoved, selectedFile, studentOrTutor)
}

export const RenameFile = async (payload: { id: string; name: string }) => {
    const { id, ...rest } = payload
    await updateDoc(doc(db, FILES_REPOSITORY, payload.id), {
        modified: moment().unix(),
        ...rest,
    })
    // const tutorRepo = new tutorsFirebaseRepository()
    // const tutorService = new GetTutor(tutorRepo)
    // return await tutorService.renameTutorFileFolder(payload, editFile, studentOrTutor, selectedFile)
}

export const ArchivesFile = (payload: any, studentOrTutor: string) => {
    // const tutorRepo = new tutorsFirebaseRepository()
    // const tutorService = new GetTutor(tutorRepo)
    // return tutorService.archivesTutorFile(payload, studentOrTutor)
}

export const GetFolders = async (partnerCode: string) => {
    if (!partnerCode) return undefined
    const ref = collection(db, FOLDERS_REPOSITORY)
    const q = query(
        ref,
        where('partnerCode', '==', partnerCode),
        where('isRemoved', '==', false)
    )
    const snap = await getDocs(q)
    // const tutorRepo = new tutorsFirebaseRepository()
    // const tutorService = new GetTutor(tutorRepo)
    // return tutorService.getBookedStudents(payload)
    return snap.docs.map((docSnap) => ({
        ...docSnap.data(),
        id: docSnap.id,
    }))
}

export const GetFiles = async (folderId: string, partnerCode: string) => {
    if (!partnerCode) return undefined
    const ref = collection(db, FILES_REPOSITORY)
    const q = query(
        ref,
        where('folderId', '==', folderId),
        where('partnerCode', '==', partnerCode),
        where('isRemoved', '==', false)
    )
    const snap = await getDocs(q)
    // const tutorRepo = new tutorsFirebaseRepository()
    // const tutorService = new GetTutor(tutorRepo)
    // return tutorService.getBookedStudents(payload)
    return snap.docs.map((docSnap) => ({
        ...docSnap.data(),
        id: docSnap.id,
    }))
}

export const GetEmployeeFiles = async (id: string, partnerCode: string) => {
    const ref = collection(db, FILES_REPOSITORY)
    const q = query(
        ref,
        where('partnerCode', '==', partnerCode),
        where('employeeId', '==', id),
        where('isRemoved', '==', false)
    )
    const snap = await getDocs(q)
    // const tutorRepo = new tutorsFirebaseRepository()
    // const tutorService = new GetTutor(tutorRepo)
    // return tutorService.getBookedStudents(payload)
    return snap.docs.map((docSnap) => ({
        ...docSnap.data(),
        id: docSnap.id,
    }))
}

export const SendFileToStudents = (payload: any) => {
    // const tutorRepo = new tutorsFirebaseRepository()
    // const tutorService = new GetTutor(tutorRepo)
    // return tutorService.sendFileToStudents(payload)
}

export const UploadFolder = async (payload: { partnerCode: string }) => {
    // const tutorRepo = new DataTuteesRepository()
    // const tutorService = new getStudentInfo(tutorRepo)
    // return tutorService.uploadStudentsFolder(payload)

    // payload = firebaseAdd(payload)
    const ref = collection(db, FOLDERS_REPOSITORY)

    await addDoc(ref, payload)
    // if (payload.uid) {
    //     const ownerRef = doc(db, PERSONNEL_REPOSITORY, payload.uid)
    //     await updateDoc(ownerRef, {
    //         myTeams: arrayUnion(payload.partnerCode as string),
    //     })
    // }
}

export const UploadFile = async (payload: { partnerCode: string }) => {
    // const tutorRepo = new DataTuteesRepository()
    // const tutorService = new getStudentInfo(tutorRepo)
    // return tutorService.uploadStudentsFolder(payload)

    // payload = firebaseAdd(payload)
    const ref = collection(db, FILES_REPOSITORY)

    await addDoc(ref, payload)
    // if (payload.uid) {
    //     const ownerRef = doc(db, PERSONNEL_REPOSITORY, payload.uid)
    //     await updateDoc(ownerRef, {
    //         myTeams: arrayUnion(payload.partnerCode as string),
    //     })
    // }
}

export const deleteTrip = async (id: string) => {
    if (!id) return
    const ref = doc(db, TRIPS_REPOSITORY, id)
    await updateDoc(ref, {
        isRemoved: true,
    })
    message.success('Successfully deleted trip')
}
export const createTeam = async (payload: Team) => {
    const teamService = new TeamService(localImpl)
    return await teamService.createTeam(payload)
}
export const getUser = async (id: string) => {
    if (!id) return undefined
    const ref = doc(db, PERSONNEL_REPOSITORY, id)
    const snap = await getDoc(ref)
    return {
        ...snap.data(),
        id: snap.id,
    } as Personnel
}
export const getVehicle = async (id: string) => {
    if (!id) return
    const ref = doc(db, VEHICLE_REPOSITORY, id)
    const snap = await getDoc(ref)
    return {
        ...snap.data(),
        id: snap.id,
    } as Vehicle
}
export const getMyTeams = createAsyncThunk(
    'team/getMyTeams',
    async (payload: string[]) => {
        const teamService = new TeamService(localImpl)
        return await teamService.getMyTeams(payload)
    }
)

export const getTeam = async (payload: string): Promise<Team | undefined> => {
    const teamService = new TeamService(localImpl)
    const team = await teamService.getMyTeams([payload])
    if (!team) return undefined
    if (!team[0]) return undefined
    return team[0]
}

export const getMyTeam = (
    payload: string,
    callback: (payload: Team) => void
) => {
    const teamService = new TeamService(localImpl)
    return teamService.getMyTeam(payload, callback)
}

export const getTeamMembers = createAsyncThunk(
    'team/getTeamMembers',
    async (payload: { activeTeam: string; pageName: string }) => {
        const teamService = new TeamService(localImpl)
        const { activeTeam, ...rest } = payload
        return await teamService.getTeamMembers(activeTeam, rest)
    }
)
export const getTeamGroupMembers = createAsyncThunk(
    'team/getTeamGroupMembers',
    async (payload: { activeTeam: string; pageName: string }) => {
        const teamService = new TeamService(localImpl)
        const { activeTeam, ...rest } = payload
        return await teamService.getTeamGroupMembers(activeTeam, rest)
    }
)

export const getTeamMemberPermission = (
    payload: {
        partnerCode: string
        memberId: string
    },
    callback: (permission: TeamPermission) => void
) => {
    const teamService = new TeamService(localImpl)
    return teamService.getTeamMemberPermission(payload, callback)
}

export const updateTeam = createAsyncThunk(
    'team/updateTeam',
    async (
        payload: Partial<Team> & {
            id: string
            modified?: number
            modifiedBy?: string
        }
    ) => {
        const teamService = new TeamService(localImpl)
        return await teamService.updateTeam(payload)
    }
)

export const addTeamMember = async (payload: Member, partnerCode: string) => {
    const teamService = new TeamService(localImpl)
    return await teamService.addTeamMember(payload, partnerCode)
}

export const removeTeamMember = async (
    memberId: string,
    status: EmployementStatus
) => {
    const teamService = new TeamService(localImpl)
    return await teamService.removeTeamMember(memberId, status)
}

export const updateTeamMember = async (
    payload: Partial<TeamMember> & {
        id: string
        partnerCode: string
    }
) => {
    const teamService = new TeamService(localImpl)
    return await teamService.updateTeamMember(payload)
}

export const getTeamMember = async (payload: {
    partnerCode: string
    memberId: string
}) => {
    const teamService = new TeamService(localImpl)
    return await teamService.getTeamMember(payload)
}
