import { UploadTypeResponse } from '../../data/repositories/api/cloud.firebase'
import { CloudFirebaseRepository } from '../repositories/cloud.repository'

export class CloudFirebaseService {
    cloudRepo: CloudFirebaseRepository

    constructor(ir: CloudFirebaseRepository) {
        this.cloudRepo = ir
    }

    async uploadFile(
        folderName: string,
        fileName: string,
        file: any,
        callback?: (payload: { type: UploadTypeResponse; resp: any }) => void
    ): Promise<any> {
        return await this.cloudRepo.uploadFile(
            folderName,
            fileName,
            file,
            callback
        )
    }
}
