/* eslint-disable no-unused-vars */
import './index.css'
import React, { ReactNode, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store'
import { shallowEqual } from 'react-redux'
import { ProLayout } from '@ant-design/pro-components'
import logo from '../../assets/mainicon1280x654.png'

// import {
//     acRoutes,
//     cashierRoutes,
//     dispatcherRoutes,
//     hrRoutes,
//     itRoutes,
//     protectedRoutes,
//     treasurerRoutes,
//     viewerRoutes,
// } from './index'
import {
    CloseOutlined,
    // LeftOutlined,
    // LeftOutlined,
    LogoutOutlined,
    // RightOutlined,
    // RightOutlined,
    UserOutlined,
} from '@ant-design/icons'
import {
    isLoggedIn,
    logout,
    setAuthState,
    updateUser,
} from '../redux/auth/auth.slice'
import {
    Avatar,
    Button,
    Col,
    Divider,
    Dropdown,
    Grid,
    Layout,
    Menu,
    Modal,
    notification,
    Row,
    Typography,
    Upload,
} from 'antd'
// import { payLoan } from '../redux/loans/loans.slice'
// import { Loan, LoanPayment } from '../../domain/entities/Loan'
// import moment from 'moment'
// import { randomId } from '../pages/utils'
// import { isDue } from '../pages/Member/loans/loanHelper'
import CreateTeamModal from '../pages/My/MyTeam/CreateTeamModal'
import ProtectedHeaderTitle, {
    TeamHeader,
} from './components/ProtectedHeaderTitle'
import { Steps } from 'intro.js-react'
import { uploadFile, UploadProp } from '../redux/cloud/cloud.action'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload'
import { randomId } from '../pages/utils'
import { UploadTypeResponse } from '../../data/repositories/api/cloud.firebase'
import { getRouteName } from '../pages/Reports/MembersReceivableReport/helper'
import { MdSettings } from 'react-icons/md'
import { getUserRoute } from './helpers'
// import ChatButton from '../components/ChatButton'
import { Content, Footer, Header } from 'antd/lib/layout/layout'
import { BiHelpCircle } from 'react-icons/bi'
// import { Personnel } from '@/domain/entities/Transport'

const steps = [
    {
        element: '.onboard-step-1',
        title: `Your teams`,
        intro: `Here is your current active team. Also, you can select which team to activate by clicking the switch button.`,
        // position: 'center',
        // tooltipClass: 'next-primary',
    },
    {
        element: '.ant-layout-sider-children',
        title: `Side Panel`,
        intro: `Here you can easily navigate Shares, Team Settings, and other pages.`,
        position: 'left',
        // tooltipClass: 'next-primary',
    },
    {
        element: '.onboard-step-2',

        title: `Invite URL`,
        intro: `The Invite URL will be used by your members to join the team. Copy this and let them paste it to any browser of their choice.`,
        // position: 'left',
        // tooltipClass: 'next-primary',
    },
]
function MemberRoute(props: any) {
    const { user, activeTeam, styles } = useAppSelector(
        (state) => ({
            user: state.auth.user,
            isLoggedInUser: state?.auth?.isLoggedIn,
            activeTeam: state?.auth?.user?.activeTeam,
            styles: state.styles,
        }),
        shallowEqual
    )
    const [welcomeOnboarding, setWelcomeOnboarding] = useState(false)
    const [startOnboarding, setStartOnboarding] = useState(false)

    const location = useLocation()
    const [currentPage, setCurrentPage] = useState('Home')
    const [currentKey, setCurrentKey] = useState('home')
    const { xs } = Grid.useBreakpoint()
    const [collapsed, setCollapsed] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const handleCloseKeys = (collapsed: boolean) => {
        // if (collapsed) {
        //     setOpenKeys([''])
        // } else {
        //     setOpenKeys(['reports'])
        // }
        setCollapsed(collapsed)

        if (collapsed) return
    }
    const handleLogout = async () => {
        // @ts-ignore
        await dispatch(logout())
        // navigate(`/login${search}`)

        // navigate(`/`, {
        //     replace: true,
        // })
    }
    const handleChange = async (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file && info.file.type && !info.file.type.includes('text')) {
            notification.error({
                message: 'Text files only',
            })
            return
        }

        const uploadPayload: UploadProp = {
            folderName: `import/${activeTeam}`,
            fileName: `${info.file.name}?${randomId(15)}`,
            file: info.file,
            callback: (resp: { type: UploadTypeResponse; resp: any }) => {
                if (resp.type === 'success') {
                    notification.success({
                        message:
                            'Successfully uploaded file. Please wait while we import your data.',
                        duration: 10,
                    })
                }
            },
        }
        await uploadFile(uploadPayload)
    }

    const handleNavigate = (item: any) => {
        const { path } = item
        setCurrentPage(item.name)
        setCurrentKey(item.key)
        if (location.pathname.includes(path) && path !== '/') return
        // @ts-ignore
        if (!user.role) return
        // eslint-disable-next-line no-console
        // console.log(path)
        // @ts-ignore
        const mainPath = getUserRoute(user.role)
        navigate(`/app/${mainPath}${path}`)
        if (xs) {
            handleCloseKeys(true)
        }
    }
    // eslint-disable-next-line no-console
    // console.log(props)
    const handleExitOnboarding = (exitNumber: number) => {}
    const handleChangeStep = (step: number) => {
        if (step === 2) {
            setCollapsed(true)
        }
    }
    const handleStartOnboarding = () => {
        if (xs) {
            setCollapsed(false)
        }
        setStartOnboarding(true)
        setWelcomeOnboarding(false)
    }

    const handleCloseWelcomeMessage = () => {
        dispatch(
            updateUser({
                dontShowWelcomeMessage: true,
                id: user.id as string,
            })
        )
        setWelcomeOnboarding(false)
        setStartOnboarding(false)
    }

    const handleWelcomeCancel = () => {
        setWelcomeOnboarding(false)
    }

    useEffect(() => {
        // dispatch(getAllMemberLoans())
        // dispatch(getSystemConfig())
        dispatch(
            isLoggedIn(async (callback) => {
                if (!callback) {
                    handleLogout()
                    return
                }
                dispatch(
                    setAuthState({
                        user: callback,
                    })
                )
                // navigate(`/teams/${callback.activeTeam}`, {
                //     replace: true,
                // })
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        // if (xs && !collapsed) {
        // if (collapsed) return
        if (xs) {
            setCollapsed(true)
        }

        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xs])

    useEffect(() => {
        if (collapsed) return
        setTimeout(() => {
            const el = document.getElementsByClassName('ant-drawer-mask')
            // eslint-disable-next-line no-console
            // console.log(el)

            if (el[0]) {
                const mask = el[0]
                mask.addEventListener(
                    'click',
                    () => {
                        setCollapsed(true)
                    },
                    false
                )
            }
        }, 500)
    }, [collapsed])

    useEffect(() => {
        let path = getRouteName()
        // const routePath = protectedRoutes.find((route) => route.key === path)
        // let name = path
        // if (routePath) name = routePath.name || 'My Team'
        // if (!path) path = 'my-team'
        setCurrentKey(path)
        setCurrentPage(path)
    }, [])
    // eslint-disable-next-line no-console
    // console.log(activeTeam);

    const headerStyle: React.CSSProperties = {
        textAlign: 'center',
        color: '#fff',
        backgroundColor: 'white',
        height: 64,
        borderBottom: '1px solid #f3f3f3',
    }

    const contentStyle: React.CSSProperties = {
        backgroundColor: 'transparent',
    }

    const siderStyle: React.CSSProperties = {
        textAlign: 'center',
        lineHeight: '120px',
        color: '#fff',
        backgroundColor: '#3ba0e9',
    }

    const footerStyle: React.CSSProperties = {
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#7dbcea',
    }
    return (
        <div
            style={{
                height: '100vh',
            }}
        >
            {welcomeOnboarding && (
                <Modal
                    width={700}
                    open
                    onCancel={handleWelcomeCancel}
                    centered
                    footer={null}
                    closeIcon={
                        <CloseOutlined
                            style={{
                                color: '#007BFF',
                                fontWeight: 'bold',
                                fontSize: 18,
                            }}
                        />
                    }
                >
                    <div
                        style={{
                            textAlign: 'center',
                            padding: xs ? '45px 0 12px' : '45px 0 12px',
                        }}
                    >
                        <Typography.Title>Welcome</Typography.Title>
                        <Typography.Paragraph
                            style={{
                                fontSize: 'larger',
                            }}
                        >
                            {`We'd love to show you around. It'll be fast, promise. You'll see where useful features are, explore our guide or jump right into your dashboard.`}
                        </Typography.Paragraph>
                        <br />

                        <Button
                            size="large"
                            shape={'round'}
                            style={{
                                marginBottom: 14,
                                backgroundColor: styles.primary,
                                color: '#fff',
                            }}
                            onClick={handleStartOnboarding}
                        >
                            Let&apos;s get started
                        </Button>
                        <br />
                        <Button type="link" onClick={handleCloseWelcomeMessage}>
                            I don&apos;t need help
                        </Button>
                    </div>
                </Modal>
            )}
            {/* <Hints hints={hints} enabled={startOnboarding} /> */}
            {startOnboarding && (
                <Steps
                    steps={steps}
                    initialStep={0}
                    enabled
                    onExit={handleExitOnboarding}
                    onComplete={handleCloseWelcomeMessage}
                    onChange={handleChangeStep}
                    options={{
                        showStepNumbers: true,
                        keyboardNavigation: true,
                        showBullets: false,
                        buttonClass: 'ant-btn',
                        stepNumbersOfLabel: '/',
                    }}
                />
            )}
            <div>
                <Layout style={{ padding: 0 }}>
                    <Header
                        style={{
                            ...headerStyle,
                            padding: xs ? '0 12px' : headerStyle.padding,
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <Row gutter={[6, 6]} justify={'space-between'}>
                                    <Col>
                                        <Typography.Text>
                                            Welcome,{' '}
                                            <Typography.Text
                                                style={{
                                                    fontWeight: 'bolder',
                                                }}
                                            >
                                                {user?.firstName ||
                                                    user?.displayName}
                                                !
                                            </Typography.Text>
                                        </Typography.Text>
                                    </Col>
                                    <Col>
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={
                                                <Menu>
                                                    <Menu.Item
                                                        key="my-account-settings"
                                                        icon={<MdSettings />}
                                                        onClick={() => {
                                                            navigate(
                                                                `settings/${user.id}`
                                                            )
                                                        }}
                                                    >
                                                        My Account
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        key="help"
                                                        icon={<BiHelpCircle />}
                                                        onClick={() => {
                                                            if (
                                                                // @ts-ignore
                                                                window.Tawk_API
                                                            ) {
                                                                // @ts-ignore
                                                                window.Tawk_API.showWidget()
                                                            }
                                                        }}
                                                    >
                                                        Help
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        icon={
                                                            <LogoutOutlined />
                                                        }
                                                        onClick={() => {
                                                            handleLogout()
                                                        }}
                                                        key="logout"
                                                    >
                                                        Logout
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                        >
                                            <Avatar
                                                // @ts-ignore
                                                src={user.idUrl}
                                                icon={<UserOutlined />}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        className="main-layout-member"
                        style={contentStyle}
                    >
                        <Outlet />
                    </Content>
                </Layout>
            </div>
        </div>
    )
}

export default MemberRoute
