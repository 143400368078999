import { UserOutlined, SolutionOutlined } from '@ant-design/icons'
import { ProFormSelect } from '@ant-design/pro-components'
import { Divider, FormInstance, Grid, Steps } from 'antd'
import React from 'react'

const SelectList = ({
    dataSource,
    form,
    placeholder,
    name,
    onSelect,
    value,
    children,
}: {
    dataSource: {
        value: any
        label: any
    }[]
    form?: FormInstance
    placeholder?: string
    onSelect?: (data: string) => void
    name: string
    value?: string
    children?: any
}) => {
    const { xs } = Grid.useBreakpoint()
    return (
        <div
            style={{
                padding: xs ? undefined : '24px 24px 0 24px',
            }}
        >
            <Steps
                current={0}
                style={{
                    padding: 0,
                }}
                type={xs ? 'default' : 'navigation'}
                progressDot={xs}
                size={xs ? 'small' : 'default'}
            >
                <Steps.Step
                    icon={<UserOutlined />}
                    status={'process'}
                    title={`Select a ${name.toLowerCase()}`}
                />
                <Steps.Step
                    icon={<SolutionOutlined />}
                    status={'wait'}
                    title={'Fill in details'}
                />
            </Steps>
            {!xs && <Divider />}
            <ProFormSelect
                name={name}
                options={dataSource}
                showSearch
                placeholder={placeholder}
                rules={[
                    {
                        required: true,
                        type: 'string',
                        message: `${name ? name : 'Item'} not found. Select ${
                            name || 'item'
                        } in the list.`,
                    },
                ]}
                fieldProps={{
                    onKeyUp: (event) => {
                        if (event.key === 'Enter') {
                            form && form.submit()
                        }
                    },
                    onSelect: onSelect,
                    value,
                    filterOption: (input: any, option: any) => {
                        const filterData = option[name]
                        return (
                            filterData
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                            filterData
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        )
                    },
                }}
            />
            {children}
        </div>
    )
}

export default SelectList
