import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../app/boot'
import { CloudFirebaseRepository } from '../../../domain/repositories/cloud.repository'

export type UploadTypeResponse = 'uploading' | 'success' | 'paused'
export class CloudFirebaseImplementation implements CloudFirebaseRepository {
    async uploadFile(
        folderName: string,
        fileName: string,
        file: any,
        callback?:
            | ((payload: { type: UploadTypeResponse; resp: any }) => void)
            | undefined
    ): Promise<any> {
        const fileRef = ref(storage, `${folderName}/${fileName}`)
        const uploadTask = uploadBytesResumable(fileRef, file, {
            contentType: file.type,
        })

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                switch (snapshot.state) {
                    case 'paused':
                        callback &&
                            callback({
                                type: 'paused',
                                resp: progress || 100,
                            })
                        break
                    case 'running':
                        callback &&
                            callback({
                                type: 'uploading',
                                resp: progress || 100,
                            })
                        break
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break
                    case 'storage/canceled':
                        // User canceled the upload
                        break

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    callback &&
                        callback({
                            type: 'success',
                            resp: downloadURL,
                        })
                })
            }
        )
    }
}
