import { Button, Result } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function FailureOrder() {
    const navigate = useNavigate()
    return (
        <Result
            status="error"
            title="Oops something went wrong!"
            subTitle={`Please wait, Somone will get in touch with you. You can also click the help button at the lower right of the page.`}
            extra={[
                <div key="Home">
                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        target="_blank"
                        rel={'noopener'}
                        onClick={() => navigate('/store')}
                    >
                        HOME
                    </Button>
                </div>,
            ]}
        />
    )
}

export default FailureOrder
