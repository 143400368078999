import { Row, Col, Button, Divider, message, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/store'
import { shallowEqual } from 'react-redux'
import PayrollAdditionals from '../../../../app/components/Payroll/PayrollAdditionals'
import PayrollDeductions from '../../../../app/components/Payroll/PayrollDeductions'
import { SystemSettings } from '../../../../domain/entities/SystemSettings'
import { updateTeam } from '../../../../app/redux/team/team.action'
import moment from 'moment'
import { ProFormDigit } from '@ant-design/pro-components'

function PayrollSettings() {
    const [loading, setLoading] = useState(false)

    const { styles, payroll, partnerCode, email } = useAppSelector(
        (state) => ({
            // partnerCode: state.auth.user.activeTeam,
            email: state.auth.user.email,
            partnerCode: state.team.selectedTeamData.partnerCode,
            shareDividendFrequency: state.systemConfig.shareDividendFrequency,
            uniformLoanInterest: state.systemConfig.uniformLoanInterest,
            notice: state.systemConfig.notice,
            payroll: state.team.selectedTeamData.payroll,
            styles: state.styles,
        }),
        shallowEqual
    )

    const [payrollSettings, setPayrollSettings] = useState<
        SystemSettings['payroll']
    >(
        payroll || {
            earnings: [],
            deductions: [
                // {
                //     title: 'Fare Shortage(For PAO)',
                //     value: 'Fare Shortage(For PAO)',
                //     key: 'fareShortage',
                //     data: 0,
                //     isChecked: false,
                //     isEditing: false,
                // },
                // {
                //     title: 'Late Deductions',
                //     value: 'Late Deductions',
                //     key: 'late',
                //     data: 0,
                //     isChecked: false,
                //     isEditing: false,
                // },
                {
                    title: 'Pag-IBIG',
                    value: 'Pag-IBIG',
                    key: 'pagibigDeduction',
                    data: 0,
                    isChecked: false,
                    isEditing: false,
                },
                {
                    title: 'PhilHealth',
                    value: 'PhilHealth',
                    key: 'philhealthDeduction',
                    data: 0,
                    isChecked: false,
                    isEditing: false,
                },
                {
                    title: 'SSS',
                    value: 'SSS',
                    key: 'sssDeduction',
                    data: 0,
                    isChecked: false,
                    isEditing: false,
                },
            ],
            overtimePayEquation: 1.25,
        }
    )

    const dispatch = useAppDispatch()
    const handleSave = async () => {
        setLoading(true)
        await dispatch(
            updateTeam({
                // shareDividendFrequency: value,
                id: partnerCode,
                payroll: payrollSettings,
                modified: moment().unix(),
                modifiedBy: email,
            })
        )
        message.success('Successfully updated HR settings.')
        setLoading(false)
    }

    const handleDeductionsChange = (data: any) => {
        // eslint-disable-next-line no-console
        // console.log(data);
        setPayrollSettings({
            ...payrollSettings,
            deductions: data,
        })
    }

    const handleAdditionalsChange = (data: any) => {
        // eslint-disable-next-line no-console
        // console.log(data);
        setPayrollSettings({
            ...payrollSettings,
            earnings: data,
        })
    }

    const handleOvertimePayChange = (data: any) => {
        // eslint-disable-next-line no-console
        // console.log(data);
        setPayrollSettings({
            ...payrollSettings,
            overtimePayEquation: data,
        })
    }
    useEffect(() => {
        if (!payroll) return
        setPayrollSettings(payroll)
    }, [payroll])
    // eslint-disable-next-line no-console
    console.log(payrollSettings)

    return (
        <div>
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <PayrollAdditionals
                        data={payrollSettings.earnings}
                        onChange={handleAdditionalsChange}
                    />
                    <Divider />
                    <PayrollDeductions
                        data={payrollSettings.deductions}
                        onChange={handleDeductionsChange}
                    />
                    <Divider />
                    <ProFormDigit label="Overtime Pay Calculations">
                        <InputNumber
                            value={payrollSettings.overtimePayEquation}
                            onChange={handleOvertimePayChange}
                        />
                    </ProFormDigit>
                </div>

                <div className={'settings-card-footer'}>
                    <Row justify={'space-between'}>
                        <Col>
                            {/*<Row>*/}
                            {/*    <Col span={24}>*/}
                            {/*        <Typography.Text type={'secondary'}>*/}
                            {/*            Please use whole numbers only. The system*/}
                            {/*            will automatically calculate it for you.*/}
                            {/*        </Typography.Text>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </Col>
                        <Col>
                            <Button
                                loading={loading}
                                onClick={handleSave}
                                style={{
                                    backgroundColor: styles.primary,
                                    color: 'white',
                                }}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default PayrollSettings
