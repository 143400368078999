/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState } from 'react'
import { Button, Modal, Alert, Row, Col, notification, message } from 'antd'
// import { ArchivesTutorFile } from "@/app/redux/tutors/tutors.actions"
// import { mixPanel } from "@/data/utils/mixpanelClass"
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from '../../store'
import { db, storage } from '../../boot'
import { deleteObject, ref } from 'firebase/storage'
import { deleteDoc, doc } from 'firebase/firestore'
import { FILES_REPOSITORY } from '../../../data/repositories/constants'
// import { companyType } from "@/app/redux/company/company.type"
// import { RootState } from "@/data/utils/rootState"
// import { authType } from "@/app/redux/auth/auth.type"

interface FilesDeleteModalProps {
    toDeleteData: {
        tutorId: string
        id: string
        folderName: string
        fileType: string
        company?: boolean
        fileName: string
        fileUrl: string
    }
    setToDeleteData: (value: any) => void
    setOpenDeleteModal: (value: boolean) => void
    openDeleteModal: boolean
    studentOrTutor: string
}

const FilesDeleteModal = ({
    toDeleteData,
    openDeleteModal,
    setOpenDeleteModal,
    setToDeleteData,
    studentOrTutor,
}: FilesDeleteModalProps) => {
    const [loading, setLoading] = useState(false)
    const { folders } = useAppSelector(
        (state) => ({ folders: state.team.selectedTeamData.folders || [] }),
        shallowEqual
    )

    const dispatch = useDispatch()
    const confirmDeleteHandler = async () => {
        console.log(toDeleteData)

        if (!toDeleteData.fileUrl) return
        setLoading(true)
        const desertRef = ref(storage, toDeleteData.fileUrl)
        const fRef = doc(db, FILES_REPOSITORY, toDeleteData.id)
        // Delete the file
        try {
            await deleteObject(desertRef)
        } catch (err) {
            console.log(err)
        }
        try {
            await deleteDoc(fRef)
            message.success('Successfully deleted')
        } catch (err) {
            console.log(err)
        }

        setLoading(false)
        setOpenDeleteModal(false)
        // const resp = await ArchivesTutorFile(payload, studentOrTutor)
        // if (resp === "success") {
        //     notification.success({
        //         message: "File deleted.",
        //         duration: 3
        //     })
        //     setOpenDeleteModal(false)
        //     setLoading(false)
        //     mixPanel.track("File Deleted")
        // } else {
        //     notification.error({
        //         message: `Something went wrong. ${resp}`,
        //         duration: 5
        //     })
        //     setOpenDeleteModal(false)
        //     setLoading(false)
        // }
    }

    const cancelModalHandler = () => {
        setToDeleteData({
            ...toDeleteData,
            id: '',
            folderName: '',
        })
        setOpenDeleteModal(false)
    }

    return (
        <Modal
            open={openDeleteModal}
            footer={null}
            closable={false}
            closeIcon={false}
        >
            <br />
            {toDeleteData.fileType === 'file' ? (
                <Alert
                    message="Warning!"
                    description="Are you sure you want to delete this file?"
                    type="warning"
                    showIcon
                />
            ) : (
                <Alert
                    message="Warning!"
                    description="All files inside this folder will also be deleted. Are you sure you want to delete?"
                    type="warning"
                    showIcon
                />
            )}
            <br />
            <br />
            <Row justify="space-between">
                <Col>
                    <Button loading={loading} onClick={cancelModalHandler}>
                        Cancel
                    </Button>
                </Col>
                <Col>
                    <Button
                        loading={loading}
                        type="primary"
                        style={{
                            backgroundColor: '#ef4444',
                            borderColor: '#ef4444',
                        }}
                        onClick={confirmDeleteHandler}
                    >
                        Delete
                    </Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default FilesDeleteModal
