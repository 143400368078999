import { AuthRepository } from '../../../domain/repositories/auth.repository'
import { LoginProp, User } from '../../../domain/entities/default/AdminUser'
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
} from 'firebase/auth'
import { app, auth, currentAuth, db } from '../../../app/boot'
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { firebaseAdd, getErrorMessage } from '../default'
import {
    MEMBERS_REPOSITORY,
    PERSONNEL_REPOSITORY,
    USERS_REPOSITORY,
} from '../constants'
import { message } from 'antd'
export class AuthFirebaseImplementation implements AuthRepository {
    async getUser(id: string): Promise<undefined | User> {
        const docData = (await getDoc(doc(db, USERS_REPOSITORY, id))).data()
        if (!docData) return undefined

        const payload: User = {
            firstName: docData.firstName || '',
            lastName: docData.lastName || '',
            email: docData.email,
            emailVerified: docData.emailVerified,
            displayName: docData.displayName,
            partnerCode: docData.partnerCode,
            type: docData.type,
            myTeams: docData.myTeams || [],
            activeTeam: docData.activeTeam || '',
            dontShowWelcomeMessage: docData.dontShowWelcomeMessage,
            id,
            role: docData.role,
        }
        if (docData.role && docData.role !== 'regular_member') {
            const personnelData = (
                await getDoc(doc(db, PERSONNEL_REPOSITORY, id))
            ).data()
            return {
                ...payload,
                ...personnelData,
            }
        }
        const memberData = (
            await getDoc(doc(db, MEMBERS_REPOSITORY, id))
        ).data()
        return {
            ...payload,
            ...memberData,
        }
    }
    setStorage(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data))
    }
    getStorage(key: string): undefined | any {
        const item = localStorage.getItem(key)
        if (item) {
            return JSON.parse(item)
        } else {
            return undefined
        }
    }

    async Login(payload: LoginProp): Promise<User | undefined> {
        const { email, password } = payload
        // if (email === 'administrator' && password === 'ThouShallNotPass01!') {
        //     const user: User = {
        //         type: 'superadmin',
        //         email,
        //         name: email,
        //         emailVerified: true,
        //         partnerCode: 'perati',
        //     }
        //     this.setStorage('auth', user)
        //     return user
        // }
        const auth = currentAuth(app)
        const resp = await signInWithEmailAndPassword(auth, email, password)
        localStorage.setItem('uid', resp.user.uid)
        const user = await this.getUser(resp.user.uid)
        return user
        // try {
        //
        //     return user
        // } catch (err) {
        //     // eslint-disable-next-line no-console
        //     console.log(err)
        //     throw err
        // }
        // .then((userCredential) => {
        //     // Signed in
        //     const user = userCredential.user;
        //     // ...
        // })
        // .catch((error) => {
        //     const errorCode = error.code;
        //     const errorMessage = error.message;
        // });
    }
    async isLoggedIn(callback?: (resp?: User | null) => void): Promise<User> {
        const uid = localStorage.getItem('uid')
        if (!uid) {
            callback && callback(undefined)
            // eslint-disable-next-line no-throw-literal
            throw undefined
        }
        return await new Promise((res, rej) => {
            auth.onAuthStateChanged(
                async (user) => {
                    if (user) {
                        const authuser = auth.currentUser
                        if (!authuser) return
                        const user = await this.getUser(authuser.uid)
                        // res(payload)
                        if (!user) return
                        callback && callback({ ...user, id: authuser.uid })
                        // User is signed in.
                        // Only after this happens does my app redirect from the login to the main screen and start making authenticated calls with the token. This is what I meant when I said the user is authenticated, but it still fails intermittently after this.
                    } else {
                        callback && callback(undefined)
                    }
                },
                () => {
                    rej()
                    // callback && callback(undefined)
                }
            )
        })
    }
    async Register(
        payload: User
    ): Promise<{ status: string; message: string; user?: User }> {
        try {
            const { user } = await createUserWithEmailAndPassword(
                auth,
                payload.email,
                payload.password as string
            )
            delete payload.password
            const userRecord: User = firebaseAdd(payload)
            // if (userRecord.type === 'admin') {
            //     let invitePayload = {
            //         partnerCode: userRecord.partnerCode,
            //         owner: userRecord.email,
            //     }
            //     invitePayload = firebaseAdd(invitePayload)
            //     await setDoc(
            //         doc(db, 'partnerCodes', userRecord.partnerCode),
            //         invitePayload
            //     )
            // }
            const userId = user.uid
            userRecord.id = userId

            await setDoc(doc(db, USERS_REPOSITORY, userId), userRecord)
            if (!payload.activeTeam) {
                // auth.signOut()
            } else {
                localStorage.setItem('uid', userId)
            }

            return {
                status: 'success',
                message: 'User successfully registered',
                user: userRecord,
            }
        } catch (err: any) {
            return {
                status: err.code,
                message: err.message as string,
                user: undefined,
            }
        }
    }
    async Logout(callback: () => void) {
        await signOut(auth)
        localStorage.removeItem('uid')
        localStorage.clear()
        callback && callback()
    }

    async verifyCode(code: string): Promise<boolean> {
        const docData = await getDoc(doc(db, 'partnerCodes', code))
        return docData.exists()
    }

    async updateUser(payload: Partial<User>, uid: string): Promise<boolean> {
        try {
            // eslint-disable-next-line no-console
            // console.trace(payload)

            await updateDoc(doc(db, USERS_REPOSITORY, uid), payload)
            return true
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
    }
}
