import './index.css'
import { useEffect } from 'react'
import { Alert } from 'antd'
import { useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual } from 'react-redux'
import { getSystemConfig, updateSystemConfig } from '../../redux/system.slice'
// import DividendCalculation from './components/DividendCalculation'
// import DeductionCalculation from './components/DeductionCalculation'
// import LoanCompound from './components/LoanCompound'
// import Loan from './components/Loan'
// import Savings from './components/Savings'
import { SettingsTitle } from './components/template'
// import DistributionPolicy from './components/DistributionPolicy'
import HR from './components/HR'
import PayrollSettings from './components/PayrollSettings'
function SettingsContainer() {
    const { notice } = useAppSelector(
        (state) => ({
            notice: state.systemConfig.notice,
        }),
        shallowEqual
    )

    const dispatch = useAppDispatch()
    // const form: React.MutableRefObject<ProFormInstance<any>> =
    //     useRef<ProFormInstance<any>>()

    const handleGetSystemConfig = () => {
        dispatch(getSystemConfig())
    }

    const handleCloseAlert = () => {
        dispatch(
            updateSystemConfig({
                notice: '',
            })
        )
    }

    useEffect(() => {
        handleGetSystemConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {notice.show && (
                <Alert
                    type={'info'}
                    onClose={handleCloseAlert}
                    closable
                    showIcon
                    message={notice.message}
                    description={notice.description}
                />
            )}
            {notice.show && <br />}
            <br />
            <div className="page-layout">
                <SettingsTitle level={1}>H.R</SettingsTitle>
                <HR />
            </div>
            <br />
            <div className="page-layout">
                <SettingsTitle level={1}>Payroll</SettingsTitle>
                <PayrollSettings />
            </div>
            {/* <br /> */}
            {/* <div className="page-layout">
                <SettingsTitle level={1}>Loan</SettingsTitle>
                <Loan />
            </div> */}
            {/* <br />
            <div className="page-layout">
                <SettingsTitle level={1}>Distribution Policy</SettingsTitle>
                <DistributionPolicy />
            </div> */}
            {/* <br />
            <br /> */}

            {/* <div className="page-layout">
                <SettingsTitle level={1} >Savings</SettingsTitle>
                <Savings />
            </div> */}
            {/* <DividendCalculation /> */}
            {/* <br /> */}
            {/* <DeductionCalculation /> */}
        </>
    )
}

export default SettingsContainer
