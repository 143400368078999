import { useEffect, useState } from 'react'
import { Col, Form, Row, message } from 'antd'
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-components'
import { useAppDispatch, useAppSelector } from '../../../store'
import { shallowEqual } from 'react-redux'
import {
    initialShareState,
    updateSharesState,
} from '../../../redux/shares/shares.slice'
import { Share } from '../../../../domain/entities/Share'
import moment from 'moment'
import { getTeamMembers } from '../../../redux/team/team.action'
import { collection, addDoc } from 'firebase/firestore'
import { db } from '../../../boot'

function AddShare() {
    const dispatch = useAppDispatch()

    const [filteredMembers, setFilteredMembers] = useState<
        {
            label: string
            value: string
        }[]
    >([])
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const { showAddShare, activeTeam, user, styles, members, currentYear } =
        useAppSelector(
            (state) => ({
                showAddShare: state.shares.addData.show,
                members: state.team.currentTeamMembers,
                activeTeam: state.auth.user.activeTeam,
                user: state.auth.user,
                styles: state.styles,
                currentYear: state.systemConfig.currentYear,
                // dueDate: 'sat',
                // frequency: 'weekly'
            }),
            shallowEqual
        )

    const handleSubmit = async (data: any) => {
        setLoading(true)
        const member = members.find((member) => member.id === data.memberId)
        if (!member) return
        const payload: Omit<Share, 'id'> = {
            ...data,
            memberId: member.id,
            amount: data.amount,
            creation: moment(data.creation, 'MMMM DD, YYYY').unix(),
            modified: moment().unix(),
            modifiedBy: user.email,
            partnerCode: activeTeam,
            isRemoved: false,
            memberUid: member?.id as string,
            displayName: member?.displayName,
            type: 'common',
        }
        const paymentRef = collection(db, 'shares')
        const resp = await addDoc(paymentRef, payload)
        if (resp) {
            message.success(`Success`)
        }
        // await dispatch(addShare(payload))
        setLoading(false)
        handleCancel()
        // dispatch(getMembers())
    }

    const handleCancel = () => {
        dispatch(
            updateSharesState({
                name: 'addData',
                value: {
                    ...initialShareState.addData,
                    show: false,
                },
            })
        )
        form.resetFields()
    }

    useEffect(() => {
        const newMembers: {
            label: string
            value: string
        }[] = []
        members.forEach((member) => {
            newMembers.push({
                label: member.displayName as string,
                value: member.id,
            })
        })

        setFilteredMembers(newMembers)
    }, [members])
    useEffect(() => {
        if (!activeTeam) return
        dispatch(
            getTeamMembers({
                activeTeam,
                pageName: 'share',
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam, currentYear, showAddShare])
    // useEffect(() => {
    //     if (showAddShare) {
    //         const shares = getMemberShare(addData.id)
    //         let totalShare = 0
    //         shares.forEach((share: Share) => (totalShare += share.amount))
    //         setMemberTotalShareAmount(totalShare)
    //         // const isPastDue = calculateDueDate(frequency, dueDate)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [showAddShare])

    return (
        <ModalForm<Share>
            title={'Add share'}
            form={form}
            onFinishFailed={(err) => {
                if (err.errorFields.length) {
                    const firstError = err.errorFields[0]
                    message.destroy()
                    message.error(firstError.errors)
                    form.scrollToField(firstError.name, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }}
            // title={
            //     <>
            //         <Typography.Title level={3}>
            //             {addData.name}
            //         </Typography.Title>
            //         Total Contribution:{' '}
            //         <Typography.Text style={{ fontWeight: 700, fontSize: 18 }}>
            //             {currency}
            //             {memberTotalShareAmount}
            //         </Typography.Text>
            //         <div>
            //             <Typography.Text
            //                 style={{ fontWeight: 700, fontSize: 18 }}
            //             >
            //                 {addData.frequency}
            //             </Typography.Text>
            //         </div>
            //     </>
            // }
            open={showAddShare}
            autoFocusFirstInput
            modalProps={{
                onCancel: handleCancel,
                okText: 'Submit',
                centered: true,
                closable: false,
            }}
            submitTimeout={2000}
            onFinish={handleSubmit}
            width={540}
            initialValues={{
                creation: moment(),
            }}
            submitter={{
                submitButtonProps: {
                    style: {
                        backgroundColor: styles.primary,
                        color: 'white',
                    },
                    loading,
                },
            }}
            // @ts-ignore
        >
            <ProFormSelect
                name={'memberId'}
                options={filteredMembers}
                label={'Name of member'}
                showSearch
                placeholder={"Please enter member's name"}
                rules={[
                    {
                        required: true,
                        type: 'string',
                        message: (
                            <div>
                                Name can't be blank.
                                <div>
                                    Select a member in the list, or invite a
                                    member in your team.
                                </div>
                                <br />
                            </div>
                        ),
                    },
                ]}
                fieldProps={{
                    onKeyUp: (event) => {
                        if (event.key === 'Enter') {
                            form.submit()
                        }
                    },
                }}
            />
            <Row gutter={[12, 12]}>
                <Col span={12}>
                    <ProFormDigit
                        rules={[
                            {
                                required: true,
                                type: 'number',
                                message: 'This field is required',
                            },
                        ]}
                        name="amount"
                        label="Amount"
                        placeholder="360.50"
                        fieldProps={{
                            onKeyUp: (event) => {
                                if (event.key === 'Enter') {
                                    form.submit()
                                }
                            },
                        }}
                    />
                </Col>
                <Col span={12}>
                    <ProFormDatePicker
                        name={'creation'}
                        label={'Date added'}
                        fieldProps={{
                            format: 'MMMM DD, YYYY',
                            style: {
                                width: '100%',
                            },
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    />
                </Col>
            </Row>
            <Row gutter={[12, 12]}>
                <Col span={12}>
                    <ProFormText
                        label="Official Receipt No."
                        name="receiptNumber"
                    />
                </Col>
                <Col span={12}>
                    <ProFormText
                        label="Share Certificate No."
                        name="shareCertificateNo"
                    />
                </Col>
            </Row>
            <ProFormTextArea label="Additional Notes" name="notes" />
            {/* <ProFormSelect
                rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                ]}
                options={[
                    {
                        value: 'common',
                        label: 'Common Share',
                    },
                    {
                        value: 'preferred',
                        label: 'Preferred Share',
                    },
                ]}
                name="type"
                label={'Type'}
            /> */}
        </ModalForm>
    )
}

export default AddShare
