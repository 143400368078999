import { MemberRepository } from '../repositories/member.repository'
import { Member } from '../entities/Member'

export class MemberService {
    memberRepo: MemberRepository

    constructor(ir: MemberRepository) {
        this.memberRepo = ir
    }

    AddMember(payload: Member): Member {
        return this.memberRepo.AddMember(payload)
    }

    GetMembers(): Member[] {
        return this.memberRepo.GetMembers()
    }

    RemoveMember(id: string): string | undefined {
        return this.memberRepo.RemoveMember(id)
    }

    GetMember(id: string): Member {
        return this.memberRepo.GetMember(id)
    }

    UpdateMember(payload: Member): Member | undefined {
        return this.memberRepo.UpdateMember(payload)
    }
}
