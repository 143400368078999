import {
    // DatabaseOutlined,
    // LogoutOutlined,
    // TeamOutlined,
    TransactionOutlined,
    // DashboardOutlined,
    CarOutlined,
    DashboardOutlined,
    HomeFilled,
    DollarCircleFilled,
    DashboardFilled,
    // ScheduleOutlined,
    // ScheduleOutlined,
} from '@ant-design/icons'
import { MenuDataItem } from '@ant-design/pro-components'
import {
    FaBook,
    FaBus,
    FaCashRegister,
    FaLandmark,
    // FaLandmark,
    FaPercentage,
} from 'react-icons/fa'
// import { SiGoogleanalytics } from 'react-icons/si'
import { IoGlobeSharp, IoPeopleSharp, IoSettingsSharp } from 'react-icons/io5'
import {
    MdAttachment,
    MdOutlineSummarize,
    MdReport,
    // MdOutlineAnalytics,
    MdSavings,
} from 'react-icons/md'
// import CashierDashboard from '../pages/Cashier/CashierDashboard'
import PersonnelContainer from '../pages/Personnel/PersonnelContainer'
import Branch from '../pages/Settings/components/Branch'
// import SettingsContainer from '../pages/Settings/SettingsContainer'
import VehiclesDriversContainer from '../pages/VehiclesDriver/VehiclesDriverContainer'
import Scheduling from '../pages/Scheduling/Scheduling'
import LoansContainer from '../pages/Reports/Loans/LoansContainer'
import SharesContainer from '../pages/Shares/SharesContainer'
import SavingsContainer from '../pages/Savings/SavingsContainer'
import ExpensesContainer from '../pages/Expenses/ExpensesContainer'
import VehicleContainer from '../pages/VehiclesDriver/Vehicle'
import Driver from '../pages/VehiclesDriver/Driver'
import PersonnelDashboard from '../pages/Personnel/PersonnelDashboard'
import FinancialReport from '../pages/Dashboard/FinancialReport'
// import DashboardLayout from '../pages/Dashboard/DashboardLayout'
import AddMember from '../pages/Member/AddMember'
import MemberReceivableReport from '../pages/Reports/MembersReceivableReport/MembersReceivableReport'
import Payroll from '../pages/Accounting/Payroll'
import ProductsContainer from '../pages/Products/ProductsContainer'
import SettingsContainer from '../pages/Settings/SettingsContainer'
// import DashboardLayout from '../pages/Dashboard/DashboardLayout'
import { SiGoogleanalytics } from 'react-icons/si'
import ChangePassword from '../pages/My/ChangePassword'
import PerformanceContainer from '../pages/Performance/PerformanceContainer'
import MyTotalReceivable from '../pages/Member/components/MyTotalReceivable'
import AllFiles from '../components/Storage/AllFiles'
// import TeamContainer from '../pages/Teams/TeamContainer'
// import { HiUserGroup } from 'react-icons/hi2'
import TeamLeaderDashboard from '../pages/Teams/TeamLeaderDashboard'
import BookKeeperReports from '../pages/Accounting/BookKeeperReports'
import SchedulingMobileView from '../pages/Scheduling/SchedulingMobileView'
import BusDailyCollectionMobileView from '../pages/Treasurer/BusDailyCollectionMobileView'
// import { BiStore } from 'react-icons/bi'
// import StoreContainer from '../pages/Store/StoreContainer'
// import ProductsContainer from '../pages/Products/ProductsContainer'
// import MemberReceivableReport from '../pages/Reports/MembersReceivableReport/MembersReceivableReport'
export const iconStyle = {
    fontSize: 16,
}

const ProductInventory = {
    name: 'Product Inventory',
    icon: <FaCashRegister style={iconStyle} />,
    key: 'products',
    path: 'products',
    element: <ProductsContainer />,
}

// const StoreFront = {
//     name: 'Store',
//     icon: <BiStore style={iconStyle} />,
//     key: 'store',
//     path: 'store',
//     element: <StoreContainer />,
// }

// const ReportsAnalytics = {
//     name: 'Reports and Analytics',
//     icon: <SiGoogleanalytics style={iconStyle} />,
//     key: 'reports-and-analytics',
//     path: 'reports',
//     element: <DashboardLayout />,
// }

// const TeamsContainer = {
//     name: 'Teams',
//     icon: <HiUserGroup style={iconStyle} />,
//     key: 'reports-and-analytics',
//     path: 'reports',
//     element: <TeamContainer />,
// }

const PerformanceSnapshot = {
    name: 'Performance Snapshot',
    icon: <SiGoogleanalytics style={iconStyle} />,
    key: 'performance',
    path: 'performance',
    element: <PerformanceContainer />,
}
const Files = {
    name: 'Files',
    icon: <MdAttachment style={iconStyle} />,
    key: 'files',
    path: 'files',
    element: <AllFiles />,
}

const HR = {
    name: 'Human Resources',
    icon: <IoPeopleSharp style={iconStyle} />,
    key: 'personnel',
    path: 'hr',
    element: <PersonnelContainer />,
}
export const coreBankingRoutes: Array<MenuDataItem> = [
    {
        name: 'Loans',
        icon: <FaCashRegister style={iconStyle} />,
        key: 'loans',
        path: 'loans',
        element: <LoansContainer />,
    },
    {
        name: 'Member Ledger',
        key: 'receivable',
        path: 'receivable',
        icon: <TransactionOutlined style={iconStyle} />,
        element: <MemberReceivableReport />,
    },
    {
        name: 'Savings Accounts',
        icon: <MdSavings style={iconStyle} />,
        key: 'savings',
        path: 'savings',
        element: <SavingsContainer />,
    },
    {
        name: 'Share Capital',
        icon: <FaPercentage style={iconStyle} />,
        key: 'shares',
        path: 'shares',
        element: <SharesContainer />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'receivable/new-member',
        path: 'receivable/new-member',
        element: <AddMember />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'receivable/update-member/:uid',
        path: 'receivable/update-member/:uid',
        element: <AddMember />,
    },
]
export const hrRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    HR,
    {
        key: 'new-personnel',
        path: 'home/new-personnel',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'hr-user-view',
        path: 'home/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'hr-user-update',
        path: 'home/:personnelId/update',
        element: <Driver />,
    },
    {
        name: 'Payroll',
        icon: <DollarCircleFilled style={iconStyle} />,
        key: 'payroll',
        path: 'payroll',
        element: <Payroll />,
    },
    {
        name: 'Bus',
        icon: <FaBus style={iconStyle} />,
        key: 'transport',
        path: 'transport',
        element: <VehiclesDriversContainer />,
    },
    // {
    //     name: 'Vehicle Scheduling',
    //     icon: <FaBus style={iconStyle} />,
    //     key: 'transport',
    //     path: 'transport',
    //     element: <Scheduling />,
    // },
    // {
    //     path: 'transport/new-vehicle',
    //     key: 'transport-new-vehicle',
    //     element: <VehicleContainer />
    // },
    // {
    //     // name: 'Scheduling',
    //     // icon: <ScheduleOutlined style={iconStyle} />,
    //     key: 'transport-scheduling',
    //     path: 'transport/scheduling',
    //     element: <Scheduling />,
    // },
    // {
    //     // name: 'My Account',
    //     // icon: <DollarOutlined style={iconStyle} />,
    //     key: 'transport/scheduling/personnel',
    //     path: 'transport/scheduling/personnel/:userId',
    //     element: <PersonnelDashboard />
    // },
    // {
    //     // name: 'My Account',
    //     // icon: <DollarOutlined style={iconStyle} />,
    //     key: 'transport/scheduling/personnel',
    //     path: 'transport/scheduling/personnel/:personnelId/update',
    //     element: <Driver />
    // },
    // {
    //     // name: 'My Account',
    //     // icon: <DollarOutlined style={iconStyle} />,
    //     key: 'transport/personnel',
    //     path: 'transport/personnel/:userId',
    //     element: <PersonnelDashboard />
    // },
    // {
    //     // name: 'My Account',
    //     // icon: <DollarOutlined style={iconStyle} />,
    //     key: 'transport/personnel',
    //     path: 'transport/personnel/:personnelId/update',
    //     element: <Driver />
    // },
]

export const payrollSpecialistRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    {
        name: 'Payroll',
        icon: <DollarCircleFilled style={iconStyle} />,
        key: 'home',
        path: 'home',
        element: <Payroll />,
    },
]
export const acRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    // {
    //     name: 'Home',
    //     key: 'home',
    //     path: 'home',
    //     icon: <HomeFilled style={iconStyle} />,
    //     element: <FinancialReport />,
    // },
    {
        name: 'Finance',
        icon: <FaBook style={iconStyle} />,
        key: 'finance',
        path: 'finance',
        element: <ExpensesContainer />,
    },
    {
        name: 'Payroll',
        icon: <DollarCircleFilled style={iconStyle} />,
        key: 'payroll',
        path: 'payroll',
        element: <Payroll />,
    },
    // PerformanceSnapshot,
    {
        name: 'Bus',
        icon: <FaBus style={iconStyle} />,
        key: 'transport',
        path: 'transport',
        element: <VehiclesDriversContainer />,
        // children: [
        // ]
    },
    {
        path: 'transport/new-vehicle',
        key: 'transport-new-vehicle',
        element: <VehicleContainer />,
    },
    {
        // name: 'Scheduling',
        // icon: <ScheduleOutlined style={iconStyle} />,
        key: 'transport-scheduling',
        path: 'transport/scheduling',
        element: <Scheduling />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:personnelId/update',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:personnelId/update',
        element: <Driver />,
    },
]

export const bookKeeperRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    // PerformanceSnapshot,
    {
        name: 'Summary Reports',
        key: 'home',
        path: 'home',
        icon: <MdOutlineSummarize style={iconStyle} />,
        element: <BookKeeperReports />,
    },
    {
        name: 'Bus Daily Collection',
        icon: <FaBus style={iconStyle} />,
        key: 'transport',
        path: 'transport',
        element: <VehiclesDriversContainer />,
        // children: [
        // ]
    },
    {
        path: 'transport/new-vehicle',
        key: 'transport-new-vehicle',
        element: <VehicleContainer />,
    },
    {
        // name: 'Scheduling',
        // icon: <ScheduleOutlined style={iconStyle} />,
        key: 'transport-scheduling',
        path: 'transport/scheduling',
        element: <Scheduling />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:personnelId/update',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:personnelId/update',
        element: <Driver />,
    },
]

export const defaultRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    {
        name: 'Home',
        key: 'home',
        path: 'home',
        icon: <HomeFilled style={iconStyle} />,
        element: <PersonnelDashboard />,
    },
]
export const protectedRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    {
        name: 'Home',
        key: 'home',
        path: 'home',
        icon: <HomeFilled style={iconStyle} />,
        element: <FinancialReport />,
    },

    {
        name: 'Content Manager',
        icon: <IoGlobeSharp style={iconStyle} />,
        key: 'web-settings',
        path: 'web-settings',
        element: <Branch />,
    },
    {
        name: 'Core Banking',
        label: 'Core Banking',
        type: 'group',
        key: 'groupings',
        children: coreBankingRoutes,
        icon: (
            <FaLandmark
                style={{
                    ...iconStyle,
                    color: 'black',
                    opacity: 1,
                }}
            />
        ),
        // open: true,
        // selected: true,
    },
    {
        name: 'Finance',
        icon: <FaBook style={iconStyle} />,
        key: 'finance',
        path: 'finance',
        element: <ExpensesContainer />,
    },
    // Files,
    // {
    //     name: 'Members',
    //     icon: <TeamOutlined style={iconStyle} />,
    //     key: 'my-team',
    //     path: '/',
    // },
    HR,
    {
        key: 'new-personnel',
        path: 'hr/new-personnel',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'hr-user-view',
        path: 'hr/personnel/:personnelId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'hr-user-update',
        path: 'hr/:personnelId/update',
        element: <Driver />,
    },
    // StoreFront,
    // TeamsContainer,
    {
        name: 'Payroll',
        icon: <DollarCircleFilled style={iconStyle} />,
        key: 'payroll',
        path: 'payroll',
        element: <Payroll />,
    },
    PerformanceSnapshot,
    // ProductInventory,
    {
        name: 'Bus',
        icon: <FaBus style={iconStyle} />,
        key: 'transport',
        path: 'transport',
        element: <VehiclesDriversContainer />,
        // children: [
        // ]
    },
    {
        path: 'transport/new-vehicle',
        key: 'transport-new-vehicle',
        element: <VehicleContainer />,
    },
    {
        // name: 'Scheduling',
        // icon: <ScheduleOutlined style={iconStyle} />,
        key: 'transport-scheduling',
        path: 'transport/scheduling',
        element: <Scheduling />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:personnelId/update',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:personnelId/update',
        element: <Driver />,
    },
    {
        name: 'Settings',
        icon: <IoSettingsSharp style={iconStyle} />,
        key: 'settings',
        path: 'settings',
        element: <SettingsContainer />,
    },
    // {
    //     name: 'Announcements',
    //     icon: <ContainerOutlined style={iconStyle} />,
    //     key: 'announcements',
    //     path: '/announcements',
    // },
    // {
    //     name: 'Upload',
    //     icon: <DatabaseOutlined style={iconStyle} />,
    //     key: 'managedata',
    //     path: '/',
    // },
]
export const viewerRoutes: Array<MenuDataItem> = [
    {
        name: 'Dashboard',
        key: 'my-dashboard',
        path: '/my-dashboard',
        icon: <DashboardOutlined style={iconStyle} />,
    },

    // {
    //     name: 'My Loans',
    //     key: 'loans',
    //     path: '/loans',
    //     icon: <FaHandHoldingUsd style={iconStyle} />,
    // },
    // {
    //     name: 'My Team',
    //     icon: <TeamOutlined style={iconStyle} />,
    //     key: 'my-team',
    //     path: '/',
    // },
]

export const dispatcherRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    {
        name: 'Bus Daily Scheduling',
        icon: <FaBus style={iconStyle} />,
        key: 'home',
        path: 'home',
        element: <VehiclesDriversContainer />,
        // children: [
        // ]
    },
    {
        path: 'home/new-vehicle',
        key: 'transport-new-vehicle',
        element: <VehicleContainer />,
    },
    {
        // name: 'Scheduling',
        // icon: <ScheduleOutlined style={iconStyle} />,
        key: 'home-scheduling',
        path: 'home/scheduling',
        element: <Scheduling />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'home/scheduling/personnel',
        path: 'home/scheduling/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'home/scheduling/personnel',
        path: 'home/scheduling/personnel/:personnelId/update',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'home/personnel',
        path: 'home/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'home/personnel',
        path: 'home/personnel/:personnelId/update',
        element: <Driver />,
    },
]

export const fleetMgrRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    {
        name: 'Fleet Scheduling',
        icon: <HomeFilled style={iconStyle} />,
        key: 'home',
        path: 'home',
        element: <SchedulingMobileView />,
    },
]
export const cashierRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    // {
    //     name: 'Home',
    //     icon: <HomeFilled style={iconStyle} />,
    //     key: 'home',
    //     path: 'home',
    //     element: <CashierDashboard />,
    // },
    {
        name: 'Bus',
        icon: <FaBus style={iconStyle} />,
        key: 'transport',
        path: 'transport',
        element: <VehiclesDriversContainer />,
        // children: [
        // ]
    },
    {
        path: 'transport/new-vehicle',
        key: 'transport-new-vehicle',
        element: <VehicleContainer />,
    },
    {
        // name: 'Scheduling',
        // icon: <ScheduleOutlined style={iconStyle} />,
        key: 'transport-scheduling',
        path: 'transport/scheduling',
        element: <Scheduling />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:personnelId/update',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:personnelId/update',
        element: <Driver />,
    },
]
export const teamLeaderRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    {
        name: 'Dashboard',
        icon: <DashboardFilled style={iconStyle} />,
        // key: 'settings-userid',
        path: ':userId',
        element: <TeamLeaderDashboard />,
    },
]
// export const agentRoutes: Array<MenuDataItem> = [
//     {
//         // name: 'My Account',
//         // icon: <DollarOutlined style={iconStyle} />,
//         // key: 'settings-userid',
//         path: 'settings/:userId',
//         element: <ChangePassword />,
//     },
//     {
//         name: 'Dashboard',
//         icon: <DashboardFilled style={iconStyle} />,
//         // key: 'settings-userid',
//         path: ':userId',
//         element: <AgentDashboard />,
//     },
// ]

export const chairmanRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    PerformanceSnapshot,
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: ':userId',
        element: <MyTotalReceivable />,
    },
]
export const treasurerRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    {
        name: 'Daily Collection',
        icon: <HomeFilled style={iconStyle} />,
        key: 'home',
        path: 'home',
        element: <BusDailyCollectionMobileView />,
    },
]

export const memberRoutes: Array<MenuDataItem> = [
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: ':userId',
        element: <MyTotalReceivable />,
    },
]

export const itRoutes: Array<MenuDataItem> = [
    // {
    //     name: 'Home',
    //     icon: <HomeFilled style={iconStyle} />,
    //     key: 'home',
    //     path: 'home',
    //     element: <FinancialReport />,
    // },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:personnelId/update',
        element: <Driver />,
    },
    {
        name: 'Content Manager',
        icon: <IoGlobeSharp style={iconStyle} />,
        key: 'web-settings',
        path: 'web-settings',
        element: <Branch />,
    },
    {
        name: 'Finance',
        icon: <FaBook style={iconStyle} />,
        key: 'finance',
        path: 'finance',
        element: <ExpensesContainer />,
    },
    // {
    //     name: 'Human Resource',
    //     icon: <IoPeopleSharp style={iconStyle} />,
    //     key: 'hr',
    //     path: 'hr',
    //     element: <PersonnelContainer />,
    // },
    // ProductInventory,
    {
        key: 'new-personnel',
        path: 'hr/new-personnel',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'hr-user-view',
        path: 'hr/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'hr-user-update',
        path: 'hr/:personnelId/update',
        element: <Driver />,
    },
    PerformanceSnapshot,
    {
        name: 'Bus',
        icon: <FaBus style={iconStyle} />,
        key: 'transport',
        path: 'transport',
        element: <VehiclesDriversContainer />,
        // children: [
        // ]
    },
    {
        path: 'transport/new-vehicle',
        key: 'transport-new-vehicle',
        element: <VehicleContainer />,
    },
    {
        // name: 'Scheduling',
        // icon: <ScheduleOutlined style={iconStyle} />,
        key: 'transport-scheduling',
        path: 'transport/scheduling',
        element: <Scheduling />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:personnelId/update',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:personnelId/update',
        element: <Driver />,
    },
]
export const gmRoutes: Array<MenuDataItem> = [
    {
        name: 'Home',
        icon: <HomeFilled style={iconStyle} />,
        key: 'home',
        path: 'home',
        element: <FinancialReport />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:userId',
        element: <ChangePassword />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        // key: 'settings-userid',
        path: 'settings/:personnelId/update',
        element: <Driver />,
    },
    {
        name: 'Content Manager',
        icon: <IoGlobeSharp style={iconStyle} />,
        key: 'web-settings',
        path: 'web-settings',
        element: <Branch />,
    },
    {
        name: 'Core Banking',
        label: 'Core Banking',
        type: 'group',
        key: 'groupings',
        children: coreBankingRoutes,
        icon: (
            <FaLandmark
                style={{
                    ...iconStyle,
                    color: 'black',
                    opacity: 1,
                }}
            />
        ),
        // open: true,
        // selected: true,
    },
    Files,
    // {
    //     name: 'Human Resource',
    //     icon: <IoPeopleSharp style={iconStyle} />,
    //     key: 'hr',
    //     path: 'hr',
    //     element: <PersonnelContainer />,
    // },
    // ProductInventory,
    {
        key: 'new-personnel',
        path: 'hr/new-personnel',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'hr-user-view',
        path: 'hr/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'hr-user-update',
        path: 'hr/:personnelId/update',
        element: <Driver />,
    },
    {
        name: 'Payroll',
        icon: <DollarCircleFilled style={iconStyle} />,
        key: 'payroll',
        path: 'payroll',
        element: <Payroll />,
    },
    PerformanceSnapshot,
    {
        name: 'Bus',
        icon: <FaBus style={iconStyle} />,
        key: 'transport',
        path: 'transport',
        element: <VehiclesDriversContainer />,
        // children: [
        // ]
    },
    {
        path: 'transport/new-vehicle',
        key: 'transport-new-vehicle',
        element: <VehicleContainer />,
    },
    {
        // name: 'Scheduling',
        // icon: <ScheduleOutlined style={iconStyle} />,
        key: 'transport-scheduling',
        path: 'transport/scheduling',
        element: <Scheduling />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/scheduling/personnel',
        path: 'transport/scheduling/personnel/:personnelId/update',
        element: <Driver />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:userId',
        element: <PersonnelDashboard />,
    },
    {
        // name: 'My Account',
        // icon: <DollarOutlined style={iconStyle} />,
        key: 'transport/personnel',
        path: 'transport/personnel/:personnelId/update',
        element: <Driver />,
    },

    {
        name: 'Settings',
        icon: <IoSettingsSharp style={iconStyle} />,
        key: 'settings',
        path: 'settings',
        element: <SettingsContainer />,
    },
]
