import React, { useState } from 'react'
import { loanPayments } from '../utils'
import { message, Modal, Tooltip } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { Loan, LoanPayment } from '../../../../../domain/entities/Loan'
import UpdateLoanPayment, { MemberLoanData } from './UpdateLoanPayment'
// import { useAppDispatch } from '../../../../store'
import Table from '../../../../components/Table'
import { collection, deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../../../boot'

function LoanPaymentTable({
    data,
    loanData,
    handleCancel,
    onRemove,
}: {
    data: LoanPayment[]
    loanData: Loan
    handleCancel: () => void
    onRemove: (id: string) => void
}) {
    // const dispatch = useAppDispatch()
    // const [prevPayment, setPrevPayments] = useState(undefined)
    const [loan] = useState<MemberLoanData | undefined>()
    // const prevPayment = newPayments.pop()

    const [showPayLoan, setShowPayLoanData] = useState(false)

    // const handleEditLoanPayment = (row: LoanPayment) => {
    //     // const newPayments = [...data]
    //     // const prevPayment = newPayments.pop()
    //     const newData: MemberLoanData = {
    //         ...loanData,
    //         payments: [],
    //         prevPayment: row,
    //         name: '',
    //     }
    //     setLoan(newData)
    //     setShowPayLoanData(true)
    // }
    const handleDeleteLoanPayment = async (row: LoanPayment) => {
        // await dispatch(
        //     removeLoanPayment({
        //         loan: {
        //             id: loanData.id as string,
        //             partnerCode: loanData.partnerCode,
        //             creation: loanData.creation,
        //             memberId: loanData.memberId,
        //         },
        //         payment: row,
        //     })
        // )

        const paymentsCol = collection(
            db,
            'loans',
            row.loanId as string,
            'payments'
        )
        const paymentRef = doc(paymentsCol, row.id)
        await deleteDoc(paymentRef)
        message.success('Successfully deleted payment.')
        onRemove(row.id as string)
    }
    const handleCloseEditLoan = () => {
        setShowPayLoanData(false)
    }

    const handlePaySuccess = () => {
        // dispatch(getAllMemberLoans(activeTeam))
        // onSuccess()
        handleCancel()
    }

    // useEffect(() => {
    //     if (!data) return
    //     if (!showPayLoan) return

    //     setLoan(newData)
    // }, [data, loanData, showPayLoan])
    return (
        <div>
            {showPayLoan && loan && (
                <UpdateLoanPayment
                    data={loan}
                    onCancel={handleCloseEditLoan}
                    handleSuccess={handlePaySuccess}
                />
            )}
            <Table
                scroll={{ x: 800 }}
                dataSource={data}
                columns={[
                    ...loanPayments,
                    {
                        title: 'Actions',
                        key: 'option',
                        valueType: 'option',
                        render: (_, row, idx) => {
                            if (
                                row.modifiedBy === 'system' ||
                                row.modifiedBy === 'automated'
                            )
                                return null
                            if (loanPayments.length !== idx + 1) return []
                            return [
                                // <Tooltip
                                //     title={'Update loan payment'}
                                //     key={'edit-loan-payment1'}
                                //     placement={'topLeft'}
                                // >
                                //     <Button
                                //         type="link"
                                //         onClick={() =>
                                //             handleEditLoanPayment(row)
                                //         }
                                //     >
                                //         <EditOutlined />
                                //     </Button>
                                // </Tooltip>,
                                <Tooltip
                                    title={'Delete loan payment'}
                                    key={'pay-loan'}
                                    placement={'topLeft'}
                                >
                                    <DeleteOutlined
                                        style={{ color: 'red' }}
                                        onClick={() => {
                                            const title =
                                                'Are you sure to delete this payment?'

                                            Modal.confirm({
                                                centered: true,
                                                title,
                                                onOk: () =>
                                                    handleDeleteLoanPayment(
                                                        row
                                                    ),
                                                autoFocusButton: 'cancel',
                                            })
                                        }}
                                    />
                                </Tooltip>,
                            ]
                        },
                    },
                ]}
                search={false}
                toolBarRender={false}
                pagination={false}
            />
        </div>
    )
}

export default LoanPaymentTable
