import { Col, Result, Row } from 'antd'
import React, { useEffect } from 'react'
import { Button } from '../../common/Button'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { CONTACT_SUCCESS_REPOSITORY } from '../../../../../data/repositories/constants'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../../../boot'

function Success() {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    useEffect(() => {
        const main = async () => {
            const queryParameters = new URLSearchParams(location.search)
            const name = queryParameters.get('name')
            const email = queryParameters.get('email')
            const message = queryParameters.get('message')
            const phone = queryParameters.get('phone')
            if (!name || !email || !message || !phone) return
            const payload = {
                name,
                email,
                message,
                phone,
                creation: moment().unix(),
            }
            // eslint-disable-next-line no-console
            console.log(payload, params)
            try {
                const ref = collection(db, CONTACT_SUCCESS_REPOSITORY)
                await addDoc(ref, payload)
            } catch (err) {
                // eslint-disable-next-line no-console
                console.log(err)
            }
            navigate('/contact-success')
        }

        main()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <Row justify={'center'}>
                <Col lg={12} xs={24}>
                    <Result
                        status="success"
                        title="Thank You for Reaching Out!"
                        subTitle="Your inquiry has been successfully submitted! Thank you for choosing PERATI – together, let's revolutionize cooperative finance! We'll be reviewing your information and will get in touch with you shortly to discuss how we can best meet your needs."
                        extra={[
                            <a href="/">
                                <Button key="console">Go Back Home</Button>
                            </a>,
                        ]}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default Success
