import React, { useEffect, useState } from 'react'
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-components'
import {
    Col,
    Divider,
    Form,
    Input,
    message,
    Row,
    Space,
    Typography,
} from 'antd'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
    updateSystemConfig,
    updateSystemState,
} from '../../../redux/system.slice'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Expense } from '../../../../domain/entities/SystemSettings'
import { shallowEqual } from 'react-redux'
import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    updateDoc,
} from 'firebase/firestore'
import {
    EXPENSE_REPOSITORY,
    TEAM_REPOSITORY,
} from '../../../../data/repositories/constants'
import { db } from '../../../boot'
import { cleanString } from '../../../../app/components/script'

function NewExpense() {
    const { showNewExpense, categories, email, styles, activeTeam } =
        useAppSelector(
            (state) => ({
                showNewExpense: state.systemConfig.showNewExpense,
                // categories: state.systemConfig.expenseCategories,
                email: state.auth.user.email,
                styles: state.styles,
                activeTeam: state.team.selectedTeamData.partnerCode,
                categories: state.team.selectedTeamData.expenseCategories,
            }),
            shallowEqual
        )
    const [expenseCategories, setExpenseCategories] = useState(categories)

    const [expensesLabelKey, setExpensesLabelKey] = useState<any>([])
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState('')
    const dispatch = useAppDispatch()

    const [form] = Form.useForm()
    // const handleFieldChange = (field: any) => {
    //     const key = Object.keys(field)[0]
    //     if (key === 'loanSelection') {
    //         setSelectedLoan(field[key])
    //     }
    // }
    const handleCancel = () => {
        dispatch(
            updateSystemState({
                showNewExpense: false,
            })
        )
    }

    const handleSubmit = async (data: any) => {
        setLoading(true)
        const {
            amount,
            title,
            purpose,
            chequeNumber,
            category,
            requestDate,
            referenceNumber,
        } = data
        const payload: Expense = {
            amount,
            title,
            purpose,
            approvedDate: 0,
            releaseDate: 0,
            requestDate: moment(requestDate).unix(),
            category,
            creation: moment().unix(),
            modified: moment().unix(),
            modifiedBy: email,
            isRemoved: false,
            partnerCode: activeTeam,
            status: 'draft',
            referenceNumber,
            type: 'payable',
            chequeNumber: chequeNumber || '',
        }
        await addDoc(collection(db, EXPENSE_REPOSITORY), payload)
        // await dispatch(addExpense(payload))
        setLoading(false)
        form.resetFields()
        setSelectedCategory('')
        message.success('Expense successfully added')
        handleCancel()
    }

    const handleAddExpenseCategory = (data: any) => {
        setName(data.target.value)
    }

    const handleAddItem = async (e: {
        preventDefault: () => void
        stopPropagation: () => void
    }) => {
        e.preventDefault()
        e.stopPropagation()
        if (!name) return
        const cleanName = cleanString(name)
        if (expenseCategories.includes(cleanName)) {
            message.error(`${name} already exists in expense categories.`)
            return
        }

        await updateDoc(doc(db, TEAM_REPOSITORY, activeTeam), {
            expenseCategories: arrayUnion(cleanName.toLowerCase()),
        })
        const expenseCategoriesCopy = [
            ...expenseCategories,
            cleanName.toLowerCase(),
        ]
        dispatch(
            updateSystemConfig({
                expenseCategories: expenseCategoriesCopy,
            })
        )
        message.success(`Successfully added ${cleanName}.`)

        setExpenseCategories(expenseCategoriesCopy)
        setName('')
    }

    const handleDeleteCategory = async (e: any, key: string) => {
        e.preventDefault()
        e.stopPropagation()
        const newExpenseCategories = [...expenseCategories]
        const keyIdx = newExpenseCategories.indexOf(key)
        if (keyIdx >= 0) {
            newExpenseCategories.splice(keyIdx, 1)
            dispatch(
                updateSystemConfig({
                    expenseCategories: newExpenseCategories,
                })
            )
            await updateDoc(doc(db, TEAM_REPOSITORY, activeTeam), {
                expenseCategories: arrayRemove(key),
            })
            message.success('Successfully delete category.')
            setExpenseCategories(newExpenseCategories)
        }
    }

    useEffect(() => {
        const mapped = expenseCategories.map((expenseCategory: string) => ({
            label: expenseCategory,
            key: expenseCategory,
        }))
        setExpensesLabelKey(
            mapped.sort((a, b) => a.label.localeCompare(b.label))
        )
    }, [expenseCategories])

    useEffect(() => {
        setExpenseCategories(categories)
    }, [categories])

    return (
        <div>
            <ModalForm<Expense>
                form={form}
                onFinishFailed={(err) => {
                    if (err.errorFields.length) {
                        const firstError = err.errorFields[0]
                        message.destroy()
                        message.error(firstError.errors)
                        form.scrollToField(firstError.name, {
                            behavior: 'smooth',
                            block: 'center',
                        })
                    }
                }}
                title={
                    <Typography.Title level={3}>
                        Operating Expenditures
                    </Typography.Title>
                }
                open={showNewExpense}
                autoFocusFirstInput
                modalProps={{
                    onCancel: handleCancel,
                    okText: 'Submit',
                    centered: true,
                    onOk: () => {
                        form.submit()
                    },
                }}
                onFinish={handleSubmit}
                initialValues={{
                    creation: moment(),
                }}
                width={500}
                submitter={{
                    submitButtonProps: {
                        style: {
                            backgroundColor: styles.primary,
                            color: 'white',
                        },
                        loading: loading,
                        onClick: () => {
                            form.submit()
                        },
                    },
                }}
                // onValuesChange={handleFieldChange}
            >
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <ProFormText
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            name="title"
                            label="Vendor"
                            placeholder="Please Enter"
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            name="referenceNumber"
                            label="Reference No."
                            placeholder="Please Enter"
                        />
                    </Col>
                </Row>

                <Row wrap={false} gutter={[12, 12]}>
                    <Col span={12}>
                        <ProFormDigit
                            rules={[
                                {
                                    required: true,
                                    type: 'number',
                                    message: 'This field is required',
                                },
                            ]}
                            width="sm"
                            name="amount"
                            label="Amount"
                            placeholder="Please Enter"
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSelect
                            placeholder={'Select category'}
                            label={'Operating Expenditure Category'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            name={'category'}
                            options={expensesLabelKey}
                            fieldProps={{
                                showSearch: true,
                                value: selectedCategory,
                                // open: categoryOpen,
                                // onDropdownVisibleChange: (val) =>
                                //     setCategoryOpen(val),
                                dropdownRender: (menu: any) => (
                                    <div style={{ width: 200 }}>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            align="center"
                                            style={{
                                                padding: '0 8px 4px',
                                            }}
                                        >
                                            <Input
                                                placeholder="Enter here"
                                                value={name}
                                                onChange={
                                                    handleAddExpenseCategory
                                                }
                                                autoFocus
                                            />
                                            <Typography.Link
                                                onClick={handleAddItem}
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                }}
                                                disabled={name === ''}
                                            >
                                                <PlusOutlined /> Add account
                                            </Typography.Link>
                                        </Space>
                                    </div>
                                ),
                                optionItemRender: (item: any) => {
                                    return (
                                        <>
                                            <Row
                                                wrap={false}
                                                justify={'space-between'}
                                            >
                                                <Col
                                                    span={22}
                                                    onClick={() =>
                                                        setSelectedCategory(
                                                            item.key
                                                        )
                                                    }
                                                >
                                                    {item.label}
                                                </Col>
                                                <Col span={2}>
                                                    <DeleteOutlined
                                                        onClick={(e) =>
                                                            handleDeleteCategory(
                                                                e,
                                                                item.key
                                                            )
                                                        }
                                                        style={{
                                                            color: 'red',
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <ProFormDatePicker
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            name={'requestDate'}
                            label={'Date'}
                            fieldProps={{
                                format: 'MMMM DD, YYYY',
                                style: {
                                    width: '100%',
                                },
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <ProFormText
                            name="chequeNumber"
                            label="Cheque No."
                            placeholder="Please Enter"
                        />
                    </Col>
                    {/* <ProFormDatePicker
                        fieldProps={{
                            format: 'MMMM DD, YYYY',
                        }}
                        name={'approvedDate'}
                        label={'Approval Date'}
                        initialValue={moment()}
                    />
                    <ProFormDatePicker
                        fieldProps={{
                            format: 'MMMM DD, YYYY',
                        }}
                        name={'releaseDate'}
                        label={'Release Date'}
                        initialValue={moment()}
                    /> */}
                </Row>
                <ProFormTextArea
                    name={'purpose'}
                    label={'Purpose'}
                    style={{
                        width: '100%',
                    }}
                />
            </ModalForm>
        </div>
    )
}

export default NewExpense
