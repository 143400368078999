import { ProColumns } from '@ant-design/pro-components'
import { MemberReceivable } from '../../../../domain/entities/MemberReceivable'
import { Statistic, Typography } from 'antd'
import React from 'react'
import { Member } from '@/domain/entities/Member'
export const annualDividendColumns: ProColumns<Member>[] = [
    {
        title: 'Name',
        dataIndex: 'displayName',
        order: 3,
    },
    // {
    //     title: 'Preferred S.',
    //     dataIndex: 'memberPreferredShares',
    //     hideInSearch: true,
    //     sorter: (a: any, b: any) =>
    //         a.memberPreferredShares - b.memberPreferredShares,
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 value={text}
    //                 prefix={<Typography.Text>₱</Typography.Text>}
    //                 prefixCls={'₱'}
    //                 precision={2}
    //             />
    //         )
    //     },
    // },
    {
        title: 'Share Capital',
        dataIndex: 'memberShares',
        order: 4,
        search: false,
        sorter: (a: Member, b: Member) => a.memberShares - b.memberShares,
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Total Loan',
        dataIndex: 'overallLoan',
        order: 4,
        search: false,
        sorter: (a: any, b: any) => a.overallLoan - b.overallLoan,
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Loan Balance',
        dataIndex: 'totalRemainingBalance',
        order: 4,
        search: false,
        sorter: (a: any, b: any) =>
            a.totalRemainingBalance - b.totalRemainingBalance,
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Account Balance',
        dataIndex: 'currentSavingsBalance',
        order: 4,
        search: false,
        sorter: (a: any, b: any) =>
            a.currentSavingsBalance - b.currentSavingsBalance,
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    // {
    //     title: 'Interest Paid',
    //     dataIndex: 'totalIncomeContribution',
    //     order: 4,
    //     search: false,
    //     sorter: (a: any, b: any) =>
    //         a.totalIncomeContribution - b.totalIncomeContribution,
    //     hideInSearch: true,
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 value={text}
    //                 prefix={<Typography.Text>₱</Typography.Text>}
    //                 prefixCls={'₱'}
    //                 precision={2}
    //             />
    //         )
    //     },
    // },
    // {
    //     title: 'Weighted Contribution',
    //     dataIndex: 'weightedContributions',
    //     search: false,
    //     sorter: (a: any, b: any) =>
    //         a.weightedContributions - b.weightedContributions,
    //     hideInSearch: true,
    //     order: 5,
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 value={text}
    //                 prefix={<Typography.Text>₱</Typography.Text>}
    //                 prefixCls={'₱'}
    //                 precision={2}
    //             />
    //         )
    //     },
    // },
    // {
    //     title: 'Allocation Ratio',
    //     key: 'percentage',
    //     dataIndex: 'percentage',
    //     hideInSearch: true,
    //     renderText: (
    //         text: number
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 value={(text * 100).toFixed(1)}
    //                 suffix={<Typography.Text>%</Typography.Text>}
    //                 prefixCls={'%'}
    //             />
    //         )
    //     },
    // },
    // {
    //     title: 'Gain',
    //     key: 'dividendByWeightedShare',
    //     dataIndex: 'dividendByWeightedShare',
    //     sorter: (a: any, b: any) =>
    //         a.dividendByWeightedShare - b.dividendByWeightedShare,
    //     hideInSearch: true,
    //     order: 6,
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 value={text}
    //                 prefix={<Typography.Text>₱</Typography.Text>}
    //                 prefixCls={'₱'}
    //                 precision={2}
    //             />
    //         )
    //     },
    // },
    // {
    //     title: 'Gross',
    //     key: 'gross',
    //     dataIndex: 'gross',
    //     sorter: (a: any, b: any) => a.gross - b.gross,
    //     hideInSearch: true,
    //     order: 7,
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 value={text}
    //                 prefix={<Typography.Text>₱</Typography.Text>}
    //                 prefixCls={'₱'}
    //                 precision={2}
    //             />
    //         )
    //     },
    // },

    // {
    //     title: 'Deductions',
    //     key: 'totalDeductions',
    //     dataIndex: 'totalDeductions',
    //     hideInSearch: true,
    //     order: 8,

    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 valueStyle={{ color: '#cf1322' }}
    //                 value={text}
    //                 prefix={
    //                     <Typography.Text style={{ color: '#cf1322' }}>
    //                         -₱
    //                     </Typography.Text>
    //                 }
    //                 prefixCls={'-₱'}
    //                 precision={2}
    //             />
    //         )
    //     },
    // },
]
export const membersReceivableColumns: ProColumns<MemberReceivable>[] = [
    {
        dataIndex: 'index',
        valueType: 'indexBorder',
        title: 'No.',
        width: 48,
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Contributions',
        dataIndex: 'contributions',
        search: false,
        sorter: (a: any, b: any) => a.contributions - b.contributions,
        hideInSearch: true,
        order: 4,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Weighted Contributions',
        dataIndex: 'weightedContributions',
        search: false,
        sorter: (a: any, b: any) =>
            a.weightedContributions - b.weightedContributions,
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Percentage',
        dataIndex: 'percentage',
        search: false,
        sorter: (a, b) => a.percentage - b.percentage,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            const newText = text * 100
            return (
                <Statistic
                    value={newText}
                    suffix={<Typography.Text>%</Typography.Text>}
                    prefixCls={'%'}
                    precision={2}
                />
            )
        },
    },
    // {
    //     title: 'Principal Percentage',
    //     dataIndex: 'dividendByWeightedPrincipalAverage',
    //     search: false,
    //     sorter: (a: any, b: any) =>
    //         a.dividendByWeightedLoanAverage - b.dividendByWeightedLoanAverage,
    //     align: 'right',
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         const newText = text * 100
    //         return `${parseFloat(newText.toFixed(2))}%`
    //     },
    // },
    // {
    //     title: 'Dividend',
    //     key: 'dividendByWeightedShare',
    //     dataIndex: 'dividendByWeightedShare',
    //     sorter: (a: any, b: any) =>
    //         a.dividendByWeightedShare - b.dividendByWeightedShare,
    //     hideInSearch: true,
    //     align: 'right',
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 value={text}
    //                 prefix={<Typography.Text>₱</Typography.Text>}
    //                 prefixCls={'₱'}
    //                 precision={2}
    //             />
    //         )
    //     },
    // },
    // {
    //     title: 'Gross',
    //     key: 'gross',
    //     dataIndex: 'gross',
    //     sorter: (a: any, b: any) => a.gross - b.gross,
    //     hideInSearch: true,
    //     align: 'right',
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 value={text}
    //                 prefix={<Typography.Text>₱</Typography.Text>}
    //                 prefixCls={'₱'}
    //                 precision={2}
    //             />
    //         )
    //     },
    // },
    //
    // {
    //     align: 'right',
    //     title: 'Deductions',
    //     key: 'totalDeductions',
    //     dataIndex: 'totalDeductions',
    //     hideInSearch: true,
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return `-₱${text.toFixed(2)}`
    //     },
    // },
    //
    // {
    //     title: 'Net',
    //     key: 'net',
    //     dataIndex: 'net',
    //     sorter: true,
    //     hideInSearch: true,
    //     align: 'right',
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 value={text}
    //                 prefix={<Typography.Text>₱</Typography.Text>}
    //                 prefixCls={'₱'}
    //                 precision={2}
    //             />
    //         )
    //     },
    // },
]
export const membersReceivableColumnsOverall: ProColumns<any>[] = [
    {
        dataIndex: 'index',
        valueType: 'indexBorder',
        title: 'No.',
        width: 48,
    },
    {
        title: 'Net',
        key: 'net',
        dataIndex: 'net',
        sorter: true,
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Contributions',
        dataIndex: 'contributions',
        search: false,
        sorter: (a: any, b: any) => a.contributions - b.contributions,
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Weighted Contributions',
        dataIndex: 'weightedContributions',
        search: false,
        sorter: (a: any, b: any) =>
            a.weightedContributions - b.weightedContributions,
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    // {
    //     title: 'Principal Percentage',
    //     dataIndex: 'dividendByWeightedPrincipalAverage',
    //     search: false,
    //     sorter: (a: any, b: any) =>
    //         a.dividendByWeightedLoanAverage - b.dividendByWeightedLoanAverage,
    //     align: 'right',
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         const newText = text * 100
    //         return `${parseFloat(newText.toFixed(2))}%`
    //     },
    // },
    {
        title: 'Profit',
        key: 'dividendByWeightedShare',
        dataIndex: 'dividendByWeightedShare',
        sorter: (a: any, b: any) =>
            a.dividendByWeightedShare - b.dividendByWeightedShare,
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Gross',
        key: 'gross',
        dataIndex: 'gross',
        sorter: (a: any, b: any) => a.gross - b.gross,
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },

    {
        title: 'Deductions',
        key: 'totalDeductions',
        dataIndex: 'totalDeductions',
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    valueStyle={{ color: '#cf1322' }}
                    value={text}
                    prefix={
                        <Typography.Text style={{ color: '#cf1322' }}>
                            -₱
                        </Typography.Text>
                    }
                    prefixCls={'-₱'}
                    precision={2}
                />
            )
        },
    },
]

export const membersReceivableHeadersExport: any = [
    ...membersReceivableColumns,
].map((column) => {
    return {
        label: column.title,
        key: column.dataIndex,
    }
})

export const keyValueDates = [
    {
        label: 'January',
        value: 12,
    },
    {
        label: 'February',
        value: 11,
    },
    {
        label: 'March',
        value: 10,
    },
    {
        label: 'April',
        value: 9,
    },
    {
        label: 'May',
        value: 8,
    },
    {
        label: 'June',
        value: 7,
    },
    {
        label: 'July',
        value: 6,
    },
    {
        label: 'August',
        value: 5,
    },
    {
        label: 'September',
        value: 4,
    },
    {
        label: 'October',
        value: 3,
    },
    {
        label: 'November',
        value: 2,
    },
    {
        label: 'December',
        value: 1,
    },
    {
        label: 'Annual',
        value: 0,
    },
]
