import React from 'react'
import { Typography } from 'antd'
import { TitleProps } from 'antd/lib/typography/Title'

interface TemplateProp {
    children: React.ReactNode
    style?: React.CSSProperties
    level?: TitleProps['level']
}
export const SettingsTitle = ({ children, style, level }: TemplateProp) => {
    return (
        <Typography.Title level={level || 3} style={style}>
            {children}
        </Typography.Title>
    )
}

export const SettingsDescription = ({ children }: TemplateProp) => {
    return <Typography.Paragraph>{children}</Typography.Paragraph>
}

export const SettingsFooterDescription = ({ children }: TemplateProp) => {
    return (
        <Typography.Paragraph
            style={{
                fontSize: 14,
                margin: '10px 0 0 0',
            }}
        >
            {children}
        </Typography.Paragraph>
    )
}

export const FooterDescription = ({ children, style }: TemplateProp) => {
    return (
        <Typography.Text type={'secondary'} style={style}>
            {children}
        </Typography.Text>
    )
}
