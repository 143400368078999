import { MemberRepository } from '../../../domain/repositories/member.repository'
import { Member } from '../../../domain/entities/Member'
import moment from 'moment'
import { Loan } from '../../../domain/entities/Loan'
import { Share } from '../../../domain/entities/Share'
// import { getWeight } from '../../../app/pages/Reports/MembersReceivableReport/helper'
import { message } from 'antd'

export class MemberLocalImplementation implements MemberRepository {
    setStorage(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data))
    }
    getStorage(key: string): undefined | any {
        const item = localStorage.getItem(key)
        if (item) {
            return JSON.parse(item)
        } else {
            return undefined
        }
    }
    addRemainingBalanceToSystem(yearOfLoan: string, amount: number) {
        const key = 'system-settings'
        const sysData = this.getStorage(key)
        if (sysData) {
            if (!sysData.remainingBalance[yearOfLoan]) {
                sysData.remainingBalance[yearOfLoan] = 0
            }
            sysData.remainingBalance[yearOfLoan] += amount
            this.setStorage(key, sysData)
        }
    }
    addUnpaidInterestToSystem(
        yearOfPayment: string,
        amount: number,
        remove?: true
    ) {
        const key = 'system-settings'
        const sysData = this.getStorage(key)
        if (sysData) {
            if (!sysData.overallUnpaidInterest[yearOfPayment]) {
                sysData.overallUnpaidInterest[yearOfPayment] = 0
            }
            if (!remove) {
                sysData.overallUnpaidInterest[yearOfPayment] += amount
            } else {
                sysData.overallUnpaidInterest[yearOfPayment] -= amount
            }
            this.setStorage(key, sysData)
        }
    }
    // addShareToSystem(yearOfShare: string, amount: number) {
    //     const key = 'system-settings'
    //     const sysData: SystemSettings = this.getStorage(key)
    //     if (sysData) {
    //         sysData.memberShares[yearOfShare] += amount
    //         this.setStorage(key, sysData)
    //     }
    // }

    addIncomeToSystem(
        yearOfPayment: string,
        amount: number,
        principalPayment: number,
        remainingBalance: number
    ) {
        const key = 'system-settings'
        const sysData = this.getStorage(key)
        if (sysData) {
            if (!sysData.overallIncome[yearOfPayment]) {
                sysData.overallIncome[yearOfPayment] = 0
            }
            sysData.overallIncome[yearOfPayment] += amount

            if (!sysData.overallPrincipal[yearOfPayment]) {
                sysData.overallPrincipal[yearOfPayment] = 0
            }
            sysData.overallPrincipal[yearOfPayment] += principalPayment
            sysData.remainingBalance[yearOfPayment] += remainingBalance
            this.setStorage(key, sysData)
        }
    }

    addLoanToSystem(
        yearOfLoan: string,
        amount: number,
        remainingBalance: number
    ) {
        const key = 'system-settings'
        const sysData = this.getStorage(key)
        if (sysData) {
            if (!sysData.overallLoan[yearOfLoan]) {
                sysData.overallLoan[yearOfLoan] = 0
            }
            sysData.overallLoan[yearOfLoan] += amount

            if (!sysData.remainingBalance[yearOfLoan]) {
                sysData.remainingBalance[yearOfLoan] = 0
            }
            sysData.remainingBalance[yearOfLoan] += remainingBalance
            this.setStorage(key, sysData)
        }
    }
    calculateMemberLoanIncome(loan: Loan, remove?: boolean) {
        const member = this.findMember(loan.memberId)
        if (member) {
            // const memberIncomeContribution: Member['incomeContribution'] = {
            //     ...member.incomeContribution,
            // }
            // const payments = loan.payments
            // payments.forEach((payment) => {
            //     const income = payment.interest - payment.paidPendingInterest
            //     const principalPayment = payment.principal
            //     const monthOfPayment = moment
            //         .unix(payment.creation)
            //         .format('MMMM')
            //     const yearOfPayment = moment
            //         .unix(payment.creation)
            //         .format('YYYY')
            //     if (!memberIncomeContribution[yearOfPayment]) {
            //         memberIncomeContribution[yearOfPayment] = {
            //             Annual: 0,
            //         }
            //     }
            //     if (!memberIncomeContribution[yearOfPayment][monthOfPayment]) {
            //         memberIncomeContribution[yearOfPayment][monthOfPayment] = 0
            //     }
            //     if (!remove) {
            //         memberIncomeContribution[yearOfPayment]['Annual'] += income
            //         memberIncomeContribution[yearOfPayment][monthOfPayment] +=
            //             income
            //         member.totalIncomeContribution += income
            //         this.addIncomeToSystem(
            //             yearOfPayment,
            //             income,
            //             principalPayment,
            //             -principalPayment
            //         )
            //     } else {
            //         memberIncomeContribution[yearOfPayment]['Annual'] -= income
            //         memberIncomeContribution[yearOfPayment][monthOfPayment] -=
            //             income
            //         member.totalIncomeContribution -= income
            //         this.addIncomeToSystem(
            //             yearOfPayment,
            //             -income,
            //             -principalPayment,
            //             principalPayment
            //         )
            //     }
            // })
            // member.incomeContribution = memberIncomeContribution
            // const resp = this.UpdateMember(member)
            // if (resp) return resp
        }

        return undefined
    }
    calculateMemberLoan(payload: Loan, remove?: true) {
        // const monthOfLoan = moment.unix(payload.creation).format('MMMM')
        // const yearOfLoan = moment.unix(payload.creation).format('YYYY')
        const member = this.findMember(payload.memberId)
        if (member) {
            // const memberLoan: Member['memberLoan'] = {
            //     ...member.memberLoan,
            // }
            // if (!memberLoan[yearOfLoan]) {
            //     memberLoan[yearOfLoan] = {
            //         Annual: 0,
            //     }
            // }
            // if (!memberLoan[yearOfLoan][monthOfLoan]) {
            //     memberLoan[yearOfLoan][monthOfLoan] = 0
            // }
            // let newMember
            // if (!remove) {
            //     memberLoan[yearOfLoan]['Annual'] += payload.amount
            //     memberLoan[yearOfLoan][monthOfLoan] += payload.amount
            //     this.addLoanToSystem(
            //         yearOfLoan,
            //         payload.amount,
            //         payload.remainingBalance
            //     )
            //     newMember = this.calculateMemberLoanIncome(payload)
            //     if (newMember) {
            //         newMember.totalLoan += payload.amount
            //     }
            // } else {
            //     memberLoan[yearOfLoan]['Annual'] -= payload.amount
            //     memberLoan[yearOfLoan][monthOfLoan] -= payload.amount
            //     this.addLoanToSystem(
            //         yearOfLoan,
            //         -payload.amount,
            //         -payload.remainingBalance
            //     )
            //     newMember = this.calculateMemberLoanIncome(payload, true)
            //     if (newMember) {
            //         newMember.totalLoan -= payload.amount
            //     }
            // }
            // if (newMember) {
            //     newMember = {
            //         ...newMember,
            //         memberLoan,
            //     }
            //     const resp = this.UpdateMember(newMember)
            //     if (resp) return true
            // }
        }
        return false
    }
    // addShareToMember(share: Share) {
    //     const member = this.findMember(share.memberId)
    //     if (member) {
    //         member.totalShare += share.amount
    //         const resp = this.UpdateMember(member)
    //         if (resp) return true
    //     }
    //     return false
    // }
    addShareToSystem(yearOfShare: string, amount: number) {
        const key = 'system-settings'
        const sysData = this.getStorage(key)
        if (sysData) {
            if (!sysData.memberShares[yearOfShare]) {
                sysData.memberShares[yearOfShare] = 0
            }
            sysData.memberShares[yearOfShare] += amount
            this.setStorage(key, sysData)
        }
    }
    addWeightedShareToSystem(yearOfShare: string, weightedShare: number) {
        const key = 'system-settings'
        const sysSettings = this.getStorage(key)
        if (sysSettings) {
            if (!sysSettings.weightedMemberShares[yearOfShare]) {
                sysSettings.weightedMemberShares[yearOfShare] = 0
            }

            sysSettings.weightedMemberShares[yearOfShare] += weightedShare
            this.setStorage(key, sysSettings)
        }
    }
    calculateMemberShare(payload: Share, remove?: boolean) {
        // const monthOfShare = moment.unix(payload.creation).format('MMMM')
        // const yearOfShare = moment.unix(payload.creation).format('YYYY')
        // const weights = getWeight(payload.creation) || 0
        // const weightedShare = weights * payload.amount
        const member = this.findMember(payload.memberId)
        if (member) {
            // const weightedMemberShare: Member['weightedMemberShare'] = {
            //     ...member.weightedMemberShare,
            // }
            // const memberShare: Member['memberShare'] = {
            //     ...member.memberShare,
            // }
            // if (!weightedMemberShare[yearOfShare]) {
            //     weightedMemberShare[yearOfShare] = {
            //         Annual: 0,
            //     }
            // }
            // if (!memberShare[yearOfShare]) {
            //     memberShare[yearOfShare] = {
            //         Annual: 0,
            //     }
            // }
            // if (!weightedMemberShare[yearOfShare][monthOfShare]) {
            //     weightedMemberShare[yearOfShare][monthOfShare] = 0
            // }
            // if (!memberShare[yearOfShare][monthOfShare]) {
            //     memberShare[yearOfShare][monthOfShare] = 0
            // }
            // if (!remove) {
            //     weightedMemberShare[yearOfShare]['Annual'] += weightedShare
            //     weightedMemberShare[yearOfShare][monthOfShare] += weightedShare
            //     memberShare[yearOfShare]['Annual'] += payload.amount
            //     memberShare[yearOfShare][monthOfShare] += payload.amount
            //     member.totalShare += payload.amount
            //     this.addShareToSystem(yearOfShare, payload.amount)
            //     this.addWeightedShareToSystem(yearOfShare, weightedShare)
            // } else {
            //     weightedMemberShare[yearOfShare]['Annual'] -= weightedShare
            //     weightedMemberShare[yearOfShare][monthOfShare] -= weightedShare
            //     memberShare[yearOfShare]['Annual'] -= payload.amount
            //     memberShare[yearOfShare][monthOfShare] -= payload.amount
            //     member.totalShare -= payload.amount
            //     this.addShareToSystem(yearOfShare, -payload.amount)
            //     this.addWeightedShareToSystem(yearOfShare, -weightedShare)
            // }
            // member.weightedMemberShare = weightedMemberShare
            // member.memberShare = memberShare
            // const newMemb = this.UpdateMember(member)
        }
    }
    removeMemberShares(memberId: string): boolean {
        const keys = 'shares'
        const shares = this.getStorage(keys)
        if (shares) {
            shares.forEach((share: Share) => {
                if (share.memberId === memberId) {
                    try {
                        // const yearOfShare = moment
                        //     .unix(share.creation)
                        //     .format('YYYY')
                        // const weights = getWeight(share.creation)
                        // const weightedShare = share.amount * weights
                        this.calculateMemberShare(share, true)
                    } catch (err) {
                        if (err instanceof Error) {
                            message.error(err.message)
                        } else {
                            message.error('An unknown error occurred.')
                        }
                    }
                    // this.calculateWeightedMemberShare(share, true)
                }
            })
            const filteredShares = [...shares].filter(
                (share) => share.memberId !== memberId
            )
            this.setStorage(keys, filteredShares)
            return true
        }
        return false
    }

    removeMemberLoans(memberId: string): boolean {
        const keys = 'loans'
        const loans = this.getStorage(keys)
        if (loans) {
            loans.forEach((loan: Loan) => {
                if (loan.memberId === memberId) {
                    try {
                        this.calculateMemberLoan(loan, true)
                        const yearOfLoan = moment
                            .unix(loan.creation)
                            .format('YYYY')
                        this.addUnpaidInterestToSystem(
                            yearOfLoan,
                            loan.pendingInterest,
                            true
                        )
                        this.addRemainingBalanceToSystem(
                            yearOfLoan,
                            -loan.totalPrincipalPaid
                        )
                    } catch (err) {
                        if (err instanceof Error) {
                            message.error(err.message)
                        } else {
                            message.error('An unknown error occurred.')
                        }
                    }
                }
            })
            const filteredLoan = [...loans].filter(
                (loan) => loan.memberId !== memberId
            )
            this.setStorage(keys, filteredLoan)
            return true
        }
        return false
    }

    findMember(id: string): Member | undefined {
        const key = 'members'
        const members: any[] | undefined = this.getStorage(key)
        if (members) {
            const memberIdx = members.findIndex(
                (member: Member) => member.id === id
            )
            if (memberIdx >= 0) {
                return members[memberIdx]
            }
        }
        return undefined
    }
    AddMember(payload: Member) {
        const key = 'members'
        const members = this.getStorage(key)
        if (members) {
            members.push(payload)
            this.setStorage(key, members)
            return payload
        } else {
            const members = [payload]
            this.setStorage(key, members)
            return payload
        }
    }
    GetMembers() {
        const key = 'members'
        const members = this.getStorage(key)
        if (members) return members
        return []
    }
    RemoveMember(id: string) {
        const key = 'members'
        const members: any[] = this.getStorage(key)
        if (members) {
            const resp = this.removeMemberShares(id)
            this.removeMemberLoans(id)
            if (resp) {
                const idx = members.findIndex(
                    (member: Member) => member.id === id
                )
                members.splice(idx, 1)
                this.setStorage(key, members)
                return id
            }
        }
        return undefined
    }
    UpdateMember(payload: Member) {
        payload.modified = moment().unix()
        const key = 'members'
        const members = this.getStorage(key)
        if (members) {
            const idx = members.findIndex(
                (member: Member) => member.id === payload.id
            )
            members[idx] = payload
            this.setStorage(key, members)
            return payload
        }
        return undefined
    }
    GetMember(id: string) {
        const key = 'members'
        const members = this.getStorage(key)
        if (members) {
            const idx = members.findIndex((member: Member) => member.id === id)
            return members[idx]
        }
        return false
    }
}
