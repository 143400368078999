import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { getAuth } from 'firebase/auth'
import { initializeFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: 'AIzaSyD5IU3fMJOaN3ySmrEZF4lHDH0yqh7zUPc',
    authDomain: 'perati-a2725.firebaseapp.com',
    projectId: 'perati-a2725',
    storageBucket: 'perati-a2725.appspot.com',
    messagingSenderId: '113825305515',
    appId: '1:113825305515:web:7c9daed9cc88745d20b8ee',
    measurementId: 'G-LGGS3P9L8K',
}

export const app = initializeApp(firebaseConfig)
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
        '6LelNK0nAAAAAB8stJgBTidY__XGianfMPQQg5Ub'
    ),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
})

export const auth = getAuth(app)
export const currentAuth = getAuth
export const db = initializeFirestore(app, {
    ignoreUndefinedProperties: true,
})
export const storage = getStorage(app)
