import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoginProp, User } from '../../../domain/entities/default/AdminUser'
// import { AuthLocalImplementation } from '../../../data/repositories/auth/auth.local'
import { AuthService } from '../../../domain/usecases/auth.usecase'
import { AuthFirebaseImplementation } from '../../../data/repositories/auth/auth.firebase'
import { Personnel } from '@/domain/entities/Transport'

interface InitState {
    isLoggedIn: boolean
    user: User & {
        role: Personnel['role']
    }
    errMessage: string
    loading: boolean
}
const initialState: InitState = {
    isLoggedIn: false,
    loading: false,
    user: {
        displayName: '',
        email: '',
        type: 'staff',
        partnerCode: '',
        emailVerified: false,
        myTeams: [],
        activeTeam: '',
        firstName: '',
        lastName: '',
        dontShowWelcomeMessage: false,
        role: 'driver',
    },
    errMessage: '',
}

const localImpl = new AuthFirebaseImplementation()

export const login = createAsyncThunk(
    'auth/login',
    async (payload: LoginProp) => {
        const authService = new AuthService(localImpl)
        return await authService.Login(payload)
    }
)

export const logout = createAsyncThunk(
    'auth/logout',
    async (callback?: undefined | (() => void)) => {
        const authService = new AuthService(localImpl)
        return await authService.Logout(callback)
    }
)

export const isLoggedIn = createAsyncThunk(
    'auth/isLoggedIn',
    async (callback?: (resp?: User | null) => void) => {
        const authService = new AuthService(localImpl)
        return await authService.isLoggedIn(callback)
    }
)

export const register = (payload: User) => {
    const authService = new AuthService(localImpl)
    return authService.Register(payload)
}

// export const isLoggedIn = async (callback?: (resp?: User) => void) => {
//     const authService = new AuthService(localImpl)
//     return await authService.isLoggedIn(callback)
// }
export const updateUser = createAsyncThunk(
    'auth/updateUser',
    async (
        payload: Partial<User> & {
            id: string
        }
    ) => {
        const authService = new AuthService(localImpl)
        const { id, ...rest } = payload
        const resp = await authService.updateUser(rest, payload.id)
        if (resp) return rest
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearErr: (state) => {
            state.errMessage = ''
        },
        setAuthState: (state, action) => {
            state = {
                ...state,
                ...action.payload,
            }
            return state
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action: any) => {
            if (!action.payload) return
            state.user = action.payload

            state.isLoggedIn = true
            state.errMessage =
                typeof action.payload === 'string' ? action.payload : ''
            state.loading = false
        })

        builder.addCase(login.rejected, (state, action) => {
            state.user = initialState.user
            state.isLoggedIn = false
            state.errMessage = action.error.code || ''
            state.loading = false
        })

        builder.addCase(login.pending, (state) => {
            state.user = initialState.user
            state.isLoggedIn = false
            state.errMessage = ''
            state.loading = true
        })

        // builder.addCase(isLoggedIn.pending, (state) => {
        //     state.loading = true
        //     state.isLoggedIn = false
        // })

        // builder.addCase(isLoggedIn.fulfilled, (state, action) => {
        //     state.user = action.payload ? action.payload : initialState.user
        //     state.isLoggedIn = action.payload ? true : false
        //     state.loading = false
        // })

        // builder.addCase(isLoggedIn.rejected, (state) => {
        //     state.user = initialState.user
        //     state.isLoggedIn = false
        //     state.loading = false
        // })

        builder.addCase(logout.pending, (state) => {
            state.user = initialState.user
            state.isLoggedIn = false
            state.loading = true
        })

        builder.addCase(logout.fulfilled, (state) => {
            state.user = initialState.user
            state.isLoggedIn = false
            state.loading = false
        })

        builder.addCase(updateUser.fulfilled, (state, action) => {
            if (!action.payload) return
            state.user = {
                ...state.user,
                ...action.payload,
            }
        })
    },
})

export const { clearErr, setAuthState } = authSlice.actions
