import { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
} from '@ant-design/pro-components'
import { shallowEqual } from 'react-redux'
import moment from 'moment'
import {
    collection,
    addDoc,
    updateDoc,
    doc,
    arrayUnion,
} from 'firebase/firestore'
import { Saving } from '../../../domain/entities/Saving'
import { db } from '../../boot'
import {
    updateSavingsState,
    initialSavingState,
} from '../../redux/savings/savings.slice'
import { getTeamMembers } from '../../redux/team/team.action'
import { useAppDispatch, useAppSelector } from '../../store'

function CreateSavings() {
    const dispatch = useAppDispatch()

    const [filteredMembers, setFilteredMembers] = useState<
        {
            label: string
            value: string
        }[]
    >([])
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const { showAddSaving, activeTeam, user, styles, members, teamData } =
        useAppSelector(
            (state) => ({
                showAddSaving: state.savings.addData.show,
                members: state.team.currentTeamMembers,
                activeTeam: state.auth.user.activeTeam,
                user: state.auth.user,
                styles: state.styles,
                currentYear: state.systemConfig.currentYear,
                teamData: state.team.selectedTeamData,
                // dueDate: 'sat',
                // frequency: 'weekly'
            }),
            shallowEqual
        )

    const handleSubmit = async (data: any) => {
        setLoading(true)
        const member = members.find((member) => member.id === data.memberId)
        if (member) {
            const payload: Omit<Saving, 'id'> = {
                memberId: data.memberId,
                openingBalance: data.openingBalance,
                creation: moment().unix(),
                modified: moment().unix(),
                modifiedBy: user.email,
                partnerCode: activeTeam,
                isRemoved: false,
                accountHolder: member?.displayName as string,
                accountNumber: member.id,
                openingDate: moment(data.openingDate).unix(),
                currentBalance: data.openingBalance,
                interestRate: 0,
                interestEarned: 0,
                totalDeposits: 0,
                totalWithdrawals: 0,
                interestPostingFrequency: 'monthly',
                status: 'active',
            }
            const paymentRef = collection(db, 'savings')
            const resp = await addDoc(paymentRef, payload)
            if (resp) {
                const teamRef = doc(db, 'team', activeTeam)
                await updateDoc(teamRef, {
                    savingsAccount: arrayUnion(member.id),
                })
                message.success(`Savings successfully opened.`)
            }
            // await dispatch(addSaving(payload))
            setLoading(false)
            handleCancel()
            // dispatch(getMembers())
        }
    }

    const handleCancel = () => {
        dispatch(
            updateSavingsState({
                name: 'addData',
                value: {
                    ...initialSavingState.addData,
                    show: false,
                },
            })
        )
        form.resetFields()
    }

    useEffect(() => {
        const newMembers: {
            label: string
            value: string
        }[] = []
        members.forEach((member) => {
            if (
                teamData.savingsAccount &&
                teamData.savingsAccount.includes(member.id)
            )
                return
            newMembers.push({
                label: member.displayName as string,
                value: member.id,
            })
        })

        setFilteredMembers(newMembers)
    }, [members, teamData.savingsAccount])
    useEffect(() => {
        if (!activeTeam) return
        dispatch(
            getTeamMembers({
                activeTeam,
                pageName: 'saving',
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam, showAddSaving])
    // useEffect(() => {
    //     if (showAddSaving) {
    //         const savings = getMemberSaving(addData.id)
    //         let totalSaving = 0
    //         savings.forEach((saving: Saving) => (totalSaving += saving.amount))
    //         setMemberTotalSavingAmount(totalSaving)
    //         // const isPastDue = calculateDueDate(frequency, dueDate)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [showAddSaving])

    return (
        <ModalForm<Saving>
            title={'Create Savings Account'}
            form={form}
            onFinishFailed={(err) => {
                if (err.errorFields.length) {
                    const firstError = err.errorFields[0]
                    message.destroy()
                    message.error(firstError.errors)
                    form.scrollToField(firstError.name, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }}
            // title={
            //     <>
            //         <Typography.Title level={3}>
            //             {addData.name}
            //         </Typography.Title>
            //         Total Contribution:{' '}
            //         <Typography.Text style={{ fontWeight: 700, fontSize: 18 }}>
            //             {currency}
            //             {memberTotalSavingAmount}
            //         </Typography.Text>
            //         <div>
            //             <Typography.Text
            //                 style={{ fontWeight: 700, fontSize: 18 }}
            //             >
            //                 {addData.frequency}
            //             </Typography.Text>
            //         </div>
            //     </>
            // }
            open={showAddSaving}
            autoFocusFirstInput
            modalProps={{
                onCancel: handleCancel,
                okText: 'Submit',
                centered: true,
                closable: false,
            }}
            submitTimeout={2000}
            onFinish={handleSubmit}
            width={540}
            initialValues={{
                openingDate: moment(),
            }}
            submitter={{
                submitButtonProps: {
                    style: {
                        backgroundColor: styles.primary,
                        color: 'white',
                    },
                    loading,
                },
            }}
            // @ts-ignore
        >
            <ProFormSelect
                name={'memberId'}
                options={filteredMembers}
                label={'Name of member'}
                showSearch
                placeholder={"Please enter member's name"}
                rules={[
                    {
                        required: true,
                        type: 'string',
                        message: (
                            <div>
                                Name can't be blank.
                                <div>
                                    Select a member in the list, or invite a
                                    member in your team.
                                </div>
                                <br />
                            </div>
                        ),
                    },
                ]}
                fieldProps={{
                    onKeyUp: (event) => {
                        if (event.key === 'Enter') {
                            form.submit()
                        }
                    },
                }}
            />
            <ProForm.Group>
                <ProFormDigit
                    rules={[
                        {
                            required: true,
                            type: 'number',
                            message: 'This field is required',
                        },
                    ]}
                    width="sm"
                    name="openingBalance"
                    label="Opening Balance"
                    fieldProps={{
                        onKeyUp: (event) => {
                            if (event.key === 'Enter') {
                                form.submit()
                            }
                        },
                    }}
                />
                <ProFormDatePicker
                    name={'openingDate'}
                    label={'Date opened'}
                    fieldProps={{
                        format: 'MMMM DD, YYYY',
                    }}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                />
            </ProForm.Group>
        </ModalForm>
    )
}

export default CreateSavings
