/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Empty,
    Typography,
    Button,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Spin,
    Grid,
    message,
} from 'antd'
import {
    FolderAddOutlined,
    CloudDownloadOutlined,
    EditOutlined,
    DeleteOutlined,
    FolderOutlined,
    LeftOutlined,
    FolderOpenOutlined,
} from '@ant-design/icons'
import { MdDriveFileMoveOutline } from 'react-icons/md'
import { FiMoreVertical } from 'react-icons/fi'
// import { t, Trans } from "@lingui/macro"
import moment from 'moment'
// import { useRouter } from "next/router"

// import { useIsMobile } from "@/data/utils/customHooks"
import { useSelector, shallowEqual } from 'react-redux'
// import { RootState } from "@/data/utils/rootState"
import { computeBytesHandler, iconCheckerHandler } from './filesHelper'
import PreviewFilesModal, { EachFileProps } from './PreviewFilesModal'
import FilesDeleteModal from './FilesDeleteModal'
// import { GetStudentFiles, UploadStudentsFolder } from "@/app/redux/tutees/tutees.actions"
// import { MoveTutorFile, RenameTutorFileFolder } from "@/app/redux/tutors/tutors.actions"
import InsideFolder from './InsideFolder'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../store'
import {
    GetFolders,
    MoveFile,
    RenameFile,
    UploadFolder,
} from '../../redux/team/team.action'
import { FolderProps } from '../../../domain/entities/File'
import { AiOutlinePlus } from 'react-icons/ai'
import AddFilesModal from './AddFilesModal'
// import { mixPanel } from "@/data/utils/mixpanelClass"

const { Text } = Typography
const { Item } = Menu
const { useBreakpoint } = Grid

const AllFiles = ({ isMember = true }: { isMember?: boolean }) => {
    const screen = useBreakpoint()
    // const history = useLocation()
    const isMobile = screen.xs
    const { user, styles } = useAppSelector(
        (state) => ({ user: state.auth.user, styles: state.styles }),
        shallowEqual
    )
    const userUid = user.id || user.uid || ''
    const [foldersModal, setFoldersModal] = useState(false)
    const [previewModal, setPreviewModal] = useState(false)
    const [previewFile, setPreviewFile] = useState({
        creation: 0,
        id: '',
        link: '',
        name: '',
        size: 0,
        type: '',
    })
    const [openDisable, setOpenDisable] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [toDeleteData, setToDeleteData] = useState({
        tutorId: userUid,
        id: '',
        folderName: '',
        fileType: '',
        fileName: '',
        fileUrl: '',
    })
    const [renameFileData, setRenameFileData] = useState<any>(null)
    const [renameFolderData, setRenameFolderData] = useState<any>(null)
    const [folderName, setFolderName] = useState('')
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [studentFiles, setStudentFiles] = useState<any[]>([])
    const [studentFilesRef, setStudentFilesRef] = useState<any>(null)
    const [studentFolders, setStudentFolders] = useState<any>([])
    const [fetchLoading, setFetchLoading] = useState(false)
    const [selectedFolderData, setSelectedFolderData] = useState<any>(null)
    const [selectedFolderString, setSelectedFolderString] = useState('')

    const backFolderHandler = () => {
        setSelectedFolderData(null)
        setSelectedFolderString('')
    }

    const openSelectedFolderHandler = (folder: FolderProps) => {
        setSelectedFolderString(folder.folderName)
        setSelectedFolderData(folder)
    }

    const previewFileHandler = (selectedFile: EachFileProps) => {
        setPreviewFile(selectedFile)
        setPreviewModal(true)
    }

    const moveFolderHandler = async (folderName: string, selectedFile: any) => {
        setOpenDisable(true)
        const folderMoved = { from: 'parentFolder', to: folderName }
        const resp: any = await MoveFile(
            userUid,
            folderMoved,
            selectedFile,
            'student'
        )
        if (resp === 'success') {
            setOpenDisable(false)
            notification.success({
                message: `file moved successfully.`,
                duration: 3,
            })
        } else {
            setOpenDisable(false)
            notification.error({
                message: `Something went wrong. ${resp}`,
                duration: 5,
            })
        }
    }

    const openModalHandler = (
        id: string,
        folderName: string,
        fileType: string
    ) => {
        setOpenDeleteModal(true)
        setToDeleteData({
            ...toDeleteData,
            id,
            folderName,
            fileType,
        })
    }
    const [filesModal, setFilesModal] = useState(false)

    const renameOnSaveHandler = async (
        fileType: string,
        folderName: string
    ) => {
        if (fileType === 'file') {
            // mixPanel.track('Rename File')
            const selectedFile = studentFiles.filter(
                (f) => f.id === renameFileData.id
            )
            if (renameFileData === selectedFile[0].name) {
                setRenameFileData(null)
                return
            } else {
                await RenameFile({
                    id: renameFolderData.id,
                    name: renameFolderData.name,
                })
                setRenameFileData(null)
                return
            }
        } else {
            // mixPanel.track('Rename Folder')
            if (renameFolderData.name === folderName) {
                setRenameFolderData(null)
                return
            } else {
                await RenameFile({
                    id: renameFolderData.id,
                    name: renameFolderData.name,
                })
                setRenameFolderData(null)
                return
            }
        }
    }

    const cancelModalFolders = () => {
        setFolderName('')
        setFoldersModal(false)
    }

    const uploadFoldersHandler = async () => {
        setLoadingUpload(true)

        if (
            studentFolders &&
            studentFolders.length > 0 &&
            studentFolders.includes(folderName)
        ) {
            setLoadingUpload(false)
            return notification.error({
                message: `Folder name already exist.`,
                duration: 5,
            })
        }

        const payload = {
            modifiedBy: user.email,
            folderName: folderName,
            partnerCode: user.activeTeam,
            creation: moment().unix(),
            modified: moment().unix(),
            isRemoved: false,
        }
        console.log(payload)

        try {
            await UploadFolder(payload)
            notification.success({
                message: `${payload.folderName} successfully created.`,
                duration: 3,
            })
            cancelModalFolders()
        } catch (err: any) {
            notification.error({
                message: 'Something went wrong.',
                description: err.message || '',
                duration: 5,
            })
        }
        setLoadingUpload(false)
    }

    const folderDisableChecker = () => {
        if (!folderName) {
            return true
        } else {
            return false
        }
    }

    const cancelHandler = () => {
        setPreviewModal(false)
        setPreviewFile({
            creation: 0,
            id: '',
            link: '',
            name: '',
            size: 0,
            type: '',
        })
    }
    const handleFolders = async () => {
        setFetchLoading(true)
        const data = await GetFolders(user.activeTeam)
        console.log(data)
        setStudentFolders(data)
        setFetchLoading(false)
    }
    useEffect(() => {
        handleFolders()

        // return () => toCancel && toCancel()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedFolderString) {
            // setSelectedFolderData(studentFilesRef[selectedFolderString])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFolderString, studentFilesRef])
    console.log(selectedFolderData)

    return (
        <div className="page-layout">
            {selectedFolderData && (
                <AddFilesModal
                    filesModal={filesModal}
                    setFilesModal={setFilesModal}
                    // userId={user.id || user.uid || ''}
                    loadingUpload={loadingUpload}
                    setLoadingUpload={setLoadingUpload}
                    folderId={selectedFolderData?.id || ''}
                    accept="pdf"
                />
            )}
            <Row justify="center">
                <Col span={24}>
                    <Row
                        style={{
                            height:
                                studentFiles.length < 1 ||
                                studentFolders.length < 1
                                    ? '50vh'
                                    : 'auto',
                            paddingBottom:
                                studentFiles.length < 1 ||
                                studentFolders.length < 1
                                    ? '0px'
                                    : '30px',
                            // borderLeft: '1px solid #ccc',
                            // borderBottom: '1px solid #ccc',
                            // borderRight: '1px solid #ccc',
                        }}
                        justify="center"
                    >
                        <Col span="23">
                            {selectedFolderString ? (
                                <div style={{ marginTop: '15px' }}>
                                    <Row align="middle">
                                        <Col>
                                            <Button
                                                type="link"
                                                onClick={backFolderHandler}
                                                style={{
                                                    fontWeight: 600,
                                                    paddingLeft: 0,
                                                }}
                                            >
                                                <LeftOutlined
                                                    style={{
                                                        marginRight: '7px',
                                                        fontSize: 24,
                                                    }}
                                                />
                                            </Button>
                                        </Col>
                                        <Col flex={1}>
                                            <Row align={'middle'}>
                                                <Col span={24}>
                                                    <Row
                                                        align={'middle'}
                                                        justify={
                                                            'space-between'
                                                        }
                                                    >
                                                        <Col>
                                                            <Typography.Title
                                                                style={{
                                                                    margin: 0,
                                                                    // color: '#1890ff',
                                                                }}
                                                            >
                                                                {selectedFolderString ||
                                                                    ''}
                                                            </Typography.Title>
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                style={{
                                                                    backgroundColor:
                                                                        styles.primary,
                                                                    color: 'white',
                                                                }}
                                                                size="large"
                                                                shape="round"
                                                                onClick={() =>
                                                                    setFilesModal(
                                                                        true
                                                                    )
                                                                }
                                                                icon={
                                                                    <AiOutlinePlus
                                                                        style={{
                                                                            margin: 'auto 5px auto 0px',
                                                                            padding:
                                                                                '3px 0px 0px 0px',
                                                                            borderRadius:
                                                                                '0px',
                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                Add Files
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 15,
                                            marginTop: '15px',
                                        }}
                                    >
                                        <Button
                                            style={{
                                                backgroundColor: styles.primary,
                                                color: 'white',
                                            }}
                                            size="large"
                                            shape="round"
                                            onClick={() =>
                                                setFoldersModal(true)
                                            }
                                            icon={
                                                <FolderAddOutlined
                                                    style={{
                                                        margin: 'auto 7px auto 0px',
                                                        padding:
                                                            '3px 0px 0px 0px',
                                                        borderRadius: '0px',
                                                    }}
                                                />
                                            }
                                        >
                                            Add Folder
                                        </Button>
                                    </div>
                                </div>
                            )}

                            <br />
                            {fetchLoading ? (
                                <Row justify="center" align="middle">
                                    <Col>
                                        <Spin />
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <Row>
                                        <Col span="24">
                                            {(studentFiles &&
                                                studentFiles.length > 0) ||
                                            (studentFolders &&
                                                studentFolders.length > 0) ? (
                                                <>
                                                    <Row
                                                        justify="space-between"
                                                        style={{
                                                            padding:
                                                                '10px 15px',
                                                            marginBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        <Col
                                                            span="8"
                                                            style={{
                                                                fontWeight: 600,
                                                                fontSize:
                                                                    !screen.md
                                                                        ? '12px'
                                                                        : '14px',
                                                            }}
                                                        >
                                                            NAME
                                                        </Col>
                                                        <Col
                                                            span="8"
                                                            style={{
                                                                fontWeight: 600,
                                                                fontSize:
                                                                    !screen.md
                                                                        ? '12px'
                                                                        : '14px',
                                                            }}
                                                        >
                                                            NOTES
                                                        </Col>
                                                        {/* <Col
                                                            span="3"
                                                            style={{
                                                                fontWeight: 600,
                                                                fontSize:
                                                                    !screen.md
                                                                        ? '12px'
                                                                        : '14px',
                                                            }}
                                                        >
                                                            SIZE
                                                        </Col> */}
                                                        <Col
                                                            span="5"
                                                            style={{
                                                                fontWeight: 600,
                                                                fontSize:
                                                                    !screen.md
                                                                        ? '12px'
                                                                        : '14px',
                                                            }}
                                                        >
                                                            DATE MODIFIED
                                                        </Col>
                                                        <Col span="1"></Col>
                                                    </Row>

                                                    {!selectedFolderString ? (
                                                        <>
                                                            {studentFiles &&
                                                            studentFiles.length >
                                                                0 ? (
                                                                studentFiles.map(
                                                                    (
                                                                        eachFile: any
                                                                    ) => (
                                                                        <Row
                                                                            key={
                                                                                eachFile.id
                                                                            }
                                                                            align="middle"
                                                                            justify="space-between"
                                                                            style={{
                                                                                border: '1px solid #000',
                                                                                padding:
                                                                                    '13px 15px',
                                                                                marginBottom:
                                                                                    '10px',
                                                                                position:
                                                                                    'relative',
                                                                            }}
                                                                        >
                                                                            <Col span="8">
                                                                                {renameFileData &&
                                                                                renameFileData.id ===
                                                                                    eachFile.id ? (
                                                                                    <Input
                                                                                        value={
                                                                                            renameFileData.name
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setRenameFileData(
                                                                                                {
                                                                                                    ...renameFileData,
                                                                                                    name: e
                                                                                                        .target
                                                                                                        .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        autoFocus
                                                                                        onBlur={() =>
                                                                                            renameOnSaveHandler(
                                                                                                'file',
                                                                                                eachFile
                                                                                            )
                                                                                        }
                                                                                        onPressEnter={() =>
                                                                                            renameOnSaveHandler(
                                                                                                'file',
                                                                                                eachFile
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <Row align="middle">
                                                                                        <Col
                                                                                            style={{
                                                                                                whiteSpace:
                                                                                                    'nowrap',
                                                                                                overflow:
                                                                                                    'hidden',
                                                                                                textOverflow:
                                                                                                    'ellipsis',
                                                                                            }}
                                                                                        >
                                                                                            <Text
                                                                                                onClick={() =>
                                                                                                    window.open(
                                                                                                        eachFile.fileUrl,
                                                                                                        '_blank'
                                                                                                    )
                                                                                                }
                                                                                                // onClick={() =>
                                                                                                //     previewFileHandler(
                                                                                                //         eachFile
                                                                                                //     )
                                                                                                // }
                                                                                                style={{
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                            >
                                                                                                {iconCheckerHandler(
                                                                                                    eachFile.type
                                                                                                )}{' '}
                                                                                                {
                                                                                                    eachFile.name
                                                                                                }
                                                                                            </Text>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                            </Col>
                                                                            <Col span="8">
                                                                                <Text
                                                                                    italic={
                                                                                        eachFile &&
                                                                                        !eachFile.course
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        eachFile.notes
                                                                                    }
                                                                                </Text>
                                                                            </Col>
                                                                            {/* <Col span="3">
                                                                                {computeBytesHandler(
                                                                                    eachFile.size
                                                                                )}
                                                                            </Col> */}
                                                                            <Col span="5">
                                                                                {eachFile.modifiedBy ||
                                                                                    ''}{' '}
                                                                                •{' '}
                                                                                {eachFile.modified
                                                                                    ? moment
                                                                                          .unix(
                                                                                              eachFile.modified
                                                                                          )
                                                                                          .format(
                                                                                              'MMM DD, YYYY h:mma'
                                                                                          )
                                                                                    : moment
                                                                                          .unix(
                                                                                              eachFile.creation
                                                                                          )
                                                                                          .format(
                                                                                              'MMM DD, YYYY h:mma'
                                                                                          )}
                                                                            </Col>
                                                                            <Col span="1">
                                                                                <Dropdown
                                                                                    disabled={
                                                                                        openDisable
                                                                                    }
                                                                                    overlay={
                                                                                        <Menu
                                                                                            style={{
                                                                                                width: isMobile
                                                                                                    ? 'unset'
                                                                                                    : '150px',
                                                                                            }}
                                                                                        >
                                                                                            <Item
                                                                                                key="downloadFile"
                                                                                                icon={
                                                                                                    <CloudDownloadOutlined />
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    window.open(
                                                                                                        eachFile.fileUrl,
                                                                                                        '_blank'
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Download
                                                                                            </Item>
                                                                                            <Item
                                                                                                key="renameFile"
                                                                                                icon={
                                                                                                    <EditOutlined />
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    setRenameFileData(
                                                                                                        eachFile
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Rename
                                                                                            </Item>
                                                                                            {/* <Item key="sendFile" icon={<SendOutlined />} onClick={() => sendFileHandler(eachFile)}>
                                                                                Send
                                                                            </Item> */}
                                                                                            {/* <Menu.SubMenu
                                                                                                key="moveFolder"
                                                                                                title="Move to"
                                                                                                icon={
                                                                                                    <MdDriveFileMoveOutline />
                                                                                                }
                                                                                            >
                                                                                                {studentFolders &&
                                                                                                studentFolders.length >
                                                                                                    0 ? (
                                                                                                    studentFolders.map(
                                                                                                        (
                                                                                                            eachFolder: any
                                                                                                        ) => (
                                                                                                            <Menu.Item
                                                                                                                key={
                                                                                                                    eachFolder
                                                                                                                }
                                                                                                                onClick={() =>
                                                                                                                    moveFolderHandler(
                                                                                                                        eachFolder,
                                                                                                                        eachFile
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <Text
                                                                                                                    key={
                                                                                                                        eachFolder
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        eachFolder
                                                                                                                    }
                                                                                                                </Text>
                                                                                                            </Menu.Item>
                                                                                                        )
                                                                                                    )
                                                                                                ) : (
                                                                                                    <Menu.Item key="emptyFiles">
                                                                                                        <Text
                                                                                                            disabled={
                                                                                                                true
                                                                                                            }
                                                                                                        >
                                                                                                            Empty
                                                                                                            folder
                                                                                                        </Text>
                                                                                                    </Menu.Item>
                                                                                                )}
                                                                                            </Menu.SubMenu> */}
                                                                                            <Item
                                                                                                key="deleteFile"
                                                                                                icon={
                                                                                                    <DeleteOutlined />
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    openModalHandler(
                                                                                                        eachFile.id,
                                                                                                        'parentFolder',
                                                                                                        'file'
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Delete
                                                                                            </Item>
                                                                                        </Menu>
                                                                                    }
                                                                                    trigger={[
                                                                                        'click',
                                                                                    ]}
                                                                                >
                                                                                    <FiMoreVertical
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                            width: '100%',
                                                                                        }}
                                                                                    />
                                                                                </Dropdown>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                )
                                                            ) : studentFiles &&
                                                              studentFiles.length <
                                                                  1 &&
                                                              studentFolders.length <
                                                                  1 ? (
                                                                <Empty
                                                                    description={`No files`}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    ) : (
                                                        <InsideFolder
                                                            selectedFolderData={
                                                                selectedFolderData
                                                            }
                                                            selectedFolderString={
                                                                selectedFolderString
                                                            }
                                                            studentFolders={
                                                                studentFolders
                                                            }
                                                            folderId={
                                                                selectedFolderData.id
                                                            }
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <Row
                                                    style={{ height: '41vh' }}
                                                    justify="center"
                                                >
                                                    <Empty />
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>
                                    {!selectedFolderString && (
                                        <Row>
                                            <Col span="24">
                                                {studentFolders &&
                                                studentFolders.length > 0
                                                    ? studentFolders.map(
                                                          (
                                                              eachFolder: any,
                                                              index: number
                                                          ) => (
                                                              <Row
                                                                  key={
                                                                      eachFolder.id
                                                                  }
                                                                  justify="space-between"
                                                                  style={{
                                                                      border: '1px solid #007BFF',
                                                                      padding:
                                                                          '13px 15px',
                                                                      marginBottom:
                                                                          '10px',
                                                                  }}
                                                              >
                                                                  <Col span="23">
                                                                      {renameFolderData &&
                                                                      renameFolderData.id ===
                                                                          index ? (
                                                                          <Input
                                                                              value={
                                                                                  renameFolderData.name
                                                                              }
                                                                              onChange={(
                                                                                  e
                                                                              ) =>
                                                                                  setRenameFolderData(
                                                                                      {
                                                                                          ...renameFolderData,
                                                                                          name: e
                                                                                              .target
                                                                                              .value,
                                                                                      }
                                                                                  )
                                                                              }
                                                                              autoFocus
                                                                              onBlur={() =>
                                                                                  renameOnSaveHandler(
                                                                                      'folder',
                                                                                      eachFolder.id
                                                                                  )
                                                                              }
                                                                              onPressEnter={() =>
                                                                                  renameOnSaveHandler(
                                                                                      'folder',
                                                                                      eachFolder.id
                                                                                  )
                                                                              }
                                                                          />
                                                                      ) : (
                                                                          <Text
                                                                              style={{
                                                                                  color: '#007BFF',
                                                                                  cursor: 'pointer',
                                                                              }}
                                                                              onClick={() =>
                                                                                  openSelectedFolderHandler(
                                                                                      eachFolder
                                                                                  )
                                                                              }
                                                                          >
                                                                              <FolderOutlined
                                                                                  style={{
                                                                                      marginRight:
                                                                                          '5px',
                                                                                  }}
                                                                              />
                                                                              {
                                                                                  eachFolder.folderName
                                                                              }
                                                                          </Text>
                                                                      )}
                                                                  </Col>
                                                                  <Col
                                                                      span="1"
                                                                      style={{
                                                                          color: '#007BFF',
                                                                      }}
                                                                  >
                                                                      <Dropdown
                                                                          disabled={
                                                                              openDisable
                                                                          }
                                                                          overlay={
                                                                              <Menu
                                                                                  style={{
                                                                                      width: isMobile
                                                                                          ? 'unset'
                                                                                          : '150px',
                                                                                  }}
                                                                              >
                                                                                  <Item
                                                                                      key="renameFolder"
                                                                                      icon={
                                                                                          <EditOutlined />
                                                                                      }
                                                                                      onClick={() =>
                                                                                          setRenameFolderData(
                                                                                              {
                                                                                                  id: eachFolder.id,
                                                                                                  name: eachFolder.folderName,
                                                                                              }
                                                                                          )
                                                                                      }
                                                                                  >
                                                                                      Rename
                                                                                  </Item>
                                                                                  <Item
                                                                                      key="deleteFolder"
                                                                                      icon={
                                                                                          <DeleteOutlined />
                                                                                      }
                                                                                      onClick={() =>
                                                                                          openModalHandler(
                                                                                              index.toString(),
                                                                                              eachFolder.id,
                                                                                              'folder'
                                                                                          )
                                                                                      }
                                                                                  >
                                                                                      Delete
                                                                                  </Item>
                                                                              </Menu>
                                                                          }
                                                                          trigger={[
                                                                              'click',
                                                                          ]}
                                                                      >
                                                                          <FiMoreVertical
                                                                              style={{
                                                                                  cursor: 'pointer',
                                                                                  width: '100%',
                                                                              }}
                                                                          />
                                                                      </Dropdown>
                                                                  </Col>
                                                              </Row>
                                                          )
                                                      )
                                                    : ''}
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>

                <Modal
                    title="ADD FOLDER"
                    visible={foldersModal}
                    footer={null}
                    closable={false}
                    closeIcon={false}
                >
                    <Text>Folder name:</Text>
                    <Input
                        size="large"
                        value={folderName}
                        onChange={(e) => setFolderName(e.target.value)}
                    />
                    <br />
                    <br />
                    <br />
                    <Row justify="space-between">
                        <Button
                            onClick={cancelModalFolders}
                            loading={loadingUpload}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={uploadFoldersHandler}
                            disabled={folderDisableChecker()}
                            loading={loadingUpload}
                        >
                            Add
                        </Button>
                    </Row>
                </Modal>
                {previewModal && (
                    <PreviewFilesModal
                        previewModal={previewModal}
                        eachFile={previewFile}
                        cancelHandler={cancelHandler}
                    />
                )}
                <FilesDeleteModal
                    toDeleteData={toDeleteData}
                    setOpenDeleteModal={setOpenDeleteModal}
                    openDeleteModal={openDeleteModal}
                    setToDeleteData={setToDeleteData}
                    studentOrTutor="student"
                />
            </Row>
        </div>
    )
}

export default AllFiles
