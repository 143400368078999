import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    UserOutlined,
} from '@ant-design/icons'
import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    // ProFormCheckbox,
    ProFormDigit,
    ProFormText,
    ProFormTextArea,
    ProFormTimePicker,
} from '@ant-design/pro-components'
import {
    Card,
    Button,
    Typography,
    Row,
    Col,
    List,
    Avatar,
    Modal,
    message,
    Statistic,
    // Statistic,
} from 'antd'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
    // PERSONNEL_ATTENDANCE_REPOSITORY,
    PERSONNEL_REPOSITORY,
    TRIPS_REPOSITORY,
    VEHICLE_REPOSITORY,
} from '../../../../data/repositories/constants'
import { Personnel, Trip } from '../../../../domain/entities/Transport'
import { db } from '../../../boot'
import TripContainer from './Trip'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from '../../../../app/store'
// import { GiPathDistance } from 'react-icons/gi'
// import { MdTimerOff } from 'react-icons/md'
import GoogleMap from '../../../components/GoogleMap'
import Marker from '../../../../app/components/Marker'
// import CountdownTimer from '../../../../app/components/Timer'
import VehicleFinancialCalculator from '../VehicleFinancialCalculator'
import { useNavigate } from 'react-router-dom'

// interface ActiveTripProps {
//     data: Vehicle
// }
const ActiveTrip = () => {
    const { email, activeTeam, data, selectedVehicleId } = useAppSelector(
        (state) => ({
            email: state.auth.user.email,
            activeTeam: state.auth.user.activeTeam,
            data: state.vehicle.selectedVehicleId
                ? state.vehicle.vehicles.entities[
                      state.vehicle.selectedVehicleId
                  ]
                : undefined,
            selectedVehicleId: state.vehicle.selectedVehicleId,
        }),
        shallowEqual
    )
    const navigate = useNavigate()
    const [isAvailable, setIsAvailable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [driverData, setDriverData] = useState<Personnel | undefined>(
        undefined
    )
    const [assistantData, setAssistantData] = useState<Personnel | undefined>(
        undefined
    )

    // useEffect(() => {
    //     if (data?.activeTrip) {
    //         const main = async () => {
    //             setLoading(true)
    //             try {
    //                 const tripData = data.activeTrip
    //                 if (tripData && tripData.driverId) {
    //                     const driverData = (
    //                         await getDoc(
    //                             doc(
    //                                 db,
    //                                 PERSONNEL_REPOSITORY,
    //                                 tripData?.driverId
    //                             )
    //                         )
    //                     ).data()
    //                     setDriverData({
    //                         ...(driverData as Personnel),
    //                         id: tripData.driverId,
    //                     })
    //                 } else {
    //                     setDriverData(undefined)
    //                 }
    //                 setIsAvailable(true)
    //             } catch (err: any) {
    //                 message.error(err.message)
    //             }
    //             setLoading(false)
    //         }
    //         main()
    //         // setIsAvailable(true)
    //     } else {
    //         setIsAvailable(false)
    //     }
    // }, [data, selectedVehicleId])
    useEffect(() => {
        if (data?.activeTrip && data.activeTripId) {
            const main = () => {
                try {
                    const tripData = data.activeTrip
                    if (tripData && tripData.driverId) {
                        const ref = doc(
                            db,
                            PERSONNEL_REPOSITORY,
                            tripData?.driverId
                        )

                        return onSnapshot(ref, async (snap) => {
                            const driverData = snap.data()
                            setIsAvailable(true)
                            setDriverData({
                                ...(driverData as Personnel),
                                id: tripData.driverId,
                            })
                        })
                    } else {
                        setDriverData(undefined)
                    }
                } catch (err: any) {
                    message.error(err.message)
                }

                return false
            }
            const sub = main()

            return () => {
                if (sub) {
                    sub()
                }
            }
            // setIsAvailable(true)
        } else if (data?.status === 'available') {
            setIsAvailable(false)
        }
    }, [data, selectedVehicleId])
    useEffect(() => {
        if (data?.activeTrip && data.activeTripId) {
            const main = () => {
                try {
                    const tripData = data.activeTrip
                    if (tripData && tripData.assistantId) {
                        const ref = doc(
                            db,
                            PERSONNEL_REPOSITORY,
                            tripData?.assistantId
                        )
                        return onSnapshot(ref, async (snap) => {
                            const assistantData = snap.data()
                            setIsAvailable(true)
                            setAssistantData({
                                ...(assistantData as Personnel),
                                id: tripData.assistantId,
                            })
                        })
                    } else {
                        setAssistantData(undefined)
                    }
                } catch (err: any) {
                    message.error(err.message)
                }

                return false
            }
            const sub = main()

            return () => {
                if (sub) {
                    sub()
                }
            }
            // setIsAvailable(true)
        }
    }, [data, selectedVehicleId])
    // const calculateActiveVehicleTripButtons = (activeTrip: Trip) => {
    //     // if(activeTrip.status === 'active')
    // }
    const endVehicleTrip = async () => {
        // endForm.setFieldValue('notes', data?.activeTrip?.notes)
        if (driverData && !driverData.salary) {
            message.error('Driver has no salary')
        }
        if (assistantData && !assistantData.salary) {
            message.error('Assistant has no salary')
        }
        endForm.setFieldsValue({
            notes: data?.activeTrip?.notes,
            driverSalary: driverData?.salary,
            assistantSalary: assistantData?.salary,
        })
        setEndDrawerOpen(true)
    }

    const closeEndDrawer = () => {
        endForm.resetFields()
        setEndDrawerOpen(false)
    }

    const closeStartDrawer = () => {
        setStartDrawerOpen(false)
    }

    const closeOvertimeDrawer = () => {
        setOvertimeDrawerOpen(false)
    }

    const [endDrawerOpen, setEndDrawerOpen] = useState(false)
    const [startDrawerOpen, setStartDrawerOpen] = useState(false)
    const [overtimeDrawerOpen, setOvertimeDrawerOpen] = useState(false)
    const containerStyle: any = {
        position: 'relative',
    }
    const [startForm] = ProForm.useForm()
    const [overtimeForm] = ProForm.useForm()
    const [endForm] = ProForm.useForm()

    const onSubmitEndTrip = async (values: any) => {
        // eslint-disable-next-line no-unreachable
        // await updateDoc(doc(db, VEHICLE_REPOSITORY, data?.id as string), {
        //     activeTripId: '',
        //     status: 'available',
        //     modified: moment().unix(),
        //     modifiedBy: email,
        // })

        // const fieldName =
        //     data?.activeTrip?.status === 'overtime'
        //         ? {
        //               endOvertime: values.endOvertime,
        //           }
        //         : {
        //               timeOut: values.timeOut,
        //           }
        //                   driverSalary
        // assistantSalary
        const cash = parseFloat(values?.cashCollected || '0')
        const overtimeHours = parseFloat(values?.overtimeHours || '0')
        const card = parseFloat(values?.cardRevenue || '0')
        const gasCost = parseFloat(values?.totalGasCost || '0')
        const totalCashReceived = parseFloat(values?.totalCashReceived || '0')
        const driverSalary = parseFloat(values?.driverSalary || '0')
        const assistantSalary = parseFloat(values?.assistantSalary || '0')
        const dispatchingCost = parseFloat(values?.dispatchingCost || '0')
        const driverAllowance = driverData?.dailyAllowance || 0
        const assistantAllowance = assistantData?.dailyAllowance || 0
        const dailyQuotaIncentives =
            parseFloat(values?.dailyQuotaIncentives || '0') * 2

        const totalRevenue = cash + card
        // const dt = moment(data?.activeTrip?.date)
        const strt = moment(values?.shiftStartTime, 'MMMM DD, YYYY h:mmA')
        const end = moment(values?.shiftEndTime, 'MMMM DD, YYYY h:mmA')

        const totalExpenses =
            gasCost +
            dailyQuotaIncentives +
            driverSalary +
            assistantSalary +
            dispatchingCost +
            driverAllowance +
            assistantAllowance

        // eslint-disable-next-line no-unused-vars
        const payload: Trip = {
            creation: moment().unix(),
            modified: moment().unix(),
            timeOut: '',
            startOvertime: '',
            endOvertime: '',
            totalRoundTrips: 0,
            paymentStatus: 'pending',
            status: 'done',
            amountCollected: 0,
            isRemoved: false,
            modifiedBy: email,
            partnerCode: activeTeam,
            timeIn: '',
            date: strt.format('ddd, MMMM DD, YYYY'),
            driverId: values.driverId,
            assistantId: values.assistantId,
            vehicleId: data?.id as string,
            notes: values.notes,
            driverPayoutStatus: 'pending',
            assistantPayoutStatus: 'pending',
            hours: 0,
            shiftStartTime: strt.unix(),
            totalGasConsumed: 0,
            totalGasCost: gasCost,
            distanceTraveled: 0,
            totalPassengers: 0,
            shiftEndTime: end.unix(),
            totalExpenses,
            cashCollected: cash,
            miscellaneousExpenses: 0,
            maintenanceFee: 0,
            totalRevenue,
            cardRevenue: card,
            driverSalary,
            assistantSalary,
            dailyQuotaIncentives,
            dispatchingCost,
            driverAllowance,
            assistantAllowance,
            shiftHistory: data?.temporaryShiftDetails?.history || [],
            totalCashReceived,
            overtimeHours,
            driverIncentives: 0,
            driverOvertimeHours: 0,
            driverOvertimePay: 0,
            assistantIncentives: 0,
            assistantOvertimeHours: 0,
            assistantOvertimePay: 0,
            withAssistant: false,
            isAssistantHalfDay: false,
            isDriverHalfDay: false,
            isEmployeeHalfDay: false,
        }
        // eslint-disable-next-line no-console
        // console.log(payload)

        // await updateDoc(
        //     doc(db, TRIPS_REPOSITORY, data?.activeTrip?.id as string),
        //     {
        //         // timeI
        //         ...values,
        //         status: 'done',
        //         cashCollected: cash,
        //         driver: driverData,
        //         dailyQuotaIncentives,
        //         assistant: assistantData,
        //         modified: moment().unix(),
        //         modifiedBy: email,
        //         shiftEndTime: end.unix(),
        //         totalRevenue: cash + card,
        //         totalExpenses,
        //         ...fieldName,
        //     }
        // )
        // closeEndDrawer()
        // endForm.resetFields()
        // message.success('Successfully end trip')
    }

    const handleStartActiveTrip = async () => {
        startForm.setFieldValue('date', data?.activeTrip?.date)
        startForm.setFieldValue('notes', data?.activeTrip?.notes)
        setStartDrawerOpen(true)
    }

    // const handleShowOvertime = async () => {
    //     // eslint-disable-next-line no-console
    //     console.log(data, moment().format('ddd, MMMM DD, YYYY'))

    //     // form.setFieldValue("date", data?.activeTrip?.date)
    //     overtimeForm.setFieldValue('notes', data?.activeTrip?.notes)
    //     setOvertimeDrawerOpen(true)
    // }

    const onSubmitStartTrip = async (datum: any) => {
        setLoading(true)

        await updateDoc(doc(db, VEHICLE_REPOSITORY, data?.id as string), {
            status: 'occupied',
            modified: moment().unix(),
            modifiedBy: email,
        })
        await updateDoc(
            doc(db, TRIPS_REPOSITORY, data?.activeTrip?.id as string),
            {
                // timeI
                timeIn: datum.timeIn,
                status: 'active',
                modified: moment().unix(),
                modifiedBy: email,
            }
        )
        setLoading(false)
        message.success('Successfully started trip.')
        setStartDrawerOpen(false)
    }

    const onSubmitOvertimeTrip = async (datum: any) => {
        setLoading(true)

        await updateDoc(
            doc(db, TRIPS_REPOSITORY, data?.activeTrip?.id as string),
            {
                // timeI
                timeOut: datum.timeOut,
                startOvertime: datum.startOvertime,
                notes: datum.notes,
                status: 'overtime',
                modified: moment().unix(),
                modifiedBy: email,
            }
        )
        setLoading(false)
        message.success('Successfully started overtime.')
        setOvertimeDrawerOpen(false)
    }

    useEffect(() => {
        if (!startDrawerOpen) return
    }, [startDrawerOpen])
    const [openTimeInOut, setOpenTimeInOut] = useState(false)
    const [form] = ProForm.useForm()
    const [currentEmployee, setCurrentEmployee] = useState<any>(undefined)

    // eslint-disable-next-line no-unused-vars
    const handleShow = async (values: any, type: 'in' | 'out') => {
        if (!values) {
            message.destroy()
            message.error('Select an employee first and try again.', 5)
            return
        }
        if (values) {
            setCurrentEmployee(values)
        }
        // const dateToday = moment().format('MM-DD-YYYY')
        // const attendanceQ = query(
        //     collection(db, PERSONNEL_ATTENDANCE_REPOSITORY),
        //     where('employeeId', '==', data?.id),
        //     where('date', '==', dateToday),
        //     where('partnerCode', '==', data.partnerCode)
        // )
        // const querySnapshot = await getDocs(attendanceQ)
        if (!values.currentAttendanceId) {
            // message.info('No existing attendance')
            const date = moment(data?.activeTrip?.date, 'ddd, MMMM DD, YYYY')
            form.setFieldValue('date', date)
        } else {
            // const attendance = querySnapshot.docs[0]
            // const attendanceData = attendance.data()
            // setExistingAttendance({
            //     ...attendanceData,
            //     id: attendance.id
            // })
            const date = moment.unix(values.timeInOfTheDay)

            form.setFieldValue('date', date)
            form.setFieldValue('timeIn', date)
        }
        setOpenTimeInOut(true)
    }
    const handleCancel = () => {
        setOpenTimeInOut(false)
        form.resetFields()
    }

    const handleFinish = async (data: any) => {
        if (!currentEmployee.currentAttendanceId) {
            const currentDate = moment(data.date, 'MMMM DD, YYYY')
            const timeIn = moment(data.timeIn, 'h:mmA')
                .set('M', currentDate.month())
                .set('D', currentDate.date())

            // const timeLogs = {
            //     timeIn: timeIn.unix(),
            //     partnerCode: currentEmployee.partnerCode,
            //     rfid: currentEmployee.rfid || '',
            //     employeeId: currentEmployee.id,
            //     timeOut: 0,
            //     status: 'pending',
            //     date: currentDate.format('MM-DD-YYYY'),
            //     creation: moment().unix(),
            //     modified: moment().unix(),
            //     email,
            // }
            // const resp = await addDoc(collection(db, 'attendance'), timeLogs)
            const ref = doc(db, PERSONNEL_REPOSITORY, currentEmployee.id)
            await updateDoc(ref, {
                timeInOfTheDay: timeIn.unix(),
                currentAttendanceId: currentDate.format('MM-DD-YYYY'),
            })

            message.destroy()
            message.success('Successfully clocked employee in', 5)
            handleCancel()
        } else {
            const currentDate = moment(data.date, 'MMMM DD, YYYY')
            // const timeIn = moment(data.timeIn, 'h:mmA')
            //     .set('M', currentDate.month())
            //     .set('D', currentDate.date())

            const timeOut = moment(data.timeOut, 'h:mmA')
                .set('M', currentDate.month())
                .set('D', currentDate.date())

            // const attendanceRef = doc(
            //     db,
            //     PERSONNEL_ATTENDANCE_REPOSITORY,
            //     currentEmployee.currentAttendanceId
            // )
            // const timeOut = moment(
            //     data.date + ` ${data.timeOut}`,
            //     'MMMM DD, YYYY h:mmA'
            // )

            // eslint-disable-next-line no-unreachable
            // await updateDoc(attendanceRef, {
            //     timeIn: timeIn.unix(),
            //     timeOut: timeOut.unix(),
            //     modifiedBy: email,
            //     modified: moment().unix(),
            // })
            const ref = doc(db, PERSONNEL_REPOSITORY, currentEmployee.id)
            await updateDoc(ref, {
                // timeInOfTheDay: timeIn.unix(),
                timeOutOfTheDay: timeOut.unix(),
                modifiedBy: email,
                modified: moment().unix(),
            })
            message.destroy()
            message.success('Successfully clocked employee out', 5)
            handleCancel()
        }
    }

    const [short, setShort] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    if (!data) return null
    return isAvailable ? (
        <div style={containerStyle}>
            <VehicleFinancialCalculator data={data} />
            <ModalForm
                form={form}
                onFinishFailed={(err) => {
                    if (err.errorFields.length) {
                        const firstError = err.errorFields[0]
                        message.destroy()
                        message.error(firstError.errors)
                        form.scrollToField(firstError.name, {
                            behavior: 'smooth',
                            block: 'center',
                        })
                    }
                }}
                open={openTimeInOut}
                onFinish={handleFinish}
                modalProps={{
                    onCancel: handleCancel,
                    centered: true,
                }}
                initialValues={{
                    date: moment(),
                }}
                width={300}
                title={currentEmployee?.displayName || ''}
                // onValuesChange={onChangeValue}
            >
                <ProFormDatePicker
                    disabled
                    name="date"
                    label={'Date'}
                    fieldProps={{
                        format: 'MMMM DD, YYYY',
                    }}
                />
                <ProFormTimePicker
                    name="timeIn"
                    label={'Time In'}
                    fieldProps={{
                        format: 'h:mmA',
                        use12Hours: true,
                    }}
                />
                <ProFormTimePicker
                    label={'Time Out'}
                    name="timeOut"
                    fieldProps={{
                        format: 'h:mmA',
                        use12Hours: true,
                    }}
                />
            </ModalForm>
            <Modal
                footer={false}
                title={`Confirm start for shift: ${data.title} | ${data.plate}?`}
                onCancel={closeStartDrawer}
                open={startDrawerOpen}
                getContainer={false}
            >
                <ProForm
                    initialValues={{
                        // driverPayoutStatus: false,
                        // assistantPayoutStatus: false,
                        notes: '',
                        date: moment(),
                    }}
                    form={startForm}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            form.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    onFinish={onSubmitStartTrip}
                    submitter={{
                        resetButtonProps: {
                            style: {
                                display: 'none',
                            },
                            onClick: () => {
                                setShort(0)
                                setTotalRevenue(0)
                            },
                        },
                    }}
                    onValuesChange={(changed, vals) => {
                        if (
                            vals['cashCollected'] &&
                            vals['totalCashReceived']
                        ) {
                            setShort(
                                vals['totalCashReceived'] -
                                    vals['cashCollected']
                            )
                        }
                        if (vals['cashCollected'] && vals['cardRevenue']) {
                            setTotalRevenue(
                                vals['cashCollected'] + vals['cardRevenue']
                            )
                        }
                    }}
                >
                    <Row>
                        <Col span={12}>
                            <ProFormText
                                // fieldProps={{
                                //     format: 'MMMM DD, YYYY',
                                // }}
                                disabled
                                width={216}
                                name={'date'}
                                label={'Clock Start Date'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormTimePicker
                                fieldProps={{
                                    format: 'h:mm A',
                                    use12Hours: true,
                                }}
                                width={216}
                                name={'timeIn'}
                                label={'Clock Start Time'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            />
                            {/* <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="amountCollected"
                                label="Daily Remittance/Collection"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            /> */}
                        </Col>
                    </Row>
                    <ProFormTextArea name="notes" label="Notes" />
                </ProForm>
            </Modal>
            <Modal
                footer={false}
                title={`Setup Overtime for: ${data.title} | ${data.plate}`}
                onCancel={closeOvertimeDrawer}
                open={overtimeDrawerOpen}
                getContainer={false}
            >
                <ProForm
                    form={overtimeForm}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            form.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    onFinish={onSubmitOvertimeTrip}
                    submitter={{
                        resetButtonProps: {
                            style: {
                                display: 'none',
                            },
                        },
                    }}
                >
                    <Row>
                        <Col span={12}>
                            <ProFormTimePicker
                                fieldProps={{
                                    format: 'h:mm A',
                                    use12Hours: true,
                                }}
                                width={216}
                                name={'timeOut'}
                                label={'Time out'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            />
                            {/* <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="amountCollected"
                                label="Daily Remittance/Collection"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            /> */}
                        </Col>
                        <Col span={12}>
                            <ProFormTimePicker
                                fieldProps={{
                                    format: 'h:mm A',
                                    use12Hours: true,
                                }}
                                width={216}
                                name={'startOvertime'}
                                label={'Start Overtime'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <ProFormTextArea name="notes" label="Notes" />
                </ProForm>
            </Modal>
            <Modal
                footer={false}
                title={`Confirm end shift for: ${data.title}-${data.routeName}?`}
                onCancel={closeEndDrawer}
                open={endDrawerOpen}
                getContainer={false}
            >
                <ProForm
                    initialValues={
                        {
                            // driverPayoutStatus: false,
                            // assistantPayoutStatus: false,
                        }
                    }
                    form={endForm}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            form.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    onFinish={onSubmitEndTrip}
                >
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <ProFormTimePicker
                                fieldProps={{
                                    format: 'MMMM DD, YYYY h:mmA',
                                    use12Hours: true,
                                    style: {
                                        width: '100%',
                                    },
                                }}
                                name={'shiftEndTime'}
                                label={'Vehicle Arrival/Standby Time'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="cashCollected"
                                label="Cash Revenue"
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="cardRevenue"
                                label="Card Revenue"
                            />
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="totalGasCost"
                                label="GASOLINE"
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormDigit
                                addonAfter={'Liter'}
                                name="totalGasConsumed"
                                label="Gas Equivalent in Liters"
                            />
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="driverSalary"
                                label="Driver Salary"
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="assistantSalary"
                                label="Assistant Salary"
                            />
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="dailyQuotaIncentives"
                                label="Daily Quota Incentives"
                                addonAfter={'each'}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="dispatchingCost"
                                label="Dispatching Cost"
                            />
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="totalCashReceived"
                                label="Cash Received"
                                addonAfter={'each'}
                            />
                        </Col>
                        <Col span={12}></Col>
                    </Row>
                    <ProForm.Item>
                        <Row>
                            <Col>
                                <Card
                                    bordered={false}
                                    bodyStyle={{
                                        padding: 12,
                                    }}
                                >
                                    <Statistic
                                        title={
                                            <Typography.Text>
                                                Total Revenue
                                            </Typography.Text>
                                        }
                                        value={totalRevenue}
                                        precision={2}
                                        valueStyle={{}}
                                        prefix={'₱'}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card
                                    bordered={false}
                                    bodyStyle={{
                                        padding: 12,
                                    }}
                                >
                                    <Statistic
                                        title={
                                            <Typography.Text>
                                                Short
                                            </Typography.Text>
                                        }
                                        value={short}
                                        precision={2}
                                        valueStyle={{
                                            color:
                                                short >= 0 ? '#3f8600' : 'red',
                                        }}
                                        prefix={
                                            <span>
                                                {short > 0 ? (
                                                    <ArrowUpOutlined />
                                                ) : short === 0 ? null : (
                                                    <ArrowDownOutlined />
                                                )}
                                                ₱
                                            </span>
                                        }
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </ProForm.Item>
                    <ProFormTextArea name="notes" label="Notes" />

                    {/* <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ProFormDigit
                                name="totalRoundTrips"
                                label="Total round trips"
                            />
                        </Col>
                        <Col span={12}></Col>
                    </Row> */}
                    {/* <ProFormCheckbox name="driverPayoutStatus">
                        Check this box if the <b>Driver</b> has already been
                        paid.
                    </ProFormCheckbox>
                    <ProFormCheckbox name="assistantPayoutStatus">
                        Check this box if the <b>Assistant</b> has already been
                        paid.
                    </ProFormCheckbox> */}
                </ProForm>
            </Modal>

            <Card
                loading={loading}
                bodyStyle={{ padding: '0 0' }}
                actions={[
                    <Button
                        onClick={handleStartActiveTrip}
                        disabled={data?.activeTrip?.status === 'active'}
                        shape="round"
                        size="large"
                        key="start"
                        style={
                            data.status !== 'available' ||
                            data?.activeTrip?.status !== 'active'
                                ? {}
                                : { color: '#52c41a', borderColor: '#52c41a' }
                        }
                    >
                        Start Shift
                    </Button>,
                    // <Button
                    //     onClick={handleShowOvertime}
                    //     disabled={
                    //         data?.activeTrip?.status === 'overtime' ||
                    //         data?.activeTrip?.timeIn === ''
                    //     }
                    //     shape="round"
                    //     size="large"
                    //     key="overtime"
                    // >
                    //     Overtime
                    // </Button>,
                    <Button
                        disabled={data?.activeTrip?.status !== 'active'}
                        shape="round"
                        size="large"
                        key="end"
                        danger
                        onClick={endVehicleTrip}
                    >
                        End Shift
                    </Button>,
                ]}
            >
                <Row>
                    <Col span={12}></Col>
                    <Col span={12}>
                        {/* <Card loading={loading} bordered={false}> */}
                        {/* <CountdownTimer
                                date={moment.unix(
                                    data?.activeTrip?.shiftStartTime as number
                                )}
                            /> */}

                        {/* <Statistic
                                title="Gross"
                                value={data?.activeTrip?.totalRevenue}
                                prefix={'₱'}
                            /> */}
                        {/* </Card> */}
                    </Col>
                </Row>
                {data.withGps ? (
                    <GoogleMap>
                        {data.coords && (
                            <Marker
                                {...data.coords}
                                vehicle={data}
                                id={data?.id as string}
                                // use your hover state (from store, react-controllables etc...)
                            />
                        )}
                    </GoogleMap>
                ) : null}
                <Card bodyStyle={{ paddingTop: 16 }} bordered={false}>
                    <Row>
                        <Col span={12}>
                            {driverData && (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                size="large"
                                                icon={<UserOutlined />}
                                            />
                                        }
                                        title={
                                            <Typography.Link
                                                style={{
                                                    fontSize: 18,
                                                    color: '#1890ff',
                                                }}
                                                onClick={() =>
                                                    navigate(
                                                        `personnel/${driverData.id}`
                                                    )
                                                }
                                            >
                                                {driverData?.displayName}
                                            </Typography.Link>
                                        }
                                        description={
                                            <div>
                                                <Row>
                                                    <Typography.Text style={{}}>
                                                        {driverData?.phone}
                                                    </Typography.Text>
                                                </Row>
                                                <Row>
                                                    <Typography.Text style={{}}>
                                                        Driver
                                                    </Typography.Text>
                                                </Row>

                                                {/* <Row
                                                    wrap={false}
                                                    gutter={[4, 0]}
                                                >
                                                    <Col>In:</Col>
                                                    <Col>
                                                        <Typography.Text>
                                                            {driverData.timeInOfTheDay ? (
                                                                moment
                                                                    .unix(
                                                                        driverData.timeInOfTheDay
                                                                    )
                                                                    .format(
                                                                        ' h:mmA'
                                                                    )
                                                            ) : (
                                                                <div>
                                                                    <span>
                                                                        --
                                                                    </span>{' '}
                                                                    <EditFilled
                                                                        style={{
                                                                            color: '#1890ff',
                                                                        }}
                                                                        onClick={() =>
                                                                            handleShow(
                                                                                driverData,
                                                                                'in'
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Typography.Text>
                                                    </Col>
                                                </Row> */}
                                                {/* <Row
                                                    wrap={false}
                                                    gutter={[4, 0]}
                                                >
                                                    <Col>Out:</Col>
                                                    <Col>
                                                        <Typography.Text>
                                                            {driverData.timeOutOfTheDay ? (
                                                                moment
                                                                    .unix(
                                                                        driverData.timeOutOfTheDay
                                                                    )
                                                                    .format(
                                                                        ' h:mmA'
                                                                    )
                                                            ) : (
                                                                <div>
                                                                    <span>
                                                                        --
                                                                    </span>{' '}
                                                                    <EditFilled
                                                                        style={{
                                                                            color: '#1890ff',
                                                                        }}
                                                                        onClick={() =>
                                                                            handleShow(
                                                                                driverData,
                                                                                'out'
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Typography.Text>
                                                    </Col>
                                                </Row> */}
                                            </div>
                                        }
                                    />
                                </List.Item>
                            )}
                        </Col>
                        <Col span={12}>
                            {assistantData && (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                size="large"
                                                icon={<UserOutlined />}
                                            />
                                        }
                                        title={
                                            <Typography.Link
                                                style={{
                                                    fontSize: 18,
                                                    color: '#1890ff',
                                                }}
                                                onClick={() =>
                                                    navigate(
                                                        `personnel/${assistantData.id}`
                                                    )
                                                }
                                            >
                                                {assistantData?.displayName}
                                            </Typography.Link>
                                        }
                                        description={
                                            <div>
                                                <Row>
                                                    <Typography.Text style={{}}>
                                                        {assistantData?.phone}
                                                    </Typography.Text>
                                                </Row>
                                                <Row>
                                                    <Typography.Text style={{}}>
                                                        Assistant
                                                    </Typography.Text>
                                                </Row>

                                                {/* <Row
                                                    wrap={false}
                                                    gutter={[4, 0]}
                                                >
                                                    <Col>In:</Col>
                                                    <Col>
                                                        <Typography.Text>
                                                            {assistantData.timeInOfTheDay ? (
                                                                moment
                                                                    .unix(
                                                                        assistantData.timeInOfTheDay
                                                                    )
                                                                    .format(
                                                                        ' h:mmA'
                                                                    )
                                                            ) : (
                                                                <div>
                                                                    <span>
                                                                        --
                                                                    </span>{' '}
                                                                    <EditFilled
                                                                        style={{
                                                                            color: '#1890ff',
                                                                        }}
                                                                        onClick={() =>
                                                                            handleShow(
                                                                                assistantData,
                                                                                'in'
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Typography.Text>
                                                    </Col>
                                                </Row>
                                                <Row
                                                    wrap={false}
                                                    gutter={[4, 0]}
                                                >
                                                    <Col>Out:</Col>
                                                    <Col>
                                                        <Typography.Text>
                                                            {assistantData.timeOutOfTheDay ? (
                                                                moment
                                                                    .unix(
                                                                        assistantData.timeOutOfTheDay
                                                                    )
                                                                    .format(
                                                                        ' h:mmA'
                                                                    )
                                                            ) : (
                                                                <div>
                                                                    <span>
                                                                        --
                                                                    </span>{' '}
                                                                    <EditFilled
                                                                        style={{
                                                                            color: '#1890ff',
                                                                        }}
                                                                        onClick={() =>
                                                                            handleShow(
                                                                                assistantData,
                                                                                'out'
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Typography.Text>
                                                    </Col>
                                                </Row> */}
                                            </div>
                                        }
                                    />
                                </List.Item>
                            )}
                        </Col>
                    </Row>
                </Card>
            </Card>
        </div>
    ) : (
        <TripContainer />
    )
}

export default ActiveTrip
