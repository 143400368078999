import { DeleteFilled, DownOutlined, RightOutlined } from '@ant-design/icons'
import { ProList } from '@ant-design/pro-components'
import {
    Card,
    Typography,
    Row,
    Col,
    Divider,
    // Badge,
    // BadgeProps,
    Statistic,
    Tooltip,
    Image,
    Empty,
    Button,
    message,
    Popconfirm,
} from 'antd'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { TRIPS_REPOSITORY } from '../../../../data/repositories/constants'
import { Personnel, Trip } from '../../../../domain/entities/Transport'
import { db } from '../../../boot'
import moment from 'moment'

import { BiTimer } from 'react-icons/bi'
import { MdTimerOff } from 'react-icons/md'
// import { GiPathDistance } from 'react-icons/gi'
import GoogleMap from '../../../../app/components/GoogleMap'
import Marker from '../../../../app/components/Marker'
import ShiftScheduler from '../ShiftScheduler'
import { useAppSelector } from '../../../../app/store'
import { shallowEqual } from 'react-redux'
import ViewEndTripDetails from './ViewEndTripDetails'
import { deleteTrip } from '../../../../app/redux/team/team.action'
import EmployeeAvatar from '../../../../app/components/EmployeeAvatar'

interface TripSchedulesProps {
    status: Trip['status']
}

const TripSchedules = ({ status }: TripSchedulesProps) => {
    const [schedules, setSchedules] = useState<any[]>([])
    const [expandedRowKeys, setExpandedRowKeys] = useState<readonly any[]>([])
    const [loading, setLoading] = useState(false)

    const { currentVehicleId, data, user } = useAppSelector(
        (state) => ({
            user: state.auth.user,
            data: state.vehicle.selectedVehicleId
                ? state.vehicle.vehicles.entities[
                      state.vehicle.selectedVehicleId
                  ]
                : undefined,
            currentVehicleId: state.vehicle.selectedVehicleId,
        }),
        shallowEqual
    )
    // const dispatch = useAppDispatch()
    useEffect(() => {
        if (!data?.id) return

        const main = async () => {
            setLoading(true)
            const ref = collection(db, TRIPS_REPOSITORY)
            const q = query(
                ref,
                where('vehicleId', '==', data.id),
                where('status', '==', status),
                where('isRemoved', '==', false)
            )

            const doc = await getDocs(q)

            const datas = doc.docs.map(
                (docSnap) =>
                    ({
                        ...docSnap.data(),
                        id: docSnap.id,
                    } as Trip)
            )
            // eslint-disable-next-line no-console
            // console.log(datas)

            setSchedules(
                datas.sort((a, b) => b.shiftStartTime - a.shiftStartTime)
            )

            const objs: any = {}

            datas.forEach((sched, idx) => {
                const date = sched.shiftStartTime
                    ? moment.unix(sched.shiftStartTime).format('DD-MM-yyyy')
                    : sched.date
                if (!objs[date]) {
                    objs[date] = []
                }

                objs[date].push({
                    id: idx,
                    startAt: sched.shiftStartTime
                        ? moment.unix(sched.shiftStartTime).toISOString()
                        : sched.date,
                    endAt: sched.shiftStartTime
                        ? moment
                              .unix(sched.shiftStartTime)
                              .add(8, 'hours')
                              .toISOString()
                        : sched.date,
                    summary: sched.notes,
                    color: '',
                })
            })
            setLoading(false)
        }

        main()
    }, [data, status, currentVehicleId])

    // const getListData = (date: any) => {
    //     const nowMonth = date.format('DDDD')
    //     const nowYear = date.format('YYYY')
    //     return schedules
    //         .map((sched: Trip) => {
    //             const schedMonth = sched.shiftStartTime
    //                 ? moment.unix(sched.shiftStartTime).format('DDDD')
    //                 : sched.date
    //             const schedYear = sched.shiftStartTime
    //                 ? moment.unix(sched.shiftStartTime).format('YYYY')
    //                 : sched.date
    //             if (
    //                 schedMonth.includes(nowMonth) &&
    //                 schedYear.includes(nowYear)
    //             )
    //                 return {
    //                     type:
    //                         sched.paymentStatus === 'pending'
    //                             ? 'warning'
    //                             : 'success',
    //                     content: `${
    //                         sched.shiftStartTime
    //                             ? moment
    //                                   .unix(sched.shiftStartTime)
    //                                   .format('h:mm A')
    //                             : ''
    //                     }`,
    //                 }
    //             return false
    //         })
    //         .filter(Boolean)
    // }

    // const getMonthData = (date: Moment) => {
    //     const nowMonth = date.format("MMMM")
    //     const nowYear = date.format("YYYY")
    //     return schedules.map(sched => {
    //         const schedMonth = sched.shiftStartTime ?   moment.unix(sched.shiftStartTime).format("MMMM") : sched.date
    //         const schedYear = sched.shiftStartTime ?   moment.unix(sched.shiftStartTime).format("YYYY") : sched.date
    //         if(schedMonth.includes(nowMonth) && schedYear.includes(nowYear)) return { type: 'warning', content: sched.date}
    //         return false
    //     }).filter(Boolean)
    // }
    // const monthCellRender = (value: Moment) => {
    //     const num = getMonthData(value);
    //     return num ? (
    //       <div className="notes-month">
    //         <section>{num}</section>
    //         <span>Backlog number</span>
    //       </div>
    //     ) : null;
    //   };

    // const dateCellRender = (value: any) => {
    //     const listData = getListData(value)
    //     return (
    //         <ul className="events">
    //             {listData.map((item: any) => (
    //                 <li key={item.content}>
    //                     <Badge
    //                         status={item.type as BadgeProps['status']}
    //                         text={item.content}
    //                     />
    //                 </li>
    //             ))}
    //         </ul>
    //     )
    // }

    //   const cellRender = (current: any, info: any) => {
    //     if (info.type === 'date') return dateCellRender(current);
    //     if (info.type === 'month') return monthCellRender(current);
    //     return info.originNode;
    //   };

    const [endDrawerOpen, setEndDrawerOpen] = useState(false)
    const [vehicle, setVehicle] = useState<Trip | undefined>(undefined)

    const showEndTripVehicle = async (vehicle: Trip) => {
        // eslint-disable-next-line no-console
        try {
            setVehicle(vehicle)
            // endForm.resetFields()
            // clearSignature()
            const driverData = vehicle?.driver
            const assistantData = vehicle?.assistant
            if (driverData && !driverData.salary) {
                message.error('Driver has no salary')
            }
            if (assistantData && !assistantData.salary) {
                message.error('Assistant has no salary')
            }
            // eslint-disable-next-line no-console
            // console.log(vehicle, additionals)
            setEndDrawerOpen(true)
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err)
        }
    }

    const onSuccessUpdateTrip = async (data: any) => {}
    const cancelEndTrip = () => {
        setEndDrawerOpen(false)
        // endForm.resetFields()
        // setTotalExpense(0)
    }
    // eslint-disable-next-line no-console

    if (!data) return null

    return (
        <div>
            {endDrawerOpen && (
                <ViewEndTripDetails
                    title={
                        vehicle?.title
                            ? vehicle.title
                            : `${data.title} - ${data.routeName}`
                    }
                    isEdit
                    vehicle={vehicle as any}
                    onFinish={onSuccessUpdateTrip}
                    onClose={cancelEndTrip}
                />
            )}
            {status === 'waiting' ? (
                <ShiftScheduler />
            ) : (
                <ProList<Trip>
                    rowKey="id"
                    headerTitle={
                        <Typography.Title level={3}>
                            {data.title} - {data.routeName}
                        </Typography.Title>
                    }
                    // toolBarRender={() => {
                    //     return [
                    //         <Button key="3" type="primary">
                    //             新建
                    //         </Button>,
                    //     ];
                    // }}

                    expandable={{
                        expandedRowKeys,
                        onExpandedRowsChange: setExpandedRowKeys,
                        expandIcon: (prop) => {
                            if (prop.expanded) {
                                return <DownOutlined />
                            } else {
                                return <RightOutlined />
                            }
                        },
                    }}
                    loading={loading}
                    dataSource={schedules}
                    size="large"
                    bordered
                    cardBordered
                    metas={{
                        title: {
                            dataIndex: 'date',
                            render: (date, record, idx, action, schema) => {
                                return (
                                    <div
                                        onClick={() =>
                                            expandedRowKeys.includes(record.id)
                                                ? setExpandedRowKeys([])
                                                : setExpandedRowKeys([
                                                      record.id,
                                                  ])
                                        }
                                    >
                                        {date} -{' '}
                                        {record?.shiftStartTime
                                            ? moment
                                                  .unix(record.shiftStartTime)
                                                  .format('h:mm A')
                                            : ''}
                                    </div>
                                )
                            },
                        },

                        // subTitle: {
                        //     dataIndex: 'driverPayoutStatus',
                        //     render: (isPaid, record) => {
                        //         return typeof isPaid === 'boolean' ? (
                        //             <Space>
                        //                 <Tag
                        //                     color={
                        //                         !isPaid ? 'warning' : '#5BD8A6'
                        //                     }
                        //                 >
                        //                     Driver:{' '}
                        //                     {!isPaid ? 'unpaid' : 'paid'}
                        //                 </Tag>
                        //                 {typeof record.assistantPayoutStatus ===
                        //                 'boolean' ? (
                        //                     <Tag
                        //                         color={
                        //                             record.assistantPayoutStatus
                        //                                 ? '#5BD8A6'
                        //                                 : 'warning'
                        //                         }
                        //                     >
                        //                         Assistant:{' '}
                        //                         {!record.assistantPayoutStatus
                        //                             ? 'unpaid'
                        //                             : 'paid'}
                        //                     </Tag>
                        //                 ) : null}
                        //             </Space>
                        //         ) : null
                        //     },
                        // },
                        // description: {
                        //     render: () => {
                        //         return 'Ant Design, a design language for background applications, is refined by Ant UED Team';
                        //     },
                        // },
                        // avatar: {},
                        description: {
                            render: (dom, schedule) => {
                                const revenue =
                                    schedule.cashCollected +
                                    schedule.cardRevenue

                                return (
                                    <Card bodyStyle={{ padding: 0 }}>
                                        {/* <Typography.Title level={4} type="secondary">
                                            <Typography.Text
                                                style={{ fontWeight: 'bold' }}
                                            >
                                            </Typography.Text>
                                        </Typography.Title> */}
                                        <Row>
                                            <Col span={12}>
                                                <Card
                                                    style={{ height: '100%' }}
                                                    bordered={false}
                                                >
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            fontSize: 24,
                                                        }}
                                                    >
                                                        {data.title}
                                                    </p>

                                                    <Typography.Paragraph
                                                        style={{
                                                            marginBottom: 6,
                                                        }}
                                                    >
                                                        <Typography.Text>
                                                            {data.plate}
                                                        </Typography.Text>{' '}
                                                        {' | '}
                                                        <Typography.Text>
                                                            {data.routeName}
                                                        </Typography.Text>
                                                    </Typography.Paragraph>
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card bordered={false}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Row
                                                                gutter={[6, 12]}
                                                                align={'middle'}
                                                            >
                                                                <Col span={2}>
                                                                    <BiTimer
                                                                        style={{
                                                                            color: 'yellowgreen',
                                                                            fontSize: 20,
                                                                            marginLeft:
                                                                                -2,
                                                                            marginTop: 6,
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text
                                                                        style={{
                                                                            marginBottom: 4,
                                                                        }}
                                                                    >
                                                                        {schedule?.shiftStartTime
                                                                            ? moment
                                                                                  .unix(
                                                                                      schedule.shiftStartTime
                                                                                  )
                                                                                  .format(
                                                                                      'ddd · MMM DD · h:mmA'
                                                                                  )
                                                                            : '--'}
                                                                    </Typography.Text>
                                                                </Col>
                                                            </Row>
                                                            <Row
                                                                gutter={[6, 12]}
                                                                align={'middle'}
                                                            >
                                                                <Col span={2}>
                                                                    <MdTimerOff
                                                                        style={{
                                                                            color: 'red',
                                                                            fontSize: 18,
                                                                            marginTop: 6,
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text
                                                                        style={{
                                                                            marginBottom: 4,
                                                                        }}
                                                                    >
                                                                        {schedule?.shiftEndTime
                                                                            ? moment
                                                                                  .unix(
                                                                                      schedule.shiftEndTime
                                                                                  )
                                                                                  .format(
                                                                                      'ddd · MMM DD · h:mmA'
                                                                                  )
                                                                            : '--'}
                                                                    </Typography.Text>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        <Col span={24}>
                                                            <Row
                                                                gutter={[6, 12]}
                                                                align={'middle'}
                                                            >
                                                                <Col span={2}>
                                                                    <GiPathDistance
                                                                        style={{
                                                                            color: 'lightblue',
                                                                            fontSize: 18,
                                                                            marginTop: 6,
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text
                                                                        style={{
                                                                            marginBottom: 4,
                                                                        }}
                                                                    >
                                                                        {schedule?.distanceTraveled ||
                                                                            '--'}
                                                                    </Typography.Text>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row> */}
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Card
                                            loading={loading}
                                            bordered={false}
                                            bodyStyle={{
                                                padding: '0 24px 24px',
                                            }}
                                        >
                                            <Row gutter={[24, 24]}>
                                                <Col span={8}>
                                                    <Tooltip
                                                        title={
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography.Text
                                                                                type="secondary"
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Total
                                                                                cash
                                                                                revenue
                                                                            </Typography.Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                    fontWeight:
                                                                                        'bolder',
                                                                                }}
                                                                            >
                                                                                ₱
                                                                                {schedule.cashCollected ||
                                                                                    '---'}
                                                                            </Typography.Text>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography.Text
                                                                                type="secondary"
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Total
                                                                                card
                                                                                revenue
                                                                            </Typography.Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                    fontWeight:
                                                                                        'bolder',
                                                                                }}
                                                                            >
                                                                                ₱
                                                                                {schedule.cardRevenue ||
                                                                                    '---'}
                                                                            </Typography.Text>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        }
                                                    >
                                                        <Statistic
                                                            title="Gross Revenue"
                                                            value={
                                                                schedule.cashCollected +
                                                                schedule.cardRevenue
                                                            }
                                                            prefix={'₱'}
                                                            // valueStyle={{
                                                            //     color: "#1890ff"
                                                            // }}
                                                            precision={2}
                                                        />
                                                    </Tooltip>
                                                </Col>
                                                <Col span={8}>
                                                    <Tooltip
                                                        title={
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography.Text
                                                                                type="secondary"
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Driver
                                                                                Salary
                                                                            </Typography.Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                    fontWeight:
                                                                                        'bolder',
                                                                                }}
                                                                            >
                                                                                ₱
                                                                                {schedule.driverSalary ||
                                                                                    '---'}
                                                                            </Typography.Text>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography.Text
                                                                                type="secondary"
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Driver
                                                                                Incentives
                                                                            </Typography.Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                    fontWeight:
                                                                                        'bolder',
                                                                                }}
                                                                            >
                                                                                ₱
                                                                                {schedule.driverIncentives ||
                                                                                    '---'}
                                                                            </Typography.Text>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography.Text
                                                                                type="secondary"
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Driver
                                                                                Overtime
                                                                                Pay
                                                                            </Typography.Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                    fontWeight:
                                                                                        'bolder',
                                                                                }}
                                                                            >
                                                                                ₱
                                                                                {schedule.driverOvertimePay ||
                                                                                    '---'}
                                                                            </Typography.Text>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography.Text
                                                                                type="secondary"
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Assistant
                                                                                Salary
                                                                            </Typography.Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                    fontWeight:
                                                                                        'bolder',
                                                                                }}
                                                                            >
                                                                                ₱
                                                                                {schedule.assistantSalary ||
                                                                                    '---'}
                                                                            </Typography.Text>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography.Text
                                                                                type="secondary"
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Assistant
                                                                                Incentives
                                                                            </Typography.Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                    fontWeight:
                                                                                        'bolder',
                                                                                }}
                                                                            >
                                                                                ₱
                                                                                {schedule.assistantIncentives ||
                                                                                    '---'}
                                                                            </Typography.Text>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography.Text
                                                                                type="secondary"
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Assistant
                                                                                Overtime
                                                                                Pay
                                                                            </Typography.Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                    fontWeight:
                                                                                        'bolder',
                                                                                }}
                                                                            >
                                                                                ₱
                                                                                {schedule.assistantOvertimePay ||
                                                                                    '---'}
                                                                            </Typography.Text>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography.Text
                                                                                type="secondary"
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Gas
                                                                                Cost
                                                                            </Typography.Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                    fontWeight:
                                                                                        'bolder',
                                                                                }}
                                                                            >
                                                                                ₱
                                                                                {schedule.totalGasCost ||
                                                                                    '---'}
                                                                            </Typography.Text>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography.Text
                                                                                type="secondary"
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Miscellaneous
                                                                                Expenses
                                                                            </Typography.Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                    fontWeight:
                                                                                        'bolder',
                                                                                }}
                                                                            >
                                                                                ₱
                                                                                {schedule.miscellaneousExpenses ||
                                                                                    '---'}
                                                                            </Typography.Text>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        }
                                                    >
                                                        <Statistic
                                                            title="Total Expenses"
                                                            value={
                                                                schedule.totalExpenses ||
                                                                0
                                                            }
                                                            prefix={'₱'}
                                                            valueStyle={{
                                                                color: 'red',
                                                            }}
                                                            precision={2}
                                                        />
                                                    </Tooltip>
                                                </Col>
                                                <Col>
                                                    <Statistic
                                                        title="Net Income"
                                                        value={
                                                            revenue -
                                                            (schedule.totalExpenses ||
                                                                0)
                                                        }
                                                        valueStyle={{
                                                            color:
                                                                revenue -
                                                                    (schedule?.totalExpenses ||
                                                                        0) >=
                                                                0
                                                                    ? 'green'
                                                                    : 'red',
                                                        }}
                                                        prefix={'₱'}
                                                        precision={2}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                        {data.withGps ? (
                                            <GoogleMap>
                                                {data.coords && (
                                                    <Marker
                                                        {...data.coords}
                                                        vehicle={data}
                                                        id={data?.id as string}
                                                        // use your hover state (from store, react-controllables etc...)
                                                    />
                                                )}
                                            </GoogleMap>
                                        ) : (
                                            <Divider />
                                        )}

                                        <Row gutter={[0, 12]}>
                                            <Col xs={24} md={12}>
                                                <EmployeeAvatar
                                                    employee={
                                                        schedule.driver as Personnel
                                                    }
                                                />
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <EmployeeAvatar
                                                    employee={
                                                        schedule.assistant as Personnel
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Divider />
                                        <Row
                                            justify={'center'}
                                            style={{ paddingBottom: 14 }}
                                        >
                                            {schedule.signatureUrl ? (
                                                <Image
                                                    src={schedule.signatureUrl}
                                                    preview={false}
                                                />
                                            ) : (
                                                <Empty
                                                    description={
                                                        'No signature.'
                                                    }
                                                />
                                            )}
                                        </Row>
                                    </Card>
                                )
                            },
                        },

                        actions: {
                            render: (text, row, index, action) => [
                                <div
                                    key="view-more"
                                    style={{
                                        position: 'absolute',
                                        top: -8,
                                        left: -64,
                                    }}
                                >
                                    <Button
                                        type="link"
                                        size="large"
                                        onClick={() => showEndTripVehicle(row)}
                                    >
                                        View More
                                    </Button>
                                </div>,
                                <div
                                    key="delete"
                                    style={{
                                        position: 'absolute',
                                        top: -8,
                                        left: 20,
                                        // @ts-ignore
                                        opacity:
                                            user.partnerCode === 'perati' ||
                                            user.role === 'chairman' ||
                                            user.role === 'general_manager'
                                                ? 1
                                                : 0,
                                    }}
                                >
                                    <Popconfirm
                                        title={'Are you sure to delete?'}
                                        onConfirm={() =>
                                            deleteTrip(row.id as string)
                                        }
                                    >
                                        <Button type="link" size="large">
                                            <DeleteFilled
                                                style={{ color: 'red' }}
                                            />
                                        </Button>
                                    </Popconfirm>
                                </div>,
                            ],
                        },
                    }}
                />
            )}
        </div>
    )
}

export default TripSchedules
