import { SavingFirebaseRepository } from '../repositories/saving.repository'
import { Saving } from '../entities/Saving'

export class SavingService {
    savingRepo: SavingFirebaseRepository

    constructor(ir: SavingFirebaseRepository) {
        this.savingRepo = ir
    }

    async AddSaving(payload: Omit<Saving, 'id'>) {
        return await this.savingRepo.AddSaving(payload)
    }

    async getTeamSavings(partnerCode: string) {
        return await this.savingRepo.getTeamSavings(partnerCode)
    }

    async DeleteSaving(id: string) {
        return await this.savingRepo.DeleteSaving(id)
    }

    async GetSaving(id: string) {
        return await this.savingRepo.GetSaving(id)
    }

    async UpdateSaving(
        payload: Saving & {
            id: string
        }
    ) {
        return await this.savingRepo.UpdateSaving(payload)
    }
    async GetAllMemberSaving(payload: {
        memberId: string
        partnerCode: string
    }) {
        return await this.savingRepo.GetAllMemberSaving(payload)
    }
}
