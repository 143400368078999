/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Modal, Upload, Button, Row, notification, message } from 'antd'
import { DeleteFilled, InboxOutlined } from '@ant-design/icons'
// import { UploadTutorFiles } from '@/app/redux/tutors/tutors.actions'
// import { useRouter } from 'next/router'
// import { mixPanel } from '@/data/utils/mixpanelClass'
// import { FileTab } from '@/domain/entities/File/file'
import { shallowEqual, useDispatch } from 'react-redux'
import { FileTab } from '../../../domain/entities/File'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../store'
import moment from 'moment'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../boot'
import { UploadFile } from '../../redux/team/team.action'
import { randomId } from '../../pages/utils'
import FilesDeleteModal from './FilesDeleteModal'
// import { companyType } from '@/app/redux/company/company.type'
// import { authType } from '@/app/redux/auth/auth.type'

interface AddFilesModalProps {
    filesModal: boolean
    // userId: string
    folderId: string
    loadingUpload: boolean
    setLoadingUpload: (value: boolean) => void
    setFilesModal: (value: boolean) => void
    accept?: string
    onCancel?: () => void
    default?: any[]
    defaultData?: any
    employeeId?: string
    // currentTab?: FileTab
}

const { Dragger } = Upload
const AddFilesModal = ({
    // currentTab,
    filesModal,
    setFilesModal,
    // userId,
    loadingUpload,
    setLoadingUpload,
    folderId,
    accept,
    onCancel,
    defaultData,
    employeeId,
}: AddFilesModalProps) => {
    const history = useLocation()
    const queryFolderName = ''

    const [newFilesData, setNewFilesData] = useState([])
    const [fileUrls, setFileUrls] = useState<any>([])
    const [fileStatus, setFileStatus] = useState({})
    const { user } = useAppSelector(
        (state) => ({ user: state.auth.user }),
        shallowEqual
    )
    const fileDisableChecker = () => {
        if (newFilesData.length < 1) {
            return true
        } else {
            return false
        }
    }
    const handleCancel = () => {
        onCancel && onCancel()
        setNewFilesData([])
    }
    const cancelModalFiles = () => {
        setNewFilesData([])
        setFilesModal(false)
    }
    const dispatch = useDispatch()
    // eslint-disable-next-line no-console
    console.log(fileStatus, fileUrls)
    const uploadFilesHandler = async (info: any) => {
        setLoadingUpload(true)
        setNewFilesData(info.fileList)
        const file = info.file
        const logoRef = ref(
            storage,
            `/${user.activeTeam}/files/${randomId(3).toLowerCase()}-${
                file.name
            }`
        )
        const uploadTask = uploadBytesResumable(logoRef, file.originFileObj)
        return uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                switch (snapshot.state) {
                    case 'paused':
                        // eslint-disable-next-line no-console
                        console.log('Upload is paused')
                        break
                    case 'running':
                        // eslint-disable-next-line no-console
                        console.log('Upload is running')
                        setFileStatus({
                            ...fileStatus,
                            [file.uid]:
                                snapshot.bytesTransferred / snapshot.totalBytes,
                        })
                        break
                    case 'success':
                        // eslint-disable-next-line no-console
                        message.success('Successfully uploaded')
                        break
                }
            },
            (error) => {
                setLoadingUpload(false)
                // Handle unsuccessful uploads
                message.error(error.message)
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then(
                    async (downloadURL) => {
                        const payload = {
                            fileUrl: downloadURL,
                            folderId,
                            partnerCode: user.activeTeam,
                            creation: moment().unix(),
                            modified: moment().unix(),
                            isRemoved: false,
                            modifiedBy: user.email,
                            name: uploadTask.snapshot.metadata.name,
                            size: uploadTask.snapshot.metadata.size,
                            fileName: uploadTask.snapshot.metadata.name,
                            type: file.type,
                            employeeId: employeeId || '',
                            ref:
                                uploadTask.snapshot.metadata.ref?.fullPath ||
                                (await uploadTask).ref.fullPath,
                            status: 'done',
                            isEmployeeRecords: folderId === 'employee-records',
                        }

                        setLoadingUpload(false)
                        setFileUrls([...fileUrls, payload])
                        await UploadFile(payload)
                        message.success(file.name)
                        console.log(uploadTask.snapshot.metadata.size)
                        // if (fieldName === 'backgroundUrl') {
                        //     setBackgroundUrl(downloadURL)
                        // } else {
                        //     setFileUrl(downloadURL)
                        // }
                    }
                )
            }
        )
        // const promises = info.fileList.map((file: any) => {

        // })
        // await Promise.all(promises)
        // if (currentTab === "vip-files") {
        //     payload.company = true
        // }

        // const resp = await UploadTutorFiles(payload)
        // if (resp === "success") {
        //     notification.success({
        //         message: "Files successfully uploaded.",
        //         duration: 3
        //     })
        //     setNewFilesData([])
        //     setLoadingUpload(false)
        //     setFilesModal(false)
        //     mixPanel.track("Files Added")
        // } else {
        //     notification.error({
        //         message: `Something went wrong. ${resp}`,
        //         duration: 5
        //     })
        //     setLoadingUpload(false)
        //     setFilesModal(false)
        // }
    }

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [toDeleteData, setToDeleteData] = useState({
        tutorId: '',
        id: '',
        folderName: '',
        fileType: '',
        fileName: '',
        fileUrl: '',
    })

    const openModalHandler = (id: string) => {
        setOpenDeleteModal(true)
        setToDeleteData({
            ...toDeleteData,
            id,
        })
    }

    const handleRemove = async (file: any) => {
        console.log(file, false)

        return true
    }

    useEffect(() => {
        if (!defaultData) return
        setFileUrls(defaultData)
    }, [defaultData])
    return (
        <div key={folderId}>
            <FilesDeleteModal
                toDeleteData={toDeleteData}
                setOpenDeleteModal={setOpenDeleteModal}
                openDeleteModal={openDeleteModal}
                setToDeleteData={setToDeleteData}
                studentOrTutor="student"
            />
            <Modal
                title="ADD FILES"
                open={filesModal}
                footer={null}
                closeIcon={false}
                onCancel={handleCancel}
                destroyOnClose
            >
                <Dragger
                    multiple={false}
                    showUploadList={false}
                    customRequest={() => null}
                    fileList={newFilesData}
                    beforeUpload={() => true}
                    onChange={uploadFilesHandler}
                    accept={accept}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                        Click or drag file to this area to upload
                    </p>
                    {/* <p className="ant-upload-hint">
                    Maximum of 50MB files are only accepted
                </p> */}
                </Dragger>
                <Upload
                    showUploadList={{
                        removeIcon(file) {
                            return (
                                <DeleteFilled
                                    onClick={() => handleRemove(file)}
                                />
                            )
                        },
                    }}
                    fileList={fileUrls}
                />
                <br />
                <br />
                <Row justify="space-between">
                    <Button onClick={handleCancel} loading={loadingUpload}>
                        Cancel
                    </Button>
                    {/* <Button
                    type="primary"
                    onClick={uploadFilesHandler}
                    disabled={fileDisableChecker()}
                    loading={loadingUpload}
                >
                    Add
                </Button> */}
                </Row>
            </Modal>
        </div>
    )
}

export default AddFilesModal
