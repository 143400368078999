import { CloudFirebaseImplementation } from '../../../data/repositories/api/cloud.firebase'
import { CloudFirebaseService } from '../../../domain/usecases/cloud.usecase'

export interface UploadProp {
    folderName: string
    fileName: string
    file: any
    callback?: (resp: any) => void
}
const repo = new CloudFirebaseImplementation()
export const uploadFile = async (payload: UploadProp) => {
    const cloud = new CloudFirebaseService(repo)
    const { folderName, fileName, file, callback } = payload
    return await cloud.uploadFile(folderName, fileName, file, callback)
}
