import { EmployementStatus } from '@/data/repositories/constants'
import { Member } from '../entities/Member'
import { Team, TeamMember, TeamPermission } from '../entities/Team'
import { TeamRepository } from '../repositories/team.repository'

export class TeamService {
    teamRepo: TeamRepository

    constructor(ir: TeamRepository) {
        this.teamRepo = ir
    }
    async createTeam(payload: Team) {
        return await this.teamRepo.createTeam(payload)
    }

    async getMyTeams(payload: string[]) {
        return await this.teamRepo.getMyTeams(payload)
    }

    getMyTeam(teamId: string, callback: (payload: Team) => void) {
        return this.teamRepo.getMyTeam(teamId, callback)
    }

    async getTeamMembers(partnerCode: string, payload: any) {
        return await this.teamRepo.getTeamMembers(partnerCode, payload)
    }

    async getTeamGroupMembers(partnerCode: string, payload: any) {
        return await this.teamRepo.getTeamGroupMembers(partnerCode, payload)
    }

    getTeamMemberPermission(
        payload: {
            partnerCode: string
            memberId: string
        },
        callback: (permission: TeamPermission) => void
    ) {
        return this.teamRepo.getTeamMemberPermission(payload, callback)
    }

    async addTeamMember(payload: Member, partnerCode: string) {
        return await this.teamRepo.addTeamMember(payload, partnerCode)
    }

    async removeTeamMember(memberId: string, status: EmployementStatus) {
        return await this.teamRepo.removeTeamMember(memberId, status)
    }

    async updateTeamMember(
        payload: Partial<TeamMember> & {
            id: string
            partnerCode: string
        }
    ) {
        return await this.teamRepo.updateTeamMember(payload)
    }

    async getTeamMember(payload: { partnerCode: string; memberId: string }) {
        return await this.teamRepo.getTeamMember(payload)
    }

    async updateTeam(
        payload: Partial<Team> & {
            id: string
        }
    ) {
        return await this.teamRepo.updateTeam(payload)
    }
}
