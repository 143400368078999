import { db } from '../../../boot'
import {
    Checkbox,
    // Button,
    // Card,
    Col,
    Modal,
    Row,
    // Statistic,
    // Table,
    Typography,
    message,
} from 'antd'
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { Personnel } from '@/domain/entities/Transport'
// import moment from 'moment'
import { ProFormDateRangePicker } from '@ant-design/pro-components'
import { useAppSelector } from '../../../../app/store'
import { shallowEqual } from 'react-redux'
import moment from 'moment'
import { utils, writeFile } from 'xlsx'
import {
    PAYROLLS_REPOSITORY,
    PERSONNEL_ATTENDANCE_REPOSITORY,
    // PERSONNEL_ATTENDANCE_REPOSITORY,
    PERSONNEL_REPOSITORY,
    busPersonnelRoles,
} from '../../../../data/repositories/constants'
// import { Expense } from '@/domain/entities/SystemSettings'
import { Attendance } from '@/domain/entities/Attendance'
import { calculateTime } from '../../Scheduling/scheduleUtils'
// import ColumnGroup from 'antd/lib/table/ColumnGroup'
// import Column from 'antd/lib/table/Column'
import EditableTable from '../../../../app/components/EditableTable'
import './index.css'
import { calculateWorkTime } from '../../My/MyTeam/hrUtils'
import { PayrollAddons } from '@/domain/entities/Payroll'
function PayrollContainer({
    onCancel,
    data,
}: {
    onCancel: () => void
    data: any
}) {
    const { activeTeam, teamName, hr, email, payrollSettings } = useAppSelector(
        (state) => ({
            // styles: state.styles,
            // // totalExpenses:
            email: state.auth.user.email,
            teamName: state.team.selectedTeamData.name,
            hr: state.team.selectedTeamData.hr,
            payrollSettings: state.team.selectedTeamData.payroll,
            //     totalCollection:
            //         state.team.selectedTeamData?.financialPerformance?.totalCollection || 0,
            // expenses: state.expense.expenses,
            activeTeam: state.auth.user.activeTeam,
        }),
        shallowEqual
    )
    const [dates, setDates] = useState<any[]>([])
    const [payroll, setPayroll] = useState<any>(undefined)
    const [payslips, setPayslips] = useState<any>([])
    const [newPayslip, setNewPayslip] = useState<any>(undefined)
    const [isEdit, setIsEdit] = useState(false)
    // const [, setTotalSalaries] = useState(0)

    const [open, setOpen] = useState(false)
    const [deductionReady, setDeductionReady] = useState(false)
    const dateRef = useRef(null)
    const [deductions, setDeductions] = useState<PayrollAddons[]>([])
    const [deductionObj, setDeductionObj] = useState<any>({})

    const handleSubmit = async () => {
        // eslint-disable-next-line no-console
        // console.log(newPayslip)
        let totalPayrollAmount = 0
        let totalPayrollDeduction = 0
        const fixedPayslip = newPayslip.map((payslip: any) => {
            const obj: any = {}
            Object.keys(payslip).forEach((pay) => {
                const value = payslip[pay]
                const isNumber =
                    pay !== 'id' && pay !== 'data' && pay !== 'name'
                obj[pay] = isNumber ? parseFloat(value) : value
            })
            const rate = obj.rate
            const days = obj.days
            const amount = rate * days
            const overtimeHours = obj.overtimeHours

            const overtimePayEquation = 1.25
            const OTrate = rate / 8
            const overtimePay =
                Math.round(OTrate * overtimePayEquation) * overtimeHours
            const incentives = obj.incentives
            const gross = amount + incentives + overtimePay

            let net = gross
            let totalDeduction = 0
            deductions.forEach((deduction) => {
                if (deduction.isChecked && payslip[deduction.key]) {
                    net -= payslip[deduction.key]
                    totalDeduction += payslip[deduction.key]
                }
            })
            totalPayrollAmount += net
            totalPayrollDeduction += totalDeduction
            obj['totalDeduction'] = totalDeduction
            obj['deductions'] = deductions
            obj['netPay'] = net
            obj['salary'] = gross
            obj['overtime'] = overtimePay
            return obj
        })
        const payrollPayload = {
            start: dates[0].unix(),
            end: dates[1].unix(),
            data: fixedPayslip,
            creation: moment().unix(),
            modified: moment().unix(),
            modifiedBy: email,
            partnerCode: activeTeam,
            status: 'pending',
            totalPayrollAmount,
            totalPayrollDeduction,
            deductions,
            isRemoved: false,
        }
        // eslint-disable-next-line no-console
        // console.log(payrollPayload)
        const ref = collection(db, PAYROLLS_REPOSITORY)
        await addDoc(ref, payrollPayload)
        message.success('Successfully created payroll')
        setNewPayslip(undefined)
        onCancel()
    }

    const handleChange = (records: any) => {
        // eslint-disable-next-line no-console
        // console.log(records)
        setNewPayslip(records)
    }

    const handleUpdate = async () => {
        // eslint-disable-next-line no-console
        // console.log(payroll, newPayslip)
        let totalPayrollAmount = 0
        let totalPayrollDeduction = 0
        const fixedPayslip = newPayslip.map((payslip: any) => {
            const obj: any = {}
            Object.keys(payslip).forEach((pay) => {
                const value = payslip[pay]
                const isNumber =
                    pay !== 'id' && pay !== 'data' && pay !== 'name'
                obj[pay] = isNumber ? parseFloat(value) : value
            })
            const rate = obj.rate
            const days = obj.days
            const amount = rate * days
            const overtimeHours = obj.overtimeHours

            const overtimePayEquation = 1.25
            const OTrate = rate / 8
            const overtimePay =
                Math.round(OTrate * overtimePayEquation) * overtimeHours
            const incentives = obj.incentives
            const gross = amount + incentives + overtimePay

            let net = gross

            let totalDeduction = 0
            deductions.forEach((deduction) => {
                if (deduction.isChecked && payslip[deduction.key]) {
                    net -= payslip[deduction.key]
                    totalDeduction += payslip[deduction.key]
                }
            })
            totalPayrollAmount += net
            totalPayrollDeduction += totalDeduction
            obj['totalDeduction'] = totalDeduction
            obj['netPay'] = net
            obj['salary'] = gross
            obj['overtime'] = overtimePay
            obj['deductions'] = deductions
            return obj
        })
        const cols = columns.map((col: any) => ({
            title: col.title,
            dataIndex: col.dataIndex || col.title,
        }))
        const updatePayrollPayload: any = {
            data: fixedPayslip,
            modified: moment().unix(),
            modifiedBy: email,
            totalPayrollDeduction,
            totalPayrollAmount,
            deductions,
            columns: cols,
        }
        // eslint-disable-next-line no-console
        console.log(cols)
        const ref = doc(db, PAYROLLS_REPOSITORY, payroll.id)
        await updateDoc(ref, updatePayrollPayload)
        message.success('Successfully updated payroll')
        setNewPayslip(undefined)
        onCancel()
        setPayroll(undefined)
    }

    // Helper function to map Ant Design column data to Excel headers
    function mapAntdColumnsToExcelHeaders(columns: any[]) {
        return columns.map((column: any) => column?.title || '')
    }

    // Helper function to map array of objects to 2D array for Excel
    function mapDataToExcelRows(data: any[], columns: any[]) {
        return data.map((row) => {
            return columns.map((column) => {
                return row[column.dataIndex]
            })
        })
    }

    // Main function to convert Ant Design table data to Excel
    // eslint-disable-next-line no-unused-vars
    function exportAntdTableToExcel(
        data: any[],
        columns: any[],
        filename = 'Export.xlsx'
    ) {
        // Map Ant Design columns to Excel headers
        const wsheaders = mapAntdColumnsToExcelHeaders(columns)

        // Map data to Excel rows
        const wsdata = mapDataToExcelRows(data, columns)

        // Create worksheet
        const ws = utils.aoa_to_sheet([wsheaders, ...wsdata])

        // Create workbook
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Sheet1')

        // Write workbook to file
        writeFile(wb, filename)
    }
    // eslint-disable-next-line no-console
    // console.log(payrollSettings.overtimePayEquation)

    useEffect(() => {
        const data = payslips.map((datum: any) => {
            return {
                ...datum,
                sssFinal: deductionObj.sssDeduction ? datum.sss : 0,
                pagibigFinal: deductionObj.pagibigDeduction ? datum.pagibig : 0,
                philhealthFinal: deductionObj.philhealthDeduction
                    ? datum.ph
                    : 0,
            }
        })

        setNewPayslip(data)
    }, [deductionObj, payslips, isEdit])
    const [columns, setColumns] = useState<any>([])
    useEffect(() => {
        if (deductions) {
            const deductionObj: any = {}
            const cols = deductions.map((deduction) => {
                deductionObj[deduction.key] = deduction.isChecked
                return {
                    title: deduction.title,
                    key: deduction.key,
                    dataIndex: deduction.key,
                    formItemProps: {
                        fieldProps: {
                            type: 'number',
                        },
                    },
                    render(row: any) {
                        if (deduction.isChecked) return row
                        return 0
                    },
                }
            })
            const newCols = cols.filter(Boolean)
            const columns: any[] = [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    width: 200,
                    fixed: 'left',
                },
                {
                    key: 'regular',
                    title: 'Regular Work',
                    children: [
                        {
                            title: 'Rate',
                            dataIndex: 'rate',
                            type: 'number',
                            render(row: any, record: any) {
                                return parseFloat(record.rate)
                            },
                            formItemProps: {
                                fieldProps: {
                                    type: 'number',
                                },
                            },
                        },
                        {
                            title: 'Days',
                            dataIndex: 'days',
                            type: 'number',
                            render(row: any, record: any) {
                                return parseFloat(record.days)
                            },
                            formItemProps: {
                                fieldProps: {
                                    type: 'number',
                                },
                            },
                        },
                        {
                            title: 'Amount',
                            dataIndex: 'amount',
                            render(row: any, record: any) {
                                return (
                                    parseFloat(record.rate) *
                                    parseFloat(record.days)
                                )
                            },
                            formItemProps: {
                                fieldProps: {
                                    type: 'number',
                                },
                            },
                        },
                    ],
                },
                {
                    title: 'Overtime',
                    key: 'ot',
                    children: [
                        {
                            title: 'OT Hours',
                            dataIndex: 'overtimeHours',
                            formItemProps: {
                                fieldProps: {
                                    type: 'number',
                                },
                            },
                        },
                        {
                            title: 'OT Pay',
                            dataIndex: 'overtime',
                            formItemProps: {
                                fieldProps: {
                                    type: 'number',
                                },
                            },
                            render(row: any, record: any) {
                                const overtimePayEquation =
                                    payrollSettings?.overtimePayEquation || 0
                                const rate = parseFloat(record.rate) / 8
                                const overtimePay =
                                    Math.round(rate * overtimePayEquation) *
                                    parseFloat(record.overtimeHours)
                                return overtimePay
                            },
                        },
                    ],
                },

                {
                    title: 'Incentives',
                    dataIndex: 'incentives',
                    formItemProps: {
                        fieldProps: {
                            type: 'number',
                        },
                    },
                },
                {
                    title: 'Gross Pay',
                    dataIndex: 'salary',
                    formItemProps: {
                        fieldProps: {
                            type: 'number',
                        },
                    },
                    render(row: any, record: any) {
                        const overtimePayEquation = 1.25
                        const rate = parseFloat(record.rate) / 8
                        const overtimePay =
                            Math.round(rate * overtimePayEquation) *
                            parseFloat(record.overtimeHours)
                        const amount =
                            parseFloat(record.rate) * parseFloat(record.days)
                        return (
                            amount + parseFloat(record.incentives) + overtimePay
                        )
                    },
                },
                {
                    key: 'deductions',
                    title: 'Deductions',
                    children: [...newCols],
                },
                {
                    title: 'Net Pay',
                    dataIndex: 'netPay',
                    formItemProps: {
                        fieldProps: {
                            type: 'number',
                        },
                    },
                    render(row: any, record: any) {
                        // eslint-disable-next-line no-console
                        // console.log(record);

                        const otRate = parseFloat(record.rate) / 8
                        const totalRegularPay =
                            parseFloat(record.days) * parseFloat(record.rate)

                        const overtimePayEquation = 1.25

                        const overtimePay =
                            Math.round(otRate * overtimePayEquation) *
                            parseFloat(record.overtimeHours)

                        const gross =
                            totalRegularPay +
                            parseFloat(record.incentives) +
                            overtimePay
                        let net = gross
                        deductions.forEach((deduction) => {
                            if (deduction.isChecked && record[deduction.key]) {
                                net -= record[deduction.key]
                            }
                        })
                        // if (deductionObj['sssDeduction']) {
                        //     net -= parseFloat(record.sssDeduction)
                        // }
                        // if (deductionObj['pagibigDeduction']) {
                        //     net -= parseFloat(record.pagibigDeduction)
                        // }
                        // if (deductionObj['philhealthDeduction']) {
                        //     net -= parseFloat(record.philhealthDeduction)
                        // }
                        return net
                    },
                },
            ]
            setDeductionObj(deductionObj)
            setColumns(columns)
            setDeductionReady(true)
        }
    }, [deductions, payrollSettings])

    useEffect(() => {
        if (!payrollSettings || !payrollSettings.deductions) return
        setDeductionReady(false)
        const deductables: any = payrollSettings.deductions.filter(
            (deduction) => deduction.isChecked
        )
        setDeductions(
            deductables.map((deduct: any) => ({
                ...deduct,
                isChecked: false,
            }))
        )
    }, [payrollSettings])
    useEffect(() => {
        if (!deductionReady) return
        if (data.data) return
        // eslint-disable-next-line no-console
        // console.log(data, deductionObj, deductions)
        let startOfDay = moment.isMoment(dates[0]) ? dates[0].unix() : dates[0]
        let endOfDay = moment.isMoment(dates[1]) ? dates[1].unix() : dates[1]
        const obj: any = {}
        deductions.forEach((deduction) => {
            obj[deduction.key] = deduction.data
        })
        if (!startOfDay || !endOfDay) return
        if (typeof startOfDay === 'number') {
            startOfDay = moment.unix(startOfDay)
        }
        if (typeof endOfDay === 'number') {
            endOfDay = moment.unix(endOfDay)
        }
        const main = async () => {
            const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)

            const q = query(
                ref,
                where('timeIn', '>=', startOfDay.startOf('day').unix()),
                where('timeIn', '<=', endOfDay.endOf('day').unix()),
                where('isRemoved', '==', false),
                where('partnerCode', '==', activeTeam),
                // where('tallied', '==', true),
                where('status', '==', 'done'),
                where('paymentStatus', '==', 'pending')
            )
            const snapshot = await getDocs(q)

            let employeeObj: any = {}
            // let driverSalaries = 0
            // let assistantSalaries = 0
            // let employeeSalaries = 0
            // const data: any = []

            // eslint-disable-next-line no-console
            // console.log(snapshot.size)

            const deductions: any = {}
            const resp = snapshot.docs.map(async (dc) => {
                const attendanceData = dc.data() as Attendance
                const attendanceId = dc.id
                // eslint-disable-next-line no-console
                // console.log(
                //     attendanceData.shiftStartTime,
                //     attendanceData.timeIn
                // )
                // eslint-disable-next-line no-console
                // console.log(attendanceData.driverName, attendanceData)

                const timeIn =
                    attendanceData.shiftStartTime || attendanceData.timeIn

                const logs = attendanceData.timeLogs || []
                const lastTimeOut = logs.length > 1 ? logs[1] : 0
                const timeOut =
                    attendanceData.shiftEndTime ||
                    attendanceData.timeOut ||
                    lastTimeOut
                if (attendanceData.driverId) {
                    const ref = doc(
                        db,
                        PERSONNEL_REPOSITORY,
                        attendanceData.driverId
                    )
                    const driverId = attendanceData.driverId

                    const driverData = (await getDoc(ref)).data() as Personnel
                    if (!driverData) return

                    const driverSalary =
                        attendanceData.driverSalary || driverData.salary || 0
                    const driverRate = driverData.salary
                    const driverName = `${driverData.lastName}, ${driverData.firstName}`

                    const workTime = calculateWorkTime(
                        moment.unix(timeIn).format('HH:mm'),
                        moment.unix(timeOut).format('HH:mm'),
                        hr,
                        true
                    )
                    let assistantName = ''
                    if (attendanceData.assistantId) {
                        const assistantRef = doc(
                            db,
                            PERSONNEL_REPOSITORY,
                            attendanceData.assistantId
                        )
                        const assistantData: any = (
                            await getDoc(assistantRef)
                        ).data()
                        if (!assistantData) return
                        const assistantSalary =
                            attendanceData.assistantSalary ||
                            assistantData.salary ||
                            0

                        const assistantRate = assistantData.salary

                        assistantName = `${assistantData.lastName}, ${assistantData.firstName}`
                        const assistantId = attendanceData.assistantId

                        if (!deductions[assistantId]) {
                            deductions[assistantId] = {
                                ...obj,
                                sssDeduction:
                                    assistantData.sssDeduction ||
                                    obj.sssDeduction ||
                                    0,
                                philhealthDeduction:
                                    assistantData.philhealthDeduction ||
                                    obj.philhealthDeduction ||
                                    0,
                                pagibigDeduction:
                                    assistantData.pagibigDeduction ||
                                    obj.pagibigDeduction ||
                                    0,
                                role: 'assistant',
                            }
                        }

                        if (!employeeObj[assistantId]) {
                            employeeObj[assistantId] = []
                        }
                        employeeObj[assistantId].push({
                            attendanceId,
                            name: assistantName,
                            partnerName: driverName,
                            salary: assistantSalary,
                            rate: assistantRate,
                            id: assistantId,
                            title: attendanceData?.title || '',
                            date: attendanceData.date,
                            driver: false,
                            shareDeduction:
                                assistantData.monthlyShareCapitalDeductions ||
                                0,
                            accidentBond:
                                assistantData.bondFundAgainstAccident || 0,
                            url: assistantData.idUrl,
                            overtimeHours:
                                attendanceData?.assistantOvertimeHours || 0,
                            overtimePay:
                                attendanceData?.assistantOvertimePay || 0,
                            incentives: attendanceData.assistantIncentives || 0,
                            timeIn,
                            timeOut,
                            minutesLate: workTime.minutesLate,
                            hoursRendered: workTime.hours,
                            revenue: attendanceData.totalRevenue,
                            cashReceived: attendanceData.totalCashReceived,
                            ...obj,
                            sssDeduction:
                                assistantData.sssDeduction ||
                                obj.sssDeduction ||
                                0,
                            philhealthDeduction:
                                assistantData.philhealthDeduction ||
                                obj.philhealthDeduction ||
                                0,
                            pagibigDeduction:
                                assistantData.pagibigDeduction ||
                                obj.pagibigDeduction ||
                                0,
                            isHalfDay:
                                attendanceData.isAssistantHalfDay || false,
                        })
                    }

                    if (!deductions[driverId]) {
                        deductions[driverId] = {
                            ...obj,
                            sssDeduction:
                                driverData.sssDeduction ||
                                obj.sssDeduction ||
                                0,
                            philhealthDeduction:
                                driverData.philhealthDeduction ||
                                obj.philhealthDeduction ||
                                0,
                            pagibigDeduction:
                                driverData.pagibigDeduction ||
                                obj.pagibigDeduction ||
                                0,
                            role: 'driver',
                        }
                    }

                    if (!employeeObj[driverId]) {
                        employeeObj[driverId] = []
                    }

                    employeeObj[driverId].push({
                        attendanceId,
                        name: driverName,
                        partnerName: assistantName,
                        salary: driverSalary,
                        rate: driverRate,
                        id: driverId,
                        title: attendanceData?.title || '',
                        date: attendanceData.date,
                        driver: false,
                        isBusPersonnel: true,
                        shareDeduction:
                            driverData.monthlyShareCapitalDeductions || 0,
                        accidentBond: driverData.bondFundAgainstAccident || 0,
                        url: driverData.idUrl,
                        overtimeHours: attendanceData?.driverOvertimeHours || 0,
                        overtimePay: attendanceData?.driverOvertimePay || 0,
                        revenue: attendanceData.totalRevenue,
                        timeIn,
                        timeOut,
                        minutesLate: workTime.minutesLate,
                        hoursRendered: workTime.hours,
                        cashReceived: attendanceData.totalCashReceived,
                        incentives: attendanceData.driverIncentives || 0,
                        ...obj,
                        sssDeduction:
                            driverData.sssDeduction || obj.sssDeduction || 0,
                        philhealthDeduction:
                            driverData.philhealthDeduction ||
                            obj.philhealthDeduction ||
                            0,
                        pagibigDeduction:
                            driverData.pagibigDeduction ||
                            obj.pagibigDeduction ||
                            0,
                        isHalfDay: attendanceData.isDriverHalfDay || false,
                    })

                    return true
                }
                if (attendanceData.employeeId) {
                    const timeIn = attendanceData.timeIn
                    const timeOut = attendanceData.timeOut
                    const employeeId = attendanceData.employeeId as string

                    const isBusPersonnel = busPersonnelRoles.includes(
                        attendanceData.employeeRole
                    )
                    const workTime = calculateWorkTime(
                        moment.unix(timeIn).format('HH:mm'),
                        moment.unix(timeOut).format('HH:mm'),
                        hr,
                        false
                    )
                    // eslint-disable-next-line no-console
                    // console.log(workTime, moment.unix(timeIn).format("HH:mm"), hr.firstShiftIn, hr.officeShiftIn, isBusPersonnel, attendanceData.employeeRole);

                    if (!employeeObj[employeeId]) {
                        employeeObj[employeeId] = []
                    }
                    // eslint-disable-next-line no-console
                    // console.log(attendanceData)

                    const ref = doc(db, PERSONNEL_REPOSITORY, employeeId)
                    const employeeData = (await getDoc(ref)).data() as Personnel

                    if (!deductions[employeeId]) {
                        deductions[employeeId] = {
                            ...obj,
                            sssDeduction:
                                employeeData.sssDeduction ||
                                obj.sssDeduction ||
                                0,
                            philhealthDeduction:
                                employeeData.philhealthDeduction ||
                                obj.philhealthDeduction ||
                                0,
                            pagibigDeduction:
                                employeeData.pagibigDeduction ||
                                obj.pagibigDeduction ||
                                0,
                            role: employeeData.role,
                        }
                    }
                    const employeeName = `${employeeData.lastName}, ${employeeData.firstName}`
                    // employeeSalaries += attendanceData.salary
                    employeeObj[employeeId].push({
                        attendanceId,
                        name: employeeName,
                        salary: attendanceData.salary,
                        rate: employeeData.salary,
                        id: employeeId,
                        title: attendanceData?.title || '',
                        date: attendanceData.date,
                        isBusPersonnel,
                        driver: false,
                        shareDeduction:
                            employeeData.monthlyShareCapitalDeductions || 0,
                        accidentBond: employeeData.bondFundAgainstAccident || 0,
                        url: employeeData.idUrl,
                        overtimeHours: attendanceData?.overtimeHours || 0,
                        overtimePay: attendanceData?.overtimePay || 0,
                        incentives: attendanceData?.incentives || 0,
                        timeIn,
                        timeOut,
                        minutesLate: workTime.minutesLate,
                        hoursRendered: workTime.hours,
                        ...obj,
                        sssDeduction:
                            employeeData.sssDeduction || obj.sssDeduction || 0,
                        philhealthDeduction:
                            employeeData.philhealthDeduction ||
                            obj.philhealthDeduction ||
                            0,
                        pagibigDeduction:
                            employeeData.pagibigDeduction ||
                            obj.pagibigDeduction ||
                            0,
                        isHalfDay: attendanceData?.isEmployeeHalfDay || false,
                    })
                }
            })

            await Promise.all(resp)
            const employees = Object.keys(employeeObj).map((key) => {
                let totalHours = 0
                // let totalExtraHours = 0
                // let employeeHours: any = {}
                let total = 0
                const data = employeeObj[key]
                // const origDays = data ? data.length : 0
                // if (days === 13) {
                //     // eslint-disable-next-line no-console
                //     // console.log(data)
                // }
                let name = ''
                let url = ''
                // let isDriver = false
                let rate = 0
                let shareDeduction = 0
                // let accidentBond = 0
                let overtime = 0
                let amount = 0
                let incentives = 0
                let overtimeHours = 0
                let daysLength = 0
                const objDate: any = []
                data.forEach((datum: any) => {
                    const [hours] = calculateTime(
                        datum.shiftStartTime as number,
                        datum.shiftEndTime as number
                    )
                    const date = moment
                        .unix(datum.timeIn)
                        .format('MMMM DD, YYYY')
                    if (!objDate.includes(date)) {
                        objDate.push(date)
                        if (datum.isHalfDay) {
                            daysLength += 0.5
                        } else {
                            daysLength += 1
                        }
                    }
                    // eslint-disable-next-line no-console
                    // console.log(key, hours, extraHours);

                    total += datum.salary + datum.incentives + datum.overtimePay
                    if (!name) {
                        name = datum.name
                    }
                    rate = datum.rate
                    shareDeduction = datum.shareDeduction
                    // accidentBond = datum.accidentBond
                    url = datum.url
                    amount += datum.salary
                    overtime += datum.overtimePay
                    incentives += datum.incentives
                    overtimeHours += datum.overtimeHours
                    totalHours += hours
                    // totalExtraHours += extraHours
                    // isDriver = datum.driver
                })
                const days = daysLength
                // eslint-disable-next-line no-console
                // console.log(days, origDays, name);

                // const overtimePay = rate * 0.15555555 * overtime
                // if(isDriver){
                //     drivers.push({
                //         salary: total,
                //         days,
                //         name,
                //         rate
                //     })
                // }else {
                //     assistants.push({
                //         salary: total,
                //         days,
                //         name,
                //         rate
                //     })
                // }
                // totalSalaries += total
                return {
                    id: key,
                    salary: total,
                    days,
                    name,
                    rate,
                    peratiDays: totalHours / 8,
                    // totalExtraHours: totalExtraHours,
                    // accidentBond,
                    shareDeduction,
                    netPay: total,
                    url,
                    overtime,
                    incentives,
                    overtimeHours,
                    amount,
                    data,
                    // sssDeduction: deductions[key]?.sssDeduction || 0,
                    // pagibigDeduction: deductions[key]?.pagibigDeduction || 0,
                    // philhealthDeduction: deductions[key]?.philhealthDeduction || 0,
                    isBusPersonnel: busPersonnelRoles.includes(
                        deductions[key].role
                    ),
                    ...deductions[key],
                    sssFinal: 0,
                    philhealthFinal: 0,
                    pagibigFinal: 0,
                }
            })
            setPayslips(employees)
        }
        main()
    }, [
        dates,
        activeTeam,
        hr,
        data,
        deductionReady,
        payrollSettings,
        deductionObj,
        deductions,
    ])
    useEffect(() => {
        if (data && data.data) {
            setPayslips(data.data)
            setIsEdit(data.status === 'done' ? false : true)
            setPayroll(data)
            setDeductions(data.deductions || [])
            setDeductionReady(true)
            // setDeductions({
            //     sssDeduction: data.sss,
            //     pagibigDeduction: data.pagibig,
            //     philhealthDeduction: data.ph,
            // })
            return
        }
    }, [data])
    // eslint-disable-next-line no-console
    // console.log(payslips)

    return (
        <Modal
            open={true}
            onCancel={onCancel}
            onOk={isEdit ? handleUpdate : handleSubmit}
            okText={isEdit ? 'UPDATE' : 'SUBMIT AS DRAFT'}
            footer={
                !isEdit && payroll && payroll.status === 'done'
                    ? false
                    : newPayslip
                    ? undefined
                    : false
            }
            okButtonProps={{
                size: 'large',
                shape: 'round',
                style: isEdit
                    ? {
                          border: 'none',
                          backgroundColor: 'green',
                      }
                    : undefined,
                type: 'primary',
                color: isEdit ? 'white' : undefined,
            }}
            cancelButtonProps={{
                size: 'large',
                shape: 'round',
                danger: true,
            }}
            centered
            destroyOnClose
            width={'1300px'}
            title={
                isEdit || payroll ? (
                    <Typography.Title level={4} style={{ marginBottom: 0 }}>
                        Update Payroll: from{' '}
                        {moment.unix(payroll.start).format('MMMM DD')} to{' '}
                        {moment.unix(payroll.end).format('MMMM DD YYYY')}
                    </Typography.Title>
                ) : (
                    <Typography.Title level={4} style={{ marginBottom: 0 }}>
                        {teamName}'s Employee Payroll
                    </Typography.Title>
                )
            }
        >
            {isEdit || (payroll && payroll.status === 'done') ? null : (
                <Row gutter={[12, 12]}>
                    <Col style={{ height: '100%' }}>
                        <div style={{ height: '100%' }}>
                            <Typography.Text>Pay period: </Typography.Text>
                        </div>
                    </Col>
                    <Col style={{ height: '100%' }}>
                        <Row style={{ height: '100%' }}>
                            <Col>
                                <ProFormDateRangePicker
                                    fieldProps={{
                                        ref: dateRef,
                                        open: open,
                                        value: dates as any,
                                        style: {
                                            marginBottom: 0,
                                        },
                                        format: 'MMM DD, YYYY',
                                        onOpenChange: (val) => setOpen(val),
                                        onChange: (vals) => {
                                            // eslint-disable-next-line no-console
                                            // console.log(vals, format, ref.current)
                                            if (!vals) {
                                                setDates([])
                                                return
                                            }
                                            // setReports({
                                            //     ...reports,
                                            //     start: vals[0]?.startOf('day').unix() || 0,
                                            //     end: vals[1]?.endOf('day').unix() || 0,
                                            // })
                                            setDates(vals)
                                            // setCurrentFilter('')
                                            setOpen(false)
                                            if (dateRef.current) {
                                                // @ts-ignore
                                                dateRef.current.blur()
                                            }
                                        },
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
            <Row>
                <Typography.Text style={{ fontWeight: 'bolder' }}>
                    Enable Deduction
                </Typography.Text>
            </Row>
            <Row>
                {deductions.map((deduction) => {
                    const key = deduction.key
                    return (
                        <Col key={key}>
                            <Checkbox
                                disabled={payroll && payroll.status === 'done'}
                                checked={deduction.isChecked}
                                name={key}
                                onChange={(e) => {
                                    const deducts = [...deductions]
                                    const idx = deducts.findIndex(
                                        (deductable) => deductable.key === key
                                    )
                                    if (idx >= 0) {
                                        const obj = {
                                            ...deducts[idx],
                                            isChecked: e.target.checked,
                                        }
                                        deducts[idx] = obj
                                        setDeductions(deducts)
                                    }
                                }}
                            >
                                {deduction.title}
                            </Checkbox>
                        </Col>
                    )
                })}
                {/* <Checkbox
                    disabled={payroll && payroll.status === 'done'}
                    checked={deductions.sss}
                    name="sss"
                    onChange={(e) =>
                        setDeductions({
                            ...deductions,
                            sss: e.target.checked,
                        })
                    }
                >
                    SSS Deduction
                </Checkbox>
                <Checkbox
                    disabled={payroll && payroll.status === 'done'}
                    checked={deductions.pagibig}
                    name="pagibig"
                    onChange={(e) =>
                        setDeductions({
                            ...deductions,
                            pagibig: e.target.checked,
                        })
                    }
                >
                    Pag-IBIG Deduction
                </Checkbox>
                <Checkbox
                    disabled={!isEdit && payroll && payroll.status === 'done'}
                    checked={deductions.ph}
                    name="ph"
                    onChange={(e) =>
                        setDeductions({
                            ...deductions,
                            ph: e.target.checked,
                        })
                    }
                >
                    PhilHealth Deduction
                </Checkbox> */}
            </Row>
            <br />
            <br />
            <EditableTable
                withActions={
                    payroll && payroll.status === 'done' ? false : true
                }
                title={
                    dates[0] &&
                    dates[1] && (
                        <div>
                            <Typography.Paragraph
                                style={{ textAlign: 'center', marginBottom: 0 }}
                            >
                                SALARIES for the PERIOD from{' '}
                                <Typography.Text style={{ fontWeight: 'bold' }}>
                                    {dates[0].format('MMMM DD-')}
                                </Typography.Text>
                                <Typography.Text style={{ fontWeight: 'bold' }}>
                                    {dates[1].format('DD, YYYY')}
                                </Typography.Text>
                            </Typography.Paragraph>
                        </div>
                    )
                }
                data={payslips}
                columns={columns}
                onChange={handleChange}
            />
            <br />
            <br />
        </Modal>
    )
}

export default PayrollContainer
