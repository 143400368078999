import React, { useEffect, useState } from 'react'
// import { Loader } from "@googlemaps/js-api-loader"
// @ts-ignore
import GoogleMapReact from 'google-map-react'
import { Spin, message } from 'antd'

function GoogleMap(props: any) {
    const [currentPos, setCurrentPos] = useState({
        lat: 0,
        lng: 0,
    })
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        const successCallback = (position: any) => {
            setCurrentPos({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            })
            setLoading(false)
        }

        const errorCallback = (err: any) => {
            if (err instanceof Error) {
                message.error(err.message)
            } else {
                message.error('An unknown error occurred.')
            }
            setLoading(false)
        }

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
    }, [])

    // const handleApiLoaded = (map: any, maps: any) => {
    //     // eslint-disable-next-line no-console
    //     console.log(map, maps);

    //     // use map and maps objects
    // };

    // const hoverHandle= (prop: any, childProps: any) => {

    // // eslint-disable-next-line no-console
    // console.log(props.children, prop, childProps);
    // }
    return (
        <div id="map" style={{ height: 300, width: '100%' }}>
            {loading ? (
                <div>
                    <Spin />
                </div>
            ) : (
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key:
                            import.meta.env.VITE_APP_GOOGLE_MAP_API_KEY ||
                            'AIzaSyD5IU3fMJOaN3ySmrEZF4lHDH0yqh7zUPc',
                    }}
                    center={currentPos}
                    zoom={14}
                    // yesIWantToUseGoogleMapApiInternals
                    // onGoogleApiLoaded={({ map, maps }: any) => handleApiLoaded(map, maps)}
                    hoverDistance={40}
                    // onChildMouseEnter={hoverHandle}
                >
                    {props.children || null}
                </GoogleMapReact>
            )}
        </div>
    )
}

export default GoogleMap
