import { createSlice } from '@reduxjs/toolkit'
import { Personnel, Trip, Vehicle } from '../../../domain/entities/Transport'

interface InitState {
    tripSchedules: any[]
    activeTrip?: Trip & {
        id: string
    }
    drivers: Personnel[]
    vehicles: Vehicle[]
}
const initialState: InitState = {
    tripSchedules: [],
    drivers: [],
    vehicles: [],
}

export const transportSlice = createSlice({
    name: 'transport',
    initialState,
    reducers: {
        updateTransportState: (
            state,
            action: {
                payload: Partial<InitState>
            }
        ) => {
            return {
                ...state,
                ...action.payload,
            }
        },
    },
})

export const { updateTransportState } = transportSlice.actions
