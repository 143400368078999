import React, { useEffect, useState } from 'react'
import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSwitch,
    ProFormText,
} from '@ant-design/pro-components'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { shallowEqual } from 'react-redux'
import moment from 'moment'
import { Col, Divider, Form, message, Modal, Row, Skeleton } from 'antd'
import { Loan } from '../../../../../domain/entities/Loan'
import {
    getLoan,
    initialLoansState,
    updateLoan,
} from '../../../../redux/loans/loans.slice'
// import { randomId } from '../../../utils'

// interface UpdateLoanProp extends Loan {
//     memberName: string
//     frequency: string
// }
function UpdateLoan({
    id,
    onClose,
    onSuccess,
}: {
    id: string
    onClose: () => void
    onSuccess: () => void
}) {
    const [form] = Form.useForm()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(true)
    // const [, setMonthlyPayment] = useState(0)

    const { currentUser, uniformLoanInterest, styles } = useAppSelector(
        (state) => ({
            currentUser: state.auth.user,
            uniformLoanInterest: state.systemConfig.uniformLoanInterest,
            styles: state.styles,
        }),
        shallowEqual
    )

    const [loanData, setLoanData] = useState<Loan>({
        ...initialLoansState.memberLoanData,
    })

    const handleSubmit = async (datum: any) => {
        setSubmitLoading(true)

        await dispatch(
            updateLoan({
                loan: datum,
                prevLoan: loanData,
            })
        )
        // if (resp) {
        message.success(`Successfully updated loan.`)
        setSubmitLoading(false)
        // handleonSuccessEditPaymentSuccess()
        onSuccess()
        handleCancel()
        // } else {
        //     message.error(
        //         'There was an error occurred during update. Please contact system administrator'
        //     )
        // }
    }

    const handleCancel = () => {
        onClose()
        form.resetFields()
    }

    // const handleonSuccessEditPaymentSuccess = () => {
    //     dispatch(getAllMemberLoans(currentUser.activeTeam))
    // }

    useEffect(() => {
        if (!id) return
        setLoading(true)
        const getLoanData = async () => {
            const loan = await getLoan(id)
            if (loan) {
                setLoanData(loan)
            } else {
                message.error(
                    'Loan not found. Please try to refresh or close the window and try again.'
                )
                onClose()
            }
            setLoading(false)
        }
        getLoanData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    // useEffect(() => {
    //     const { termYears, termMonths, amount, interest, payback, compound } =
    //         data
    //     if (!((termYears || termMonths) && amount && interest)) return
    //
    //     const numberOfPaymentsEveryYear = 12
    //     // const arrayOfArrays = []
    //     const interestRate = interest / 100
    //     const monthlyInterestRate = interestRate / numberOfPaymentsEveryYear
    //     // const monthsToPay = termYears * numberOfPaymentsEveryYear + termMonths
    //
    //     // const n = monthsToPay
    //     // const i = monthlyInterestRate
    //     // const u = i * (1 + i) ** n
    //     // const d = (1 + i) ** n - 1
    //     const monthlyPayment = calculateMonthlyPayment(amount, interest, {
    //         termYears,
    //         termMonths,
    //         payback,
    //         compound,
    //     })
    //     let loanAmount = amount
    //     // let totalInterestPayment = 0
    //     for (let y = 1; y <= termYears; y++) {
    //         // const yearData = []
    //         for (let m = 1; m <= 12; m++) {
    //             const beginningLoanBalance = loanAmount
    //             let interestPayment = monthlyInterestRate * loanAmount
    //             let principalPayment = monthlyPayment - interestPayment
    //
    //             loanAmount = loanAmount - principalPayment
    //             if (loanAmount < 0) {
    //                 loanAmount = 0
    //                 principalPayment = beginningLoanBalance
    //             }
    //             // totalInterestPayment += interestPayment
    //
    //             // const monthlyData: any = {
    //             //     beginningBalance: beginningLoanBalance,
    //             //     interest: interestPayment,
    //             //     principal: principalPayment,
    //             //     endingBalance: loanAmount,
    //             //     year: y,
    //             //     month: m,
    //             //     id: randomId(5),
    //             // }
    //             // yearData.push(monthlyData)
    //         }
    //         // }
    //         // arrayOfArrays.push(yearData)
    //     }
    //     // const yearDataExtra = []
    //     if (termMonths > 0) {
    //         for (let m = 1; m <= termMonths; m++) {
    //             const beginningLoanBalance = loanAmount
    //             let interestPayment = monthlyInterestRate * loanAmount
    //             let principalPayment = monthlyPayment - interestPayment
    //
    //             loanAmount = loanAmount - principalPayment
    //
    //             if (loanAmount < 0) {
    //                 loanAmount = 0
    //                 principalPayment = beginningLoanBalance
    //             }
    //             // totalInterestPayment += interestPayment
    //
    //             // const monthlyData: any = {
    //             //     beginningBalance: beginningLoanBalance,
    //             //     interest: interestPayment,
    //             //     principal: principalPayment,
    //             //     endingBalance: loanAmount,
    //             //     year: termYears + 1,
    //             //     month: m,
    //             //     id: randomId(12),
    //             // }
    //             // yearDataExtra.push(monthlyData)
    //         }
    //         // arrayOfArrays.push(yearDataExtra)
    //     }
    //     // setTableData(arrayOfArrays)
    //     // setTotalInterest(parseInt(totalInterestPayment.toFixed(2), 10))
    //     // setTotalPayment(parseInt((monthlyPayment * monthsToPay).toFixed()))
    //     setMonthlyPayment(monthlyPayment)
    //     // setNumberOfPayments(monthsToPay)
    // }, [])
    return loading ? (
        <Modal open centered footer={false} closable={false} confirmLoading>
            <Skeleton />
            <Skeleton />
        </Modal>
    ) : (
        <ModalForm<Loan>
            width={540}
            form={form}
            onFinishFailed={(err) => {
                if (err.errorFields.length) {
                    const firstError = err.errorFields[0]
                    message.destroy()
                    message.error(firstError.errors)
                    form.scrollToField(firstError.name, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }}
            title={'Update Loan'}
            open
            autoFocusFirstInput
            modalProps={{
                onCancel: handleCancel,
                okText: 'Update',
                centered: true,
                okButtonProps: {
                    loading: submitLoading,
                },
            }}
            submitTimeout={2000}
            onFinish={handleSubmit}
            initialValues={{
                amount: loanData.amount,
                interest: loanData.interest,
                startDate: moment.unix(loanData.creation),
                title: loanData.title,
                loanPenalty: loanData.loanPenalty,
                due: moment.unix(loanData.due),
                approvalDate: moment.unix(loanData.approvalDate),
                disbursementDate: moment.unix(loanData.disbursementDate),
            }}
            submitter={{
                submitButtonProps: {
                    style: {
                        backgroundColor: styles.primary,
                        color: 'white',
                    },
                },
            }}
        >
            <ProFormText
                rules={[
                    {
                        required: true,
                        type: 'string',
                        message: 'This field is required',
                    },
                ]}
                name="title"
                label="Loan title"
                style={{ width: '100%' }}
            />
            <Row wrap={false} gutter={[12, 0]}>
                <Col>
                    <ProFormDigit
                        rules={[
                            {
                                required: true,
                                type: 'number',
                                message: 'This field is required',
                            },
                        ]}
                        name="amount"
                        label="Loan Amount"
                        placeholder="360.50"
                        disabled={loanData.payments.length > 1}
                    />
                </Col>
                <Col>
                    <ProFormDigit
                        name={'interest'}
                        label={'Interest %'}
                        max={100}
                        min={1}
                        disabled={
                            uniformLoanInterest || loanData.payments.length > 1
                        }
                        rules={[
                            {
                                required: true,
                                type: 'number',
                                message: 'This field is required',
                            },
                        ]}
                    />
                </Col>
            </Row>
            {/*<ProFormDatePicker*/}
            {/*    name={'creation'}*/}
            {/*    initialValue={Date.now()}*/}
            {/*    label={'Date added'}*/}
            {/*    width={'sm'}*/}
            {/*    // @ts-ignore*/}
            {/*    format={'MMM D, YYYY'}*/}
            {/*/>*/}

            <ProForm.Group>
                <ProFormDatePicker
                    name={'startDate'}
                    label={'Application Date'}
                    fieldProps={{
                        format: 'MMMM DD, YYYY',
                    }}
                    width={'sm'}
                />
                <ProForm.Group>
                    <ProFormSwitch
                        formItemProps={{
                            labelAlign: 'left',
                        }}
                        name={'loanPenalty'}
                        label={'Monthly Due'}
                        checkedChildren={'Enabled'}
                        unCheckedChildren={'Disabled'}
                    />
                </ProForm.Group>
            </ProForm.Group>
            <Divider style={{ margin: '8px 0' }} />
            <Row wrap={false} gutter={[12, 0]}>
                <Col span={12}>
                    {currentUser.type !== 'staff' && (
                        <ProFormDatePicker
                            name={'approvalDate'}
                            label={'Approval Date'}
                            fieldProps={{
                                format: 'MMMM DD, YYYY',
                            }}
                        />
                    )}
                </Col>
                <Col span={12}>
                    {currentUser.type !== 'staff' && (
                        <ProFormDatePicker
                            name={'disbursementDate'}
                            label={'Disbursement Date'}
                            fieldProps={{
                                format: 'MMMM DD, YYYY',
                            }}
                        />
                    )}
                </Col>
            </Row>
            <Row wrap={false} gutter={[12, 0]}>
                <Col span={12}>
                    <ProFormDatePicker
                        name={'due'}
                        label={'Due Date'}
                        placeholder={'Select Date'}
                        fieldProps={{
                            format: 'MMMM DD, YYYY',
                        }}
                    />
                </Col>
            </Row>
            {/*{!loanData.loanPenalty && (*/}
            {/*)}*/}
            {/*<ProForm.Group*/}
            {/*    label={*/}
            {/*        <Typography.Text>*/}
            {/*            <Typography.Text style={{ color: 'red' }}>*/}
            {/*                **/}
            {/*            </Typography.Text>{' '}*/}
            {/*            Loan Term*/}
            {/*        </Typography.Text>*/}
            {/*    }*/}
            {/*>*/}
            {/*{currentUser.type === 'superadmin' && (*/}
            {/*    <ProFormDatePicker*/}
            {/*        name={'startDate'}*/}
            {/*        label={'Custom Due'}*/}
            {/*        help={'For demo only'}*/}
            {/*        width={216}*/}
            {/*    />*/}
            {/*)}*/}
            {/*<Divider />*/}
            {/*<LoanPaymentTable*/}
            {/*    loanData={loanData}*/}
            {/*    data={loanData.payments}*/}
            {/*    handleCancel={handleCancel}*/}
            {/*    onSuccess={handleonSuccessEditPaymentSuccess}*/}
            {/*/>*/}
        </ModalForm>
    )
}

export default UpdateLoan
