export const SHARES_REPOSITORY = 'shares'
export const SAVINGS_REPOSITORY = 'savings'
export const EXPENSE_REPOSITORY = 'expense'
export const CONTACT_SUCCESS_REPOSITORY = 'contactus'
export const VEHICLE_REPOSITORY = 'vehicles'
export const PAYROLLS_REPOSITORY = 'payrolls'
export const DRIVERS_REPOSITORY = 'drivers'
export const PERSONNEL_REPOSITORY = 'personnel'
export const TEAM_GROUP_REPOSITORY = 'teamGroups'
export const PAYSLIPS_REPOSITORY = 'payslips'
export const PERSONNEL_ATTENDANCE_REPOSITORY = 'attendance'
export const TRIPS_REPOSITORY = 'trips'
export const LOANS_REPOSITORY = 'loans'
export const TEAM_REPOSITORY = 'team'
export const FILES_REPOSITORY = 'files'
export const FOLDERS_REPOSITORY = 'folders'
export const USERS_REPOSITORY = 'users'
export const PAYMENTS_REPOSITORY = 'payments'
export const BORROWERS_REPOSITORY = 'borrowers'
export const MEMBERS_REPOSITORY = 'members'
export const CHAT_REPOSITORY = 'chats'
export const MESSAGE_REPOSITORY = 'messages'
export const STORE_PRODUCT_REPOSITORY = 'storeProducts'
export const STORE_ORDERS_REPOSITORY = 'storeOrders'

export const paymentMethods = [
    {
        label: 'Cash',
        description: 'Payment made in physical currency',
        value: 'cash',
    },
    {
        label: 'Bank Transfer',
        description: 'Payment made through electronic funds transfer',
        value: 'bank_transfer',
    },
    {
        label: 'Check',
        description: 'Payment made using a physical or digital check',
        value: 'check',
    },
    {
        label: 'Credit Card',
        description: 'Payment made using a credit card',
        value: 'credit_card',
    },
    // Add more payment methods as needed
]

export const managementRoles = [
    {
        label: 'Driver',
        value: 'Driver',
    },
    {
        label: 'Assistant',
        value: 'Assistant',
    },
    {
        label: 'Helper',
        value: 'Helper',
    },
    {
        label: 'Dispatcher',
        value: 'Dispatcher',
    },
    {
        label: 'Management Staff',
        value: 'Management-staff',
    },
]

export const employeeStatus = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'onLeave', label: 'On Leave' },
    { value: 'resigned', label: 'Resigned' },
    { value: 'terminated', label: 'Terminated' },
]

export const employmentStatusOptions = [
    { value: 'full-time', label: 'Full-Time' },
    { value: 'part-time', label: 'Part-Time' },
    // { value: 'temporary', label: 'Temporary' },
    { value: 'contractor', label: 'Contractor' },
    { value: 'intern', label: 'Intern' },
    { value: 'probationary', label: 'Probationary' },
    { value: 'retired', label: 'Retired' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'onLeave', label: 'On Leave' },
    { value: 'resigned', label: 'Resigned' },
    { value: 'terminated', label: 'Terminated' },
    { value: 'permanent', label: 'Permanent' },
    { value: 'freelancer', label: 'Freelancer' },
]

export type EmployementStatus =
    | 'full-time'
    | 'part-time'
    | 'contractor'
    | 'intern'
    | 'probationary'
    | 'retired'
    | 'active'
    | 'inactive'
    | 'onLeave'
    | 'resigned'
    | 'terminated'
    | 'permanent'
    | 'freelancer'
export const transportOptions = [
    { value: 'jeepney', label: 'Jeepney' },
    { value: 'taxi', label: 'Taxi' },
    { value: 'tricycle', label: 'Tricycle' },
    { value: 'bus', label: 'Bus' },
    { value: 'bus_aircon', label: 'Bus | Air-Conditioned ' },
    { value: 'van', label: 'Van and Shuttle Service' },
    { value: 'motorela', label: 'Motorela' },
    { value: 'private_vehicles', label: 'Private Vehicles' },
]
export const averageUsefulLife = [
    { value: 'jeepneys', label: '15-20 years' },
    { value: 'taxis', label: '5-7 years' },
    { value: 'tricycles', label: '5-8 years' },
    { value: 'buses', label: '10-15 years' },
    { value: 'vans', label: '10-15 years' },
    { value: 'motorelas', label: '5-7 years' },
    { value: 'private_vehicles', label: '10-15 years' },
    { value: 'ferries', label: '20-30 years' },
]

export const positions = [
    {
        value: 'accounting_manager',
        label: 'Accounting',
    },
    {
        value: 'administrative_staff',
        label: 'Administrative Staff',
    },
    {
        value: 'assistant',
        label: 'Assistant',
    },
    {
        value: 'book_keeper',
        label: 'Book Keeper',
    },
    {
        value: 'cashier',
        label: 'Cashier',
    },
    {
        value: 'chairman',
        label: 'Chairman',
    },
    {
        value: 'data_analyst',
        label: 'Data Analyst',
    },
    {
        value: 'dispatcher',
        label: 'Dispatcher',
    },
    {
        value: 'driver',
        label: 'Driver',
    },
    {
        value: 'finance_officer',
        label: 'Finance Officer',
    },
    {
        value: 'fleet_manager',
        label: 'Fleet Manager',
    },
    {
        value: 'gasman',
        label: 'Gasman',
    },
    {
        value: 'general_manager',
        label: 'General Manager',
    },
    {
        value: 'hr_manager',
        label: 'HR Manager',
    },
    {
        value: 'inspector',
        label: 'Inspector',
    },
    {
        value: 'it_support',
        label: 'IT Support',
    },
    {
        value: 'liason',
        label: 'Liason',
    },
    {
        value: 'maintenance_staff',
        label: 'Maintenance Staff',
    },
    {
        value: 'mechanic',
        label: 'Mechanic',
    },
    {
        value: 'regular_member',
        label: 'Regular Member',
    },
    {
        value: 'safety_officer',
        label: 'Safety Officer',
    },
    {
        value: 'secretary',
        label: 'Secretary',
    },
    {
        value: 'support_staff',
        label: 'Support Staff',
    },
    {
        value: 'taxi_operator',
        label: 'Taxi Operator',
    },
    {
        value: 'team_leader',
        label: 'Team Leader',
    },
    {
        value: 'ticketing_staff',
        label: 'Ticketing Staff',
    },
    {
        value: 'treasurer',
        label: 'Treasurer',
    },
    
    {
        value: 'payroll_specialist',
        label: 'Payroll Specialist',
    },
    {
        value: 'public_assistant_officer',
        label: 'Public Assistant Officer',
    },
    {
        value: 'puj_operator',
        label: 'PUJ Operator',
    },
    {
        value: 'washer',
        label: 'Washer',
    },
    {
        value: 'watchman',
        label: 'Watchman',
    },
    // {
    //     value: 'operations_manager',
    //     label: 'Operations Manager',
    // },
    // {
    //     value: 'customer_service_representative',
    //     label: 'Customer Service Representative',
    // },
    // {
    //     value: 'marketing_sales',
    //     label: 'Marketing and Sales',
    // },
]

export const civilStatuses = [
    {
        label: 'Single',
        value: 'single',
    },
    {
        label: 'Married',
        value: 'maried',
    },
    {
        label: 'Widowed',
        value: 'widowed',
    },
    {
        label: 'Divorced',
        value: 'divorced',
    },
    {
        label: 'Separated',
        value: 'separated',
    },
]

export const busPersonnelRoles = [
    'driver',
    'assistant',
    'gasman',
    'dispatcher',
    'washer',
]
