import { updateTeamState } from '../../../app/redux/team/team.slice'
import { useAppDispatch, useAppSelector } from '../../../app/store'
import {
    PlusCircleFilled,
    UpOutlined,
    DownOutlined,
    CheckOutlined,
    LineOutlined,
} from '@ant-design/icons'
import {
    Row,
    Col,
    Dropdown,
    Typography,
    Menu,
    Avatar,
    Divider,
    Grid,
} from 'antd'
import { shallowEqual } from 'react-redux'
import logo from '../../../assets/mainicon1280x654.png'
import { updateUser } from '../../../app/redux/auth/auth.slice'
import { Team } from '../../../domain/entities/Team'
import { useEffect } from 'react'
import { TitleProps } from 'antd/lib/typography/Title'
import {
    getMyTeam,
    getMyTeams,
    // getTeamMemberPermission,
} from '../../redux/team/team.action'
import { getRouteName } from '../../pages/Reports/MembersReceivableReport/helper'

interface TeamHeaderProps {
    sizeLevel: TitleProps['level']
    withAvatar?: boolean
    withSwitch?: boolean
}

const sizeMapping: {
    [key: number]: {
        avatarSize: number
        fontSize: number
    }
} = {
    '1': {
        avatarSize: 40,
        fontSize: 32,
    },
    '2': {
        avatarSize: 40,
        fontSize: 28,
    },
    '3': {
        avatarSize: 35,
        fontSize: 24,
    },
    '4': {
        avatarSize: 30,
        fontSize: 20,
    },
    '5': {
        avatarSize: 25,
        fontSize: 16,
    },
}
export const TeamHeader = ({
    sizeLevel,
    withAvatar = true,
}: // withSwitch = false,
TeamHeaderProps) => {
    const dispatch = useAppDispatch()
    const {
        currentTeam,
        activeTeam,
        name,
        styles,
        userId,
        myTeams,
        // loggedIn,
        // id,
        userMyTeams,
        partnerCode,
    } = useAppSelector(
        (state) => ({
            currentTeam: state.team.selectedTeamData,
            name: state.team.selectedTeamData.name,
            loggedIn: state.auth.isLoggedIn,
            id: state.auth.user.activeTeam,
            partnerCode: state.auth.user.partnerCode,
            myTeams: state.team.myTeams,
            userMyTeams: state.auth.user.myTeams,
            styles: state.styles,
            userId: state.auth.user.id,
            activeTeam: state.auth.user.activeTeam,
        }),
        shallowEqual
    )

    // const navigate = useNavigate()
    // const handleClickTeamHome = () => {
    //     if (!loggedIn) return
    //     if (!xs) return
    //     // navigate(`/teams/${id}`)
    // }
    const { xs } = Grid.useBreakpoint()

    const toggleCreateTeamModal = () => {
        dispatch(
            updateTeamState({
                showCreateModal: true,
            })
        )
    }

    const handleSelectTeam = async (partnerCode: Team['partnerCode']) => {
        let routeName = getRouteName()

        dispatch(
            updateUser({
                id: userId as string,
                activeTeam: partnerCode,
            })
        )
        if (routeName === 'My Team') routeName = ''
        // navigate(`/teams/${partnerCode}/${routeName}`, {
        //     replace: true,
        // })
    }

    useEffect(() => {
        if (!activeTeam || !userId) return

        // const resp = getTeamMemberPermission(
        //     {
        //         partnerCode: activeTeam,
        //         memberId: userId as string,
        //     },
        //     async (data) => {
        //         if (data.isRemoved) {
        //             const teamIdx = userMyTeams.findIndex(
        //                 (str) => str === activeTeam
        //             )
        //             const newTeams = [...userMyTeams]
        //             newTeams.splice(teamIdx, 1)

        //             // dispatch(
        //             //     updateUser({
        //             //         myTeams: newTeams,
        //             //         id: userId,
        //             //     })
        //             // )
        //             await handleSelectTeam(newTeams[newTeams.length - 1])
        //             return
        //         }
        //         // dispatch(
        //         //     updateTeamState({
        //         //         currentTeamPermission: data,
        //         //     })
        //         // )
        //     }
        // )
        dispatch(getMyTeams(userMyTeams))

        const resp1 = getMyTeam(activeTeam, (data) => {
            dispatch(
                updateTeamState({
                    selectedTeamData: data,
                })
            )
        })
        return () => {
            // resp()
            resp1()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam, userId, userMyTeams])
    return (
        <Row align="middle" wrap={false} style={{ padding: '0 8px' }}>
            <Col span={24}>
                <Row
                    className="onboard-step-1"
                    align="middle"
                    gutter={[6, 0]}
                    style={{ userSelect: 'none' }}
                >
                    {currentTeam.logoUrl ? (
                        <Col>
                            <Avatar src={currentTeam.logoUrl} size={35} />
                        </Col>
                    ) : (
                        <Col>
                            <Avatar
                                size={
                                    sizeLevel
                                        ? sizeMapping[sizeLevel].avatarSize
                                        : 30
                                }
                                style={{
                                    background: `linear-gradient(rgba(${currentTeam.avatar}, 1), rgba(${currentTeam.avatar}, 0.5))`,
                                }}
                            />
                        </Col>
                    )}
                    <Col>
                        {/* <div style={{ marginTop: 4 }}> */}
                        {xs ? (
                            <Typography.Text
                                style={{
                                    marginLeft: 6,
                                }}
                            >
                                {name}
                            </Typography.Text>
                        ) : (
                            <Typography.Title
                                level={sizeLevel || 4}
                                style={{
                                    marginBottom: 0,
                                    marginLeft: !withAvatar ? 6 : 0,
                                }}
                            >
                                {name}
                            </Typography.Title>
                        )}
                        {/* </div> */}
                    </Col>
                    {partnerCode === 'perati' ? (
                        <Col>
                            <Dropdown
                                trigger={['click']}
                                placement="bottom"
                                overlay={
                                    <div
                                        style={{
                                            padding: '16px 16px 10px',
                                            backgroundColor: 'white',
                                            border: '1px solid #eaeaea',
                                            borderRadius: 5,
                                            width: xs ? '100vw' : undefined,
                                        }}
                                    >
                                        <Typography.Paragraph
                                            type={'secondary'}
                                            style={{ marginBottom: 0 }}
                                        >
                                            Teams
                                        </Typography.Paragraph>
                                        <Menu
                                            style={{
                                                // width: 280,
                                                boxShadow: 'none',
                                            }}
                                            activeKey={currentTeam.partnerCode}
                                        >
                                            {myTeams.map((team) => {
                                                return (
                                                    <Menu.Item
                                                        key={team.partnerCode}
                                                        onClick={() =>
                                                            handleSelectTeam(
                                                                team.partnerCode
                                                            )
                                                        }
                                                    >
                                                        <Row
                                                            style={{
                                                                borderRadius: 5,
                                                            }}
                                                            align="middle"
                                                        >
                                                            <Col>
                                                                <Avatar
                                                                    size={26}
                                                                    style={{
                                                                        background: `linear-gradient(rgba(${team.avatar}, 1), rgba(${team.avatar}, 0.5))`,
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <span
                                                                    style={{
                                                                        marginLeft: 8,
                                                                        color: '#333',
                                                                        fontSize: 16,
                                                                    }}
                                                                >
                                                                    {team.name}
                                                                </span>
                                                            </Col>
                                                            {team.partnerCode ===
                                                            currentTeam.partnerCode ? (
                                                                <Col flex={1}>
                                                                    <Row justify="end">
                                                                        <Col>
                                                                            <CheckOutlined />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            ) : null}
                                                        </Row>
                                                    </Menu.Item>
                                                )
                                            })}
                                            <Divider
                                                style={{
                                                    margin: '14px 0 10px',
                                                }}
                                            />
                                            <Menu.Item
                                                key="create-team"
                                                onClick={toggleCreateTeamModal}
                                            >
                                                <Row
                                                    style={{
                                                        borderRadius: 5,
                                                    }}
                                                >
                                                    <Col>
                                                        <PlusCircleFilled
                                                            style={{
                                                                fontSize: 24,
                                                                color: styles.primary,
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <span
                                                            style={{
                                                                marginLeft: 8,
                                                                color: '#333',
                                                                fontSize: 16,
                                                            }}
                                                        >
                                                            Create a team
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Menu.Item>
                                        </Menu>
                                    </div>
                                }
                            >
                                <div className="switch-teams">
                                    <Row align={'top'}>
                                        <Col span={24}>
                                            <Row>
                                                <UpOutlined
                                                    style={{
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                    }}
                                                />
                                            </Row>
                                            <Row>
                                                <DownOutlined
                                                    style={{
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                    }}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Dropdown>
                        </Col>
                    ) : null}
                </Row>
            </Col>
        </Row>
    )
}

const ProtectedHeaderTitle = () => {
    // const navigate = useNavigate()
    // const { user } = useAppSelector(
    //     (state) => ({
    //         user: state.auth.user,
    //     }),
    //     shallowEqual
    // )
    const { xs } = Grid.useBreakpoint()
    return (
        <Row justify={xs ? 'start' : 'center'} align="middle" gutter={[6, 0]}>
            <Col>
                <img
                    // onClick={() => navigate(`settings/${user.id}`)}
                    src={logo}
                    style={{
                        height: 30,
                    }}
                    alt=""
                />
            </Col>
            <Col>
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <LineOutlined
                        rotate={120}
                        style={{ fontSize: 30, color: '#e5e5e5' }}
                    />
                </div>
            </Col>
            <Col>
                <TeamHeader sizeLevel={5} />
            </Col>
            {/* )} */}
        </Row>
    )
}

export default ProtectedHeaderTitle
