import React, { useEffect, useState } from 'react'
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormText,
    // ProFormTextArea,
} from '@ant-design/pro-components'
import {
    Col,
    Divider,
    Form,
    Input,
    message,
    Row,
    Space,
    Typography,
} from 'antd'
import moment from 'moment'
import { useAppSelector } from '../../../store'
// import { updateSystemConfig } from '../../../redux/system.slice'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Expense } from '../../../../domain/entities/SystemSettings'
import { shallowEqual } from 'react-redux'
import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    updateDoc,
} from 'firebase/firestore'
import {
    EXPENSE_REPOSITORY,
    TEAM_REPOSITORY,
} from '../../../../data/repositories/constants'
import { db } from '../../../boot'
import { cleanString } from '../../../../app/components/script'

interface NewCapExProps {
    onClose: () => void
    title?: any
}
function NewCapEx({ onClose, title }: NewCapExProps) {
    const { categories, email, styles, selectedTeamData } = useAppSelector(
        (state) => ({
            showNewExpense: state.systemConfig.showNewExpense,
            // categories: state.systemConfig.expenseCategories,
            email: state.auth.user.email,
            styles: state.styles,
            selectedTeamData: state.team.selectedTeamData,
            categories: state.team.selectedTeamData?.capExCategories || [],
        }),
        shallowEqual
    )
    const [expenseCategories, setExpenseCategories] = useState(categories)

    const [expensesLabelKey, setExpensesLabelKey] = useState<any>([])
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState('')
    // const dispatch = useAppDispatch()

    const [form] = Form.useForm()
    // const handleFieldChange = (field: any) => {
    //     const key = Object.keys(field)[0]
    //     if (key === 'loanSelection') {
    //         setSelectedLoan(field[key])
    //     }
    // }
    const handleCancel = () => {
        onClose()
    }

    const handleSubmit = async (data: any) => {
        setLoading(true)
        const {
            amount,
            title,
            purpose,
            requestDate,
            chequeNumber,
            category,
            referenceNumber,
            quantity,
        } = data

        // let obj = selectedTeamData.product || {}
        // let remaining = 0
        // if(obj && obj[category]){
        //     remaining = obj[category]
        // }
        const payload: Expense = {
            amount,
            title,
            purpose,
            requestDate: moment(requestDate).unix(),
            releaseDate: 0,
            approvedDate: 0,
            category,
            creation: moment().unix(),
            modified: moment().unix(),
            modifiedBy: email,
            isRemoved: false,
            partnerCode: selectedTeamData.partnerCode,
            status: 'draft',
            referenceNumber,
            type: 'payable',
            chequeNumber: chequeNumber || '',
            isCapEx: true,
            quantity,
            productHistory: [],
            remainingQuantity: quantity,
        }
        await addDoc(collection(db, EXPENSE_REPOSITORY), payload)
        // await dispatch(addExpense(payload))
        setLoading(false)
        form.resetFields()
        message.success('Accounts receivable successfully added')
        handleCancel()
        setSelectedCategory('')
    }

    const handleAddExpenseCategory = (data: any) => {
        setName(data.target.value)
    }

    const handleAddItem = async (e: {
        preventDefault: () => void
        stopPropagation: () => void
    }) => {
        e.preventDefault()
        e.stopPropagation()
        if (!name) return
        const cleanName = cleanString(name)
        if (expenseCategories.includes(cleanName)) {
            message.error(`${name} already exists in expense categories.`)
            return
        }

        await updateDoc(
            doc(db, TEAM_REPOSITORY, selectedTeamData.partnerCode),
            {
                capExCategories: arrayUnion(cleanName.toLowerCase()),
            }
        )
        const expenseCategoriesCopy = [
            ...expenseCategories,
            cleanName.toLowerCase(),
        ]
        // dispatch(
        //     updateSystemConfig({
        //         capExCategories: expenseCategoriesCopy,
        //     })
        // )
        message.success(`Successfully added ${cleanName}.`)

        setExpenseCategories(expenseCategoriesCopy)
        setName('')
    }

    const handleDeleteCategory = async (e: any, key: string) => {
        e.preventDefault()
        e.stopPropagation()
        const newExpenseCategories = [...expenseCategories]
        const keyIdx = newExpenseCategories.indexOf(key)
        if (keyIdx >= 0) {
            newExpenseCategories.splice(keyIdx, 1)

            await updateDoc(
                doc(db, TEAM_REPOSITORY, selectedTeamData.partnerCode),
                {
                    capExCategories: arrayRemove(key),
                }
            )
            // dispatch(
            //     updateSystemConfig({
            //         capExCategories: newExpenseCategories,
            //     })
            // )
            message.success('Successfully delete category.')
            setExpenseCategories(newExpenseCategories)
        }
    }

    useEffect(() => {
        const mapped = expenseCategories.map((expenseCategory: string) => ({
            label: expenseCategory,
            key: expenseCategory,
        }))
        setExpensesLabelKey(
            mapped.sort((a, b) => a.label.localeCompare(b.label))
        )
    }, [expenseCategories])

    useEffect(() => {
        setExpenseCategories(categories)
    }, [categories])

    // const [categoryOpen, setCategoryOpen] = useState(false)
    return (
        <div>
            <ModalForm<Expense>
                form={form}
                title={title || undefined}
                open
                onFinishFailed={(err) => {
                    if (err.errorFields.length) {
                        const firstError = err.errorFields[0]
                        message.destroy()
                        message.error(firstError.errors)
                        form.scrollToField(firstError.name, {
                            behavior: 'smooth',
                            block: 'center',
                        })
                    }
                }}
                autoFocusFirstInput
                modalProps={{
                    onCancel: onClose,
                    okText: 'Submit',
                    centered: true,
                    onOk: () => {
                        form.submit()
                    },
                }}
                onFinish={handleSubmit}
                initialValues={{
                    creation: moment(),
                    purpose: '',
                }}
                width={500}
                submitter={{
                    submitButtonProps: {
                        style: {
                            backgroundColor: styles.primary,
                            color: 'white',
                        },
                        loading: loading,
                        onClick: () => {
                            form.submit()
                        },
                    },
                }}
                // onValuesChange={handleFieldChange}
            >
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <ProFormText
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            name="title"
                            label="Name"
                            placeholder="Please Enter"
                        />
                    </Col>
                    <Col span={12}>
                        {/* <ProFormDigit
                            rules={[
                                {
                                    required: true,
                                    type: 'number',
                                    message: 'This field is required',
                                },
                            ]}
                            name="amount"
                            label="Cost"
                            placeholder="Please Enter"
                        /> */}
                        <ProFormText
                            rules={[
                                {
                                    required: true,
                                    type: 'number',
                                    message: 'This field is required',
                                },
                            ]}
                            name="amount"
                            label="Code"
                            placeholder="Please Enter"
                        />
                    </Col>
                </Row>

                <Row wrap={false} gutter={[12, 12]}>
                    <Col span={12}>
                        <ProFormDigit
                            rules={[
                                {
                                    required: true,
                                    type: 'number',
                                    message: 'This field is required',
                                },
                            ]}
                            name="amount"
                            label="Price per Piece"
                            placeholder="Please Enter"
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSelect
                            placeholder={'Select account'}
                            label={'Product Category'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            name={'category'}
                            options={expensesLabelKey}
                            fieldProps={{
                                showSearch: true,
                                value: selectedCategory,
                                // open: categoryOpen,
                                // onDropdownVisibleChange: (val) =>
                                //     setCategoryOpen(val),
                                dropdownRender: (menu: any) => (
                                    <div style={{ width: 200 }}>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            align="center"
                                            style={{
                                                padding: '0 8px 4px',
                                            }}
                                        >
                                            <Input
                                                placeholder="Enter here"
                                                value={name}
                                                onChange={
                                                    handleAddExpenseCategory
                                                }
                                                autoFocus
                                            />
                                            <Typography.Link
                                                onClick={handleAddItem}
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                }}
                                                disabled={name === ''}
                                            >
                                                <PlusOutlined /> Add account
                                            </Typography.Link>
                                        </Space>
                                    </div>
                                ),
                                optionItemRender: (item: any) => {
                                    return (
                                        <>
                                            <Row
                                                wrap={false}
                                                justify={'space-between'}
                                            >
                                                <Col
                                                    span={22}
                                                    onClick={() =>
                                                        setSelectedCategory(
                                                            item.key
                                                        )
                                                    }
                                                >
                                                    {item.label}
                                                </Col>
                                                <Col span={2}>
                                                    <DeleteOutlined
                                                        onClick={(e) =>
                                                            handleDeleteCategory(
                                                                e,
                                                                item.key
                                                            )
                                                        }
                                                        style={{
                                                            color: 'red',
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                },
                            }}
                        />
                    </Col>
                </Row>
                {/* <ProFormTextArea
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                    name={'purpose'}
                    label={'Purpose'}
                    style={{
                        width: '100%',
                    }}
                /> */}

                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <ProFormDatePicker
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            name={'requestDate'}
                            label={'Date'}
                            initialValue={moment()}
                            fieldProps={{
                                format: 'MMMM DD, YYYY',
                                style: {
                                    width: '100%',
                                },
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        {/* <ProFormText
                            rules={[]}
                            name="chequeNumber"
                            label="Cheque No."
                            placeholder="Please Enter"
                        /> */}
                    </Col>

                    {/* <ProFormDatePicker
                        fieldProps={{
                            format: 'MMMM DD, YYYY',
                        }}
                        name={'approvedDate'}
                        label={'Approval Date'}
                        initialValue={moment()}
                    />
                    <ProFormDatePicker
                        fieldProps={{
                            format: 'MMMM DD, YYYY',
                        }}
                        name={'releaseDate'}
                        label={'Release Date'}
                        initialValue={moment()}
                    /> */}
                </Row>
            </ModalForm>
        </div>
    )
}

export default NewCapEx
