import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SystemSettings } from '../../domain/entities/SystemSettings'
import { SystemService } from '../../domain/usecases/system.usecase'
import { SystemLocalImplementation } from '../../data/repositories/team/system.local'
import moment from 'moment'

export const initialSystemState: SystemSettings = {
    shareStartDate: '01/02',
    shareDurationInDays: 52 * 7,
    loanInterestRate: 5,
    shareDividendFrequency: 'annually',
    deductionFrequency: 'annually',
    deductionFormula: 'equal',
    paymentFrequency: 'monthly',
    shareDepositStartDate: 10,
    shareDepositDeadlineInHours: 6 * 24,
    shareMinDeposit: 260,
    shareMaxDeposit: 260,
    sharePenalty: 0,
    overallShares: 0,
    currency: '₱',
    minimumLoanAmount: 0,
    maximumLoanAmount: 0,
    minimumBalance: 0,
    maximumBalance: 0,
    showNewExpense: false,
    currentYear: moment().year().toString(),
    expenseState: {
        showNewExpense: false,
    },
    expenseCategories: [],
    expenses: [],
    notice: {
        show: false,
        message: '',
        type: '',
        description: '',
    },
    uniformLoanInterest: false,
    withInitialLoanPayment: true,
    compoundInterest: 'continuous',
    loanPenalty: true,
    financialPerformance: {
        remainingBalance: 0,
        overallLoan: 0,
        overallUnpaidInterest: 0,
        totalPaymentsReceived: 0,
        overallIncome: 0,
        overallPrincipal: 0,
        totalExpense: 0,
        memberShares: 0,
        memberPreferredShares: 0,
        totalCollection: 0,
    },
    bankingPerformance: {
        totalAccounts: 0,
        totalDeposits: 0,
        totalWithdrawals: 0,
        totalInterestEarned: 0,
        averageBalance: 0,
        highestBalance: 0,
        lowestBalance: 0,
    },
    loanCategories: [],
    transportPerformance: {
        totalAssistantSalary: 0,
        totalCardRevenue: 0,
        totalCashCollected: 0,
        totalDriverSalary: 0,
        totalExpense: 0,
        totalQuotaIncentives: 0,
        totalRevenue: 0,
    },
    routeNames: [],
    collectionCategories: [],
    capExCategories: [],
    hr: {
        firstShiftIn: '',
        firstShiftOut: '',
        officeShiftIn: '',
        officeShiftOut: '',
    },
    payroll: {
        earnings: [],
        deductions: [],
        overtimePayEquation: 1.25,
    },
    productCollectionCategories: [],
}

const localImpl = new SystemLocalImplementation()

export const exportData = () => {
    const systemService = new SystemService(localImpl)
    return systemService.Export()
}

export const systemInit = () => {
    const systemService = new SystemService(localImpl)
    return systemService.systemInit()
}

export const importData = (payload: any) => {
    const systemService = new SystemService(localImpl)
    return systemService.Import(payload)
}

export const getSystemConfig = createAsyncThunk(
    'system/getSystemConfig',
    (): any => {
        const systemService = new SystemService(localImpl)
        return systemService.getSystemConfig()
    }
)

type systemKeys = keyof typeof initialSystemState

export const updateSystemConfig = createAsyncThunk(
    'system/updateSystemConfig',
    (payload: typeof initialSystemState[systemKeys]): any => {
        const systemService = new SystemService(localImpl)
        return systemService.updateSystemConfig(payload)
    }
)
export const systemSlice = createSlice({
    name: 'system',
    initialState: initialSystemState,
    reducers: {
        // @ts-ignore
        updateSystemState: (
            state: { [key: string]: any },
            action: PayloadAction<Partial<SystemSettings>>
        ) => {
            state = {
                ...state,
                ...action.payload,
            }
            return state
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSystemConfig.fulfilled, (state, action) => {
            if (!action.payload) return
            return {
                ...state,
                ...action.payload,
            }
        })
        builder.addCase(updateSystemConfig.fulfilled, (state, action) => {
            if (!action.payload) return
            return {
                ...state,
                ...action.payload,
            }
        })
    },
})

export const { updateSystemState } = systemSlice.actions
