import { Vehicle } from '../../domain/entities/Transport'
import { Row, Tag } from 'antd'
import React from 'react'
import { FaBusAlt } from 'react-icons/fa'
// import { MdLocationPin } from 'react-icons/md';

interface MarkerInterface {
    vehicle: Vehicle
    withDescription?: boolean
    id?: string
}
const Marker = ({ vehicle, withDescription }: MarkerInterface) => {
    return (
        <div className="hint hint--html hint--info hint--top">
            {/* <div><MdLocationPin style={{color: "red", fontSize: 42}} /></div> */}

            <div
                style={{
                    height: 30,
                    width: 30,
                    borderRadius: '50%',
                    backgroundColor: 'red',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        height: 22,
                        width: 22,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <FaBusAlt
                        style={{
                            color: 'black',
                            fontSize: 12,
                            fontWeight: 'bolder',
                        }}
                    />
                </div>
            </div>
            {withDescription && (
                <div style={{ position: 'absolute', top: -5, left: 34 }}>
                    <Tag color="success">
                        <Row>{vehicle.title}</Row>
                        <Row>
                            {vehicle.plate} | {vehicle.routeName}
                        </Row>
                    </Tag>
                </div>
            )}
        </div>
    )
}

export default Marker
