import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MemberService } from '../../../domain/usecases/member.usecase'
import { Member } from '../../../domain/entities/Member'
import { MemberLocalImplementation } from '../../../data/repositories/team/member.localImp'
import {
    TeamMember,
    teamMemberInitialState,
} from '../../../domain/entities/Team'
import { Loan } from '../../../domain/entities/Loan'
import { AmortizationSchedule } from '../../pages/Member/loans/ApplyLoan'
interface ApplyLoanData extends TeamMember {
    show: boolean
    memberId: string
}
interface InitState {
    members: Member[]
    applyLoanData: ApplyLoanData
    draftLoanData?: Loan
    draftLoanAmortization?: AmortizationSchedule
    previewAmortization?: AmortizationSchedule
    ready: boolean
}
const initialState: InitState = {
    members: [],
    applyLoanData: {
        ...teamMemberInitialState,
        status: 'pending',
        isRemoved: false,
        dateAccepted: 0,
        show: false,
        memberId: '',
    },
    ready: false,
}

const localImpl = new MemberLocalImplementation()

export const getMembers = createAsyncThunk('members/getMembers', () => {
    const memberService = new MemberService(localImpl)
    return memberService.GetMembers()
})

export const addMember = createAsyncThunk(
    'members/addMember',
    (payload: Member) => {
        const memberService = new MemberService(localImpl)
        return memberService.AddMember(payload)
    }
)

export const removeMember = createAsyncThunk(
    'members/removeMember',
    (payload: string) => {
        const memberService = new MemberService(localImpl)
        return memberService.RemoveMember(payload)
    }
)

export const updateMember = createAsyncThunk(
    'members/updateMember',
    (payload: Member) => {
        const memberService = new MemberService(localImpl)
        return memberService.UpdateMember(payload)
    }
)

export const getMember = (memberId: string) => {
    const memberService = new MemberService(localImpl)
    return memberService.GetMember(memberId)
}

export const memberSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {
        updateMemberState: (
            state: { [key: string]: any },
            action: PayloadAction<{
                name: string
                value: any
            }>
        ) => {
            state[action.payload.name] = action.payload.value
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMembers.fulfilled, (state, action) => {
            state.members = action.payload
            state.ready = true
        })
        builder.addCase(getMembers.pending, (state) => {
            state.ready = false
        })
        builder.addCase(addMember.fulfilled, (state, action) => {
            state.members = [...state.members, action.payload]
        })
        builder.addCase(removeMember.fulfilled, (state, action) => {
            if (action.payload) {
                const members = state.members
                const idx = members.findIndex(
                    (member) => member.id === action.payload
                )
                if (idx >= 0) {
                    state.members.splice(idx, 1)
                }
            }
        })
        builder.addCase(updateMember.fulfilled, (stx, action) => {
            const state = stx
            const members = state.members
            const idx = members.findIndex(
                (member) => member.id === action?.payload?.id
            )
            state.members[idx] = {
                ...state.members[idx],
                ...action.payload,
            }
        })
    },
})

export const { updateMemberState } = memberSlice.actions
