import {
    AiOutlineFileUnknown,
    AiOutlineFileWord,
    AiOutlineFilePpt,
    AiOutlineFileExcel,
    AiOutlineFileImage,
    AiOutlineFileGif,
    AiOutlineFilePdf,
} from 'react-icons/ai'

export const computeBytesHandler = (value: any) => {
    if (value.toString().length > 3 && value.toString().length < 7) {
        const result = value / 1000
        return `${Math.floor(result)} KB`
    } else if (value.toString().length > 6 && value.toString().length < 9) {
        const result = value / 1000000
        return `${Math.fround(result).toFixed(2)} MB`
    } else {
        return `${Math.floor(value)} B`
    }
}

export const iconCheckerHandler = (type: string) => {
    const fileType = type || ''
    if (fileType.includes('officedocument')) {
        const documentType = fileType.split('.')
        const docType = documentType[documentType.length - 1]
        if (docType === 'document') {
            return <AiOutlineFileWord style={{ marginRight: '5px' }} />
        }
        if (docType === 'presentation') {
            return <AiOutlineFilePpt style={{ marginRight: '5px' }} />
        }
        if (docType === 'sheet') {
            return <AiOutlineFileExcel style={{ marginRight: '5px' }} />
        }

        return <AiOutlineFileUnknown style={{ marginRight: '5px' }} />
    } else {
        if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
            return <AiOutlineFileImage style={{ marginRight: '5px' }} />
        }
        if (fileType === 'gif') {
            return <AiOutlineFileGif style={{ marginRight: '5px' }} />
        }
        if (fileType === 'pdf') {
            return <AiOutlineFilePdf style={{ marginRight: '5px' }} />
        }

        return <AiOutlineFileUnknown style={{ marginRight: '5px' }} />
    }
}

export const fileTypeSplitter = (values: string) => {
    const fileType = values.split('/')[1]
    if (fileType.includes('officedocument')) {
        const documentType = fileType.split('.')
        const docType = documentType[documentType.length - 1]
        if (docType === 'document') {
            return '.docx file'
        }
        if (docType === 'presentation') {
            return '.pptx file'
        }
        if (docType === 'sheet') {
            return '.xlsx file'
        }

        return 'file'
    } else {
        return `.${fileType} file`
    }
}
