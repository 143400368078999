import { Actions } from './default/actions'
import { Frequency } from './SystemSettings'

export const savingsAccountStatuses = [
    {
        label: 'Active',
        description: 'The savings account is active and in good standing.',
        value: 'active',
    },
    {
        label: 'Inactive',
        description: 'The savings account is temporarily inactive.',
        value: 'inactive',
    },
    {
        label: 'Dormant',
        description:
            'The savings account has been inactive for an extended period of time.',
        value: 'dormant',
    },
    {
        label: 'Closed',
        description: 'The savings account has been permanently closed.',
        value: 'closed',
    },
]

type SavingAccountStatus = 'active' | 'inactive' | 'dormant' | 'closed'
export interface Saving extends Actions {
    accountNumber: string
    accountHolder: string
    openingDate: number
    openingBalance: number
    currentBalance: number
    interestRate: number
    interestEarned: number
    totalDeposits: number
    totalWithdrawals: number
    interestPostingFrequency: Frequency
    partnerCode: string
    memberId: string
    id?: string
    status: SavingAccountStatus
}

export interface CalculateSaving {}

export interface Deposit extends Actions {
    id?: string
    savingsAccountId: string
    memberId: string
    amount: number
    transactionType: DepositTypes
    remarks: string
    partnerCode: string
}

export type DepositTypes =
    | 'deposit'
    | 'withdrawal'
    | 'transfer'
    | 'interest'
    | 'fee'
    | 'opening'

export const transactionTypesOptions = [
    {
        value: 'deposit',
        label: 'Deposit',
        description: 'Amount deposited into the account.',
    },
    {
        value: 'withdrawal',
        label: 'Withdrawal',
        description: 'Amount withdrawn from the account.',
    },
    // {
    //     value: 'transfer',
    //     label: 'Transfer',
    //     description: 'Amount transferred between accounts.',
    // },
    // {
    //     value: 'interest',
    //     label: 'Interest',
    //     description: 'Accrued interest credited to the account.',
    // },
    // {
    //     value: 'fee',
    //     label: 'Fee',
    //     description: 'Service fee deducted from the account.',
    // },
    // Add more transaction types as needed
]
