/* eslint-disable no-unused-vars */
import { useAppSelector } from '../../../app/store'
import { db } from '../../boot'
import Papa from 'papaparse'
import {
    PAYROLLS_REPOSITORY,
    PERSONNEL_ATTENDANCE_REPOSITORY,
    TRIPS_REPOSITORY,
} from '../../../data/repositories/constants'
import {
    Typography,
    Table,
    Badge,
    Button,
    Col,
    Row,
    Grid,
    Upload,
    message,
    Popconfirm,
    Dropdown,
    Menu,
    Statistic,
} from 'antd'
import {
    collection,
    query,
    where,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    onSnapshot,
} from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import PayrollContainer from '../Personnel/Payroll/PayrollContainer'
import {
    ModalForm,
    ProCard,
    ProFormDateRangePicker,
    ProFormDigit,
    ProFormSelect,
} from '@ant-design/pro-components'
import { GiBookPile } from 'react-icons/gi'
import { Trip } from '@/domain/entities/Transport'
import { Attendance } from '@/domain/entities/Attendance'
import { HiEllipsisVertical } from 'react-icons/hi2'
import { DownloadOutlined } from '@ant-design/icons'
import { utils, writeFile } from 'xlsx'

interface PayrollInterface {}

function Payroll(props: PayrollInterface) {
    const [data, setData] = useState<any>([])
    // eslint-disable-next-line no-unused-vars
    const [totalShiftHours, setTotalShiftHours] = useState(8)
    const { styles, activeTeam, vehicles, currentTeamMembers, partnerCode } =
        useAppSelector(
            (state) => ({
                styles: state.styles,
                email: state.auth.user.email,
                partnerCode: state.auth.user.partnerCode,
                currentTeamMembers: state.team.currentTeamMembers,
                activeTeam: state.team.selectedTeamData.partnerCode,
                totalPersonnel: state.team.selectedTeamData.totalPersonnel,
                vehicles: state.vehicle.vehicles.entities,
            }),
            shallowEqual
        )

    const [showPayroll, setShowPayroll] = useState({
        show: false,
        data: undefined,
    })
    const handleShowPayroll = (data: any) => {
        setShowPayroll({
            show: true,
            data,
        })
    }

    // Helper function to map Ant Design column data to Excel headers
    function mapAntdColumnsToExcelHeaders(columns: any[]) {
        return columns.map((column: any) => column?.title || '')
    }

    // Helper function to map array of objects to 2D array for Excel
    function mapDataToExcelRows(data: any[], columns: any[]) {
        return data.map((row) => {
            return columns.map((column) => {
                return row[column.dataIndex]
            })
        })
    }

    // Main function to convert Ant Design table data to Excel
    // eslint-disable-next-line no-unused-vars
    async function exportAntdTableToExcel(
        data: any[],
        columns: any[],
        filename = 'Export.xlsx'
    ) {
        // Map Ant Design columns to Excel headers
        const wsheaders = mapAntdColumnsToExcelHeaders(columns)

        // Map data to Excel rows
        const wsdata = mapDataToExcelRows(data, columns)

        // Create worksheet
        const ws = utils.aoa_to_sheet([wsheaders, ...wsdata])

        // Create workbook
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Sheet1')

        // Write workbook to file
        writeFile(wb, filename)
    }
    const handleDownloadRow = async (row: any) => {
        // eslint-disable-next-line no-console
        console.log(row)
        if (!row.columns) return
        await exportAntdTableToExcel(row.data, row.columns || [])
    }

    const handleChange = async (data: any) => {
        // const response = await fetch('/data/nodes.csv')
        // if(!response.body) return
        // const reader = response.body.getReader()
        // const result = await reader.read() // raw array
        // const decoder = new TextDecoder('utf-8')
        // const csv = decoder.decode(result.value) // the csv text
        Papa.parse(data.file.originFileObj, {
            header: true,
            async complete(results: any, file) {
                // eslint-disable-next-line no-console
                const vehicleArray = Object.keys(vehicles).map((key) => {
                    return {
                        ...vehicles[key],
                        id: key,
                    }
                })
                // eslint-disable-next-line no-console
                // console.log(
                //     results,
                //     currentTeamMembers.find(
                //         (member) => member.displayName === 'Samuel Aseniero'
                //     )
                // )
                const alls = results.data.map(async (res: any) => {
                    if (res.assistantName !== 'Samuel Aseniero ') return false
                    const filtered = vehicleArray.filter(
                        (vehicle) =>
                            vehicle.plate === res.plateNumber ||
                            vehicle.plate?.includes(res.plateNumber)
                    )
                    let driverFiltered: any = undefined
                    let assistantFiltered: any = undefined
                    currentTeamMembers.forEach((member) => {
                        // eslint-disable-next-line no-console
                        // console.log(member.displayName, res.driverName, res.assistantName);
                        if (
                            (member.displayName === res.driverName ||
                                member.displayName.includes(res.driverName)) &&
                            !driverFiltered
                        ) {
                            driverFiltered = member
                        }
                        if (
                            (member.displayName === res.assistantName.trim() ||
                                member.displayName.includes(
                                    res.assistantName
                                )) &&
                            !assistantFiltered
                        ) {
                            assistantFiltered = member
                        }
                    })
                    let vehicleId
                    // let plate;
                    let title = ''
                    const vehicle = filtered[0]
                    if (vehicle) {
                        vehicleId = vehicle.id
                        title = `${vehicle?.title} - ${vehicle?.routeName}`
                        // plate = filtered[0].plate
                    }
                    const date = moment(res.date, 'ddd, MMMM DD, YYYY')
                    const timeIn = moment(res.shiftStartTime, 'HH:mm')
                        .set('M', date.month())
                        .set('D', date.date())
                    const timeOut = moment(res.shiftEndTime, 'HH:mm')
                        .set('M', date.month())
                        .set('D', date.date())

                    const cashCollected = parseFloat(res.cashCollected)
                    const totalCashReceived = parseFloat(res.totalCashReceived)
                    const dispatchingCost = parseFloat(res.dispatchingCost)
                    const miscellaneousExpenses = parseFloat(
                        res.miscellaneousExpenses
                    )
                    const driverSalary = parseFloat(res.driverSalary)
                    const driverIncentives = parseFloat(res.driverIncentives)
                    const assistantSalary = parseFloat(res.assistantSalary)
                    const assistantIncentives = parseFloat(
                        res.assistantIncentives
                    )
                    const driverOvertimeHours = parseFloat(
                        res.driverOvertimeHours
                    )
                    const assistantOvertimeHours = parseFloat(
                        res.assistantOvertimeHours
                    )
                    const assistantOvertimePay = parseFloat(
                        res.assistantOvertimePay
                    )
                    const driverOvertimePay = parseFloat(res.driverOvertimePay)

                    const totalRevenue = cashCollected
                    const totalExpenses =
                        miscellaneousExpenses +
                        driverSalary +
                        driverIncentives +
                        assistantSalary +
                        assistantIncentives +
                        assistantOvertimePay +
                        driverOvertimePay
                    // eslint-disable-next-line no-console
                    if (!driverFiltered || !assistantFiltered) return false

                    const payload: Trip = {
                        // timeIn: timeIn.unix(),
                        // timeOut: timeOut.unix(),
                        // id: vehicleId,
                        driver: driverFiltered,
                        assistant: assistantFiltered,
                        totalCashReceived,
                        dispatchingCost,
                        miscellaneousExpenses,
                        driverSalary,
                        driverIncentives,
                        assistantSalary,
                        assistantIncentives,
                        driverOvertimeHours,
                        assistantOvertimeHours,
                        assistantOvertimePay,
                        driverOvertimePay,
                        // plate: plate as string,
                        shiftStartTime: timeIn.unix(),
                        shiftEndTime: timeOut.unix(),
                        date: date.format('ddd, MMMM DD, YYYY'),
                        vehicleId: vehicleId as string,
                        partnerCode: 'orotsco',
                        paymentStatus: 'pending',
                        status: 'done',
                        driverPayoutStatus: 'pending',
                        assistantPayoutStatus: 'pending',
                        totalExpenses,
                        totalRevenue,
                        cashCollected,
                        creation: timeIn.unix(),
                        modified: timeIn.unix(),
                        modifiedBy: 'system-import-2',
                        isRemoved: false,
                        title,
                        totalRoundTrips: 0,
                        driverId: driverFiltered.id,
                        assistantId: assistantFiltered?.id,
                        withAssistant: assistantFiltered !== undefined,
                        notes: '',
                        amountCollected: 0,
                        hours: 0,
                        totalGasConsumed: 0,
                        totalGasCost: 0,
                        distanceTraveled: 0,
                        totalPassengers: 0,
                        maintenanceFee: 0,
                        cardRevenue: 0,
                        dailyQuotaIncentives: 0,
                        driverAllowance: 0,
                        assistantAllowance: 0,
                        shiftHistory: [],
                        overtimeHours: 0,
                        attendanceId: '',
                        isAssistantHalfDay: res.isAssistantHalfDay,
                        isDriverHalfDay: res.isDriverHalfDay,
                        isEmployeeHalfDay: res.isEmployeeHalfDay,
                    }

                    const resp = await addDoc(
                        collection(db, TRIPS_REPOSITORY),
                        payload
                    )
                    message.success(`Successfully added trip: ${title}`)

                    const attendanceUpdatePayload: Attendance = {
                        totalCashReceived: payload.totalCashReceived || 0,
                        totalExpenses: payload.totalExpenses || 0,
                        totalGasConsumed: payload.totalGasConsumed || 0,
                        totalGasCost: payload.totalGasCost || 0,
                        totalPassengers: payload.totalPassengers || 0,
                        totalRevenue: payload.totalRevenue || 0,
                        totalRoundTrips: payload.totalRoundTrips || 0,
                        assistantId: payload.assistantId,
                        driverId: payload.driverId,
                        driverIncentives: payload.driverIncentives || 0,
                        driverOvertimeHours: payload.driverOvertimeHours || 0,
                        driverOvertimePay: payload.driverOvertimePay || 0,
                        driverSalary: payload.driverSalary || 0,
                        assistantIncentives: payload.assistantIncentives || 0,
                        assistantOvertimeHours:
                            payload.assistantOvertimeHours || 0,
                        assistantOvertimePay: payload.assistantOvertimePay || 0,
                        assistantSalary: payload.assistantSalary || 0,
                        cardRevenue: payload.cardRevenue || 0,
                        cashCollected: payload.cashCollected || 0,
                        dispatchingCost: payload.dispatchingCost || 0,
                        timeIn: payload.shiftStartTime || 0,
                        timeOut: payload.shiftEndTime || 0,
                        miscellaneousExpenses:
                            payload.miscellaneousExpenses || 0,
                        partnerCode: payload.partnerCode,
                        assistantSss: payload.driver?.sss || '',
                        assistantPhilhealth: payload.driver?.philhealth || '',
                        assistantPagibig: payload.driver?.pagibig || '',
                        driverSss: payload.driver?.sss || '',
                        driverPhilhealth: payload.driver?.philhealth || '',
                        driverPagibig: payload.driver?.pagibig || '',
                        signatureUrl: payload.signatureUrl || '',
                        vehicleId: payload.vehicleId,
                        isAssistantHalfDay: payload.isAssistantHalfDay,
                        isDriverHalfDay: payload.isDriverHalfDay,
                        isEmployeeHalfDay: payload.isEmployeeHalfDay || false,
                        tripId: resp.id,
                        status: 'done',
                        paymentStatus: 'pending',
                        withAssistant: false,
                        date: moment
                            .unix(payload.shiftStartTime)
                            .format('MMM DD, YYYY'),
                        creation: payload.shiftStartTime || 0,
                        modified: moment().unix(),

                        overtimePay: 0,
                        overtimeHours: 0,
                        incentives: 0,
                        salary: 0,
                        employeeRole: '',
                        driverStatus: 'pending',
                        assistantStatus: 'pending',
                        employeeStatus: 'pending',
                        modifiedBy: 'system-import-2',
                        isRemoved: false,
                    }
                    await addDoc(
                        collection(db, PERSONNEL_ATTENDANCE_REPOSITORY),
                        attendanceUpdatePayload
                    )
                    // eslint-disable-next-line no-console
                    // console.log(payload, attendanceUpdatePayload);

                    message.success(`Successfully added attendance: ${title}`)
                    return payload
                })
                const promised = await Promise.all(alls.filter(Boolean))
                // eslint-disable-next-line no-console
                // console.log(promised, );
            },
        }) // object with { data, errors, meta }
        // const rows = results.data // array of objects
        // eslint-disable-next-line no-console
        // console.log(data, results);
        // setRows(rows)
    }
    useEffect(() => {
        // generatePayrollList()
        // attendanceScript()
        // tripScript()
        const main = () => {
            const payrollRef = collection(db, PAYROLLS_REPOSITORY)
            const q = query(
                payrollRef,
                where('partnerCode', '==', activeTeam),
                where('isRemoved', '==', false)
            )
            return onSnapshot(q, async (snap) => {
                const data: any = []
                snap.docs.forEach((doc) => {
                    data.push({
                        ...doc.data(),
                        id: doc.id,
                    })
                })
                setData(data.sort((a: any, b: any) => b.creation - a.creation))
            })
        }

        const listener = main()
        return () => listener()
        // generatePayrollList()
    }, [activeTeam])

    const handleClosePayroll = () => {
        setShowPayroll({
            show: false,
            data: undefined,
        })
    }

    const [importer, setImporter] = useState<any>({
        show: false,
        columns: [],
        data: undefined,
    })

    const handleCloseImporter = () => {
        setImporter({
            show: false,
            columns: [],
        })
    }

    const approvePayroll = async (id: string) => {
        // eslint-disable-next-line no-console
        // console.log(data)
        const ref = doc(db, PAYROLLS_REPOSITORY, id)
        await updateDoc(ref, {
            status: 'done',
        })
        message.success(
            'Successfully approved payroll. Employees can now view their payslip',
            10
        )
    }

    const deletePayroll = async (id: string) => {
        // eslint-disable-next-line no-console
        // console.log(data)
        const ref = doc(db, PAYROLLS_REPOSITORY, id)
        await updateDoc(ref, {
            isRemoved: true,
        })
        message.success('Successfully removed payroll.')
    }

    // eslint-disable-next-line no-console
    // console.log(currentTeamMembers)
    const { xs } = Grid.useBreakpoint()
    return (
        <div className="page-layout">
            <ModalForm
                open={importer.show}
                modalProps={{ onCancel: handleCloseImporter }}
                title={'Select column correspondence'}
                // onFinish={handleSubmitPayroll}
            >
                <ProFormDigit
                    label="Number of hours per shift"
                    initialValue={totalShiftHours}
                    name="numberOfHoursPerShift"
                />
                <br />
                <ProFormSelect
                    label={'Employee Number'}
                    options={importer.columns}
                    name=""
                />
                <ProFormSelect
                    label={'Employee Name'}
                    options={importer.columns}
                    name=""
                />
                <ProFormSelect
                    label={'Time In'}
                    options={importer.columns}
                    name=""
                />
                <ProFormSelect
                    label={'Time Out'}
                    options={importer.columns}
                    name=""
                />
                <ProFormSelect
                    label={'Daily Salary Rate'}
                    name=""
                    options={importer.columns}
                />
                <ProFormSelect
                    label={'Number of hours per shift'}
                    options={importer.columns}
                    name=""
                />
            </ModalForm>
            <ModalForm
                open={importer.show}
                modalProps={{ onCancel: handleCloseImporter }}
                title={'Select column correspondence'}
                // onFinish={handleSubmitPayroll}
            >
                <ProFormDateRangePicker
                    name="rangge"
                    label="Payroll schedule"
                />
            </ModalForm>
            {/* <Importer /> */}
            <Row>
                <Col xs={12} lg={6}>
                    <ProCard
                        style={{
                            maxHeight: 100,
                            cursor: 'pointer',
                        }}
                        bodyStyle={{
                            maxHeight: 100,
                            padding: 16,
                            maxWidth: 200,
                        }}
                        onClick={handleShowPayroll}
                    >
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <GiBookPile
                                    style={{
                                        fontSize: xs ? 16 : 28,
                                        color: styles.primary,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <Button
                                    style={{
                                        color: styles.primary,
                                        fontSize: xs ? 16 : 20,
                                        padding: 0,
                                    }}
                                    type={'text'}
                                    onClick={handleShowPayroll}
                                >
                                    New Payroll
                                </Button>
                            </Col>
                        </Row>
                    </ProCard>
                </Col>
                {partnerCode === 'perati' ? (
                    <Col xs={12} lg={6}>
                        <ProCard
                            style={{
                                maxHeight: 100,
                                cursor: 'pointer',
                            }}
                            bodyStyle={{
                                maxHeight: 100,
                                padding: 16,
                                maxWidth: 200,
                            }}
                            // onClick={handleShowPayroll}
                        >
                            <Row justify={'center'} align={'middle'}>
                                <Col>
                                    <GiBookPile
                                        style={{
                                            fontSize: xs ? 16 : 28,
                                            color: styles.primary,
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row justify={'center'} align={'middle'}>
                                <Col>
                                    <Upload
                                        onChange={handleChange}
                                        showUploadList={false}
                                        customRequest={() => null}
                                    >
                                        <Button
                                            style={{
                                                color: styles.primary,
                                                fontSize: xs ? 16 : 20,
                                                padding: 0,
                                            }}
                                            type={'text'}
                                            // onClick={handleShowPayroll}
                                        >
                                            Import Payroll
                                        </Button>
                                    </Upload>
                                </Col>
                            </Row>
                        </ProCard>
                    </Col>
                ) : null}
            </Row>
            {showPayroll.show && (
                <PayrollContainer
                    onCancel={handleClosePayroll}
                    data={showPayroll.data}
                />
            )}
            {/* <Button onClick={employeeTimeIn}>Driver Time In</Button> */}
            {/* <Button onClick={employeeTimeIn}>Assistant Time In</Button> */}
            <Table
                scroll={{ x: 800 }}
                bordered
                pagination={false}
                dataSource={data}
                columns={[
                    {
                        title: 'No.',
                        dataIndex: 'rowNumber',
                        key: 'rowNumber',
                        render: (text: any, record: any, index: any) =>
                            index + 1,
                        // The render function displays the row number (index + 1) for each row
                    },
                    {
                        title: 'Start Date',
                        dataIndex: 'start',
                        // sorter: (a, b) =>
                        //     a.currentMonth.localeCompare(b.currentMonth),
                        render: (
                            text: any
                            // record: MemberReceivable,
                            // index: number,
                            // action: ProCoreActionType
                        ) => {
                            const date = moment.unix(text)
                            return `${date.format('MM/DD/YYYY')}`
                        },
                    },

                    {
                        title: 'Cut-off Date',
                        dataIndex: 'end',
                        // width: 200,
                        render: (
                            text: any
                            // record: MemberReceivable,
                            // index: number,
                            // action: ProCoreActionType
                        ) => {
                            const date = moment.unix(text)
                            return `${date.format('MM/DD/YYYY')}`
                        },
                    },

                    {
                        title: 'Employee',
                        render: (
                            text: any,
                            record: any
                            // index: number,
                            // action: ProCoreActionType
                        ) => {
                            // const date = moment.unix(text)
                            const length =
                                record.data?.length ||
                                Object.keys(record.data)?.length ||
                                0
                            return length
                        },
                    },
                    {
                        title: 'Total Pay',
                        dataIndex: 'totalPayrollAmount',
                        // width: 200,

                        render: (
                            text: any
                            // record: MemberReceivable,
                            // index: number,
                            // action: ProCoreActionType
                        ) => {
                            // return `₱${parseFloat(text).toFixed(2)}`
                            return (
                                <Statistic
                                    prefix={'₱'}
                                    value={text}
                                    precision={2}
                                    prefixCls="₱"
                                />
                            )
                        },
                    },
                    {
                        title: 'Total Deductions',
                        dataIndex: 'totalPayrollDeduction',
                        // width: 200,

                        render: (
                            text: any
                            // record: MemberReceivable,
                            // index: number,
                            // action: ProCoreActionType
                        ) => {
                            return (
                                <Statistic
                                    prefix={'₱'}
                                    value={text}
                                    precision={2}
                                    prefixCls="₱"
                                />
                            )
                        },
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        render: (text: any, row: any) => (
                            <Row gutter={[12, 12]}>
                                <Col>
                                    <Badge
                                        status={
                                            text === 'pending'
                                                ? 'processing'
                                                : 'success'
                                        }
                                        text={
                                            text === 'pending'
                                                ? 'Processing'
                                                : 'Finished'
                                        }
                                    />
                                </Col>
                            </Row>
                        ),
                    },
                    {
                        title: 'Actions',
                        render: (text, row) => {
                            return (
                                <Row gutter={[12, 12]}>
                                    <Col>
                                        <Button
                                            type="link"
                                            size="small"
                                            onClick={() =>
                                                handleShowPayroll(row)
                                            }
                                        >
                                            View
                                        </Button>
                                    </Col>

                                    <Col>
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={
                                                <Menu>
                                                    {row.status ===
                                                        'pending' && (
                                                        <Popconfirm
                                                            title={
                                                                "You can't undo! Are you sure you want to approve?"
                                                            }
                                                            onConfirm={() =>
                                                                approvePayroll(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            <Menu.Item>
                                                                Approve
                                                            </Menu.Item>
                                                        </Popconfirm>
                                                    )}

                                                    <Menu.Item
                                                        onClick={() =>
                                                            handleDownloadRow(
                                                                row
                                                            )
                                                        }
                                                    >
                                                        Download
                                                    </Menu.Item>

                                                    <Popconfirm
                                                        title={
                                                            "You can't undo! Are you sure you want to delete?"
                                                        }
                                                        onConfirm={() =>
                                                            deletePayroll(
                                                                row.id
                                                            )
                                                        }
                                                    >
                                                        <Menu.Item>
                                                            Delete
                                                        </Menu.Item>
                                                    </Popconfirm>
                                                </Menu>
                                            }
                                        >
                                            <Button type="link" size="small">
                                                More
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            )
                        },
                    },
                ]}
            />
        </div>
    )
}

export default Payroll
