import { message } from 'antd'
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    increment,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import moment from 'moment'
import { auth, db } from '../../../app/boot'
import { getWeight } from '../../../app/pages/Reports/MembersReceivableReport/helper'
import { CalculateShare, Share } from '../../../domain/entities/Share'
import { ShareFirebaseRepository } from '../../../domain/repositories/share.repository'
import { SHARES_REPOSITORY } from '../constants'
import { getErrorMessage } from '../default'
import { TeamFirebaseImplementation } from '../team/team.firebase'

export class ShareFirebaseImplementation
    extends TeamFirebaseImplementation
    implements ShareFirebaseRepository
{
    async AddShare(payload: Share) {
        // try {
        //     // const res = this.addShareToMember(payload.memberId, payload.amount)
        //     // if (!res) {
        //     //     throw `Share unsuccessfully added to member: ${payload.memberId}`
        //     // }
        //     const key = SHARES_REPOSITORY
        //     let shares = this.getStorage(key)
        //     if (!shares) {
        //         shares = []
        //     }
        //     shares.push(payload)
        //     this.setStorage(key, shares)
        //     // this.addShareToMember(payload)
        //     this.calculateMemberShare(payload)
        // } catch (err) {
        //     // eslint-disable-next-line no-console
        //     console.log(err)
        // }
        // return payload

        try {
            const resp = await addDoc(
                collection(db, SHARES_REPOSITORY),
                payload
            )
            payload.id = resp.id
            const { creation, partnerCode, memberId, amount } = payload
            await this.calculateMemberShare({
                creation,
                partnerCode,
                memberId,
                amount,
            })
            return payload
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
    }
    async getTeamShares(partnerCode: string) {
        const ref = collection(db, SHARES_REPOSITORY)
        const q = query(
            ref,
            where('partnerCode', '==', partnerCode),
            where('isRemoved', '==', false)
        )

        const snap = await getDocs(q)
        const data = snap.docs.map((docSnap) => {
            return {
                ...docSnap.data(),
                id: docSnap.id,
            } as Share
            // let shareData = docSnap.data()
            // let user = {}
            // if (shareData.memberUid) {
            //     const userSnap = await getDoc(
            //         doc(db, 'users', docSnap.data().memberUid)
            //     )

            //     user = userSnap.data() as User
            // }
            // return {
            //     // ...userSnap.data(),
            //     ...docSnap.data(),
            //     ...user,
            //     id: docSnap.id,
            // } as Share
        })
        // const resp = await Promise.all(data)
        return data
    }
    async DeleteShare(
        payload: CalculateShare & {
            id: string
        }
    ) {
        // const key = SHARES_REPOSITORY
        // const shares: Share[] = this.getStorage(key)
        // if (shares) {
        //     const idx = shares.findIndex((share: Share) => share.id === id)
        //     if (idx >= 0) {
        //         const removedShare = shares.splice(idx, 1)[0]
        //         this.calculateMemberShare(removedShare, true)
        //         this.setStorage(key, shares)
        //         return id
        //     }
        // }
        // return undefined
        const { id } = payload
        const ref = doc(db, SHARES_REPOSITORY, id)
        // await updateDoc(doc(db, SHARES_REPOSITORY, id), {
        //     isRemoved: true,
        //     modified: moment().unix(),
        //     modifiedBy: auth.currentUser?.uid,
        // })
        // await this.calculateMemberShare(payload, true)
        await deleteDoc(ref)
        return id
    }
    async UpdateShare(payload: Share, prevShare: CalculateShare) {
        // const key = SHARES_REPOSITORY
        // const shares = this.getStorage(key)
        // if (shares) {
        //     const idx = shares.findIndex(
        //         (share: Share) => share.id === payload.id
        //     )
        //     if (idx >= 0) {
        //         const prevShare = shares[idx]
        //         if (
        //             payload.amount !== prevShare.amount ||
        //             payload.creation !== prevShare.creation
        //         ) {
        //             this.calculateMemberShare(prevShare, true)

        //             this.calculateMemberShare(payload)
        //         }
        //         shares[idx] = {
        //             ...shares[idx],
        //             ...payload,
        //         }
        //         this.setStorage(key, shares)
        //         return payload
        //     }
        // }
        // return undefined
        if (
            payload.amount !== prevShare.amount ||
            payload.creation !== prevShare.creation
        ) {
            this.calculateMemberShare(prevShare, true)
            this.calculateMemberShare(payload)
        }

        await updateDoc(doc(db, SHARES_REPOSITORY, payload.id), {
            amount: payload.amount,
            modified: moment().unix(),
            modifiedBy: auth.currentUser?.uid,
        })
        return payload
    }
    async GetShare(id: string) {
        const snap = await getDoc(doc(db, SHARES_REPOSITORY, id))
        const data = snap.data()

        return {
            ...data,
            id,
        } as Share
    }
    async GetAllMemberShare(payload: {
        memberId: string
        partnerCode: string
    }) {
        const { memberId, partnerCode } = payload
        const ref = collection(db, SHARES_REPOSITORY)
        const q = query(
            ref,
            where('memberId', '==', memberId),
            where('partnerCode', '==', partnerCode),
            where('isRemoved', '==', false)
        )
        const snap = await getDocs(q)
        const data = snap.docs.map((docSnap) => {
            // const userSnap = await getDoc(doc(db, 'users', docSnap.id))
            return {
                // ...userSnap.data(),
                ...docSnap.data(),
                id: docSnap.id,
            } as Share
        })

        return data
    }

    async calculateMemberShare(payload: CalculateShare, remove?: boolean) {
        try {
            const monthOfShare = moment.unix(payload.creation).format('MMMM')
            const yearOfShare = moment.unix(payload.creation).format('YYYY')
            const weights = getWeight(payload.creation) || 0
            const weightedAverage = weights * payload.amount
            const share = remove ? -payload.amount : payload.amount
            const weightedShare = remove ? -weightedAverage : weightedAverage
            const { partnerCode } = payload
            const ref = doc(
                db,
                'team',
                partnerCode,
                'members',
                payload.memberId
            )

            await updateDoc(ref, {
                [`memberShare.${yearOfShare}.Annual`]: increment(share),
                [`memberShare.${yearOfShare}.${monthOfShare}`]:
                    increment(share),
                [`weightedMemberShare.${yearOfShare}.Annual`]:
                    increment(weightedShare),
                [`weightedMemberShare.${yearOfShare}.${monthOfShare}`]:
                    increment(weightedShare),
                totalShare: increment(share),
            })
            await this.addShareToTeam(
                yearOfShare,
                monthOfShare,
                partnerCode,
                share,
                weightedShare
            )
            return true
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
    }
}
