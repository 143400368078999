import { SystemRepository } from '../repositories/system.repository'

export class SystemService {
    systemRepo: SystemRepository

    constructor(ir: SystemRepository) {
        this.systemRepo = ir
    }

    systemInit(): any {
        return this.systemRepo.systemInit()
    }

    Export(): any {
        return this.systemRepo.Export()
    }

    Import(payload: any): any {
        return this.systemRepo.Import(payload)
    }

    getSystemConfig(): any {
        return this.systemRepo.getSystemConfig()
    }

    updateSystemConfig(payload: any): any {
        return this.systemRepo.updateSystemConfig(payload)
    }
}
