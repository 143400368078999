import React, { useEffect, useState } from 'react'
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardImage,
    // MDBCheckbox,
    MDBCol,
    MDBInput,
    MDBListGroup,
    MDBListGroupItem,
    MDBRow,
    MDBTypography,
} from 'mdb-react-ui-kit'
import { Button, Col, Form, Row, Spin } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { StoreProduct } from '../../../domain/entities/Store'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../boot'
import moment from 'moment'
import { STORE_ORDERS_REPOSITORY } from '../../../data/repositories/constants'

function OrderPage() {
    const navigate = useNavigate()
    const back = () => {
        navigate(-1)
    }
    const params = useParams()

    const [form] = Form.useForm()
    const [data, setData] = useState<StoreProduct | null>(null)
    const location = useLocation()

    // eslint-disable-next-line no-console
    console.log(location)
    const handleFinish = async (formData: any) => {
        try {
            const split = window.location.hostname.split('.')
            const hostName = split[0]
            // eslint-disable-next-line no-console
            console.log(data, formData)
            const payload = {
                orderData: data,
                formData,
                creation: moment().unix(),
                modified: moment().unix(),
                partnerCode: hostName,
                isRemoved: false,
            }
            const ref = collection(db, STORE_ORDERS_REPOSITORY)
            const resp = await addDoc(ref, payload)
            navigate(`success`, {
                state: {
                    orderId: resp.id,
                },
            })
        } catch (err) {
            navigate(`failure`)
        }
    }
    useEffect(() => {
        if (!location.state) {
            navigate(`/store/${params.productId}`)

            return
        }
        setData(location.state)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, params])
    useEffect(() => {}, [data])
    return (
        <div className="mx-auto mt-5" style={{ maxWidth: '900px' }}>
            {data ? (
                <MDBCard>
                    <MDBCardHeader className="py-3">
                        <Row>
                            <Col>
                                <Button onClick={back} type="link" size="large">
                                    <LeftOutlined style={{ fontSize: 24 }} />
                                </Button>
                            </Col>
                            <Col>
                                <MDBTypography tag="h2" className="mb-0">
                                    {data.title}
                                </MDBTypography>
                            </Col>
                        </Row>
                    </MDBCardHeader>
                </MDBCard>
            ) : (
                <Spin />
            )}
            <br />
            {data && (
                <Form form={form} onFinish={handleFinish}>
                    <MDBRow>
                        <MDBCol md="7" className="mb-4">
                            <MDBCard className="mb-4">
                                <MDBCardHeader className="py-3">
                                    <MDBTypography tag="h5" className="mb-0">
                                        Contact Information
                                    </MDBTypography>
                                </MDBCardHeader>
                                <MDBCardBody>
                                    <MDBRow className="mb-4">
                                        <MDBCol>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                name="firstName"
                                            >
                                                <MDBInput
                                                    name="firstName"
                                                    label="First name"
                                                    type="text"
                                                />
                                            </Form.Item>
                                        </MDBCol>
                                        <MDBCol>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                name="lastName"
                                            >
                                                <MDBInput
                                                    label="Last name"
                                                    type="text"
                                                />
                                            </Form.Item>
                                        </MDBCol>
                                    </MDBRow>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <MDBInput label="Email" type="text" />
                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <MDBInput label="Phone" type="text" />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        name="address"
                                    >
                                        <MDBInput
                                            label="Full Address"
                                            type="text"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        name="landmark"
                                    >
                                        <MDBInput
                                            label="Land Mark"
                                            type="text"
                                        />
                                    </Form.Item>

                                    {/* <div className="d-flex justify-content-center">
                                    <MDBCheckbox
                                        name="flexCheck"
                                        value=""
                                        id="flexCheckChecked"
                                        label="Create an account?"
                                        defaultChecked
                                    />
                                </div> */}
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol md="5" className="mb-4 position-statics">
                            <MDBCard className="mb-4">
                                <MDBCardHeader className="py-3">
                                    <MDBTypography
                                        tag="h5"
                                        className="mb-0 text-font"
                                    >
                                        {data?.productName || ''}
                                    </MDBTypography>
                                </MDBCardHeader>
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol md="4">
                                            <MDBCardImage
                                                src={data.coverImage}
                                                className="rounded-3"
                                                style={{ width: '100px' }}
                                                alt="Blue Jeans Jacket"
                                            />
                                        </MDBCol>
                                        <MDBCol md="6" className="ms-3">
                                            <span className="mb-0 text-price">
                                                ₱{data.productPrice} / each
                                            </span>
                                            <p className="mb-0 text-descriptions">
                                                {data.collection}{' '}
                                            </p>
                                            <p className="text-descriptions mt-0">
                                                Qty:{' '}
                                                <span className="text-descriptions fw-bold">
                                                    {data?.quantity}
                                                </span>
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                                <MDBCardFooter className="mt-4">
                                    <MDBListGroup flush>
                                        <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0 px-0 pb-0 text-muted">
                                            Subtotal
                                            <span>
                                                ₱
                                                {data?.productPrice *
                                                    data?.quantity}
                                            </span>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0  px-0 fw-bold text-uppercase">
                                            Total to pay
                                            <span>
                                                ₱
                                                {data?.productPrice *
                                                    data?.quantity}
                                            </span>
                                        </MDBListGroupItem>
                                    </MDBListGroup>
                                </MDBCardFooter>
                            </MDBCard>

                            <Form.Item>
                                <MDBBtn type="submit" size="lg" block>
                                    Make purchase
                                </MDBBtn>
                            </Form.Item>
                        </MDBCol>
                    </MDBRow>
                </Form>
            )}
        </div>
    )
}

export default OrderPage
