/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
    Row,
    Empty,
    Col,
    Typography,
    Dropdown,
    Menu,
    notification,
    Input,
    Grid,
    message,
} from 'antd'
import {
    CloudDownloadOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons'
import { MdDriveFileMoveOutline } from 'react-icons/md'
import { FiMoreVertical } from 'react-icons/fi'
import { BsArrowUpLeftCircle } from 'react-icons/bs'
import moment from 'moment'

// import { useIsMobile } from "@/data/utils/customHooks"
import { shallowEqual } from 'react-redux'
// import { RootState } from "@/data/utils/rootState"
import { computeBytesHandler, iconCheckerHandler } from './filesHelper'
import PreviewFilesModal, { EachFileProps } from './PreviewFilesModal'
import FilesDeleteModal from './FilesDeleteModal'
// import { MoveTutorFile, RenameTutorFileFolder } from "@/app/redux/tutors/tutors.actions"
import { useAppSelector } from '../../store'
import { GetFiles, RenameFile } from '../../redux/team/team.action'

const { Text } = Typography
const { Item } = Menu

interface InsideFolderProps {
    selectedFolderData: any[]
    studentFolders: string[]
    selectedFolderString?: string
    folderId: string
}

const InsideFolder = ({
    selectedFolderData,
    studentFolders,
    selectedFolderString,
    folderId,
}: InsideFolderProps) => {
    const { xs } = Grid.useBreakpoint()
    const isMobile = xs
    const user = useAppSelector((state) => state.auth.user, shallowEqual)
    const [previewModal, setPreviewModal] = useState(false)
    const [previewFile, setPreviewFile] = useState({
        creation: 0,
        id: '',
        link: '',
        name: '',
        size: 0,
        type: '',
    })
    const [files, setFiles] = useState<any[]>([])
    const [openDisable, setOpenDisable] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [toDeleteData, setToDeleteData] = useState<any>({
        tutorId: user.id,
        id: '',
        folderName: '',
        fileType: '',
        fileName: '',
        fileUrl: '',
    })
    const [renameFileData, setRenameFileData] = useState<any>(null)

    const previewFileHandler = (selectedFile: EachFileProps) => {
        setPreviewFile(selectedFile)
        setPreviewModal(true)
    }

    const moveFolderHandler = async (folderName: string, selectedFile: any) => {
        setOpenDisable(true)
        const folderMoved = { from: selectedFolderString, to: folderName }
        // const resp = await MoveTutorFile(user.id || user.uid, folderMoved, selectedFile, "student")
        // if (resp === "success") {
        //     setOpenDisable(false)
        //     notification.success({
        //         message: `file moved successfully.`,
        //         duration: 3
        //     })
        // } else {
        //     setOpenDisable(false)
        //     notification.error({
        //         message: `Something went wrong. ${resp}`,
        //         duration: 5
        //     })
        // }
    }

    const openModalHandler = (file: any) => {
        setOpenDeleteModal(true)
        setToDeleteData({
            ...toDeleteData,
            ...file,
            folderId,
        })
    }

    const renameOnSaveHandler = async (
        id: string,
        name: string,
        type: string
    ) => {
        if (renameFileData) {
            await RenameFile({
                id,
                name: name,
            })
            const newFiles = [...files]
            const idx = newFiles.findIndex((file) => file.id === id)
            newFiles[idx].name = name
            newFiles[idx].modified = moment().unix()
            setFiles(newFiles)
            setRenameFileData(null)
            message.success('Renamed successfully')
        }
    }

    const cancelHandler = () => {
        setPreviewModal(false)
        setPreviewFile({
            creation: 0,
            id: '',
            link: '',
            name: '',
            size: 0,
            type: '',
        })
    }
    const handleGetFiles = async () => {
        const file = (await GetFiles(folderId, user.activeTeam)) as any
        // eslint-disable-next-line no-console
        console.log(file)
        setFiles(file)
    }
    useEffect(() => {
        // eslint-disable-next-line no-console
        handleGetFiles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folderId, user.activeTeam])

    return (
        <>
            {files.length > 0 ? (
                files.map((eachFile) => (
                    <Row
                        key={eachFile.id}
                        align="middle"
                        justify="space-between"
                        style={{
                            border: '1px solid #000',
                            padding: '13px 15px',
                            marginBottom: '10px',
                            position: 'relative',
                        }}
                    >
                        <Col span="8">
                            {renameFileData &&
                            renameFileData.id === eachFile.id ? (
                                <Input
                                    value={renameFileData.name}
                                    onChange={(e) =>
                                        setRenameFileData({
                                            ...renameFileData,
                                            name: e.target.value,
                                        })
                                    }
                                    autoFocus
                                    onBlur={() =>
                                        renameOnSaveHandler(
                                            eachFile.id,
                                            renameFileData.name,
                                            eachFile.type
                                        )
                                    }
                                    onPressEnter={() =>
                                        renameOnSaveHandler(
                                            eachFile.id,
                                            renameFileData.name,
                                            eachFile.type
                                        )
                                    }
                                />
                            ) : (
                                <Row align="middle">
                                    <Col>
                                        {eachFile.type &&
                                            iconCheckerHandler(eachFile.type)}
                                    </Col>
                                    <Col
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        <Text
                                            onClick={() =>
                                                window.open(
                                                    eachFile.fileUrl,
                                                    '_blank'
                                                )
                                            }
                                            // onClick={() =>
                                            //     previewFileHandler(eachFile)
                                            // }
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {eachFile.name}
                                        </Text>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col span="5">
                            <Text italic={eachFile && !eachFile.course}>
                                {eachFile.course || 'Manual upload'}
                            </Text>
                        </Col>
                        <Col span="3">
                            {eachFile.size
                                ? computeBytesHandler(eachFile.size)
                                : ''}
                        </Col>
                        <Col span="5">
                            {eachFile.modified
                                ? moment
                                      .unix(eachFile.modified)
                                      .format('MMM DD, YYYY h:mma')
                                : moment
                                      .unix(eachFile.creation)
                                      .format('MMM DD, YYYY h:mma')}
                        </Col>
                        <Col span="1">
                            <Dropdown
                                disabled={openDisable}
                                overlay={
                                    <Menu
                                        style={{
                                            width: isMobile ? 'unset' : '150px',
                                        }}
                                    >
                                        <Item
                                            key="downloadFile"
                                            icon={<CloudDownloadOutlined />}
                                            onClick={() =>
                                                window.open(
                                                    eachFile.fileUrl,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            Download
                                        </Item>
                                        <Item
                                            key="renameFile"
                                            icon={<EditOutlined />}
                                            onClick={() =>
                                                setRenameFileData(eachFile)
                                            }
                                        >
                                            Rename
                                        </Item>
                                        {/* <Menu.SubMenu
                                            key="moveFolder"
                                            title="Move to"
                                            icon={<MdDriveFileMoveOutline />}
                                        >
                                            {studentFolders &&
                                            studentFolders.length > 0 ? (
                                                <>
                                                    <Menu.Item
                                                        key="Upper folder"
                                                        onClick={() =>
                                                            moveFolderHandler(
                                                                'parentFolder',
                                                                eachFile
                                                            )
                                                        }
                                                    >
                                                        <BsArrowUpLeftCircle
                                                            style={{
                                                                marginRight:
                                                                    '5px',
                                                            }}
                                                        />
                                                        <Text>
                                                            Upper folder
                                                        </Text>
                                                    </Menu.Item>
                                                    {studentFolders.map(
                                                        (eachFolder: any) => (
                                                            <Menu.Item
                                                                key={
                                                                    eachFolder.id
                                                                }
                                                                disabled={
                                                                    eachFolder ===
                                                                    selectedFolderString
                                                                }
                                                                style={{}}
                                                                onClick={() =>
                                                                    moveFolderHandler(
                                                                        eachFolder,
                                                                        eachFile
                                                                    )
                                                                }
                                                            >
                                                                <Text
                                                                    key={
                                                                        eachFolder.id
                                                                    }
                                                                    style={{
                                                                        color:
                                                                            eachFolder.id ===
                                                                            selectedFolderString
                                                                                ? '#ccc'
                                                                                : 'unset',
                                                                    }}
                                                                >
                                                                    {
                                                                        eachFolder.folderName
                                                                    }
                                                                </Text>
                                                            </Menu.Item>
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                <Menu.Item key="emptyFiles">
                                                    <Text disabled={true}>
                                                        Empty folder
                                                    </Text>
                                                </Menu.Item>
                                            )}
                                        </Menu.SubMenu> */}
                                        <Item
                                            key="deleteFile"
                                            icon={<DeleteOutlined />}
                                            onClick={() =>
                                                openModalHandler(eachFile)
                                            }
                                        >
                                            Delete
                                        </Item>
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <FiMoreVertical
                                    style={{ cursor: 'pointer', width: '100%' }}
                                />
                            </Dropdown>
                        </Col>
                    </Row>
                ))
            ) : (
                <Row justify="center">
                    <Empty description="No files" />
                </Row>
            )}

            <FilesDeleteModal
                toDeleteData={toDeleteData}
                setOpenDeleteModal={setOpenDeleteModal}
                openDeleteModal={openDeleteModal}
                setToDeleteData={setToDeleteData}
                studentOrTutor="student"
            />
            {previewModal && (
                <PreviewFilesModal
                    previewModal={previewModal}
                    eachFile={previewFile}
                    cancelHandler={cancelHandler}
                />
            )}
        </>
    )
}

export default InsideFolder
