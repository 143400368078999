import { auth, db } from '../../../app/boot'
import { Team, TeamMember, TeamPermission } from '../../../domain/entities/Team'
import { TeamRepository } from '../../../domain/repositories/team.repository'
import {
    doc,
    setDoc,
    getDoc,
    collection,
    getDocs,
    updateDoc,
    query,
    where,
    increment,
    onSnapshot,
    addDoc,
    arrayUnion,
} from 'firebase/firestore'
import { firebaseAdd, getErrorMessage } from '../default'
import moment from 'moment'
import { message } from 'antd'
import {
    EmployementStatus,
    MEMBERS_REPOSITORY,
    PERSONNEL_REPOSITORY,
    TEAM_REPOSITORY,
} from '../constants'
import { Member } from '../../../domain/entities/Member'

export class TeamFirebaseImplementation implements TeamRepository {
    async createTeam(payload: Team): Promise<any> {
        payload = firebaseAdd(payload)
        const ref = doc(db, TEAM_REPOSITORY, payload.partnerCode)
        const docRef = await getDoc(ref)
        if (docRef.exists()) {
            return false
        }

        await setDoc(ref, payload)
        if (payload.ownerUid) {
            const ownerRef = doc(db, PERSONNEL_REPOSITORY, payload.ownerUid)
            await updateDoc(ownerRef, {
                myTeams: arrayUnion(payload.partnerCode as string),
            })
        }
        return true
    }
    async getMyTeams(payload: string[]): Promise<Team[]> {
        const teams = payload.map(async (teamId) => {
            const snap = await getDoc(doc(db, TEAM_REPOSITORY, teamId))
            return {
                ...snap.data(),
                id: snap.id,
            } as Team
        })
        return await Promise.all(teams)
    }
    getMyTeam(teamId: string, callback: (payload: Team) => void): () => void {
        // const teams = payload.map(async (teamId) => {
        // const data = await getDoc(ref)
        // return {
        //     ...snap.data(),
        //     id: snap.id,
        // } as Team
        // })
        // return await Promise.all(teams)
        const ref = doc(db, TEAM_REPOSITORY, teamId)
        return onSnapshot(ref, (doc) => {
            const data = doc.data()

            if (!data) return
            callback && callback(data as Team)
        })
    }

    async getTeamMembers(partnerCode: string, payload: any): Promise<Member[]> {
        const ref = collection(db, MEMBERS_REPOSITORY)
        let q = query(
            ref,
            where('partnerCode', '==', partnerCode),
            where('isRemoved', '==', false)
        )
        // const year = moment()
        //     .set('year', parseInt(payload.currentYear))
        //     .startOf('year')

        const snap = await getDocs(q)
        const data: any = snap.docs.map(async (docSnap) => {
            const memberData = docSnap.data()

            let payload = {
                ...memberData,
                id: docSnap.id,
            }
            if (memberData.memberId) {
                const userSnap = await getDoc(
                    doc(db, 'users', memberData.memberId)
                )
                if (userSnap.exists()) {
                    payload = {
                        ...userSnap.data(),
                        ...payload,
                    }
                }
            }
            return payload
        })
        const promises = await Promise.all(data)
        return promises
    }

    async getTeamGroupMembers(
        partnerCode: string,
        payload: any
    ): Promise<Member[]> {
        const ref = collection(db, PERSONNEL_REPOSITORY)
        let q = query(
            ref,
            where('partnerCode', '==', partnerCode),
            where('isRemoved', '==', false)
        )

        // const year = moment()
        //     .set('year', parseInt(payload.currentYear))
        //     .startOf('year')

        const snap = await getDocs(q)
        const data: any = snap.docs.map(async (docSnap) => {
            const memberData = docSnap.data()

            let payload = {
                ...memberData,
                id: docSnap.id,
            }
            // eslint-disable-next-line no-console
            // console.log(docSnap.id, memberData.memberId)

            // if (memberData.memberId) {
            //     const userSnap = await getDoc(
            //         doc(db, 'users', memberData.memberId)
            //     )
            //     if (userSnap.exists()) {
            //         payload = {
            //             ...userSnap.data(),
            //             ...payload,
            //         }
            //     }
            // }
            return payload
        })
        const promises = await Promise.all(data)
        return promises
    }
    getTeamMemberPermission(
        payload: {
            partnerCode: string
            memberId: string
        },
        callback: (permission: TeamPermission) => void
    ): () => void {
        const ref = collection(
            db,
            TEAM_REPOSITORY,
            payload.partnerCode,
            'members'
        )
        const q = query(
            ref,
            where('isRemoved', '==', false),
            where('memberId', '==', payload.memberId)
        )
        // const snap = await getDocs(q)
        // const data: any = snap.docs.map(async (docSnap) => {
        return onSnapshot(q, (doc) => {
            if (doc.empty) {
                callback &&
                    callback({
                        role: 'viewer',
                        status: 'pending',
                        isRemoved: true,
                        dateAccepted: 0,
                        memberId: '',
                    })
                return
            }
            const data = doc.docs[0].data()
            const permission: TeamPermission = {
                role: data.role,
                status: data.status,
                isRemoved: data.isRemoved,
                dateAccepted: data.dateAccepted,
                memberId: doc.docs[0].id,
            }
            callback && callback(permission)
        })
        // })
        // return await Promise.all(data)
    }
    async addTeamMember(
        payload: Member,
        partnerCode: string
    ): Promise<boolean | string> {
        payload = firebaseAdd(payload)
        await addDoc(collection(db, 'members'), payload)
        return true
    }
    async removeTeamMember(
        memberId: string,
        status: EmployementStatus
    ): Promise<boolean> {
        // payload = firebaseAdd(payload)
        await updateDoc(doc(db, PERSONNEL_REPOSITORY, memberId), {
            isRemoved: false,
            status: status,
            modified: moment().unix(),
            modifiedBy: auth?.currentUser?.uid,
        })
        return true
    }
    async updateTeamMember(
        payload: Partial<TeamMember> & {
            id: string
            partnerCode: string
        }
    ): Promise<boolean> {
        // payload = firebaseAdd(payload)
        const { id, partnerCode, ...rest } = payload
        // eslint-disable-next-line no-console
        console.log(id, partnerCode, rest)

        await updateDoc(doc(db, TEAM_REPOSITORY, partnerCode, 'members', id), {
            modified: moment().unix(),
            modifiedBy: auth?.currentUser?.uid,
            ...rest,
        })
        return true
    }
    async updateTeam(
        payload: Partial<Team> & {
            id: string
        }
    ): Promise<boolean> {
        const { id, ...rest } = payload
        await updateDoc(doc(db, TEAM_REPOSITORY, id), {
            modified: moment().unix(),
            modifiedBy: auth?.currentUser?.uid,
            ...rest,
        })
        return true
    }
    async getTeamMember(payload: {
        memberId: string
        partnerCode: string
    }): Promise<TeamMember | undefined> {
        // payload = firebaseAdd(payload)
        const { memberId, partnerCode } = payload
        const snap = await getDoc(
            doc(db, TEAM_REPOSITORY, partnerCode, 'members', memberId)
        )
        if (!snap.exists()) return undefined
        return {
            ...snap.data(),
            id: snap.id,
        } as TeamMember
    }
    async addRemainingBalanceToSystem(
        partnerCode: string,
        yearOfLoan: string,
        monthOfLoan: string,
        amount: number
    ) {
        try {
            const ref = doc(db, TEAM_REPOSITORY, partnerCode)

            await updateDoc(ref, {
                [`remainingBalance.${yearOfLoan}.Annual`]: increment(amount),
                [`remainingBalance.${yearOfLoan}.${monthOfLoan}`]:
                    increment(amount),
            })
            return true
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
    }

    async addLoanToTeam(
        yearOfLoan: string,
        monthOfLoan: string,
        partnerCode: string,
        amount: number,
        remainingBalance: number
    ) {
        // const key = 'system-settings'
        // const sysData: SystemSettings = this.getStorage(key)
        // if (sysData) {
        //     if (!sysData.overallLoan[yearOfLoan]) {
        //         sysData.overallLoan[yearOfLoan] = 0
        //     }
        //     sysData.overallLoan[yearOfLoan] += amount
        //     if (!sysData.remainingBalance[yearOfLoan]) {
        //         sysData.remainingBalance[yearOfLoan] = 0
        //     }
        //     sysData.remainingBalance[yearOfLoan] += remainingBalance
        //     this.setStorage(key, sysData)
        // }

        try {
            const ref = doc(db, TEAM_REPOSITORY, partnerCode)

            await updateDoc(ref, {
                [`remainingBalance.${yearOfLoan}.Annual`]:
                    increment(remainingBalance),
                [`remainingBalance.${yearOfLoan}.${monthOfLoan}`]:
                    increment(remainingBalance),
                [`overallLoan.${yearOfLoan}.Annual`]: increment(amount),
                [`overallLoan.${yearOfLoan}.${monthOfLoan}`]: increment(amount),
            })
            return true
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
    }

    async addIncomeToTeam(
        partnerCode: string,
        yearOfPayment: string,
        monthOfPayment: string,
        incomeVal: number,
        principalVal: number,
        remainingVal: number
    ) {
        try {
            const teamRef = doc(db, TEAM_REPOSITORY, partnerCode)

            await updateDoc(teamRef, {
                modified: moment().unix(),
                modifiedBy: auth?.currentUser?.uid,
                [`overallIncome.${yearOfPayment}.Annual`]: increment(incomeVal),
                [`overallIncome.${yearOfPayment}.${monthOfPayment}`]:
                    increment(incomeVal),
                [`overallPrincipal.${yearOfPayment}.Annual`]:
                    increment(principalVal),
                [`overallPrincipal.${yearOfPayment}.${monthOfPayment}`]:
                    increment(principalVal),
                [`remainingBalance.${yearOfPayment}.Annual`]:
                    increment(remainingVal),
                [`remainingBalance.${yearOfPayment}.${monthOfPayment}`]:
                    increment(remainingVal),
            })
            return true
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
    }

    async addUnpaidInterestToSystem(
        partnerCode: string,
        yearOfPayment: string,
        monthOfPayment: string,
        amount: number,
        remove?: boolean
    ) {
        const value = remove ? -amount : amount
        await updateDoc(doc(db, TEAM_REPOSITORY, partnerCode), {
            modified: moment().unix(),
            modifiedBy: auth?.currentUser?.uid,
            [`overallUnpaidInterest.${yearOfPayment}.Annual`]: increment(value),
            [`overallUnpaidInterest.${yearOfPayment}.${monthOfPayment}`]:
                increment(value),
        })
        return true
        // const key = 'system-settings'
        // const sysData = this.getStorage(key)
        // if (sysData) {
        //     if (!sysData.overallUnpaidInterest[yearOfPayment]) {
        //         sysData.overallUnpaidInterest[yearOfPayment] = 0
        //     }
        //     if (!remove) {
        //         sysData.overallUnpaidInterest[yearOfPayment] += amount
        //     } else {
        //         sysData.overallUnpaidInterest[yearOfPayment] -= amount
        //     }
        //     this.setStorage(key, sysData)
        // }
    }

    async addShareToTeam(
        yearOfShare: string,
        monthOfShare: string,
        partnerCode: string,
        amount: number,
        weightedShare: number
    ) {
        try {
            const ref = doc(db, TEAM_REPOSITORY, partnerCode)

            await updateDoc(ref, {
                [`memberShares.${yearOfShare}.Annual`]: increment(amount),
                [`memberShares.${yearOfShare}.${monthOfShare}`]:
                    increment(amount),
                [`weightedMemberShares.${yearOfShare}.Annual`]:
                    increment(weightedShare),
                [`weightedMemberShares.${yearOfShare}.${monthOfShare}`]:
                    increment(weightedShare),
            })
            return true
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
    }
}
