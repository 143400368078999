import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExpenseFirebaseImplementation } from '../../../data/repositories/expense/expense.firebase'
import { Expense } from '../../../domain/entities/SystemSettings'
import { ExpenseService } from '../../../domain/usecases/expense.usecase'

interface InitState {
    expenses: Expense[]
}
const initialState: InitState = {
    expenses: [],
}

const localImpl = new ExpenseFirebaseImplementation()

export const addExpense = createAsyncThunk(
    'expense/addExpense',
    async (payload: Expense) => {
        const loanService = new ExpenseService(localImpl)
        return await loanService.AddExpense(payload)
    }
)

export const createCategory = createAsyncThunk(
    'expense/addExpense',
    async (payload: Expense) => {
        const loanService = new ExpenseService(localImpl)
        return await loanService.createCategory(payload)
    }
)

export const getTeamExpenses = createAsyncThunk(
    'expense/getTeamExpenses',
    async (payload: string) => {
        const loanService = new ExpenseService(localImpl)
        return await loanService.getTeamExpenses(payload)
    }
)

export const expenseSlice = createSlice({
    name: 'style',
    initialState,
    reducers: {
        updateExpenseState: (
            state: { [key: string]: any },
            action: PayloadAction<{
                name: string
                value: any
            }>
        ) => {
            state[action.payload.name] = action.payload.value
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addExpense.fulfilled, (state, action) => {
            if (!action.payload) return
            state.expenses = [...state.expenses, action.payload]
        })
        builder.addCase(getTeamExpenses.fulfilled, (state, action) => {
            if (!action.payload) return
            state.expenses = action.payload
        })
    },
})

export const { updateExpenseState } = expenseSlice.actions
