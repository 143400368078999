import {
    PlusOutlined,
    DeleteOutlined,
    EditOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import { ProCard, ProColumns } from '@ant-design/pro-components'
import {
    Row,
    Col,
    Button,
    Statistic,
    Typography,
    Modal,
    Input,
    Dropdown,
    Menu,
    // Tooltip,
    Grid,
} from 'antd'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { Share } from '../../../domain/entities/Share'
import { db } from '../../boot'
import Table from '../../components/Table'
import {
    deleteShare,
    // getTeamShares,
    updateSharesState,
} from '../../redux/shares/shares.slice'
import { useAppDispatch, useAppSelector } from '../../store'
import AddShare from '../Member/Shares/AddShare'
import UpdateShare from '../Member/Shares/UpdateShare'
// import { MemberReceivableReportSummary } from '../Reports/MembersReceivableReport/MembersReceivableReport'
const shareColumns: ProColumns<Share>[] = [
    {
        title: 'Date Purchased',
        dataIndex: 'creation',
        defaultSortOrder: 'ascend',
        render: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            const date = moment.unix(text)
            return `${date.format('MMMM DD, YYYY ')}`
        },
    },
    {
        title: 'Name',
        dataIndex: 'displayName',
        renderText: (
            text: any,
            record: any
            // index: number,
            // action: ProCoreActionType
        ) => {
            return text || record.memberId
        },
    },
    {
        title: 'Share Amount',
        dataIndex: 'amount',
        sorter: {
            compare: (a: any, b: any) => b.amount - a.amount,
        },
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    // {
    //     title: 'Share Type',
    //     dataIndex: 'type',
    // },
]

const SharesContainer = () => {
    const {
        shares,
        styles,
        // currentYear,
        // currentTeamPermission,
        activeTeam,
        systemData,
        members,
    } = useAppSelector(
        (state) => ({
            activeTeam: state.auth.user.activeTeam,
            shares: state.shares.shares,
            members: state.team.currentTeamMembers,
            styles: state.styles,
            // currentTeamPermission: state.team.currentTeamPermission,
            // currentYear: state.systemConfig.currentYear,
            systemData: state.team.selectedTeamData,
        }),
        shallowEqual
    )
    // const [, setInterestPaidPerUser] = useState(0)
    // const [, setLoanAmount] = useState(0)
    // const [currentData, setCurrentData] =
    //     useState<MemberReceivableReportSummary>({
    //         totalShares: 0,
    //         totalIncome: 0,
    //         totalExpenses: 0,
    //         totalRemainingBalance: 0,
    //         totalUnpaidInterest: 0,
    //         totalWeightedShares: 0,
    //         totalPrincipalPaid: 0,
    //         overallLoan: 0,
    //     })
    // const [, setCashInHand] = useState(0)
    // const [filteredLoans, setFilteredLoans] = useState<Loan[]>([])
    // const { xs } = Grid.useBreakpoint()

    const dispatch = useAppDispatch()

    const [shareData, setShareData] = useState<any>({
        show: false,
    })
    const [toSearch, setToSearch] = useState('')
    const [loading, setLoading] = useState(false)

    // const [activeFilter, setActiveFilter] = useState('all')
    const [filteredShares, setFilteredShares] = useState<any>(shares)

    const handleSearch = (e: string) => {
        setToSearch(e)
    }
    const handleShowAddShare = () => {
        dispatch(
            updateSharesState({
                name: 'addData',
                value: {
                    show: true,
                },
            })
        )
    }

    // const handleSearch = (txt: string) => {
    //     setToSearch(txt)
    // }
    const handleEditShare = (row: Share) => {
        const member = members.find((member) => member.id === row.memberId)

        setShareData({
            ...row,
            memberName: member?.displayName || row.memberId,
            show: true,
        })
    }
    const handleCloseEditShare = () => {
        setShareData({
            show: false,
        })
    }
    const handleDeleteShare = async (row: Share) => {
        setLoading(true)
        await dispatch(deleteShare(row))
        setLoading(false)
    }

    // useEffect(() => {
    //     if (!activeTeam) return
    //     dispatch(getTeamShares(activeTeam))
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [activeTeam])

    useEffect(() => {
        if (!activeTeam) return
        // dispatch(getTeamSavings(activeTeam))
        const ref = collection(db, 'shares')
        const q = query(
            ref,
            where('partnerCode', '==', activeTeam),
            where('isRemoved', '==', false)
        )
        const unsub = onSnapshot(q, (doc) => {
            const data = doc.docs.map((docSnap) => ({
                ...docSnap.data(),
                id: docSnap.id,
            }))

            dispatch(
                updateSharesState({
                    name: 'shares',
                    value: data,
                })
            )
        })

        return () => unsub()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam])

    useEffect(() => {
        let timeout: any
        if (toSearch) {
            timeout = setTimeout(() => {
                const filtered = [...shares].filter((share: Share) => {
                    const splittedTitle = share.displayName || ''
                    return (
                        splittedTitle
                            .toLowerCase()
                            .replace(/ /g, '')
                            .includes(toSearch) ||
                        splittedTitle.toLowerCase().includes(toSearch)
                    )
                })
                setFilteredShares(filtered)
            }, 100)
        } else {
            setFilteredShares(shares)
        }
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [shares, toSearch])

    const { xs } = Grid.useBreakpoint()
    return (
        <div className="page-layout">
            {shareData.show && (
                <UpdateShare data={shareData} onClose={handleCloseEditShare} />
            )}
            <AddShare />

            <Row
            // className="divider-section"
            // justify={xs ? 'start' : 'space-evenly'}
            >
                <Col xs={12} md={6}>
                    <ProCard
                        bordered
                        style={{
                            borderRadius: 0,
                            height: '100%',

                            backgroundColor: 'transparent',
                        }}
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title="Total Share Capital"
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                            value={(
                                systemData.financialPerformance?.memberShares ||
                                0
                            ).toFixed(2)}
                            prefix="₱"
                            precision={2}
                        />
                    </ProCard>
                </Col>
                <Col xs={12} md={6}>
                    {/* <ProCard
                        bordered
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title="Preferred Shares"
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                            value={(
                                systemData.financialPerformance
                                    ?.memberPreferredShares || 0
                            ).toFixed(2)}
                            prefix="₱"
                            precision={2}
                        />
                    </ProCard> */}
                </Col>
                {/* <Col xs={12} md={6}>
                    <ProCard
                        bordered
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title="Loan Amount"
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                            value={loanAmount}
                            prefix="₱"
                            precision={2}
                        />
                    </ProCard>
                </Col> */}
            </Row>
            {/* {currentTeamPermission.role !== 'viewer' && ( */}
            <div
                style={{
                    padding: '16px 0',
                }}
            >
                <Row
                    align={'middle'}
                    gutter={[
                        {
                            xs: 6,
                            md: 12,
                        },
                        {
                            xs: 6,
                            md: 12,
                        },
                    ]}
                    wrap={false}
                >
                    <Col>
                        <Button
                            size={'large'}
                            shape={'round'}
                            onClick={() => handleShowAddShare()}
                            style={{
                                backgroundColor: styles.primary,
                                color: 'white',
                            }}
                        >
                            <PlusOutlined />
                            Share
                        </Button>
                    </Col>

                    <Col>
                        <Input
                            value={toSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                            allowClear
                            placeholder={'Search by title'}
                            suffix={
                                <Button icon={<SearchOutlined />} type="link" />
                            }
                            // addonBefore={xs ? null : <>Search name</>}
                            size="large"
                        />
                    </Col>
                </Row>
            </div>
            {/* )} */}
            {/* <div style={{ overflow: 'auto' }}> */}
            <Table
                scroll={{ x: 800 }}
                dataSource={filteredShares}
                columns={[
                    ...shareColumns,
                    {
                        title: '',
                        key: 'option',
                        valueType: 'option',
                        // hideInTable: currentTeamPermission.role === 'viewer',
                        align: 'center',
                        render: (_, row, idx) => {
                            const toReturn = [
                                {
                                    icon: (
                                        <EditOutlined
                                            key={'edit-share-payment'}
                                            style={{
                                                fontSize: 16,
                                                color: '#1890ff',
                                            }}
                                        />
                                    ),
                                    title: 'Update Share',
                                    key: 'edit-share',
                                    onClick: () => handleEditShare(row),
                                },
                                {
                                    icon: (
                                        <DeleteOutlined
                                            key={'del-share'}
                                            style={{ color: 'red' }}
                                        />
                                    ),
                                    title: 'Delete Share',
                                    key: 'delete-share',
                                    onClick: () => {
                                        const title =
                                            'Are you sure to delete this share? This cannot be undone.'

                                        Modal.confirm({
                                            centered: true,
                                            title,
                                            onOk: () => handleDeleteShare(row),
                                            autoFocusButton: 'cancel',
                                            okButtonProps: {
                                                loading,
                                            },
                                        })
                                    },
                                },
                            ]

                            return [
                                <Dropdown
                                    trigger={['click']}
                                    key="action-share"
                                    overlay={
                                        <Menu>
                                            {toReturn.map((action) => (
                                                <Menu.Item
                                                    key={action.key}
                                                    icon={action.icon}
                                                    onClick={action.onClick}
                                                >
                                                    {action.title}
                                                </Menu.Item>
                                            ))}
                                        </Menu>
                                    }
                                >
                                    <Button type="link">Action</Button>
                                </Dropdown>,
                            ]
                        },
                    },
                ]}
                // headerTitle={
                // }
                toolBarRender={false}
            />
            {/* </div> */}
        </div>
    )
}

export default SharesContainer
