import { useState } from 'react'
import {
    ModalForm,
    ProForm,
    ProFormDateTimePicker,
    ProFormDigit,
} from '@ant-design/pro-components'
import { Loan, LoanPayment } from '../../../../../domain/entities/Loan'
import { Col, Divider, Form, message, Row, Statistic, Typography } from 'antd'
import moment from 'moment'
import PreviewLoanAmortizationSchedule from '../PreviewLoanAmortizationSchedule'
import { useAppSelector } from '../../../../store'
import { calculateInterest } from '../loanHelper'
import { shallowEqual } from 'react-redux'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../boot'
import {
    LOANS_REPOSITORY,
    PAYMENTS_REPOSITORY,
} from '../../../../../data/repositories/constants'

export interface MemberLoanData extends Loan {
    prevPayment: LoanPayment
    name: string
    frequency?: string
}
interface PayLoanProp {
    data: MemberLoanData
    onCancel: () => void
    handleSuccess: () => void
}

function UpdateLoanPayment({
    data: memberLoanData,
    onCancel: handleCancel,
    handleSuccess,
}: PayLoanProp) {
    const [previewAmortization, setPreviewAmortization] = useState(false)
    const [loading, setLoading] = useState(false)
    // const handlePreviewAmortization = () => {
    //     setPreviewAmortization(true)
    // }
    const handleClosePreviewLoanPayment = () => {
        setPreviewAmortization(false)
    }
    // const [totalInterest, setTotalInterest] = useState(
    //     previousPayment.interest
    // )

    const [form] = Form.useForm()

    const { currentUser, styles } = useAppSelector(
        (state) => ({
            currentUser: state.auth.user,
            styles: state.styles,
        }),
        shallowEqual
    )
    const [previousPayment] = useState<LoanPayment>(memberLoanData.prevPayment)
    const [totalPrincipalPayment, setTotalPrincipalPayment] = useState(
        previousPayment.principal
    )
    const prevDue = previousPayment.due
    // const [paymentDate, setPaymentDate] = useState(
    //     previousPayment.creation
    // )

    const [values, setValues] = useState({
        amount: previousPayment.amountPaid,
    })
    const [totalInterestPayment, setTotalInterestPayment] = useState(
        previousPayment.interest
    )
    const handleFieldChange = (field: any) => {
        const key = Object.keys(field)[0]

        if (key === 'amount') {
            // const principalPayment = field[key] - totalInterest
            // setTotalPrincipalPayment(principalPayment)
            const interestPayment = previousPayment.currentInterest - field[key]
            if (interestPayment < 0) {
                setTotalPrincipalPayment(-interestPayment)
                setTotalInterestPayment(previousPayment.currentInterest)
            } else {
                setTotalInterestPayment(field[key])
                setTotalPrincipalPayment(0)
            }
        }

        if (key === 'principal') {
            setTotalPrincipalPayment(field[key])
        }
        if (key === 'interest') {
            setTotalInterestPayment(field[key])
        }
        if (key === 'startDate') {
            const paymentDate = moment(field[key]).unix()

            const interest = calculateInterest(
                memberLoanData.remainingBalance,
                memberLoanData.compound,
                memberLoanData.payback,
                memberLoanData.interest,
                memberLoanData.amount,
                memberLoanData.due,
                paymentDate
            )

            const totalInterest = interest + previousPayment.pendingInterest
            if (values.amount) {
                const interestPayment = totalInterest - values.amount
                if (interestPayment < 0) {
                    setTotalPrincipalPayment(-interestPayment)
                    setTotalInterestPayment(totalInterest)
                } else {
                    setTotalInterestPayment(values.amount)
                    setTotalPrincipalPayment(0)
                }
            } else {
                setTotalInterestPayment(0)
                setTotalPrincipalPayment(0)
            }
        }
        setValues({
            ...values,
            ...field,
        })
    }

    const handleSubmit = async (data: any) => {
        setLoading(true)
        let amountToPay = previousPayment.loanPenalty
            ? data.amount
            : data.interest + data.principal
        const beginningLoanBalance =
            previousPayment.endingBalance + previousPayment.principal
        // let interestPayment = calculateInterest(
        //     beginningLoanBalance,
        //     memberLoanData.compound,
        //     memberLoanData.interest,
        //     memberLoanData.amount,
        //     moment.unix(memberLoanData.due).add(-1, 'month').unix()
        // )

        const creationDate =
            currentUser.type !== 'staff'
                ? moment(data.startDate).unix()
                : previousPayment.creation

        let principalPayment = Math.fround(totalPrincipalPayment)
        const endingBalance = Math.fround(
            beginningLoanBalance - principalPayment
        )
        // const totalInterest = previousPayment.pendingInterest

        let paidPendingInterest = 0
        // let newPendingInterest = 0
        // if (totalInterest) {
        //     if (previousPayment.pendingInterest < totalInterestPayment) {
        //         paidPendingInterest =
        //             previousPayment.pendingInterest - totalInterestPayment
        //         paidPendingInterest = totalInterest - paidPendingInterest
        //         newPendingInterest =
        //             previousPayment.pendingInterest - paidPendingInterest
        //     } else {
        //         paidPendingInterest = totalInterestPayment
        //         newPendingInterest = totalInterest - totalInterestPayment
        //     }
        // }
        const payload: LoanPayment = {
            beginningBalance: beginningLoanBalance,
            interest: totalInterestPayment,
            memberId: memberLoanData.memberId,
            principal: principalPayment,
            endingBalance,
            amountPaid: amountToPay,
            creation: creationDate,
            modified: moment().unix(),
            modifiedBy: currentUser.email,
            paidPendingInterest: paidPendingInterest,
            id: previousPayment.id,
            due: previousPayment.due,
            pendingInterest: previousPayment.pendingInterest,
            currentInterest: previousPayment.currentInterest,
            loanPenalty: previousPayment.loanPenalty,
            isRemoved: false,
            partnerCode: previousPayment.partnerCode,
            loanId: memberLoanData.id as string,
            paymentDate: creationDate,
            previousPaymentDate: previousPayment.previousPaymentDate,
        }

        const paymentRef = doc(
            db,
            LOANS_REPOSITORY,
            memberLoanData.id as string,
            PAYMENTS_REPOSITORY,
            previousPayment.id as string
        )
        await updateDoc(paymentRef, {
            ...payload,
        })
        // await dispatch(
        //     updateLoanPayment({
        //         previousPayment: previousPayment,
        //         payment: payload,
        //         loan: {
        //             creation: memberLoanData.creation,
        //             memberId: memberLoanData.memberId,
        //             partnerCode: memberLoanData.partnerCode,
        //             id: memberLoanData.id as string,
        //         },
        //         newPendingInterest,
        //     })
        // )
        setLoading(false)
        // const newPayments = [...memberLoanData.payments, payload]
        // // console.log(
        // //     payload,
        // //     previousPayment,
        // //     newPendingInterest,
        // //     totalInterest,
        // //     totalInterestPayment
        // // )
        // // return
        // dispatch(
        //     updateLoan({
        //         ...memberLoanData,
        //         payments: newPayments,
        //         pendingInterest: newPendingInterest,
        //     })
        // )
        message.success('Payment successfully updated.')

        handleSuccess()
        handleCancel()
    }

    // useEffect(() => {
    //     const interest = calculateInterest(
    //         memberLoanData.payments[memberLoanData.payments.length - 1]
    //             .endingBalance,
    //         memberLoanData.compound,
    //         memberLoanData.interest,
    //         memberLoanData.amount,
    //         memberLoanData.due,
    //         paymentDate
    //     )
    //
    //     const totalInterest = interest + memberLoanData.pendingInterest
    //     if (values.amount) {
    //         const interestPayment = totalInterest - values.amount
    //         if (interestPayment < 0) {
    //             setTotalPrincipalPayment(-interestPayment)
    //             setTotalInterestPayment(totalInterest)
    //         } else {
    //             setTotalInterestPayment(values.amount)
    //             setTotalPrincipalPayment(0)
    //         }
    //     } else {
    //         setTotalInterestPayment(0)
    //         setTotalPrincipalPayment(0)
    //     }
    //     setInterest(interest)
    // }, [paymentDate, values.amount])

    // useEffect(() => {
    //     if (memberLoanData.payments.length) {
    //         setPreviousPayment(
    //             memberLoanData.payments[memberLoanData.payments.length - 1]
    //         )
    //     } else {
    //         setPreviousPayment({
    //             ...previousPayment,
    //             beginningBalance: memberLoanData.amount,
    //             endingBalance: memberLoanData.amount,
    //             dueDate: memberLoanData.creation,
    //         })
    //     }
    // }, [])

    return (
        <>
            <ModalForm<Loan>
                form={form}
                onFinishFailed={(err) => {
                    if (err.errorFields.length) {
                        const firstError = err.errorFields[0]
                        message.destroy()
                        message.error(firstError.errors)
                        form.scrollToField(firstError.name, {
                            behavior: 'smooth',
                            block: 'center',
                        })
                    }
                }}
                visible
                autoFocusFirstInput
                modalProps={{
                    onCancel: handleCancel,
                    okText: 'Update',
                    centered: true,
                }}
                submitTimeout={2000}
                onFinish={handleSubmit}
                initialValues={{
                    startDate: moment.unix(previousPayment.creation),
                    loanSelection: memberLoanData.id,
                    amount: previousPayment.amountPaid,
                    interest: previousPayment.interest,
                    principal: previousPayment.principal,
                }}
                onValuesChange={handleFieldChange}
                submitter={{
                    submitButtonProps: {
                        style: {
                            backgroundColor: styles.primary,
                            color: 'white',
                        },
                        loading,
                    },
                }}
            >
                <Row wrap={false}>
                    <Col span={8}>
                        <table cellPadding={'5px 10px'}>
                            <tbody>
                                <tr>
                                    <th colSpan={2} align={'center'}>
                                        Loan Details
                                    </th>
                                </tr>
                                <tr>
                                    <td>Due Date</td>
                                    <td align={'right'}>
                                        {moment
                                            .unix(prevDue)
                                            .format('MMM DD, YYYY')}
                                    </td>
                                </tr>
                                {memberLoanData.approvalDate && (
                                    <tr>
                                        <td>Approval Date</td>
                                        <td align={'right'}>
                                            {moment
                                                .unix(
                                                    memberLoanData.approvalDate
                                                )
                                                .format('MMM DD, YYYY')}
                                        </td>
                                    </tr>
                                )}

                                {memberLoanData.disbursementDate && (
                                    <tr>
                                        <td>Disbursement Date</td>
                                        <td align={'right'}>
                                            {moment
                                                .unix(
                                                    memberLoanData.disbursementDate
                                                )
                                                .format('MMM DD, YYYY')}
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td>Percentage</td>
                                    <td align={'right'}>
                                        <Statistic
                                            value={memberLoanData.interest}
                                            suffix={'%'}
                                            prefixCls={'%'}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Amount</td>
                                    <td align={'right'}>
                                        <Statistic
                                            value={memberLoanData.amount}
                                            prefix={'₱'}
                                            prefixCls={'₱'}
                                            precision={2}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Remaining Balance</td>
                                    <td align={'right'}>
                                        <Statistic
                                            value={
                                                previousPayment.endingBalance +
                                                previousPayment.principal
                                            }
                                            prefix={'₱'}
                                            prefixCls={'₱'}
                                            precision={2}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Late Payment Penalty</td>
                                    <td align={'right'}>
                                        <Statistic
                                            value={
                                                previousPayment.pendingInterest
                                            }
                                            prefix={'₱'}
                                            prefixCls={'₱'}
                                            precision={2}
                                            valueStyle={{
                                                color:
                                                    previousPayment.pendingInterest >
                                                    0
                                                        ? '#cf1322'
                                                        : 'unset',
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Percentage</td>
                                    <td align={'right'}>
                                        {memberLoanData?.interest}%
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={2} align={'center'}>
                                        Payment Breakdown
                                    </th>
                                </tr>

                                <tr>
                                    <td>Principal</td>
                                    <td align={'right'}>
                                        <Statistic
                                            value={totalPrincipalPayment}
                                            prefix={'₱'}
                                            prefixCls={'₱'}
                                            precision={2}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Interest</td>
                                    <td align={'right'}>
                                        <Statistic
                                            value={totalInterestPayment}
                                            prefix={'₱'}
                                            prefixCls={'₱'}
                                            precision={2}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Balance after payment</td>
                                    <td align={'right'}>
                                        <Statistic
                                            value={
                                                previousPayment.endingBalance +
                                                previousPayment.principal -
                                                totalPrincipalPayment
                                            }
                                            prefix={'₱'}
                                            prefixCls={'₱'}
                                            precision={2}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/*<br />*/}
                        {/*<Row justify={'center'}>*/}
                        {/*    <Col>*/}
                        {/*        <Typography.Link*/}
                        {/*            onClick={handlePreviewAmortization}*/}
                        {/*            disabled={*/}
                        {/*                memberLoanData && memberLoanData.id ? false : true*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            View Amortization Schedule*/}
                        {/*        </Typography.Link>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Col>
                    <div style={{ height: '200px' }}>
                        <Divider type={'vertical'} style={{ height: '100%' }} />
                    </div>
                    <Col flex={'auto'}>
                        <div style={{ marginLeft: 20 }}>
                            <Typography.Title level={2}>
                                {memberLoanData.title}
                            </Typography.Title>
                        </div>
                        <Divider />
                        <div style={{ marginLeft: 20 }}>
                            {previousPayment.loanPenalty ? (
                                <ProFormDigit
                                    rules={[
                                        {
                                            required: true,
                                            type: 'number',
                                            message: 'This field is required',
                                        },
                                    ]}
                                    width="sm"
                                    name="amount"
                                    label="Amount"
                                    placeholder="Please Enter"
                                    min={0}
                                    max={
                                        previousPayment.pendingInterest +
                                        previousPayment.currentInterest +
                                        previousPayment.endingBalance
                                    }
                                />
                            ) : (
                                <ProForm.Group>
                                    <ProFormDigit
                                        rules={[
                                            {
                                                required: true,
                                                type: 'number',
                                                message:
                                                    'This field is required',
                                            },
                                        ]}
                                        width="sm"
                                        name="interest"
                                        label="Interest"
                                        placeholder="Please Enter"
                                        min={0}
                                    />
                                    <ProFormDigit
                                        rules={[
                                            {
                                                required: true,
                                                type: 'number',
                                                message:
                                                    'This field is required',
                                            },
                                        ]}
                                        width="sm"
                                        name="principal"
                                        label="Principal"
                                        placeholder="Please Enter"
                                        min={0}
                                    />
                                </ProForm.Group>
                            )}

                            {currentUser.type !== 'staff' && (
                                <ProFormDateTimePicker
                                    name={'startDate'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                    label={'Payment Date'}
                                    width={216}
                                    fieldProps={{
                                        disabledDate: (date) => {
                                            let customDate = moment
                                                .unix(memberLoanData.due)
                                                .add(1, 'day')
                                                .format('YYYY-MM-DD')
                                            return (
                                                previousPayment.loanPenalty &&
                                                date &&
                                                date >
                                                    moment(
                                                        customDate,
                                                        'YYYY-MM-DD'
                                                    )
                                            )
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </ModalForm>

            {previewAmortization && (
                <PreviewLoanAmortizationSchedule
                    handleClose={handleClosePreviewLoanPayment}
                    data={memberLoanData.amortization}
                    // monthlyPayment={monthlyPayment.toFixed(2)}
                    // numberOfPayments={numberOfPayments}
                    // totalPayment={totalPayment.toFixed(2)}
                    // totalInterest={totalInterest.toFixed(2)}
                    // tableData={tableData}
                />
            )}
        </>
    )
}

export default UpdateLoanPayment
