import '../index.css'
// import React, { useState } from 'react'
// import { Button, Col, Dropdown, Row, Typography } from 'antd'
// import { LeftOutlined, RightOutlined } from '@ant-design/icons'
// import { useAppDispatch, useAppSelector } from '../../../../store'
// import { ProFormDatePicker } from '@ant-design/pro-components'
// import { shallowEqual } from 'react-redux'
// import { updateSystemState } from '../../../../redux/system.slice'
// import { keyValueDates } from '../utils'

// const contentStyle: React.CSSProperties = {
//     color: '#fff',
//     height: '60px',
// }
// const textStyle: React.CSSProperties = {
//     color: '#fff',
//     margin: 0,
// }
// const iconStyle: React.CSSProperties = {
//     fontSize: 16,
// }
// interface MonthSelectionProps {
// onYearChange: (paload: string) => void
// onMonthChange: (payload: number) => void
// currentYear: string
// currentMonth: string
// }
function YearSelection() {
    return <></>
}
// function YearSelection1() {
//     const dispatch = useAppDispatch()
//     const { styles, currentYear } = useAppSelector(
//         (state) => ({
//             styles: state.styles,
//             currentYear: state.systemConfig.currentYear,
//         }),
//         shallowEqual
//     )
//     const [visible, setVisible] = useState(false)
//     // const [mouseEntered, setMouseEntered] = useState(false)
//     const handleClick = (number: number) => {
//         let year = parseInt(currentYear)
//         year += number
//         handleYearChange(year.toString())
//     }

//     const handleChange = (number: number) => {
//         handleYearChange(number.toString())
//     }

//     const handleYearChange = (payload: string) => {
//         dispatch(
//             updateSystemState({
//                 currentYear: payload,
//             })
//         )
//     }

//     return (
//         <Row
//             justify={'center'}
//             style={{
//                 background: styles.primary,
//                 // marginBottom: xs ? 0 : 16,
//             }}
//         >
//             <Col>
//                 <Row align={'middle'} gutter={[12, 12]}>
//                     <Col>
//                         <Button
//                             icon={
//                                 <LeftOutlined
//                                     style={{
//                                         ...iconStyle,
//                                         color: styles.primary,
//                                     }}
//                                 />
//                             }
//                             onClick={() => handleClick(-1)}
//                         />
//                     </Col>
//                     <Col>
//                         <div style={contentStyle}>
//                             <Row
//                                 style={{
//                                     height: '100%',
//                                 }}
//                                 justify={'center'}
//                                 align={'middle'}
//                             >
//                                 <Col>
//                                     <Dropdown
//                                         open={visible}
//                                         trigger={['hover']}
//                                         onOpenChange={(vis) => {
//                                             setVisible(vis)
//                                         }}
//                                         overlay={
//                                             <ProFormDatePicker.Year
//                                                 placeholder={currentYear}
//                                                 fieldProps={{
//                                                     onChange: (e) => {
//                                                         if (!e) return
//                                                         handleChange(e.year())
//                                                         setVisible(false)
//                                                     },
//                                                     style: {
//                                                         opacity: 0,
//                                                         top: -30,
//                                                         left: -50,
//                                                         width: 1,
//                                                     },
//                                                     open: visible,
//                                                     bordered: false,
//                                                 }}
//                                             />
//                                         }
//                                     >
//                                         <div>
//                                             <Typography.Title
//                                                 level={2}
//                                                 style={{
//                                                     ...textStyle,
//                                                     cursor: 'pointer',
//                                                 }}
//                                             >
//                                                 {currentYear}
//                                             </Typography.Title>
//                                         </div>
//                                     </Dropdown>
//                                 </Col>
//                             </Row>
//                         </div>
//                     </Col>

//                     <Col>
//                         <Button
//                             icon={
//                                 <RightOutlined
//                                     style={{
//                                         ...iconStyle,
//                                         color: styles.primary,
//                                     }}
//                                 />
//                             }
//                             onClick={() => handleClick(1)}
//                         />
//                     </Col>
//                 </Row>
//             </Col>
//         </Row>
//     )
// }

export default YearSelection
