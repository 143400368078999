import { message } from 'antd'
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import moment from 'moment'
import { auth, db } from '../../../app/boot'
import { Saving } from '../../../domain/entities/Saving'
import { SavingFirebaseRepository } from '../../../domain/repositories/saving.repository'
import { SAVINGS_REPOSITORY } from '../constants'
import { getErrorMessage } from '../default'

export class SavingFirebaseImplementation implements SavingFirebaseRepository {
    async AddSaving(payload: Saving) {
        // try {
        //     // const res = this.addSavingToMember(payload.memberId, payload.amount)
        //     // if (!res) {
        //     //     throw `Saving unsuccessfully added to member: ${payload.memberId}`
        //     // }
        //     const key = SAVINGS_REPOSITORY
        //     let savings = this.getStorage(key)
        //     if (!savings) {
        //         savings = []
        //     }
        //     savings.push(payload)
        //     this.setStorage(key, savings)
        //     // this.addSavingToMember(payload)
        //     this.calculateMemberSaving(payload)
        // } catch (err) {
        //     // eslint-disable-next-line no-console
        //     console.log(err)
        // }
        // return payload

        try {
            const resp = await addDoc(
                collection(db, SAVINGS_REPOSITORY),
                payload
            )
            payload.id = resp.id
            return payload
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
    }
    async getTeamSavings(partnerCode: string) {
        const ref = collection(db, SAVINGS_REPOSITORY)
        const q = query(
            ref,
            where('partnerCode', '==', partnerCode),
            where('isRemoved', '==', false),
            where('status', '==', 'active')
        )

        const snap = await getDocs(q)
        const data = snap.docs.map((docSnap) => {
            return {
                ...docSnap.data(),
                id: docSnap.id,
            } as Saving
            // let savingData = docSnap.data()
            // let user = {}
            // if (savingData.memberUid) {
            //     const userSnap = await getDoc(
            //         doc(db, 'users', docSnap.data().memberUid)
            //     )

            //     user = userSnap.data() as User
            // }
            // return {
            //     // ...userSnap.data(),
            //     ...docSnap.data(),
            //     ...user,
            //     id: docSnap.id,
            // } as Saving
        })
        // const resp = await Promise.all(data)
        return data
    }
    async DeleteSaving(id: string) {
        // const key = SAVINGS_REPOSITORY
        // const savings: Saving[] = this.getStorage(key)
        // if (savings) {
        //     const idx = savings.findIndex((saving: Saving) => saving.id === id)
        //     if (idx >= 0) {
        //         const removedSaving = savings.splice(idx, 1)[0]
        //         this.calculateMemberSaving(removedSaving, true)
        //         this.setStorage(key, savings)
        //         return id
        //     }
        // }
        // return undefined
        const ref = doc(db, SAVINGS_REPOSITORY, id)
        // await updateDoc(doc(db, SAVINGS_REPOSITORY, id), {
        //     isRemoved: true,
        //     modified: moment().unix(),
        //     modifiedBy: auth.currentUser?.uid,
        // })
        // await this.calculateMemberSaving(payload, true)
        await deleteDoc(ref)
        return id
    }
    async UpdateSaving(
        payload: Saving & {
            id: string
        }
    ) {
        await updateDoc(doc(db, SAVINGS_REPOSITORY, payload.id), {
            ...payload,
            modified: moment().unix(),
            modifiedBy: auth.currentUser?.uid,
        })
        return payload
    }
    async GetSaving(id: string) {
        const snap = await getDoc(doc(db, SAVINGS_REPOSITORY, id))
        const data = snap.data()

        return {
            ...data,
            id,
        } as Saving
    }
    async GetAllMemberSaving(payload: {
        memberId: string
        partnerCode: string
    }) {
        const { memberId, partnerCode } = payload
        const ref = collection(db, SAVINGS_REPOSITORY)
        const q = query(
            ref,
            where('memberId', '==', memberId),
            where('partnerCode', '==', partnerCode),
            where('isRemoved', '==', false)
        )
        const snap = await getDocs(q)
        const data = snap.docs.map((docSnap) => {
            // const userSnap = await getDoc(doc(db, 'users', docSnap.id))
            return {
                // ...userSnap.data(),
                ...docSnap.data(),
                id: docSnap.id,
            } as Saving
        })

        return data
    }

    // async calculateMemberSaving(payload: CalculateSaving, remove?: boolean) {
    //     try {
    //         const monthOfSaving = moment.unix(payload.creation).format('MMMM')
    //         const yearOfSaving = moment.unix(payload.creation).format('YYYY')
    //         const weights = getWeight(payload.creation) || 0
    //         const weightedAverage = weights * payload.amount
    //         const saving = remove ? -payload.amount : payload.amount
    //         const weightedSaving = remove ? -weightedAverage : weightedAverage
    //         const { partnerCode } = payload
    //         const ref = doc(
    //             db,
    //             'team',
    //             partnerCode,
    //             'members',
    //             payload.memberId
    //         )

    //         await updateDoc(ref, {
    //             [`memberSaving.${yearOfSaving}.Annual`]: increment(saving),
    //             [`memberSaving.${yearOfSaving}.${monthOfSaving}`]:
    //                 increment(saving),
    //             [`weightedMemberSaving.${yearOfSaving}.Annual`]:
    //                 increment(weightedSaving),
    //             [`weightedMemberSaving.${yearOfSaving}.${monthOfSaving}`]:
    //                 increment(weightedSaving),
    //             totalSaving: increment(saving),
    //         })
    //         await this.addSavingToTeam(
    //             yearOfSaving,
    //             monthOfSaving,
    //             partnerCode,
    //             saving,
    //             weightedSaving
    //         )
    //         return true
    //     } catch (err) {
    //         message.error(getErrorMessage(err))
    //         return false
    //     }
    // }
}
