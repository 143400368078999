import { Button, Result } from 'antd'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function SuccessOrder() {
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <Result
            status="success"
            title="Successfully Received Your Order!"
            subTitle={`Order number: ${
                location?.state?.orderId || ''
            } Cloud server configuration takes 1-5 minutes, please wait. Somone will get in touch with you!`}
            extra={[
                <div key="Home">
                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        target="_blank"
                        rel={'noopener'}
                        onClick={() => navigate('/store')}
                    >
                        HOME
                    </Button>
                </div>,
            ]}
        />
    )
}

export default SuccessOrder
