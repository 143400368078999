import { Vehicle } from '@/domain/entities/Transport'
import { EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
interface InitState {
    vehicles: EntityState<Vehicle>
    selectedVehicleId: string
}
const vehiclesAdapter = createEntityAdapter<Vehicle>({
    selectId: (book) => book.id as string,
    sortComparer: (a, b) => a.title.localeCompare(b.title),
})

const initialState: InitState = {
    vehicles: vehiclesAdapter.getInitialState(),
    selectedVehicleId: '',
}

export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        addVehicle: (state, action) => {
            vehiclesAdapter.addOne(state.vehicles, action.payload)
        },
        setAllVehicles(state, action) {
            vehiclesAdapter.upsertMany(state.vehicles, action.payload)
        },
        updateVehicleState(state, action) {
            state = {
                ...state,
                ...action.payload,
            }
            return state
        },
        restoreVehicleState(state) {
            state.vehicles = initialState.vehicles
            return state
        },
    },
})

export const {
    addVehicle,
    setAllVehicles,
    updateVehicleState,
    restoreVehicleState,
} = vehicleSlice.actions
