import './index.css'
import { ModalForm, ProCard } from '@ant-design/pro-components'
import React, { useState } from 'react'
// import { Loan } from '../../../../domain/entities/Loan'
// import { randomId } from '../../utils'
import { amortizationTableColumn, amortizationTableColumnYearly } from './utils'
import { Col, Divider, Grid, Row, Statistic, Typography } from 'antd'
// import { capitalize } from '../../utils'
import // calculateLoanInterest,
// calculateMonthlyPayment,
// calculateTotalPayments,
// generatePaymentMockSchedule,
'./loanHelper'
import { Loan } from '../../../../domain/entities/Loan'
import Table from '../../../components/Table'
import moment from 'moment'
import { TeamMember } from '../../../../domain/entities/Team'
import { AmortizationSchedule } from './ApplyLoan'
import { updateMemberState } from '../../../redux/members/members.slice'
import { useAppDispatch } from '../../../store'
// import { updateMemberState } from '../../../redux/members/members.slice'
import { useAppSelector } from '../../../store'
import { shallowEqual } from 'react-redux'
// import { calculateAmortizationScheduleV2 } from './amoritzationUtils'

interface PreviewLoanAmortizationScheduleProps {
    handleClose: () => void
    data: Loan['amortization']
    member?: TeamMember
    // monthlyPayment: number | string
    // numberOfPayments: number
    // totalPayment: number | string
    // totalInterest: number | string
    // tableData: any[]
}

function PreviewLoanAmortizationSchedule({
    handleClose,
    data,
    member,
}: // monthlyPayment,
// numberOfPayments,
// totalPayment,
// totalInterest,
// tableData,
PreviewLoanAmortizationScheduleProps) {
    // const [tableData, setTableData] = useState<any>([])
    // const [totalInterest, setTotalInterest] = useState(0)
    // const [totalPayment, setTotalPayment] = useState(0)
    // const [monthlyPayment, setMonthlyPayment] = useState(0)
    // const [numberOfPayments, setNumberOfPayments] = useState(0)

    const { previewAmortization, draftLoanData } = useAppSelector(
        (state) => ({
            draftLoanData: state.members.draftLoanData,
            previewAmortization: state.members.previewAmortization,
            // loanPenaltyUniformity: state.systemConfig.loanPenaltyUniformity,
        }),
        shallowEqual
    )

    // eslint-disable-next-line no-console
    console.log(data, draftLoanData)
    const [paymentSchedule] = useState<AmortizationSchedule>(data)
    // const calculateAmortization = (ln: Loan) => {
    //     if (!ln) return
    //     const loan = Object.assign({}, ln)
    //     if (!loan) return
    //     // const { termYears, termMonths, amount, interest, payback, compound, creation } =
    //     //     loan
    //     // const arrayOfArrays = []
    //     // const interestRate = interest / 100
    //     // const numberOfPayments: number = calculateTotalPayments({
    //     //     termYears,
    //     //     termMonths,
    //     //     payback,
    //     //     compound,
    //     // })
    //     // const numberOfPayments = termMonths
    //     // // const monthlyInterestRate = interestRate
    //     // const monthlyPayment = calculateMonthlyPayment(amount, interest, {
    //     //     termYears,
    //     //     termMonths,
    //     //     payback,
    //     //     compound,
    //     // })

    //     // // let loanAmount = amount
    //     // const totalInterestPayment = calculateLoanInterest(amount, interest, {
    //     //     termYears,
    //     //     termMonths,
    //     //     payback,
    //     //     compound,
    //     // })

    //     // const totalPayment = parseFloat((monthlyPayment * numberOfPayments).toFixed(2))
    //     // const totalMonthlyPayment = parseFloat(monthlyPayment.toFixed(2))

    //     // const totalInterest = (parseFloat(totalInterestPayment.toFixed(2)))
    //     const { totalInterest, totalPayment, monthlyPayment: totalMonthlyPayment, } = calculateAmortizationScheduleV2(loan)
    //     const schedules = loan?.amortization?.schedules || []
    //     setPaymentSchedule({
    //         totalPayment,
    //         totalMonthlyPayment,
    //         numberOfPayments: loan.termMonths,
    //         totalInterest,
    //         totalPrincipal: loan.amount,
    //         payback: loan.payback,
    //         schedules,
    //         creation: loan.creation
    //     })
    // }

    // useEffect(() => {
    //     calculateAmortization(data as Loan)
    //     // setTableData(arrayOfArrays)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [data])

    // const dispatch = useAppDispatch()
    // useEffect(() => {

    //     // calculateAmortization(data as Loan)

    //     dispatch(
    //         updateMemberState({
    //             name: 'draftLoanAmortization',
    //             value: paymentSchedule,
    //         })
    //     )
    //     // setTableData(arrayOfArrays)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [paymentSchedule])
    const { xs } = Grid.useBreakpoint()
    const dispatch = useAppDispatch()
    const onCancel = () => {
        dispatch(
            updateMemberState({
                name: 'previewAmortization',
                value: undefined,
            })
        )
        handleClose()
    }

    return (
        <ModalForm
            open={previewAmortization !== undefined}
            modalProps={{
                onCancel: onCancel,
                centered: true,
            }}
            submitter={false}
        >
            <Row justify={'start'}>
                <Col xs={12} md={6}>
                    <ProCard
                        bordered
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title={
                                <Typography.Title level={5}>
                                    Total Payment
                                </Typography.Title>
                            }
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                            value={paymentSchedule.totalPayment}
                            prefix="₱"
                            precision={2}
                        />
                    </ProCard>
                </Col>
                <Col xs={12} md={6}>
                    <ProCard
                        bordered
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title={
                                <Typography.Title level={5}>
                                    Total Principal
                                </Typography.Title>
                            }
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                            value={paymentSchedule.totalPrincipal}
                            prefix="₱"
                            precision={2}
                            formatter="number"
                        />
                    </ProCard>
                </Col>
                <Col xs={12} md={6}>
                    <ProCard
                        bordered
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title={
                                <Typography.Title level={5}>
                                    Total Interest
                                </Typography.Title>
                            }
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                            value={paymentSchedule.totalInterest}
                            prefix="₱"
                            precision={2}
                        />
                    </ProCard>
                </Col>
                <Col xs={12} md={6}>
                    <ProCard
                        bordered
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title={
                                <Typography.Title level={5}>
                                    Interest Rate
                                </Typography.Title>
                            }
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                            value={draftLoanData?.interest || 0}
                            suffix="%"
                            precision={2}
                        />
                    </ProCard>
                </Col>
                {/* <Col xs={12} md={6}>
                    <ProCard
                        bordered
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title={<Typography.Title level={5}>{capitalize(data.payback)} Payment</Typography.Title>}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                            value={paymentSchedule.totalMonthlyPayment}
                            prefix="₱"
                            precision={2}
                        />
                    </ProCard>
                </Col> */}
            </Row>
            <br />
            <Row gutter={[12, 0]}>
                {member && (
                    <Col md={12} xs={24}>
                        <Row justify="center">
                            <Col>
                                <Typography.Paragraph
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    Borrower's Information
                                </Typography.Paragraph>
                            </Col>
                        </Row>
                        <Row align={'top'} wrap={false} gutter={[12, 0]}>
                            <Col span={5}>
                                <Typography.Text>Name:</Typography.Text>
                            </Col>
                            <Col>
                                <Typography.Title level={5}>
                                    {member?.lastName}, {member?.firstName}
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row align={'top'}>
                            <Col span={5}>
                                <Typography.Text>Address:</Typography.Text>
                            </Col>
                            <Col>
                                <Typography.Title level={5}>
                                    {member?.presentAddress}
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row align={'top'}>
                            <Col span={5}>
                                <Typography.Text>Contact:</Typography.Text>
                            </Col>
                            <Col>
                                <Typography.Title
                                    level={5}
                                    style={{
                                        margin: 0,
                                    }}
                                >
                                    {member?.email}
                                </Typography.Title>

                                <Typography.Title
                                    level={5}
                                    style={{
                                        margin: 0,
                                    }}
                                >
                                    {member?.phone}
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Col>
                )}

                <Col md={12} xs={24}>
                    <Row justify="center">
                        <Col>
                            <Typography.Paragraph>
                                Installment Schedule
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                    <Row align={'top'} justify="space-between">
                        <Col span={12}>
                            <Typography.Paragraph>Terms:</Typography.Paragraph>
                        </Col>
                        <Col span={12}>
                            <Typography.Title
                                level={5}
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                {paymentSchedule.numberOfPayments}
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row align={'middle'} justify="space-between">
                        <Col span={12}>
                            <Typography.Paragraph>
                                Date granted:
                            </Typography.Paragraph>
                        </Col>
                        <Col span={12}>
                            <Typography.Title
                                level={5}
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                {moment
                                    .unix(data.creation)
                                    .format('MMMM DD, YYYY')}
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row align={'middle'} justify="space-between">
                        <Col span={12}>
                            <Typography.Paragraph>
                                Maturity:
                            </Typography.Paragraph>
                        </Col>
                        <Col span={12}>
                            <Typography.Title
                                level={5}
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                {moment
                                    .unix(data.creation)
                                    .add(paymentSchedule.numberOfPayments, 'M')
                                    .format('MMMM DD, YYYY')}
                            </Typography.Title>
                        </Col>
                    </Row>
                    {/*<Row align={'middle'}>*/}
                    {/*    <Col span={6}>*/}
                    {/*        <Typography.Paragraph>*/}
                    {/*            Loan Term:*/}
                    {/*        </Typography.Paragraph>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={8}>*/}
                    {/*        <Typography.Title*/}
                    {/*            level={4}*/}
                    {/*            style={{*/}
                    {/*                fontWeight: 'bold',*/}
                    {/*                textAlign: 'right',*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            {data.termYears*/}
                    {/*                ? `${data.termYears} year${*/}
                    {/*                      data.termYears > 1 ? 's' : ''*/}
                    {/*                  }${*/}
                    {/*                      data.termMonths*/}
                    {/*                          ? ` and ${data.termMonths} month${*/}
                    {/*                                data.termMonths > 1*/}
                    {/*                                    ? 's'*/}
                    {/*                                    : ''*/}
                    {/*                            }`*/}
                    {/*                          : ''*/}
                    {/*                  }`*/}
                    {/*                : `${data.termMonths} months`}*/}
                    {/*        </Typography.Title>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Col>
            </Row>
            <br />
            {data.payback === 'yearly' ? (
                paymentSchedule?.schedules.map((table: any, idx: number) => {
                    return (
                        <div>
                            <Divider
                                style={{
                                    margin: '10px 0',
                                }}
                            />
                            {paymentSchedule.totalPayment / 12 > 0 && (
                                <Typography.Title level={3}>
                                    Year {idx + 1}
                                </Typography.Title>
                            )}

                            <Table
                                dataSource={table}
                                columns={amortizationTableColumnYearly}
                                cardBordered
                                search={false}
                                toolBarRender={false}
                                pagination={false}
                            />
                        </div>
                    )
                })
            ) : (
                <Table
                    size={'large'}
                    dataSource={paymentSchedule?.schedules || []}
                    columns={amortizationTableColumn}
                    cardBordered
                    bordered
                    rowKey={'id'}
                    search={false}
                    toolBarRender={false}
                    pagination={false}
                    style={{
                        fontSize: 24,
                    }}
                    // rowClassName={'large-rows'}
                    // tableClassName={'large-columns'}
                />
            )}
        </ModalForm>
    )
}

export default PreviewLoanAmortizationSchedule
