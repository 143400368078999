import { memberStateInitialValues } from '../../app/pages/Member/UpdateMember'
import { initialSystemState } from '../../app/redux/system.slice'
import { User } from './default/AdminUser'
import { Member } from './Member'
import { SystemSettings } from './SystemSettings'
import { Personnel } from './Transport'

type TeamRole = 'owner' | 'editor' | 'viewer' | 'chairman'

type TeamSettings = Omit<SystemSettings, 'notice'>
export interface Team extends TeamSettings {
    name: string
    partnerCode: string
    owner: string
    id?: string
    avatar: string
    address: string
    contactInfo: string
    email: string
    phone: string
    retainedEarnings: number
    savingsAccount: string[]
    vision: string
    mission: string
    coreValues: string[]
    socialGoals: string[]
    registrationNumber: string
    registrationDate: number
    folders: any[]
    files: {
        parentFolder: any[]
    }
    loanInterestType:
        | 'reducing-balance'
        | 'straight-line'
        | 'flat'
        | 'compound'
        | 'variable'
    logoUrl: string
    headline: string
    dividendPayoutRatio: number
    dividendPayoutDate: number
    dividendFrequency: DividendPayoutFrequency
    totalPersonnel: number
    backgroundUrl: string
    cardPercentage: number
    totalMembers: number
    ownerUid: string
    product?: {
        [key: string]: {
            date: number
            total: number
        }[]
    }
    // memberIds: string[]
    // members: {
    //     [key: string]: TeamRole
    // }
    busPersonnelSalaryPercentage: boolean
    expenseCategories: any[]
}

export const dividendPayoutFrequencyTypes = [
    {
        value: 'annual',
        label: 'Annual Dividends',
        description:
            'Dividends are paid once a year, typically at the end of the fiscal year.',
    },
    {
        value: 'semi-annual',
        label: 'Semi-Annual Dividends',
        description:
            'Twice a year, usually at regular intervals such as every six months.',
    },
    {
        value: 'quarterly',
        label: 'Quarterly Dividends',
        description:
            'Dividends are paid four times a year, at the end of each quarter.',
    },
    {
        value: 'monthly',
        label: 'Monthly Dividends',
        description: 'Dividends are paid on a monthly basis.',
    },
]

export type DividendPayoutFrequency =
    | 'annual'
    | 'semi-annual'
    | 'quarterly'
    | 'monthly'

type success = 'accepted'
type pending = 'pending'
type rejected = 'rejected'
type TeamMemberStatus = success | pending | rejected

interface TeamMate
    extends Omit<
        Member,
        'email' | 'accountNumber' | 'phoneNumber' | 'tags' | 'name'
    > {
    memberId: string
    // role: TeamRole
    status: TeamMemberStatus
    isRemoved: boolean
    dateAccepted: number
}

export type TeamMember = TeamMate & Partial<User>
export type CoopMember = TeamMate &
    Partial<Personnel> & {
        role: 'regular_member'
    }
export interface TeamPermission {
    role: TeamRole
    status: TeamMemberStatus
    isRemoved: boolean
    dateAccepted: number
    memberId: string
}

const { email, accountNumber, phoneNumber, tags, name, ...restOfMemberState } =
    memberStateInitialValues
export const teamMemberInitialState = restOfMemberState

const { notice, ...restOfTeamState } = initialSystemState
export const teamInitialState = restOfTeamState

export const interestCalculations = [
    {
        value: 'straight-line',
        label: 'Straight-Line',
        description: 'Equal installment payments throughout the loan term',
    },
    {
        value: 'reducing-balance',
        label: 'Reducing Balance',
        description: 'Interest calculated on the outstanding loan balance',
    },
    {
        value: 'flat',
        label: 'Flat',
        description: 'Fixed interest amount added to each installment',
    },
    // {
    //   value: "compound",
    //   label: "Compound",
    //   description: "Interest calculated on the initial loan balance with compounding"
    // },
    // {
    //   value: "variable",
    //   label: "Variable",
    //   description: "Interest rate varies over the loan term"
    // }
]
