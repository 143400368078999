import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../boot'
import {
    EXPENSE_REPOSITORY,
    PERSONNEL_ATTENDANCE_REPOSITORY,
    PERSONNEL_REPOSITORY,
    TRIPS_REPOSITORY,
} from '../../data/repositories/constants'
import { capitalize } from '../pages/utils'
import { Expense } from '../../domain/entities/SystemSettings'
import moment, { Moment } from 'moment'
import { message } from 'antd'
// import moment from "moment"

export interface ExpenseCharting {
    chart: {
        item: string
        percent: number
        total: number
        data: {
            item: string
            percentage: string
        }[]
    }[]
    data: Expense[]
}
export function getQuarterFromDate() {
    // Ensure 'date' is a valid Moment.js object
    // if (!moment.isMoment(date)) {
    //     return "Invalid date. Please provide a valid Moment.js date object.";
    // }

    // Get the month (0-based index) from the date
    const month = moment().month()

    // Determine the quarter based on the month
    if (month >= 0 && month <= 2) {
        return 1 // Q1: January to March
    } else if (month >= 3 && month <= 5) {
        return 2 // Q2: April to June
    } else if (month >= 6 && month <= 8) {
        return 3 // Q3: July to September
    } else {
        return 4 // Q4: October to December
    }
}
export function getQuarterDates(quarter: number): [Moment, Moment] {
    // Validate the quarter input
    if (quarter < 1 || quarter > 4) {
        // eslint-disable-next-line no-throw-literal
        throw 'Invalid quarter. Please provide a quarter between 1 and 4.'
    }

    // Calculate the start and end dates of the quarter
    const year = moment().year() // Get the current year
    let startDate, endDate

    switch (quarter) {
        case 1:
            startDate = moment(`${year}-01-01 00:00:00`, 'YYYY-MM-DD HH:mm:ss')
            endDate = moment(`${year}-03-31 23:59:59`, 'YYYY-MM-DD HH:mm:ss')
            break
        case 2:
            startDate = moment(`${year}-04-01 00:00:00`, 'YYYY-MM-DD HH:mm:ss')
            endDate = moment(`${year}-06-30 23:59:59`, 'YYYY-MM-DD HH:mm:ss')
            break
        case 3:
            startDate = moment(`${year}-07-01 00:00:00`, 'YYYY-MM-DD HH:mm:ss')
            endDate = moment(`${year}-09-30 23:59:59`, 'YYYY-MM-DD HH:mm:ss')
            break
        case 4:
            startDate = moment(`${year}-10-01 00:00:00`, 'YYYY-MM-DD HH:mm:ss')
            endDate = moment(`${year}-12-31 23:59:59`, 'YYYY-MM-DD HH:mm:ss')
            break
    }
    if (!startDate || !endDate) {
        startDate = moment(`${year}-01-01 00:00:00`, 'YYYY-MM-DD HH:mm:ss')
        endDate = moment(`${year}-03-31 23:59:59`, 'YYYY-MM-DD HH:mm:ss')
    }

    // Convert the dates to Unix timestamps
    const startUnix = startDate
    const endUnix = endDate

    // Return the start and end dates as Unix timestamps
    return [startUnix, endUnix]
}

export const deleteExpenseScript = async (partnerCode: string) => {
    const ref = collection(db, PERSONNEL_REPOSITORY)
    const q = query(ref, where('partnerCode', '==', partnerCode))
    const snapshots = await getDocs(q)
    snapshots.docs.forEach(async (doc) => {
        const data = doc.data()
        if (data.partnerCode === 'gofetco') {
            // eslint-disable-next-line no-console
            // await deleteDoc(doc.ref)
            // await updateDoc(doc.ref, {
            //     salaryBalance: 0
            // })
            // eslint-disable-next-line no-console
            console.log(doc.ref.path, 'update')
        }
    })
}
export function cleanString(inputString: string) {
    return inputString.replace(/\./g, ' ')
}

export const tripScript = async (
    partnerCode?: string,
    start?: number,
    end?: number
) => {
    const ref = collection(db, TRIPS_REPOSITORY)

    const q = query(ref, where('modifiedBy', '==', 'system-import-2'))
    const snapshots = await getDocs(q)
    // eslint-disable-next-line no-console
    console.log(snapshots.size)
    snapshots.forEach(async (docSnap) => {
        const data = docSnap.data()
        const tripId = docSnap.id
        // eslint-disable-next-line no-console
        console.log(data, tripId)
        // await deleteDoc(docSnap.ref)
        message.success('Deleted')
    })
}

export const attendanceScript = async (
    partnerCode?: string,
    start?: number,
    end?: number
) => {
    const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)

    const q = query(ref, where('modifiedBy', '==', 'system-import-2'))
    const snapshots = await getDocs(q)
    // eslint-disable-next-line no-console
    console.log(snapshots.size)
    snapshots.forEach(async (docSnap) => {
        const data = docSnap.data()
        const tripId = docSnap.id
        // eslint-disable-next-line no-console
        console.log(data, tripId)
        // await deleteDoc(docSnap.ref)
        message.success('Deleted')
    })
}

export const deductionScript = async (
    partnerCode?: string,
    start?: number,
    end?: number
) => {
    const ref = collection(db, PERSONNEL_REPOSITORY)

    const q = query(ref, where('partnerCode', '==', 'orotsco'))
    const snapshots = await getDocs(q)
    // eslint-disable-next-line no-console
    console.log(snapshots.size)
    snapshots.forEach(async (docSnap) => {
        const data = docSnap.data()
        const tripId = docSnap.id
        // eslint-disable-next-line no-console
        console.log(data, tripId)
        // await updateDoc(docSnap.ref, {
        //     salaryBalance: 0,
        // })
        message.success('Updated')
    })
}

export const capExScript = async (partnerCode: string) => {
    const ref = collection(db, EXPENSE_REPOSITORY)
    let q = query(
        ref,
        where('isCapEx', '==', true),
        where('partnerCode', '==', partnerCode),
        where('isRemoved', '==', false)
    )
    const snapshots = await getDocs(q)
    // const yearlyExpense: any = {}
    const totallyExpense: any = {}
    let totalExpenses = 0
    // let totalRevenue = 0
    // const labels: any = {

    // }
    const dataCategory: any = {}
    const allCategories: any = []
    snapshots.forEach((snap) => {
        const data = snap.data() as Expense
        // const monthly = moment.unix(data.requestDate).format('MMM-YYYY')
        const name =
            data.type === 'payable' ? 'totalExpense' : 'totalCollection'
        // eslint-disable-next-line no-console
        // console.log(name, snap.id, data.amount);
        if (name === undefined) return
        const category = data.category
        const amount = data.amount
        if (name === 'totalExpense') {
            if (!totallyExpense[category]) {
                totallyExpense[category] = 0
            }
            totallyExpense[category] += amount
            totalExpenses += amount
        }
        // annually
        if (!dataCategory[category]) {
            dataCategory[category] = []
        }
        dataCategory[category].push(data)
        allCategories.push({
            ...data,
            id: snap.id,
        })
        // if(!yearlyExpense[monthly][name]){
        //     yearlyExpense[monthly][name] = 0
        // }
        // if(!yearlyExpense[monthly][category]){
        //     yearlyExpense[monthly][category] = 0
        // }

        // yearlyExpense[monthly][category] += amount
        // yearlyExpense[monthly][name] += amount

        // total
        // totally[name] += amount
    })

    let totalOthers = 0
    let totalOthersAmount = 0
    const prepare = Object.keys(totallyExpense)
        .map((category) => {
            const totalAmount = totallyExpense[category]
            const percentage = totalAmount / totalExpenses
            if (percentage <= 0.02) {
                totalOthers += percentage
                totalOthersAmount += totalAmount
                return false
            }
            let subObj: any = {}
            dataCategory[category].forEach((data: Expense) => {
                if (!subObj[data.title]) {
                    subObj[data.title] = 0
                }
                subObj[data.title] += data.amount
            })

            const subDataTotal = Object.keys(subObj).map((vendorKey) => {
                const total = subObj[vendorKey]

                return {
                    item: vendorKey,
                    total,
                }
            })
            const subDataChart = subDataTotal.map(
                (data: { item: string; total: number }) => {
                    // const subPercent = data.total / subTotal
                    return {
                        item: data.item,
                        total: data.total,
                    }
                }
            )
            // const subTotalDataChart = subDataChart.map((data: {item: string, total: number}) => {
            //     // const subPercent = data.total / subTotal
            //     return {
            //         item: data.item,
            //         data: data.total
            //     }
            // })

            // eslint-disable-next-line no-console
            // console.log(subDataChart, subDataTotal, subObj)
            return {
                item: capitalize(category),
                percent: percentage,
                total: totalAmount,
                data: subDataChart,
            }
        })
        .filter(Boolean)

    return {
        chart: [
            ...prepare,
            {
                item: 'others',
                percent: totalOthers,
                total: totalOthersAmount,
            },
        ] as any,
        data: allCategories,
    }
}
export const expenseScript = async (
    partnerCode: string,
    start: number,
    end: number,
    category: string
): Promise<ExpenseCharting> => {
    const ref = collection(db, EXPENSE_REPOSITORY)
    let q
    if (category !== 'all') {
        q = query(
            ref,
            where('requestDate', '>=', start),
            where('requestDate', '<=', end),
            where('partnerCode', '==', partnerCode),
            where('isRemoved', '==', false),
            where('category', '==', category)
        )
    } else {
        q = query(
            ref,
            where('requestDate', '>=', start),
            where('requestDate', '<=', end),
            where('partnerCode', '==', partnerCode),
            where('isRemoved', '==', false)
        )
    }
    const snapshots = await getDocs(q)
    // const yearlyExpense: any = {}
    const totallyExpense: any = {}
    let totalExpenses = 0
    // let totalRevenue = 0
    // const labels: any = {

    // }
    const dataCategory: any = {}
    const allCategories: any = []
    snapshots.forEach((snap) => {
        const data = snap.data() as Expense
        // const monthly = moment.unix(data.requestDate).format('MMM-YYYY')
        const name =
            data.type === 'payable' ? 'totalExpense' : 'totalCollection'
        // eslint-disable-next-line no-console
        // console.log(name, snap.id, data.amount);
        if (name === undefined) return
        const category = data.category
        const amount = data.amount
        if (name === 'totalExpense') {
            if (!totallyExpense[category]) {
                totallyExpense[category] = 0
            }
            totallyExpense[category] += amount
            totalExpenses += amount
        }
        // annually
        if (!dataCategory[category]) {
            dataCategory[category] = []
        }
        dataCategory[category].push(data)
        allCategories.push({
            ...data,
            id: snap.id,
        })
        // if(!yearlyExpense[monthly][name]){
        //     yearlyExpense[monthly][name] = 0
        // }
        // if(!yearlyExpense[monthly][category]){
        //     yearlyExpense[monthly][category] = 0
        // }

        // yearlyExpense[monthly][category] += amount
        // yearlyExpense[monthly][name] += amount

        // total
        // totally[name] += amount
    })
    // eslint-disable-next-line no-console
    // console.log(yearlyExpense, totallyExpense);
    // eslint-disable-next-line no-console
    // console.log(totalExpenses, totalRevenue);
    let totalOthers = 0
    let totalOthersAmount = 0
    const prepare = Object.keys(totallyExpense)
        .map((category) => {
            const totalAmount = totallyExpense[category]
            const percentage = totalAmount / totalExpenses
            if (percentage <= 0.02) {
                totalOthers += percentage
                totalOthersAmount += totalAmount
                return false
            }
            let subObj: any = {}
            dataCategory[category].forEach((data: Expense) => {
                if (!subObj[data.title]) {
                    subObj[data.title] = 0
                }
                subObj[data.title] += data.amount
            })

            const subDataTotal = Object.keys(subObj).map((vendorKey) => {
                const total = subObj[vendorKey]

                return {
                    item: vendorKey,
                    total,
                }
            })
            const subDataChart = subDataTotal.map(
                (data: { item: string; total: number }) => {
                    // const subPercent = data.total / subTotal
                    return {
                        item: data.item,
                        total: data.total,
                    }
                }
            )
            // const subTotalDataChart = subDataChart.map((data: {item: string, total: number}) => {
            //     // const subPercent = data.total / subTotal
            //     return {
            //         item: data.item,
            //         data: data.total
            //     }
            // })

            // eslint-disable-next-line no-console
            // console.log(subDataChart, subDataTotal, subObj)
            return {
                item: capitalize(category),
                percent: percentage,
                total: totalAmount,
                data: subDataChart,
            }
        })
        .filter(Boolean)

    return {
        chart: [
            ...prepare,
            {
                item: 'others',
                percent: totalOthers,
                total: totalOthersAmount,
            },
        ] as any,
        data: allCategories,
    }
}
