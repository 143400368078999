import { PlusOutlined } from '@ant-design/icons'
import { ProFormDatePicker } from '@ant-design/pro-components'
import { Row, Col, Button, Input, message, Typography, Grid } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { shallowEqual } from 'react-redux'
import { updateTeam } from '../../../redux/team/team.action'
import { useAppDispatch, useAppSelector } from '../../../store'
import { SettingsTitle, SettingsDescription } from './template'
import ChangeBranchLogo from './ChangeBranchLogo'

const Branch = () => {
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)

    const {
        activeTeam,
        styles,
        // currentTeamPermission,
        // retainedEarnings,
        team,
        // address,
        // name,
        // contactInfo,
        email,
        // phone,
        // vision,
        // mission,
        // coreValuesTeam,
        // registrationNumber,
        // registrationDate,
        // socialGoalsTeam,
        // logoUrl,
        // headline,
    } = useAppSelector(
        (state) => ({
            team: state.team.selectedTeamData,
            // retainedEarnings: state.team.selectedTeamData.retainedEarnings || 0,
            styles: state.styles,
            // currentTeamPermission: state.team.currentTeamPermission,
            activeTeam: state.auth.user.activeTeam,
            email: state.auth.user.email,
        }),
        shallowEqual
    )

    const [values, setValues] = useState<{
        address: string
        name: string
        contactInfo: string
        email: string
        phone: string
        retainedEarnings: number
        mission: string
        vision: string
        // coreValues: string[];
        registrationNumber: string
        registrationDate: number
        headline: string
    }>({
        address: '',
        name: '',
        contactInfo: '',
        email: '',
        phone: '',
        retainedEarnings: 0,
        mission: '',
        vision: '',
        headline: '',
        registrationNumber: '',
        registrationDate: 0,
        // coreValues: ["", "", ""]
    })
    const handleValuesChange = (name: string, val: any) => {
        if (!changed) {
            setChanged(true)
        }
        setValues({
            ...values,
            [name]: val,
        })
    }
    const [coreValues, setCoreValues] = useState(['', '', ''])
    const [socialGoals, setSocialGoals] = useState(['', '', ''])
    const handleCoreValuesChange = (val: any, idx: number) => {
        const newVals = [...coreValues]
        newVals[idx] = val
        setCoreValues(newVals)

        if (!changed) {
            setChanged(true)
        }
    }
    const handleSocialGoalsChange = (val: any, idx: number) => {
        const newVals = [...socialGoals]
        newVals[idx] = val
        setSocialGoals(newVals)

        if (!changed) {
            setChanged(true)
        }
    }

    const addCoreValue = () => {
        setCoreValues([...coreValues, ''])

        if (!changed) {
            setChanged(true)
        }
    }

    const handleReset = () => {
        setSocialGoals(team.socialGoals)
        setCoreValues(team.coreValues)
        setValues({
            ...team,
        })
        setChanged(false)
    }

    const handleSave = async () => {
        setLoading(true)
        await dispatch(
            updateTeam({
                ...values,
                coreValues,
                socialGoals,
                modified: moment().unix(),
                modifiedBy: email,
                id: activeTeam,
            })
        )
        message.success('Successfully updated branch settings.')
        setLoading(false)
        setChanged(false)
    }
    const [changed, setChanged] = useState<any>(undefined)
    // const [ready, setReady] = useState(false)
    useEffect(() => {
        if (changed) return
        setSocialGoals(team.socialGoals)
        setCoreValues(team.coreValues)
        setValues({
            ...team,
        })
        // setFileUrl(team.logoUrl)

        // return () => clearTimeout(time)
    }, [team, changed])

    // useEffect(() => {

    //     const time = setTimeout(() => {
    //         dispatch(updateTeam({
    //             logoUrl: fileUrl,
    //             id: activeTeam
    //         }))
    //     }, 1000)

    //     return () => clearTimeout(time)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fileUrl])

    // useEffect(() => {
    //     if (!team.logoUrl) return
    //     setFileUrl(team.logoUrl)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [team.logoUrl])
    const { xs } = Grid.useBreakpoint()
    return (
        <div className="page-layout">
            <SettingsTitle level={1}>Content Manager</SettingsTitle>

            <ChangeBranchLogo activeTeam={activeTeam} />
            <br />
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <Row>
                        <Col span={24}>
                            <SettingsTitle>Name</SettingsTitle>
                            <SettingsDescription>
                                The name or identifier of the branch.
                            </SettingsDescription>
                            <Input
                                // disabled={
                                //     currentTeamPermission.role === 'viewer' ||
                                //     currentTeamPermission.status !== 'accepted'
                                // }
                                size={'large'}
                                style={{
                                    width: '50%',
                                }}
                                value={values.name}
                                onChange={(e) =>
                                    handleValuesChange('name', e.target.value)
                                }
                            />
                        </Col>
                    </Row>
                </div>
                {/* <div className={'settings-card-footer'}>

                    <Row justify={'space-between'} wrap={false}>
                        <Col>

                        </Col>
                        <Col>
                            {currentTeamPermission.role === 'viewer' ||
                                currentTeamPermission.status !== 'accepted' ? (
                                <Button disabled>Save</Button>
                            ) : (
                                <Button
                                    loading={loading}
                                    onClick={() => handleSave({name: values.name})}
                                    style={{
                                        backgroundColor: styles.primary,
                                        color: 'white',
                                    }}
                                >
                                    Save
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div> */}
            </div>

            <br />
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <Row>
                        <Col span={24}>
                            <SettingsTitle>Headline</SettingsTitle>
                            <SettingsDescription>
                                Text to be displayed below the name of the
                                cooperative.
                            </SettingsDescription>
                            <Input
                                // disabled={
                                //     currentTeamPermission.role === 'viewer' ||
                                //     currentTeamPermission.status !== 'accepted'
                                // }
                                size={'large'}
                                style={{
                                    width: '50%',
                                }}
                                value={values.headline}
                                onChange={(e) =>
                                    handleValuesChange(
                                        'headline',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </div>
                {/* <div className={'settings-card-footer'}>

                    <Row justify={'space-between'} wrap={false}>
                        <Col>

                        </Col>
                        <Col>
                            {currentTeamPermission.role === 'viewer' ||
                                currentTeamPermission.status !== 'accepted' ? (
                                <Button disabled>Save</Button>
                            ) : (
                                <Button
                                    loading={loading}
                                    onClick={() => handleSave({headline: values.headline})}
                                    style={{
                                        backgroundColor: styles.primary,
                                        color: 'white',
                                    }}
                                >
                                    Save
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div> */}
            </div>

            <br />
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <Row>
                        <Col span={24}>
                            <SettingsTitle>Address</SettingsTitle>
                            <SettingsDescription>
                                The physical address of the branch.
                            </SettingsDescription>
                            <Input.TextArea
                                // disabled={
                                //     currentTeamPermission.role === 'viewer' ||
                                //     currentTeamPermission.status !== 'accepted'
                                // }
                                size={'large'}
                                value={values.address}
                                onChange={(e) =>
                                    handleValuesChange(
                                        'address',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </div>
                {/* <div className={'settings-card-footer'}>

                    <Row justify={'space-between'} wrap={false}>
                        <Col>

                        </Col>
                        <Col>
                            {currentTeamPermission.role === 'viewer' ||
                                currentTeamPermission.status !== 'accepted' ? (
                                <Button disabled>Save</Button>
                            ) : (
                                <Button
                                    loading={loading}
                                    onClick={() => handleSave({address: values.address})}
                                    style={{
                                        backgroundColor: styles.primary,
                                        color: 'white',
                                    }}
                                >
                                    Save
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div> */}
            </div>

            <br />
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <Row>
                        <Col span={24}>
                            <SettingsTitle>Registration Number</SettingsTitle>
                            <Input.TextArea
                                // disabled={
                                //     currentTeamPermission.role === 'viewer' ||
                                //     currentTeamPermission.status !== 'accepted'
                                // }
                                size={'large'}
                                value={values.registrationNumber}
                                onChange={(e) =>
                                    handleValuesChange(
                                        'registrationNumber',
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </div>
                {/* <div className={'settings-card-footer'}>

                    <Row justify={'space-between'} wrap={false}>
                        <Col>

                        </Col>
                        <Col>
                            {currentTeamPermission.role === 'viewer' ||
                                currentTeamPermission.status !== 'accepted' ? (
                                <Button disabled>Save</Button>
                            ) : (
                                <Button
                                    loading={loading}
                                    onClick={() => handleSave({registrationNumber: values.registrationNumber})}
                                    style={{
                                        backgroundColor: styles.primary,
                                        color: 'white',
                                    }}
                                >
                                    Save
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div> */}
            </div>

            <br />
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <Row>
                        <Col span={24}>
                            <SettingsTitle>Registration Date</SettingsTitle>
                            <ProFormDatePicker
                                // disabled={
                                //     currentTeamPermission.role === 'viewer' ||
                                //     currentTeamPermission.status !== 'accepted'
                                // }
                                fieldProps={{
                                    value: values.registrationDate
                                        ? moment.unix(values.registrationDate)
                                        : undefined,
                                    onChange: (e) =>
                                        handleValuesChange(
                                            'registrationDate',
                                            e?.unix()
                                        ),
                                }}
                                // onChange={(e) => handleValuesChange('registrationDate', e.target.value)}
                            />
                        </Col>
                    </Row>
                </div>
                {/* <div className={'settings-card-footer'}>

                    <Row justify={'space-between'} wrap={false}>
                        <Col>

                        </Col>
                        <Col>
                            {currentTeamPermission.role === 'viewer' ||
                                currentTeamPermission.status !== 'accepted' ? (
                                <Button disabled>Save</Button>
                            ) : (
                                <Button
                                    loading={loading}
                                    onClick={() => handleSave({registrationDate: values.registrationDate})}
                                    style={{
                                        backgroundColor: styles.primary,
                                        color: 'white',
                                    }}
                                >
                                    Save
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div> */}
            </div>

            <br />
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <Row>
                        <Col span={24}>
                            <SettingsTitle>Contact Info</SettingsTitle>
                            <SettingsDescription>
                                Contact information for the branch (phone,
                                email, etc.)
                            </SettingsDescription>
                            <Row>
                                <SettingsTitle level={5}>Phone</SettingsTitle>
                            </Row>
                            <Row>
                                <Input
                                    // disabled={
                                    //     currentTeamPermission.role ===
                                    //         'viewer' ||
                                    //     currentTeamPermission.status !==
                                    //         'accepted'
                                    // }
                                    size={'large'}
                                    style={{
                                        width: 200,
                                    }}
                                    value={values.phone}
                                    onChange={(e) =>
                                        handleValuesChange(
                                            'phone',
                                            e.target.value
                                        )
                                    }
                                />
                            </Row>
                            <Row>
                                <SettingsTitle level={5}>Email</SettingsTitle>
                            </Row>
                            <Row>
                                <Input
                                    // disabled={
                                    //     currentTeamPermission.role ===
                                    //         'viewer' ||
                                    //     currentTeamPermission.status !==
                                    //         'accepted'
                                    // }
                                    size={'large'}
                                    style={{
                                        width: 200,
                                    }}
                                    value={values.email}
                                    onChange={(e) =>
                                        handleValuesChange(
                                            'email',
                                            e.target.value
                                        )
                                    }
                                />
                            </Row>
                        </Col>
                    </Row>
                </div>
                {/* <div className={'settings-card-footer'}>

                    <Row justify={'space-between'} wrap={false}>
                        <Col>

                        </Col>
                        <Col>
                            {currentTeamPermission.role === 'viewer' ||
                                currentTeamPermission.status !== 'accepted' ? (
                                <Button disabled>Save</Button>
                            ) : (
                                <Button
                                    loading={loading}
                                    onClick={() => handleSave({
                                        phone: values.phone,
                                        email: values.email,
                                    })}
                                    style={{
                                        backgroundColor: styles.primary,
                                        color: 'white',
                                    }}
                                >
                                    Save
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div> */}
            </div>
            {/* <br />
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <Row>
                        <Col span={24}>
                            <SettingsTitle>Retained Earnings</SettingsTitle>
                            <SettingsDescription>
                                The minimum required balance for savings accounts.
                            </SettingsDescription>
                            <InputNumber
                                disabled={
                                    currentTeamPermission.role === 'viewer' ||
                                    currentTeamPermission.status !== 'accepted'
                                }
                                size={'large'}
                                style={{
                                    width: 200,
                                }}
                                value={values.retainedEarnings}
                                addonAfter={'%'}
                                onChange={(e) => handleValuesChange('retainedEarnings', e)}
                            />
                        </Col>
                    </Row>
                </div>

                <br />
                <div className={'settings-card-footer'}>

                    <Row justify={'space-between'} wrap={false}>
                        <Col>

                        </Col>
                        <Col>
                            {currentTeamPermission.role === 'viewer' ||
                                currentTeamPermission.status !== 'accepted' ? (
                                <Button disabled>Save</Button>
                            ) : (
                                <Button
                                    loading={loading}
                                    onClick={handleSave}
                                    style={{
                                        backgroundColor: styles.primary,
                                        color: 'white',
                                    }}
                                >
                                    Save
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
            </div> */}
            <br />
            <div className={'settings-card'}>
                <div className={'settings-card-content'}>
                    <Row>
                        <Col span={24}>
                            <SettingsTitle>Social Statement</SettingsTitle>
                            <SettingsDescription>
                                Public facing information of the branch.
                            </SettingsDescription>

                            <Row>
                                <SettingsTitle level={5}>Vision</SettingsTitle>
                            </Row>
                            <Row>
                                <Input.TextArea
                                    // disabled={
                                    //     currentTeamPermission.role ===
                                    //         'viewer' ||
                                    //     currentTeamPermission.status !==
                                    //         'accepted'
                                    // }
                                    size={'large'}
                                    value={values.vision}
                                    onChange={(e) =>
                                        handleValuesChange(
                                            'vision',
                                            e.target.value
                                        )
                                    }
                                />
                            </Row>
                            <br />
                            <Row>
                                <SettingsTitle level={5}>Mission</SettingsTitle>
                            </Row>
                            <Row>
                                <Input.TextArea
                                    // disabled={
                                    //     currentTeamPermission.role ===
                                    //         'viewer' ||
                                    //     currentTeamPermission.status !==
                                    //         'accepted'
                                    // }
                                    size={'large'}
                                    value={values.mission}
                                    onChange={(e) =>
                                        handleValuesChange(
                                            'mission',
                                            e.target.value
                                        )
                                    }
                                />
                            </Row>
                            <br />
                            <Row>
                                <SettingsTitle level={5}>
                                    Core Values
                                </SettingsTitle>
                            </Row>
                            {coreValues &&
                                coreValues.map((value, idx) => (
                                    <Row
                                        align="middle"
                                        style={{ marginBottom: 10 }}
                                        wrap={false}
                                    >
                                        <Col span={22}>
                                            <Input
                                                // disabled={
                                                //     currentTeamPermission.role ===
                                                //         'viewer' ||
                                                //     currentTeamPermission.status !==
                                                //         'accepted'
                                                // }
                                                size={'large'}
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={value}
                                                onChange={(e) =>
                                                    handleCoreValuesChange(
                                                        e.target.value,
                                                        idx
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col span={2}>
                                            <Button
                                                size="small"
                                                type="text"
                                                onClick={() => {
                                                    const newVals = [
                                                        ...coreValues,
                                                    ]
                                                    newVals.splice(idx, 1)
                                                    setCoreValues(newVals)
                                                }}
                                                icon={
                                                    <FaTrash
                                                        style={{ color: 'red' }}
                                                    />
                                                }
                                            />
                                        </Col>
                                    </Row>
                                ))}

                            <Button
                                icon={<PlusOutlined />}
                                onClick={addCoreValue}
                            >
                                Add More
                            </Button>
                            <br />
                            <br />
                            <Row>
                                <SettingsTitle level={5}>
                                    Social Goals
                                </SettingsTitle>
                            </Row>
                            {socialGoals &&
                                socialGoals.map((value, idx) => (
                                    <Row
                                        align="middle"
                                        style={{ marginBottom: 10 }}
                                        wrap={false}
                                    >
                                        <Col span={22}>
                                            <Input
                                                // disabled={
                                                //     currentTeamPermission.role ===
                                                //         'viewer' ||
                                                //     currentTeamPermission.status !==
                                                //         'accepted'
                                                // }
                                                size={'large'}
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={value}
                                                onChange={(e) =>
                                                    handleSocialGoalsChange(
                                                        e.target.value,
                                                        idx
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col span={2}>
                                            <Button
                                                size="small"
                                                type="text"
                                                onClick={() => {
                                                    const newVals = [
                                                        ...socialGoals,
                                                    ]
                                                    newVals.splice(idx, 1)
                                                    setSocialGoals(newVals)
                                                }}
                                                icon={
                                                    <FaTrash
                                                        style={{ color: 'red' }}
                                                    />
                                                }
                                            />
                                        </Col>
                                    </Row>
                                ))}

                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    setSocialGoals([...socialGoals, ''])
                                }}
                            >
                                Add More
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>

            {changed && (
                <Row
                    justify={'space-between'}
                    align={'middle'}
                    wrap={xs}
                    style={{
                        position: 'sticky',
                        bottom: 10,
                        backgroundColor: 'black',
                        padding: '12px 24px',
                        borderRadius: 8,
                    }}
                    gutter={[12, 12]}
                >
                    <Col xs={24} md={12}>
                        <Typography.Title
                            level={4}
                            style={{ color: 'white', marginBottom: 0 }}
                        >
                            Careful -- You have unsaved changes!
                        </Typography.Title>
                    </Col>
                    <Col>
                        <Row gutter={[12, 12]}>
                            <Col>
                                <Button
                                    size="large"
                                    loading={loading}
                                    onClick={handleReset}
                                    style={{
                                        color: 'white',
                                        border: '1px solid white',
                                    }}
                                    type="link"
                                >
                                    Reset
                                </Button>
                            </Col>
                            <Col>
                                {/* {currentTeamPermission.role === 'viewer' ||
                                currentTeamPermission.status !== 'accepted' ? (
                                    <Button disabled>Save</Button>
                                ) : ( */}
                                <Button
                                    size="large"
                                    loading={loading}
                                    onClick={handleSave}
                                    style={{
                                        backgroundColor: styles.primary,
                                        color: 'white',
                                        width: xs ? 125 : 150,
                                        border: 'none',
                                    }}
                                >
                                    Save Changes
                                </Button>
                                {/* )} */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default Branch
