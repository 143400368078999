// import { SystemSettings } from '../../domain/entities/SystemSettings'

import { message } from 'antd'
import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    increment,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import moment from 'moment'
import { auth, db } from '../../../app/boot'
import {
    Loan,
    LoanPayment,
    LoanPaymentProps,
} from '../../../domain/entities/Loan'
import { LoanFirebaseRepository } from '../../../domain/repositories/loan.repository'
import {
    LOANS_REPOSITORY,
    PAYMENTS_REPOSITORY,
    TEAM_REPOSITORY,
} from '../constants'
import { getErrorMessage } from '../default'
import { TeamFirebaseImplementation } from '../team/team.firebase'

export class LoanFirebaseImplementation
    extends TeamFirebaseImplementation
    implements LoanFirebaseRepository
{
    // setStorage(key: string, data: any) {
    //     localStorage.setItem(key, JSON.stringify(data))
    // }
    // getStorage(key: string): undefined | any {
    //     const item = localStorage.getItem(key)
    //     if (item) {
    //         return JSON.parse(item)
    //     } else {
    //         return undefined
    //     }
    // }
    // removeUnpaidInterest(loan: Loan, remove?: boolean) {
    //     const key = 'system-settings'
    //     const sysData: SystemSettings = this.getStorage(key)
    //     const yearOfLoan = moment.unix(loan.creation).format('YYYY')
    //     if (sysData) {
    //         if (remove) {
    //             sysData.overallUnpaidInterest[yearOfLoan] -=
    //                 loan.pendingInterest
    //         } else {
    //             sysData.overallUnpaidInterest[yearOfLoan] +=
    //                 loan.pendingInterest
    //         }
    //         this.setStorage(key, sysData)
    //     }
    // }

    async calculateMemberLoanIncome(loan: Loan, remove?: boolean) {
        // const member = await this.getTeamMember({
        //     memberId: loan.memberId,
        //     partnerCode: loan.partnerCode,
        // })
        // const memberIncomeContribution: TeamMember['incomeContribution'] = {
        // ...member.incomeContribution,
        // }
        // let totalPrincipalPaid = 0
        // let totalIncome = 0
        // let totalRemainingBalance = 0
        // if (!member) return undefined
    }

    async calculateMemberLoan(payload: Loan, remove?: boolean) {
        const monthOfLoan = moment.unix(payload.creation).format('MMMM')
        const yearOfLoan = moment.unix(payload.creation).format('YYYY')
        const { partnerCode, amount, remainingBalance } = payload
        const amountVal = remove ? -amount : amount
        const remainingBalanceVal = remove
            ? -remainingBalance
            : remainingBalance

        await this.addLoanToTeam(
            yearOfLoan,
            monthOfLoan,
            partnerCode,
            amountVal,
            remainingBalanceVal
        )
        // await this.calculateMemberLoanIncome(payload, remove)

        const memberRef = doc(
            db,
            'team',
            payload.partnerCode,
            'members',
            payload.memberId
        )
        await updateDoc(memberRef, {
            modified: moment().unix(),
            modifiedBy: auth?.currentUser?.uid,
            [`memberLoan.${yearOfLoan}.${monthOfLoan}`]: increment(amountVal),
            [`memberLoan.${yearOfLoan}.Annual`]: increment(amountVal),
            [`memberLoanRemainingBalance.${yearOfLoan}.${monthOfLoan}`]:
                increment(remainingBalanceVal),
            [`memberLoanRemainingBalance.${yearOfLoan}.Annual`]:
                increment(remainingBalanceVal),
            totalLoan: increment(amountVal),
            totalRemainingBalance: increment(remainingBalanceVal),
        })
        // if (!remove) {

        //     newMember =
        //     if (newMember) {
        //         newMember.totalLoan += payload.amount
        //     }
        // } else {
        //     await this.addLoanToTeam(
        //         yearOfLoan,
        //         partnerCode,
        //         amountVal,
        //         remainingBalanceVal
        //     )
        //     newMember = this.calculateMemberLoanIncome(payload, true)
        //     if (newMember) {
        //         newMember.totalLoan -= amount
        //     }
        // }
        // if (newMember) {
        //     const resp = this.UpdateMember(newMember)
        //     if (resp) return true
        // }
        return false
    }
    async addIncomeToMember(
        loan: LoanPaymentProps,
        payload: LoanPayment,
        withTeam: boolean,
        remove?: boolean
    ) {
        const income = payload.interest - payload.paidPendingInterest
        const principalPayment = payload.principal
        const monthOfPayment = moment.unix(payload.creation).format('MMMM')
        const yearOfPayment = moment.unix(payload.creation).format('YYYY')
        // const member = this.findMember(loan.memberId)
        if (!loan.memberId) return false

        const memberRef = doc(
            db,
            'team',
            loan.partnerCode,
            'members',
            loan.memberId
        )
        const incomeVal = remove ? increment(-income) : increment(income)

        await updateDoc(memberRef, {
            modified: moment().unix(),
            modifiedBy: auth?.currentUser?.uid,
            [`incomeContribution.${yearOfPayment}.${monthOfPayment}`]:
                incomeVal,
            [`incomeContribution.${yearOfPayment}.Annual`]: incomeVal,
            totalIncomeContribution: incomeVal,
        })
        if (withTeam) {
            await this.addIncomeToTeam(
                loan.partnerCode,
                yearOfPayment,
                monthOfPayment,
                remove ? -income : income,
                remove ? -principalPayment : principalPayment,
                remove ? principalPayment : -principalPayment
            )
        }
        // const memberIncomeContribution: TeamMember['incomeContribution'] = {
        //     ...member.incomeContribution,
        // }
        // if (!memberIncomeContribution[yearOfPayment]) {
        //     memberIncomeContribution[yearOfPayment] = {
        //         Annual: 0,
        //     }
        // }
        // if (!memberIncomeContribution[yearOfPayment][monthOfPayment]) {
        //     memberIncomeContribution[yearOfPayment][monthOfPayment] = 0
        // }

        // if (!remove) {
        //     memberIncomeContribution[yearOfPayment]['Annual'] += income
        //     memberIncomeContribution[yearOfPayment][monthOfPayment] +=
        //         income
        //     member.totalIncomeContribution += income
        // } else {
        //     memberIncomeContribution[yearOfPayment]['Annual'] -= income
        //     memberIncomeContribution[yearOfPayment][monthOfPayment] -=
        //         income
        //     member.totalIncomeContribution -= income

        //     this.addIncomeToSystem(
        //         yearOfPayment,
        //         -income,
        //         -principalPayment,
        //         principalPayment
        //     )
        // }
        // member.incomeContribution = memberIncomeContribution
        // const resp = this.UpdateMember(member)
        return true
    }
    // addLoanToSystem(amount: number) {
    //     const key = 'system-settings'
    //     const sysData: SystemSettings = this.getStorage(key)
    //     if (sysData) {
    //         sysData.overallLoans += amount
    //         this.setStorage(key, sysData)
    //     } else {
    //         this.setStorage(key, {
    //             loanStartDate: '01/02',
    //             loanDurationInDays: 52 * 7,
    //             loanInterestRate: 5,
    //             loanFrequency: 'monthly',
    //             loanDepositStartDate: 10,
    //             loanDepositDeadlineInHours: 6 * 24,
    //             loanMinDeposit: 260,
    //             loanMaxDeposit: 260,
    //             loanPenalty: 0,
    //             overallLoans: 0,
    //         })
    //     }
    // }
    // addLoanToMember(id: string, amount: number): boolean {
    //     const member = this.findMember(id)
    //     if (member) {
    //         member.totalLoan =
    //             member.totalLoan === null ? amount : member.totalLoan + amount
    //         this.addLoanToSystem(amount)
    //         this.UpdateMember(member)
    //         return true
    //     }
    //     return false
    // }
    async AddLoan(payload: Loan) {
        // // const res = this.addLoanToMember(payload.memberId, payload.amount)
        // // if (!res)
        // //     throw `Loan unsuccessfully added to member: ${payload.memberId}`
        // const key = LOANS_REPOSITORY
        // let loans = this.getStorage(key)
        // if (!loans) {
        //     loans = []
        // }

        // loans.push(payload)
        // this.setStorage(key, loans)
        // try {
        //     const noPayment: any = {
        //         ...payload,
        //         payments: [],
        //     }
        //     this.calculateMemberLoan(noPayment)
        //     if (payload.payments[0]) {
        //         this.addIncomeToMember(payload, payload.payments[0])
        //     }
        // } catch (err) {
        //     // eslint-disable-next-line no-console
        //     console.log(err)
        // }
        // return payload
        // try {
        // const noPayment: any = {
        //     ...payload,
        //     payments: [],
        // }
        const resp = await addDoc(collection(db, LOANS_REPOSITORY), payload)
        payload.id = resp.id

        // await this.calculateMemberLoan(noPayment)
        // if (payload.payments.length) {
        //     const payment = payload.payments[0]

        //     const incomeVal = payment.interest - payment.paidPendingInterest
        //     const principalPaymentVal = payment.principal
        //     const remove = false
        //     const income = remove ? -incomeVal : incomeVal
        //     const principalPayment = remove
        //         ? -principalPaymentVal
        //         : principalPaymentVal

        //     const monthOfPayment = moment.unix(payment.creation).format('MMMM')
        //     const yearOfPayment = moment.unix(payment.creation).format('YYYY')
        //     await this.addIncomeToTeam(
        //         payload.partnerCode,
        //         yearOfPayment,
        //         monthOfPayment,
        //         income,
        //         principalPayment,
        //         -principalPayment
        //     )

        //     await this.addIncomeToMember(payload, payment, false, remove)
        //     const paymentResp = await addDoc(
        //         collection(db, LOANS_REPOSITORY, resp.id, PAYMENTS_REPOSITORY),
        //         payment
        //     )
        //     const loanRef = doc(db, LOANS_REPOSITORY, resp.id)
        //     await updateDoc(loanRef, {
        //         payments: arrayUnion(paymentResp.id),
        //     })
        // }
        return payload
        // } catch (err) {
        //     // eslint-disable-next-line no-console
        //     message.error(getErrorMessage(err))
        //     return false
        // }
    }

    async GetMemberLoans(payload: { memberId: string; partnerCode: string }) {
        const { memberId, partnerCode } = payload
        const ref = collection(db, LOANS_REPOSITORY)
        const q = query(
            ref,
            where('memberId', '==', memberId),
            where('partnerCode', '==', partnerCode),
            where('isRemoved', '==', false)
        )

        const snap = await getDocs(q)
        const data: Loan[] = snap.docs.map((docSnap) => {
            // const userSnap = await getDoc(doc(db, 'users', docSnap.id))
            return {
                // ...userSnap.data(),
                ...docSnap.data(),
                id: docSnap.id,
            } as Loan
        })
        return await Promise.all(data)
        // const key = LOANS_REPOSITORY
        // const loans: Loan[] = this.getStorage(key)
        // if (loans) return loans.filter((loan) => loan.memberId === id)
        // return []
    }
    async RemoveLoan(id: string) {
        try {
            // const loan = await this.GetLoan(id)
            // // await this.calculateMemberLoan(loan, true)
            // const monthOfLoan = moment.unix(loan.creation).format('MMMM')
            // const yearOfLoan = moment.unix(loan.creation).format('YYYY')
            // const { amount, remainingBalance } = loan
            // const amountVal = -amount
            // const remainingBalanceVal = -remainingBalance
            // // member: memberLoan, memberLoanRemainingBalance, totalLoan, totalRemainingBalance
            // // team: remainingBalance, overallLoan
            // const teamRef = doc(db, TEAM_REPOSITORY, loan.partnerCode)

            // await updateDoc(teamRef, {
            //     [`remainingBalance.${yearOfLoan}.Annual`]:
            //         increment(remainingBalanceVal),
            //     [`remainingBalance.${yearOfLoan}.${monthOfLoan}`]:
            //         increment(remainingBalanceVal),
            //     [`overallLoan.${yearOfLoan}.Annual`]: increment(amountVal),
            //     [`overallLoan.${yearOfLoan}.${monthOfLoan}`]:
            //         increment(amountVal),
            // })

            // const memberRef = doc(
            //     db,
            //     'team',
            //     loan.partnerCode,
            //     'members',
            //     loan.memberId
            // )
            // await updateDoc(memberRef, {
            //     modified: moment().unix(),
            //     modifiedBy: auth?.currentUser?.uid,
            //     [`memberLoan.${yearOfLoan}.${monthOfLoan}`]:
            //         increment(amountVal),
            //     [`memberLoan.${yearOfLoan}.Annual`]: increment(amountVal),
            //     [`memberLoanRemainingBalance.${yearOfLoan}.${monthOfLoan}`]:
            //         increment(remainingBalanceVal),
            //     [`memberLoanRemainingBalance.${yearOfLoan}.Annual`]:
            //         increment(remainingBalanceVal),
            //     totalLoan: increment(amountVal),
            //     totalRemainingBalance: increment(remainingBalanceVal),
            // })
            // // await updateDoc(doc(db, LOANS_REPOSITORY, id), {
            // //     isRemoved: true,
            // //     modified: moment().unix(),
            // //     modifiedBy: auth.currentUser?.uid,
            // // })

            // // await this.addUnpaidInterestToSystem(
            // //     loan.partnerCode,
            // //     yearOfLoan,
            // //     monthOfLoan,
            // //     -loan.pendingInterest
            // //     // true
            // // )
            // await this.addRemainingBalanceToSystem(
            //     loan.partnerCode,
            //     yearOfLoan,
            //     monthOfLoan,
            //     -loan.totalPrincipalPaid
            // )
            // const promises = loan.payments.map(async (payment) => {
            //     const interest = payment.interest - payment.paidPendingInterest
            //     const paymentRef = doc(
            //         db,
            //         LOANS_REPOSITORY,
            //         loan.id as string,
            //         PAYMENTS_REPOSITORY,
            //         payment.id as string
            //     )
            //     const yearOfPayment = moment
            //         .unix(payment.creation)
            //         .format('YYYY')
            //     const monthOfPayment = moment
            //         .unix(payment.creation)
            //         .format('MMMM')
            //     if (payment.modifiedBy === 'system') {
            //         await this.addUnpaidInterestToSystem(
            //             loan.partnerCode,
            //             yearOfPayment,
            //             monthOfPayment,
            //             -interest
            //         )
            //     } else {
            //         const monthOfLoan = monthOfPayment
            //         const yearOfLoan = yearOfPayment
            //         await updateDoc(memberRef, {
            //             modified: moment().unix(),
            //             modifiedBy: auth?.currentUser?.uid,
            //             // [`memberLoanRemainingBalance.${yearOfLoan}.${monthOfLoan}`]:
            //             //     increment(-payment.principal),
            //             // [`memberLoanRemainingBalance.${yearOfLoan}.Annual`]:
            //             //     increment(-payment.principal),
            //             // totalRemainingBalance: increment(-payment.principal),

            //             [`incomeContribution.${yearOfLoan}.${monthOfLoan}`]:
            //                 increment(-interest),
            //             [`incomeContribution.${yearOfLoan}.Annual`]: increment(
            //                 -interest
            //             ),
            //             totalIncomeContribution: increment(-interest),
            //         })
            //     }
            //     // await updateDoc(doc(db, LOANS_REPOSITORY, loan.id as string), {
            //     //     totalInterestPaid: increment(-payment.interest),
            //     //     totalPrincipalPaid: increment(-payment.principal),
            //     //     remainingBalance: increment(payment.principal),
            //     //     modified: moment().unix(),
            //     //     modifiedBy: auth?.currentUser?.uid,
            //     //     pendingInterest: 0,
            //     // })

            //     await updateDoc(teamRef, {
            //         modified: moment().unix(),
            //         modifiedBy: auth?.currentUser?.uid,
            //         [`overallIncome.${yearOfPayment}.Annual`]: increment(
            //             -interest
            //         ),
            //         [`overallIncome.${yearOfPayment}.${monthOfPayment}`]:
            //             increment(-interest),
            //         [`overallPrincipal.${yearOfPayment}.Annual`]: increment(
            //             -payment.principal
            //         ),
            //         [`overallPrincipal.${yearOfPayment}.${monthOfPayment}`]:
            //             increment(-payment.principal),
            //         [`remainingBalance.${yearOfPayment}.Annual`]: increment(
            //             payment.principal
            //         ),
            //         [`remainingBalance.${yearOfPayment}.${monthOfPayment}`]:
            //             increment(payment.principal),
            //         [`overallUnpaidInterest.${yearOfPayment}.Annual`]:
            //             increment(payment.paidPendingInterest),
            //         [`overallUnpaidInterest.${yearOfPayment}.${monthOfPayment}`]:
            //             increment(payment.paidPendingInterest),
            //     })
            //     await deleteDoc(paymentRef)
            //     return true
            // })
            // await Promise.all(promises)
            await updateDoc(doc(db, LOANS_REPOSITORY, id), {
                isRemoved: true,
            })
            return id
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
        // const key = LOANS_REPOSITORY
        // const loans = this.getStorage(key)
        // if (loans) {
        //     const idx = loans.findIndex((loan: Loan) => loan.id === id)
        //     const loan = loans.splice(idx, 1)[0]
        //     this.calculateMemberLoan(loan, true)
        //     const yearOfLoan = moment.unix(loan.creation).format('YYYY')
        //     this.addUnpaidInterestToSystem(
        //         yearOfLoan,
        //         loan.pendingInterest,
        //         true
        //     )
        //     this.addRemainingBalanceToSystem(
        //         yearOfLoan,
        //         -loan.totalPrincipalPaid
        //     )
        //     this.setStorage(key, loans)
        //     return id
        // }
        // return undefined
    }
    async UpdateLoan(payload: Loan, prevLoan: Loan) {
        // payload = firebaseAdd(payload)
        try {
            const { id, ...rest } = payload
            if (
                prevLoan.amount !== payload.amount ||
                prevLoan.interest !== payload.interest
            ) {
                await this.calculateMemberLoan(prevLoan, true)
                await this.calculateMemberLoan(payload)
            }
            await updateDoc(doc(db, LOANS_REPOSITORY, id as string), {
                ...rest,
                modified: moment().unix(),
                modifiedBy: auth?.currentUser?.uid,
            })
            return payload
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
    }
    // UpdateLoan(payload: Loan) {
    //     const key = LOANS_REPOSITORY
    //     const loans = this.getStorage(key)
    //     if (loans) {
    //         const idx = loans.findIndex((loan: Loan) => loan.id === payload.id)
    //         if (idx >= 0) {
    //             const prevLoan = loans[idx]
    //             if (
    //                 prevLoan.amount !== payload.amount ||
    //                 prevLoan.interest !== payload.interest
    //             ) {
    //                 this.calculateMemberLoan(prevLoan, true)
    //                 this.calculateMemberLoan(payload)
    //             }

    //             loans[idx] = {
    //                 ...loans[idx],
    //                 ...payload,
    //             }
    //             this.setStorage(key, loans)
    //             return loans[idx]
    //         }
    //     }
    // }
    async GetLoan(id: string) {
        const loansRef = doc(db, LOANS_REPOSITORY, id)
        const snap = await getDoc(loansRef)
        const data = snap.data()
        // const payments = await this.getLoanPayments(id)
        return {
            ...data,
            id,
        } as Loan
        // const key = LOANS_REPOSITORY
        // const loans = this.getStorage(key)
        // if (loans) {
        //     const idx = loans.findIndex((loan: Loan) => loan.id === id)
        //     return loans[idx]
        // }
    }
    async GetAllMemberLoan(partnerCode: string) {
        const ref = collection(db, LOANS_REPOSITORY)
        const q = query(
            ref,
            where('isRemoved', '==', false),
            where('partnerCode', '==', partnerCode)
        )
        const snap = await getDocs(q)
        const promises = snap.docs.map(async (docSnap) => {
            // const userSnap = await getDoc(doc(db, 'users', docSnap.id))
            // const payments = await this.getLoanPayments(docSnap.id)
            return {
                // ...userSnap.data(),
                ...docSnap.data(),
                // payments,
                id: docSnap.id,
            } as Loan
        })

        return await Promise.all(promises)
    }

    async AddLoanPayment(
        loan: LoanPaymentProps & {
            id: string
        },
        payload: LoanPayment
    ) {
        try {
            const ref = doc(db, 'loans', loan.id)
            const memberRef = doc(
                db,
                TEAM_REPOSITORY,
                loan.partnerCode,
                'members',
                loan.memberId
            )
            const paymentRef = collection(db, 'loans', loan.id, 'payments')
            let newPendingInterest = 0
            const totalInterest = payload.currentInterest
            const totalInterestPayment = payload.interest
            if (totalInterest) {
                if (payload.pendingInterest < totalInterestPayment) {
                    newPendingInterest =
                        payload.pendingInterest - payload.paidPendingInterest
                } else {
                    newPendingInterest = totalInterest - totalInterestPayment
                }
            }
            const yearOfPayment = moment.unix(payload.creation).format('YYYY')
            const monthOfPayment = moment.unix(payload.creation).format('MMMM')
            let pendingInterest = parseFloat(newPendingInterest.toFixed(2))
            let due = payload.due
            let inc = false
            if (payload.modifiedBy === 'system') {
                inc = true
                pendingInterest += payload.interest
                await this.addUnpaidInterestToSystem(
                    loan.partnerCode,
                    yearOfPayment,
                    monthOfPayment,
                    payload.interest
                )
            } else {
                due = moment.unix(payload.due).add(1, 'month').unix()
            }
            // if (
            //     payload.modifiedBy !== 'system' &&
            //     payload.modifiedBy !== 'automated'
            // ) {
            //     due = moment.unix(payload.due).add(1, 'month').unix()
            // }
            const interestPaid = payload.interest - payload.paidPendingInterest
            const resp = await addDoc(paymentRef, payload)
            await updateDoc(ref, {
                totalInterestPaid: increment(interestPaid),
                totalPrincipalPaid: increment(payload.principal),
                remainingBalance: increment(-payload.principal),
                pendingInterest: inc
                    ? increment(pendingInterest)
                    : newPendingInterest,
                payments: arrayUnion(resp.id),
                due,
                dueHistory: {
                    [moment.unix(due).format('MM/DD/YYYY')]: {
                        penalty: payload.modifiedBy === 'system',
                        interestPaid,
                    },
                },
            })

            await this.addIncomeToTeam(
                loan.partnerCode,
                yearOfPayment,
                monthOfPayment,
                interestPaid,
                payload.principal,
                -payload.principal
            )

            await updateDoc(memberRef, {
                modified: moment().unix(),
                modifiedBy: auth?.currentUser?.uid,
                // [`memberLoan.${yearOfPayment}.${monthOfPayment}`]:
                //     increment(amountVal),
                // [`memberLoan.${yearOfPayment}.Annual`]: increment(amountVal),
                [`memberLoanRemainingBalance.${yearOfPayment}.${monthOfPayment}`]:
                    increment(-payload.principal),
                [`memberLoanRemainingBalance.${yearOfPayment}.Annual`]:
                    increment(-payload.principal),
                // totalLoan: increment(amountVal),
                totalRemainingBalance: increment(-payload.principal),
            })
            await this.addUnpaidInterestToSystem(
                loan.partnerCode,
                yearOfPayment,
                monthOfPayment,
                payload.paidPendingInterest,
                true
            )
            return {
                totalInterestPaid: interestPaid,
                totalPrincipalPaid: payload.principal,
                remainingBalance: -payload.principal,
                pendingInterest: pendingInterest,
                id: loan.id,
            }
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
        // if (payload.modifiedBy === 'system') {
        //     loanData.due = moment.unix(payload.due).add(1, 'month').unix()
        //     loanData.pendingInterest += payload.interest
        // }

        // loans[idx] = loanData
        // this.setStorage(key, loans)
        // this.addIncomeToMember(loanData, payload)

        // const yearOfPayment = moment.unix(payload.creation).format('YYYY')
        // this.addUnpaidInterestToSystem(
        //     yearOfPayment,
        //     payload.paidPendingInterest,
        //     true
        // )
    }

    async UpdateLoanPayment(
        previousPayment: LoanPayment,
        payment: LoanPayment,
        loanProp: LoanPaymentProps & {
            id: string
        },
        newPendingInterest: number
    ): Promise<boolean> {
        const prevPayment = previousPayment
        if (prevPayment.amountPaid !== payment.amountPaid) {
            const prevInterest =
                prevPayment.interest - prevPayment.paidPendingInterest
            const currentInterest =
                payment.interest - payment.paidPendingInterest
            // remove previous payment
            // await this.addIncomeToMember(loanProp, prevPayment, true, true)
            const yearOfPayment = moment
                .unix(prevPayment.creation)
                .format('YYYY')
            const monthOfPayment = moment
                .unix(prevPayment.creation)
                .format('MMMM')
            await this.addUnpaidInterestToSystem(
                loanProp.partnerCode,
                yearOfPayment,
                monthOfPayment,
                currentInterest - prevInterest
            )
            await updateDoc(doc(db, LOANS_REPOSITORY, loanProp.id), {
                totalInterestPaid: increment(currentInterest - prevInterest),
                totalPrincipalPaid: increment(
                    payment.principal - prevPayment.principal
                ),
                remainingBalance: increment(
                    prevPayment.principal - payment.principal
                ),
                modified: moment().unix(),
                modifiedBy: auth?.currentUser?.uid,
                pendingInterest: newPendingInterest,
            })

            const teamRef = doc(db, TEAM_REPOSITORY, loanProp.partnerCode)

            await updateDoc(teamRef, {
                modified: moment().unix(),
                modifiedBy: auth?.currentUser?.uid,
                [`overallIncome.${yearOfPayment}.Annual`]: increment(
                    currentInterest - prevInterest
                ),
                [`overallIncome.${yearOfPayment}.${monthOfPayment}`]: increment(
                    currentInterest - prevInterest
                ),
                [`overallPrincipal.${yearOfPayment}.Annual`]: increment(
                    payment.principal - prevPayment.principal
                ),
                [`overallPrincipal.${yearOfPayment}.${monthOfPayment}`]:
                    increment(payment.principal - prevPayment.principal),
                [`remainingBalance.${yearOfPayment}.Annual`]: increment(
                    prevPayment.principal - payment.principal
                ),
                [`remainingBalance.${yearOfPayment}.${monthOfPayment}`]:
                    increment(prevPayment.principal - payment.principal),
                [`overallUnpaidInterest.${yearOfPayment}.Annual`]: increment(
                    prevPayment.paidPendingInterest -
                        payment.paidPendingInterest
                ),
                [`overallUnpaidInterest.${yearOfPayment}.${monthOfPayment}`]:
                    increment(
                        prevPayment.paidPendingInterest -
                            payment.paidPendingInterest
                    ),
            })
            const memberRef = doc(
                db,
                'team',
                loanProp.partnerCode,
                'members',
                loanProp.memberId
            )
            const monthOfLoan = monthOfPayment
            const yearOfLoan = yearOfPayment
            await updateDoc(memberRef, {
                modified: moment().unix(),
                modifiedBy: auth?.currentUser?.uid,
                [`memberLoanRemainingBalance.${yearOfLoan}.${monthOfLoan}`]:
                    increment(prevPayment.principal - payment.principal),
                [`memberLoanRemainingBalance.${yearOfLoan}.Annual`]: increment(
                    prevPayment.principal - payment.principal
                ),
                totalRemainingBalance: increment(
                    prevPayment.principal - payment.principal
                ),

                [`incomeContribution.${yearOfLoan}.${monthOfLoan}`]: increment(
                    prevInterest - currentInterest
                ),
                [`incomeContribution.${yearOfLoan}.Annual`]: increment(
                    prevInterest - currentInterest
                ),
                totalIncomeContribution: increment(
                    prevInterest - currentInterest
                ),
            })

            const {
                beginningBalance,
                interest,
                principal,
                endingBalance,
                amountPaid,
                paidPendingInterest,
                creation,
                modified,
                modifiedBy,
            } = payment
            const paymentRef = doc(
                db,
                LOANS_REPOSITORY,
                loanProp.id,
                PAYMENTS_REPOSITORY,
                payment.id as string
            )
            await updateDoc(paymentRef, {
                beginningBalance,
                interest,
                principal,
                endingBalance,
                amountPaid,
                paidPendingInterest,
                creation,
                modified,
                modifiedBy,
            })
            // add new payment
            // await this.addIncomeToMember(loanProp, payment, true)

            // const newYearOfPayment = moment
            //     .unix(payment.creation)
            //     .format('YYYY')
            // const newMonthOfPayment = moment
            //     .unix(payment.creation)
            //     .format('MMMM')
            // await this.addUnpaidInterestToSystem(
            //     loanProp.partnerCode,
            //     newYearOfPayment,
            //     newMonthOfPayment,
            //     payment.interest
            // )
            // await updateDoc(doc(db, LOANS_REPOSITORY, loanProp.id), {
            //     modified: moment().unix(),
            //     modifiedBy: auth?.currentUser?.uid,
            //     totalInterestPaid: increment(payment.interest),
            //     totalPrincipalPaid: increment(payment.principal),
            //     remainingBalance: increment(-payment.principal),
            //     pendingInterest: newPendingInterest,
            // })
        }

        // this.setStorage(key, loans)
        return true
    }

    async RemovePayment(
        loan: LoanPaymentProps & {
            id: string
        },
        payment: LoanPayment & {
            id: string
        }
        // prevPayment: LoanPayment
    ): Promise<
        | {
              totalInterestPaid: number
              totalPrincipalPaid: number
              remainingBalance: number
              pendingInterest: number
              id: string
          }
        | false
    > {
        try {
            const { partnerCode } = loan

            const teamRef = doc(db, TEAM_REPOSITORY, loan.partnerCode)
            const ref = doc(db, 'loans', loan.id)
            const paymentRef = doc(db, 'loans', loan.id, 'payments', payment.id)

            const memberRef = doc(
                db,
                TEAM_REPOSITORY,
                loan.partnerCode,
                'members',
                loan.memberId
            )
            const totalInterestPaid =
                payment.interest - payment.paidPendingInterest
            // loans[loanIdx].totalInterestPaid -=
            //     prevPayment.interest - prevPayment.paidPendingInterest
            let pendingInterest = 0
            const yearOfPayment = moment.unix(payment.creation).format('YYYY')
            const monthOfPay = moment.unix(payment.creation).format('MMMM')

            const monthOfLoan = monthOfPay
            const yearOfLoan = yearOfPayment
            if (payment.modifiedBy === 'system' && payment.loanPenalty) {
                pendingInterest += payment.interest
                // loans[loanIdx].pendingInterest += prevPayment.interest

                await this.addUnpaidInterestToSystem(
                    partnerCode,
                    yearOfPayment,
                    monthOfPay,
                    payment.interest,
                    true
                )
            } else {
                await updateDoc(memberRef, {
                    modified: moment().unix(),
                    modifiedBy: auth?.currentUser?.uid,
                    [`memberLoanRemainingBalance.${yearOfLoan}.${monthOfLoan}`]:
                        increment(payment.principal),
                    [`memberLoanRemainingBalance.${yearOfLoan}.Annual`]:
                        increment(payment.principal),
                    totalRemainingBalance: increment(payment.principal),

                    [`incomeContribution.${yearOfLoan}.${monthOfLoan}`]:
                        increment(-totalInterestPaid),
                    [`incomeContribution.${yearOfLoan}.Annual`]: increment(
                        -totalInterestPaid
                    ),
                    totalIncomeContribution: increment(-totalInterestPaid),
                })
            }
            const totalPrincipalPaid = -payment.principal
            const remainingBalance = payment.principal
            // const payments = payments
            // this.setStorage(loanKey, loans)
            await deleteDoc(paymentRef)
            await updateDoc(ref, {
                totalInterestPaid: increment(-totalInterestPaid),
                totalPrincipalPaid: increment(-payment.principal),
                remainingBalance: increment(payment.principal),
                pendingInterest: increment(payment.paidPendingInterest),
                payments: arrayRemove(payment.id),
            })

            // await this.addIncomeToMember(loan, payment, true, true)

            // await updateDoc(memberRef, {
            //     modified: moment().unix(),
            //     modifiedBy: auth?.currentUser?.uid,
            //     // [`memberLoan.${yearOfPayment}.${monthOfPayment}`]:
            //     //     increment(amountVal),
            //     // [`memberLoan.${yearOfPayment}.Annual`]: increment(amountVal),
            //     [`memberLoanRemainingBalance.${yearOfLoan}.${monthOfLoan}`]:
            //         increment(payment.principal),
            //     [`memberLoanRemainingBalance.${yearOfLoan}.Annual`]: increment(
            //         payment.principal
            //     ),
            //     // totalLoan: increment(amountVal),
            //     totalRemainingBalance: increment(payment.principal),
            // })
            await updateDoc(teamRef, {
                modified: moment().unix(),
                modifiedBy: auth?.currentUser?.uid,
                [`overallIncome.${yearOfPayment}.Annual`]: increment(
                    -totalInterestPaid
                ),
                [`overallIncome.${yearOfPayment}.${monthOfPay}`]: increment(
                    -totalInterestPaid
                ),
                [`overallPrincipal.${yearOfPayment}.Annual`]: increment(
                    -payment.principal
                ),
                [`overallPrincipal.${yearOfPayment}.${monthOfPay}`]: increment(
                    -payment.principal
                ),
                [`remainingBalance.${yearOfPayment}.Annual`]: increment(
                    payment.principal
                ),
                [`remainingBalance.${yearOfPayment}.${monthOfPay}`]: increment(
                    payment.principal
                ),
                [`overallUnpaidInterest.${yearOfPayment}.Annual`]: increment(
                    payment.paidPendingInterest
                ),
                [`overallUnpaidInterest.${yearOfPayment}.${monthOfPay}`]:
                    increment(payment.paidPendingInterest),
            })

            return {
                totalInterestPaid: -totalInterestPaid,
                totalPrincipalPaid: totalPrincipalPaid,
                remainingBalance: remainingBalance,
                pendingInterest: pendingInterest,
                id: loan.id,
            }
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
        // const loanKey = LOANS_REPOSITORY
        // const loans: Loan[] = this.getStorage(loanKey)
        // if (loans) {
        //     const loanIdx = loans.findIndex((loan) => loan.id === loanId)
        //     if (loans[loanIdx]) {
        //         try {
        //             const payments = loans[loanIdx].payments
        //             const paymentIdx = payments.findIndex(
        //                 (payment) => payment.id === paymentId
        //             )
        //             if (!payments[paymentIdx]) return undefined
        //             const prevPayment = payments[paymentIdx]
        //             payments.splice(paymentIdx, 1)
        //             loans[loanIdx].totalInterestPaid -=
        //                 prevPayment.interest - prevPayment.paidPendingInterest
        //             if (
        //                 prevPayment.modifiedBy !== 'system' &&
        //                 prevPayment.loanPenalty
        //             ) {
        //                 loans[loanIdx].pendingInterest += prevPayment.interest

        //                 const newYearOfPay = moment
        //                     .unix(prevPayment.creation)
        //                     .format('YYYY')
        //                 this.addUnpaidInterestToSystem(
        //                     newYearOfPay,
        //                     prevPayment.interest
        //                 )
        //             }
        //             loans[loanIdx].totalPrincipalPaid -= prevPayment.principal
        //             loans[loanIdx].remainingBalance += prevPayment.principal
        //             loans[loanIdx].payments = payments
        //             this.setStorage(loanKey, loans)

        //             this.addIncomeToMember(loans[loanIdx], prevPayment, true)
        //             return loans[loanIdx]
        //         } catch (err) {
        //             // eslint-disable-next-line no-console
        //             console.log(err)
        //         }
        //     }
        // }
        // return undefined
    }

    async getLoanPayments(loanId: string): Promise<LoanPayment[]> {
        const ref = collection(
            db,
            LOANS_REPOSITORY,
            loanId,
            PAYMENTS_REPOSITORY
        )
        const q = query(ref, where('isRemoved', '==', false))
        const doc = await getDocs(q)

        return doc.docs.map(
            (docSnap) =>
                ({
                    ...docSnap.data(),
                    id: docSnap.id,
                } as LoanPayment)
        )
    }
}
