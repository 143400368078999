import 'antd/dist/antd.css'
import { ConfigProvider } from 'antd'
import RouteManager from './app/RouteManager'
import { Provider } from 'react-redux'
import { store } from './app/store'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import en from 'antd/lib/locale/en_US'
// import { useEffect } from 'react'
// import { systemInit } from './app/redux/system.slice'
import './app/boot'
// import { enableMultiTabIndexedDbPersistence } from 'firebase/firestore'
// import { useEffect, useState } from 'react'
// import { db } from './app/boot'

const App = () => {
    // const [isReady, setIsReady] = useState(false)
    // useEffect(() => {
    //     const main = async () => {
    //         try {
    //             await enableMultiTabIndexedDbPersistence(db)
    //         } catch (err) {
    //             // eslint-disable-next-line no-console
    //             console.log(err);

    //         }
    //         // await disableNetwork(db)
    //         setIsReady(true)
    //         // await enableNetwork(db)
    //     }
    //     main()
    // }, [])
    if (import.meta.env.NODE_ENV === 'production')
        console.log = function no_console() {}
    return (
        <Provider store={store}>
            <ConfigProvider locale={en}>
                <RouteManager />
            </ConfigProvider>
        </Provider>
    )
}
export default App
