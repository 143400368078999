import { ModalForm, ProFormText } from '@ant-design/pro-components'
import { Col, Form, Row, Tag, Typography, message } from 'antd'
import { useAppSelector } from '../../../store'
import { shallowEqual } from 'react-redux'

function NewExpense() {
    const { expenseCategories, styles } = useAppSelector(
        (state) => ({
            expenseCategories: state.systemConfig.expenseCategories,
            styles: state.styles,
        }),
        shallowEqual
    )
    // const dispatch = useAppDispatch()

    const [form] = Form.useForm()
    const handleFieldChange = () => {}
    const handleCancel = () => {}
    const handleSubmit = async (data: any) => {
        // eslint-disable-next-line no-console
        console.log(data)
    }
    return (
        <div>
            <ModalForm
                form={form}
                onFinishFailed={(err) => {
                    if (err.errorFields.length) {
                        const firstError = err.errorFields[0]
                        message.destroy()
                        message.error(firstError.errors)
                        form.scrollToField(firstError.name, {
                            behavior: 'smooth',
                            block: 'center',
                        })
                    }
                }}
                title={
                    <Typography.Title level={3}>Title here</Typography.Title>
                }
                autoFocusFirstInput
                modalProps={{
                    onCancel: handleCancel,
                    okText: 'Submit',
                    centered: true,
                }}
                onFinish={handleSubmit}
                onValuesChange={handleFieldChange}
                width={300}
                submitter={{
                    submitButtonProps: {
                        style: {
                            backgroundColor: styles.primary,
                            color: 'white',
                        },
                    },
                }}
            >
                <Row>
                    {expenseCategories.map((expenseCategory: string) => (
                        <Col>
                            <Tag>{expenseCategory}</Tag>
                        </Col>
                    ))}
                </Row>
                <Row wrap={false}>
                    <Col>
                        <div style={{ marginLeft: 20 }}>
                            <ProFormText
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                                width="sm"
                                name="category"
                                label="Expense Category"
                                placeholder="Please Enter"
                            />
                        </div>
                    </Col>
                </Row>
            </ModalForm>
        </div>
    )
}

export default NewExpense
