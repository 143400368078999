import { ProCard } from '@ant-design/pro-components'
import { Row, Col, Statistic, Grid } from 'antd'
import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { TeamMember } from '../../../../domain/entities/Team'
import { getTeamMember } from '../../../redux/team/team.action'
import { useAppSelector } from '../../../store'
import YearSelection from '../../Reports/MembersReceivableReport/components/YearSelection'

const MyTotalReceivable = () => {
    const [currentData, setCurrentData] = useState({
        totalReceivable: 0,
        totalIncome: 0,
        totalShares: 0,
        totalDeductions: 0,
        totalRemainingBalance: 0,
        overallLoan: 0,
        totalWeightedShares: 0,
    })
    const { xs } = Grid.useBreakpoint()
    const [myData, setMyData] = useState<TeamMember>()
    const { activeTeam, memberId, currentYear, deductionFormula } =
        useAppSelector(
            (state) => ({
                // shareDividendFrequency: state.systemConfig.shareDividendFrequency,
                // deductionFrequency: state.systemConfig.deductionFrequency,
                deductionFormula: state.systemConfig.deductionFormula,
                activeTeam: state.auth.user.activeTeam,
                currentYear: state.systemConfig.currentYear,
                // teamData: state.team.selectedTeamData,
                memberId: state.team.currentTeamPermission.memberId,
            }),
            shallowEqual
        )
    // const dispatch = useAppDispatch()
    const getMyData = async () => {
        const data = await getTeamMember({
            partnerCode: activeTeam,
            memberId,
        })
        if (!data) return
        setMyData(data)
    }

    useEffect(() => {
        getMyData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam, memberId])

    useEffect(() => {
        if (!myData) return

        const annualData = {
            totalExpenses: 0,
            totalRemainingBalance: 0,
            // totalUnpaidInterest: 0,
            totalWeightedShares: 0,
            // totalPrincipalPaid: 0,
            overallLoan: 0,
            totalShares: 0,
            totalIncome: 0,
            totalReceivable: 0,
            totalDeductions: 0,
        }
        // const member: TeamMember = myData
        // if (
        //     teamData.overallExpenses[currentYear] &&
        //     teamData.overallExpenses[currentYear]['Annual']
        // ) {
        //     annualData.totalExpenses =
        //         teamData.overallExpenses[currentYear]['Annual']
        // }
        // if (
        //     member.memberShare[currentYear] &&
        //     member.memberShare[currentYear]['Annual']
        // ) {
        //     annualData.totalShares = member.memberShare[currentYear]['Annual']
        // }

        // if (
        //     member.incomeContribution[currentYear] &&
        //     member.incomeContribution[currentYear]['Annual']
        // ) {
        //     annualData.totalIncome =
        //         member.incomeContribution[currentYear]['Annual']
        // }

        // if (
        //     member.memberLoanRemainingBalance[currentYear] &&
        //     member.memberLoanRemainingBalance[currentYear]['Annual']
        // ) {
        //     annualData.totalRemainingBalance =
        //         member.memberLoanRemainingBalance[currentYear]['Annual']
        // }

        // if (
        //     member.weightedMemberShare[currentYear] &&
        //     member.weightedMemberShare[currentYear]['Annual']
        // ) {
        //     annualData.totalWeightedShares =
        //         member.weightedMemberShare[currentYear]['Annual']
        // }

        // if (
        //     member.memberLoan[currentYear] &&
        //     member.memberLoan[currentYear]['Annual']
        // ) {
        //     annualData.overallLoan = member.memberLoan[currentYear]['Annual']
        // }

        const annualWeightedAverage = parseFloat(
            annualData.totalWeightedShares.toFixed(2)
        )
        const annualContribution = parseFloat(annualData.totalShares.toFixed(2))

        // -----------------
        // team data
        const annualTeamData = {
            totalWeightedShares: 0,
            totalIncome: 0,
        }

        // if (
        //     teamData.overallIncome[currentYear] &&
        //     teamData.overallIncome[currentYear]['Annual']
        // ) {
        //     annualTeamData.totalIncome =
        //         teamData.overallIncome[currentYear]['Annual']
        // }

        // if (
        //     teamData.weightedMemberShares[currentYear] &&
        //     teamData.weightedMemberShares[currentYear]['Annual']
        // ) {
        //     annualTeamData.totalWeightedShares =
        //         teamData.weightedMemberShares[currentYear]['Annual']
        // }
        let percentage =
            annualWeightedAverage / annualTeamData.totalWeightedShares || 0
        percentage = parseFloat(percentage.toFixed(2))
        const dividendByWeightedShare = parseFloat(
            Math.round(percentage * annualTeamData.totalIncome).toFixed(2)
        )
        const gross = parseFloat(
            Math.round(annualContribution + dividendByWeightedShare).toFixed(2)
        )
        let totalDeductions = 0

        if (deductionFormula === 'equal') {
            totalDeductions = 0
        }
        if (deductionFormula === 'percentage') {
            totalDeductions = parseFloat(
                Math.round(percentage * annualData.totalExpenses).toFixed(2)
            )
        }
        const net = parseFloat(Math.round(gross - totalDeductions).toFixed(2))
        annualData.totalReceivable = net
        // annualData.overallLoan = sysData.overallLoan[currentYear]

        // setCashInHand({
        //     totalIncome: annualData.totalIncome,
        //     totalShares: annualData.totalShares,
        //     totalUnpaidInterest: annualData.totalUnpaidInterest,
        //     totalRemainingBalance: annualData.totalRemainingBalance
        // })
        // setCashInHand(
        //     annualData.totalIncome +
        //         annualData.totalShares -
        //         annualData.totalRemainingBalance -
        //         annualData.totalUnpaidInterest
        // )
        setCurrentData(annualData)
    }, [
        myData,
        currentYear,
        // teamData.overallExpenses,
        deductionFormula,
        // teamData.overallIncome,
        // teamData.weightedMemberShares,
    ])

    return (
        <div className="page-layout">
            <YearSelection />
            <Row justify={xs ? 'start' : 'space-evenly'}>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <ProCard
                        hoverable
                        style={{
                            cursor: 'default',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bordered
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title="Receivable"
                            value={currentData.totalReceivable}
                            prefix="₱"
                            precision={2}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                                color: '#3f8600',
                            }}
                        />
                    </ProCard>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <ProCard
                        hoverable
                        style={{
                            cursor: 'default',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bordered
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title="Deductions"
                            value={currentData.totalDeductions}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                                color: '#cf1322',
                            }}
                            prefix="-₱"
                            precision={2}
                        />
                    </ProCard>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <ProCard
                        hoverable
                        style={{
                            cursor: 'default',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bordered
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title="Total Remaining Balance"
                            value={myData?.totalRemainingBalance || 0}
                            prefix="₱"
                            precision={2}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                        />
                    </ProCard>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <ProCard
                        hoverable
                        style={{
                            cursor: 'default',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bordered
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title="Loan Interest Paid"
                            value={myData?.totalIncomeContribution || 0}
                            prefix="₱"
                            precision={2}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                        />
                    </ProCard>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <ProCard
                        hoverable
                        style={{
                            cursor: 'default',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bordered
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title="Total Capital Share"
                            value={myData?.memberShares}
                            prefix="₱"
                            precision={2}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                        />
                    </ProCard>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <ProCard
                        hoverable
                        style={{
                            cursor: 'default',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bordered
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title="Total Preferred Share"
                            value={myData?.memberPreferredShares || 0}
                            prefix="₱"
                            precision={2}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                        />
                    </ProCard>
                </Col>
                {/* <Col span={12}>
                    <ProCard
                        hoverable
                        style={{
                            cursor: 'default',
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bordered
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                    >
                        <Statistic
                            title="Weighted Share Contribution"
                            value={currentData.totalWeightedShares}
                            prefix="₱"
                            precision={2}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                        />
                    </ProCard>
                </Col> */}
            </Row>
        </div>
    )
}

export default MyTotalReceivable
