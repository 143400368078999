import {
    Button,
    Card,
    Col,
    Dropdown,
    Grid,
    // Image,
    Menu,
    Modal,
    Row,
    Statistic,
    Tooltip,
    Typography,
    message,
} from 'antd'

import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../app/store'
// import { VEHICLE_REPOSITORY, PERSONNEL_REPOSITORY } from '../../../data/repositories/constants'
import {
    // ArrowUpOutlined,
    // ArrowDownOutlined,
    EllipsisOutlined,
    ArrowLeftOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
} from '@ant-design/icons'
import {
    ProForm,
    ProFormSelect,
    ProFormTextArea,
    ProFormDateTimePicker,
    ProFormDigit,
    ProFormCheckbox,
} from '@ant-design/pro-components'
import {
    collection,
    getDoc,
    doc,
    updateDoc,
    addDoc,
    // onSnapshot,
    increment,
    arrayUnion,
} from 'firebase/firestore'
// import { PhoneNumberFormat } from 'google-libphonenumber'
import moment from 'moment'
import { BiStopCircle } from 'react-icons/bi'
// import { PhoneNumberUtil } from 'google-libphonenumber'
import { MdOutlineChangeCircle } from 'react-icons/md'
import { shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { createPeerMentorPairs, checkForDuplicates } from './suggestUtil'
import { Personnel, Trip, Vehicle } from '../../../domain/entities/Transport'
import { db, storage } from '../../boot'
import {
    PERSONNEL_ATTENDANCE_REPOSITORY,
    TRIPS_REPOSITORY,
    VEHICLE_REPOSITORY,
} from '../../../data/repositories/constants'
import { ref, getDownloadURL, uploadString } from 'firebase/storage'
import Table from '../../../app/components/Table'
import { getAttendance } from './scheduleUtils'
import EmployeeAvatar from '../../../app/components/EmployeeAvatar'
// import { Attendance } from '@/domain/entities/Attendance'
// import { checkCurrentReservation } from './scheduleUtils'
// import { getUserRoute } from '../../../app/routes/helpers'
// import { updateVehicleState } from '../../../app/redux/vehicle/vehicle.slice'
// const phoneUtil = PhoneNumberUtil.getInstance()

function Scheduling(props: any) {
    const { vehicleIds } = useAppSelector(
        (state) => ({
            vehicleIds: state.vehicle.vehicles.ids,
        }),
        shallowEqual
    )
    const navigate = useNavigate()
    const { xs } = Grid.useBreakpoint()
    return (
        <div className="page-layout">
            <Row align={'middle'} justify={'space-between'}>
                <Col>
                    <Button type="link">
                        <ArrowLeftOutlined
                            style={{ fontSize: 32 }}
                            onClick={() => navigate(-1)}
                        />
                    </Button>
                </Col>
                <Col>
                    <Typography.Title
                        level={xs ? 2 : 1}
                        style={{ textAlign: 'center', fontWeight: '400' }}
                    >
                        {moment().format('MMMM DD, YYYY')}
                    </Typography.Title>
                </Col>
                <Col></Col>
            </Row>
            <Row gutter={[24, 46]}>
                {vehicleIds.map((vehicleId) => (
                    <VehicleItem key={vehicleId} id={vehicleId as string} />
                ))}
            </Row>
        </div>
    )
}

interface NewVehicle extends Vehicle {
    driver?: Personnel & {
        rank: number
    }
    assistant?: Personnel & {
        rank: number
    }
}
interface VehicleItemProps {
    id: string
}
const VehicleItem = ({ id }: VehicleItemProps) => {
    const { styles, vehicle, email, activeTeam, assistants, drivers } =
        useAppSelector(
            (state) => ({
                email: state.auth.user.email,
                activeTeam: state.team.selectedTeamData.partnerCode,
                assistants: state.team.assistants,
                drivers: state.team.drivers,
                selectedTeamData:
                    state.team.selectedTeamData.busPersonnelSalaryPercentage ||
                    false,
                styles: state.styles,
                vehicle: state.vehicle.vehicles.entities[id] as NewVehicle,
            }),
            shallowEqual
        )

    const [sched, setSched] = useState<
        | (Vehicle & {
              driver: Personnel & {
                  rank: number
              }
              assistant: Personnel & {
                  rank: number
              }
          })
        | undefined
    >(undefined)

    useEffect(() => {
        if (!vehicle) return
        setSched(vehicle as any)
    }, [vehicle])

    const signatureRef = useRef()
    const [loading, setLoading] = useState(false)
    // const [vehicle, setVehicle] = useState<Vehicle | undefined>(undefined)
    // const [selectedTrip, setSelectedTrip] = useState<any | undefined>(undefined)

    const [startForm] = ProForm.useForm()
    const [remittanceDrawerOpen, setRemittanceDrawerOpen] = useState(false)
    const [endDrawerOpen, setEndDrawerOpen] = useState(false)
    const [startDrawerOpen, setStartDrawerOpen] = useState(false)
    const [endForm] = ProForm.useForm()
    const [remittanceForm] = ProForm.useForm()
    const [expenses, setExpenses] = useState({
        gasCost: 0,
        miscellaneousExpenses: 0,
        driverSalary: 0,
        assistantSalary: 0,
        driverIncentives: 0,
        assistantIncentives: 0,
        driverOvertimePay: 0,
        assistantOvertimePay: 0,
    })
    const closeEndDrawer = () => {
        setEndDrawerOpen(false)
    }

    const closeStartDrawer = () => {
        setStartDrawerOpen(false)
    }

    const closeRemittanceDrawer = () => {
        setRemittanceDrawerOpen(false)
        // setSelectedTrip(undefined)
        // setVehicle(undefined)
        remittanceForm.resetFields()
        clearSignature()
    }
    const handleStartActiveTrip = async () => {
        // eslint-disable-next-line no-console
        // console.log(vehicle)
        // setSelectedTrip(vehicle)
        // setVehicle(vehicle)
        // startForm.resetFields()
        // startForm.setFieldValue('date', vehicle.date)

        // startForm.setFieldValue('notes', vehicle.notes)
        if (sched && sched.currentDriverId) {
            startForm.setFieldValue('previousDriverId', sched.currentDriverId)
            startForm.setFieldValue(
                'previousAssistantId',
                sched.currentAssistantId
            )
        }
        setStartDrawerOpen(true)
    }
    const showEndTripVehicle = async () => {
        // eslint-disable-next-line no-console
        try {
            // setSelectedTrip(vehicle)
            // setVehicle(vehicle)
            endForm.resetFields()
            clearSignature()
            // @ts-ignore
            const driverData = vehicle?.driver
            // @ts-ignore
            const assistantData = vehicle?.assistant
            if (driverData && !driverData.salary) {
                message.error('Driver has no salary')
            }
            if (assistantData && !assistantData.salary) {
                message.error('Assistant has no salary')
            }
            endForm.setFieldsValue({
                notes: vehicle?.temporaryShiftDetails?.notes || '',
                driverSalary: driverData?.salary,
                assistantSalary: assistantData?.salary,
            })
            let additionals = getDriverAssistantTripExpense()
            // if (assistantData.salary && driverData.salary) {
            //     additionals = assistantData.salary + assistantData.dailyAllowance + driverData.salary + driverData.dailyAllowance
            // }
            setTotalRevenue(sched?.temporaryShiftDetails?.totalRevenue || 0)
            setTotalExpense(
                sched?.temporaryShiftDetails?.totalExpenses || 0 + additionals
            )
            // setShort(sched.temporaryShiftDetails?.totalShort || 0)
            // eslint-disable-next-line no-console
            // console.log(vehicle, additionals)
            setEndDrawerOpen(true)
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err)
        }
    }

    const onSubmitStartTrip = async (values: any) => {
        // eslint-disable-next-line no-console
        // console.log(values, vehicle)
        if (sched && sched.attendanceId && sched.attendanceData) {
            const newReservations = [...sched.reservations]
            const currentReservationIndex = sched.currentReservationIndex
            if (typeof currentReservationIndex === 'number') {
                const newAttendanceData = {
                    ...sched.attendanceData,
                    ...values,
                    driverId: values.currentDriverId,
                    assistantId: values.currentAssistantId,
                    forAbsent: true,
                }

                newReservations[currentReservationIndex].driverId =
                    newAttendanceData.driverId
                newReservations[currentReservationIndex].assistantId =
                    newAttendanceData.assistantId
                // if(reserveData){
                if (newReservations[currentReservationIndex].driverId) {
                    const driverRef = doc(
                        db,
                        'personnel',
                        newReservations[currentReservationIndex]
                            .driverId as string
                    )
                    const snap = await getDoc(driverRef)
                    const driverData = snap.data()
                    if (driverData) {
                        newReservations[currentReservationIndex].driverName =
                            driverData.lastName || ''
                        newReservations[currentReservationIndex].title =
                            driverData.lastName || ''
                        newAttendanceData.driverName =
                            newReservations[currentReservationIndex].driverName
                    }
                }
                if (newReservations[currentReservationIndex].assistantId) {
                    const assistantRef = doc(
                        db,
                        'personnel',
                        newReservations[currentReservationIndex]
                            .assistantId as string
                    )
                    const snap = await getDoc(assistantRef)
                    const assistantData = snap.data()
                    if (assistantData) {
                        newReservations[currentReservationIndex].assistantName =
                            assistantData.lastName || ''
                        newReservations[currentReservationIndex].title =
                            newReservations[currentReservationIndex].title +
                                ' - ' +
                                assistantData.lastName || ''
                        newAttendanceData.title =
                            newReservations[currentReservationIndex].title
                        newAttendanceData.assistantName =
                            newReservations[
                                currentReservationIndex
                            ].assistantName
                    }
                }
                // eslint-disable-next-line no-console
                // console.log(
                //     sched.reservations,
                //     newReservations,
                //     newAttendanceData
                // )
                const attendanceRef = collection(
                    db,
                    PERSONNEL_ATTENDANCE_REPOSITORY
                )
                const resp = await addDoc(attendanceRef, newAttendanceData)
                await updateDoc(
                    doc(db, VEHICLE_REPOSITORY, vehicle?.id as string),
                    {
                        ...values,
                        attendanceId: resp.id,
                        attendanceData: {
                            ...newAttendanceData,
                            id: resp.id,
                        },
                        currentDriverId: values.currentDriverId,
                        currentAssistantId: values.currentAssistantId,
                        reservations: newReservations,
                        status: 'occupied',
                        modified: moment().unix(),
                        modifiedBy: email,
                    }
                )

                setSched({
                    ...(sched as any),
                    ...values,
                    attendanceId: resp.id,
                    attendanceData: {
                        ...newAttendanceData,
                        id: resp.id,
                    },
                    currentDriverId: values.currentDriverId,
                    currentAssistantId: values.currentAssistantId,
                    reservations: newReservations,
                    status: 'occupied',
                    modified: moment().unix(),
                    modifiedBy: email,
                })
            }
            if (values.forAbsent) {
                await updateDoc(
                    doc(
                        db,
                        PERSONNEL_ATTENDANCE_REPOSITORY,
                        sched.attendanceId as string
                    ),
                    {
                        ...values,
                        status: 'absent',
                        modified: moment().unix(),
                        modifiedBy: email,
                    }
                )
            }
        } else {
            await updateDoc(
                doc(db, VEHICLE_REPOSITORY, vehicle?.id as string),
                {
                    ...values,
                    status: 'occupied',
                    modified: moment().unix(),
                    modifiedBy: email,
                }
            )

            setSched({
                ...(sched as any),
                ...values,
                status: 'occupied',
                modified: moment().unix(),
                modifiedBy: email,
            })
        }
        setLoading(false)
        message.success('Successfully changed driver/assistant.')
        setStartDrawerOpen(false)
        startForm.resetFields()
    }

    const getDriverAssistantTripExpense = () => {
        const driverSalary = vehicle?.driver?.salary || 0
        const assistantSalary = vehicle?.assistant?.salary || 0

        return (
            driverSalary + assistantSalary
            // driverAllowance +
            // assistantAllowance
        )
    }

    const cancelEndTrip = () => {
        setEndDrawerOpen(false)
        endForm.resetFields()
        setTotalExpense(0)
    }
    const onSubmitEndTrip = async (values: any) => {
        setLoading(true)
        try {
            const strt = moment(values?.shiftStartTime, 'MMMM DD, YYYY HH:mm')
            const end = moment(values?.shiftEndTime, 'MMMM DD, YYYY HH:mm')

            const dataUrl = await handleUploadChange({
                name: 'end-shift-confirmation',
                // @ts-ignore
                file: signatureRef?.current.toDataURL(),
            })

            const payload: Trip = {
                ...values,
                creation: moment().unix(),
                modified: moment().unix(),
                timeOut: '',
                startOvertime: '',
                endOvertime: '',
                // totalRoundTrips,
                paymentStatus: 'pending',
                status: 'done',
                amountCollected: 0,
                isRemoved: false,
                modifiedBy: email,
                partnerCode: activeTeam,
                timeIn: '',
                date: strt.format('ddd, MMMM DD, YYYY'),
                driverId: vehicle?.driver?.id as string,
                assistantId: vehicle?.assistant?.id as string,
                vehicleId: vehicle?.id as string,
                notes: values.notes,
                driverPayoutStatus: 'pending',
                assistantPayoutStatus: 'pending',
                hours: 0,
                shiftStartTime: strt.unix(),
                // totalGasConsumed: totalGasConsumed + pendingGasConsumed,
                // totalGasCost: gasCost + pendingGasCost,
                distanceTraveled: 0,
                totalPassengers: 0,
                shiftEndTime: end.unix(),
                totalExpenses: totalExpense,
                // cashCollected: cash + pendingCashCollected,
                // miscellaneousExpenses,
                maintenanceFee: 0,
                totalRevenue,
                // cardRevenue: card + pendingCardRevenue,
                // driverSalary,
                // assistantSalary,
                // dailyQuotaIncentives: dailyQuotaIncentives + pendingIncentives,
                // dispatchingCost: dispatchingCost + pendingDispatchingCost,
                assistant: sched?.assistant,
                driver: sched?.driver,
                // driverAllowance,
                // assistantAllowance,
                shiftHistory: vehicle?.temporaryShiftDetails?.history || [],
                signatureUrl: dataUrl,
                // totalCashReceived: totalCashReceived + pendingTotalCashReceived,
                title: `${vehicle?.title} - ${vehicle?.routeName}`,
                // driverAllowance: 0,
                // assistantAllowance: 0,
                // overtimeHours,
            }

            const attendanceUpdatePayload = {
                totalCashReceived: payload.totalCashReceived || 0,
                totalExpenses: payload.totalExpenses || 0,
                totalGasConsumed: payload.totalGasConsumed || 0,
                totalGasCost: payload.totalGasCost || 0,
                totalPassengers: payload.totalPassengers || 0,
                totalRevenue: payload.totalRevenue || 0,
                totalRoundTrips: payload.totalRoundTrips || 0,
                driverIncentives: payload.driverIncentives || 0,
                driverOvertimeHours: payload.driverOvertimeHours || 0,
                driverOvertimePay: payload.driverOvertimePay || 0,
                driverSalary: payload.driverSalary || 0,
                assistantIncentives: payload.assistantIncentives || 0,
                assistantOvertimeHours: payload.assistantOvertimeHours || 0,
                assistantOvertimePay: payload.assistantOvertimePay || 0,
                assistantSalary: payload.assistantSalary || 0,
                cardRevenue: payload.cardRevenue || 0,
                cashCollected: payload.cashCollected || 0,
                dispatchingCost: payload.dispatchingCost || 0,
                timeIn: payload.shiftStartTime || 0,
                timeOut: payload.shiftEndTime || 0,
                miscellaneousExpenses: payload.miscellaneousExpenses || 0,
                partnerCode: payload.partnerCode,
                assistantSss: vehicle.driver?.sss || '',
                assistantPhilhealth: vehicle.driver?.philhealth || '',
                assistantPagibig: vehicle.driver?.pagibig || '',
                driverSss: vehicle.driver?.sss || '',
                driverPhilhealth: vehicle.driver?.philhealth || '',
                driverPagibig: vehicle.driver?.pagibig || '',
                signatureUrl: payload.signatureUrl || '',
                status: 'done',
            }
            if (
                vehicle.attendanceData &&
                vehicle.attendanceData.date === strt.format('MMM DD, YYYY')
            ) {
                const ref = doc(
                    db,
                    PERSONNEL_ATTENDANCE_REPOSITORY,
                    vehicle.attendanceData.id as string
                )
                await updateDoc(ref, {
                    ...attendanceUpdatePayload,
                })
                // eslint-disable-next-line no-console
                // console.log(attendanceUpdatePayload, vehicle)
            } else {
                const attendance = await getAttendance(
                    payload.driverId,
                    payload.assistantId,
                    strt.unix()
                )
                // eslint-disable-next-line no-console
                // console.log(attendance)
                if (attendance) {
                    const ref = doc(
                        db,
                        PERSONNEL_ATTENDANCE_REPOSITORY,
                        attendance.id as string
                    )
                    await updateDoc(ref, {
                        ...attendanceUpdatePayload,
                    })
                }
            }

            // @ts-ignore
            // eslint-disable-next-line no-console
            // console.log(payload)
            await addDoc(collection(db, TRIPS_REPOSITORY), payload)

            await updateDoc(
                doc(db, VEHICLE_REPOSITORY, vehicle?.id as string),
                {
                    // ...values,
                    status: 'available',
                    currentDriverId: '',
                    currentAssistantId: '',
                    modified: moment().unix(),
                    modifiedBy: email,
                }
            )
            setSched({
                ...(sched as any),
                status: 'available',
                currentDriverId: '',
                currentAssistantId: '',
                modified: moment().unix(),
                modifiedBy: email,
            })
            message.success('Successfully end trip')
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err)
        }
        setLoading(false)
        cancelEndTrip()
    }
    const handleUploadChange = async (
        file: { name: string; file: any },
        callback?: (progress: number) => void
    ) => {
        // eslint-disable-next-line no-console

        const logoRef = ref(
            storage,
            `/${activeTeam}/schedulingremittance/${moment().unix()}/${
                file.name + '.png'
            }`
        )
        const uploadTask = await uploadString(logoRef, file.file, 'data_url', {
            contentType: 'image/png',
        })

        const url = await getDownloadURL(uploadTask.ref)
        return url
    }
    const onSubmitRemittance = async (values: any) => {
        setLoading(true)
        try {
            const cashCollected = parseFloat(values?.cashCollected || '0')
            const card = parseFloat(values?.cardRevenue || '0')
            const gasCost = parseFloat(values?.totalGasCost || '0')
            const totalGasConsumed = parseFloat(values?.totalGasConsumed || '0')
            const totalCashReceived = parseFloat(
                values?.totalCashReceived || '0'
            )
            const dispatchingCost = parseFloat(values?.dispatchingCost || '0')
            const dailyQuotaIncentives =
                parseFloat(values?.dailyQuotaIncentives || '0') * 2

            const totalRevenue = cashCollected + card
            // const dt = moment(data?.activeTrip?.date)
            // const strt = moment(values?.shiftStartTime, 'MMMM DD, YYYY h:mmA')
            // const end = moment(values?.shiftEndTime, 'MMMM DD, YYYY h:mmA')

            const totalExpenses =
                gasCost + dailyQuotaIncentives + dispatchingCost
            // await addDoc(collection(db, TRIPS_REPOSITORY), payload)

            // const payload = {
            //     ...values,
            //     totalExpenses,
            //     totalRevenue,
            // }

            const short = totalCashReceived - cashCollected
            // eslint-disable-next-line no-console
            // console.log(payload)
            // @ts-ignore
            // eslint-disable-next-line no-console
            // console.log(payload, signatureRef?.current.toData())
            const dataUrl = await handleUploadChange({
                name: 'add-remittance-confirmation',
                // @ts-ignore
                file: signatureRef?.current.toDataURL(),
            })
            // eslint-disable-next-line no-console
            // console.log(dataUrl)

            await updateDoc(
                doc(db, VEHICLE_REPOSITORY, vehicle?.id as string),
                {
                    [`temporaryShiftDetails.history`]: arrayUnion({
                        cashCollected: cashCollected,
                        cardRevenue: card,
                        totalGasCost: gasCost,
                        totalGasConsumed: totalGasConsumed,
                        dispatchingCost: dispatchingCost,
                        totalCashReceived: totalCashReceived,
                        totalExpenses: totalExpenses,
                        totalRevenue: totalRevenue,
                        notes: values.notes || '',
                        signatureUrl: dataUrl,
                        dailyQuotaIncentives,
                    }),
                    [`temporaryShiftDetails.totalExpenses`]:
                        increment(totalExpenses),
                    [`temporaryShiftDetails.totalIncentives`]:
                        increment(dailyQuotaIncentives),
                    [`temporaryShiftDetails.totalRevenue`]:
                        increment(totalRevenue),
                    [`temporaryShiftDetails.totalShort`]: increment(short),
                    [`temporaryShiftDetails.notes`]: values.notes || '',
                }
            )
            message.success('Remittance successfully added.')
            closeRemittanceDrawer()
        } catch (err) {
            if (err instanceof Error) {
                message.error(err.message)
            } else {
                message.error('An unknown error occurred.')
            }
        }
        setLoading(false)
        endForm.resetFields()
    }

    const closeRevenueBreakdown = () => {
        setRevenueBreakdown({
            open: false,
            data: undefined,
        })
    }
    const [revenueBreakdown, setRevenueBreakdown] = useState<{
        open: boolean
        data: any
    }>({
        open: false,
        data: undefined,
    })

    // eslint-disable-next-line no-console
    // console.log(vehicle.title, vehicle.routeName, vehicle)
    const [short, setShort] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [totalExpense, setTotalExpense] = useState(0)

    const clearSignature = () => {
        // @ts-ignore
        signatureRef?.current?.clear()
    }
    return sched && sched.partnerCode === activeTeam ? (
        <Col xs={24} md={12} key={sched.id}>
            <Modal
                open={revenueBreakdown.open}
                onCancel={closeRevenueBreakdown}
            >
                <Typography.Title level={4}>Revenue</Typography.Title>

                <Table
                    toolBarRender={false}
                    dataSource={
                        revenueBreakdown.data && revenueBreakdown.data.history
                            ? revenueBreakdown.data.history
                            : []
                    }
                    columns={[
                        {
                            dataIndex: 'cashCollected',
                            title: 'Cash Revenue',
                        },
                        {
                            dataIndex: 'cardRevenue',
                            title: 'Card Revenue',
                        },
                        {
                            dataIndex: 'totalCashReceived',
                            title: 'Cash Received',
                        },
                        {
                            dataIndex: 'totalRevenue',
                            title: 'Gross Revenue',
                        },
                        // {
                        //     dataIndex: 'signatureUrl',
                        //     title: 'Signature',
                        //     render(text: any) {
                        //         return <Image src={text} width={150} />
                        //     },
                        // },
                    ]}
                    scroll={{
                        x: 800,
                    }}
                />
                <Typography.Title level={4}>Expense</Typography.Title>

                <Table
                    scroll={{
                        x: 800,
                    }}
                    toolBarRender={false}
                    dataSource={
                        revenueBreakdown.data && revenueBreakdown.data.history
                            ? revenueBreakdown.data.history
                            : []
                    }
                    columns={[
                        {
                            dataIndex: 'totalIncentives',
                            title: 'Total Incentives',
                        },
                        {
                            dataIndex: 'totalGasCost',
                            title: 'GASOLINE',
                        },
                        {
                            dataIndex: 'totalExpenses',
                            title: 'Expense',
                        },
                    ]}
                />
            </Modal>
            <Modal
                destroyOnClose
                footer={false}
                title={`${vehicle?.title} | ${vehicle?.routeName}`}
                onCancel={closeStartDrawer}
                open={startDrawerOpen}
                getContainer={false}
            >
                <ProForm
                    initialValues={{
                        // driverPayoutStatus: false,
                        // assistantPayoutStatus: false,
                        notes: '',
                        date: moment(),
                        forAbsent: true,
                    }}
                    form={startForm}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            startForm.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    onFinish={onSubmitStartTrip}
                    submitter={{
                        resetButtonProps: {
                            style: {
                                display: 'none',
                            },
                        },
                    }}
                >
                    <ProForm.Item
                        label={
                            <Typography.Text style={{ fontWeight: 'bolder' }}>
                                Current Driver/Assistant
                            </Typography.Text>
                        }
                    >
                        <Row wrap={false} gutter={[24, 24]}>
                            <Col span={12}>
                                <ProFormSelect
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder={'Select driver'}
                                    label={'Driver'}
                                    fieldProps={{
                                        showSearch: true,
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                    name={'previousDriverId'}
                                    options={drivers.map((driver) => ({
                                        value: driver.id,
                                        label:
                                            driver.displayName ||
                                            driver.firstName +
                                                ' ' +
                                                driver.lastName,
                                    }))}
                                />
                            </Col>
                            <Col span={12}>
                                <ProFormSelect
                                    style={{
                                        width: '100%',
                                    }}
                                    fieldProps={{
                                        showSearch: true,
                                    }}
                                    placeholder={'Select Assistant'}
                                    label={'Assistant'}
                                    name={'previousAssistantId'}
                                    options={assistants.map((driver) => ({
                                        value: driver.id,
                                        label:
                                            driver.displayName ||
                                            driver.firstName +
                                                ' ' +
                                                driver.lastName,
                                    }))}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </ProForm.Item>
                    <ProForm.Item
                        label={
                            <Typography.Text style={{ fontWeight: 'bolder' }}>
                                New Driver/Assistant
                            </Typography.Text>
                        }
                    >
                        <Row wrap={false} gutter={[24, 24]}>
                            <Col span={12}>
                                <ProFormSelect
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder={'Select driver'}
                                    label={'Driver'}
                                    fieldProps={{
                                        showSearch: true,
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                    name={'currentDriverId'}
                                    options={drivers.map((driver) => ({
                                        value: driver.id,
                                        label:
                                            driver.displayName ||
                                            driver.firstName +
                                                ' ' +
                                                driver.lastName,
                                    }))}
                                />
                            </Col>
                            <Col span={12}>
                                <ProFormSelect
                                    style={{
                                        width: '100%',
                                    }}
                                    fieldProps={{
                                        showSearch: true,
                                    }}
                                    placeholder={'Select Assistant'}
                                    label={'Assistant'}
                                    name={'currentAssistantId'}
                                    options={assistants.map((driver) => ({
                                        value: driver.id,
                                        label:
                                            driver.displayName ||
                                            driver.firstName +
                                                ' ' +
                                                driver.lastName,
                                    }))}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </ProForm.Item>
                    <ProFormCheckbox name="forAbsent">
                        <Typography.Text style={{ fontWeight: 'bolder' }}>
                            For Absence?
                        </Typography.Text>
                    </ProFormCheckbox>
                    {/* <ProFormTextArea name="notes" label="Notes" /> */}
                </ProForm>
            </Modal>
            <Modal
                footer={false}
                title={`${vehicle?.title || ''}-${vehicle?.routeName || ''}`}
                onCancel={closeEndDrawer}
                open={endDrawerOpen}
                getContainer={false}
                okButtonProps={{
                    loading,
                }}
                destroyOnClose
                cancelButtonProps={{
                    loading,
                }}
                centered
            >
                <ProForm
                    form={endForm}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            endForm.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    onFinish={onSubmitEndTrip}
                    onValuesChange={(changed, vals) => {
                        // eslint-disable-next-line no-console
                        // console.log(changed, vals)
                        // const pendingCashReceived =
                        //     vehicle?.temporaryShiftDetails?.totalCashReceived ||
                        //     0
                        // const pendingCashCollected =
                        //     vehicle?.temporaryShiftDetails?.cashCollected || 0
                        // const pendingCashReceived = vehicle?.temporaryShiftDetails?.totalCashReceived
                        const cashCollected = vals['cashCollected'] || 0
                        const cardRevenue = vals['cardRevenue'] || 0
                        const totalCashReceived = vals['totalCashReceived'] || 0
                        const driverSalary = vals['driverSalary'] || 0
                        const driverOvertimeHours =
                            vals['driverOvertimeHours'] || 0
                        const overtimePayEquation = 1.25
                        const driverOvertimePay =
                            Math.round(
                                (driverSalary / 8) * overtimePayEquation
                            ) * driverOvertimeHours
                        endForm.setFieldsValue({
                            driverOvertimePay,
                        })
                        const assistantSalary = vals['assistantSalary'] || 0
                        const assistantOvertimeHours =
                            vals['assistantOvertimeHours'] || 0
                        const assistantOvertimePay =
                            Math.round(
                                (assistantSalary / 8) * overtimePayEquation
                            ) * assistantOvertimeHours

                        endForm.setFieldsValue({
                            assistantOvertimePay,
                        })
                        const totalGasCost = vals['totalGasCost'] || 0
                        const dispatchingCost = vals['dispatchingCost'] || 0
                        // const dailyQuotaIncentives =
                        //     vals['dailyQuotaIncentives'] || 0
                        const miscellaneousExpenses =
                            vals['miscellaneousExpenses'] || 0

                        const driverIncentives = vals['driverIncentives'] || 0
                        const assistantIncentives =
                            vals['assistantIncentives'] || 0
                        setExpenses({
                            driverIncentives,
                            assistantIncentives,
                            assistantSalary,
                            driverSalary,
                            gasCost: totalGasCost,
                            miscellaneousExpenses,
                            driverOvertimePay,
                            assistantOvertimePay,
                        })
                        const totalIncentives =
                            driverIncentives + assistantIncentives
                        // const pendingExpense =
                        //     vehicle?.temporaryShiftDetails?.totalExpenses || 0
                        // const pendingRevenue =
                        //     vehicle?.temporaryShiftDetails?.totalRevenue || 0
                        // const pendingShort =
                        //     pendingCashReceived - pendingCashCollected
                        setTotalExpense(
                            totalGasCost +
                                dispatchingCost +
                                totalIncentives +
                                miscellaneousExpenses +
                                driverSalary +
                                assistantSalary +
                                assistantOvertimePay +
                                driverOvertimePay
                        )
                        setTotalRevenue(cashCollected + cardRevenue)
                        // const currentExpense = dispatchingCost + dailyQuotaIncentives + miscellaneousExpenses
                        setShort(
                            totalCashReceived -
                                cashCollected +
                                miscellaneousExpenses +
                                dispatchingCost +
                                totalGasCost
                        )
                    }}
                >
                    {sched && sched.currentDriverId && sched.driver && (
                        <Row gutter={[0, 12]}>
                            <Col xs={24} md={12}>
                                <EmployeeAvatar employee={sched.driver} />
                            </Col>
                            <Col xs={24} md={12}>
                                <EmployeeAvatar employee={sched.assistant} />
                            </Col>
                        </Row>
                    )}
                    <br />
                    <ProForm.Item
                        label={
                            <Typography.Text style={{ fontWeight: 'bolder' }}>
                                Shift Details
                            </Typography.Text>
                        }
                    >
                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDateTimePicker
                                    fieldProps={{
                                        format: 'MMMM DD, YYYY HH:mm',
                                        // use12Hours: true,
                                        style: {
                                            width: '100%',
                                        },
                                    }}
                                    width={216}
                                    name={'shiftStartTime'}
                                    label={'Departed'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDateTimePicker
                                    fieldProps={{
                                        format: 'MMMM DD, YYYY HH:mm',
                                        // use12Hours: true,
                                        style: {
                                            width: '100%',
                                        },
                                    }}
                                    name={'shiftEndTime'}
                                    label={'Arrived'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={'₱'}
                                    name="cashCollected"
                                    label="Cash Revenue"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="cardRevenue"
                                    label="Card Revenue"
                                />
                            </Col>
                        </Row>

                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="totalCashReceived"
                                    label="Cash Received"
                                    // addonAfter={'each'}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="totalRoundTrips"
                                    label="Total Round Trips"
                                    // addonAfter={'each'}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="totalPassengers"
                                    label="No. of Passengers"
                                    // addonAfter={'each'}
                                />
                            </Col>
                            <Col xs={24} md={12}></Col>
                        </Row>
                    </ProForm.Item>

                    <ProForm.Item
                        label={
                            <Typography.Text style={{ fontWeight: 'bolder' }}>
                                Unit Expense
                            </Typography.Text>
                        }
                    >
                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="totalGasCost"
                                    label="GASOLINE"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonAfter={'Liter'}
                                    name="totalGasConsumed"
                                    label="Gas Equivalent in Liters"
                                />
                            </Col>
                        </Row>

                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="dispatchingCost"
                                    label="Dispatching Cost"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="miscellaneousExpenses"
                                    label="Miscellaneous Expenses"
                                    // addonAfter={'each'}
                                />
                            </Col>
                        </Row>
                    </ProForm.Item>
                    <ProForm.Item
                        label={
                            <Typography.Text style={{ fontWeight: 'bolder' }}>
                                Bus Driver Pay
                            </Typography.Text>
                        }
                    >
                        <Row gutter={[12, 12]}>
                            <Col span={12}>
                                <ProFormDigit
                                    name="driverSalary"
                                    label="Driver Salary"
                                    disabled
                                />
                            </Col>
                            <Col span={12}>
                                <ProFormDigit
                                    name="driverIncentives"
                                    label="Incentives"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[12, 12]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="driverOvertimeHours"
                                    label="Overtime Hours"
                                    // addonAfter={'each'}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="driverOvertimePay"
                                    label="Overtime Pay"
                                    // addonAfter={'each'}
                                />
                            </Col>
                        </Row>
                    </ProForm.Item>
                    <ProForm.Item
                        label={
                            <Typography.Text style={{ fontWeight: 'bolder' }}>
                                Bus Assistant Pay
                            </Typography.Text>
                        }
                    >
                        <Row gutter={[12, 12]}>
                            <Col span={12}>
                                <ProFormDigit
                                    name="assistantSalary"
                                    label="Assistant Salary"
                                    disabled
                                />
                            </Col>
                            <Col span={12}>
                                <ProFormDigit
                                    name="assistantIncentives"
                                    label="Incentives"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[12, 12]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="assistantOvertimeHours"
                                    label="Overtime Hours"
                                    // addonAfter={'each'}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="assistantOvertimePay"
                                    label="Overtime Pay"
                                    // addonAfter={'each'}
                                />
                            </Col>
                        </Row>
                    </ProForm.Item>

                    <ProFormTextArea
                        name="notes"
                        label="Notes"
                        formItemProps={{
                            style: {
                                marginBottom: 0,
                            },
                        }}
                    />
                    <ProForm.Item>
                        <Row>
                            <Col xs={24} md={8}>
                                <Card
                                    bordered={false}
                                    bodyStyle={{
                                        padding: 12,
                                    }}
                                >
                                    <Statistic
                                        title={
                                            <Typography.Text>
                                                Short/Over
                                            </Typography.Text>
                                        }
                                        value={short}
                                        precision={2}
                                        valueStyle={{
                                            color:
                                                short >= 0 ? '#3f8600' : 'red',
                                        }}
                                        prefix={
                                            <span>
                                                {short > 0 ? (
                                                    <ArrowUpOutlined />
                                                ) : short === 0 ? null : (
                                                    <ArrowDownOutlined />
                                                )}
                                                ₱
                                            </span>
                                        }
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} md={8}>
                                <Card
                                    bordered={false}
                                    bodyStyle={{
                                        padding: 12,
                                    }}
                                >
                                    <Statistic
                                        title={
                                            <Typography.Text
                                            // style={{ color: '#1890ff' }}
                                            // onClick={() =>
                                            //     showRevenueBreakdown()
                                            // }
                                            >
                                                Gross Revenue
                                            </Typography.Text>
                                        }
                                        // style={{
                                        //     color: '#1890ff',
                                        // }}
                                        value={totalRevenue}
                                        precision={2}
                                        // valueStyle={{
                                        //     color: '#3f8600',
                                        // }}
                                        prefix={'₱'}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} md={8}>
                                <Card
                                    bordered={false}
                                    bodyStyle={{
                                        padding: 12,
                                    }}
                                >
                                    <Tooltip
                                        title={
                                            vehicle &&
                                            sched.driver &&
                                            sched.assistant && (
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Driver
                                                                    Salary
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {expenses.driverSalary ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Driver
                                                                    Incentives
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {expenses.driverIncentives ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Driver
                                                                    Overtime Pay
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {expenses.driverOvertimePay ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Assistant
                                                                    Salary
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {expenses.assistantSalary ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Assistant
                                                                    Incentives
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {expenses.assistantIncentives ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Assistant
                                                                    Overtime Pay
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {expenses.assistantOvertimePay ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    GASOLINE
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {expenses.gasCost ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Miscellaneous
                                                                    Expenses
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {expenses.miscellaneousExpenses ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                    >
                                        <Statistic
                                            title={
                                                <Typography.Text>
                                                    Total Expenses
                                                </Typography.Text>
                                            }
                                            value={totalExpense}
                                            precision={2}
                                            valueStyle={{
                                                color: 'red',
                                            }}
                                            prefix={'₱'}
                                        />
                                    </Tooltip>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row justify={'center'}>
                            <Col>
                                <Button
                                    onClick={showRevenueBreakdown}
                                    shape="round"
                                >
                                    Remittance History
                                </Button>
                            </Col>
                        </Row> */}
                    </ProForm.Item>
                    {/* <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ProFormDigit
                                name="totalRoundTrips"
                                label="Total round trips"
                            />
                        </Col>
                        <Col span={12}></Col>
                    </Row> */}
                    {/* <ProFormCheckbox name="driverPayoutStatus">
                        Check this box if the <b>Driver</b> has already been
                        paid.
                    </ProFormCheckbox>
                    <ProFormCheckbox name="assistantPayoutStatus">
                        Check this box if the <b>Assistant</b> has already been
                        paid.
                    </ProFormCheckbox> */}
                    {/* <ProForm.Item label={'Signature'}>
                        <div
                            style={{
                                border: `1px solid rgba(0,0,0, 0.2)`,
                                position: 'relative',
                            }}
                        >
                            <div style={{ padding: 12, position: 'absolute' }}>
                                <Button
                                    onClick={clearSignature}
                                    shape="round"
                                    type="primary"
                                >
                                    Clear
                                </Button>
                            </div>
                            <div>
                                <ReactSignatureCanvas
                                    // @ts-ignore
                                    ref={signatureRef}
                                    penColor="green"
                                    canvasProps={{
                                        width: 500,
                                        height: 200,
                                        className: 'sigCanvas',
                                    }}
                                />
                            </div>
                        </div>
                    </ProForm.Item> */}
                </ProForm>
            </Modal>
            <Modal
                footer={false}
                title={`Add Remittance: ${vehicle?.title || ''}-${
                    vehicle?.routeName || ''
                }`}
                onCancel={closeRemittanceDrawer}
                open={remittanceDrawerOpen}
                getContainer={false}
                okButtonProps={{
                    loading,
                }}
                cancelButtonProps={{
                    loading,
                }}
                centered
                destroyOnClose
            >
                <ProForm
                    initialValues={
                        {
                            // driverPayoutStatus: false,
                            // assistantPayoutStatus: false,
                        }
                    }
                    form={remittanceForm}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            remittanceForm.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    onFinish={onSubmitRemittance}
                    // onValuesChange={(changed, vals) => {
                    //     // console.log(changed, vals)
                    //     if (
                    //         vals['cashCollected'] &&
                    //         vals['totalCashReceived']
                    //     ) {
                    //         setShort(
                    //             vals['totalCashReceived'] -
                    //                 vals['cashCollected']
                    //         )
                    //     }
                    //     if (vals['cashCollected'] && vals['cardRevenue']) {
                    //         setTotalRevenue(
                    //             vals['cashCollected'] + vals['cardRevenue']
                    //         )
                    //     }
                    //     if (
                    //         vals['totalGasCost'] !== undefined &&
                    //         vals['dispatchingCost'] !== undefined &&
                    //         vals['dailyQuotaIncentives'] !== undefined
                    //     ) {
                    //         setTotalExpense(
                    //             vals['totalGasCost'] +
                    //                 vals['dispatchingCost'] +
                    //                 vals['dailyQuotaIncentives'] +
                    //                 getDriverAssistantTripExpense()
                    //         )
                    //     }
                    // }}
                >
                    {vehicle && (
                        <Row gutter={[0, 12]}>
                            <Col xs={24} md={12}>
                                <EmployeeAvatar employee={sched.driver} />
                            </Col>
                            <Col xs={24} md={12}>
                                <EmployeeAvatar employee={sched.assistant} />
                            </Col>
                        </Row>
                    )}
                    {vehicle && sched.driver && <br />}
                    <Row gutter={[12, 0]}>
                        <Col xs={24} md={12}>
                            <ProFormDigit
                                // addonBefore={'₱'}
                                name="cashCollected"
                                label="Cash Revenue"
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <ProFormDigit
                                // addonBefore={<div>₱</div>}
                                name="cardRevenue"
                                label="Card Revenue"
                            />
                        </Col>
                    </Row>
                    <Row gutter={[12, 0]}>
                        <Col xs={24} md={12}>
                            <ProFormDigit
                                // addonBefore={<div>₱</div>}
                                name="totalGasCost"
                                label="GASOLINE"
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <ProFormDigit
                                // addonAfter={'Liter'}
                                name="totalGasConsumed"
                                label="Gas Equivalent in Liters"
                            />
                        </Col>
                    </Row>
                    <Row gutter={[12, 0]}>
                        <Col xs={24} md={12}>
                            <ProFormDigit
                                // addonBefore={<div>₱</div>}
                                name="totalCashReceived"
                                label="Cash Received"
                                // addonAfter={'each'}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <ProFormDigit
                                // addonBefore={<div>₱</div>}
                                name="dispatchingCost"
                                label="Dispatching Cost"
                            />
                        </Col>
                    </Row>
                    {/* <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="driverSalary"
                                label="Driver Salary"
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormDigit
                                addonBefore={<div>₱</div>}
                                name="assistantSalary"
                                label="Assistant Salary"
                            />
                        </Col>
                    </Row> */}
                    <ProFormTextArea
                        name="notes"
                        label="Notes"
                        formItemProps={{
                            style: {
                                marginBottom: 0,
                            },
                        }}
                    />
                    <br />
                    {/* <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ProFormDigit
                                name="totalRoundTrips"
                                label="Total round trips"
                            />
                        </Col>
                        <Col span={12}></Col>
                    </Row> */}
                    {/* <ProFormCheckbox name="driverPayoutStatus">
                        Check this box if the <b>Driver</b> has already been
                        paid.
                    </ProFormCheckbox>
                    <ProFormCheckbox name="assistantPayoutStatus">
                        Check this box if the <b>Assistant</b> has already been
                        paid.
                    </ProFormCheckbox> */}
                    {/* <ProForm.Item label={'Signature'}>
                        <div
                            style={{
                                border: `1px solid rgba(0,0,0, 0.2)`,
                                position: 'relative',
                            }}
                        >
                            <div style={{ padding: 12, position: 'absolute' }}>
                                <Button
                                    onClick={clearSignature}
                                    shape="round"
                                    type="primary"
                                >
                                    Clear
                                </Button>
                            </div>
                            <div>
                                <ReactSignatureCanvas
                                    ref={signatureRef}
                                    penColor="green"
                                    canvasProps={{
                                        width: 500,
                                        height: 200,
                                        className: 'sigCanvas',
                                    }}
                                />
                            </div>
                        </div>
                    </ProForm.Item> */}
                </ProForm>
            </Modal>
            <Card
                headStyle={{
                    padding: '0 12px',
                    textAlign: 'center',
                    fontSize: 24,
                    fontWeight: 'bold',
                }}
                extra={
                    sched.driver && (
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="change-driver"
                                        icon={
                                            <MdOutlineChangeCircle
                                                style={{
                                                    fontSize: 24,
                                                    color: '#1890ff',
                                                }}
                                            />
                                        }
                                        onClick={() => handleStartActiveTrip()}
                                    >
                                        {sched.status === 'available'
                                            ? 'Assign'
                                            : 'Change'}{' '}
                                        Driver/Assistant
                                    </Menu.Item>
                                    {/* {sched.currentDriverId && (
                                        <Menu.Item
                                            key="add-remittance"
                                            icon={
                                                <MdOutlinePayments
                                                    style={{
                                                        fontSize: 24,
                                                        color: '#1890ff',
                                                    }}
                                                />
                                            }
                                            onClick={() =>
                                                openRemittanceDrawer(
                                                    sched
                                                )
                                            }
                                        >
                                            Add Remittance
                                        </Menu.Item>
                                    )} */}
                                    {sched.status === 'occupied' ? (
                                        <Menu.Item
                                            key="end-shift"
                                            icon={
                                                <BiStopCircle
                                                    style={{
                                                        fontSize: 24,
                                                        color: '#1890ff',
                                                    }}
                                                />
                                            }
                                            onClick={() => showEndTripVehicle()}
                                        >
                                            End Shift
                                        </Menu.Item>
                                    ) : null}
                                </Menu>
                            }
                        >
                            <Button
                                style={{
                                    padding: '4px 0',
                                    marginLeft: 6,
                                    border: '1px solid #d3d3d3',
                                }}
                                type="link"
                                size="large"
                            >
                                <EllipsisOutlined
                                    style={{ fontSize: 32 }}
                                    rotate={90}
                                />
                            </Button>
                        </Dropdown>
                    )
                }
                bodyStyle={{
                    padding: '24px 12px',
                }}
                title={
                    <Row justify="space-between">
                        <Col>
                            {sched.title} - {sched.routeName}
                        </Col>
                        {/* <Col>
                            <Tooltip
                                title={
                                    dates.length
                                        ? `${moment
                                              .unix(dates[0])
                                              .format(
                                                  'MMM DD'
                                              )}-${moment
                                              .unix(dates[1])
                                              .format(
                                                  'MMM DD, YYYY'
                                              )}`
                                        : null
                                }
                            >
                                <Statistic
                                    value={sched.totalEarnings}
                                    valueStyle={{
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                    // title={<Typography.Text style={{
                                    //     fontSize: 11,
                                    //     color: "rgba(0,0,0,.45)",
                                    //     margin: 0
                                    // }}>Avg. Revenue</Typography.Text>}
                                    prefixCls="p"
                                    precision={2}
                                    valueRender={(node) => (
                                        <Typography.Text type="secondary">
                                            ₱{node}
                                        </Typography.Text>
                                    )}
                                />
                            </Tooltip>
                        </Col> */}
                    </Row>
                }
            >
                {sched && sched.currentDriverId && sched.driver ? (
                    <Row gutter={[0, 12]}>
                        <Col xs={24} md={12}>
                            <EmployeeAvatar employee={sched.driver} />
                        </Col>
                        <Col xs={24} md={12}>
                            <EmployeeAvatar employee={sched.assistant} />
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col span={24}>
                            <Typography.Title
                                level={5}
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 400,
                                }}
                            >
                                Assign a personnel to this vehicle!
                            </Typography.Title>

                            <Row justify={'center'}>
                                <Col>
                                    <Button
                                        size="large"
                                        onClick={() => {
                                            handleStartActiveTrip()
                                        }}
                                        shape="round"
                                        style={{
                                            backgroundColor: styles.primary,
                                            color: 'white',
                                        }}
                                    >
                                        Assign now
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </Card>
        </Col>
    ) : null
}

export default Scheduling
