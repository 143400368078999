import {
    ArrowLeftOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
    PlusOutlined,
    // UploadOutlined,
} from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import {
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-components'
import {
    Button,
    Card,
    Col,
    Grid,
    Image,
    message,
    Row,
    Tooltip,
    Typography,
    Upload,
    // UploadProps,
} from 'antd'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
    PERSONNEL_REPOSITORY,
    // TEAM_REPOSITORY,
    civilStatuses,
    employmentStatusOptions,
    positions,
} from '../../../data/repositories/constants'
import { Personnel } from '../../../domain/entities/Transport'
import { auth, db, storage } from '../../boot'
import { useAppDispatch, useAppSelector } from '../../store'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import PhoneInputWithCountrySelect from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { memberStateInitialValues } from '../Member/UpdateMember'
// import { addTeamMember } from '@/app/redux/team/team.action'
import { updateTeamState } from '../../redux/team/team.slice'
import {
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
} from 'firebase/auth'
const DriverContainer = () => {
    const [form] = ProForm.useForm()
    const [passForm] = ProForm.useForm()
    const [loading, setLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isPersonal, setIsPersonal] = useState(false)
    const { styles, activeTeam, email, currentTeamMembers } = useAppSelector(
        (state) => ({
            styles: state.styles,
            email: state.auth.user.email,
            activeTeam: state.auth.user.activeTeam,
            currentTeamMembers: state.team.currentTeamMembers,
        }),
        shallowEqual
    )
    const navigate = useNavigate()

    const handleUpdate = async () => {
        await form.validateFields()
        form.submit()
    }
    const dispatch = useAppDispatch()

    const handlePassForm = async (data: any) => {
        const credential = EmailAuthProvider.credential(
            email,
            data.currentPassword
        )

        // Prompt the user to re-provide their sign-in credentials
        const user = auth.currentUser
        if (!user) return
        try {
            const response = await reauthenticateWithCredential(
                user,
                credential
            )
            await updatePassword(response.user, data.newPassword)
            message.destroy()
            message.success('Password updated successfully', 5)
            passForm.resetFields()
        } catch (err) {
            if (err instanceof Error) {
                message.error(err.message)
            } else {
                message.error('An unknown error occurred.')
            }
        }
    }
    const handleFinish = async (data: any) => {
        setLoading(true)

        const payload: any = {
            ...data,
            isPWD: data.isPWD || false,
            isSeniorCitizen: data.isSeniorCitizen || false,
            startDate: moment(data.startDate).unix(),
            birthdate: moment(data.birthdate).unix(),
            partnerCode: activeTeam,
            modified: moment().unix(),
            modifiedBy: email,
            displayName: data.firstName + ' ' + data.lastName,
            regularLeaveDays: data.regularLeaveDays || 12,
            parentLeaveDays:
                data.parentLeaveDays || data.gender === 'male' ? 7 : 78,
            activeTeam,
        }
        if (!payload.status) {
            payload.status = 'active'
        }
        if (!isEdit) {
            const addPayload = {
                ...memberStateInitialValues,
                ...payload,
                myTeams: [activeTeam],
                type: 'admin',
                creation: moment().unix(),
                partnerCode: activeTeam,
                activeTeam,
                isRemoved: false,
            }
            // eslint-disable-next-line no-console
            // console.log(addPayload)
            const respData = await addDoc(
                collection(db, PERSONNEL_REPOSITORY),
                addPayload
            )

            addPayload.memberId = respData.id
            addPayload.id = respData.id

            // await setDoc(
            //     doc(db, TEAM_REPOSITORY, activeTeam, 'members', respData.id),
            //     addPayload
            // )
            // const res = await addTeamMember(payload, activeTeam)
            // message.success('successfully added member')
            setLoading(false)
            form.resetFields()
            // if (typeof res === 'string') {
            // }
            dispatch(
                updateTeamState({
                    currentTeamMembers: [...currentTeamMembers, addPayload],
                })
            )
        } else {
            // eslint-disable-next-line
            await updateDoc(
                doc(db, PERSONNEL_REPOSITORY, params.personnelId as string),
                payload
            )
        }

        // await dispatch(addExpense(payload))
        form.resetFields()
        const msg = isEdit
            ? 'Employee successfully updated'
            : 'Employee successfully added'
        message.destroy()
        message.success(msg)
        setLoading(false)
        navigate(-1)
    }
    const params = useParams()
    const location = useLocation()
    useEffect(() => {
        if (!params.personnelId || !location.state) return
        form.setFieldsValue({
            ...location.state,
            birthdate: moment.unix(location.state.birthdate),
            startDate: moment.unix(location.state.startDate),
        })
        if (location.state.phone) {
            setValue(location.state.phone)
        }
        if (location.state.idUrl) {
            setImageUrl(location.state.idUrl)
        }
        if (location.state.gender) {
            setGender(location.state.gender)
        }
        if (location.state.role) {
            setPosition(location.state.role)
        }
        setIsEdit(true)
        if (location.search && location.search.includes('personal')) {
            setIsPersonal(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location])
    const [imageUrl, setImageUrl] = useState<string>()
    // eslint-disable-next-line no-console
    // console.log(imageUrl)

    // const getBase64 = (img: any, callback: (url: string) => void) => {
    //     const reader = new FileReader()
    //     reader.addEventListener('load', () => callback(reader.result as string))
    //     reader.readAsDataURL(img)
    // }

    const beforeUpload = (file: any) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!')
        }
        // const isLt2M = file.size / 1024 / 1024 < 2
        // if (!isLt2M) {
        //     message.error('Image must smaller than 2MB!')
        // }
        return isJpgOrPng
    }
    const handleUploadChange = async (files: any) => {
        // eslint-disable-next-line no-console
        // console.log(files)

        setLoading(true)
        const logoRef = ref(
            storage,
            `/${activeTeam}/personnel/${moment().unix()}/${files.file.name}`
        )
        const uploadTask = uploadBytesResumable(
            logoRef,
            files.file.originFileObj
        )
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                switch (snapshot.state) {
                    case 'paused':
                        // eslint-disable-next-line no-console
                        console.log('Upload is paused')
                        break
                    case 'running':
                        // eslint-disable-next-line no-console
                        console.log('Upload is running')
                        break
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                setLoading(false)
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then(
                    async (downloadURL) => {
                        await updateDoc(
                            doc(
                                db,
                                PERSONNEL_REPOSITORY,
                                params.personnelId as string
                            ),
                            {
                                idUrl: downloadURL,
                            }
                        )
                        message.success('Successfully uploaded')
                        setImageUrl(downloadURL)
                        setLoading(false)
                    }
                )
            }
        )
    }
    // const handleChange: UploadProps['onChange'] = (info: any) => {
    //     if (info.file.status === 'uploading') {
    //         setLoading(true)
    //         handleUploadChange(info)
    //         return
    //     }
    //     if (info.file.status === 'done') {
    //         // Get this url from response in real world.
    //         getBase64(info.file.originFileObj as any, (url) => {
    //             setLoading(false)
    //             setImageUrl(url)
    //         })
    //     }
    // }
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )

    const { xs } = Grid.useBreakpoint()
    const [value, setValue] = useState('')
    const [gender, setGender] = useState('')
    const [position, setPosition] = useState('')
    const handleSelectGender = (gender: any) => {
        setGender(gender)
    }
    const onSelectPosition = (position: any) => {
        setPosition(position)
    }
    return (
        <div
            style={{
                paddingTop: 24,
                maxWidth: 936,
                width: xs ? '100%' : undefined,
            }}
        >
            <Row gutter={[12, 12]}>
                <Col xs={24} lg={18}>
                    <Card
                        bodyStyle={{}}
                        title={
                            <Row>
                                <Col span={8}>
                                    <Button
                                        type="link"
                                        onClick={() => navigate(-1)}
                                    >
                                        <ArrowLeftOutlined
                                            style={{ fontSize: 18 }}
                                        />
                                    </Button>
                                </Col>
                                {isPersonal ? (
                                    <Col span={8}>Update Information</Col>
                                ) : (
                                    <Col span={8}>
                                        {isEdit ? 'Update' : 'New'} Employee
                                    </Col>
                                )}
                                <Col span={8}></Col>
                            </Row>
                        }
                    >
                        <Row justify={'center'}>
                            <Col>
                                {imageUrl ? (
                                    <div style={{ position: 'relative' }}>
                                        <ImgCrop
                                            beforeCrop={beforeUpload}
                                            // cropperProps={{
                                            //     onCropAreaChange(croppedArea, croppedAreaPixels) {
                                            //     // eslint-disable-next-line no-console
                                            //         console.log(croppedArea, croppedAreaPixels);

                                            //     },
                                            // }}
                                            cropShape="round"
                                            onModalOk={(val: any) => {
                                                // eslint-disable-next-line no-console
                                                console.log(val)
                                                handleUploadChange({
                                                    file: {
                                                        name: val.name,
                                                        originFileObj: val,
                                                    },
                                                })
                                            }}
                                        >
                                            <Upload
                                                showUploadList={false}
                                                multiple={false}
                                                customRequest={() => null}
                                                // @ts-ignore
                                                listType="picture-circle"
                                            >
                                                <Image
                                                    src={imageUrl}
                                                    alt="avatar"
                                                    style={{
                                                        width: '100%',
                                                        maxWidth: 150,
                                                        borderRadius: '50%',
                                                        border: '1px solid black',
                                                    }}
                                                    preview={false}
                                                />
                                                {/* <div style={{borderRadius: 5,position: 'absolute', top: "45%", left: 0, width: '100%', backgroundColor: "rgba(0,0,0,0.4)"}}>
                                                   
                                                </div> */}
                                            </Upload>
                                        </ImgCrop>
                                        <Row justify={'center'}>
                                            <Col>
                                                <Button
                                                    loading={loading}
                                                    style={{ marginTop: 5 }}
                                                    shape="round"
                                                >
                                                    Upload
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : (
                                    <ImgCrop
                                        beforeCrop={beforeUpload}
                                        // cropperProps={{
                                        //     onCropAreaChange(croppedArea, croppedAreaPixels) {
                                        //     // eslint-disable-next-line no-console
                                        //         console.log(croppedArea, croppedAreaPixels);

                                        //     },
                                        // }}
                                        cropShape="round"
                                        onModalOk={(val: any) => {
                                            // eslint-disable-next-line no-console
                                            console.log(val)
                                            handleUploadChange({
                                                file: {
                                                    name: val.name,
                                                    originFileObj: val,
                                                },
                                            })
                                        }}
                                    >
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            multiple={false}
                                            customRequest={() => null}
                                            // onChange={handleChange}
                                        >
                                            {uploadButton}
                                        </Upload>
                                    </ImgCrop>
                                )}
                            </Col>
                        </Row>
                        <br />
                        <ProForm<Personnel>
                            form={form}
                            onFinish={handleFinish}
                            initialValues={{}}
                            // onValuesChange={onValuesChange}
                            autoFocusFirstInput
                            submitter={false}
                            onFinishFailed={(err) => {
                                if (err.errorFields.length) {
                                    const firstError = err.errorFields[0]
                                    message.destroy()
                                    message.error(firstError.errors)
                                    form.scrollToField(firstError.name, {
                                        behavior: 'smooth',
                                        block: 'center',
                                    })
                                }
                            }}
                        >
                            <ProForm.Item
                                label={
                                    <Typography.Text
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Employee Information
                                    </Typography.Text>
                                }
                            >
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} lg={8}>
                                        <ProFormText
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            label="First Name"
                                            name="firstName"
                                        />
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <ProFormText
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            label="Last Name"
                                            name="lastName"
                                        />
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <ProFormText
                                            label="Middle Name"
                                            name="middleName"
                                        />
                                    </Col>
                                </Row>

                                <Row gutter={[12, 12]}>
                                    <Col span={12}>
                                        <ProFormDatePicker
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            name="birthdate"
                                            label={'Birth Date'}
                                            fieldProps={{
                                                style: {
                                                    width: '100%',
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ProFormSelect
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            options={[
                                                {
                                                    label: 'Male',
                                                    value: 'male',
                                                },
                                                {
                                                    label: 'Female',
                                                    value: 'female',
                                                },
                                            ]}
                                            name="gender"
                                            label={'Gender'}
                                            fieldProps={{
                                                onSelect: handleSelectGender,
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} md={12}>
                                        <ProFormText
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            label={'Phone Number'}
                                            name="phone"
                                        >
                                            <PhoneInputWithCountrySelect
                                                placeholder="Enter phone number"
                                                value={value}
                                                name="phone"
                                                international
                                                defaultCountry="PH"
                                                className="ant-input"
                                                style={{ border: 'none' }}
                                                countryCallingCodeEditable={
                                                    false
                                                }
                                                onChange={(number) => {
                                                    setValue(number || '')
                                                    form.setFieldsValue({
                                                        phone: number,
                                                    })
                                                }}
                                            />
                                        </ProFormText>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <ProFormText
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'email',
                                                },
                                            ]}
                                            label={'Email'}
                                            name="email"
                                        />
                                    </Col>
                                </Row>
                                <ProFormTextArea
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    label={'Address'}
                                    name="address"
                                />

                                <Row gutter={[12, 12]}>
                                    <Col span={12}>
                                        <ProFormSelect
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            options={civilStatuses}
                                            label={'Civil Status'}
                                            name="maritalStatus"
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ProFormText
                                            label={'TIN Number'}
                                            name="tinNumber"
                                        />
                                    </Col>
                                </Row>

                                <Row gutter={[12, 12]}>
                                    <Col span={12}>
                                        <ProFormText
                                            label={
                                                'Highest Educational Attainment'
                                            }
                                            name="highestEducationalAttainment"
                                        />
                                    </Col>
                                    <Col span={12}>
                                        {!isPersonal && (
                                            <ProFormDigit
                                                name={'accountNumber'}
                                                label="Employee No."
                                                fieldProps={{
                                                    style: {
                                                        width: '100%',
                                                    },
                                                }}
                                            />
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={12}></Col>
                                </Row>
                            </ProForm.Item>

                            {/* <ProFormDatePicker
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required.',
                                    },
                                ]}
                                name={'startDate'}
                                label="Start Date"
                                width={'sm'}
                            /> */}
                            {/* <ProFormCheckbox name="isPWD">
                                Person With Disablity (PWD)
                            </ProFormCheckbox> */}

                            {!isPersonal && (
                                <ProForm.Item
                                    label={
                                        <Typography.Text
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            Employment Information
                                        </Typography.Text>
                                    }
                                >
                                    <Row gutter={[12, 12]}>
                                        <Col span={12}>
                                            <ProFormDigit
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                name={'salary'}
                                                label="Salary"
                                                fieldProps={{
                                                    style: {
                                                        width: '100%',
                                                    },
                                                }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ProFormDatePicker
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                name={'startDate'}
                                                label="Start Date"
                                                fieldProps={{
                                                    style: {
                                                        width: '100%',
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]}>
                                        <Col span={12}>
                                            <ProFormSelect
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                options={positions}
                                                name="role"
                                                label={'Position'}
                                                fieldProps={{
                                                    onSelect: onSelectPosition,
                                                }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ProFormSelect
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                options={
                                                    employmentStatusOptions
                                                }
                                                initialValue={'probationary'}
                                                name="employmentStatus"
                                                label={'Status'}
                                            />
                                        </Col>
                                    </Row>
                                    {position === 'driver' ? (
                                        <Row gutter={[12, 12]}>
                                            <Col span={12}>
                                                <ProFormText
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                    label={"Driver's License"}
                                                    name="license"
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <ProFormText
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                    label={'License Code'}
                                                    name="licenseCode"
                                                />
                                            </Col>
                                        </Row>
                                    ) : null}
                                    <Row gutter={[12, 12]}>
                                        <Col span={12}>
                                            <ProFormSelect
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                name={'contractTerm'}
                                                label="Type"
                                                fieldProps={{
                                                    style: {
                                                        width: '100%',
                                                    },
                                                }}
                                                options={[
                                                    {
                                                        value: 'indefinite',
                                                        label: 'Indefinite or Permanent',
                                                    },
                                                    {
                                                        value: 'fixed-term',
                                                        label: 'Fixed-Term',
                                                    },
                                                    {
                                                        value: 'probationary',
                                                        label: 'Probationary',
                                                    },
                                                    {
                                                        value: 'casual',
                                                        label: 'Casual or Temporary',
                                                    },
                                                    // {
                                                    //     value: 'part-time',
                                                    //     label: 'Part-Time',
                                                    // },
                                                    {
                                                        value: 'job-order',
                                                        label: 'Job Order',
                                                    },
                                                    {
                                                        value: 'agency',
                                                        label: 'Agency',
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ProFormDigit
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                name={'dailyAllowance'}
                                                label="Daily Allowance"
                                                fieldProps={{
                                                    style: {
                                                        width: '100%',
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]}>
                                        <Col span={12}>
                                            <ProFormSelect
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                                name={'employeeStage'}
                                                label="Employment Stage"
                                                fieldProps={{
                                                    style: {
                                                        width: '100%',
                                                    },
                                                }}
                                                options={[
                                                    {
                                                        value: 'interview',
                                                        label: 'For Interview',
                                                    },
                                                    {
                                                        value: 'orientation',
                                                        label: 'For Orientation',
                                                    },
                                                    {
                                                        value: 'training',
                                                        label: 'Trainee',
                                                    },
                                                    {
                                                        value: 'screening',
                                                        label: 'For Screening',
                                                    },
                                                    {
                                                        value: 'test_drive',
                                                        label: 'Schedule for Test Drive',
                                                    },
                                                    {
                                                        value: 'approval',
                                                        label: 'For Approval',
                                                    },
                                                    {
                                                        value: 'blowbagets',
                                                        label: 'BLOWBAGETS',
                                                    },
                                                    {
                                                        value: 'employed',
                                                        label: 'Employed',
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ProFormDigit
                                                name={
                                                    'monthlyShareCapitalDeductions'
                                                }
                                                label="Monthly Share Capital Deductions"
                                                fieldProps={{
                                                    style: {
                                                        width: '100%',
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <ProFormDigit
                                                name={'bondFundAgainstAccident'}
                                                label="Bond Fund Against Accident"
                                                fieldProps={{
                                                    style: {
                                                        width: '100%',
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </ProForm.Item>
                            )}
                            {!isPersonal && (
                                <ProForm.Item
                                    label={
                                        <Typography.Text
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            Employment Benefits (
                                            <Typography.Text type="secondary">
                                                Leave blank if not yet available
                                            </Typography.Text>
                                            )
                                        </Typography.Text>
                                    }
                                >
                                    {!isPersonal && (
                                        <Row gutter={[12, 12]}>
                                            <Col span={8}>
                                                <ProFormDigit
                                                    name={'sickLeaveBalance'}
                                                    label="Sick Leave"
                                                    fieldProps={{
                                                        style: {
                                                            width: '100%',
                                                        },
                                                    }}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <ProFormDigit
                                                    name={
                                                        'vacationLeaveBalance'
                                                    }
                                                    label="Vacation"
                                                    fieldProps={{
                                                        style: {
                                                            width: '100%',
                                                        },
                                                    }}
                                                />
                                            </Col>
                                            {gender === 'male' ? (
                                                <Col span={8}>
                                                    <ProFormDigit
                                                        name={
                                                            'paternityLeaveBalance'
                                                        }
                                                        label="Paternity Leave"
                                                        fieldProps={{
                                                            style: {
                                                                width: '100%',
                                                            },
                                                        }}
                                                    />
                                                </Col>
                                            ) : (
                                                <Col span={8}>
                                                    <ProFormDigit
                                                        name={
                                                            'maternityLeaveBalance'
                                                        }
                                                        label="Maternity Leave"
                                                        fieldProps={{
                                                            style: {
                                                                width: '100%',
                                                            },
                                                        }}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    )}

                                    <Row gutter={[12, 12]} wrap={false}>
                                        <Col span={8}>
                                            <ProFormText
                                                name="sss"
                                                label={
                                                    <div>
                                                        SSS I.D
                                                        <Tooltip
                                                            color={
                                                                styles.primary
                                                            }
                                                            title={
                                                                <div>
                                                                    <Typography.Text
                                                                        style={{
                                                                            fontWeight:
                                                                                'bold',
                                                                            color: 'white',
                                                                        }}
                                                                    >
                                                                        Below
                                                                        ₱20,000:
                                                                    </Typography.Text>
                                                                    <br />
                                                                    4.5% of
                                                                    monthly
                                                                    Income.
                                                                    <br />
                                                                    Employer
                                                                    9.5%
                                                                    <br />
                                                                    <Typography.Text
                                                                        style={{
                                                                            fontWeight:
                                                                                'bold',
                                                                            color: 'white',
                                                                        }}
                                                                    >
                                                                        Above
                                                                        ₱20,000:
                                                                    </Typography.Text>{' '}
                                                                    <br />
                                                                    Employee
                                                                    ₱900. <br />
                                                                    Employer
                                                                    ₱1900.
                                                                </div>
                                                            }
                                                        >
                                                            <InfoCircleOutlined
                                                                style={{
                                                                    fontSize: 16,
                                                                    marginLeft: 6,
                                                                    color: styles.primary,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                }
                                            />
                                            <ProFormDigit
                                                name="sssDeduction"
                                                label={'SSS Deduction'}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <ProFormText
                                                name="philhealth"
                                                label={
                                                    <div>
                                                        PhilHealth I.D.
                                                        <Tooltip
                                                            color={
                                                                styles.primary
                                                            }
                                                            title="4.5% of monthly income."
                                                        >
                                                            <InfoCircleOutlined
                                                                style={{
                                                                    fontSize: 16,
                                                                    marginLeft: 6,
                                                                    color: styles.primary,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                }
                                            />
                                            <ProFormDigit
                                                name="philhealthDeduction"
                                                label={'PhilHealth Deduction'}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <ProFormText
                                                label={
                                                    <div>
                                                        Pag-IBIG MID
                                                        <Tooltip
                                                            color={
                                                                styles.primary
                                                            }
                                                            title="₱100 employee. ₱100 employer"
                                                        >
                                                            <InfoCircleOutlined
                                                                style={{
                                                                    fontSize: 16,
                                                                    marginLeft: 6,
                                                                    color: styles.primary,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                }
                                                name="pagibig"
                                            />
                                            <ProFormDigit
                                                label={'Pag-IBIG Deduction'}
                                                name="pagibigDeduction"
                                            />
                                        </Col>
                                    </Row>
                                </ProForm.Item>
                            )}
                            <ProForm.Item
                                label={
                                    <Typography.Text
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        In case of Emergency
                                    </Typography.Text>
                                }
                            >
                                <Row gutter={[12, 12]}>
                                    <Col span={12}>
                                        <ProFormText
                                            label={'Contact Name'}
                                            name="emergencyName"
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ProFormText
                                            label={'Contact Number'}
                                            name="emergencyPhone"
                                        />
                                    </Col>
                                </Row>

                                <ProFormTextArea
                                    label={'Contact Address'}
                                    name="emergencyContactAddress"
                                />
                            </ProForm.Item>
                            <Row gutter={[24, 24]}>
                                <Col span={12}>
                                    <Button
                                        size="large"
                                        loading={loading}
                                        // disabled={password.length > 1 && email.length > 1 ? false : true}
                                        style={{
                                            fontWeight: 'bold',
                                            width: '100%',
                                        }}
                                        onClick={() => navigate(-1)}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    {isEdit ? (
                                        <Button
                                            size="large"
                                            loading={loading}
                                            // disabled={password.length > 1 && email.length > 1 ? false : true}
                                            style={{
                                                fontWeight: 'bold',
                                                width: '100%',
                                                color: 'white',
                                                backgroundColor: styles.primary,
                                            }}
                                            onClick={handleUpdate}
                                        >
                                            Update
                                        </Button>
                                    ) : (
                                        <Button
                                            htmlType="submit"
                                            size="large"
                                            loading={loading}
                                            // disabled={password.length > 1 && email.length > 1 ? false : true}
                                            style={{
                                                fontWeight: 'bold',
                                                width: '100%',
                                                color: 'white',
                                                backgroundColor: styles.primary,
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </ProForm>
                    </Card>
                </Col>
                {isEdit && (
                    <Col xs={24} lg={8}>
                        {isPersonal && (
                            <>
                                <br />
                                <Card title={'Change Password'}>
                                    <ProForm
                                        form={passForm}
                                        onFinishFailed={(err) => {
                                            if (err.errorFields.length) {
                                                const firstError =
                                                    err.errorFields[0]
                                                message.destroy()
                                                message.error(firstError.errors)
                                                passForm.scrollToField(
                                                    firstError.name,
                                                    {
                                                        behavior: 'smooth',
                                                        block: 'center',
                                                    }
                                                )
                                            }
                                        }}
                                        onFinish={handlePassForm}
                                    >
                                        <ProFormText.Password
                                            name="currentPassword"
                                            label="Current Password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter your current password',
                                                },
                                            ]}
                                        />
                                        <ProFormText.Password
                                            name="newPassword"
                                            label="New Password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter your new password',
                                                },
                                                {
                                                    min: 8,
                                                    message:
                                                        'Password must be at least 8 characters',
                                                },
                                            ]}
                                        />
                                        <ProFormText.Password
                                            name="confirmNewPassword"
                                            label="Confirm New Password"
                                            dependencies={['newPassword']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please confirm your new password',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (
                                                            !value ||
                                                            getFieldValue(
                                                                'newPassword'
                                                            ) === value
                                                        ) {
                                                            return Promise.resolve()
                                                        }
                                                        return Promise.reject(
                                                            new Error(
                                                                'New passwords do not match'
                                                            )
                                                        )
                                                    },
                                                }),
                                            ]}
                                        />
                                    </ProForm>
                                </Card>
                            </>
                        )}
                    </Col>
                )}
            </Row>
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default DriverContainer
