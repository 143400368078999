import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    Loan,
    LoanPayment,
    LoanPaymentProps,
} from '../../../domain/entities/Loan'
import { LoanService } from '../../../domain/usecases/loan.usecase'
import { LoanFirebaseImplementation } from '../../../data/repositories/loan/loan.firebase'
import { UpdateLoanProp } from '../../../domain/repositories/loan.repository'

export interface ViewLoan extends Loan {
    show: boolean
    showLoanStatus: boolean
    name: string
}
interface InitState {
    loans: Array<Loan>
    memberLoans: Array<Loan>
    memberLoanData: ViewLoan
    ready: boolean
    showSelectMember: boolean
}
export const initialLoansState: InitState = {
    loans: [],
    memberLoanData: {
        memberId: '',
        payments: [],
        amount: 0,
        interest: 0,
        unpaidInterest: 0,
        lastPaymentDate: 0,
        termYears: 0,
        termMonths: 0,
        payback: 'monthly',
        creation: 0,
        modified: 0,
        modifiedBy: '',
        show: false,
        monthlyPayment: 0,
        showLoanStatus: false,
        id: '',
        compound: 'continuous',
        title: '',
        name: '',
        totalInterestPaid: 0,
        totalPrincipalPaid: 0,
        remainingBalance: 0,
        due: 0,
        pendingInterest: 0,
        approvalDate: 0,
        disbursementDate: 0,
        withInitialLoanPayment: false,
        loanPenalty: true,
        partnerCode: '',
        isRemoved: false,
        type: 'personal',
        numberOfPaymentsMade: 0,
        loanInterestType: 'reducing-balance',
        amortization: {
            totalPrincipal: 0,
            payback: 'monthly',
            totalPayment: 0,
            totalMonthlyPayment: 0,
            totalInterest: 0,
            creation: 0,
            numberOfPayments: 0,
            schedules: [],
        },
    },
    memberLoans: [],
    ready: false,
    showSelectMember: false,
}

// const localImpl = new LoanLocalImplementation()
const repo = new LoanFirebaseImplementation()

export const addLoan = createAsyncThunk(
    'loans/addLoan',
    async (payload: Loan) => {
        const loanService = new LoanService(repo)
        return await loanService.AddLoan(payload)
    }
)

export const getLoan = async (payload: string) => {
    const loanService = new LoanService(repo)
    return await loanService.GetLoan(payload)
}

export const getLoanPayments = async (loanId: string) => {
    const loanService = new LoanService(repo)
    return await loanService.getLoanPayments(loanId)
}
//
export const updateLoan = createAsyncThunk(
    'loans/updateLoan',
    async (payload: { loan: Loan; prevLoan: Loan }) => {
        const loanService = new LoanService(repo)
        const { loan, prevLoan } = payload
        return await loanService.UpdateLoan(loan, prevLoan)
    }
)

export const payLoan = createAsyncThunk(
    'loans/payLoan',
    async (payload: {
        loan: LoanPaymentProps & {
            id: string
        }
        payment: LoanPayment
    }) => {
        const loanService = new LoanService(repo)
        return await loanService.AddLoanPayment(payload.loan, payload.payment)
    }
)

// export const updateLoan = createAsyncThunk(
//     'loans/updateLoan',
//     (payload: Loan): Loan => {
//         const loanService = new LoanService(repo)
//         return loanService.UpdateLoan(payload)
//     }
// )

export const updateLoanPayment = createAsyncThunk(
    'loans/updateLoanPayment',
    async (payload: {
        previousPayment: LoanPayment
        payment: LoanPayment
        loan: LoanPaymentProps & {
            id: string
        }
        newPendingInterest: number
    }) => {
        const loanService = new LoanService(repo)
        return await loanService.UpdateLoanPayment(
            payload.previousPayment,
            payload.payment,
            payload.loan,
            payload.newPendingInterest
        )
    }
)

export const removeLoanPayment = createAsyncThunk(
    'loans/removeLoanPayment',
    async (payload: {
        loan: LoanPaymentProps & {
            id: string
        }
        payment: LoanPayment
        // prevPayment: LoanPayment
    }) => {
        const loanService = new LoanService(repo)
        return await loanService.RemovePayment(
            payload.loan,
            payload.payment
            // payload.prevPayment
        )
    }
)

export const deleteLoan = createAsyncThunk(
    'loans/deleteLoan',
    async (payload: string) => {
        const loanService = new LoanService(repo)
        return await loanService.RemoveLoan(payload)
    }
)

export const getMemberLoans = createAsyncThunk(
    'loans/getMemberLoans',
    async (payload: { memberId: string; partnerCode: string }) => {
        const loanService = new LoanService(repo)
        return await loanService.GetMemberLoans(payload)
    }
)

export const getAllMemberLoans = createAsyncThunk(
    'loans/getAllMemberLoans',
    async (partnerCode: string) => {
        const loanService = new LoanService(repo)
        return await loanService.GetAllMemberLoan(partnerCode)
    }
)

export const loansSlice = createSlice({
    name: 'loans',
    initialState: initialLoansState,
    reducers: {
        updateLoanState: (
            state: { [key: string]: any },
            action: PayloadAction<{
                name: string
                value: any
            }>
        ) => {
            state[action.payload.name] = action.payload.value
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getMemberLoans.fulfilled, (state, action) => {
            state.memberLoans = action.payload
            state.ready = true
        })
        builder.addCase(getMemberLoans.pending, (state) => {
            state.ready = false
        })
        builder.addCase(getAllMemberLoans.pending, (state) => {
            state.ready = false
        })
        builder.addCase(getAllMemberLoans.fulfilled, (state, action) => {
            state.loans = action.payload
            state.ready = true
        })
        builder.addCase(addLoan.fulfilled, (state, action) => {
            if (!action.payload) return
            state.loans = [...state.loans, action.payload]
        })
        builder.addCase(deleteLoan.fulfilled, (state, action) => {
            if (action.payload) {
                const idx = state.loans.findIndex(
                    (loan) => loan.id === action.payload
                )
                if (idx >= 0) {
                    state.loans.splice(idx, 1)
                }

                const memberloansIdx = state.memberLoans.findIndex(
                    (loan) => loan.id === action.payload
                )
                if (memberloansIdx >= 0) {
                    state.memberLoans.splice(memberloansIdx, 1)
                }
            }
        })
        builder.addCase(updateLoan.fulfilled, (state, action) => {
            if (!action.payload) return
            const payload = action.payload as UpdateLoanProp
            // if (action.payload) {
            const idx = state.loans.findIndex((loan) => loan.id === payload.id)
            if (idx >= 0) {
                state.loans[idx] = {
                    ...state.loans[idx],
                    ...payload,
                }
            }

            const memberloansIdx = state.memberLoans.findIndex(
                (loan) => loan.id === payload.id
            )
            if (memberloansIdx >= 0) {
                state.memberLoans[memberloansIdx] = {
                    ...state.loans[idx],
                    ...payload,
                }
            }
            // }
        })
        // builder.addCase(updateLoanPayment.fulfilled, (state, action) => {
        //     if (action.payload) {
        //         const idx = state.loans.findIndex(
        //             (loan) => loan.id === action?.payload?.id
        //         )
        //         if (idx >= 0) {
        //             state.loans[idx] = action.payload
        //         }

        //         const memberloansIdx = state.memberLoans.findIndex(
        //             (loan) => loan.id === action.payload?.id
        //         )
        //         if (memberloansIdx >= 0) {
        //             state.memberLoans[memberloansIdx] = action.payload
        //         }
        //     }
        // })
        builder.addCase(removeLoanPayment.fulfilled, (state, action) => {
            if (!action.payload) return

            const payload: {
                totalInterestPaid: number
                totalPrincipalPaid: number
                remainingBalance: number
                pendingInterest: number
                id: string
            } = action.payload
            const idx = state.loans.findIndex((loan) => loan.id === payload.id)
            if (idx >= 0) {
                const { id, ...rest } = action.payload
                state.loans[idx] = {
                    ...state.loans[idx],
                    totalInterestPaid:
                        state.loans[idx].totalInterestPaid +
                        rest.totalInterestPaid,
                    totalPrincipalPaid:
                        state.loans[idx].totalPrincipalPaid +
                        rest.totalPrincipalPaid,
                    remainingBalance:
                        state.loans[idx].remainingBalance +
                        rest.remainingBalance,
                    pendingInterest:
                        state.loans[idx].pendingInterest + rest.pendingInterest,
                }
            }

            // const memberloansIdx = state.memberLoans.findIndex(
            //     (loan) => loan.id === action.payload?.id
            // )
            // if (memberloansIdx >= 0) {
            //     state.memberLoans[memberloansIdx] = action.payload
            // }
        })
        builder.addCase(payLoan.fulfilled, (state, action) => {
            if (!action.payload) return
            const payload: {
                id: string
            } = action.payload
            const idx = state.loans.findIndex((loan) => loan.id === payload.id)
            if (idx >= 0) {
                const { id, ...rest } = action.payload
                state.loans[idx] = {
                    ...state.loans[idx],
                    totalInterestPaid:
                        state.loans[idx].totalInterestPaid +
                        rest.totalInterestPaid,
                    totalPrincipalPaid:
                        state.loans[idx].totalPrincipalPaid +
                        rest.totalPrincipalPaid,
                    remainingBalance:
                        state.loans[idx].remainingBalance +
                        rest.remainingBalance,
                    pendingInterest:
                        state.loans[idx].pendingInterest + rest.pendingInterest,
                }
            }
        })
    },
})

export const { updateLoanState } = loansSlice.actions
