import { message } from 'antd'
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import moment from 'moment'
import { auth, db } from '../../../app/boot'
import { Expense } from '../../../domain/entities/SystemSettings'
import { ExpenseFirebaseRepository } from '../../../domain/repositories/expense.repository'
import { EXPENSE_REPOSITORY } from '../constants'
import { getErrorMessage } from '../default'

export class ExpenseFirebaseImplementation
    implements ExpenseFirebaseRepository
{
    async AddExpense(payload: Expense) {
        try {
            const resp = await addDoc(
                collection(db, EXPENSE_REPOSITORY),
                payload
            )
            payload.id = resp.id
            return payload
        } catch (err) {
            message.error(getErrorMessage(err))
            return false
        }
    }
    async getTeamExpenses(partnerCode: string) {
        const ref = collection(db, EXPENSE_REPOSITORY)
        const q = query(
            ref,
            where('partnerCode', '==', partnerCode),
            where('isRemoved', '==', false)
        )

        const snap = await getDocs(q)
        return snap.docs.map((docSnap) => {
            let expenseData = docSnap.data()
            return {
                ...expenseData,
                id: docSnap.id,
            }
        })
    }
    async DeleteExpense(id: string) {
        const ref = doc(db, EXPENSE_REPOSITORY, id)
        await deleteDoc(ref)
        return id
    }
    async UpdateExpense(
        payload: Expense & {
            id: string
        }
    ) {
        const { id, ...rest } = payload
        await updateDoc(doc(db, EXPENSE_REPOSITORY, id), {
            ...rest,
            modified: moment().unix(),
            modifiedBy: auth.currentUser?.uid,
        })
        return payload
    }
    async GetExpense(id: string) {
        const snap = await getDoc(doc(db, EXPENSE_REPOSITORY, id))
        const data = snap.data()

        return {
            ...data,
            id,
        } as Expense
    }

    async createCategory(payload: any) {
        const resp = await addDoc(collection(db, EXPENSE_REPOSITORY), payload)

        return resp.id
    }
}
