import './index.css'
import React, { useEffect, useState } from 'react'
import { MemberReceivable } from '../../../../domain/entities/MemberReceivable'
// import { Member } from '../../../../domain/entities/Member'
import MembersReceivableTable from './MembersReceivableTable'
import { useAppDispatch, useAppSelector } from '../../../store'
import { shallowEqual } from 'react-redux'
import { keyValueDates } from './utils'
// import moment from 'moment'
import { Expense } from '../../../../domain/entities/SystemSettings'
// import YearSelection from './components/YearSelection'
import YearSelection from './components/YearSelection'
import { getTeamMembers } from '../../../redux/team/team.action'
// import { TeamMember } from '../../../../domain/entities/Team'
import { Button, Col, Grid, Row, Statistic } from 'antd'
import { ProCard } from '@ant-design/pro-components'
import { MdPerson } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

export const maintenancePercentage = 0.1
interface MonthObjProp {
    sharesArray: any
    loansArray: any
    weight: number
    totalShares: number
    totalIncome: number
    data: MemberReceivable[]
    totalExpenses: number
    expenseArray: Expense[]
    totalWeightedShare: number
}
interface MonthsObjProp {
    [key: string]: MonthObjProp
}

export interface MemberReceivableReportSummary {
    totalShares: number
    totalIncome: number
    totalExpenses: number
    totalRemainingBalance: number
    totalUnpaidInterest: number
    totalWeightedShares: number
    totalPrincipalPaid: number
    overallLoan: number
    totalPaymentsReceived: number
}
const obj: MonthsObjProp = {}
keyValueDates.forEach((keyValueDate) => {
    obj[keyValueDate.label] = {
        weight: keyValueDate.value / 12,
        totalShares: 0,
        totalIncome: 0,
        data: [],
        sharesArray: [],
        loansArray: [],
        totalExpenses: 0,
        totalWeightedShare: 0,
        expenseArray: [],
    }
})
function MemberReceivableReport() {
    const {
        members,
        shareDividendFrequency,
        deductionFrequency,
        deductionFormula,
        systemData,
        currentYear,
        activeTeam,
        styles,
    } = useAppSelector(
        (state) => ({
            members: state.team.currentTeamMembers,
            shareDividendFrequency: state.systemConfig.shareDividendFrequency,
            deductionFrequency: state.systemConfig.deductionFrequency,
            deductionFormula: state.systemConfig.deductionFormula,
            systemData: state.team.selectedTeamData,
            currentYear: state.systemConfig.currentYear,
            activeTeam: state.auth.user.activeTeam,
            styles: state.styles,
        }),
        shallowEqual
    )
    // const [currentYear, setCurrentYear] = useState(moment().format('YYYY'))
    // const { xs } = Grid.useBreakpoint()
    const [currentMonth] = useState(12)
    const [, setMembersReceivableBreakdown] = useState<MemberReceivable[]>([])

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!systemData.financialPerformance) return
        // if (!membersReady) return
        // const annualData: MemberReceivableReportSummary = {
        //     totalExpenses: 0,
        //     totalShares: 0,
        //     totalIncome: 0,
        //     totalRemainingBalance: 0,
        //     totalUnpaidInterest: 0,
        //     totalWeightedShares: 0,
        //     totalPrincipalPaid: 0,
        //     overallLoan: 0,
        // }
        // const sysData: Team = systemData
        // if (
        //     sysData.memberShares[currentYear] &&
        //     sysData.memberShares[currentYear]['Annual']
        // ) {
        //     annualData.totalShares = sysData.memberShares[currentYear]['Annual']
        // }

        // if (
        //     sysData.overallIncome[currentYear] &&
        //     sysData.overallIncome[currentYear]['Annual']
        // ) {
        //     annualData.totalIncome =
        //         sysData.overallIncome[currentYear]['Annual']
        // }

        // if (
        //     sysData.remainingBalance[currentYear] &&
        //     sysData.remainingBalance[currentYear]['Annual']
        // ) {
        //     annualData.totalRemainingBalance =
        //         sysData.remainingBalance[currentYear]['Annual']
        // }

        // if (
        //     sysData.overallUnpaidInterest[currentYear] &&
        //     sysData.overallUnpaidInterest[currentYear]['Annual']
        // ) {
        //     annualData.totalUnpaidInterest =
        //         sysData.overallUnpaidInterest[currentYear]['Annual']
        // }

        // if (
        //     sysData.weightedMemberShares[currentYear] &&
        //     sysData.weightedMemberShares[currentYear]['Annual']
        // ) {
        //     annualData.totalWeightedShares =
        //         sysData.weightedMemberShares[currentYear]['Annual']
        // }

        // if (
        //     sysData.overallPrincipal[currentYear] &&
        //     sysData.overallPrincipal[currentYear]['Annual']
        // ) {
        //     annualData.totalPrincipalPaid =
        //         sysData.overallPrincipal[currentYear]['Annual']
        // }

        // if (
        //     sysData.overallLoan[currentYear] &&
        //     sysData.overallLoan[currentYear]['Annual']
        // ) {
        //     annualData.overallLoan = sysData.overallLoan[currentYear]['Annual']
        // }
        // annualData.overallLoan = sysData.overallLoan[currentYear]

        // setCashInHand({
        //     totalIncome: annualData.totalIncome,
        //     totalShares: annualData.totalShares,
        //     totalUnpaidInterest: annualData.totalUnpaidInterest,
        //     totalRemainingBalance: annualData.totalRemainingBalance
        // })
        // setCashInHand(
        //     annualData.totalIncome +
        //         annualData.totalShares -
        //         annualData.totalRemainingBalance -
        //         annualData.totalUnpaidInterest
        // )
        // setCurrentData(annualData)
        const memberPayable: MemberReceivable[] = []
        members.forEach((member) => {
            const net = 0
            memberPayable.push({
                id: member.id,
                name: (member?.displayName as string) || '',
                weightedContributions: member?.memberPreferredShares || 0,
                contributions: member?.memberShares || 0,
                percentage: 0,
                dividendByWeightedShare: 0,
                gross: 0,
                totalDeductions: 0,
                net,
                modifiedBy: member.modifiedBy,
                modified: member.modified,
                creation: member.creation,
                isRemoved: false,
            })
        })
        setMembersReceivableBreakdown(memberPayable)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [members, currentYear, deductionFrequency, deductionFormula])

    useEffect(() => {
        if (!activeTeam) return
        dispatch(
            getTeamMembers({
                activeTeam,
                pageName: 'Receivable',
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam])

    // useEffect(() => {
    //     if (!systemData.financialPerformance) return

    //     // const annualData = {
    //     //     totalExpenses: systemData.financialPerformance.totalExpense || 0,
    //     //     totalShares: systemData.financialPerformance.memberShares || 0,
    //     //     totalIncome: systemData.financialPerformance.overallIncome || 0,
    //     //     totalRemainingBalance: systemData.financialPerformance.remainingBalance || 0,
    //     //     totalUnpaidInterest: systemData.financialPerformance.overallUnpaidInterest || 0,
    //     //     totalWeightedShares: 0,
    //     //     totalPrincipalPaid: systemData.financialPerformance.overallPrincipal || 0,
    //     //     overallLoan: systemData.financialPerformance.overallLoan || 0,
    //     // }

    //     // setCurrentData(annualData)
    // }, [systemData])

    const navigate = useNavigate()
    const handleNewMember = () => {
        navigate('new-member')
    }
    const { xs } = Grid.useBreakpoint()
    return (
        <div className="page-layout">
            <YearSelection />
            <Row>
                <Col xs={12} md={6}>
                    <ProCard
                        style={{
                            maxHeight: 100,
                            cursor: 'pointer',
                        }}
                        bodyStyle={{
                            maxHeight: 100,
                            padding: 16,
                        }}
                        onClick={handleNewMember}
                        colSpan={xs ? 100 : '200px'}
                    >
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <MdPerson
                                    style={{
                                        fontSize: xs ? 16 : 28,
                                        color: styles.primary,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <Button
                                    style={{
                                        color: styles.primary,
                                        fontSize: xs ? 16 : 20,
                                        padding: 0,
                                    }}
                                    type={'text'}
                                    onClick={handleNewMember}
                                >
                                    New Member
                                </Button>
                            </Col>
                        </Row>
                    </ProCard>
                </Col>
                <Col xs={12} md={6}>
                    <ProCard
                        style={{
                            borderRadius: 0,
                            height: '100%',

                            backgroundColor: 'transparent',
                        }}
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                        bordered
                        title="Total Members"
                    >
                        <Statistic
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                            value={systemData.totalMembers || 0}
                        />
                    </ProCard>
                </Col>
            </Row>
            <MembersReceivableTable
                currentMonth={keyValueDates[currentMonth].label}
                data={members}
                shareDividendFrequency={shareDividendFrequency}
            />
        </div>
    )
}

export default MemberReceivableReport
