import // LoadingOutlined,
// PlusOutlined,
// UploadOutlined,
'@ant-design/icons'
import { auth } from '../../boot'
import { useAppSelector } from '../../../app/store'
import { ProForm, ProFormText } from '@ant-design/pro-components'
import {
    // Button,
    Card,
    Col,
    // Image,
    Row,
    // Upload,
    // UploadProps,
    message,
} from 'antd'
import {
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
} from 'firebase/auth'
import React from 'react'
import { shallowEqual } from 'react-redux'
// import { PERSONNEL_REPOSITORY } from '../../../data/repositories/constants'
// import { updateDoc, doc } from 'firebase/firestore'
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
// import moment from 'moment'
// import { useParams } from 'react-router-dom'

function ChangePassword() {
    const [passForm] = ProForm.useForm()
    // const [loading, setLoading] = useState(false)
    // const params = useParams()

    const { email } = useAppSelector(
        (state) => ({
            // styles: state.styles,
            email: state.auth.user.email,
            // activeTeam: state.auth.user.activeTeam,
            // currentTeamMembers: state.team.currentTeamMembers,
        }),
        shallowEqual
    )
    const handlePassForm = async (data: any) => {
        const credential = EmailAuthProvider.credential(
            email,
            data.currentPassword
        )

        // Prompt the user to re-provide their sign-in credentials
        const user = auth.currentUser
        if (!user) return
        try {
            const response = await reauthenticateWithCredential(
                user,
                credential
            )
            await updatePassword(response.user, data.newPassword)
            message.destroy()
            message.success('Password updated successfully', 5)
            passForm.resetFields()
        } catch (err) {
            if (err instanceof Error) {
                message.error(err.message)
            } else {
                message.error('An unknown error occurred.')
            }
        }
    }
    // const [imageUrl, setImageUrl] = useState<string>()

    // const getBase64 = (img: any, callback: (url: string) => void) => {
    //     const reader = new FileReader()
    //     reader.addEventListener('load', () => callback(reader.result as string))
    //     reader.readAsDataURL(img)
    // }

    // const beforeUpload = (file: any) => {
    //     const isJpgOrPng =
    //         file.type === 'image/jpeg' || file.type === 'image/png'
    //     if (!isJpgOrPng) {
    //         message.error('You can only upload JPG/PNG file!')
    //     }
    //     const isLt2M = file.size / 1024 / 1024 < 2
    //     if (!isLt2M) {
    //         message.error('Image must smaller than 2MB!')
    //     }
    //     return isJpgOrPng && isLt2M
    // }
    // const handleUploadChange = async (files: any) => {
    //     const logoRef = ref(
    //         storage,
    //         `/${activeTeam}/personnel/${moment().unix()}/${files.file.name}`
    //     )
    //     const uploadTask = uploadBytesResumable(
    //         logoRef,
    //         files.file.originFileObj
    //     )
    //     uploadTask.on(
    //         'state_changed',
    //         (snapshot) => {
    //             // Observe state change events such as progress, pause, and resume
    //             // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //             switch (snapshot.state) {
    //                 case 'paused':
    //                     // eslint-disable-next-line no-console
    //                     console.log('Upload is paused')
    //                     break
    //                 case 'running':
    //                     // eslint-disable-next-line no-console
    //                     console.log('Upload is running')
    //                     break
    //             }
    //         },
    //         (error) => {
    //             // Handle unsuccessful uploads
    //             setLoading(false)
    //         },
    //         () => {
    //             // Handle successful uploads on complete
    //             // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //             getDownloadURL(uploadTask.snapshot.ref).then(
    //                 async (downloadURL) => {
    //                     setImageUrl(downloadURL)
    //                     setLoading(false)
    //                     await updateDoc(
    //                         doc(
    //                             db,
    //                             PERSONNEL_REPOSITORY,
    //                             params.userId as string
    //                         ),
    //                         {
    //                             idUrl: downloadURL,
    //                         }
    //                     )
    //                 }
    //             )
    //         }
    //     )
    // }
    // const handleChange: UploadProps['onChange'] = (info: any) => {
    //     if (info.file.status === 'uploading') {
    //         setLoading(true)
    //         handleUploadChange(info)
    //         return
    //     }
    //     if (info.file.status === 'done') {
    //         // Get this url from response in real world.
    //         getBase64(info.file.originFileObj as any, (url) => {
    //             setLoading(false)
    //             setImageUrl(url)
    //         })
    //     }
    // }
    // const uploadButton = (
    //     <div>
    //         {loading ? <LoadingOutlined /> : <PlusOutlined />}
    //         <div style={{ marginTop: 8 }}>Upload</div>
    //     </div>
    // )
    return (
        <div>
            <br />
            <br />
            <Row gutter={[12, 12]} justify={'center'}>
                {/* <Col>
                    <Card>
                        <Row>
                            <Col span={14}>
                                <Card.Meta
                                    title="Picture"
                                    description="Photo identification"
                                />
                            </Col>
                            <Col span={10}>
                                {imageUrl ? (
                                    <div>
                                        <Image
                                            src={imageUrl}
                                            alt="avatar"
                                            style={{ width: '100%' }}
                                        />
                                        <Upload
                                            showUploadList={false}
                                            beforeUpload={beforeUpload}
                                            multiple={false}
                                            customRequest={() => null}
                                            onChange={handleChange}
                                        >
                                            <Button
                                                type="text"
                                                icon={<UploadOutlined />}
                                            >
                                                Replace
                                            </Button>
                                        </Upload>
                                    </div>
                                ) : (
                                    <div>
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={beforeUpload}
                                            multiple={false}
                                            customRequest={() => null}
                                            onChange={handleChange}
                                        >
                                            {uploadButton}
                                        </Upload>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col> */}
                <Col>
                    <Card title={'Change Password'}>
                        <ProForm
                            form={passForm}
                            onFinish={handlePassForm}
                            onFinishFailed={(err) => {
                                if (err.errorFields.length) {
                                    const firstError = err.errorFields[0]
                                    message.destroy()
                                    message.error(firstError.errors)
                                    passForm.scrollToField(firstError.name, {
                                        behavior: 'smooth',
                                        block: 'center',
                                    })
                                }
                            }}
                        >
                            <ProFormText.Password
                                name="currentPassword"
                                label="Current Password"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please enter your current password',
                                    },
                                ]}
                            />
                            <ProFormText.Password
                                name="newPassword"
                                label="New Password"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please enter your new password',
                                    },
                                    {
                                        min: 8,
                                        message:
                                            'Password must be at least 8 characters',
                                    },
                                ]}
                            />
                            <ProFormText.Password
                                name="confirmNewPassword"
                                label="Confirm New Password"
                                dependencies={['newPassword']}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please confirm your new password',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('newPassword') ===
                                                    value
                                            ) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'New passwords do not match'
                                                )
                                            )
                                        },
                                    }),
                                ]}
                            />
                        </ProForm>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ChangePassword
