import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ShareService } from '../../../domain/usecases/shares.usecase'
import { CalculateShare, Share } from '../../../domain/entities/Share'
import { ShareFirebaseImplementation } from '../../../data/repositories/share/share.firebase'
import {
    TeamMember,
    teamMemberInitialState,
} from '../../../domain/entities/Team'

interface AddDataSharesType extends TeamMember {
    show: boolean
    showUserSharesBreakdown: boolean
    frequency: string
}
interface InitState {
    showAddModal: boolean
    addData: AddDataSharesType
    shares: Share[]
    ready: boolean
}
export const initialShareState: InitState = {
    showAddModal: false,
    addData: {
        ...teamMemberInitialState,
        memberId: '',
        status: 'pending',
        isRemoved: false,
        dateAccepted: 0,
        show: false,
        showUserSharesBreakdown: false,
        frequency: '',
    },
    shares: [],
    ready: false,
}

const localImpl = new ShareFirebaseImplementation()

export const addShare = createAsyncThunk(
    'shares/addShare',
    async (payload: Omit<Share, 'id'>) => {
        const shareService = new ShareService(localImpl)
        return await shareService.AddShare(payload)
    }
)

export const getAllMemberShare = createAsyncThunk(
    'shares/getAllMemberShare',
    async (payload: { memberId: string; partnerCode: string }) => {
        const shareService = new ShareService(localImpl)
        return await shareService.GetAllMemberShare(payload)
    }
)

export const getTeamShares = createAsyncThunk(
    'shares/getAllMemberShare',
    async (partnerCode: string) => {
        const shareService = new ShareService(localImpl)
        return await shareService.getTeamShares(partnerCode)
    }
)

export const getShare = async (shareId: string) => {
    const shareService = new ShareService(localImpl)
    return await shareService.GetShare(shareId)
}

export const deleteShare = createAsyncThunk(
    'shares/deleteShare',
    async (
        payload: CalculateShare & {
            id: string
        }
    ) => {
        const shareService = new ShareService(localImpl)
        return await shareService.DeleteShare(payload)
    }
)

export const updateShare = createAsyncThunk(
    'shares/updateShare',
    async (updatePayload: { payload: Share; prevShare: CalculateShare }) => {
        const shareService = new ShareService(localImpl)
        return await shareService.UpdateShare(
            updatePayload.payload,
            updatePayload.prevShare
        )
    }
)

export const shareSlice = createSlice({
    name: 'shares',
    initialState: initialShareState,
    reducers: {
        updateSharesState: (
            state: { [key: string]: any },
            action: PayloadAction<{
                name: string
                value: any
            }>
        ) => {
            state[action.payload.name] = action.payload.value
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllMemberShare.fulfilled, (state, action) => {
            state.shares = action.payload
            state.ready = true
        })
        builder.addCase(getAllMemberShare.pending, (state) => {
            state.ready = false
        })
        builder.addCase(addShare.fulfilled, (state, action) => {
            if (!action.payload) return
            state.shares = [...state.shares, action.payload]
        })
        builder.addCase(deleteShare.fulfilled, (state, action) => {
            const idx = state.shares.findIndex(
                (share) => share.id === action.payload
            )
            if (idx >= 0) {
                state.shares.splice(idx, 1)
            }
        })
        builder.addCase(updateShare.fulfilled, (state, action) => {
            const idx = state.shares.findIndex(
                (share) => share.id === action?.payload?.id
            )
            if (idx >= 0 && action.payload) {
                state.shares[idx] = action.payload
            }
        })
    },
})

export const { updateSharesState } = shareSlice.actions
