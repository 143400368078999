import { Expense } from '../entities/SystemSettings'
import { ExpenseFirebaseRepository } from '../repositories/expense.repository'

export class ExpenseService {
    cloudRepo: ExpenseFirebaseRepository

    constructor(ir: ExpenseFirebaseRepository) {
        this.cloudRepo = ir
    }
    async AddExpense(payload: Expense): Promise<any> {
        return await this.cloudRepo.AddExpense(payload)
    }
    async getTeamExpenses(partnerCode: string): Promise<any> {
        return await this.cloudRepo.getTeamExpenses(partnerCode)
    }
    async DeleteExpense(id: string): Promise<any> {
        return await this.cloudRepo.DeleteExpense(id)
    }
    async UpdateExpense(
        payload: Expense & {
            id: string
        }
    ): Promise<any> {
        return await this.cloudRepo.UpdateExpense(payload)
    }
    async GetExpense(id: string): Promise<any> {
        return await this.cloudRepo.GetExpense(id)
    }
    async createCategory(payload: any): Promise<any> {
        return await this.cloudRepo.createCategory(payload)
    }
}
