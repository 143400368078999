import {
    DeleteOutlined,
    FilterFilled,
    HistoryOutlined,
    IssuesCloseOutlined,
    PlusOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import { ProCard, ProColumns } from '@ant-design/pro-components'
import {
    Row,
    Col,
    Button,
    Input,
    Dropdown,
    Menu,
    Modal,
    Statistic,
    Typography,
    Grid,
} from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { Saving, savingsAccountStatuses } from '../../../domain/entities/Saving'
import {
    deleteSaving,
    updateSavingsState,
} from '../../redux/savings/savings.slice'
import { useAppDispatch, useAppSelector } from '../../store'
import Table from '../../components/Table'
import CreateSavings from './CreateSavings'
import CreateDeposit from './CreateDeposit'
import SavingsDeposits from './SavingsDeposits'
import {
    updateDoc,
    doc,
    onSnapshot,
    query,
    where,
    collection,
} from 'firebase/firestore'
import { SAVINGS_REPOSITORY } from '../../../data/repositories/constants'
import { db } from '../../boot'

const savingColumns: ProColumns<Saving>[] = [
    {
        title: 'Date Opened',
        dataIndex: 'openingDate',
        defaultSortOrder: 'ascend',
        render: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            const date = moment.unix(text)
            return `${date.format('MMMM DD, YYYY ')}`
        },
    },
    {
        title: 'Name',
        dataIndex: 'accountHolder',
    },
    {
        title: 'Current Balance',
        dataIndex: 'currentBalance',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Account Status',
        dataIndex: 'status',
    },
]
const SavingsContainer = () => {
    const [toSearch, setToSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState('active')
    const {
        savings,
        styles,
        currentTeamPermission,
        activeTeam,
        systemData,
        // members
    } = useAppSelector(
        (state) => ({
            activeTeam: state.auth.user.activeTeam,
            savings: state.savings.savings,
            members: state.team.currentTeamMembers,
            styles: state.styles,
            currentTeamPermission: state.team.currentTeamPermission,
            currentYear: state.systemConfig.currentYear,
            systemData: state.team.selectedTeamData,
        }),
        shallowEqual
    )

    const [filteredSavings, setFilteredSavings] = useState<any>(savings)
    // const [savingData, setSavingData] = useState<any>({
    //     show: false,
    // })
    const [showAddDeposit, setShowAddDeposit] = useState<{
        show: boolean
        data: Saving | undefined
    }>({
        show: false,
        data: undefined,
    })

    const [showDepositList, setDepositList] = useState<{
        show: boolean
        data: Saving | undefined
    }>({
        show: false,
        data: undefined,
    })

    const dispatch = useAppDispatch()

    const handleSearch = (e: string) => {
        setToSearch(e)
    }

    // const handleShowAddSavings = () => {
    //     setSavingData({
    //         show: true
    //     })
    // }
    const handleShowAddSavings = () => {
        dispatch(
            updateSavingsState({
                name: 'addData',
                value: {
                    show: true,
                },
            })
        )
    }

    // const handleSearch = (txt: string) => {
    //     setToSearch(txt)
    // }
    // const handleEditSaving = (row: Saving) => {
    // const member = members.find((member) => member.id === row.memberId)

    // setSavingData({
    //     ...row,
    //     memberName: member?.displayName || row.memberId,
    //     show: true,
    // })
    // }
    // const handleCloseEditSaving = () => {
    //     setSavingData({
    //         show: false,
    //     })
    // }

    const handleCloseAccount = async (row: Saving) => {
        const paymentRef = doc(db, SAVINGS_REPOSITORY, row.id as string)
        await updateDoc(paymentRef, {
            status: 'closed',
        })
    }
    const handleDeleteSaving = async (row: Saving) => {
        setLoading(true)
        await dispatch(deleteSaving(row.id as string))
        setLoading(false)
    }

    const handleDeposits = (data: any) => {
        setShowAddDeposit({
            show: true,
            data,
        })
    }

    const handleCancelAddDeposits = () => {
        setShowAddDeposit({
            show: false,
            data: undefined,
        })
    }

    const handleShowDeposits = (data: any) => {
        setDepositList({
            show: true,
            data,
        })
    }

    const handleCancelDeposits = () => {
        setDepositList({
            show: false,
            data: undefined,
        })
    }

    useEffect(() => {
        if (!activeTeam) return
        // dispatch(getTeamSavings(activeTeam))
        const ref = collection(db, 'savings')
        const q = query(
            ref,
            where('status', '==', status),
            where('partnerCode', '==', activeTeam),
            where('isRemoved', '==', false)
        )
        const unsub = onSnapshot(q, (doc) => {
            const data = doc.docs.map((docSnap) => ({
                ...docSnap.data(),
                id: docSnap.id,
            }))

            dispatch(
                updateSavingsState({
                    name: 'savings',
                    value: data,
                })
            )
        })

        return () => unsub()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam, status])

    useEffect(() => {
        let timeout: any
        if (toSearch) {
            timeout = setTimeout(() => {
                const filtered = [...savings].filter((saving: Saving) => {
                    const splittedTitle = saving.accountHolder || ''
                    return (
                        splittedTitle
                            .toLowerCase()
                            .replace(/ /g, '')
                            .includes(toSearch) ||
                        splittedTitle.toLowerCase().includes(toSearch)
                    )
                })
                setFilteredSavings(filtered)
            }, 100)
        } else {
            setFilteredSavings(savings)
        }
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [savings, toSearch])
    const { xs } = Grid.useBreakpoint()

    return (
        <div className="page-layout">
            <CreateSavings />
            {showAddDeposit.show && (
                <CreateDeposit
                    row={showAddDeposit.data as Saving}
                    handleCancel={handleCancelAddDeposits}
                />
            )}
            {showDepositList.show && (
                <SavingsDeposits
                    savingsData={showDepositList.data as Saving}
                    handleCancel={handleCancelDeposits}
                />
            )}
            <div
                style={{
                    padding: '16px 0',
                }}
            >
                <Row
                    className="divider-section"
                    // justify={xs ? 'start' : 'space-evenly'}
                >
                    <Col xs={12} md={6}>
                        <ProCard
                            bordered
                            style={{
                                borderRadius: 0,
                                height: '100%',

                                backgroundColor: 'transparent',
                            }}
                            bodyStyle={{
                                padding: xs ? 12 : undefined,
                            }}
                        >
                            <Statistic
                                title="Cash in Bank"
                                valueStyle={{
                                    fontSize: xs ? 16 : undefined,
                                }}
                                value={
                                    (systemData.bankingPerformance
                                        ?.totalDeposits || 0) -
                                    (systemData.bankingPerformance
                                        ?.totalWithdrawals || 0)
                                }
                                prefix="₱"
                                precision={2}
                            />
                        </ProCard>
                    </Col>
                    <Col xs={12} md={6}>
                        <ProCard
                            bordered
                            style={{
                                borderRadius: 0,
                                height: '100%',

                                backgroundColor: 'transparent',
                            }}
                            bodyStyle={{
                                padding: xs ? 12 : undefined,
                            }}
                        >
                            <Statistic
                                title="Total Deposits"
                                valueStyle={{
                                    fontSize: xs ? 16 : undefined,
                                }}
                                value={(
                                    systemData.bankingPerformance
                                        ?.totalDeposits || 0
                                ).toFixed(2)}
                                prefix="₱"
                                precision={2}
                            />
                        </ProCard>
                    </Col>
                    <Col xs={12} md={6}>
                        <ProCard
                            bordered
                            style={{
                                backgroundColor: 'transparent',
                                borderRadius: 0,
                                height: '100%',
                            }}
                            bodyStyle={{
                                padding: xs ? 12 : undefined,
                            }}
                        >
                            <Statistic
                                title="Total Withdrawals"
                                valueStyle={{
                                    fontSize: xs ? 16 : undefined,
                                }}
                                value={(
                                    systemData.bankingPerformance
                                        ?.totalWithdrawals || 0
                                ).toFixed(2)}
                                prefix="₱"
                                precision={2}
                            />
                        </ProCard>
                    </Col>
                    <Col xs={12} md={6}>
                        <ProCard
                            bordered
                            style={{
                                backgroundColor: 'transparent',
                                borderRadius: 0,
                                height: '100%',
                            }}
                            bodyStyle={{
                                padding: xs ? 12 : undefined,
                            }}
                        >
                            <Statistic
                                title="Total opened accounts"
                                valueStyle={{
                                    fontSize: xs ? 16 : undefined,
                                    textAlign: 'center',
                                }}
                                value={
                                    systemData.bankingPerformance
                                        ?.totalAccounts || 0
                                }
                            />
                        </ProCard>
                    </Col>
                </Row>
                <br />
                <Row
                    align={'middle'}
                    gutter={[
                        {
                            xs: 6,
                            md: 12,
                        },
                        {
                            xs: 6,
                            md: 12,
                        },
                    ]}
                    wrap={false}
                >
                    <Col>
                        <Button
                            size={'large'}
                            shape={'round'}
                            onClick={handleShowAddSavings}
                            style={{
                                backgroundColor: styles.primary,
                                color: 'white',
                            }}
                        >
                            <PlusOutlined />
                            Saving
                        </Button>
                    </Col>

                    <Col>
                        <Input
                            value={toSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                            allowClear
                            placeholder={'Search by title'}
                            suffix={
                                <Button icon={<SearchOutlined />} type="link" />
                            }
                            // addonBefore={xs ? null : <>Search name</>}
                            size="large"
                        />
                    </Col>
                    <Col>
                        <Dropdown
                            menu={{
                                items: savingsAccountStatuses.map(
                                    (accountStat) => ({
                                        title: accountStat.label,
                                        label: accountStat.label,
                                        key: accountStat.value,
                                    })
                                ),
                                onClick: (selected) => setStatus(selected.key),
                                activeKey: status,
                            }}
                            trigger={['click']}
                        >
                            <FilterFilled
                                style={{ color: styles.primary, fontSize: 24 }}
                            />
                        </Dropdown>
                    </Col>
                </Row>
            </div>
            <Table
                scroll={{ x: 800 }}
                dataSource={filteredSavings}
                columns={[
                    ...savingColumns,
                    {
                        key: 'option',
                        valueType: 'option',
                        hideInTable: currentTeamPermission.role === 'viewer',
                        align: 'center',
                        render: (_, row: Saving, idx) => {
                            const toReturn = [
                                {
                                    icon: (
                                        <HistoryOutlined
                                            key={'view-deposit-history'}
                                            style={{
                                                fontSize: 16,
                                                color: '#1890ff',
                                            }}
                                        />
                                    ),
                                    title: 'Transaction History',
                                    key: 'deposit-history',
                                    onClick: () => handleShowDeposits(row),
                                },
                                {
                                    icon: (
                                        <IssuesCloseOutlined
                                            key={'close-saving-payment'}
                                            style={{
                                                fontSize: 16,
                                                color: 'gold',
                                            }}
                                        />
                                    ),
                                    title: 'Close this account',
                                    key: 'close-saving',
                                    onClick: () => handleCloseAccount(row),
                                },
                                showDepositList.data &&
                                showDepositList.data.currentBalance <= 0
                                    ? {
                                          icon: (
                                              <DeleteOutlined
                                                  key={'del-saving'}
                                                  style={{
                                                      color: 'red',
                                                      fontSize: 16,
                                                  }}
                                              />
                                          ),
                                          title: 'Delete Saving',
                                          key: 'delete-saving',
                                          onClick: () => {
                                              const title =
                                                  'Are you sure to delete this saving? This cannot be undone.'

                                              Modal.confirm({
                                                  centered: true,
                                                  title,
                                                  onOk: () =>
                                                      handleDeleteSaving(row),
                                                  autoFocusButton: 'cancel',
                                                  okButtonProps: {
                                                      loading,
                                                  },
                                              })
                                          },
                                      }
                                    : {},
                            ]

                            return [
                                <Typography.Link
                                    onClick={() => handleDeposits(row)}
                                    key={'edit-borrow-table-actions-xyz'}
                                >
                                    Transact
                                </Typography.Link>,
                                <Dropdown
                                    key="action-saving"
                                    trigger={['click']}
                                    overlay={
                                        <Menu>
                                            {toReturn.map((action) => (
                                                <Menu.Item
                                                    key={action.key}
                                                    icon={action.icon}
                                                    onClick={action.onClick}
                                                >
                                                    {action.title}
                                                </Menu.Item>
                                            ))}
                                        </Menu>
                                    }
                                >
                                    <Button type="link">Action</Button>
                                </Dropdown>,
                            ]
                        },
                    },
                ]}
                // headerTitle={
                // }
                toolBarRender={false}
            />
        </div>
    )
}

export default SavingsContainer
