import { DeleteOutlined } from '@ant-design/icons'
import { ModalForm, ProColumns } from '@ant-design/pro-components'
import { Form, message, Typography, Modal, Statistic } from 'antd'
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SAVINGS_REPOSITORY } from '../../../data/repositories/constants'
import { Deposit, Saving } from '../../../domain/entities/Saving'
import { db } from '../../boot'
import Table from '../../components/Table'

const depositsColumn: ProColumns<Deposit>[] = [
    {
        title: 'Date',
        dataIndex: 'creation',
        defaultSortOrder: 'ascend',
        render: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            const date = moment.unix(text)
            return `${date.format('MMMM DD, YYYY ')}`
        },
    },
    {
        title: 'Transaction',
        dataIndex: 'transactionType',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Remarks/Notes',
        dataIndex: 'remarks',
    },
]
function SavingsDeposits({
    savingsData,
    handleCancel,
}: {
    savingsData: Saving
    handleCancel: () => void
}) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [deposits, setDeposits] = useState<Deposit[]>([])
    // const {
    //     // currentYear
    // } = useAppSelector(
    //     (state) => ({
    //         currency: state.systemConfig.currency,
    //         // currentYear: state.systemConfig.currentYear,
    //         styles: state.styles,
    //     }),
    //     shallowEqual
    // )
    // const handleGetUserSavings = async () => {

    //     // const memberSavings = await getMemberSaving()
    //     // console.log(memberSavings)
    //     // setSavings(
    //     //     memberSavings.filter((saving: Saving) => {
    //     //         const yearOfSaving = moment.unix(saving.creation).format('YYYY')
    //     //         if (yearOfSaving === currentYear) return true

    //     //         return false
    //     //     })
    //     // )
    //     // dispatch(getMembers())
    // }
    // const handleCancel = () => {
    //     form.resetFields()
    // }

    const handleDeleteDeposit = async (data: Deposit, idx: number) => {
        setLoading(true)

        const paymentRef = doc(
            db,
            SAVINGS_REPOSITORY,
            data.savingsAccountId as string,
            'deposits',
            data.id as string
        )
        await deleteDoc(paymentRef)
        const newDeposits = [...deposits]
        newDeposits.splice(idx, 1)
        setDeposits(newDeposits)
        message.success('Deposit successfully deleted.')
        setLoading(false)
    }

    // const handleSuccess = () => {
    //     // dispatch(getMembers())
    //     // handleGetUserSavings()
    // }

    useEffect(() => {
        const main = async () => {
            setLoading(true)
            const col = collection(
                db,
                SAVINGS_REPOSITORY,
                savingsData.id as string,
                'deposits'
            )
            const docs = await getDocs(col)
            if (!docs.empty) {
                const data = docs.docs.map(
                    (snap) =>
                        ({
                            ...snap.data(),
                            id: snap.id,
                        } as Deposit & {
                            id: string
                        })
                )
                setDeposits([
                    {
                        creation: savingsData.openingDate,
                        transactionType: 'opening',
                        amount: savingsData.openingBalance,
                        remarks: 'Initial opening amount',
                        id: 'Initial opening amount',
                    } as any,
                    ...data,
                ])
            }
            setLoading(false)
        }
        main()
        // if (addData.showUserSavingsBreakdown) {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savingsData])

    return (
        <ModalForm
            form={form}
            onFinishFailed={(err) => {
                if (err.errorFields.length) {
                    const firstError = err.errorFields[0]
                    message.destroy()
                    message.error(firstError.errors)
                    form.scrollToField(firstError.name, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }}
            open
            modalProps={{
                onCancel: handleCancel,
                maskClosable: true,
                closable: true,
                centered: true,
            }}
            submitter={false}
            title={
                <>
                    <Typography.Title level={3}>
                        {savingsData.accountHolder}
                    </Typography.Title>
                    <Typography.Text type={'secondary'}>
                        Savings Account History
                    </Typography.Text>
                </>
            }
        >
            <Table
                loading={loading}
                dataSource={deposits}
                columns={[
                    ...depositsColumn,
                    {
                        title: 'Actions',
                        key: 'option',
                        valueType: 'option',
                        render: (_, row, idx) => {
                            if (
                                row.modifiedBy === 'system' ||
                                row.modifiedBy === 'automated'
                            )
                                return null
                            if (row.transactionType === 'opening') return null
                            return [
                                <DeleteOutlined
                                    style={{ color: 'red' }}
                                    onClick={() => {
                                        const title =
                                            'Are you sure to delete this deposit?'

                                        Modal.confirm({
                                            centered: true,
                                            title,
                                            onOk: () =>
                                                handleDeleteDeposit(row, idx),
                                            autoFocusButton: 'cancel',
                                            okButtonProps: {
                                                loading,
                                            },
                                            cancelButtonProps: {
                                                loading,
                                            },
                                        })
                                    }}
                                />,
                            ]
                        },
                    },
                ]}
                search={false}
                toolBarRender={false}
                pagination={false}
            />
        </ModalForm>
    )
}

export default SavingsDeposits
