/* eslint-disable no-console */
import {
    ArrowLeftOutlined,
    DeleteOutlined,
    PlusOutlined,
} from '@ant-design/icons'
import {
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormText,
} from '@ant-design/pro-components'
import {
    Button,
    Card,
    Col,
    Divider,
    Input,
    message,
    Row,
    Space,
    Typography,
} from 'antd'
import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    PERSONNEL_REPOSITORY,
    TEAM_REPOSITORY,
    VEHICLE_REPOSITORY,
    transportOptions,
} from '../../../data/repositories/constants'
import { Personnel, Vehicle } from '../../../domain/entities/Transport'
import { db } from '../../boot'
import { useAppDispatch, useAppSelector } from '../../store'
import { updateTeamState } from '../../../app/redux/team/team.slice'
import { updateSystemConfig } from '../../../app/redux/system.slice'
import { cleanString } from '../../../app/components/script'

// interface VehicleContainerProps {
//     data?: Vehicle
// }

const VehicleContainer = ({ isUpdate }: { isUpdate?: boolean }) => {
    const [form] = ProForm.useForm()
    const [loading, setLoading] = useState(false)
    const { styles, activeTeam, email, data, selectedVehicleId, routeNames } =
        useAppSelector(
            (state) => ({
                routeNames: state.team.selectedTeamData?.routeNames || [],
                styles: state.styles,
                email: state.auth.user.email,
                activeTeam: state.auth.user.activeTeam,
                data: state.vehicle.selectedVehicleId
                    ? state.vehicle.vehicles.entities[
                          state.vehicle.selectedVehicleId
                      ]
                    : undefined,
                selectedVehicleId: state.vehicle.selectedVehicleId,
            }),
            shallowEqual
        )
    const navigate = useNavigate()
    const location = useLocation()
    const handleFinish = async (data: Vehicle) => {
        setLoading(true)
        const payload: Vehicle = {
            ...data,
            serviceStartDate: moment(data.serviceStartDate).unix(),
            dateOfLoan: moment(data.dateOfLoan).unix(),
            partnerCode: activeTeam,
            creation: moment().unix(),
            modified: moment().unix(),
            modifiedBy: email,
            isRemoved: false,
            running: false,
            title: data.title,
            plate: data.plate,
            routeName: data.routeName,
            status: 'available',
            coords: {
                lat: 0,
                lng: 0,
            },
            type: data.type,
            totalRevenue: 0,
            totalExpenses: 0,
            totalCashCollected: 0,
            totalGasCost: 0,
            totalGasConsumed: 0,
        }
        await addDoc(collection(db, VEHICLE_REPOSITORY), payload)
        // await dispatch(addExpense(payload))
        form.resetFields()
        message.success('Vehicle successfully added')
        setLoading(false)
        navigate(-1)
    }

    const handleUpdate = async (datum: any) => {
        // console.log(datum)

        setLoading(true)
        if (!isUpdate) return
        if (!data) return
        console.log(data)

        try {
            const ref = doc(db, VEHICLE_REPOSITORY, data.id as string)

            await updateDoc(ref, {
                ...datum,
                modified: moment().unix(),
                modifiedBy: email,
                serviceStartDate: moment(datum.serviceStartDate).unix(),
                dateOfLoan: moment(datum.dateOfLoan).unix(),
            })
        } catch (err) {
            if (err instanceof Error) {
                message.error(err.message)
            } else {
                message.error('An unknown error occurred.')
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (location.state && location.state.new) {
            form.resetFields()
            return
        }
        if (!data) return
        if (!isUpdate) return

        form.resetFields()
        form.setFieldsValue({
            ...data,
            title: data.title,
            plate: data.plate,
            routeName: data.routeName,
            serviceStartDate: moment.unix(data.serviceStartDate),
            dateOfLoan: data.dateOfLoan
                ? moment.unix(data.dateOfLoan)
                : undefined,
        })
        setSelectedCategory(data.routeName)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedVehicleId, isUpdate])

    const dispatch = useAppDispatch()
    useEffect(() => {
        const main = async () => {
            const ref = collection(db, PERSONNEL_REPOSITORY)
            const q = await getDocs(
                query(
                    ref,
                    where('partnerCode', '==', activeTeam),
                    where('isRemoved', '==', false),
                    where('role', '==', 'driver')
                )
            )
            const data = q.docs.map((docSnap) => {
                const driverData = docSnap.data()
                return {
                    ...driverData,
                    id: docSnap.id,
                } as Personnel
            })
            dispatch(
                updateTeamState({
                    drivers: data,
                })
            )
        }
        main()
        const assistantData = async () => {
            const ref = collection(db, PERSONNEL_REPOSITORY)
            const q = await getDocs(
                query(
                    ref,
                    where('partnerCode', '==', activeTeam),
                    where('isRemoved', '==', false),
                    where('role', '==', 'assistant')
                )
            )
            const data = q.docs.map((docSnap) => {
                const driverData = docSnap.data()
                return {
                    ...driverData,
                    id: docSnap.id,
                } as Personnel
            })
            dispatch(
                updateTeamState({
                    assistants: data,
                })
            )
        }
        assistantData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam])
    const [expensesLabelKey, setExpensesLabelKey] = useState<any>([])
    const [selectedCategory, setSelectedCategory] = useState('')
    const [name, setName] = useState('')

    const handleAddExpenseCategory = (data: any) => {
        setName(data.target.value)
    }

    const [routeNamesCategories, setRouteNamesCategories] = useState(routeNames)
    const handleAddItem = async (e: {
        preventDefault: () => void
        stopPropagation: () => void
    }) => {
        e.preventDefault()
        e.stopPropagation()
        if (!name) return
        const cleanName = cleanString(name)
        let included = false
        routeNamesCategories.forEach((route) => {
            if (route.toLowerCase() === cleanName.toLowerCase()) {
                included = true
            }
        })
        if (included) {
            message.error(`${cleanName} already exists in expense categories.`)
            return
        }

        await updateDoc(doc(db, TEAM_REPOSITORY, activeTeam), {
            routeNames: arrayUnion(cleanName),
        })
        const expenseCategoriesCopy = [...routeNames, cleanName]
        dispatch(
            updateSystemConfig({
                routeNames: expenseCategoriesCopy,
            })
        )

        setRouteNamesCategories(expenseCategoriesCopy)
        setName('')
    }

    const handleDeleteCategory = async (e: any, key: string) => {
        e.preventDefault()
        e.stopPropagation()
        const newExpenseCategories = [...routeNamesCategories]
        const keyIdx = newExpenseCategories.indexOf(key)
        if (keyIdx >= 0) {
            newExpenseCategories.splice(keyIdx, 1)

            await updateDoc(doc(db, TEAM_REPOSITORY, activeTeam), {
                routeNames: arrayRemove(key),
            })
            dispatch(
                updateSystemConfig({
                    routeNames: newExpenseCategories,
                })
            )
            message.success('Successfully delete category.')
            setRouteNamesCategories(newExpenseCategories)
        }
    }

    useEffect(() => {
        const mapped = routeNamesCategories.map((expenseCategory: string) => ({
            label: expenseCategory,
            key: expenseCategory,
        }))
        setExpensesLabelKey(mapped)
    }, [routeNamesCategories])
    useEffect(() => {
        setRouteNamesCategories(routeNames)
    }, [routeNames])
    return (
        <div style={{ paddingTop: 24, width: 400 }}>
            <Card
                title={
                    !isUpdate && (
                        <Row>
                            <Col span={8}>
                                <Button
                                    type="text"
                                    onClick={() => navigate(-1)}
                                >
                                    <ArrowLeftOutlined
                                        style={{ fontSize: 18 }}
                                    />
                                </Button>
                            </Col>
                            <Col span={8}>Add Bus</Col>
                            <Col span={8}></Col>
                        </Row>
                    )
                }
            >
                <ProForm<Vehicle>
                    form={form}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            form.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    onFinish={isUpdate ? handleUpdate : handleFinish}
                    // initialValues={{
                    //     ...memberStateInitialValues,
                    //     startDate: moment(),
                    // }}
                    // onValuesChange={onValuesChange}
                    autoFocusFirstInput
                    submitter={false}
                >
                    <ProFormSelect
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        name="type"
                        label={'Type'}
                        options={transportOptions}
                    />
                    <ProFormText
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        label="Title"
                        name="title"
                        width={'md'}
                    />
                    <ProFormText
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        label="Plate No."
                        name="plate"
                        width={'md'}
                    />
                    <ProFormSelect
                        style={{
                            width: 200,
                        }}
                        placeholder={'Select route'}
                        label={'Route'}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        name={'routeName'}
                        options={expensesLabelKey}
                        fieldProps={{
                            value: selectedCategory,
                            dropdownRender: (menu: any) => (
                                <>
                                    {menu}
                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />
                                    <Space
                                        align="center"
                                        style={{
                                            padding: '0 8px 4px',
                                        }}
                                    >
                                        <Input
                                            placeholder="Please enter item"
                                            value={name}
                                            onChange={handleAddExpenseCategory}
                                        />
                                        <Typography.Link
                                            onClick={handleAddItem}
                                            style={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <PlusOutlined /> Add route
                                        </Typography.Link>
                                    </Space>
                                </>
                            ),
                            optionItemRender: (item: any) => {
                                return (
                                    <>
                                        <Row
                                            wrap={false}
                                            justify={'space-between'}
                                        >
                                            <Col
                                                span={22}
                                                onClick={() =>
                                                    setSelectedCategory(
                                                        item.key
                                                    )
                                                }
                                            >
                                                {item.label}
                                            </Col>
                                            <Col span={2}>
                                                <DeleteOutlined
                                                    onClick={(e) =>
                                                        handleDeleteCategory(
                                                            e,
                                                            item.key
                                                        )
                                                    }
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )
                            },
                        }}
                    />
                    {/* <ProFormText
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        label="Route"
                        name="routeName"
                        width={'md'}
                    /> */}

                    <ProFormDigit
                        label={'Daily Quota'}
                        name="dailyTripRemittance"
                    />
                    <ProFormDatePicker
                        name={'serviceStartDate'}
                        label="Date of Purchase"
                    />

                    {/* <ProFormText
                        label={'Phone Number'}
                        name="phoneNumber"
                        width={'md'}
                    /> */}

                    <ProFormDigit label={'Principal Cost'} name="totalCost" />

                    <ProFormDigit
                        label={'Estimated Useful Life'}
                        name="estimatedUsefulLife"
                    />

                    <ProFormDatePicker
                        name={'dateOfLoan'}
                        label="Date of Loan"
                    />
                    <ProFormDigit label={'Loan Amount'} name="loanAmount" />
                    <ProFormDigit
                        label={'Interest Rate'}
                        name="loanInterestRate"
                    />
                    <ProFormDigit
                        label={'Monthly Installment'}
                        name="monthlyLoanInstallment"
                    />
                    <ProFormDigit
                        label={'Monthly Insurance Expense'}
                        name="monthlyInsuranceExpense"
                    />

                    {/* <ProFormDatePicker
                        label="Monthly Installment"
                        name={'monthlyLoanInstallment'}
                    /> */}

                    {/* <ProFormCheckbox.Group
                        name="sendEmail"
                        layout="horizontal"
                        options={['Send email invitation']}
                    /> */}
                    <Row gutter={[24, 24]}>
                        <Col span={12}>
                            <Button
                                size="large"
                                loading={loading}
                                // disabled={password.length > 1 && email.length > 1 ? false : true}
                                style={{
                                    fontWeight: 'bold',
                                    width: '100%',
                                }}
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                htmlType="submit"
                                size="large"
                                loading={loading}
                                // disabled={password.length > 1 && email.length > 1 ? false : true}
                                style={{
                                    fontWeight: 'bold',
                                    width: '100%',
                                    color: 'white',
                                    backgroundColor: styles.primary,
                                }}
                            >
                                {data ? 'Update' : 'Submit'}
                            </Button>
                        </Col>
                    </Row>
                </ProForm>
            </Card>
        </div>
    )
}

export default VehicleContainer
