import styled from 'styled-components'

export const Container = styled('div')`
    display: inline-block;
    width: 100%;
    padding: 10px 5px;
`

export const StyledInput = styled('input')`
    font-size: 0.875rem;
`
