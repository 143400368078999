import { createSlice } from '@reduxjs/toolkit'

interface InitState {
    primary: string
    secondary: string
    backgroundPrimary: string
    backgroundSecondary: string
    boxShadow: string
}
const initialState: InitState = {
    primary: 'rgb(19,121,170)',
    secondary: '',
    backgroundPrimary: '',
    backgroundSecondary: '',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
}

export const styleSlice = createSlice({
    name: 'style',
    initialState,
    reducers: {},
})

// export const {} = styleSlice.actions
