import { randomId } from '../../utils'
import { Frequency } from '../../../../domain/entities/SystemSettings'
import { LoanPayment, LoanTerms } from '../../../../domain/entities/Loan'
import moment from 'moment'
import { PaymentSchedule } from './ApplyLoan'

export const isDue = (date: number | string, paymentDate?: number): boolean => {
    const today = paymentDate ? moment.unix(paymentDate) : moment()
    const dueDate = typeof date === 'number' ? moment.unix(date) : moment(date)

    const daysElapse = today.diff(dueDate, 'days')
    if (daysElapse === 0) {
        return false
    }
    return today > dueDate
}
export function isSameDay(date1: number, date2: number) {
    // Convert Unix timestamps to milliseconds
    const milliseconds1 = date1 * 1000
    const milliseconds2 = date2 * 1000

    // Create Date objects
    const dateObj1 = new Date(milliseconds1)
    const dateObj2 = new Date(milliseconds2)

    // Extract the year, month, and day from the Date objects
    const year1 = dateObj1.getFullYear()
    const month1 = dateObj1.getMonth()
    const day1 = dateObj1.getDate()

    const year2 = dateObj2.getFullYear()
    const month2 = dateObj2.getMonth()
    const day2 = dateObj2.getDate()

    // Compare the year, month, and day values
    if (year1 === year2 && month1 === month2 && day1 === day2) {
        return true // The dates represent the same day
    } else {
        return false // The dates are different days
    }
}
export const calculateDaysElapsed = (
    date: number | string,
    paymentDate?: number
): number => {
    let today = paymentDate ? moment.unix(paymentDate) : moment()
    today = moment(today.format('MMMM DD YYYY'))

    let dueDate = typeof date === 'number' ? moment.unix(date) : moment(date)
    dueDate = moment(dueDate.format('MMMM DD YYYY'))
    return today.diff(dueDate, 'days')
}
function calculateInterestV2(
    currentDate: number,
    dueDate: number,
    remainingBalance: number,
    interestRate: number
) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000 // Number of milliseconds in a day
    // const daysLate = calculateDaysElapsed(dueDate, currentDate) // Calculate days late

    const daysLate = Math.ceil((currentDate - dueDate) / millisecondsPerDay) // Calculate days late
    const daysInMonth = moment.unix(currentDate).daysInMonth() // Number of days in the current month

    let interestAmount = 0
    if (daysLate === 0) {
        interestAmount = remainingBalance * interestRate // Full monthly interest based on the remaining balance
    } else if (daysLate < daysInMonth) {
        const dailyInterestRate = interestRate / daysInMonth // Daily interest rate
        interestAmount = remainingBalance * dailyInterestRate * daysLate // Adjusted interest based on days late
    }

    return interestAmount
}
export const calculateInterest = (
    remainingBalance: number,
    compound: string,
    payback: Frequency,
    interest: number,
    amount: number,
    due: number,
    paymentDate?: number
): number => {
    const daysElapsed = calculateDaysElapsed(due, paymentDate)
    // if (daysElapsed <= 0) {
    //     return 0
    // }
    const months = daysElapsed
    let interestPay = 0
    const interestRate = interest / 100
    if (compound === 'fix') {
        interestPay = interestRate * amount * months
    }
    if (compound === 'continuous' && payback === 'monthly') {
        // if (paymentDate && !isDue(due, paymentDate)) {

        //     const interest = calculateInterestV2(paymentDate, due, remainingBalance, interestRate)
        //     console.log(interest);

        // }
        let balance = remainingBalance
        let days = daysElapsed

        if (isSameDay(paymentDate || moment().unix(), due)) {
            interestPay = remainingBalance * interestRate
        }

        if (days < 0 && paymentDate) {
            const interest = calculateInterestV2(
                paymentDate,
                due,
                remainingBalance,
                interestRate
            )
            const daysInMonth = moment.unix(paymentDate).daysInMonth() // Number of days in the current month
            const daysElapsed = daysInMonth - days * -1
            interestPay = (interest / daysInMonth) * daysElapsed
            // const interest = interestRate * remainingBalance
        }
        while (days > 30) {
            const interest = balance * interestRate
            balance += interest
            interestPay += interest
            days -= 30
        }
        // interestPay = interest
    }
    // if (compound === 'continuous' && payback === 'weekly') {
    //     let balance = remainingBalance
    //     let days = daysElapsed

    //     // while (days > 0) {
    //     if (days > 0) {
    //         const numberOfWeeks = Math.round(days / 7) + 1
    //         const numberOfDaysInMonth = moment.unix(due).daysInMonth()
    //         const interest = balance * interestRate
    //         let tempInterest = interest / numberOfDaysInMonth
    //         const weeklyInterest = tempInterest * 7
    //         interestPay = weeklyInterest * numberOfWeeks
    //         // eslint-disable-next-line no-console
    //         console.log('numberOfWeeks', numberOfWeeks)
    //         // eslint-disable-next-line no-console
    //         console.log('tempInterest', tempInterest)
    //         // eslint-disable-next-line no-console
    //         console.log('days', days)
    //         // eslint-disable-next-line no-console
    //         console.log('interestPay', interestPay)
    //         // eslint-disable-next-line no-console
    //         console.log('weeklyInterest', weeklyInterest)
    //     }
    //     // balance += tempInterest
    //     // interestPay += tempInterest
    //     // days -= 7
    //     // }
    //     // interestPay = interest
    // }
    return interestPay || 0
}

export const calculateTotalPayments = (loanTerms: {
    termYears: number
    termMonths: number
    payback: Frequency
    compound: string
}) => {
    const { termMonths, payback } = loanTerms
    let numberOfRepayments = 0
    switch (payback) {
        case 'monthly':
            numberOfRepayments = termMonths
            break
        // case 'quarterly':
        //     numberOfRepayments = Math.ceil(termMonths / 3);
        //     break;
        // case 'semiannually':
        //     numberOfRepayments = Math.ceil(termMonths / 6);
        //     break;
        case 'yearly':
            numberOfRepayments = Math.ceil(termMonths / 12)
            break
        default:
            // Handle unsupported payment frequency
            // eslint-disable-next-line no-console
            console.error('Unsupported payment frequency:', payback)
    }

    return numberOfRepayments
}

export const calculateInterestPayment = (
    amount: number,
    interest: number,
    compound: string,
    loanTerms: number,
    payments?: LoanPayment[]
) => {
    let interestPayment = 0
    const interestRate = interest / 100
    const dailyInterestRate = interestRate / loanTerms
    if (compound === 'fix') {
        interestPayment = dailyInterestRate * amount
    }
    if (compound === 'continuous' && payments) {
        if (payments && payments.length) {
            const lastPay = payments[payments.length - 1]
            if (lastPay && lastPay.endingBalance) {
                interestPayment = lastPay.endingBalance * interestRate
            }
        } else {
            interestPayment = interestRate * amount
        }
    }

    return interestPayment
}

export const calculateMonthlyPayment = (
    amount: number,
    interest: number,
    loanTerms: LoanTerms
): number => {
    const interestRate = interest / 100
    const monthsToPay = calculateTotalPayments(loanTerms)
    let monthlyPayment = 0
    if (loanTerms.compound === 'continuous') {
        const i = interestRate
        const n = monthsToPay
        const u = i * (1 + i) ** n
        const d = (1 + i) ** n - 1
        monthlyPayment = amount * (u / d)
    }

    if (loanTerms.compound === 'fix') {
        const overallInterest = amount * interestRate * monthsToPay
        monthlyPayment = (overallInterest + amount) / monthsToPay
    }
    return monthlyPayment
}

export const calculateLoanInterest = (
    amount: number,
    interest: number,
    loanTerms: LoanTerms
): number => {
    const monthsToPay = calculateTotalPayments(loanTerms)

    const interestRate = interest / 100

    let loanAmount = amount
    let totalInterestPayment = 0
    const monthlyPayment = calculateMonthlyPayment(amount, interest, loanTerms)

    for (let m = 1; m <= monthsToPay; m++) {
        const beginningLoanBalance = loanAmount
        const interestPayment =
            loanTerms.compound === 'fix'
                ? calculateInterestPayment(
                      amount,
                      interest,
                      loanTerms.compound,
                      loanTerms.termMonths
                  )
                : interestRate * loanAmount
        let principalPayment = monthlyPayment - interestPayment

        loanAmount = loanAmount - principalPayment
        if (loanAmount < 0) {
            loanAmount = 0
            principalPayment = beginningLoanBalance
        }
        totalInterestPayment += interestPayment
    }
    return totalInterestPayment
}

export const generatePaymentMockSchedule = (
    amount: number,
    interest: number,
    numberOfPayments: number,
    monthlyPayment: number,
    loanTerms: LoanTerms,
    creation: number
): PaymentSchedule[] => {
    let startDate = moment.unix(creation)
    let loanAmount = amount
    const interestRate = interest / 100
    const arrayOfArrays: any = []
    const { payback, termYears, termMonths } = loanTerms
    if (payback !== 'yearly') {
        for (let m = 1; m <= termMonths; m++) {
            startDate = startDate.add(1, 'M')
            const beginningLoanBalance = loanAmount
            let interestPayment =
                loanTerms.compound === 'fix'
                    ? calculateInterestPayment(
                          amount,
                          interest,
                          loanTerms.compound || 'fix',
                          loanTerms.termMonths
                      )
                    : interestRate * loanAmount
            let principalPayment = monthlyPayment - interestPayment

            loanAmount = loanAmount - principalPayment
            if (parseFloat(loanAmount.toFixed(2)) <= 0) {
                loanAmount = 0
                principalPayment = beginningLoanBalance
            }
            const monthlyData: PaymentSchedule = {
                beginningBalance: beginningLoanBalance,
                loanDue: monthlyPayment,
                interest: interestPayment,
                principal: principalPayment,
                endingBalance: loanAmount,
                paymentNumber: m,
                due: startDate.unix(),
                id: randomId(5),
            }
            arrayOfArrays.push(monthlyData)
        }
    } else {
        for (let y = 1; y <= termYears; y++) {
            const yearData: PaymentSchedule[] = []
            for (let m = 1; m <= 12; m++) {
                const beginningLoanBalance = loanAmount
                let interestPayment =
                    loanTerms.compound === 'fix'
                        ? calculateInterestPayment(
                              amount,
                              interest,
                              loanTerms.compound || 'fix',
                              loanTerms.termMonths
                          )
                        : interestRate * loanAmount
                let principalPayment = monthlyPayment - interestPayment

                loanAmount = loanAmount - principalPayment
                if (loanAmount < 0) {
                    loanAmount = 0
                    principalPayment = beginningLoanBalance
                }
                const monthlyData: PaymentSchedule = {
                    beginningBalance: beginningLoanBalance,
                    loanDue: monthlyPayment,
                    interest: interestPayment,
                    principal: principalPayment,
                    endingBalance: loanAmount,
                    year: y,
                    paymentNumber: m,
                    due: startDate.unix(),
                    id: randomId(5),
                }
                yearData.push(monthlyData)
            }
            // }
            arrayOfArrays.push(yearData)
        }
        const yearDataExtra = []
        if (termMonths > 0) {
            for (let m = 1; m <= termMonths; m++) {
                const beginningLoanBalance = loanAmount
                let interestPayment =
                    loanTerms.compound === 'fix'
                        ? calculateInterestPayment(
                              amount,
                              interest,
                              loanTerms.compound || 'fix',
                              loanTerms.termMonths
                          )
                        : interestRate * loanAmount
                let principalPayment = monthlyPayment - interestPayment

                loanAmount = loanAmount - principalPayment

                if (loanAmount < 0) {
                    loanAmount = 0
                    principalPayment = beginningLoanBalance
                }

                const monthlyData: PaymentSchedule = {
                    beginningBalance: beginningLoanBalance,
                    interest: interestPayment,
                    principal: principalPayment,
                    endingBalance: loanAmount,
                    year: termYears + 1,
                    due: startDate.add(1, 'M').unix(),
                    paymentNumber: m,
                    id: randomId(12),
                    loanDue: monthlyPayment,
                }
                yearDataExtra.push(monthlyData)
            }
            arrayOfArrays.push(yearDataExtra)
        }
    }
    return arrayOfArrays
}
