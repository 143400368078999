import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Col,
    // Divider,
    Form,
    Grid,
    Input,
    message,
    Row,
    Typography,
} from 'antd'
// import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store'
import { clearErr, login, setAuthState } from '../../redux/auth/auth.slice'
import { shallowEqual } from 'react-redux'
// @ts-ignore
// import logo from '../../../assets/mainicon1280x654.png'
import { ProCard } from '@ant-design/pro-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { Team } from '../../../domain/entities/Team'
import { getTeam } from '../../redux/team/team.action'

function Login() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { isLoading, errMessage, styles } = useAppSelector(
        (state: any) => ({
            errMessage: state.auth.errMessage,
            styles: state.styles,
            isLoading: state.auth.loading,
        }),
        shallowEqual
    )
    const [inviter, setInviter] = useState<Team | undefined>(undefined)
    const { xs } = Grid.useBreakpoint()

    const [values, setValues] = useState<any>({
        email: '',
        password: '',
    })
    const [form] = Form.useForm()
    const handleError = () => {
        setValues({
            email: 'error',
            password: 'error',
        })
        dispatch(clearErr())
    }
    const handleFinish = async (data: any) => {
        await dispatch(login(data))
        // navigate('members')
    }

    const handleValuesChange = () => {
        if (values.email && values.password) {
            setValues({
                email: '',
                password: '',
            })
        }
    }
    useEffect(() => {
        if (errMessage) {
            message.error(errMessage)
            handleError()
        } else {
            handleValuesChange()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errMessage])
    const params = useLocation()
    useEffect(() => {
        dispatch(
            setAuthState({
                loading: false,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const passRef = useRef(null)
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(params)
        if (!passRef.current) return
        if (!params.state) return
        if (!params.state.email) return
        form.setFieldsValue({
            email: params.state.email,
        })
        // @ts-ignore
        passRef.current.focus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, passRef])

    useEffect(() => {
        const getTeamData = async () => {
            const queryParamsString = location.search.substring(1), // remove the "?" at the start
                searchParams = new URLSearchParams(queryParamsString),
                partnerCode = searchParams.get('partnerCode')
            // invitedBy = searchParams.get('invitedBy')

            if (!partnerCode) return
            const teamData = await getTeam(partnerCode)
            setInviter(teamData)
        }
        getTeamData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: xs ? undefined : 20,
            }}
        >
            <ProCard
                bordered
                style={{
                    width: !xs ? 450 : '100%',
                    borderRadius: 5,
                    boxShadow: '0 20px 40px rgb(57 76 96 / 15%)',
                }}
            >
                <Row justify={'center'} align={'middle'}>
                    {/* <Col span={xs ? 10 : undefined}>
                        <img
                            src={logo}
                            style={{
                                height: xs ? 48 : 64,
                            }}
                            alt="perati-logo"
                        />
                    </Col> */}
                    {inviter ? (
                        <Col span={xs ? 14 : 12}>
                            <Typography.Title
                                style={{
                                    textAlign: 'center',
                                    marginBottom: 0,
                                }}
                                level={xs ? 4 : 3}
                            >
                                <Typography.Text style={{ fontSize: 17 }}>
                                    You are invited to:{' '}
                                </Typography.Text>
                                {inviter.name}
                            </Typography.Title>
                            {/* <Typography.Paragraph
                                style={{
                                    textAlign: 'center',
                                    marginTop: 6,
                                    marginBottom: 6,
                                    fontSize: 18,
                                }}
                            >
                                Join now
                            </Typography.Paragraph> */}
                        </Col>
                    ) : null}
                </Row>
                <br />
                <Form
                    form={form}
                    onFinish={handleFinish}
                    style={{ width: '100%', color: 'white' }}
                    onValuesChange={handleValuesChange}
                    layout={'vertical'}
                >
                    <Form.Item
                        name="email"
                        label={'Email'}
                        style={{ color: 'white' }}
                        rules={[
                            {
                                required: true,
                                message: 'Invalid email',
                                type: 'email',
                            },
                        ]}
                        validateStatus={values.email}
                    >
                        <Input
                            size="large"
                            name="username"
                            placeholder={'user@example.com'}
                            // onChange={(event) => setEmail(event.currentTarget.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={'Password'}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Invalid password',
                            },
                        ]}
                        validateStatus={values.password}
                    >
                        <Input.Password
                            ref={passRef}
                            className="changeB"
                            size="large"
                            // onChange={(event) => setPassword(event.currentTarget.value)}
                            // iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            size="large"
                            block
                            loading={isLoading}
                            shape={'round'}
                            style={{
                                fontWeight: 'bold',
                                color: 'white',
                                backgroundColor: styles.primary,
                            }}
                        >
                            LOGIN
                        </Button>
                    </Form.Item>
                    {/*{errMessage !== null && showAuthError ? <Alert message={errMessage}></Alert> : null}*/}
                </Form>
                {/* <Divider>
                    <Typography.Text type="secondary">
                        You don't have an account?{' '}
                    </Typography.Text>
                </Divider> */}

                {/* <Button
                    size="large"
                    block
                    loading={isLoading}
                    onClick={() => navigate(`/register${location.search}`)}
                    shape={'round'}
                    style={{
                        fontWeight: 'bold',
                        color: styles.secondary,
                        backgroundColor: styles.backgroundSecondary,
                    }}
                >
                    REGISTER
                </Button> */}
                <Row justify="center">
                    <Col>
                        <Typography.Link onClick={() => navigate('/forgot')}>
                            Forgot your password?
                        </Typography.Link>
                    </Col>
                </Row>
                {/* <Button
                    disabled={isLoading}
                    shape={'round'}
                    block
                    size="large"
                    style={{
                        backgroundColor: 'green',
                        border: 'none',
                        color: '#fff',
                    }}
                >
                    Register now!
                </Button> */}
                {/* <Typography.Link
                            onClick={() => navigate('/register/admin')}
                        >
                            <Button
                                type="text"
                                loading={isLoading}
                                shape={'round'}
                                style={{ color: '#06c' }}
                            >
                                FOR ADMINISTRATOR
                            </Button>
                        </Typography.Link> */}
            </ProCard>
        </div>
    )
}

export default Login
