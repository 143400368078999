import {
    CheckCircleFilled,
    // CheckCircleFilled,
    ClockCircleOutlined,
    // DeleteFilled,
    // EditFilled,
} from '@ant-design/icons'
import {
    Card,
    Row,
    Col,
    Typography,
    Empty,
    Tag,
    Avatar,
    Popconfirm,
    Button,
    Tooltip,
    // Divider,
    // Tooltip,
    // Popconfirm,
    // message,
    // Button,
} from 'antd'
import {
    // doc,
    // deleteDoc,
    collection,
    query,
    where,
    getDocs,
    doc,
    getDoc,
    // updateDoc,
} from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
    PERSONNEL_ATTENDANCE_REPOSITORY,
    PERSONNEL_REPOSITORY,
    TEAM_GROUP_REPOSITORY,
} from '../../../data/repositories/constants'
import { Attendance } from '../../../domain/entities/Attendance'
import { db } from '../../boot'
import { useAppSelector } from '../../store'
import { shallowEqual } from 'react-redux'
import { TeamGroup } from '../../../domain/entities/Group'
import { calculateWorkTime } from '../My/MyTeam/hrUtils'
import { Personnel } from '../../../domain/entities/Transport'

function TeamLeaderDashboard() {
    // const [attendance, setAttendance] = useState<any>([])
    const [teamGroupData, setTeamGroupData] = useState<{
        pendingAttendance: any[]
        doneAttendance: any[]
    }>({
        pendingAttendance: [],
        doneAttendance: [],
    })
    const { styles, activeTeam, userId } = useAppSelector(
        (state) => ({
            styles: state.styles,
            userId: state.auth.user.id,
            role: state.auth.user.role,
            email: state.auth.user.email,
            hr: state.team.selectedTeamData.hr,
            activeTeam: state.auth.user.activeTeam,
        }),
        shallowEqual
    )

    // const handleShowUpdateAttendance = (data: any) => {}

    // const handleUpdateAttendance = async (
    //     att: any,
    //     status: 'done' | 'absent'
    // ) => {
    //     // if (!personnel) return
    //     // let role: string = 'employee'
    //     // if (personnel.role === 'assistant' || personnel.role === 'driver') {
    //     //     role = personnel.role
    //     // }
    //     await updateDoc(
    //         doc(db, PERSONNEL_ATTENDANCE_REPOSITORY, att.id as string),
    //         {
    //             // [`${role}Status`]: status,
    //             status,
    //         }
    //     )
    //     message.success('Successfully updated.')
    //     // message.success(role)
    // }
    useEffect(() => {
        if (!activeTeam) return
        const main = async () => {
            const ref = collection(db, TEAM_GROUP_REPOSITORY)
            let q = query(
                ref,
                where('partnerCode', '==', activeTeam),
                where('isRemoved', '==', false),
                where('supervisorId', '==', userId)
            )

            const snap = await getDocs(q)
            const data: any = snap.docs.map(async (docSnap) => {
                const teamGroupData = docSnap.data() as TeamGroup
                const pendingArrr: any = []
                const doneArrr: any = []
                const rsp = teamGroupData.members.map(async (memberId) => {
                    const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)
                    const employeeDoc = doc(db, PERSONNEL_REPOSITORY, memberId)
                    const employeeDocSnap = await getDoc(employeeDoc)
                    const employeeData = employeeDocSnap.data()
                    const doneQ = query(
                        ref,
                        where('employeeId', '==', memberId),
                        where('paymentStatus', '==', 'done')
                    )
                    const doneAttendance = await getDocs(doneQ)

                    const donePromise = doneAttendance.docs.map((docSnap) => {
                        const attendanceData = docSnap.data() as Attendance
                        // eslint-disable-next-line no-console
                        // console.log(docSnap.id)

                        const workTime = calculateWorkTime(
                            moment.unix(attendanceData.timeIn).format('HH:mm'),
                            moment.unix(attendanceData.timeOut).format('HH:mm'),
                            {
                                firstShiftIn: teamGroupData.scheduleIn,
                                firstShiftOut: teamGroupData.scheduleOut,
                            }
                        )
                        const hours = workTime.hours || 0

                        const minutesLate = workTime.minutesLate || 0
                        let payload: any = {
                            ...attendanceData,
                            status: attendanceData.paymentStatus,
                            isAbsent:
                                attendanceData.employeeStatus === 'absent',
                            dateNumber: moment(
                                docSnap.data().date,
                                'MMM DD, YYYY'
                            ).unix(),
                            id: docSnap.id,
                            incentives: attendanceData.incentives,
                            salary: attendanceData.salary,
                            overtimePay: attendanceData.overtimePay,
                            overtimeHours: attendanceData.overtimeHours,
                            hoursRendered: hours,
                            minutesLate: minutesLate,
                            employeeData: undefined,
                        }

                        if (employeeData) {
                            payload.employeeData = employeeData
                        }
                        return payload
                    })
                    donePromise.forEach((attd) => doneArrr.push(attd))

                    const pendingQ = query(
                        ref,
                        where('employeeId', '==', memberId),
                        where('paymentStatus', '==', 'pending')
                    )
                    const pendingAttendance = await getDocs(pendingQ)
                    const attdPromise = pendingAttendance.docs.map(
                        (docSnap) => {
                            const attendanceData = docSnap.data() as Attendance
                            // eslint-disable-next-line no-console
                            // console.log(docSnap.id)

                            const workTime = calculateWorkTime(
                                moment
                                    .unix(attendanceData.timeIn)
                                    .format('HH:mm'),
                                moment
                                    .unix(attendanceData.timeOut)
                                    .format('HH:mm'),
                                {
                                    firstShiftIn: teamGroupData.scheduleIn,
                                    firstShiftOut: teamGroupData.scheduleOut,
                                }
                            )
                            const hours = workTime.hours || 0

                            const minutesLate = workTime.minutesLate || 0
                            let payload: any = {
                                ...attendanceData,
                                status: attendanceData.paymentStatus,
                                isAbsent:
                                    attendanceData.employeeStatus === 'absent',
                                dateNumber: moment(
                                    docSnap.data().date,
                                    'MMM DD, YYYY'
                                ).unix(),
                                id: docSnap.id,
                                incentives: attendanceData.incentives,
                                salary: attendanceData.salary,
                                overtimePay: attendanceData.overtimePay,
                                overtimeHours: attendanceData.overtimeHours,
                                hoursRendered: hours,
                                minutesLate: minutesLate,
                                employeeData: undefined,
                            }

                            if (employeeData) {
                                payload.employeeData = employeeData
                            }
                            return payload
                        }
                    )
                    attdPromise.forEach((attd) => pendingArrr.push(attd))
                })

                await Promise.all(rsp)
                let payload = {
                    ...teamGroupData,
                    id: docSnap.id,
                    pendingAttendance: pendingArrr,
                    doneAttendance: doneArrr,
                }
                setTeamGroupData(payload)
                // setAttendance([...pendingArrr, ...doneArrr])
                return payload
            })
            const final = await Promise.all(data)
            // eslint-disable-next-line no-console
            console.log(final)
        }
        main()
    }, [activeTeam, userId])
    return (
        <div>
            <div className="page-layout">
                <Row gutter={[24, 24]}>
                    <Col xs={24} lg={12}>
                        <Card
                            title={
                                <div style={{ color: 'white' }}>
                                    <Row justify={'space-between'}>
                                        <Col>
                                            <Row
                                                align={'middle'}
                                                gutter={[12, 12]}
                                            >
                                                <Col>
                                                    <div
                                                        style={{
                                                            height: 25,
                                                            width: 10,
                                                            borderRadius: 10,
                                                            backgroundColor:
                                                                styles.primary,
                                                        }}
                                                    ></div>
                                                </Col>
                                                <Col>
                                                    <Typography.Title
                                                        level={4}
                                                        style={{
                                                            marginBottom: 0,
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Time In
                                                    </Typography.Title>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            }
                            headStyle={{ borderBottom: 'none' }}
                            style={{
                                backgroundColor: '#272829',
                                border: 'none',
                                borderRadius: 12,
                            }}
                            bodyStyle={{
                                maxHeight: 600,
                                overflowY: 'scroll',
                            }}
                        >
                            {!teamGroupData.pendingAttendance.length && (
                                <Empty style={{ color: 'white' }} />
                            )}
                            <Row gutter={[24, 24]}>
                                {teamGroupData.pendingAttendance.map(
                                    (
                                        att: Attendance & {
                                            employeeData: Personnel
                                        }
                                    ) => {
                                        return (
                                            <Col key={att.id} span={24}>
                                                <Card
                                                    bordered={false}
                                                    headStyle={{
                                                        borderBottom: 'none',
                                                    }}
                                                    title={
                                                        <div
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        >
                                                            <Row
                                                                wrap={false}
                                                                justify={
                                                                    'space-between'
                                                                }
                                                            >
                                                                <Col>
                                                                    <ClockCircleOutlined />{' '}
                                                                    {att.date} -
                                                                    {att.timeIn
                                                                        ? moment
                                                                              .unix(
                                                                                  att.timeIn
                                                                              )
                                                                              .format(
                                                                                  ' hh:mm a'
                                                                              )
                                                                        : '---'}
                                                                </Col>
                                                                <Col>
                                                                    <Tag
                                                                        color={
                                                                            att.status ===
                                                                            'pending'
                                                                                ? 'darkorange'
                                                                                : att.status ===
                                                                                  'absent'
                                                                                ? '#bd4c5f'
                                                                                : 'darkgreen'
                                                                        }
                                                                        style={{
                                                                            borderRadius: 10,
                                                                        }}
                                                                    >
                                                                        {att.status ===
                                                                        'pending'
                                                                            ? 'Pending'
                                                                            : att.status ===
                                                                              'absent'
                                                                            ? 'Absent'
                                                                            : 'Done'}
                                                                    </Tag>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            '#434343',
                                                        border: 'none',
                                                        borderRadius: 12,
                                                    }}
                                                    bodyStyle={{
                                                        color: 'white',
                                                    }}
                                                >
                                                    <Row>
                                                        <Col flex={1}>
                                                            <Card.Meta
                                                                className="transport"
                                                                avatar={
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                        }}
                                                                    >
                                                                        <Avatar
                                                                            src={
                                                                                att
                                                                                    .employeeData
                                                                                    .idUrl
                                                                            }
                                                                            style={{
                                                                                width: 64,
                                                                                height: 64,
                                                                                backgroundColor:
                                                                                    '#1890ff',
                                                                                border: `2px solid ${'green'}`,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                title={
                                                                    <Typography.Text
                                                                        style={{
                                                                            color: 'white',
                                                                            fontSize: 18,
                                                                        }}
                                                                        // onClick={() =>
                                                                        //     navigate(
                                                                        //         `personnel/${att.employeeId}`
                                                                        //     )
                                                                        // }
                                                                    >
                                                                        {
                                                                            att
                                                                                .employeeData
                                                                                .displayName
                                                                        }
                                                                    </Typography.Text>
                                                                }
                                                                description={
                                                                    <>
                                                                        <div>
                                                                            <Typography.Link>
                                                                                {
                                                                                    att
                                                                                        .employeeData
                                                                                        .email
                                                                                }
                                                                            </Typography.Link>
                                                                        </div>
                                                                        <div>
                                                                            <Typography.Text
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    att
                                                                                        .employeeData
                                                                                        .phone
                                                                                }
                                                                            </Typography.Text>
                                                                        </div>
                                                                    </>
                                                                }
                                                            />
                                                        </Col>
                                                        <Col span={4}>
                                                            <Tooltip title="Mark as Done">
                                                                <Popconfirm
                                                                    title={
                                                                        "This can't be undone. Are you sure?"
                                                                    }
                                                                    // onConfirm={() =>
                                                                    //     handleUpdateAttendance(
                                                                    //         att,
                                                                    //         'done'
                                                                    //     )
                                                                    // }
                                                                >
                                                                    <Button
                                                                        type="link"
                                                                        key="accept"
                                                                    >
                                                                        <CheckCircleFilled
                                                                            style={{
                                                                                fontSize: 36,
                                                                                fontWeight:
                                                                                    'bold',
                                                                                color: '#5cb85c',
                                                                            }}
                                                                        />
                                                                    </Button>
                                                                </Popconfirm>
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )
                                    }
                                )}
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Card
                            title={
                                <div style={{ color: 'white' }}>
                                    <Row justify={'space-between'}>
                                        <Col>
                                            <Row
                                                align={'middle'}
                                                gutter={[12, 12]}
                                            >
                                                <Col>
                                                    <div
                                                        style={{
                                                            height: 25,
                                                            width: 10,
                                                            borderRadius: 10,
                                                            backgroundColor:
                                                                styles.primary,
                                                        }}
                                                    ></div>
                                                </Col>
                                                <Col>
                                                    <Typography.Title
                                                        level={4}
                                                        style={{
                                                            marginBottom: 0,
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Time Out
                                                    </Typography.Title>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            }
                            headStyle={{ borderBottom: 'none' }}
                            style={{
                                backgroundColor: '#272829',
                                border: 'none',
                                borderRadius: 12,
                            }}
                            bodyStyle={{
                                maxHeight: 600,
                                overflowY: 'scroll',
                            }}
                        >
                            {!teamGroupData.doneAttendance.length && (
                                <Empty style={{ color: 'white' }} />
                            )}
                            <Row gutter={[24, 24]}>
                                {teamGroupData.doneAttendance.map(
                                    (
                                        att: Attendance & {
                                            employeeData: Personnel
                                        }
                                    ) => {
                                        return (
                                            <Col key={att.id} span={24}>
                                                <Card
                                                    bordered={false}
                                                    headStyle={{
                                                        borderBottom: 'none',
                                                    }}
                                                    title={
                                                        <div
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        >
                                                            <Row
                                                                wrap={false}
                                                                justify={
                                                                    'space-between'
                                                                }
                                                            >
                                                                <Col>
                                                                    <ClockCircleOutlined />{' '}
                                                                    {att.date} -
                                                                    {att.timeOut
                                                                        ? moment
                                                                              .unix(
                                                                                  att.timeOut
                                                                              )
                                                                              .format(
                                                                                  ' hh:mm a'
                                                                              )
                                                                        : '---'}
                                                                </Col>
                                                                <Col>
                                                                    <Tag
                                                                        color={
                                                                            att.status ===
                                                                            'pending'
                                                                                ? 'darkorange'
                                                                                : att.status ===
                                                                                  'absent'
                                                                                ? '#bd4c5f'
                                                                                : 'darkgreen'
                                                                        }
                                                                        style={{
                                                                            borderRadius: 10,
                                                                        }}
                                                                    >
                                                                        {att.status ===
                                                                        'pending'
                                                                            ? 'Pending'
                                                                            : att.status ===
                                                                              'absent'
                                                                            ? 'Absent'
                                                                            : 'Done'}
                                                                    </Tag>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            '#434343',
                                                        border: 'none',
                                                        borderRadius: 12,
                                                    }}
                                                    bodyStyle={{
                                                        color: 'white',
                                                    }}
                                                >
                                                    <Card.Meta
                                                        className="transport"
                                                        avatar={
                                                            <div
                                                                style={{
                                                                    position:
                                                                        'relative',
                                                                }}
                                                            >
                                                                <Avatar
                                                                    src={
                                                                        att
                                                                            .employeeData
                                                                            .idUrl
                                                                    }
                                                                    style={{
                                                                        width: 64,
                                                                        height: 64,
                                                                        backgroundColor:
                                                                            '#1890ff',
                                                                        border: `2px solid ${'green'}`,
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        title={
                                                            <Typography.Text
                                                                style={{
                                                                    color: 'white',
                                                                    fontSize: 18,
                                                                }}
                                                                // onClick={() =>
                                                                //     navigate(
                                                                //         `personnel/${att.employeeId}`
                                                                //     )
                                                                // }
                                                            >
                                                                {
                                                                    att
                                                                        .employeeData
                                                                        .displayName
                                                                }
                                                            </Typography.Text>
                                                        }
                                                        description={
                                                            <>
                                                                <div>
                                                                    <Typography.Link>
                                                                        {
                                                                            att
                                                                                .employeeData
                                                                                .email
                                                                        }
                                                                    </Typography.Link>
                                                                </div>
                                                                <div>
                                                                    <Typography.Text
                                                                        style={{
                                                                            color: 'white',
                                                                        }}
                                                                    >
                                                                        {
                                                                            att
                                                                                .employeeData
                                                                                .phone
                                                                        }
                                                                    </Typography.Text>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </Card>
                                            </Col>
                                        )
                                    }
                                )}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default TeamLeaderDashboard
