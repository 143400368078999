import { useState } from 'react'
import { Col, Form, message, Row } from 'antd'
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormTextArea,
} from '@ant-design/pro-components'
import { shallowEqual } from 'react-redux'
import moment from 'moment'
import { collection, addDoc } from 'firebase/firestore'
import {
    Deposit,
    Saving,
    transactionTypesOptions,
} from '../../../domain/entities/Saving'
import { db } from '../../boot'
import { useAppSelector } from '../../store'
import { SAVINGS_REPOSITORY } from '../../../data/repositories/constants'

function CreateDeposit({
    handleCancel,
    row,
}: {
    handleCancel: () => void
    row: Saving
}) {
    // const dispatch = useAppDispatch()

    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const { user, styles } = useAppSelector(
        (state) => ({
            activeTeam: state.auth.user.activeTeam,
            user: state.auth.user,
            styles: state.styles,
            // dueDate: 'sat',
            // frequency: 'weekly'
        }),
        shallowEqual
    )

    const handleSubmit = async (data: any) => {
        setLoading(true)
        const payload: Deposit = {
            savingsAccountId: row.id as string,
            memberId: row.memberId,
            amount: data.amount,
            transactionType: data.transactionType,
            remarks: data.remarks,
            creation: moment(data.creation).unix(),
            modified: moment().unix(),
            modifiedBy: user.email,
            isRemoved: false,
            partnerCode: row.partnerCode,
        }
        const paymentRef = collection(
            db,
            SAVINGS_REPOSITORY,
            row.id as string,
            'deposits'
        )
        const resp = await addDoc(paymentRef, payload)
        if (resp) {
            message.success('Success')
        }
        form.resetFields()
        setLoading(false)
        handleCancel()
    }

    return (
        <ModalForm<Deposit>
            title={`${row.accountHolder || ''}'s savings account`}
            form={form}
            onFinishFailed={(err) => {
                if (err.errorFields.length) {
                    const firstError = err.errorFields[0]
                    message.destroy()
                    message.error(firstError.errors)
                    form.scrollToField(firstError.name, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }}
            // title={
            //     <>
            //         <Typography.Title level={3}>
            //             {addData.name}
            //         </Typography.Title>
            //         Total Contribution:{' '}
            //         <Typography.Text style={{ fontWeight: 700, fontSize: 18 }}>
            //             {currency}
            //             {memberTotalDepositAmount}
            //         </Typography.Text>
            //         <div>
            //             <Typography.Text
            //                 style={{ fontWeight: 700, fontSize: 18 }}
            //             >
            //                 {addData.frequency}
            //             </Typography.Text>
            //         </div>
            //     </>
            // }
            open
            autoFocusFirstInput
            modalProps={{
                onCancel: handleCancel,
                okText: 'Submit',
                centered: true,
                closable: false,
            }}
            submitTimeout={2000}
            onFinish={handleSubmit}
            width={540}
            initialValues={{
                creation: moment(),
            }}
            submitter={{
                submitButtonProps: {
                    style: {
                        backgroundColor: styles.primary,
                        color: 'white',
                    },
                    loading,
                },
            }}
        >
            <Row wrap={false} gutter={[12, 0]}>
                <Col span={12}>
                    <ProFormDigit
                        rules={[
                            {
                                required: true,
                                type: 'number',
                                message: 'This field is required',
                            },
                        ]}
                        name="amount"
                        label="Amount"
                        fieldProps={{
                            onKeyUp: (event) => {
                                if (event.key === 'Enter') {
                                    form.submit()
                                }
                            },
                        }}
                    />
                </Col>
                <Col span={12}>
                    <ProFormSelect
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        label={'Type'}
                        name={'transactionType'}
                        options={transactionTypesOptions}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={12} xs={24}>
                    <ProFormDatePicker
                        name={'creation'}
                        label={'Transaction Date'}
                        fieldProps={{
                            format: 'MMMM DD, YYYY',
                            style: {
                                width: '100%',
                            },
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    />
                </Col>
            </Row>
            <ProFormTextArea name={'remarks'} label={'Remarks/Notes'} />
        </ModalForm>
    )
}

export default CreateDeposit
