import { useEffect, useState } from 'react'
import {
    Button,
    Card,
    Col,
    // Divider,
    Form,
    Grid,
    Input,
    message,
    Row,
    Spin,
    Typography,
} from 'antd'
// @ts-ignore
// import logo from '../../../assets/mainicon1280x654.png'
// import { register, setAuthState } from '../../redux/auth/auth.slice'
import { useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getTeam } from '../../redux/team/team.action'
import { Team } from '../../../domain/entities/Team'
// import { TeamHeader } from '../../routes/components/ProtectedHeaderTitle'
// import moment from 'moment'
// import { updateTeamState } from '../../redux/team/team.slice'
// import { User } from '../../../domain/entities/default/AdminUser'
import {
    RecaptchaVerifier,
    getAuth,
    // sendSignInLinkToEmail,
    signInWithPhoneNumber,
} from 'firebase/auth'
import moment from 'moment'
import PhoneInputWithCountrySelect from 'react-phone-number-input'
import { auth } from '../../boot'
import { isLoggedIn, setAuthState } from '../../redux/auth/auth.slice'
// import { auth } from '../../../app/boot'
// import { updateTeamState } from '../../redux/team/team.slice'

function UserRegister() {
    const { errMessage, styles } = useAppSelector(
        (state) => ({
            errMessage: state.auth.errMessage,
            styles: state.styles,
            selectedTeamData: state.team.selectedTeamData,
        }),
        shallowEqual
    )
    const [isLoading, setIsLoading] = useState(false)
    const [inviterLoading, setInviterLoading] = useState(false)
    const location = useLocation()
    const { xs } = Grid.useBreakpoint()
    const [values, setValues] = useState<any>({
        email: '',
        password: '',
        verifyPassword: '',
    })
    const [inviter, setInviter] = useState<Team | undefined>(undefined)
    // const navigate = useNavigate()
    // const dispatch = useAppDispatch()
    function isTimeDifferenceGreaterThan3Minutes(
        startTime: number,
        endTime: number
    ) {
        const startMoment = moment.unix(startTime)
        const endMoment = moment.unix(endTime)
        const timeDifferenceInMinutes = endMoment.diff(startMoment, 'minutes')
        // eslint-disable-next-line no-console
        console.log(timeDifferenceInMinutes)
        return timeDifferenceInMinutes > 3
    }
    const isAllowed = () => {
        const timeSent = window.localStorage.getItem('emailSentTime')
        const unixTime = timeSent ? parseInt(timeSent) : null
        if (unixTime) {
            const now = moment().unix()
            const isAllowed = isTimeDifferenceGreaterThan3Minutes(unixTime, now)
            if (!isAllowed) return false
        }
        return true
    }

    const [codeVerify, setCodeVerify] = useState('')
    const [isVerifying, setIsVerifying] = useState(false)
    const handleFinish = async (data: any) => {
        if (!isAllowed()) return
        setIsLoading(true)
        // const actionCodeSettings = {
        //     // URL you want to redirect back to. The domain (www.example.com) for this
        //     // URL must be in the authorized domains list in the Firebase Console.
        //     url: 'https://perati.co/auth',
        //     // This must be true.
        //     handleCodeInApp: true,
        //     // iOS: {
        //     //   bundleId: 'com.example.ios'
        //     // },
        //     // android: {
        //     //   packageName: 'com.example.android',
        //     //   installApp: true,
        //     //   minimumVersion: '12'
        //     // },
        //     // dynamicLinkDomain: 'example.page.link'
        // }
        // @ts-ignore
        if (!window.recaptchaVerifier) return
        const auth = getAuth()
        // @ts-ignore
        signInWithPhoneNumber(auth, data.phone, window.recaptchaVerifier)
            .then((confirmationResult) => {
                // @ts-ignore
                window.confirmationResult = confirmationResult
                // eslint-disable-next-line no-console
                // console.log(confirmationResult)

                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', data.email)
                window.localStorage.setItem(
                    'emailSentTime',
                    `${moment().unix()}`
                )
                setIsVerifying(true)
                setIsLoading(false)
                // ...
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                // console.log(err)

                if (err instanceof Error) {
                    message.error(err.message)
                } else {
                    message.error('An unknown error occurred.')
                }

                // ...
                setIsLoading(false)
            })
    }
    const [value, setValue] = useState('')

    const handleValuesChange = () => {
        if (values.email || values.password || values.verifyPassword) {
            setValues({
                email: '',
                password: '',
                verifyPassword: '',
            })
        }
    }
    const [form] = Form.useForm()
    const handleError = () => {
        setValues({
            email: 'error',
            password: 'error',
            validatePassword: 'error',
        })
        form.resetFields(['email', 'password', 'verifyPassword'])
    }

    const queryParamsString = location.search.substring(1), // remove the "?" at the start
        searchParams = new URLSearchParams(queryParamsString)
    // invitedBy = searchParams.get('invitedBy')
    const split = window.location.hostname.split('.')
    const host = split[0]
    // eslint-disable-next-line no-console
    // console.log(codeVerify)
    const dispatch = useAppDispatch()
    const handleVerifyOTP = () => {
        // @ts-ignore
        if (!window.confirmationResult) return
        // @ts-ignore
        window.confirmationResult
            .confirm(codeVerify)
            .then((result: any) => {
                // const user = result.user
                // eslint-disable-next-line no-console
                // console.log(user, result)

                dispatch(
                    isLoggedIn(async (callback) => {
                        if (!callback) return

                        await dispatch(
                            setAuthState({
                                user: callback,
                                isLoggedIn: true,
                            })
                        )
                        if (!callback.activeTeam) return
                        // navigate(
                        //     `/teams/${callback.activeTeam || callback.partnerCode}`,
                        //     {
                        //         replace: true,
                        //     }
                        // )
                    })
                )
            })
            .catch((err: any) => {
                if (err instanceof Error) {
                    message.error(err.message)
                } else {
                    message.error('An unknown error occurred.')
                }
            })
    }
    useEffect(() => {
        const getTeamData = async () => {
            try {
                let partnerCode = searchParams.get('partnerCode')

                if (!partnerCode && host !== 'perati') partnerCode = host
                if (!partnerCode) return
                setInviterLoading(true)
                const teamData = await getTeam(partnerCode)
                setInviter(teamData)
                setInviterLoading(false)
            } catch (err) {
                // eslint-disable-next-line no-console
                console.log(err)
            }
            setInviterLoading(false)
        }
        getTeamData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [host, location])
    useEffect(() => {
        if (errMessage) {
            message.error(errMessage)
            handleError()
        } else {
            handleValuesChange()
        }
        // @ts-ignore
        window.recaptchaVerifier = new RecaptchaVerifier(
            'sign-in-button',
            {
                size: 'invisible',
            },
            auth
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errMessage, auth])

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: xs ? 'center' : 'center',
                alignItems: xs ? 'normal' : 'center',
                padding: xs ? undefined : 20,
            }}
        >
            <Card
                style={{
                    paddingTop: 0,
                    boxShadow: '0 20px 40px rgb(57 76 96 / 15%)',
                    maxWidth: 400,
                    width: xs ? '100%' : 400,
                }}
                bordered
                // title={selectedTeamData.name}
            >
                <Row justify="center" align="middle" gutter={[12, 12]}>
                    {inviterLoading ? (
                        <Spin />
                    ) : (
                        <>
                            {/* <Col span={xs ? 10 : undefined}>
                                <img
                                    src={inviter?.logoUrl || logo}
                                    style={{
                                        height: xs ? 48 : 64,
                                    }}
                                    alt="perati-logo"
                                />
                            </Col> */}
                            {inviter ? (
                                <Col span={24}>
                                    <Typography.Title
                                        style={{
                                            textAlign: 'center',
                                            marginBottom: 0,
                                        }}
                                        level={xs ? 4 : 3}
                                    >
                                        {/* <Typography.Paragraph
                                            style={{
                                                fontSize: 17,
                                                marginBottom: 0,
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            You are invited to:{' '}
                                        </Typography.Paragraph> */}
                                        {inviter.name}'s Member Portal
                                    </Typography.Title>
                                    {/* <Typography.Paragraph
                                style={{
                                    textAlign: 'center',
                                    marginTop: 6,
                                    marginBottom: 6,
                                    fontSize: 18,
                                }}
                            >
                                Join now
                            </Typography.Paragraph> */}
                                </Col>
                            ) : (
                                <Col span={12}>
                                    {/* <Typography.Title
                                        level={3}
                                        style={{
                                            color: '#132438',
                                            textAlign: 'center',
                                            // fontWeight: 'bolder',
                                        }}
                                    >
                                        Sign Up
                                    </Typography.Title> */}
                                </Col>
                            )}
                        </>
                    )}
                </Row>
                <br />
                <Form
                    form={form}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            form.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    onFinish={handleFinish}
                    style={{ width: '100%' }}
                    onValuesChange={handleValuesChange}
                    layout={'vertical'}
                >
                    {isVerifying ? (
                        <div>
                            <Form.Item
                                name="OTP"
                                label="OTP Code"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    value={codeVerify}
                                    size="large"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck={false}
                                    onInput={(e: any) =>
                                        (e.target.value = e.target.value
                                            .toLowerCase()
                                            .trim())
                                    }
                                    onChange={(event) =>
                                        setCodeVerify(event.currentTarget.value)
                                    }
                                    onKeyUp={(props) => {
                                        if (props.key === 'Enter') {
                                            handleVerifyOTP()
                                        }
                                    }}
                                    suffix={
                                        <Button
                                            type="link"
                                            onClick={handleVerifyOTP}
                                        >
                                            Verify
                                        </Button>
                                    }
                                />
                            </Form.Item>
                        </div>
                    ) : (
                        <Form.Item
                            name="phone"
                            label={'Phone'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            // validateStatus={values.email}
                            // help={values.email ? 'email already exists' : ''}
                        >
                            <PhoneInputWithCountrySelect
                                placeholder="Enter phone number"
                                value={value}
                                name="phone"
                                international
                                defaultCountry="PH"
                                className="ant-input ant-input-lg invite-code-input"
                                countryCallingCodeEditable={false}
                                style={{ padding: 12, border: 'none' }}
                                onChange={(number) => {
                                    setValue(number || '')
                                    form.setFieldsValue({
                                        phone: number,
                                    })
                                }}
                            />
                            {/* <Input
                            size="large"
                            placeholder={'user@example.com'}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck={false}
                            onInput={(e: any) =>
                                (e.target.value = e.target.value
                                    .toLowerCase()
                                    .trim())
                            }
                            // onChange={(event) => setEmail(event.currentTarget.value)}
                        /> */}
                        </Form.Item>
                    )}
                    {/* <Form.Item
                        name="password"
                        label={'Password'}
                        rules={[
                            {
                                required: true,
                                message: `Password can't be blank`,
                            },
                        ]}
                        // validateStatus={
                        //     values.verifyPassword || values.password
                        // }
                        // hasFeedback
                        // help={
                        //     values.verifyPassword ? 'Password do not match' : ''
                        // }
                    >
                        <Input.Password
                            className="changeB"
                            size="large"
                            minLength={6}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck={false}
                            // onChange={(event) => setPassword(event.currentTarget.value)}
                            // iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="verifyPassword"
                        label={'Verify Password'}
                        rules={[
                            {
                                required: true,
                                message: 'Password do not match',
                            },
                        ]}
                        // validateStatus={values.verifyPassword}
                        // validateStatus={values.verifyPassword}
                        // hasFeedback
                        // help={
                        //     values.verifyPassword ? 'Password not matched' : ''
                        // }
                    >
                        <Input.Password
                            className="changeBa"
                            size="large"
                            minLength={6}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck={false}
                            // onChange={(event) => setPassword(event.currentTarget.value)}
                            // iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item> */}

                    <Form.Item
                        style={{
                            display: isVerifying ? 'none' : undefined,
                        }}
                    >
                        <Button
                            htmlType="submit"
                            size="large"
                            id="sign-in-button"
                            block
                            onClick={() => {
                                form.validateFields()
                            }}
                            disabled={!isAllowed()}
                            loading={isLoading}
                            shape={'round'}
                            style={
                                isAllowed()
                                    ? {
                                          fontWeight: 'bold',
                                          color: 'white',
                                          backgroundColor: styles.primary,
                                      }
                                    : undefined
                            }
                        >
                            {!isAllowed() ? (
                                <Typography.Paragraph type="danger">
                                    Wait for 3 minutes. Then send again.
                                </Typography.Paragraph>
                            ) : (
                                'GET VERIFICATION CODE'
                            )}
                        </Button>
                    </Form.Item>
                </Form>
                {/* <Divider>
                    <Typography.Text type="secondary">
                        Already have an account?{' '}
                    </Typography.Text>
                </Divider>

                <Button
                    size="large"
                    block
                    loading={isLoading}
                    onClick={() => navigate(`/login${location.search}`)}
                    shape={'round'}
                    style={{
                        fontWeight: 'bold',
                        color: styles.secondary,
                        backgroundColor: styles.backgroundSecondary,
                    }}
                >
                    LOGIN
                </Button> */}
            </Card>
        </div>
    )
}

export default UserRegister
