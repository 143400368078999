import Table from '../../../components/Table'
import { Trip } from '../../../../domain/entities/Transport'
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    UserOutlined,
} from '@ant-design/icons'
import {
    ProForm,
    ProFormDateTimePicker,
    ProFormDigit,
    ProFormTextArea,
} from '@ant-design/pro-components'
import {
    Modal,
    Row,
    Col,
    Card,
    Avatar,
    Typography,
    Statistic,
    Tooltip,
    // Button,
    // Image,
    // Empty,
    message,
} from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
// import ReactSignatureCanvas from 'react-signature-canvas'

interface EndTripProps {
    vehicle: Trip
    onClose: () => void
    onFinish: (data: any) => Promise<void>
    isEdit?: boolean
    title: string
}
const ViewEndTripDetails = ({
    vehicle,
    onClose,
    onFinish,
    isEdit,
    title,
}: EndTripProps) => {
    // const signatureRef = useRef()
    const [loading, setLoading] = useState(false)
    const [endForm] = ProForm.useForm()

    const [short, setShort] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [totalExpense, setTotalExpense] = useState(0)

    const [revenueBreakdown, setRevenueBreakdown] = useState<{
        open: boolean
        data: any
    }>({
        open: false,
        data: undefined,
    })

    const closeEndDrawer = () => {
        onClose && onClose()
    }

    const onSubmitEndTrip = async (data: any) => {
        setLoading(true)
        await onFinish(data)
        setLoading(false)
        endForm.resetFields()
        setTotalExpense(0)
        setTotalRevenue(0)
        setShort(0)
    }

    const getDriverAssistantTripExpense = () => {
        const driverSalary = vehicle?.driver?.salary || 0
        const assistantSalary = vehicle?.assistant?.salary || 0
        const driverAllowance = vehicle?.driver?.dailyAllowance || 0
        const assistantAllowance = vehicle?.assistant?.dailyAllowance || 0

        return (
            driverSalary +
            assistantSalary +
            driverAllowance +
            assistantAllowance
        )
    }

    // const showRevenueBreakdown = () => {
    //     setRevenueBreakdown({
    //         open: true,
    //         data: vehicle?.shiftHistory || [],
    //     })
    // }

    const closeRevenueBreakdown = () => {
        setRevenueBreakdown({
            open: false,
            data: undefined,
        })
    }
    // eslint-disable-next-line no-console
    // console.log(vehicle)

    useEffect(() => {
        endForm.setFieldsValue({
            ...vehicle,
            shiftStartTime: moment.unix(vehicle.shiftStartTime),
            shiftEndTime: moment.unix(vehicle.shiftEndTime),
            cashCollected: vehicle.cashCollected,
            cardRevenue: vehicle.cardRevenue,
            totalGasCost: vehicle.totalGasCost,
            totalGasConsumed: vehicle.totalGasConsumed,
            dailyQuotaIncentives: vehicle.dailyQuotaIncentives,
            dispatchingCost: vehicle.dispatchingCost,
            totalCashReceived: vehicle.totalCashReceived,
            miscellaneousExpenses: vehicle.miscellaneousExpenses,
            notes: vehicle.notes,
            // driverSalary: vehicle.driver?.salary,
            // assistantSalary: vehicle.assistant?.salary,
        })
        let additionals = getDriverAssistantTripExpense()
        // if (assistantData.salary && driverData.salary) {
        //     additionals = assistantData.salary + assistantData.dailyAllowance + driverData.salary + driverData.dailyAllowance
        // }
        setTotalRevenue(vehicle.totalRevenue || 0)
        setTotalExpense(vehicle.totalExpenses || 0 + additionals)
        // @ts-ignore
        setShort(
            vehicle.totalCashReceived -
                vehicle.cashCollected +
                vehicle.miscellaneousExpenses +
                vehicle.dispatchingCost +
                vehicle.totalGasCost
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle])
    return (
        <div>
            <Modal
                open={revenueBreakdown.open}
                onCancel={closeRevenueBreakdown}
            >
                <Typography.Title level={4}>Revenue</Typography.Title>

                <Table
                    toolBarRender={false}
                    dataSource={
                        revenueBreakdown.data && revenueBreakdown.data.history
                            ? revenueBreakdown.data.history
                            : []
                    }
                    columns={[
                        {
                            dataIndex: 'cashCollected',
                            title: 'Cash Revenue',
                        },
                        {
                            dataIndex: 'cardRevenue',
                            title: 'Card Revenue',
                        },
                        {
                            dataIndex: 'totalCashReceived',
                            title: 'Cash Received',
                        },
                        {
                            dataIndex: 'totalRevenue',
                            title: 'Gross Revenue',
                        },
                        // {
                        //     dataIndex: 'signatureUrl',
                        //     title: 'Signature',
                        //     render(text: any) {
                        //         return <Image src={text} width={150} />
                        //     },
                        // },
                    ]}
                    scroll={{
                        x: 800,
                    }}
                />
                <Typography.Title level={4}>Expense</Typography.Title>

                <Table
                    scroll={{
                        x: 800,
                    }}
                    toolBarRender={false}
                    dataSource={
                        revenueBreakdown.data && revenueBreakdown.data.history
                            ? revenueBreakdown.data.history
                            : []
                    }
                    columns={[
                        {
                            dataIndex: 'totalIncentives',
                            title: 'Total Incentives',
                        },
                        {
                            dataIndex: 'totalGasCost',
                            title: 'GASOLINE',
                        },
                        {
                            dataIndex: 'totalExpenses',
                            title: 'Expense',
                        },
                    ]}
                />
            </Modal>
            <Modal
                footer={false}
                title={title}
                onCancel={closeEndDrawer}
                open
                getContainer={false}
                okButtonProps={{
                    loading,
                }}
                destroyOnClose
                cancelButtonProps={{
                    loading,
                }}
                centered
            >
                <ProForm
                    form={endForm}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            endForm.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    onFinish={onSubmitEndTrip}
                    disabled
                    onValuesChange={(changed, vals) => {
                        // console.log(changed, vals)
                        if (
                            vals['cashCollected'] &&
                            vals['totalCashReceived']
                        ) {
                            setShort(
                                vals['totalCashReceived'] -
                                    vals['cashCollected']
                            )
                        }
                        if (vals['cashCollected'] && vals['cardRevenue']) {
                            setTotalRevenue(
                                vals['cashCollected'] + vals['cardRevenue']
                            )
                        }
                        if (
                            vals['totalGasCost'] !== undefined &&
                            vals['dispatchingCost'] !== undefined &&
                            vals['dailyQuotaIncentives'] !== undefined &&
                            vals['miscellaneousExpenses'] !== undefined
                        ) {
                            setTotalExpense(
                                vals['totalGasCost'] +
                                    vals['dispatchingCost'] +
                                    vals['dailyQuotaIncentives'] +
                                    getDriverAssistantTripExpense() +
                                    vals['miscellaneousExpenses']
                            )
                        }
                    }}
                >
                    {vehicle && vehicle?.driver && (
                        <Row gutter={[0, 12]}>
                            <Col xs={24} md={12}>
                                <Card.Meta
                                    className="transport"
                                    avatar={
                                        <div
                                            style={{
                                                position: 'relative',
                                            }}
                                        >
                                            {vehicle?.driver.idUrl ? (
                                                <Avatar
                                                    src={vehicle?.driver.idUrl}
                                                    style={{
                                                        width: 64,
                                                        height: 64,
                                                        backgroundColor:
                                                            '#1890ff',
                                                        border: '2px solid #1890ff',
                                                    }}
                                                />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        width: 64,
                                                        height: 64,
                                                        // backgroundColor:
                                                        //     '#1890ff',
                                                    }}
                                                    icon={
                                                        <UserOutlined
                                                            sizes="64px"
                                                            style={{
                                                                fontSize: 46,
                                                                marginTop: 8,
                                                            }}
                                                        />
                                                    }
                                                />
                                            )}
                                            {/* {vehicle?.driver.rank <= 3 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: -32,
                                                        left: 6,
                                                        padding: 8,
                                                    }}
                                                >
                                                    <FaMedal
                                                        style={{
                                                            fontSize: 36,
                                                            padding: 2,
                                                            borderRadius: 24,
                                                            backgroundColor:
                                                                'rgba(255, 255, 255, 1)',
                                                            border: '1px solid rgba(0, 0, 0, 0.3)',
                                                            color:
                                                                vehicle
                                                                    .driver
                                                                    .rank === 1
                                                                    ? '#FFD700'
                                                                    : vehicle
                                                                          .driver
                                                                          .rank ===
                                                                      2
                                                                    ? 'silver'
                                                                    : vehicle
                                                                          .driver
                                                                          .rank ===
                                                                      3
                                                                    ? '#cd7f32'
                                                                    : '',
                                                        }}
                                                    />
                                                </div>
                                            ) : null} */}
                                        </div>
                                    }
                                    title={
                                        <Typography.Text
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {vehicle?.driver.firstName[0]}.{' '}
                                            {vehicle?.driver.lastName}
                                        </Typography.Text>
                                    }
                                    description={
                                        <Row>
                                            <Typography.Text style={{}}>
                                                {vehicle?.driver.phone}
                                            </Typography.Text>
                                        </Row>
                                    }
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <Card.Meta
                                    className="transport"
                                    avatar={
                                        <div
                                            style={{
                                                position: 'relative',
                                            }}
                                        >
                                            {vehicle?.assistant?.idUrl ? (
                                                <Avatar
                                                    src={
                                                        vehicle?.assistant.idUrl
                                                    }
                                                    style={{
                                                        width: 64,
                                                        height: 64,
                                                        backgroundColor:
                                                            '#CAC601',
                                                        border: '2px solid #CAC601',
                                                    }}
                                                />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        width: 64,
                                                        height: 64,
                                                        // backgroundColor:
                                                        //     '#CAC601',
                                                    }}
                                                    icon={
                                                        <UserOutlined
                                                            sizes="64px"
                                                            style={{
                                                                fontSize: 46,
                                                                marginTop: 8,
                                                            }}
                                                        />
                                                    }
                                                />
                                            )}
                                            {/* {vehicle?.assistant?.rank <=
                                            3 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: -32,
                                                        left: 6,
                                                        padding: 8,
                                                    }}
                                                >
                                                    <FaMedal
                                                        style={{
                                                            fontSize: 36,
                                                            padding: 2,
                                                            borderRadius: 24,
                                                            backgroundColor:
                                                                'rgba(255, 255, 255, 1)',
                                                            border: '1px solid rgba(0, 0, 0, 0.3)',

                                                            color:
                                                                vehicle
                                                                    .assistant
                                                                    .rank === 1
                                                                    ? '#FFD700'
                                                                    : vehicle
                                                                          .assistant
                                                                          .rank ===
                                                                      2
                                                                    ? 'silver'
                                                                    : vehicle
                                                                          .assistant
                                                                          .rank ===
                                                                      3
                                                                    ? '#cd7f32'
                                                                    : '',
                                                        }}
                                                    />
                                                </div>
                                            ) : null} */}
                                        </div>
                                    }
                                    title={
                                        <Typography.Text
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {vehicle?.assistant?.firstName[0]}.{' '}
                                            {vehicle?.assistant?.lastName}
                                        </Typography.Text>
                                    }
                                    description={
                                        <Row>
                                            <Typography.Text style={{}}>
                                                {vehicle?.assistant?.phone}
                                            </Typography.Text>
                                        </Row>
                                    }
                                />
                            </Col>
                        </Row>
                    )}
                    <br />
                    <ProForm.Item
                        label={
                            <Typography.Text style={{ fontWeight: 'bolder' }}>
                                Shift Details
                            </Typography.Text>
                        }
                    >
                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDateTimePicker
                                    fieldProps={{
                                        format: 'MMMM DD, YYYY HH:mm',
                                        // use12Hours: true,
                                        style: {
                                            width: '100%',
                                        },
                                    }}
                                    width={216}
                                    name={'shiftStartTime'}
                                    label={'Departed'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDateTimePicker
                                    fieldProps={{
                                        format: 'MMMM DD, YYYY HH:mm',
                                        // use12Hours: true,
                                        style: {
                                            width: '100%',
                                        },
                                    }}
                                    name={'shiftEndTime'}
                                    label={'Arrived'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={'₱'}
                                    name="cashCollected"
                                    label="Cash Revenue"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="cardRevenue"
                                    label="Card Revenue"
                                />
                            </Col>
                        </Row>

                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="totalCashReceived"
                                    label="Cash Received"
                                    // addonAfter={'each'}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="totalRoundTrips"
                                    label="Total Round Trips"
                                    // addonAfter={'each'}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="totalPassengers"
                                    label="No. of Passengers"
                                    // addonAfter={'each'}
                                />
                            </Col>
                            <Col xs={24} md={12}></Col>
                        </Row>
                    </ProForm.Item>

                    <ProForm.Item
                        label={
                            <Typography.Text style={{ fontWeight: 'bolder' }}>
                                Unit Expense
                            </Typography.Text>
                        }
                    >
                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="totalGasCost"
                                    label="GASOLINE"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonAfter={'Liter'}
                                    name="totalGasConsumed"
                                    label="Gas Equivalent in Liters"
                                />
                            </Col>
                        </Row>

                        <Row gutter={[12, 0]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="dispatchingCost"
                                    label="Dispatching Cost"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="miscellaneousExpenses"
                                    label="Miscellaneous Expenses"
                                    // addonAfter={'each'}
                                />
                            </Col>
                        </Row>
                    </ProForm.Item>
                    <ProForm.Item
                        label={
                            <Typography.Text style={{ fontWeight: 'bolder' }}>
                                Bus Driver Pay
                            </Typography.Text>
                        }
                    >
                        <Row gutter={[12, 12]}>
                            <Col span={12}>
                                <ProFormDigit
                                    name="driverSalary"
                                    label="Driver Salary"
                                />
                            </Col>
                            <Col span={12}>
                                <ProFormDigit
                                    name="driverIncentives"
                                    label="Incentives"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[12, 12]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="driverOvertimeHours"
                                    label="Overtime Hours"
                                    // addonAfter={'each'}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="driverOvertimePay"
                                    label="Overtime Pay"
                                    // addonAfter={'each'}
                                />
                            </Col>
                        </Row>
                    </ProForm.Item>
                    <ProForm.Item
                        label={
                            <Typography.Text style={{ fontWeight: 'bolder' }}>
                                Bus Assistant Pay
                            </Typography.Text>
                        }
                    >
                        <Row gutter={[12, 12]}>
                            <Col span={12}>
                                <ProFormDigit
                                    name="assistantSalary"
                                    label="Assistant Salary"
                                />
                            </Col>
                            <Col span={12}>
                                <ProFormDigit
                                    name="assistantIncentives"
                                    label="Incentives"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[12, 12]}>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="assistantOvertimeHours"
                                    label="Overtime Hours"
                                    // addonAfter={'each'}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProFormDigit
                                    // addonBefore={<div>₱</div>}
                                    name="assistantOvertimePay"
                                    label="Overtime Pay"
                                    // addonAfter={'each'}
                                />
                            </Col>
                        </Row>
                    </ProForm.Item>

                    <ProFormTextArea
                        name="notes"
                        label="Notes"
                        formItemProps={{
                            style: {
                                marginBottom: 0,
                            },
                        }}
                    />
                    <ProForm.Item>
                        <Row>
                            <Col xs={24} md={8}>
                                <Card
                                    bordered={false}
                                    bodyStyle={{
                                        padding: 12,
                                    }}
                                >
                                    <Statistic
                                        title={
                                            <Typography.Text>
                                                Short/Over
                                            </Typography.Text>
                                        }
                                        value={short}
                                        precision={2}
                                        valueStyle={{
                                            color:
                                                short >= 0 ? '#3f8600' : 'red',
                                        }}
                                        prefix={
                                            <span>
                                                {short > 0 ? (
                                                    <ArrowUpOutlined />
                                                ) : short === 0 ? null : (
                                                    <ArrowDownOutlined />
                                                )}
                                                ₱
                                            </span>
                                        }
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} md={8}>
                                <Card
                                    bordered={false}
                                    bodyStyle={{
                                        padding: 12,
                                    }}
                                >
                                    <Statistic
                                        title={
                                            <Typography.Text
                                            // style={{ color: '#1890ff' }}
                                            // onClick={() =>
                                            //     showRevenueBreakdown()
                                            // }
                                            >
                                                Gross Revenue
                                            </Typography.Text>
                                        }
                                        // style={{
                                        //     color: '#1890ff',
                                        // }}
                                        value={totalRevenue}
                                        precision={2}
                                        // valueStyle={{
                                        //     color: '#3f8600',
                                        // }}
                                        prefix={'₱'}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} md={8}>
                                <Card
                                    bordered={false}
                                    bodyStyle={{
                                        padding: 12,
                                    }}
                                >
                                    <Tooltip
                                        title={
                                            vehicle &&
                                            vehicle.driver &&
                                            vehicle.assistant && (
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Driver
                                                                    Salary
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {vehicle.driverSalary ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Driver
                                                                    Incentives
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {vehicle.driverIncentives ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Driver
                                                                    Overtime Pay
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {vehicle.driverOvertimePay ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Assistant
                                                                    Salary
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {vehicle.assistantSalary ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Assistant
                                                                    Incentives
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {vehicle.assistantIncentives ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Assistant
                                                                    Overtime Pay
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {vehicle.assistantOvertimePay ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    GASOLINE
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {vehicle.totalGasCost ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Miscellaneous
                                                                    Expenses
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {vehicle.miscellaneousExpenses ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td>
                                                                <Typography.Text
                                                                    type="secondary"
                                                                    style={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Pending
                                                                    Expense
                                                                </Typography.Text>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        color: 'white',
                                                                        fontWeight:
                                                                            'bolder',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {vehicle
                                                                        ?.temporaryShiftDetails
                                                                        ?.totalExpenses ||
                                                                        '---'}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                    >
                                        <Statistic
                                            title={
                                                <Typography.Text>
                                                    Total Expenses
                                                </Typography.Text>
                                            }
                                            value={totalExpense}
                                            precision={2}
                                            valueStyle={{
                                                color: 'red',
                                            }}
                                            prefix={'₱'}
                                        />
                                    </Tooltip>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row justify={'center'}>
                            <Col>
                                <Button
                                    onClick={showRevenueBreakdown}
                                    shape="round"
                                >
                                    Remittance History
                                </Button>
                            </Col>
                        </Row> */}
                    </ProForm.Item>
                    {/* <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <ProFormDigit
                                name="totalRoundTrips"
                                label="Total round trips"
                            />
                        </Col>
                        <Col span={12}></Col>
                    </Row> */}
                    {/* <ProFormCheckbox name="driverPayoutStatus">
                        Check this box if the <b>Driver</b> has already been
                        paid.
                    </ProFormCheckbox>
                    <ProFormCheckbox name="assistantPayoutStatus">
                        Check this box if the <b>Assistant</b> has already been
                        paid.
                    </ProFormCheckbox> */}
                    {/* <ProForm.Item label={'Signature'}>
                        <div
                            style={{
                                border: `1px solid rgba(0,0,0, 0.2)`,
                                position: 'relative',
                            }}
                        >
                            <div>
                                {vehicle.signatureUrl ? (
                                    <Image src={vehicle.signatureUrl} />
                                ) : (
                                    <Empty description={'No signature.'} />
                                )}
                            </div>
                        </div>
                    </ProForm.Item> */}
                </ProForm>
            </Modal>
        </div>
    )
}

export default ViewEndTripDetails
