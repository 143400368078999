import styled from 'styled-components'

export const LeftContentSection = styled('section')`
    position: relative;
    padding: 10rem 0 8rem;

    @media only screen and (max-width: 1024px) {
        padding: 4rem 0 4rem;
    }
`

export const Content = styled('p')`
    margin: 1.5rem 0 2rem 0;
`

export const ContentWrapper = styled('div')`
    position: relative;
    max-width: 540px;

    @media only screen and (max-width: 575px) {
        padding-top: 4rem;
    }
`

export const ServiceWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    max-width: 100%;
`

export const MinTitle = styled('h5')`
    font-size: 22px;
    padding: 0.5rem 0;
    color: #281A39;
    font-family: Avenir, sans-serif;
    font-weight: 600;
`

export const MinPara = styled('p')`
    font-size: 16px;
    font-family: Avenir, sans-serif;
    line-height: 1.6;
    color: #281A39;


`
