import { ProColumns } from '@ant-design/pro-components'
import { Expense } from '../../../domain/entities/SystemSettings'
import moment from 'moment'
import { Statistic, Typography } from 'antd'
import React from 'react'
import { Vehicle } from '../../../domain/entities/Transport'

export const expensesColumns: ProColumns<Expense>[] = []
export const cashierColumns: ProColumns<Expense>[] = [
    {
        title: 'Date',
        dataIndex: 'requestDate',
        sorter: (a, b) => b.requestDate - a.requestDate,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `${moment.unix(text).format('MMM DD, YYYY')}`
        },
    },
    {
        title: 'Reference No.',
        dataIndex: 'referenceNumber',
        // sorter: (a, b) => a.referenceNumber.localeCompare(b.referenceNumber),
    },
    {
        title: 'Title',
        dataIndex: 'title',
        width: 200,
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        sorter: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
]

interface ProjectedPerformance {
    Year: number
    Date: string
    Type: string
    Route: string
    revenue: number
    expenses: number
    netIncome: number
    index: number
}

export function calculateVehiclePerformance(
    years: number,
    dailyAverageRevenue: number,
    vehicle: Vehicle
): {
    timeLogs: ProjectedPerformance[]
    overallRevenue: number
    overallExpenses: number
    overallNet: number
    obj: any
} {
    const projectedPerformance: ProjectedPerformance[] = []
    let overallRevenue = 0
    let overallExpenses = 0
    let overallNet = 0
    let obj: any = []
    for (let year = 1; year <= years; year++) {
        const date = moment
            .unix(vehicle.serviceStartDate)
            .add(year - 1, 'years')

        const totalRevenue = dailyAverageRevenue * 365 * year
        const totalExpenses =
            vehicle.monthlyLoanInstallment * 12 * year +
            vehicle.monthlyInsuranceExpense * 12 * year
        const netIncome = totalRevenue - totalExpenses
        overallRevenue += totalRevenue
        overallExpenses += totalExpenses
        overallNet += netIncome
        projectedPerformance.push({
            index: year,
            Year: year,
            Date: date.format('YYYY-MM-DD'),
            Type: vehicle.type,
            Route: vehicle.routeName,
            revenue: totalRevenue,
            expenses: totalExpenses,
            netIncome: netIncome,
        })
    }

    return {
        timeLogs: projectedPerformance,
        overallRevenue,
        overallExpenses,
        overallNet,
        obj,
    }
}
