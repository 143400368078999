import { message, Skeleton } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { User } from '../../../domain/entities/default/AdminUser'
import { teamMemberInitialState } from '../../../domain/entities/Team'
import { isLoggedIn, updateUser } from '../../redux/auth/auth.slice'
import { addTeamMember } from '../../redux/team/team.action'
import { useAppDispatch } from '../../store'

const Invite = () => {
    const dispatch = useAppDispatch()
    const params = useParams()
    // const location = useLocation()
    const navigate = useNavigate()

    const addMemberToTeam = async (user: User, partnerCode: string) => {
        const myTeams = user.myTeams || []
        await addTeamMember(
            {
                ...teamMemberInitialState,
                // status: 'pending',
                role: 'regular_member',
                // dateAccepted: moment().unix(),
                // memberId: user.id as string,
                isRemoved: false,
                creation: moment().unix(),
                modified: moment().unix(),
                modifiedBy: user.email,
                tags: [],
                name: user.displayName,
                accountNumber: '',
            },
            partnerCode
        )

        await dispatch(
            updateUser({
                myTeams: [...myTeams, partnerCode],
                id: user.id as string,
                activeTeam: partnerCode,
            })
        )
        message.success(
            'Successfully added you as a member. Waiting for admin approval',
            10
        )
        navigate(`/teams/${partnerCode}`, {
            replace: true,
        })
    }

    useEffect(() => {
        dispatch(
            isLoggedIn((resp) => {
                const inviteCode = params.teamId as string
                if (!inviteCode) return

                if (!resp && inviteCode) {
                    // const href = `/register${location.search}&partnerCode=${inviteCode}`
                    // navigate(href, {
                    //     replace: true,
                    // })
                    return
                }

                if (!resp) {
                    navigate('/login')
                    return
                }

                const user = resp
                if (user.myTeams.includes(inviteCode)) {
                    message.info('You are already in this team.', 10)
                    if (user.activeTeam !== inviteCode) {
                        dispatch(
                            updateUser({
                                id: user.id as string,
                                activeTeam: inviteCode,
                            })
                        )
                    }
                    navigate(`/teams/${inviteCode}`, {
                        replace: true,
                    })
                    return
                }

                addMemberToTeam(user, inviteCode)
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            {/* <div> */}
            <Skeleton paragraph={{ rows: 5 }} style={{ width: 500 }} />
            <Skeleton paragraph={{ rows: 5 }} style={{ width: 500 }} />
            <Skeleton paragraph={{ rows: 5 }} style={{ width: 500 }} />
            {/* </div> */}
        </div>
    )
}

export default Invite
