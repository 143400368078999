import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual } from 'react-redux'
import {
    ProCard,
    ProFormDateRangePicker,
    WaterMark,
    // WaterMark,
} from '@ant-design/pro-components'
import {
    // Badge,
    Button,
    Col,
    Grid,
    Layout,
    Menu,
    Modal,
    Row,
    // Segmented,
    Tabs,
    Tooltip,
    Typography,
    message,
} from 'antd'
import { CarFilled, PrinterFilled, SettingFilled } from '@ant-design/icons'
// import // getSystemConfig,
// '../../redux/system.slice'

import html2canvas from 'html2canvas'
import {
    collection,
    query,
    where,
    onSnapshot,
    // getDoc,
    // doc,
    getDocs,
} from 'firebase/firestore'
import { db } from '../../boot'
// import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Personnel, Vehicle } from '../../../domain/entities/Transport'
import ActiveTrip from './Trip/ActiveTrip'
import {
    PERSONNEL_ATTENDANCE_REPOSITORY,
    PERSONNEL_REPOSITORY,
    VEHICLE_REPOSITORY,
} from '../../../data/repositories/constants'
import TripSchedules from './Trip/TripSchedules'
import VehicleContainer from './Vehicle'
import { updateTeamState } from '../../../app/redux/team/team.slice'
import {
    // restoreVehicleState,
    setAllVehicles,
    updateVehicleState,
} from '../../../app/redux/vehicle/vehicle.slice'
import { MdEventAvailable } from 'react-icons/md'
import { HiWrenchScrewdriver } from 'react-icons/hi2'
import moment, { Moment } from 'moment'
import { FaPrint } from 'react-icons/fa'
import jsPDF from 'jspdf'
import { capitalize } from '../utils'
import // getAttendance,
'../Scheduling/scheduleUtils'
import { getUser, getVehicle } from '../../../app/redux/team/team.action'
// import moment from 'moment'
// import Scheduling from '../Scheduling/Scheduling'
// import { updateSharesState } from '../../redux/shares/shares.slice'
// const headerStyle: React.CSSProperties = {
//     paddingInline: 24,
//     backgroundColor: 'transparent',
// };

const contentStyle: React.CSSProperties = {
    minHeight: 120,
    backgroundColor: 'transparent',
    padding: '0 24px 36px',
}

const siderStyle: React.CSSProperties = {
    backgroundColor: 'transparent',
    borderRight: '1px solid #f0f0f0',
    marginTop: 2,
    padding: 12,
}

interface ForPrint {
    driver: string
    assistant: string
    title: string
    route: string
}
// const footerStyle: React.CSSProperties = {
//     textAlign: 'center',
//     color: '#fff',
//     backgroundColor: '#7dbcea',
// };
function VehiclesDriversContainer() {
    const {
        styles,
        activeTeam,
        teamName,
        routeNames,
        selectedVehicleId,
        isAdmin,
    } = useAppSelector(
        (state) => ({
            styles: state.styles,
            activeTeam: state.team.selectedTeamData.partnerCode,
            teamName: state.team.selectedTeamData.name,
            routeNames: state.team.selectedTeamData.routeNames,
            selectedVehicleId: state.vehicle.selectedVehicleId,
            isAdmin: state.auth.user.partnerCode === 'perati',
        }),
        shallowEqual
    )
    // const [selectedVehicle, setSelectedVehicle] = useState<string>('')

    const navigate = useNavigate()
    // const [yearlyExpenses, setYearlyExpenses] = useState<number>(0)
    const [vehicles, setVehicles] = useState<Vehicle[]>([])
    const [filterVehicles, setFilterVehicles] = useState<Vehicle[]>([])
    const [currentTab, setCurrentTab] = useState('Bus Assignment')
    // const dispatch = useAppDispatch()
    const handleNewVehicle = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        navigate(`new-vehicle`, {
            state: {
                new: true,
            },
        })
    }
    // eslint-disable-next-line no-unused-vars
    const handleViewScheduling = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        navigate(`scheduling`, {
            state: {
                new: true,
            },
        })
    }
    // const handleNewDriver = (e: any) => {
    //     e.preventDefault()
    //     e.stopPropagation()
    //     navigate(`new-personnel`)
    // }
    const handleTabChange = (tab: string) => {
        setCurrentTab(tab)
    }

    const handleSelectVehicle = (data: Vehicle) => {
        dispatch(
            updateVehicleState({
                selectedVehicleId: data.id,
            })
        )
        // setSelectedVehicle(data)
    }
    // const [vehicleTypes, setVehicleType] = useState('Available')
    // const handleSelectVehicleType = (type: any) => {
    //     setVehicleType(type)
    // }

    const [dates, setDates] = useState<[Moment, Moment] | any[]>([])
    // const [vehicleCounts, setVehicleCounts] = useState({
    //     available: 0,
    //     occupied: 0,
    // })
    useEffect(() => {
        if (!dates || !dates[0] || !dates[1]) return
        const main = async () => {
            // eslint-disable-next-line no-console
            console.log('1')

            const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)
            const q = query(
                ref,
                where('timeIn', '>=', dates[0].startOf('day').unix()),
                where('timeIn', '<=', dates[1].endOf('day').unix()),
                where('partnerCode', '==', activeTeam)
            )
            const snaps = await getDocs(q)
            const obj: any = {}
            const res = snaps.docs.map(async (docSnap) => {
                const data = docSnap.data()
                let driver: Personnel | undefined = undefined
                let assistant: Personnel | undefined = undefined
                if (!data.driver || !data.assistant) {
                    driver = await getUser(data.driverId)
                    assistant = await getUser(data.assistantId)
                }

                let vehicle
                if (data.vehicleId) {
                    vehicle = await getVehicle(data.vehicleId)
                }

                const date = data.date
                const driverName = `${driver?.lastName}, ${driver?.firstName[0]}`
                const assistantName = `${assistant?.lastName}, ${assistant?.firstName[0]}`
                if (driver && assistant && vehicle) {
                    if (!obj[data.date]) {
                        obj[data.date] = {
                            data: [],
                            title: date,
                            route: vehicle.routeName,
                        }
                    }
                    obj[data.date].data.push({
                        driver: driverName,
                        assistant: assistantName,
                        route: vehicle.routeName,
                        title: vehicle.title
                            .replace('Unit', '')
                            .replace('UNIT', '')
                            .replace('unit', ''),
                        date: data.date,
                    })
                }
                // return payload
            })
            await Promise.all(res)
            const datesObj: any = {}
            Object.keys(obj).forEach((date) => {
                const data = obj[date].data
                const routesObj: any = {}
                data.forEach((data: ForPrint) => {
                    const title = data.route
                    if (!routesObj[title]) {
                        routesObj[title] = []
                    }
                    routesObj[title].push(data)
                })

                Object.keys(routesObj).forEach((route) => {
                    if (!datesObj[date]) {
                        datesObj[date] = []
                    }
                    datesObj[date].push({
                        data: routesObj[route],
                        name: route,
                    })
                })
                // eslint-disable-next-line no-console
                // console.log(routesObj, obj[date].date)
            })

            // eslint-disable-next-line no-console
            // console.log(obj, datesObj)

            const pairs: any = []
            const gofetcoFormat = 'dddd (MMM. DD)'
            Object.keys(datesObj).forEach((date) => {
                const dateFormat = moment(date, 'MMM DD, YYYY').format(
                    gofetcoFormat
                )
                pairs.push({
                    date: dateFormat,
                    data: datesObj[date],
                    unixDate: moment(date, 'MMM DD, YYYY').unix(),
                })
            })

            // eslint-disable-next-line no-console
            // console.log(pairs, )
            setPrintingSchedsV2(pairs)
        }
        main()
    }, [activeTeam, dates, vehicles])
    useEffect(() => {
        if (!activeTeam) return
        // dispatch(getTeamSavings(activeTeam))
        const ref = collection(db, VEHICLE_REPOSITORY)
        const q = query(
            ref,
            where('partnerCode', '==', activeTeam),
            where('isRemoved', '==', false)
        )

        const unsub = onSnapshot(q, async (docSnap) => {
            // setLoading(true)
            // const source = docSnap.metadata.fromCache ? 'local cache' : 'server'
            // const obj: any = {}
            const data = docSnap.docs.map(async (docSnap) => {
                const data = docSnap.data()
                let currentDriverId = data.currentDriverId || ''
                let currentAssistantId = data.currentAssistantId || ''
                let status = data.status
                let attendanceId = data.attendanceId
                let attendanceData = data.attendanceData
                let currentReservationIndex = undefined
                let fromReservation = false
                // if (data.reservations && data.reservations.length) {
                //     const currentReserved = checkCurrentReservation(
                //         data.reservations
                //     )
                //     // eslint-disable-next-line no-console
                //     // console.log(currentReserved, data.title);
                //     if (currentReserved) {
                //         currentDriverId = currentReserved.driverId
                //         currentAssistantId = currentReserved.assistantId
                //         currentReservationIndex = currentReserved.index
                //         status = 'occupied'
                //         fromReservation = true
                //     }
                // }
                let trip: any = {
                    // ...data,
                }
                // if (currentDriverId) {
                //     const resp = await getAttendance(
                //         currentDriverId,
                //         currentAssistantId,
                //         moment().unix()
                //     )
                //     // eslint-disable-next-line no-console
                //     if (resp) {
                //         attendanceId = resp.id
                //         attendanceData = resp
                //     }
                //     const driverRef = doc(db, 'personnel', currentDriverId)
                //     const snap = await getDoc(driverRef)
                //     const driverData = snap.data()

                //     trip.driver = {
                //         ...driverData,
                //         id: snap.id,
                //     }
                // }
                // if (currentAssistantId) {
                //     const assistantRef = doc(
                //         db,
                //         'personnel',
                //         currentAssistantId
                //     )
                //     const snap = await getDoc(assistantRef)
                //     const assistantData = snap.data()

                //     trip.assistant = {
                //         ...assistantData,
                //         id: snap.id,
                //     }
                // }
                return {
                    ...data,
                    ...trip,
                    attendanceId,
                    attendanceData,
                    currentDriverId,
                    currentAssistantId,
                    currentReservationIndex,
                    status,
                    fromReservation,
                    id: docSnap.id,
                }
            })
            const promises = await Promise.all(data)
            const datum = promises.sort((a, b) => {
                // Compare the names
                const nameA = a.title.toUpperCase() // Convert to uppercase for case-insensitive sorting
                const nameB = b.title.toUpperCase()

                if (nameA < nameB) {
                    return -1 // a should come before b in the sorted order
                }
                if (nameA > nameB) {
                    return 1 // a should come after b in the sorted order
                }
                return 0 // names are equal, maintain their relative order
            })
            const first = datum[0]
            if (first && vehicles.length === 0 && !selectedVehicleId) {
                // setSelectedVehicle(first.id)

                dispatch(
                    updateVehicleState({
                        selectedVehicleId: first.id,
                    })
                )
                // setFilterVehicles(datum.filter(vehicle=> vehicle.routeName === first.routeName))
            }
            if (first) setCurrentRoute(first.routeName)
            setVehicles(datum)
            // setVehicleCounts(obj)
            dispatch(setAllVehicles(datum))
            // setLoading(false)
        })

        return () => {
            unsub()
            // dispatch(restoreVehicleState())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam])
    const [currentRoute, setCurrentRoute] = useState('')
    useEffect(() => {
        const filtered = [...vehicles].filter(
            (vehicle) => vehicle.routeName === currentRoute
        )
        setFilterVehicles(filtered)
    }, [vehicles, currentRoute])
    const { xs } = Grid.useBreakpoint()

    const dispatch = useAppDispatch()
    useEffect(() => {
        const main = async () => {
            const ref = collection(db, PERSONNEL_REPOSITORY)
            const q = await getDocs(
                query(
                    ref,
                    where('partnerCode', '==', activeTeam),
                    where('isRemoved', '==', false),
                    // where('role', '==', 'driver'),
                    where('role', 'in', [
                        'driver',
                        'assistant',
                        'dispatcher',
                        'inspector',
                    ])
                )
            )
            const data = q.docs.map((docSnap) => {
                const driverData = docSnap.data()
                return {
                    ...driverData,
                    id: docSnap.id,
                } as Personnel
            })
            // eslint-disable-next-line no-console
            // console.log(data);

            dispatch(
                updateTeamState({
                    drivers: data
                        .filter((val) => val.role === 'driver')
                        .sort((a, b) => a.lastName.localeCompare(b.lastName)),
                    assistants: data
                        .filter((val) => val.role === 'assistant')
                        .sort((a, b) => a.lastName.localeCompare(b.lastName)),
                    otherPersonnel: data
                        .filter(
                            (val) =>
                                val.role !== 'assistant' &&
                                val.role !== 'driver'
                        )
                        .sort((a, b) => a.lastName.localeCompare(b.lastName)),
                })
            )
        }
        main()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam])
    const [showPrint, setShowPrint] = useState(false)
    const handlePrintCancel = () => {
        setShowPrint(false)
        // setDates([])
    }
    const handlePrintShow = () => {
        setShowPrint(true)
    }

    function generateDatePairs(
        startDate: moment.Moment,
        endDate: moment.Moment
    ) {
        let datePairs = []
        let currentDate = moment(startDate).startOf('day')
        const endMoment = moment(endDate).endOf('day')
        const categorizedDates = []
        const doneDates: any = []
        while (
            currentDate.isBefore(endMoment) ||
            currentDate.isSame(endMoment)
        ) {
            // eslint-disable-next-line no-console

            const nextDate = moment(currentDate).add(1, 'days')
            datePairs.push({
                start: currentDate.startOf('day').unix(),
                end: currentDate.endOf('day').unix() - 1,
            })

            if (datePairs.length === 2) {
                categorizedDates.push(datePairs)
                datePairs = []
            }
            // eslint-disable-next-line no-console
            // console.log(doneDates, doneDates.includes(nextDate.format("MM-DD-YYYY")));
            // eslint-disable-next-line no-console
            // console.log(currentDate.format(),nextDate.format(), endMoment.format(), datePairs.length);

            if (currentDate.isSame(endMoment) && datePairs.length === 1) {
                // datePairs.push({
                //     start: currentDate.startOf('day').unix(),
                //     end: currentDate.endOf('day').unix() - 1,
                // });
                categorizedDates.push(datePairs)
            }
            doneDates.push(currentDate.format('MM-DD-YYYY'))
            currentDate = nextDate
        }

        return categorizedDates
    }
    const [open, setOpen] = useState(false)
    const dateRef = useRef(null)
    function findReservationsInRange(
        vehicles: Vehicle[],
        startUnix: number,
        endUnix: number
    ): ForPrint[] {
        const reservationsInRange: ForPrint[] = []
        const start = moment.unix(startUnix).format('YYYY-MM-DD')
        const end = moment.unix(endUnix).format('YYYY-MM-DD')
        // const dateObjs: any = {}

        vehicles &&
            vehicles.forEach((vehicle) => {
                const { reservations } = vehicle
                if (!reservations) return
                const obj: {
                    [key: string]: ForPrint
                } = {}
                // const pair = []
                // eslint-disable-next-line no-console
                // console.log(reservations);
                reservations.forEach((reservation) => {
                    // const reservationEnd = moment(reservation.end).unix();
                    // if(!dateObjs[start]) {
                    //     dateObjs[start] = {}
                    // }
                    // if(!dateObjs[start][vehicle.routeName]){
                    //     dateObjs[start][vehicle.routeName] = {}
                    // }
                    // if(!dateObjs[start][vehicle.routeName][vehicle.id as string]){
                    //     dateObjs[start][vehicle.routeName] = {}
                    // }
                    if (
                        reservation.start.includes(start) ||
                        reservation.end.includes(end)
                    ) {
                        const vehicleTitle = vehicle.title
                            .replace('Unit', '')
                            .replace('Unit', '')
                            .replace('unit', '')
                        if (!obj[vehicle.routeName]) {
                            obj[vehicle.routeName] = {
                                driver: '',
                                assistant: '',
                                title: vehicleTitle,
                                route: vehicle.routeName,
                            }
                        }
                        obj[vehicle.routeName]['assistant'] =
                            reservation.assistantName
                        obj[vehicle.routeName]['driver'] =
                            reservation.driverName
                        // dateObjs[start][vehicle.routeName].push()
                        // obj[vehicle.title].push({
                        //     vehicleName: vehicle.title,
                        //     personnel: reservation.title,
                        //     date: moment.unix(startUnix).format("MMMM DD YYYY"),
                        //     route: vehicle.routeName

                        // });
                    }
                })
                if (obj[vehicle.routeName]) {
                    obj[vehicle.routeName].route = vehicle.routeName
                    reservationsInRange.push(obj[vehicle.routeName])
                }
            })
        const newDatesObj: any = {}
        reservationsInRange.forEach((hitReserves) => {
            if (!newDatesObj[hitReserves.route]) {
                newDatesObj[hitReserves.route] = []
            }

            const { route, ...rest } = hitReserves
            newDatesObj[hitReserves.route].push({
                ...rest,
            })
        })
        // eslint-disable-next-line no-console
        // console.log(newDatesObj, start);

        return reservationsInRange
    }
    const [, setPrintingScheds] = useState([])
    const [printingSchedsV2, setPrintingSchedsV2] = useState([])
    useEffect(() => {
        const newDates = generateDatePairs(dates[0], dates[1])
        // eslint-disable-next-line no-console
        // console.log(newDates)
        const main = async () => {
            const pairs: any = []
            const newpairs: any = []
            const obj: any = {}
            newDates.forEach((date) => {
                if (!date[0]) return
                const left = date[0]
                const leftReservations = findReservationsInRange(
                    vehicles,
                    left.start,
                    left.end
                )

                const leftPayload = {
                    data: leftReservations,
                    title: moment.unix(left.start).format('dddd (MMM. DD)'),
                }
                if (!obj[leftPayload.title]) {
                    obj[leftPayload.title] = []
                }
                // obj[left.start] = leftReservations

                let leftNewObj: any = {}
                leftReservations.forEach((hitRight) => {
                    if (!leftNewObj[hitRight.route]) {
                        leftNewObj[hitRight.route] = []
                    }
                    leftNewObj[hitRight.route].push({
                        ...hitRight,
                    })
                })
                // eslint-disable-next-line no-console
                // console.log(leftNewObj)
                Object.keys(leftNewObj).forEach((routeKey) => {
                    if (!obj[leftPayload.title]) {
                        obj[leftPayload.title] = []
                    }
                    obj[leftPayload.title].push({
                        data: leftNewObj[routeKey],
                        name: routeKey,
                    })
                    // obj[left.start].push(data)
                    // return data
                })

                const right = date[1]
                if (right) {
                    const rightReservations = findReservationsInRange(
                        vehicles,
                        right.start,
                        right.end
                    )
                    const rightPayload = {
                        data: rightReservations,
                        title: moment
                            .unix(right.start)
                            .format('dddd (MMM. DD)'),
                    }
                    // eslint-disable-next-line no-console
                    // console.log(rightPayload);

                    if (!obj[rightPayload.title]) {
                        obj[rightPayload.title] = []
                    }
                    // obj[rightPayload.title] = rightReservations

                    let rightNewObj: any = {}
                    rightReservations.forEach((hitRight) => {
                        if (!rightNewObj[hitRight.route]) {
                            rightNewObj[hitRight.route] = []
                        }
                        rightNewObj[hitRight.route].push({
                            ...hitRight,
                        })
                    })

                    // eslint-disable-next-line no-console
                    // console.log(rightNewObj)
                    Object.keys(rightNewObj).forEach((routeKey) => {
                        if (!obj[rightPayload.title]) {
                            obj[rightPayload.title] = []
                        }
                        obj[rightPayload.title].push({
                            data: rightNewObj[routeKey],
                            name: routeKey,
                        })
                        // return data
                    })
                }
                // eslint-disable-next-line no-console
                // console.log(rightNewObj)
                // eslint-disable-next-line no-console

                // eslint-disable-next-line no-console
                // console.log(obj)
                // pairs.push({ yesterday: leftPayload, tomorrow: rightPayload })
                // newpairs.push(obj)
            })

            Object.keys(obj).forEach((objKey) => {
                newpairs.push({
                    date: objKey,
                    data: obj[objKey],
                })
                // if (!obj[rightPayload.title]) {
                //     obj[rightPayload.title] = []
                // }
                // obj[rightPayload.title].push({
                //     data: rightNewObj[routeKey],
                //     name: routeKey
                // })
                // return data
            })
            // eslint-disable-next-line no-console
            // console.log(newpairs)
            // eslint-disable-next-line no-console
            // console.log(pairs)
            setPrintingScheds(pairs)
            // setPrintingSchedsV2(newpairs)
        }

        main()
    }, [dates, vehicles])
    const [loading, setLoading] = useState(false)
    const handlePrint = async (width: number, height: number) => {
        if (!dates[0] || !dates[1]) {
            message.error('Dates missing. Try again')
            return
        }

        const input = document.getElementById('divToPrint')
        if (!input) return
        setLoading(true)
        const canvas = await html2canvas(input)
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF({ orientation: 'landscape', unit: 'mm' })
        pdf.addImage({
            imageData: imgData,
            x: 0,
            y: 0,
            width,
            height,
        })
        // pdf.output('dataurlnewwindow');
        pdf.save(
            `${teamName}-dispatch schedule for ${dates[0].format(
                'MM-DD-YYYY'
            )} to ${dates[1].format('MM-DD-YYYY')}.pdf`
        )
        setLoading(false)
    }
    const [, setRouteSettings] = useState({
        open: false,
        data: undefined,
    })
    // eslint-disable-next-line no-console
    // console.log(printingSchedsV2)

    const handleShowRouteSettings = (data: any) => {
        setRouteSettings({
            open: true,
            data,
        })
    }
    // const handleCancelRouteSettings = () => {
    //     setRouteSettings({
    //         open: false,
    //         data: undefined,
    //     })
    // }
    return (
        <div className="page-layout-wide">
            {/* <Modal
                open={routeSettings.open}
                onCancel={handleCancelRouteSettings}
            ></Modal> */}
            <Modal
                open={showPrint}
                onCancel={handlePrintCancel}
                footer={false}
                bodyStyle={{
                    padding: 24,
                    backgroundColor: '#efefef',
                }}
                width={'310mm'}
                closeIcon={null}
                title={
                    <div style={{ marginTop: 24 }}>
                        <Row justify={'space-around'} align={'middle'}>
                            <Col>
                                <Row gutter={[12, 12]} align={'middle'}>
                                    <Col>Select dates to print</Col>
                                    <Col>
                                        <ProFormDateRangePicker
                                            proFieldProps={{}}
                                            formItemProps={{
                                                style: {
                                                    marginBottom: 0,
                                                },
                                            }}
                                            fieldProps={{
                                                ref: dateRef,
                                                open: open,
                                                value: dates as any,
                                                style: {
                                                    marginBottom: 0,
                                                },
                                                format: 'MMM DD, YYYY',
                                                onOpenChange: (val) =>
                                                    setOpen(val),
                                                allowClear: true,
                                                onChange: (vals, format) => {
                                                    // eslint-disable-next-line no-console
                                                    // console.log(vals, format)
                                                    if (!vals) {
                                                        setDates([])
                                                        return
                                                    }
                                                    // setReports({
                                                    //     ...reports,
                                                    //     start: vals[0]?.startOf('day').unix() || 0,
                                                    //     end: vals[1]?.endOf('day').unix() || 0,
                                                    // })
                                                    setDates(vals)
                                                    // setCurrentFilter('')
                                                    setOpen(false)
                                                    if (dateRef.current) {
                                                        // @ts-ignore
                                                        dateRef.current.blur()
                                                    }
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Button
                                    onClick={() => handlePrint(297, 210)}
                                    icon={
                                        <FaPrint style={{ marginRight: 5 }} />
                                    }
                                    type="primary"
                                    size="large"
                                    shape="round"
                                    style={{ zIndex: 999 }}
                                    loading={loading}
                                >
                                    Download as PDF
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
            >
                {/* <Row
                    justify={'center'}
                    style={
                        dates[0]
                            ? {
                                  position: 'absolute',
                                  width: '100%',
                                  top: 68,
                                  zIndex: 99,
                              }
                            : undefined
                    }
                >
                    <Col>
                        
                    </Col>
                </Row> */}
                {dates[0] && dates[1] ? (
                    <>
                        <Row
                            justify={'center'}
                            style={{ overflow: xs ? 'scroll' : undefined }}
                        >
                            <Col>
                                <div
                                    id="divToPrint"
                                    style={{
                                        backgroundColor: '#fff',
                                        width: '297mm',
                                        minHeight: '210mm',
                                        overflow: 'hidden',
                                        border: '1px solid #dfdfdf',
                                    }}
                                >
                                    <WaterMark
                                        image="/logo192.png"
                                        markStyle={{
                                            opacity: 0.05,
                                        }}
                                        content={[
                                            `${teamName.toUpperCase()}`,
                                            `${teamName.toUpperCase()} ${moment().format(
                                                'MM-DD-YYYY'
                                            )}`,
                                        ]}
                                        style={{ height: '210mm' }}
                                    >
                                        {xs ? (
                                            <Typography.Paragraph
                                                style={{
                                                    textAlign: 'center',
                                                    fontSize: 22,
                                                }}
                                            >
                                                Bus Personnel Schedule
                                            </Typography.Paragraph>
                                        ) : (
                                            <Typography.Title
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Bus Personnel Schedule
                                            </Typography.Title>
                                        )}
                                        <Row
                                            // key={idx}
                                            style={{ marginBottom: 20 }}
                                        >
                                            {printingSchedsV2.map(
                                                (sched: {
                                                    date: string
                                                    data: {
                                                        name: string
                                                        data: ForPrint[]
                                                    }[]
                                                }) => {
                                                    return (
                                                        <Col
                                                            span={
                                                                printingSchedsV2.length ===
                                                                1
                                                                    ? 24
                                                                    : printingSchedsV2.length ===
                                                                      2
                                                                    ? 12
                                                                    : 8
                                                            }
                                                            key={sched.date}
                                                            style={{
                                                                border: '1px solid #dfdfdf',
                                                            }}
                                                        >
                                                            <Typography.Title
                                                                level={2}
                                                                style={{
                                                                    color: 'white',
                                                                    backgroundColor:
                                                                        styles.primary,
                                                                    textAlign:
                                                                        'center',
                                                                    padding:
                                                                        '6px 0',
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                {sched.date}
                                                            </Typography.Title>
                                                            <Row
                                                                style={
                                                                    {
                                                                        // borderTop: '1px solid #a4a4a4',
                                                                    }
                                                                }
                                                            >
                                                                {sched.data.map(
                                                                    (
                                                                        schedToms
                                                                    ) => {
                                                                        return (
                                                                            <Col
                                                                                span={
                                                                                    24
                                                                                }
                                                                            >
                                                                                <Typography.Title
                                                                                    level={
                                                                                        4
                                                                                    }
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'center',
                                                                                        backgroundColor:
                                                                                            '#D85618',
                                                                                        color: 'white',
                                                                                        padding: 0,
                                                                                        marginBottom: 0,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        schedToms.name
                                                                                    }
                                                                                </Typography.Title>

                                                                                {/* <Typography.Title level={2} style={{color: "white", backgroundColor: styles.primary, textAlign: 'center'}}>
                                                                            {sched.yesterday.route}
                                                                        </Typography.Title> */}
                                                                                <Row>
                                                                                    <Col
                                                                                        span={
                                                                                            4
                                                                                        }
                                                                                    >
                                                                                        <Typography.Paragraph
                                                                                            style={{
                                                                                                textAlign:
                                                                                                    'center',
                                                                                                marginBottom: 0,
                                                                                            }}
                                                                                        >
                                                                                            Unit
                                                                                        </Typography.Paragraph>
                                                                                    </Col>
                                                                                    <Col
                                                                                        span={
                                                                                            10
                                                                                        }
                                                                                    >
                                                                                        <Typography.Paragraph
                                                                                            style={{
                                                                                                textAlign:
                                                                                                    'center',
                                                                                                marginBottom: 0,
                                                                                            }}
                                                                                        >
                                                                                            Driver
                                                                                        </Typography.Paragraph>
                                                                                    </Col>
                                                                                    <Col
                                                                                        span={
                                                                                            10
                                                                                        }
                                                                                    >
                                                                                        <Typography.Paragraph
                                                                                            style={{
                                                                                                textAlign:
                                                                                                    'center',
                                                                                                marginBottom: 0,
                                                                                            }}
                                                                                        >
                                                                                            Assistant
                                                                                        </Typography.Paragraph>
                                                                                    </Col>
                                                                                </Row>
                                                                                {schedToms.data.map(
                                                                                    (
                                                                                        schedData
                                                                                    ) => {
                                                                                        return (
                                                                                            <Row
                                                                                                key={
                                                                                                    schedData.title
                                                                                                }
                                                                                            >
                                                                                                <Col
                                                                                                    span={
                                                                                                        4
                                                                                                    }
                                                                                                >
                                                                                                    <Typography.Paragraph
                                                                                                        style={{
                                                                                                            fontWeight:
                                                                                                                'bolder',
                                                                                                            textAlign:
                                                                                                                'center',
                                                                                                            fontSize: 20,
                                                                                                            marginBottom: 0,
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            schedData.title
                                                                                                        }
                                                                                                    </Typography.Paragraph>
                                                                                                </Col>
                                                                                                <Col
                                                                                                    span={
                                                                                                        10
                                                                                                    }
                                                                                                >
                                                                                                    <Typography.Paragraph
                                                                                                        style={{
                                                                                                            fontWeight:
                                                                                                                'bolder',
                                                                                                            textAlign:
                                                                                                                'center',
                                                                                                            fontSize: 20,
                                                                                                            marginBottom: 0,
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            schedData.driver
                                                                                                        }
                                                                                                    </Typography.Paragraph>
                                                                                                </Col>
                                                                                                <Col
                                                                                                    span={
                                                                                                        10
                                                                                                    }
                                                                                                >
                                                                                                    <Typography.Paragraph
                                                                                                        style={{
                                                                                                            fontWeight:
                                                                                                                'bolder',
                                                                                                            textAlign:
                                                                                                                'center',
                                                                                                            fontSize: 20,
                                                                                                            marginBottom: 0,
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            schedData.assistant
                                                                                                        }
                                                                                                    </Typography.Paragraph>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        )
                                                                                    }
                                                                                )}
                                                                            </Col>
                                                                        )
                                                                    }
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    )
                                                }
                                            )}
                                        </Row>
                                        <Row justify={'space-between'}>
                                            <Col style={{ width: 250 }}>
                                                <Typography.Paragraph
                                                    style={{ fontSize: 24 }}
                                                >
                                                    Prepared by:
                                                </Typography.Paragraph>
                                                <Typography.Paragraph
                                                    style={{
                                                        fontSize: 24,
                                                        marginBottom: 0,
                                                    }}
                                                >
                                                    ________________________
                                                </Typography.Paragraph>
                                                <Typography.Paragraph
                                                    style={{
                                                        fontSize: 18,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    DISPATCHING OFFICER
                                                </Typography.Paragraph>
                                            </Col>
                                            <Col style={{ width: 450 }}>
                                                <table
                                                    style={{
                                                        fontSize: 'larger',
                                                    }}
                                                >
                                                    <tr>
                                                        <th
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Role
                                                        </th>
                                                        {printingSchedsV2.map(
                                                            (sched: any) => (
                                                                <th
                                                                    key={
                                                                        sched.date
                                                                    }
                                                                    style={{
                                                                        width: 150,
                                                                        fontWeight:
                                                                            'normal',
                                                                    }}
                                                                >
                                                                    {moment
                                                                        .unix(
                                                                            sched.unixDate
                                                                        )
                                                                        .format(
                                                                            'ddd'
                                                                        )}
                                                                </th>
                                                            )
                                                        )}
                                                        {/* <th
                                                            style={{
                                                                width: 150,
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: 150,
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: 150,
                                                                fontWeight:
                                                                    'normal',
                                                            }}
                                                        >
                                                            
                                                        </th> */}
                                                    </tr>
                                                    <tr>
                                                        <th>Dispatcher</th>
                                                        <td
                                                            style={{
                                                                border: '1px solid rgb(223, 223, 223)',
                                                            }}
                                                        ></td>
                                                        <td
                                                            style={{
                                                                border: '1px solid rgb(223, 223, 223)',
                                                            }}
                                                        ></td>
                                                        <td
                                                            style={{
                                                                border: '1px solid rgb(223, 223, 223)',
                                                            }}
                                                        ></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Inspector</th>
                                                        <td
                                                            style={{
                                                                border: '1px solid rgb(223, 223, 223)',
                                                            }}
                                                        ></td>
                                                        <td
                                                            style={{
                                                                border: '1px solid rgb(223, 223, 223)',
                                                            }}
                                                        ></td>
                                                        <td
                                                            style={{
                                                                border: '1px solid rgb(223, 223, 223)',
                                                            }}
                                                        ></td>
                                                    </tr>
                                                </table>
                                            </Col>
                                        </Row>
                                    </WaterMark>
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : null}
            </Modal>
            {/* <Scheduling /> */}
            {xs && <br />}
            {isAdmin && (
                <Row
                    style={{
                        padding: 24,
                    }}
                >
                    <Col xs={12} md={6} lg={4}>
                        <ProCard
                            hoverable
                            style={{
                                maxHeight: 100,
                                cursor: 'pointer',
                            }}
                            bodyStyle={{
                                maxHeight: 100,
                                padding: 16,
                            }}
                            onClick={handleNewVehicle}
                            colSpan={xs ? 100 : '200px'}
                        >
                            <Row
                                justify={'center'}
                                align={'middle'}
                                style={{ height: 34 }}
                            >
                                <Col>
                                    <CarFilled
                                        style={{
                                            fontSize: xs ? 16 : 28,
                                            color: styles.primary,
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row justify={'center'} align={'middle'}>
                                <Col>
                                    <Button
                                        style={{
                                            color: styles.primary,
                                            fontSize: xs ? 16 : 20,
                                            padding: 0,
                                        }}
                                        type={'text'}
                                        onClick={handleNewVehicle}
                                    >
                                        Add Bus
                                    </Button>
                                </Col>
                            </Row>
                        </ProCard>
                    </Col>
                    {/* <Col xs={12} md={6} lg={4}> */}
                    {/* <ProCard
                        hoverable
                        style={{
                            maxHeight: 100,
                            cursor: 'pointer',
                        }}
                        bodyStyle={{
                            maxHeight: 100,
                            padding: 16,
                        }}
                        onClick={handleViewScheduling}
                        colSpan={xs ? 100 : '200px'}
                    >
                        <Row
                            justify={'center'}
                            align={'middle'}
                            style={{ height: 34 }}
                        >
                            <Col>
                                <MdEventAvailable
                                    style={{
                                        fontSize: xs ? 16 : 32,
                                        color: styles.primary,
                                        marginTop: 2,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <Button
                                    style={{
                                        color: styles.primary,
                                        fontSize: xs ? 16 : 20,
                                        padding: 0,
                                    }}
                                    type={'text'}
                                    onClick={handleViewScheduling}
                                >
                                    Dispatching
                                </Button>
                            </Col>
                        </Row>
                    </ProCard> */}
                    {/* </Col> */}
                    <Col xs={12} md={6} lg={4}>
                        <ProCard
                            hoverable
                            style={{
                                maxHeight: 100,
                                cursor: 'pointer',
                            }}
                            bodyStyle={{
                                maxHeight: 100,
                                padding: 16,
                            }}
                            onClick={handlePrintShow}
                            colSpan={xs ? 100 : '200px'}
                        >
                            <Row
                                justify={'center'}
                                align={'middle'}
                                style={{ height: 34 }}
                            >
                                <Col>
                                    <PrinterFilled
                                        style={{
                                            fontSize: xs ? 16 : 28,
                                            color: styles.primary,
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row justify={'center'} align={'middle'}>
                                <Col>
                                    <Button
                                        style={{
                                            color: styles.primary,
                                            fontSize: xs ? 16 : 20,
                                            padding: 0,
                                        }}
                                        type={'text'}
                                        onClick={handlePrintShow}
                                    >
                                        Print Schedule
                                    </Button>
                                </Col>
                            </Row>
                        </ProCard>
                    </Col>

                    {/* <ProCard.Divider type={'vertical'} /> */}

                    {/* <ProCard
                    hoverable
                    style={{
                        maxHeight: 100,
                        cursor: 'pointer',
                    }}
                    bodyStyle={{
                        maxHeight: 100,
                        padding: 16,
                    }}
                    onClick={handleNewDriver}
                    colSpan={xs ? 100 : '200px'}
                >
                    <Row justify={'center'} align={'middle'}>
                        <Col>
                            <MdPerson
                                style={{
                                    fontSize: xs ? 16 : 28,
                                    color: styles.primary,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row justify={'center'} align={'middle'}>
                        <Col>
                            <Button
                                style={{
                                    color: styles.primary,
                                    fontSize: xs ? 16 : 20,
                                    padding: 0,
                                }}
                                type={'text'}
                                onClick={handleNewDriver}
                            >
                                New Personnel
                            </Button>
                        </Col>
                    </Row>
                </ProCard> */}
                </Row>
            )}
            <Layout>
                <Layout.Header
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        padding: 0,
                        backgroundColor: 'transparent',
                    }}
                >
                    <Menu
                        style={{
                            width: '100%',
                            padding: '0 24px',
                            // backgroundColor: '#fbfbfb',
                            marginTop: 4,
                        }}
                        theme="dark"
                        mode="horizontal"
                        selectedKeys={[currentRoute]}
                        onClick={(menuInfo) => setCurrentRoute(menuInfo.key)}
                        items={routeNames
                            .map((route) => ({
                                key: route,
                                title: capitalize(route || ''),
                                label: (
                                    <div style={{}}>
                                        <Row gutter={[12, 12]}>
                                            <Col>
                                                <span style={{ fontSize: 20 }}>
                                                    {capitalize(route || '')}
                                                </span>
                                            </Col>
                                            <Col>
                                                <div
                                                    className="show-onhover"
                                                    // style={{
                                                    //     visibility: 'hidden',
                                                    //     opacity: 0,
                                                    //     zIndex: 9999
                                                    // }}
                                                >
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            handleShowRouteSettings(
                                                                route
                                                            )
                                                        }}
                                                        type="link"
                                                        icon={<SettingFilled />}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            }))
                            .sort((a, b) => a.title.localeCompare(b.title))}
                    />
                </Layout.Header>
                <Layout.Content
                    style={{
                        border: '1px solid #f0f0f0',
                        marginTop: 3,
                    }}
                >
                    <Layout>
                        <Layout.Sider style={siderStyle} title="Vehicles">
                            {/* <Segmented
                                value={vehicleTypes}
                                onChange={handleSelectVehicleType}
                                style={{
                                    width: '100%',
                                }}
                                options={[
                                    {
                                        className:
                                            vehicleTypes === 'Occupied'
                                                ? 'ant-segmented-item ant-segmented-item-selected badge-show-bold'
                                                : 'ant-segmented-item badge-show',
                                        value: 'Occupied',
                                        label: (
                                            <Badge
                                                count={
                                                    vehicleCounts['occupied']
                                                }
                                                offset={[0, -8]}
                                            >
                                                Occupied
                                            </Badge>
                                        ),
                                    },
                                    {
                                        className:
                                            vehicleTypes === 'Available'
                                                ? 'ant-segmented-item ant-segmented-item-selected badge-show-bold'
                                                : 'ant-segmented-item badge-show',
                                        label: (
                                            <Badge
                                                count={
                                                    vehicleCounts['available']
                                                }
                                                offset={[0, -8]}
                                            >
                                                Available
                                            </Badge>
                                        ),
                                        value: 'Available',
                                    },
                                ]}
                            /> */}
                            <Menu
                                selectedKeys={[selectedVehicleId]}
                                style={{ border: 'none' }}
                            >
                                {filterVehicles.map((vehicle, idx) => (
                                    <Menu.Item
                                        title={vehicle.title}
                                        onClick={() =>
                                            handleSelectVehicle(vehicle)
                                        }
                                        key={vehicle.id}
                                        style={{
                                            height: 80,
                                            paddingBottom: '8px',
                                            borderBottom:
                                                filterVehicles.length ===
                                                idx + 1
                                                    ? undefined
                                                    : '1px solid #f1f1f1',
                                        }}
                                        // icon={<GiOccupy />}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 6,
                                                right: 12,
                                            }}
                                        >
                                            {vehicle.status === 'available' && (
                                                <Tooltip
                                                // title={
                                                //     'No personnel assigned'
                                                // }
                                                >
                                                    <MdEventAvailable
                                                        style={{
                                                            color: 'green',
                                                            fontSize: 20,
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            {vehicle.status === 'repair' && (
                                                <Tooltip
                                                    title={'Under maintenance'}
                                                >
                                                    <HiWrenchScrewdriver
                                                        style={{
                                                            color: 'orange',
                                                            fontSize: 20,
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            {/* {vehicle.status === 'occupied' && (
                                                <Tooltip title={'Occupied'}>
                                                    <MdEventAvailable
                                                        style={{
                                                            color: 'green',
                                                            fontSize: 20,
                                                        }}
                                                    />
                                                </Tooltip>
                                            )} */}
                                        </div>
                                        <p style={{ margin: 0, fontSize: 16 }}>
                                            {vehicle.title}
                                        </p>
                                        <Typography.Text type="secondary">
                                            {vehicle.plate}
                                        </Typography.Text>{' '}
                                        {' | '}
                                        <Typography.Text type="secondary">
                                            {vehicle.routeName}
                                        </Typography.Text>
                                    </Menu.Item>
                                ))}
                            </Menu>
                        </Layout.Sider>
                        <Layout.Content style={contentStyle}>
                            <Tabs
                                activeKey={currentTab}
                                size="large"
                                onChange={handleTabChange}
                                style={{ border: 'none', marginTop: 12 }}
                                tabBarStyle={{
                                    border: 'none',
                                }}
                                items={[
                                    // 'Active shift',
                                    'Bus Assignment',
                                    'Bus Collection History',
                                    // 'Maintenance log',
                                    // 'Bus Settings',
                                ].map((tabName) => {
                                    // const id = String(i + 1);

                                    return {
                                        label: <span>{tabName}</span>,
                                        key: tabName,
                                    }
                                })}
                            />
                            {currentTab === 'Active shift' && <ActiveTrip />}
                            {currentTab === 'Bus Assignment' && (
                                <TripSchedules status="waiting" />
                            )}
                            {currentTab === 'Bus Collection History' && (
                                <TripSchedules status="done" />
                            )}
                            {currentTab === 'Bus Settings' && (
                                <VehicleContainer isUpdate />
                            )}
                        </Layout.Content>
                    </Layout>
                </Layout.Content>
            </Layout>
        </div>
    )
}

export default VehiclesDriversContainer
