/* eslint-disable no-unused-vars */
import {
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-components'
import { useEffect, useState } from 'react'
import { Button, Card, Col, Grid, Row, Typography, message } from 'antd'
import { useAppDispatch, useAppSelector } from '../../store'
import { capitalize, randomId } from '../utils'
import { Member } from '../../../domain/entities/Member'
import { memberStateInitialValues } from './UpdateMember'
import moment from 'moment'
import { shallowEqual } from 'react-redux'
import { addTeamMember } from '../../redux/team/team.action'
import { CoopMember, TeamMember } from '../../../domain/entities/Team'
import { updateTeamState } from '../../redux/team/team.slice'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
    MEMBERS_REPOSITORY,
    civilStatuses,
} from '../../../data/repositories/constants'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../../boot'

export const teamRoleOptions = [
    {
        label: 'Owner',
        value: 'owner',
    },
    {
        label: 'Editor',
        value: 'editor',
    },
    {
        label: 'Member',
        value: 'viewer',
    },
]

export const getRoleValue = (role: string) => {
    let value = role

    const found = teamRoleOptions.find((opt) => opt.value === role)
    if (found) {
        value = found.label
    }
    return value
}

function AddMember() {
    const [changed, setChanged] = useState<any>(undefined)
    const { styles, email, activeTeam, team, currentTeamMembers } =
        useAppSelector(
            (state) => ({
                styles: state.styles,
                email: state.auth.user.email,
                activeTeam: state.auth.user.activeTeam,
                team: state.team.selectedTeamData,
                currentTeamMembers: state.team.currentTeamMembers,
            }),
            shallowEqual
        )
    const [isEdit, setIsEdit] = useState(false)
    const dispatch = useAppDispatch()
    const [emailRequired, setEmailRequired] = useState(true)
    const [loading, setLoading] = useState(false)
    const [form] = ProForm.useForm()
    function formatNumberWithLeadingZeros(number: number) {
        return String(number ? number : 1).padStart(5, '0')
    }
    const handleFinish = async (values: any) => {
        setLoading(true)
        if (isEdit) {
            handleUpdate(values)
            return
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const payload: Member = {
            ...memberStateInitialValues,
            ...values,
            creation: moment().unix(),
            name: capitalize(`${values.firstName} ${values.lastName}`),
            displayName: capitalize(`${values.firstName} ${values.lastName}`),
            modified: moment().unix(),
            modifiedBy: email,
            accountNumber: `${moment().year()}-${formatNumberWithLeadingZeros(
                team.totalMembers + 1
            )}`,
            status: 'accepted',
            dateAccepted: moment().unix(),
            isRemoved: false,
            memberId: undefined,
            partnerCode: activeTeam,
            role: 'regular_member',
            memberShares: values.initialShare,
            currentSavingsBalance: values.initialSavings,
            birthdate: moment(values.birthdate, 'MMM DD, YYYY').unix(),
            dateOfBirth: moment(values.birthdate, 'MMM DD, YYYY').unix(),
        }
        // eslint-disable-next-line no-console
        // console.log(payload, String(1).padStart(5, '0'))

        const res = await addTeamMember(payload, activeTeam)
        message.success('successfully added member')
        setLoading(false)
        form.resetFields()
        if (typeof res === 'string') {
            payload.id = res
        }
        dispatch(
            updateTeamState({
                currentTeamMembers: [...currentTeamMembers, payload],
            })
        )
        navigate('receivable')
        // onFinish && onFinish()
    }

    const handleUpdate = async (values: any) => {
        const payload: Member = {
            ...values,
            name: capitalize(`${values.firstName} ${values.lastName}`),
            displayName: capitalize(`${values.firstName} ${values.lastName}`),
            modified: moment().unix(),
            modifiedBy: email,
            birthdate: moment(values.birthdate, 'MMM DD, YYYY').unix(),
            dateOfBirth: moment(values.birthdate, 'MMM DD, YYYY').unix(),
        }

        await updateDoc(
            doc(db, MEMBERS_REPOSITORY, params.uid as string),
            payload as any
        )
        message.success('Successfully updated')
        setLoading(false)
        navigate(-1)
    }

    const onValuesChange = (val: any) => {
        const keys = Object.keys(val)
        const key = keys[0]
        if (!changed) {
            setChanged(true)
        }
        if (key && key === 'sendEmail') {
            const value = val[key]
            if (value.length) {
                setEmailRequired(true)
            } else {
                setEmailRequired(false)
            }
        }
    }

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(params)
        if (params.uid) return setIsEdit(true)
        setIsEdit(false)
    }, [params])
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(location)
        if (!isEdit || !location.state) return

        form.setFieldsValue({
            ...location.state,
            birthdate: moment.unix(location.state.birthdate),
        })
    }, [location, isEdit, form])

    const { xs } = Grid.useBreakpoint()
    return (
        <div
            style={{
                paddingTop: 24,
                maxWidth: 936,
            }}
        >
            <br />
            <Row justify={'center'}>
                <Col xs={24} lg={18}>
                    <Card
                        style={{
                            boxShadow: '0 20px 40px rgb(57 76 96 / 15%)',
                        }}
                        bodyStyle={{}}
                        title={
                            <Row>
                                <Col span={8}>
                                    <Button
                                        type="link"
                                        onClick={() => navigate(-1)}
                                    >
                                        <ArrowLeftOutlined
                                            style={{ fontSize: 18 }}
                                        />
                                    </Button>
                                </Col>
                                <Col span={8} style={{ textAlign: 'center' }}>
                                    {isEdit ? 'Update' : 'New'} Member
                                </Col>
                                <Col span={8}></Col>
                            </Row>
                        }
                    >
                        <ProForm<Member>
                            form={form}
                            onFinishFailed={(err) => {
                                if (err.errorFields.length) {
                                    const firstError = err.errorFields[0]
                                    message.destroy()
                                    message.error(firstError.errors)
                                    form.scrollToField(firstError.name, {
                                        behavior: 'smooth',
                                        block: 'center',
                                    })
                                }
                            }}
                            onFinish={handleFinish}
                            onValuesChange={onValuesChange}
                            autoFocusFirstInput
                            submitter={false}
                            style={{}}
                        >
                            <ProForm.Item
                                label={
                                    <Typography.Text
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Basic Information
                                    </Typography.Text>
                                }
                            >
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} lg={8}>
                                        <ProFormText
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required.',
                                                },
                                            ]}
                                            label="First Name"
                                            name="firstName"
                                            width={'md'}
                                        />
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <ProFormText
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required.',
                                                },
                                            ]}
                                            label="Last Name"
                                            name="lastName"
                                            width={'md'}
                                        />
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <ProFormText
                                            label="Middle Name"
                                            name="middleName"
                                            width={'md'}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} sm={24} lg={8}>
                                        <ProFormSelect
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            options={[
                                                {
                                                    label: 'Male',
                                                    value: 'male',
                                                },
                                                {
                                                    label: 'Female',
                                                    value: 'female',
                                                },
                                            ]}
                                            name="gender"
                                            label={'Gender'}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} lg={8}>
                                        <ProFormText
                                            rules={[
                                                {
                                                    required: emailRequired,
                                                    message: 'Invalid email',
                                                    type: 'email',
                                                },
                                            ]}
                                            disabled={isEdit}
                                            label={'Email'}
                                            name="email"
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} lg={8}>
                                        <ProFormText
                                            label={'Phone Number'}
                                            name="phoneNumber"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} lg={8}>
                                        <ProFormDatePicker
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required.',
                                                },
                                            ]}
                                            fieldProps={{
                                                style: {
                                                    width: '100%',
                                                },
                                                format: 'MMM DD, YYYY',
                                            }}
                                            name={'birthdate'}
                                            label="Birthday"
                                        />
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <ProFormSelect
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            options={civilStatuses}
                                            label={'Civil Status'}
                                            name="maritalStatus"
                                        />
                                    </Col>
                                </Row>

                                <ProFormTextArea
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    label={'Address'}
                                    name="address"
                                />
                            </ProForm.Item>

                            <ProForm.Item
                                label={
                                    <Typography.Text
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Membership Information
                                    </Typography.Text>
                                }
                            >
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} lg={8}>
                                        <ProFormDigit
                                            label={'Membership Fee'}
                                            name="membershipFee"
                                        />
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <ProFormDigit
                                            label={'Initial Savings Balance'}
                                            name="initialSavings"
                                        />
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <ProFormDigit
                                            label={'Initial Share Capital'}
                                            name="initialShare"
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Item>
                            {/* <Button
                                htmlType="submit"
                                size="large"
                                loading={loading}
                                // disabled={password.length > 1 && email.length > 1 ? false : true}
                                style={{
                                    fontWeight: 'bold',
                                    // width: '100%',
                                    color: 'white',
                                    backgroundColor: styles.primary,
                                }}
                            >
                                Submit
                            </Button> */}
                        </ProForm>
                    </Card>

                    {changed && (
                        <Row
                            justify={'end'}
                            align={'middle'}
                            wrap={xs}
                            style={{
                                position: 'sticky',
                                bottom: 10,
                                backgroundColor: styles.primary,
                                padding: '12px 24px',
                            }}
                        >
                            <Col>
                                <Row gutter={[12, 12]}>
                                    <Col>
                                        <Button
                                            size="large"
                                            loading={loading}
                                            style={{
                                                border: 'none',
                                            }}
                                            onClick={() => {
                                                if (!isEdit) {
                                                    return form.resetFields()
                                                }
                                                form.setFieldsValue({
                                                    ...location.state,
                                                    birthdate: moment.unix(
                                                        location.state.birthdate
                                                    ),
                                                })
                                            }}
                                            danger
                                        >
                                            Reset
                                        </Button>
                                    </Col>
                                    <Col>
                                        {/* {currentTeamPermission.role === 'viewer' ||
                                currentTeamPermission.status !== 'accepted' ? (
                                    <Button disabled>Save</Button>
                                ) : ( */}
                                        <Button
                                            size="large"
                                            loading={loading}
                                            onClick={() => {
                                                form.validateFields().then(
                                                    () => {
                                                        form.submit()
                                                    }
                                                )
                                            }}
                                            style={{
                                                width: xs ? 125 : 150,
                                                border: 'none',
                                            }}
                                            type="primary"
                                        >
                                            {isEdit ? 'Save Changes' : 'Submit'}
                                        </Button>
                                        {/* )} */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>

            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default AddMember
