import React, { useRef, useState } from 'react'
import { ProFormDateRangePicker } from '@ant-design/pro-components'
import { Row, Col, Segmented, Button, Grid } from 'antd'
import { FaPrint } from 'react-icons/fa'

BookKeeperReports.propTypes = {}

function BookKeeperReports(props) {
    const { xs } = Grid.useBreakpoint()
    const ref = useRef(null)

    const [open, setOpen] = useState(false)
    const [currentFilter, setCurrentFilter] = useState('today')
    const [reports, setReports] = useState<{
        revenue: any[]
        expense: any[]
        start: number
        end: number
        totalExpense: number
        totalCollection: number
        expenseBreakdown: any
        revenueBreakdown: any
        expenseReport: any
        collectionReport: any
    }>({
        revenue: [],
        expense: [],
        start: 0,
        end: 0,
        totalExpense: 0,
        totalCollection: 0,
        expenseBreakdown: [],
        revenueBreakdown: [],
        expenseReport: {},
        collectionReport: {},
    })
    const handlePrint = () => {}
    const onSelect = async (data: any) => {
        setCurrentFilter(data)
    }
    return (
        <div className="page-layout">
            <Row>
                <Col xs={24} lg={12}>
                    <ProFormDateRangePicker
                        fieldProps={{
                            ref: ref,
                            open: open,
                            onOpenChange: (val) => setOpen(val),
                            onChange: (vals, format) => {
                                // eslint-disable-next-line no-console
                                // console.log(vals, format, ref.current)
                                if (!vals) {
                                    return
                                }
                                setReports({
                                    ...reports,
                                    start: vals[0]?.startOf('day').unix() || 0,
                                    end: vals[1]?.endOf('day').unix() || 0,
                                })
                                setCurrentFilter('')
                                setOpen(false)
                                if (ref.current) {
                                    // @ts-ignore
                                    ref.current.blur()
                                }
                            },
                        }}
                    />
                </Col>

                <Col xs={24} lg={12}>
                    {!xs && (
                        <Row justify={'end'}>
                            <Col>
                                <Button
                                    onClick={handlePrint}
                                    icon={
                                        <FaPrint style={{ marginRight: 5 }} />
                                    }
                                    type="primary"
                                    size="large"
                                    shape="round"
                                >
                                    Print to PDF
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default BookKeeperReports
