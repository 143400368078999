import { useEffect, useState } from 'react'
// import PeratiLandingPage from './PeratiLandingPage/PeratiLandingPage'
import Home from './pages/Home'
import { Styles } from './styles'

const LandingHome = () => {
    const [isCompany, setIsCompany] = useState(false)
    useEffect(() => {
        const split = window.location.hostname.split('.')
        const hostName = split[0]
        setIsCompany(hostName !== 'perati')
    }, [])
    // eslint-disable-next-line no-console
    console.log(isCompany)

    return (
        <div>
            <Styles />
            <Home />
            {/* {isCompany && <ClientLandingPage />} */}
        </div>
    )
}

export default LandingHome
