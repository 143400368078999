import { useAppDispatch, useAppSelector } from '../../../app/store'
import { ProCard } from '@ant-design/pro-components'
import { Row, Col, Button, Grid, Statistic } from 'antd'
import { MdPerson } from 'react-icons/md'
import { shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MyPersonnel from '../My/MyTeam'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { useEffect } from 'react'
import { PERSONNEL_REPOSITORY } from '../../../data/repositories/constants'
import { db } from '../../boot'
import { updateTeamState } from '../../redux/team/team.slice'
// import { Trip } from '@/domain/entities/Transport'

const PersonnelContainer = () => {
    const { xs } = Grid.useBreakpoint()
    const { styles, totalPersonnel, activeTeam } = useAppSelector(
        (state) => ({
            styles: state.styles,
            email: state.auth.user.email,
            activeTeam: state.team.selectedTeamData.partnerCode,
            totalPersonnel: state.team.selectedTeamData.totalPersonnel,
        }),
        shallowEqual
    )
    const navigate = useNavigate()
    const handleNewDriver = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        navigate(`new-personnel`)
    }
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!activeTeam) return
        const ref = collection(db, PERSONNEL_REPOSITORY)
        const q = query(
            ref,
            where('partnerCode', '==', activeTeam),
            where('isRemoved', '==', false)
        )

        const unsub = onSnapshot(q, async (docSnap) => {
            // const source = docSnap.metadata.fromCache ? 'local cache' : 'server'
            const data = docSnap.docs.map((docSnaps) => {
                return {
                    ...docSnaps.data(),
                    id: docSnaps.id,
                } as any
            })
            // const promises = await Promise.all(data)
            dispatch(
                updateTeamState({
                    currentEmployees: data,
                })
            )
        })
        return () => unsub()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam])
    return (
        <div>
            <div className="page-layout">
                <ProCard.Group direction={'row'}>
                    <ProCard
                        style={{
                            maxHeight: 100,
                            cursor: 'pointer',
                        }}
                        bodyStyle={{
                            maxHeight: 100,
                            padding: 16,
                        }}
                        onClick={handleNewDriver}
                        colSpan={xs ? 100 : '200px'}
                    >
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <MdPerson
                                    style={{
                                        fontSize: xs ? 16 : 28,
                                        color: styles.primary,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <Button
                                    style={{
                                        color: styles.primary,
                                        fontSize: xs ? 16 : 20,
                                        padding: 0,
                                    }}
                                    type={'text'}
                                    onClick={handleNewDriver}
                                >
                                    New Employee
                                </Button>
                            </Col>
                        </Row>
                    </ProCard>
                    <ProCard
                        bordered
                        style={{
                            cursor: 'default',
                            maxWidth: 200,
                            borderRadius: 0,
                            height: '100%',
                        }}
                        bodyStyle={{
                            padding: xs ? 12 : undefined,
                        }}
                        subTitle={<div></div>}
                        title={'Total Employee'}
                    >
                        <Statistic
                            value={totalPersonnel || 0}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                            // valueRender={(node) => {
                            //     return (
                            //         <Row>
                            //             <Col>{node}</Col>
                            //             <Col>
                            //                 <Button
                            //                     type="link"
                            //                     onClick={() => navigate('all')}
                            //                 >
                            //                     View All
                            //                 </Button>
                            //             </Col>
                            //         </Row>
                            //     )
                            // }}
                        />
                    </ProCard>
                </ProCard.Group>
            </div>
            <br />
            <MyPersonnel />
        </div>
    )
}

export default PersonnelContainer
