import { SystemSettings } from '@/domain/entities/SystemSettings'
import moment from 'moment'

export function calculateWorkTime(
    timeIn: string,
    timeOut: string,
    hrSettings?: SystemSettings['hr'],
    isBusPersonnel?: boolean
) {
    const format = 'HH:mm'

    // Convert all times to Moment.js objects
    const actualTimeIn = moment(timeIn, format)
    const actualTimeOut = moment(timeOut, format)

    // const expectedTimeOut = moment(hrSettings.firstShiftOut, format);

    // Calculate total time rendered in minutes
    const renderedTime = moment
        .duration(actualTimeOut.diff(actualTimeIn))
        .asHours()

    // Calculate the number of minutes late for clocking in.
    // If the employee clocked in early, this will be negative.
    let minutesLate = 0
    if (hrSettings && hrSettings.firstShiftIn) {
        const expectedTimeIn = moment(
            isBusPersonnel ? hrSettings.firstShiftIn : hrSettings.officeShiftIn,
            format
        )
        // eslint-disable-next-line no-console
        // console.log(expectedTimeIn.format(format), actualTimeIn.format(format))

        if (actualTimeIn.isAfter(expectedTimeIn)) {
            minutesLate = moment
                .duration(actualTimeIn.diff(expectedTimeIn))
                .asMinutes()
        }
    }

    return {
        hours: Math.round((renderedTime + Number.EPSILON) * 10) / 10,
        minutesLate: Math.round((minutesLate + Number.EPSILON) * 10) / 10,
    }
}
