export const randomId = (length: number) => {
    const randomChars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
        result += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
        )
    }
    return result
}

export const capitalize = (str: string): string => {
    if (typeof str !== 'string') return ''
    if (str.includes(' ')) {
        const words = str.split(' ')

        return words
            .map((word) => {
                if (!word[0]) return ''
                return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
    } else {
        return str.replace(str[0], str[0].toUpperCase())
    }
}
