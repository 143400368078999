import { ShareFirebaseRepository } from '../repositories/share.repository'
import { CalculateShare, Share } from '../entities/Share'

export class ShareService {
    shareRepo: ShareFirebaseRepository

    constructor(ir: ShareFirebaseRepository) {
        this.shareRepo = ir
    }

    async AddShare(payload: Omit<Share, 'id'>) {
        return await this.shareRepo.AddShare(payload)
    }

    async getTeamShares(partnerCode: string) {
        return await this.shareRepo.getTeamShares(partnerCode)
    }

    async DeleteShare(
        payload: CalculateShare & {
            id: string
        }
    ) {
        return await this.shareRepo.DeleteShare(payload)
    }

    async GetShare(id: string) {
        return await this.shareRepo.GetShare(id)
    }

    async UpdateShare(payload: Share, prevShare: CalculateShare) {
        return await this.shareRepo.UpdateShare(payload, prevShare)
    }
    async GetAllMemberShare(payload: {
        memberId: string
        partnerCode: string
    }) {
        return await this.shareRepo.GetAllMemberShare(payload)
    }
}
