import { DownloadOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row, Table } from 'antd'
import React, { useState } from 'react'
import { useAppSelector } from '../../store'
import { shallowEqual } from 'react-redux'

interface PayslipPDFProps {
    data: any
}

function PayslipPDF({ data }: PayslipPDFProps) {
    const [open, setOpen] = useState(false)
    const { name } = useAppSelector(
        (state) => ({
            name: state.team.selectedTeamData.name,
            // activeTeam: state.auth.user.activeTeam,
        }),
        shallowEqual
    )
    const handleDownloadPayslip = () => {
        // eslint-disable-next-line no-console
        console.log(data, name)
        setOpen(true)
    }

    const handleCancelDownload = () => {
        setOpen(false)
    }
    return (
        <div>
            <Modal
                footer={false}
                open={open}
                onCancel={handleCancelDownload}
                centered
                destroyOnClose
                style={{
                    width: '210mm',
                }}
                width={'225mm'}
            >
                <br />
                <div
                    id="divToPrint"
                    style={{
                        backgroundColor: '#fff',
                        width: '210mm',
                        minHeight: '297mm',
                        overflow: 'hidden',
                        border: '1px solid #dfdfdf',
                    }}
                >
                    <Row>
                        <Col span={12}>
                            <Table />
                        </Col>
                        <Col span={12}>
                            <Table />
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Button
                key="download"
                size="large"
                type="link"
                onClick={() => handleDownloadPayslip()}
            >
                <DownloadOutlined
                    style={{
                        fontSize: 24,
                    }}
                />
            </Button>
        </div>
    )
}

export default PayslipPDF
