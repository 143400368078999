import styled from 'styled-components'

export const Content = styled('p')`
    margin-top: 1.5rem;
`

export const Container = styled('div')`
    position: relative;
    max-width: 700px;
`

export const TextWrapper = styled('div')`
    border-radius: 3rem;
    max-width: 400px;
`
