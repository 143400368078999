import { ProCard } from '@ant-design/pro-components'
import {
    Row,
    Col,
    Typography,
    Form,
    Input,
    Button,
    Divider,
    Grid,
    Alert,
} from 'antd'
import React, { useState } from 'react'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from '../../store'
// @ts-ignore
import { useNavigate } from 'react-router-dom'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../boot'

const ForgotPage = () => {
    const { xs } = Grid.useBreakpoint()
    const navigate = useNavigate()
    const { isLoading, styles } = useAppSelector(
        (state) => ({
            styles: state.styles,
            isLoading: state.auth.loading,
        }),
        shallowEqual
    )
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState<any>('')
    const [errMessage, setErrMessage] = useState('')
    const [form] = Form.useForm()

    const handleFinish = async () => {
        setErrMessage('')
        try {
            await sendPasswordResetEmail(auth, email)
            setErrMessage('success')
            setEmail('')
            setStatus('')
        } catch (err: any) {
            setErrMessage(err.message)
            // message.error(err.message)
        }
        form.resetFields()
        // await dispatch(login(data))
        // navigate('members')
    }
    function validateEmail(input: string) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        if (input.match(validRegex)) {
            setStatus(undefined)
            return true
        } else {
            setStatus('error')
            return false
        }
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: xs ? undefined : 20,
                height: '100%',
                backgroundColor: 'white',
                overflow: 'hidden',
            }}
        >
            <ProCard
                bordered
                style={{
                    width: !xs ? 450 : '100%',
                    borderRadius: 5,
                    boxShadow: '0 20px 40px rgb(57 76 96 / 15%)',
                }}
                bodyStyle={{
                    padding: 0,
                }}
            >
                <Row
                    align={'middle'}
                    style={{
                        padding: '18px 24px 0',
                        backgroundColor: '#fbfbfb',
                    }}
                >
                    <Col>
                        <Typography.Title level={3}>
                            Find your account
                        </Typography.Title>
                    </Col>
                </Row>
                <div
                    style={{
                        width: '100%',
                        color: 'white',
                        padding: '24px',
                    }}
                >
                    {errMessage && (
                        <div
                            style={{
                                width: '102%',
                                display: 'flex',
                                marginLeft: -3,
                            }}
                        >
                            <Alert
                                type={
                                    errMessage === 'success'
                                        ? 'success'
                                        : 'error'
                                }
                                description={
                                    errMessage === 'success' ? (
                                        <span>
                                            Password reset link successfully
                                            sent to your email. Don't forget to{' '}
                                            <b>check your spam folder!</b>
                                        </span>
                                    ) : (
                                        'Your search did not return any results. Please try again with other information.'
                                    )
                                }
                                message={
                                    <Typography.Text
                                        style={{ fontWeight: 'bolder' }}
                                    >
                                        {errMessage === 'success'
                                            ? 'Email found!'
                                            : 'No search results'}
                                    </Typography.Text>
                                }
                                style={{ marginBottom: 12, width: '100%' }}
                            />
                        </div>
                    )}
                    <Typography.Paragraph style={{ fontSize: 17 }}>
                        Please enter your email to search for your account.
                    </Typography.Paragraph>
                    <Input
                        size="large"
                        autoFocus
                        style={{
                            padding: '16px 0 16px 16px',
                            fontSize: 18,
                        }}
                        type="email"
                        value={email}
                        onPressEnter={handleFinish}
                        placeholder={'user@example.com'}
                        onChange={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            const value = event.currentTarget.value.replace(
                                ' ',
                                ''
                            )
                            if (value.includes(' ')) return
                            validateEmail(value)
                            setEmail(value)
                        }}
                        status={status}
                        checked
                    />
                    {/* </Form.Item> */}
                    {/*{errMessage !== null && showAuthError ? <Alert message={errMessage}></Alert> : null}*/}
                </div>
                <Divider style={{ margin: 0 }} />
                <div style={{ padding: '16px 24px' }}>
                    <Row gutter={[24, 24]} justify="end">
                        <Col>
                            <Button
                                disabled={isLoading}
                                size="large"
                                onClick={() => navigate('/login')}
                                style={{ borderRadius: 6 }}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                size="large"
                                loading={isLoading}
                                style={
                                    status !== 'error'
                                        ? {
                                              fontWeight: 'bold',
                                              color: 'white',
                                              backgroundColor: styles.primary,
                                              borderRadius: 6,
                                          }
                                        : {}
                                }
                                disabled={status === 'error'}
                                onClick={handleFinish}
                            >
                                Search
                            </Button>
                        </Col>
                    </Row>
                </div>

                {/* <Typography.Link
                            onClick={() => navigate('/register/admin')}
                        >
                            <Button
                                type="text"
                                loading={isLoading}
                                shape={'round'}
                                style={{ color: '#06c' }}
                            >
                                FOR ADMINISTRATOR
                            </Button>
                        </Typography.Link> */}
            </ProCard>
        </div>
    )
}
export default ForgotPage
