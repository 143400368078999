import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, message } from 'antd'
import { useAppDispatch, useAppSelector } from '../store'
import { shallowEqual } from 'react-redux'
import { isLoggedIn, setAuthState } from '../redux/auth/auth.slice'
import PeratiHeader from '../pages/Home/PeratiLandingPage/PeratiHeader'
import ClientHeader from '../pages/Home/ClientLandingPage/ClientHeader'
// import { Footer20DataSource } from '../pages/Home/ClientLandingPage/data.source'
// import Footer2 from '../pages/Home/ClientLandingPage/Footer2'
import { getTeam } from '../redux/team/team.action'
import { updateTeamState } from '../redux/team/team.slice'
import { getUserRoute } from './helpers'
// import Header from '../pages/Home/components/Header'
import Footer from '../pages/Home/components/Footer'

const PublicRoute = (prop: any) => {
    const split = window.location.hostname.split('.')
    const hostName = split[0]
    const isCompany = hostName !== 'perati'
    const { xs } = Grid.useBreakpoint()
    // const location = useLocation()

    const { loggedIn, user, errMessage, activeTeam } = useAppSelector(
        (state) => ({
            loggedIn: state.auth.isLoggedIn,
            user: state.auth.user,
            errMessage: state.auth.errMessage,
            activeTeam: state.auth.user?.activeTeam || '',
        }),
        shallowEqual
    )
    // eslint-disable-next-line no-console
    console.log(user)

    const navigate = useNavigate()
    // eslint-disable-next-line no-unused-vars
    const location = useLocation()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(
            isLoggedIn(async (callback) => {
                if (!callback) return
                // eslint-disable-next-line no-console
                console.log(callback)

                await dispatch(
                    setAuthState({
                        user: callback,
                        isLoggedIn: true,
                    })
                )

                if (!callback.activeTeam) return
                // navigate(
                //     `/teams/${callback.activeTeam || callback.partnerCode}`,
                //     {
                //         replace: true,
                //     }
                // )
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(user)

        if (!user) return

        if (!user.role) {
            message.error('Missing Role')
        }
        // @ts-ignore
        if (user.role && loggedIn) {
            // const search = location.search || ''
            // const queryParamsString = location.search.substring(1), // remove the "?" at the start
            //     searchParams = new URLSearchParams(queryParamsString),
            //     partnerCode = searchParams.get('partnerCode'),
            //     invitedBy = searchParams.get('invitedBy')
            // if (invitedBy && partnerCode) {
            //     navigate(`/invite/${partnerCode}${search}`, {
            //         replace: true,
            //     })
            //     return
            // }
            // @ts-ignore

            // @ts-ignore
            const route = getUserRoute(user.role)
            if (route === 'err') {
                navigate('/report-issues')
                return
            }
            const id = user.id || user.uid
            if (user.role === 'regular_member' && id) {
                navigate(`/app/u/${id}`, {
                    replace: true,
                })
                return
            }
            navigate(`/app/${route}/home`, {
                replace: true,
                state: {
                    main: true,
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn, user, activeTeam])
    useEffect(() => {
        if (errMessage) {
            message.error(errMessage)
        }
    }, [errMessage])

    //   const split = window.location.hostname.split('.')
    const host = split[0]
    useEffect(() => {
        if (!user.activeTeam) return
        const getTeamData = async () => {
            // const queryParamsString = location.search.substring(1), // remove the "?" at the start
            //     searchParams = new URLSearchParams(queryParamsString),
            //     partnerCode = searchParams.get('partnerCode')
            // invitedBy = searchParams.get('invitedBy')

            const teamData = await getTeam(user.activeTeam)

            dispatch(
                updateTeamState({
                    selectedTeamData: teamData,
                })
            )
            // setInviter(teamData)
        }
        getTeamData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.activeTeam])

    useEffect(() => {
        const email = localStorage.getItem('email')
        if (!email) return

        localStorage.removeItem('email')
        let time = setTimeout(() => {
            navigate(`login`, {
                state: {
                    email,
                },
            })
        }, 2000)

        return () => clearTimeout(time)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn])
    return (
        <div
            style={{
                position: 'relative',
                height: '100vh',
                width: '100vw',
                overflow: 'auto',
            }}
        >
            {!isCompany && <PeratiHeader isMobile={xs || false} />}
            {isCompany && <ClientHeader isMobile={xs} />}
            <div
                style={{
                    position: 'relative',
                }}
            >
                {prop.children}
            </div>

            {location.pathname.includes('store') ? null : <Footer />}
        </div>
    )
}

export default PublicRoute
