import { createSlice } from '@reduxjs/toolkit'
import { Borrow } from '../../../domain/entities/Borrow'
import {
    borrow,
    deleteBorrower,
    getBorrowers,
    updateBorrower,
} from './borrow.actions'

interface InitialState {
    borrowers: Borrow[]
}

const initialState: InitialState = {
    borrowers: [],
}

export const borrowSlice = createSlice({
    name: 'borrow',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(borrow.fulfilled, (state, action) => {
            state.borrowers = [...state.borrowers, action.payload]
        })
        builder.addCase(getBorrowers.fulfilled, (state, action) => {
            state.borrowers = action.payload
        })
        builder.addCase(updateBorrower.fulfilled, (state, action) => {
            const borrowersCopy = [...state.borrowers]

            const idx = borrowersCopy.findIndex(
                (borrower) => borrower.id === action.payload.id
            )
            const { id, ...newState } = action.payload
            state.borrowers[idx] = {
                ...state.borrowers[idx],
                ...newState,
            }
            // state.borrowers = borrowersCopy
        })
        builder.addCase(deleteBorrower.fulfilled, (state, action) => {
            const borrowersCopy = [...state.borrowers]

            const idx = borrowersCopy.findIndex(
                (borrower) => borrower.id === action.payload
            )
            state.borrowers.splice(idx, 1)
            // state.borrowers = borrowersCopy
        })
    },
})
