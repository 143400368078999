/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { shallowEqual } from 'react-redux'
import { ProCard } from '@ant-design/pro-components'
import {
    Button,
    Col,
    Grid,
    Input,
    message,
    Modal,
    Row,
    Statistic,
    Tooltip,
    Typography,
    Table as AntTable,
    Popconfirm,
} from 'antd'
import {
    DeleteOutlined,
    EditOutlined,
    SearchOutlined,
    // PlusSquareOutlined,
} from '@ant-design/icons'
import NewExpense from './components/NewExpense'
import {
    // getSystemConfig,
    updateSystemState,
} from '../../redux/system.slice'
import NewExpenseCategory from './components/NewExpenseCategory'
import { expensesColumns } from './utils'
import { Expense } from '../../../domain/entities/SystemSettings'
import UpdateExpense from './components/UpdateExpense'
import Table from '../../components/Table'
import YearSelection from '../Reports/MembersReceivableReport/components/YearSelection'
import { updateExpenseState } from '../../redux/expense/expense.slice'
import { GiExpense, GiPayMoney } from 'react-icons/gi'
import {
    collection,
    query,
    where,
    onSnapshot,
    doc,
    deleteDoc,
    limit,
    orderBy,
} from 'firebase/firestore'
import { db } from '../../boot'
import { EXPENSE_REPOSITORY } from '../../../data/repositories/constants'
import NewCollection from './components/NewCollection'
import {
    VictoryContainer,
    VictoryPie,
    VictoryLabel,
    VictoryLegend,
} from 'victory'
import moment from 'moment'
import NewCapEx from './components/NewCapEx'
// import { updateSharesState } from '../../redux/shares/shares.slice'

function ExpensesContainer() {
    const {
        styles,
        expenses,
        totalExpenses,
        totalCollection,
        activeTeam,
        expenseCategories,
        collectionCategories,
        capExCategories,
    } = useAppSelector(
        (state) => ({
            styles: state.styles,
            totalExpenses:
                state.team.selectedTeamData?.financialPerformance?.totalExpense,
            totalCollection:
                state.team.selectedTeamData?.financialPerformance
                    ?.totalCollection || 0,
            expenses: state.expense.expenses,
            activeTeam: state.team.selectedTeamData.partnerCode,
            expenseCategories: state.team.selectedTeamData.expenseCategories,
            collectionCategories:
                state.team.selectedTeamData?.collectionCategories || [],
            capExCategories: state.team.selectedTeamData?.capExCategories || [],
        }),
        shallowEqual
    )

    const [categories] = useState(
        [
            ...expenseCategories,
            ...collectionCategories,
            ...capExCategories,
        ].sort((a, b) => a.localeCompare(b))
    )
    const [updateExpenseData, setUpdateExpenseData] = useState<any>({
        show: false,
    })

    // const [yearlyExpenses, setYearlyExpenses] = useState<number>(0)
    // const [yearlyExpense, setYearlyExpense] = useState<Expense[]>([])
    // const [toSearch] = useState('')
    const dispatch = useAppDispatch()
    const handleNewExpense = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch(
            updateSystemState({
                showNewExpense: true,
            })
        )
    }
    const handleEditExpense = (rowData: Expense) => {
        setUpdateExpenseData({
            ...rowData,
            show: true,
        })
    }

    const handleCancelUpdateExpense = () => {
        setUpdateExpenseData({
            show: false,
        })
    }
    const handleDeleteExpense = async (rowData: Expense) => {
        const ref = doc(db, EXPENSE_REPOSITORY, rowData.id as string)
        // await updateDoc(doc(db, SAVINGS_REPOSITORY, id), {
        //     isRemoved: true,
        //     modified: moment().unix(),
        //     modifiedBy: auth.currentUser?.uid,
        // })
        // await this.calculateMemberSaving(payload, true)
        await deleteDoc(ref)
        message.success('Successfully deleted')
        handleCancelUpdateExpense()
    }

    // useEffect(() => {
    //     setYearlyExpenses(overallExpenses[currentYear]['Annual'] || 0)
    // }, [currentYear, overallExpenses])

    // useEffect(() => {
    //     setYearlyExpense(
    //         expenses.filter((expense: Expense) => {
    //             const expenseYear = moment
    //                 .unix(expense.releaseDate)
    //                 .format('YYYY')
    //             if (expenseYear === currentYear) return true

    //             return false
    //         })
    //     )
    // }, [currentYear, expenses])

    // useEffect(() => {
    //     dispatch(getTeamExpenses(activeTeam))
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [activeTeam])

    useEffect(() => {
        if (!activeTeam) return
        // dispatch(getTeamSavings(activeTeam))
        const ref = collection(db, EXPENSE_REPOSITORY)
        const q = query(
            ref,
            where('partnerCode', '==', activeTeam),
            where('isRemoved', '==', false),
            orderBy('creation', 'desc')
            // limit(10)
        )
        const unsub = onSnapshot(q, (doc) => {
            const data = doc.docs.map((docSnap) => ({
                ...docSnap.data(),
                id: docSnap.id,
            }))

            dispatch(
                updateExpenseState({
                    name: 'expenses',
                    value: data,
                })
            )
        })

        return () => unsub()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam])
    const { xs } = Grid.useBreakpoint()
    const [showCollection, setShowCollection] = useState({
        show: false,
        data: undefined,
    })

    const [showCapEx, setShowCapEx] = useState({
        show: false,
        data: undefined,
    })

    const handleShowNewCollection = () => {
        setShowCollection({
            ...showCollection,
            show: true,
        })
    }
    const handleCloseNewCollection = () => {
        setShowCollection({
            ...showCollection,
            show: false,
        })
    }

    const handleShowNewCapEx = () => {
        setShowCapEx({
            ...showCapEx,
            show: true,
        })
    }
    const handleCloseNewCapEx = () => {
        setShowCapEx({
            ...showCapEx,
            show: false,
        })
    }

    const [data, setData] = useState<any>([])

    useEffect(() => {
        const expensObj: any = {}
        const objsArrs: any = []

        expenses.forEach((expense) => {
            if (!expensObj[expense.category]) {
                expensObj[expense.category] = 0
            }
            // objsArrs.push([expense.title, expense.amount])
            expensObj[expense.category] += expense.amount
        })
        const keys = Object.keys(expensObj)
        const mapped = keys.map((key) => {
            if (expensObj[key] < 30000) return false

            const label = expensObj[key] / totalExpenses
            const beutified = (label * 100).toFixed(2)
            return {
                x: key,
                y: expensObj[key],
                label: `${beutified}%`,
            }
        })
        // console.log(objsArrs, mapped)
        setData(mapped.filter(Boolean).sort((a: any, b: any) => b.y - a.y))
        // setData(objsArrs.map(([driverId, revenue]: any) => {
        //     return { x: driverId, y: revenue }
        // })
        // .sort((a: any, b: any) => b.y - a.y))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expenses])
    const middleIndex = Math.floor(data.length / 2)

    const firstHalf = data.slice(0, middleIndex)
    const secondHalf = data.slice(middleIndex)
    const [filteredExpenses, setFilteredExpenses] = useState<any>(expenses)
    const [toSearch, setToSearch] = useState('')

    const handleSearch = (e: string) => {
        setToSearch(e)
    }

    const [selectedRows, setSelectedRows] = useState<any>([])
    const [selectedKeys, setSelectedKeys] = useState<any[]>([])
    useEffect(() => {
        let timeout: any
        if (toSearch) {
            timeout = setTimeout(() => {
                const filtered = [...expenses].filter((expense: Expense) => {
                    const splittedTitle = ((expense?.title as string) || '')
                        .toLowerCase()
                        .replace(/ /g, '')
                    const splittedEmail = ((expense?.purpose as string) || '')
                        .toLowerCase()
                        .replace(/ /g, '')
                    const clearedAmount = (
                        typeof expense.amount === 'number'
                            ? expense.amount.toFixed(2)
                            : ''
                    ).toLowerCase()
                    const clearedReference = (
                        expense?.referenceNumber || ''
                    ).toLowerCase()
                    const search = (toSearch || '')
                        .toLowerCase()
                        .replace(/ /g, '')
                    return (
                        splittedTitle.includes(search) ||
                        splittedEmail.includes(search) ||
                        clearedAmount.includes(search) ||
                        clearedReference.includes(search)
                    )
                })
                setFilteredExpenses(filtered)
            }, 100)
        } else {
            setFilteredExpenses(expenses)
        }
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [expenses, toSearch, activeTeam])
    const cachedFilters = localStorage.getItem('expenseFilters')
    // console.log(cachedFilters);

    const filters =
        typeof cachedFilters === 'string' && cachedFilters !== 'undefined'
            ? JSON.parse(cachedFilters)
            : []
    const [currentFilters, setCurrentFilters] = useState(filters)
    const onBulkDelete = async () => {
        // console.log(selectedRows)
        const resp = selectedRows.map(async (expense: any) => {
            const ref = doc(db, EXPENSE_REPOSITORY, expense.id)
            await deleteDoc(ref)
            message.success(`Successfully deleted ${expense.title}`)
        })
        await Promise.all(resp)
        setSelectedKeys([])
        setSelectedRows([])
    }
    return (
        <div className="page-layout">
            <NewExpenseCategory />
            <NewExpense />
            {showCollection.show && (
                <NewCollection onClose={handleCloseNewCollection} />
            )}
            {showCapEx.show && (
                <NewCapEx
                    title={
                        <Typography.Title level={3}>
                            New Capital Expenditure
                        </Typography.Title>
                    }
                    onClose={handleCloseNewCapEx}
                />
            )}
            {updateExpenseData.show && (
                <UpdateExpense
                    data={updateExpenseData}
                    onCancel={handleCancelUpdateExpense}
                />
            )}
            <YearSelection />
            {xs && <br />}
            <Row gutter={[12, 12]}>
                <Col lg={6} xs={12}>
                    <ProCard
                        hoverable
                        bordered
                        style={{
                            maxHeight: 100,
                            cursor: 'pointer',
                        }}
                        bodyStyle={{
                            padding: 16,
                        }}
                        onClick={handleNewExpense}
                        colSpan={xs ? 100 : '150px'}
                    >
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <GiExpense
                                    style={{
                                        fontSize: xs ? 16 : 28,
                                        color: styles.primary,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <Button
                                    style={{
                                        color: styles.primary,
                                        fontSize: xs ? 16 : 16,
                                        padding: 0,
                                    }}
                                    type={'text'}
                                    onClick={handleNewExpense}
                                >
                                    Operating
                                    {xs ? <br /> : ' '}
                                    Expenditures
                                </Button>
                            </Col>
                        </Row>
                    </ProCard>
                </Col>
                <Col lg={6} xs={12}>
                    <ProCard
                        hoverable
                        bordered
                        style={{
                            maxHeight: 100,
                            cursor: 'pointer',
                        }}
                        bodyStyle={{
                            padding: 16,
                        }}
                        onClick={handleShowNewCapEx}
                        colSpan={xs ? 100 : '150px'}
                    >
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <GiExpense
                                    style={{
                                        fontSize: xs ? 16 : 28,
                                        color: styles.primary,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <Button
                                    style={{
                                        color: styles.primary,
                                        fontSize: xs ? 16 : 16,
                                        padding: 0,
                                    }}
                                    type={'text'}
                                    onClick={handleShowNewCapEx}
                                >
                                    Capital
                                    {xs ? <br /> : ' '}
                                    Expenditures
                                </Button>
                            </Col>
                        </Row>
                    </ProCard>
                </Col>

                <Col lg={6} xs={12}>
                    <ProCard
                        hoverable
                        bordered
                        style={{
                            maxHeight: 100,
                            cursor: 'pointer',
                        }}
                        bodyStyle={{
                            padding: 16,
                        }}
                        onClick={handleShowNewCollection}
                        colSpan={xs ? 100 : '150px'}
                    >
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <GiPayMoney
                                    style={{
                                        fontSize: xs ? 16 : 28,
                                        color: styles.primary,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justify={'center'} align={'middle'}>
                            <Col>
                                <Button
                                    style={{
                                        color: styles.primary,
                                        fontSize: 16,
                                        padding: 0,
                                    }}
                                    type={'text'}
                                    onClick={handleShowNewCollection}
                                >
                                    Operating Revenue
                                </Button>
                            </Col>
                        </Row>
                    </ProCard>
                </Col>

                {/* <ProCard
                    hoverable
                    bordered
                    style={{
                        maxHeight: 100,
                        cursor: 'pointer',
                    }}
                    bodyStyle={{
                        padding: 16,
                    }}
                    onClick={handleNewExpense}
                    colSpan={xs ? 100 : '150px'}
                >
                    <Row justify={'center'} align={'middle'}>
                        <Col>
                            <PlusSquareOutlined
                                style={{
                                    fontSize: xs ? 16 : 28,
                                    color: styles.primary,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row justify={'center'} align={'middle'}>
                        <Col>
                            <Button
                                style={{
                                    color: styles.primary,
                                    fontSize: xs ? 16 : 20,
                                    padding: 0,
                                }}
                                type={'text'}
                                onClick={handleNewExpense}
                            >
                                Petty Cash
                            </Button>
                        </Col>
                    </Row>
                </ProCard> */}
                <Col lg={6} xs={12}>
                    {/* <ProCard
                        colSpan={xs ? 100 : '200px'}
                        bordered
                        style={{
                            maxHeight: 100,
                            cursor: 'unset',
                            textAlign: 'center',
                        }}
                        bodyStyle={{
                            padding: 16,
                            maxHeight: 100,
                        }}
                    >
                        <Statistic
                            title="Total Expenses"
                            value={totalExpenses}
                            prefix="₱"
                            precision={2}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                        />
                    </ProCard> */}
                </Col>
                <Col lg={6} xs={12}>
                    {/* <ProCard
                        colSpan={xs ? 100 : '200px'}
                        bordered
                        style={{
                            maxHeight: 100,
                            cursor: 'unset',
                            textAlign: 'center',
                        }}
                        bodyStyle={{
                            padding: 16,
                            maxHeight: 100,
                        }}
                    >
                        <Statistic
                            title="Total Collection"
                            value={totalCollection}
                            prefix="₱"
                            precision={2}
                            valueStyle={{
                                fontSize: xs ? 16 : undefined,
                            }}
                        />
                    </ProCard> */}
                </Col>
            </Row>
            <div style={{ height: '20px' }} />

            {/* <div style={{ overflow: 'auto' }}> */}
            <Row>
                <Col md={12} xs={24}>
                    <Input
                        value={toSearch}
                        onChange={(e) => handleSearch(e.target.value)}
                        allowClear
                        placeholder={'Search title/category/amount/purpose'}
                        suffix={
                            <Button icon={<SearchOutlined />} type="link" />
                        }
                        // addonBefore={xs ? null : <>Search name</>}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {selectedRows.length ? (
                        <div style={{ padding: 12 }}>
                            <Popconfirm
                                title={`Are you sure to delete ${selectedRows.length} item/s?`}
                                onConfirm={onBulkDelete}
                            >
                                <Button shape="round" danger>
                                    Bulk Delete
                                </Button>
                            </Popconfirm>
                        </div>
                    ) : null}
                </Col>
            </Row>
            <Table
                scroll={{ x: 800 }}
                dataSource={filteredExpenses}
                onChange={(pagination: any, filters: any) => {
                    // eslint-disable-next-line no-console
                    // console.log(filters);
                    localStorage.setItem(
                        'expenseFilters',
                        JSON.stringify(filters['category'])
                    )
                    setCurrentFilters(filters['category'])
                }}
                rowSelection={{
                    onChange(selectedRowKeys, rows, info) {
                        // eslint-disable-next-line no-console
                        // console.log(selectedRowKeys)
                        setSelectedRows(rows)
                        setSelectedKeys(selectedRowKeys)
                    },
                    selectedRowKeys: selectedKeys,
                }}
                columns={[
                    {
                        title: 'Date',
                        dataIndex: 'requestDate',
                        width: 100,
                        sorter: (a, b) => b.requestDate - a.requestDate,
                        renderText: (
                            text: any
                            // record: MemberReceivable,
                            // index: number,
                            // action: ProCoreActionType
                        ) => {
                            return `${moment.unix(text).format('MMM DD, YYYY')}`
                        },
                    },
                    {
                        title: 'Category',
                        dataIndex: 'category',
                        order: 2,
                        // sorter: (a, b) => a.category.localeCompare(b.category),
                        filters: categories.map((pos) => ({
                            text: pos,
                            value: pos,
                        })),
                        onFilter: (value, record: any) =>
                            record.category.indexOf(value) === 0,
                        width: 150,
                        // onFilter: (value: string, record: any) =>
                        //     record.role.indexOf(value) === 0,
                        sorter: (a: Expense, b: Expense) =>
                            a.category.localeCompare(b.category),
                        // specify the condition of filtering result
                        // here is that finding the name started with `value`
                        // render: (text: string) => {
                        //     return <div>{text}</div>
                        // },
                        filteredValue: currentFilters,
                    },
                    {
                        title: 'Reference No.',
                        dataIndex: 'referenceNumber',
                        // sorter: (a, b) => a.referenceNumber.localeCompare(b.referenceNumber),
                        // defaultSortOrder: "descend"
                    },
                    {
                        title: 'Title',
                        dataIndex: 'title',
                        width: 150,
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        sorter: (a, b) => b.amount - a.amount,
                        renderText: (
                            text: any
                            // record: MemberReceivable,
                            // index: number,
                            // action: ProCoreActionType
                        ) => {
                            return (
                                <Statistic
                                    value={text}
                                    prefix={
                                        <Typography.Text>₱</Typography.Text>
                                    }
                                    prefixCls={'₱'}
                                    precision={2}
                                />
                            )
                        },
                    },
                    {
                        title: 'Purpose',
                        dataIndex: 'purpose',
                        width: 200,
                        render(text) {
                            return (
                                <Typography.Paragraph
                                    ellipsis={{
                                        rows: 3,
                                        expandable: true,
                                        symbol: 'More',
                                    }}
                                >
                                    {text}
                                </Typography.Paragraph>
                            )
                        },
                    },

                    {
                        title: 'Actions',
                        key: 'option',
                        valueType: 'option',
                        render: (_, row, _index) => {
                            return [
                                <Tooltip
                                    title={'Update expense details'}
                                    key={'eduiit-je'}
                                >
                                    <Button
                                        type="link"
                                        onClick={() => handleEditExpense(row)}
                                    >
                                        <EditOutlined />
                                    </Button>
                                </Tooltip>,

                                <Tooltip
                                    title={'Delete expense'}
                                    key={'delete-expense'}
                                    placement={'top'}
                                >
                                    <DeleteOutlined
                                        onClick={() => {
                                            Modal.confirm({
                                                onOk: () =>
                                                    handleDeleteExpense(row),
                                                title: 'Delete expense',
                                                centered: true,
                                                autoFocusButton: 'cancel',
                                            })
                                        }}
                                        style={{
                                            color: 'red',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </Tooltip>,
                            ]
                        },
                    },
                ]}
                toolBarRender={false}
                summary={(pageData) => {
                    let total = 0
                    pageData.forEach(({ amount }) => {
                        total += amount
                    })
                    return (
                        <>
                            <AntTable.Summary.Row>
                                <AntTable.Summary.Cell index={0}>
                                    <Typography.Text
                                        style={{ fontWeight: 'bolder' }}
                                    >
                                        Total
                                    </Typography.Text>
                                </AntTable.Summary.Cell>
                                <AntTable.Summary.Cell
                                    index={1}
                                ></AntTable.Summary.Cell>
                                <AntTable.Summary.Cell
                                    index={1}
                                ></AntTable.Summary.Cell>
                                <AntTable.Summary.Cell
                                    index={2}
                                ></AntTable.Summary.Cell>
                                <AntTable.Summary.Cell
                                    index={3}
                                ></AntTable.Summary.Cell>
                                <AntTable.Summary.Cell index={4}>
                                    <Statistic
                                        value={total}
                                        precision={2}
                                        prefix="₱"
                                        valueStyle={{
                                            fontWeight: 'bolder',
                                        }}
                                        prefixCls="₱"
                                    />
                                </AntTable.Summary.Cell>
                            </AntTable.Summary.Row>
                            {/* <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                          <Table.Summary.Cell index={1} colSpan={2}>
                            <Typography.Text type="danger">{totalBorrow - totalRepayment}</Typography.Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row> */}
                        </>
                    )
                }}
            />
            {/* </div> */}
        </div>
    )
}

export default ExpensesContainer
