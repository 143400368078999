import React, { useEffect, useState } from 'react'
import {
    ModalForm,
    ProForm,
    ProFormDateTimePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormTextArea,
} from '@ant-design/pro-components'
import { Loan, LoanPayment } from '../../../../../domain/entities/Loan'
import {
    Button,
    Col,
    Divider,
    Form,
    Grid,
    message,
    Row,
    Statistic,
    Typography,
} from 'antd'
import moment from 'moment'
import PreviewLoanAmortizationSchedule from '../PreviewLoanAmortizationSchedule'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { calculateDaysElapsed, isSameDay } from '../loanHelper'
// import { randomId } from '../../../utils'
import { shallowEqual } from 'react-redux'
import { getAllMemberLoans, getLoan } from '../../../../redux/loans/loans.slice'
import { useNavigate, useParams } from 'react-router-dom'
import SelectList from '../../../../components/SelectList'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../boot'
import { paymentMethods } from '../../../../../data/repositories/constants'
import { updateMemberState } from '../../../../redux/members/members.slice'

interface MemberLoanData extends Loan {
    name: string
    frequency: string
}
interface PayLoanProp {
    data?: MemberLoanData
    onCancel?: () => void
    handleSuccess?: () => void
}

function PayLoan({ data, onCancel, handleSuccess: onSuccess }: PayLoanProp) {
    const isModal = data ? true : false
    const [isOpen, setIsOpen] = useState(data ? true : false)
    const [paymentDate, setPaymentDate] = useState(moment().unix())
    const [loading, setLoading] = useState(false)
    const [memberLoanData, setMemberLoanData] = useState<MemberLoanData>(
        data || ({} as MemberLoanData)
    )

    const [amortization, setAmortization] = useState<
        Loan['amortization'] | undefined
    >(undefined)

    const loans = useAppSelector((state) => state.loans.loans, shallowEqual)

    const handlePreviewAmortization = () => {
        // await form.current.validateFields()
        // const formData = form.current.getFieldsValue()
        setAmortization(memberLoanData.amortization)
        dispatch(
            updateMemberState({
                name: 'previewAmortization',
                value: memberLoanData?.amortization,
            })
        )
    }
    const handleClosePreviewLoanPayment = () => {
        setAmortization(undefined)
        dispatch(
            updateMemberState({
                name: 'previewAmortization',
                value: undefined,
            })
        )
    }
    const [interest, setInterest] = useState(0)
    const [totalInterestPayment, setTotalInterestPayment] = useState(0)
    const [totalPrincipalPayment, setTotalPrincipalPayment] = useState(0)

    const [values, setValues] = useState({
        amount: 0,
    })
    const [form] = Form.useForm()

    const { currentUser, styles } = useAppSelector(
        (state) => ({
            currentUser: state.auth.user,
            styles: state.styles,
        }),
        shallowEqual
    )
    const { xs } = Grid.useBreakpoint()

    // const [previousPayment, setPreviousPayment] = useState<LoanPayment>({
    //     beginningBalance: 0,
    //     interest: 0,
    //     principal: 0,
    //     endingBalance: 0,
    //     creation: 0,
    //     modified: 0,
    //     modifiedBy: '',
    //     amountPaid: 0,
    //     id: '',
    //     paidPendingInterest: 0,
    //     due: 0,
    //     pendingInterest: 0,
    //     currentInterest: 0,
    //     loanPenalty: true,
    //     isRemoved: false,
    // })

    const dispatch = useAppDispatch()
    const handleFieldChange = (field: any) => {
        const key = Object.keys(field)[0]
        if (key === 'interest') {
            setTotalInterestPayment(field[key])
            // const amount = field[key]
            // if (amount) {
            //     const interestPayment = pendingInterest - amount
            //     console.log(interestPayment, pendingInterest)
            //     if (interestPayment < 0) {
            //         setTotalPrincipalPayment(-interestPayment)
            //         setTotalInterestPayment(pendingInterest)
            //     } else {
            //         setTotalInterestPayment(amount)
            //         setTotalPrincipalPayment(0)
            //     }
            // } else {
            //     setTotalInterestPayment(0)
            //     setTotalPrincipalPayment(0)
            // }
        }
        if (key === 'principal') {
            setTotalPrincipalPayment(field[key])
        }

        if (key === 'startDate') {
            const paymentDate = moment(field[key]).unix()
            // const due = isDue(memberLoanData.due, paymentDate)
            // if (due) {
            //     const interestRate = memberLoanData.interest / 100
            //     const interest = memberLoanData.remainingBalance * interestRate
            //     const newPendingInterest =
            //         interest + memberLoanData.pendingInterest
            //
            //     setPendingInterest(newPendingInterest)
            // }

            setPaymentDate(paymentDate)
        }
        setValues({
            ...values,
            ...field,
        })
    }
    const navigate = useNavigate()
    const handleCancel = () => {
        if (!onCancel) {
            navigate(-1)
        }
        onCancel && onCancel()
        handleClosePreviewLoanPayment()
    }

    const handleSuccess = () => {
        onSuccess && onSuccess()
    }
    const handleSubmit = async (data: any) => {
        setLoading(true)

        // const interestPaid = parseFloat(totalInterestPayment.toFixed(2))
        // let totalInterest = parseFloat(
        //     (interest + memberLoanData.pendingInterest).toFixed(2)
        // )
        // totalInterest = totalInterest || interestPaid
        // let paidPendingInterest = 0
        // if (totalInterest) {
        //     if (memberLoanData.pendingInterest <= interestPaid) {
        //         paidPendingInterest =
        //             interestPaid - memberLoanData.pendingInterest
        //         paidPendingInterest = totalInterest - paidPendingInterest
        //     } else {
        //         paidPendingInterest = interestPaid
        //     }
        // }
        const payload: LoanPayment = {
            loanId: memberLoanData.id as string,
            interest: totalInterestPayment,
            memberId: memberLoanData.memberId,
            principal: totalPrincipalPayment,
            amountPaid: data.amount,
            creation: moment().unix(),
            paymentDate: moment(data.startDate).unix(),
            previousPaymentDate:
                memberLoanData?.lastPaymentDate ||
                moment(data.startDate).unix(),
            modified: moment().unix(),
            modifiedBy: currentUser.email,
            due: memberLoanData.due,
            // paidPendingInterest: paidPendingInterest,
            pendingInterest: memberLoanData.pendingInterest,
            currentInterest: totalInterestPayment,
            isRemoved: false,
            partnerCode: memberLoanData.partnerCode || '',
            beginningBalance: memberLoanData.remainingBalance,
            endingBalance:
                memberLoanData.remainingBalance - totalPrincipalPayment,
            loanPenalty: false,
            ...data,
        }

        // const daysElapsed = calculateDaysElapsed(
        //     memberLoanData.due,
        //     creationDate
        // )
        // const numberOfMonths = parseInt((daysElapsed / 30).toFixed())
        // payload.due = moment
        //     .unix(payload.due)
        //     .add(numberOfMonths + 1, 'month')
        //     .unix()
        const paymentRef = collection(
            db,
            'loans',
            memberLoanData.id as string,
            'payments'
        )
        const loanRef = doc(db, 'loans', memberLoanData.id as string)
        const resp = await addDoc(paymentRef, payload)
        await updateDoc(loanRef, {
            due: moment.unix(payload.due).add(1, 'months').unix(),
        })

        // await dispatch(
        //     payLoan({
        //         loan: {
        //             id: memberLoanData.id as string,
        //             memberId: memberLoanData.memberId,
        //             partnerCode: memberLoanData.partnerCode,
        //             creation: memberLoanData.creation,
        //         },
        //         payment: payload,
        //     })
        // )
        if (resp) {
            message.success('Payment successfully recorded.')
            setLoading(false)
            handleSuccess()
            handleCancel()
        }
    }
    const params = useParams()

    useEffect(() => {
        if (!memberLoanData || !memberLoanData.loanPenalty) {
            dispatch(getAllMemberLoans(params.teamId as string))
            return
        }
        const paymentSchedule =
            memberLoanData?.amortization?.schedules[
                memberLoanData.numberOfPaymentsMade
            ] || []
        const days = calculateDaysElapsed(paymentSchedule.due, paymentDate)
        let interest = 0
        if (isSameDay(paymentDate || moment().unix(), paymentSchedule.due)) {
            interest = paymentSchedule.interest
        }
        if (days < 0) {
            const daysInMonth = moment.unix(paymentDate).daysInMonth() // Number of days in the current month
            const daysElapsed = daysInMonth - days * -1
            interest = (paymentSchedule.interest / daysInMonth) * daysElapsed
        }

        if (interest < 0) {
            interest = 0
        }

        const totalInterest = interest + memberLoanData.pendingInterest
        if (values.amount) {
            const interestPayment = totalInterest - values.amount
            if (interestPayment < 0) {
                setTotalPrincipalPayment(-interestPayment)
                setTotalInterestPayment(totalInterest)
            } else {
                setTotalInterestPayment(values.amount)
                setTotalPrincipalPayment(0)
            }
        } else {
            setTotalInterestPayment(0)
            setTotalPrincipalPayment(0)
        }

        setInterest(interest)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentDate, values.amount, memberLoanData])

    // useEffect(() => {
    //     if (memberLoanData.payments.length) {
    //         setPreviousPayment(
    //             memberLoanData.payments[memberLoanData.payments.length - 1]
    //         )
    //     } else {
    //         setPreviousPayment({
    //             ...previousPayment,
    //             beginningBalance: memberLoanData.amount,
    //             endingBalance: memberLoanData.amount,
    //         })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [memberLoanData])

    useEffect(() => {
        let calculating = false
        const getData = async () => {
            let loan = await getLoan(memberLoanData.id as string)

            setMemberLoanData({
                ...memberLoanData,
                ...loan,
            })
        }
        getData()
        return () => {
            if (calculating) {
                alert('Calculating loan penalty... Dont close this tab!')
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberLoanData.id, params])
    return (
        <>
            <ModalForm<Loan>
                form={form}
                onFinishFailed={(err) => {
                    if (err.errorFields.length) {
                        const firstError = err.errorFields[0]
                        message.destroy()
                        message.error(firstError.errors)
                        form.scrollToField(firstError.name, {
                            behavior: 'smooth',
                            block: 'center',
                        })
                    }
                }}
                visible
                autoFocusFirstInput
                modalProps={{
                    onCancel: () => {
                        if (loading) {
                            message.destroy()
                            message.info(
                                'System is calculating penalty. Please wait!',
                                50
                            )
                            return
                        }
                        handleCancel()
                    },
                    okText: 'Submit',
                    centered: true,
                    okButtonProps: {
                        loading,
                    },
                    cancelButtonProps: {
                        loading,
                        disabled: loading,
                    },
                    closable: loading ? false : undefined,
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        form.submit()
                    }
                }}
                submitTimeout={2000}
                onFinish={handleSubmit}
                initialValues={{
                    startDate: moment(),
                    loanSelection: memberLoanData.id,
                }}
                onValuesChange={handleFieldChange}
                submitter={{
                    submitButtonProps: {
                        style: {
                            backgroundColor: styles.primary,
                            color: 'white',
                        },
                        loading,
                    },
                    render: (props, dom) => {
                        let toReturn = [...dom]
                        if (!isModal && !memberLoanData.id) {
                            return [
                                <Button
                                    key="2-back-go"
                                    onClick={() => {
                                        handleCancel()
                                    }}
                                >
                                    Back
                                </Button>,
                            ]
                        }
                        if (!isModal && memberLoanData.id) {
                            return [
                                <Button
                                    key="1"
                                    onClick={() => {
                                        setMemberLoanData({} as MemberLoanData)
                                        setIsOpen(false)
                                    }}
                                >
                                    Back
                                </Button>,
                                ...toReturn,
                            ]
                        }
                        return toReturn
                    },
                }}
                // submitter={false}
            >
                {isOpen ? (
                    <Row>
                        <Col xs={24} sm={12} md={8}>
                            <table cellPadding={'5px 10px'}>
                                <tbody>
                                    <tr>
                                        <th colSpan={2} align={'center'}>
                                            Loan Details
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>Due Date</td>
                                        <td align={'right'}>
                                            {moment
                                                .unix(memberLoanData.due)
                                                .format('MMM DD, YYYY')}
                                        </td>
                                    </tr>
                                    {memberLoanData.approvalDate ? (
                                        <tr>
                                            <td>Approval Date</td>
                                            <td align={'right'}>
                                                {moment
                                                    .unix(
                                                        memberLoanData.approvalDate
                                                    )
                                                    .format('MMM DD, YYYY')}
                                            </td>
                                        </tr>
                                    ) : null}

                                    {memberLoanData.disbursementDate ? (
                                        <tr>
                                            <td>Disbursement Date</td>
                                            <td align={'right'}>
                                                {moment
                                                    .unix(
                                                        memberLoanData.disbursementDate
                                                    )
                                                    .format('MMM DD, YYYY')}
                                            </td>
                                        </tr>
                                    ) : null}
                                    <tr>
                                        <td>Percentage</td>
                                        <td align={'right'}>
                                            <Statistic
                                                value={memberLoanData.interest}
                                                suffix={'%'}
                                                prefixCls={'%'}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Loan Amount</td>
                                        <td align={'right'}>
                                            <Statistic
                                                value={memberLoanData.amount}
                                                prefix={'₱'}
                                                prefixCls={'₱'}
                                                precision={2}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Balance</td>
                                        <td align={'right'}>
                                            <Statistic
                                                value={
                                                    memberLoanData.remainingBalance
                                                }
                                                prefix={'₱'}
                                                prefixCls={'₱'}
                                                precision={2}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Interest Payable</td>
                                        <td align={'right'}>
                                            <Statistic
                                                value={interest}
                                                prefix={'₱'}
                                                prefixCls={'₱'}
                                                precision={2}
                                                valueStyle={{
                                                    color:
                                                        interest > 0
                                                            ? '#cf1322'
                                                            : 'unset',
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th colSpan={2} align={'center'}>
                                            Payment Breakdown
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>Principal</td>
                                        <td align={'right'}>
                                            <Statistic
                                                value={totalPrincipalPayment}
                                                prefix={'₱'}
                                                prefixCls={'₱'}
                                                precision={2}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Interest</td>
                                        <td align={'right'}>
                                            <Statistic
                                                value={totalInterestPayment}
                                                prefix={'₱'}
                                                prefixCls={'₱'}
                                                precision={2}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Outstanding Balance</td>
                                        <td align={'right'}>
                                            <Statistic
                                                value={
                                                    memberLoanData.remainingBalance -
                                                    totalPrincipalPayment
                                                }
                                                prefix={'₱'}
                                                prefixCls={'₱'}
                                                precision={2}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <Button
                                block
                                onClick={handlePreviewAmortization}
                                disabled={
                                    memberLoanData && memberLoanData.id
                                        ? false
                                        : true
                                }
                                style={{
                                    color: '#1890ff',
                                }}
                            >
                                View Amortization Schedule
                            </Button>
                            {/*<br />*/}
                            {/*<Row justify={'center'}>*/}
                            {/*    <Col>*/}
                            {/*        <Typography.Link*/}
                            {/*            onClick={handlePreviewAmortization}*/}
                            {/*            disabled={*/}
                            {/*                memberLoanData && memberLoanData.id ? false : true*/}
                            {/*            }*/}
                            {/*        >*/}
                            {/*            View Amortization Schedule*/}
                            {/*        </Typography.Link>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </Col>

                        <Col xs={24} md={1}>
                            {!xs ? (
                                <Divider
                                    type={'vertical'}
                                    style={{ height: '100%' }}
                                />
                            ) : (
                                <Divider
                                    type={'horizontal'}
                                    style={{ height: '100%', width: '100%' }}
                                />
                            )}
                        </Col>
                        <Col xs={24} sm={12}>
                            <div style={{ marginLeft: xs ? 0 : 20 }}>
                                <Typography.Title level={xs ? 3 : 2}>
                                    {memberLoanData.title}
                                </Typography.Title>
                            </div>
                            <div style={{ marginLeft: xs ? 0 : 20 }}>
                                {memberLoanData.loanPenalty ? (
                                    <Row wrap={false} gutter={[12, 0]}>
                                        <Col span={12}>
                                            <ProFormDigit
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: 'number',
                                                        message:
                                                            'This field is required',
                                                    },
                                                ]}
                                                name="amount"
                                                disabled={loading}
                                                label="Amount"
                                                placeholder="Please Enter"
                                                min={0}
                                                max={
                                                    memberLoanData.pendingInterest +
                                                    interest +
                                                    memberLoanData.remainingBalance
                                                }
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ProFormSelect
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'This field is required',
                                                    },
                                                ]}
                                                name="paymentMethod"
                                                label="Payment Method"
                                                options={paymentMethods}
                                                placeholder="Please select"
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    <ProForm.Group>
                                        <ProFormDigit
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'number',
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                            width="sm"
                                            name="interest"
                                            label="Interest"
                                            placeholder="Please Enter"
                                            min={0}
                                        />
                                        <ProFormDigit
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'number',
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                            width="sm"
                                            name="principal"
                                            label="Principal"
                                            placeholder="Please Enter"
                                            min={0}
                                        />
                                    </ProForm.Group>
                                )}

                                {currentUser.type !== 'staff' && (
                                    <ProFormDateTimePicker
                                        name={'startDate'}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'This field is required',
                                            },
                                        ]}
                                        disabled={loading}
                                        label={'Payment Date'}
                                        width={216}
                                        fieldProps={{
                                            disabledDate: (date) => {
                                                const customDate = moment
                                                    .unix(memberLoanData.due)
                                                    .add(1, 'day')
                                                    .format('YYYY-MM-DD')
                                                return (
                                                    memberLoanData.loanPenalty &&
                                                    date &&
                                                    date >
                                                        moment(
                                                            customDate,
                                                            'YYYY-MM-DD'
                                                        )
                                                )
                                            },
                                            format: 'MMMM DD, YYYY',
                                        }}
                                    />
                                )}
                                <ProFormTextArea
                                    name="remarks"
                                    label="Remarks/Notes"
                                />
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <SelectList
                        value={memberLoanData.id}
                        dataSource={loans.map((loan) => ({
                            value: loan.id as string,
                            label: (
                                <>
                                    <Typography.Text>
                                        {loan.title}{' '}
                                        <span>
                                            <Typography.Text
                                                type="secondary"
                                                style={{}}
                                            >
                                                • ₱{loan.amount}
                                            </Typography.Text>{' '}
                                            <Typography.Text type="secondary">
                                                • Due{' '}
                                                {moment
                                                    .unix(loan.due)
                                                    .format('MM/DD/`YY')}
                                            </Typography.Text>
                                        </span>
                                    </Typography.Text>
                                </>
                            ),
                            loan: `${loan.title}${loan.amount}`,
                        }))}
                        name="loan"
                        placeholder={'Select loan'}
                        onSelect={(loanId) => {
                            const loan = loans.find(
                                (loan) => loan.id === loanId
                            )
                            if (!loan) {
                                message.error('error selecting loan. try again')
                                return
                            }
                            setIsOpen(true)
                            setMemberLoanData({
                                ...loan,
                                name: loan.title,
                                frequency: loan.payback,
                            })
                        }}
                    />
                )}
            </ModalForm>

            {amortization && (
                <PreviewLoanAmortizationSchedule
                    handleClose={handleClosePreviewLoanPayment}
                    data={amortization}
                    // monthlyPayment={monthlyPayment.toFixed(2)}
                    // numberOfPayments={numberOfPayments}
                    // totalPayment={totalPayment.toFixed(2)}
                    // totalInterest={totalInterest.toFixed(2)}
                    // tableData={tableData}
                />
            )}
        </>
    )
}

export default PayLoan
