import { Borrow, BorrowerPayment } from '../entities/Borrow'
import { BorrowRepository } from '../repositories/borrow.repository'

export class BorrowService {
    repo: BorrowRepository
    constructor(ir: BorrowRepository) {
        this.repo = ir
    }

    async borrow(payload: Borrow) {
        return await this.repo.borrow(payload)
    }

    async updateBorrower(id: string, payload: Partial<Borrow>) {
        return await this.repo.updateBorrower(id, payload)
    }

    async deleteBorrower(payload: Borrow) {
        return await this.repo.deleteBorrower(payload)
    }
    async getBorrowers(
        payload:
            | {
                  partnerCode: string
              }
            | string
    ) {
        return await this.repo.getBorrowers(payload)
    }

    async payBorrower(payload: BorrowerPayment) {
        return await this.repo.payBorrower(payload)
    }

    async getPayments(id: string) {
        return await this.repo.getPayments(id)
    }

    async deleteBorrowerPayment(payload: {
        borrowerId: string
        payment: BorrowerPayment
    }) {
        return await this.repo.deleteBorrowerPayment(payload)
    }
}
