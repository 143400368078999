import { Personnel } from '@/domain/entities/Transport'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Card, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaMedal } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { PhoneNumberFormat } from 'google-libphonenumber'

interface EmployeeAvatarProps {
    employee: Personnel & {
        rank?: number
    }
    initials?: boolean
    withLink?: boolean
}
const phoneUtil = PhoneNumberUtil.getInstance()
export default function EmployeeAvatar({
    employee,
    initials = true,
    withLink = true,
}: EmployeeAvatarProps) {
    const navigate = useNavigate()

    const url = employee?.idUrl || ''
    const rank = employee?.rank || 0
    const id = employee?.id || ''
    // const driverName = employee?.displayName || ''
    // const driverPhone = employee?.phone || ''
    const [phone, setPhone] = useState('')
    const [driverName, setDriverName] = useState('')
    useEffect(() => {
        if (!employee) return
        try {
            const phoneNumber = phoneUtil.parse(employee.phone, 'PH')
            const phone = phoneUtil.format(
                phoneNumber,
                PhoneNumberFormat.INTERNATIONAL
            )
            setPhone(phone)
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err)
        }
        const name = `${employee.lastName}, ${
            initials ? `${employee.firstName[0]}.` : employee.firstName
        }`
        setDriverName(name)
    }, [employee, initials])
    if (!employee) return null
    return employee.role ? (
        <Card.Meta
            className="transport"
            avatar={
                <div
                    style={{
                        position: 'relative',
                    }}
                >
                    {url ? (
                        <Avatar
                            src={url}
                            style={{
                                width: 64,
                                height: 64,
                                backgroundColor: '#1890ff',
                                border: `2px solid ${
                                    employee.role === 'driver'
                                        ? '#1890ff'
                                        : employee.role === 'assistant'
                                        ? '#CAC601'
                                        : 'green'
                                }`,
                            }}
                        />
                    ) : (
                        <Avatar
                            style={{
                                width: 64,
                                height: 64,
                                border: `2px solid ${
                                    employee.role === 'driver'
                                        ? '#1890ff'
                                        : employee.role === 'assistant'
                                        ? '#CAC601'
                                        : 'green'
                                }`,
                                // backgroundColor:
                                //     '#1890ff',
                            }}
                            icon={
                                <UserOutlined
                                    sizes="64px"
                                    style={{
                                        fontSize: 46,
                                        marginTop: 8,
                                    }}
                                />
                            }
                        />
                    )}
                    {rank && rank <= 3 ? (
                        <div
                            style={{
                                position: 'absolute',
                                bottom: -32,
                                left: 6,
                                padding: 8,
                            }}
                        >
                            <FaMedal
                                style={{
                                    fontSize: 36,
                                    padding: 2,
                                    borderRadius: 24,
                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                    border: '1px solid rgba(0, 0, 0, 0.3)',
                                    color:
                                        rank === 1
                                            ? '#FFD700'
                                            : rank === 2
                                            ? 'silver'
                                            : rank === 3
                                            ? '#cd7f32'
                                            : '',
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            }
            title={
                <Typography.Link
                    style={{ fontSize: 18 }}
                    onClick={() => {
                        if (!withLink) return
                        navigate(`personnel/${id}`)
                    }}
                >
                    {driverName}
                </Typography.Link>
            }
            description={
                <>
                    {employee.email ? (
                        <div>
                            <Typography.Text
                                style={{
                                    fontSize: 14,
                                }}
                            >
                                {employee.email}
                            </Typography.Text>
                        </div>
                    ) : null}
                    <div>
                        <Typography.Text
                            style={{
                                fontSize: 14,
                            }}
                        >
                            {phone}
                        </Typography.Text>
                    </div>
                </>
            }
        />
    ) : null
}
