import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
// import { getMembers } from '../../../../redux/members/members.slice'
import {
    ModalForm,
    ProFormDatePicker,
    ProFormSelect,
    ProFormText,
} from '@ant-design/pro-components'
import { shallowEqual } from 'react-redux'
import { Col, Form, message, Row } from 'antd'
import { getTeamMembers } from '../../../../redux/team/team.action'
import { TeamMember } from '../../../../../domain/entities/Team'
import { useLocation } from 'react-router-dom'
import SelectList from '../../../../components/SelectList'
import moment from 'moment'
import { Member } from '@/domain/entities/Member'

interface SelectMemberProp {
    onCancel: () => void
    handleSuccess: (
        prop: TeamMember & {
            id: string
            partnerCode: string
        }
    ) => Promise<void>
}

function SelectMember({ onCancel, handleSuccess }: SelectMemberProp) {
    const { members, styles, activeTeam } = useAppSelector(
        (state) => ({
            members: state.team.currentTeamMembers,
            styles: state.styles,
            activeTeam: state.auth.user.activeTeam,
        }),
        shallowEqual
    )
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    const [filteredMembers, setFilteredMembers] = useState<
        {
            label: string
            value: string
        }[]
    >([])
    const [selectedMember, setSelectedMember] = useState<null | Member>(null)

    const onSubmit = async (data: any) => {
        const member = members.find((member) => member.id === data.member)
        if (!member) {
            message.error('No member found')
            return
        }
        data.dateOfBirth = moment(data.dateOfBirth).unix()
        data.partnerCode = activeTeam
        data.displayName = data.firstName + ' ' + data.lastName
        data.id = member.id
        await handleSuccess(data)
        onCancel()
    }

    const handleSelect = (data: any) => {
        const memberData = members.find((member) => member.id === data)
        if (memberData) {
            form.setFieldsValue({
                ...memberData,
                dateOfBirth:
                    typeof memberData.dateOfBirth === 'number'
                        ? moment.unix(memberData.dateOfBirth)
                        : memberData.dateOfBirth,
            })
            setSelectedMember(memberData)
        }
    }

    useEffect(() => {
        const newMembers: {
            label: string
            value: string
            member: any
        }[] = []
        members.forEach((member) => {
            if (member.id === 'default') return
            newMembers.push({
                label: member.displayName as string,
                value: member.id,
                member: `${member.displayName}`,
            })
        })

        setFilteredMembers(newMembers)
    }, [members])

    const location = useLocation()
    useEffect(() => {
        if (!activeTeam) return
        dispatch(
            getTeamMembers({
                activeTeam,
                pageName: 'Member Selection',
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTeam, location.pathname])

    return (
        <ModalForm
            open
            form={form}
            autoFocusFirstInput
            onFinishFailed={(err) => {
                if (err.errorFields.length) {
                    const firstError = err.errorFields[0]
                    message.destroy()
                    message.error(firstError.errors)
                    form.scrollToField(firstError.name, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }}
            modalProps={{
                onCancel: () => onCancel(),
                okText: 'Next',
                centered: true,
            }}
            submitter={{
                submitButtonProps: {
                    style: {
                        backgroundColor: styles.primary,
                        color: 'white',
                    },
                },
            }}
            onFinish={onSubmit}
            width={540}
        >
            <SelectList
                placeholder={"Please enter member's name"}
                dataSource={filteredMembers}
                form={form}
                name="member"
                onSelect={handleSelect}
            >
                {selectedMember && (
                    <>
                        <Row wrap={false} gutter={[12, 0]}>
                            <Col span={12}>
                                <ProFormText
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required.',
                                        },
                                    ]}
                                    label="First Name"
                                    name="firstName"
                                />
                            </Col>
                            <Col span={12}>
                                <ProFormText
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required.',
                                        },
                                    ]}
                                    label="Last Name"
                                    name="lastName"
                                />
                            </Col>
                        </Row>
                        <Row wrap={false} gutter={[12, 0]}>
                            <Col span={12}>
                                <ProFormSelect
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required.',
                                        },
                                    ]}
                                    label={'Gender'}
                                    name="gender"
                                    options={[
                                        {
                                            key: '',
                                            value: 'male',
                                            label: 'Male',
                                        },
                                        {
                                            key: '',
                                            value: 'female',
                                            label: 'Female',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={12}>
                                <ProFormDatePicker
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required.',
                                        },
                                    ]}
                                    label={'Birthday'}
                                    name="dateOfBirth"
                                    fieldProps={{
                                        format: 'MMMM DD, YYYY',
                                    }}
                                />
                            </Col>
                        </Row>

                        <ProFormText
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'This field is required.',
                            //     },
                            // ]}
                            label={'Present Address'}
                            name="presentAddress"
                        />
                        <Row wrap={false} gutter={[12, 0]}>
                            <Col span={12}>
                                <ProFormText label={'Email'} name="email" />
                            </Col>
                            <Col>
                                <ProFormText
                                    label={'Phone number'}
                                    name="phone"
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </SelectList>

            {/* <div
                style={{
                    padding: xs ? undefined : '24px 24px 0 24px',
                }}
            >
                <Steps
                    current={0}
                    style={{
                        padding: 0,
                    }}
                    type={xs ? 'default' : 'navigation'}
                    progressDot={xs}
                    size={xs ? 'small' : 'default'}
                >
                    <Steps.Step
                        icon={<UserOutlined />}
                        status={'process'}
                        title={'Select a member'}
                    />
                    <Steps.Step
                        icon={<SolutionOutlined />}
                        status={'wait'}
                        title={'Fill in details'}
                    />
                </Steps>
                {!xs && <Divider />}
                <ProFormSelect
                    name={'selectedMember'}
                    options={filteredMembers}
                    showSearch
                    placeholder={"Please enter member's name"}
                    rules={[
                        {
                            required: true,
                            type: 'string',
                            message:
                                'Member not found. Select a member in the list.',
                        },
                    ]}
                    fieldProps={{
                        onKeyUp: (event) => {
                            if (event.key === 'Enter') {
                                form.submit()
                            }
                        },
                    }}
                />
            </div> */}
        </ModalForm>
    )
}

export default SelectMember
