/* eslint-disable no-unused-vars */
import {
    ArrowLeftOutlined,
    ClockCircleOutlined,
    // CloseCircleFilled,
    DeleteFilled,
    EditFilled,
    // EditOutlined,
    UserOutlined,
} from '@ant-design/icons'
import { db } from '../../boot'
import {
    PERSONNEL_ATTENDANCE_REPOSITORY,
    PERSONNEL_REPOSITORY,
    PAYSLIPS_REPOSITORY,
} from '../../../data/repositories/constants'
import {
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Empty,
    Grid,
    List,
    Row,
    Tag,
    Tooltip,
    Typography,
    message,
    Comment,
    Popconfirm,
    Statistic,
    Upload,
} from 'antd'
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import moment from 'moment'
import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../app/store'
import { shallowEqual } from 'react-redux'
// import { isLate } from '../Borrowers/helper'
import { Personnel } from '@/domain/entities/Transport'
import { Attendance } from '@/domain/entities/Attendance'
import { calculateWorkTime } from '../My/MyTeam/hrUtils'
import {
    ModalForm,
    ProFormDatePicker,
    ProFormTimePicker,
    ProFormDigit,
    ProForm,
} from '@ant-design/pro-components'
import { formatNumberWithCommas } from '../../../app/components/utils'
import PayslipPDF from '../../components/Employee/PayslipPDF'
import { MdAttachFile } from 'react-icons/md'
import AddFilesModal from '../../components/Storage/AddFilesModal'
// import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
// import { randomId } from '../utils'
import { GetEmployeeFiles } from '../../redux/team/team.action'

const PersonnelDashboard = () => {
    const params = useParams()
    const [attendance, setAttendance] = useState<any[]>([])
    const [personnel, setPersonnel] = useState<Personnel | undefined>(undefined)
    const [attendanceForm] = ProForm.useForm()
    const { styles, uid, role, hr, email, activeTeam } = useAppSelector(
        (state) => ({
            styles: state.styles,
            uid: state.auth.user.id || state.auth.user.uid,
            role: state.auth.user.role,
            email: state.auth.user.email,
            hr: state.team.selectedTeamData.hr,
            activeTeam: state.auth.user.activeTeam,
        }),
        shallowEqual
    )
    React.useEffect(() => {
        const id = params.personnelId || params.userId || uid
        if (!id) return

        let resp: () => void | undefined
        const main = async () => {
            const personnelSnap = await getDoc(
                doc(db, PERSONNEL_REPOSITORY, id as string)
            )
            if (personnelSnap) {
                setPersonnel({
                    ...(personnelSnap.data() as Personnel),
                    id: personnelSnap.id,
                })
                const user = personnelSnap.data()
                if (user) {
                    const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)
                    const q = query(
                        ref,
                        where(
                            user.role === 'driver'
                                ? 'driverId'
                                : user.role === 'assistant'
                                ? 'assistantId'
                                : 'employeeId',
                            '==',
                            id
                        ),
                        where('paymentStatus', '==', 'pending')
                    )
                    resp = onSnapshot(q, async (snaps) => {
                        const datum = snaps.docs.map((docSnap) => {
                            const attendanceData = docSnap.data() as Attendance
                            // eslint-disable-next-line no-console
                            // console.log(docSnap.id)

                            const workTime = calculateWorkTime(
                                moment
                                    .unix(attendanceData.timeIn)
                                    .format('HH:mm'),
                                moment
                                    .unix(attendanceData.timeOut)
                                    .format('HH:mm'),
                                hr
                            )
                            const hours =
                                user.role === 'driver' ||
                                user.role === 'assistant'
                                    ? workTime.hours
                                    : 0
                            const minutesLate =
                                user.role === 'driver' ||
                                user.role === 'assistant'
                                    ? workTime.minutesLate
                                    : 0
                            return {
                                ...attendanceData,
                                status: attendanceData.paymentStatus,
                                isAbsent:
                                    (user.role === 'driver' &&
                                        attendanceData.driverStatus ===
                                            'absent') ||
                                    (user.role === 'assistant' &&
                                        attendanceData.assistantStatus ===
                                            'absent') ||
                                    attendanceData.employeeStatus === 'absent',
                                dateNumber: moment(
                                    docSnap.data().date,
                                    'MMM DD, YYYY'
                                ).unix(),
                                id: docSnap.id,
                                incentives:
                                    user.role === 'driver'
                                        ? attendanceData.driverIncentives
                                        : user.role === 'assistant'
                                        ? attendanceData.assistantIncentives
                                        : attendanceData.incentives,
                                salary:
                                    user.role === 'driver'
                                        ? attendanceData.driverSalary
                                        : user.role === 'assistant'
                                        ? attendanceData.assistantSalary
                                        : attendanceData.salary,
                                overtimePay:
                                    user.role === 'driver'
                                        ? attendanceData.driverOvertimePay
                                        : user.role === 'assistant'
                                        ? attendanceData.assistantOvertimePay
                                        : attendanceData.overtimePay,
                                overtimeHours:
                                    user.role === 'driver'
                                        ? attendanceData.driverOvertimeHours
                                        : user.role === 'assistant'
                                        ? attendanceData.assistantOvertimeHours
                                        : attendanceData.overtimeHours,
                                hoursRendered:
                                    user.role !== 'driver' &&
                                    user.role !== 'assistant'
                                        ? attendanceData.hoursRendered
                                        : hours,
                                minutesLate:
                                    user.role !== 'driver' &&
                                    user.role !== 'assistant'
                                        ? attendanceData.minutesLate
                                        : minutesLate,
                                // sssDeductions: user.role === 'driver' ? attendanceData.driverSss : attendanceData.assistantSss,
                                // pagIbigDeductions: user.role === 'driver' ? attendanceData.driverPagibig : attendanceData.assistantPagibig,
                                // philhealthDeductions: user.role === 'driver' ? attendanceData.driverPhilhealth : attendanceData.assistantPhilhealth,
                            }
                        })
                        setAttendance(
                            datum.sort(
                                (a: any, b: any) => b.dateNumber - a.dateNumber
                            )
                        )
                    })
                    return resp
                }
            }
        }

        main()
        return () => {
            resp && resp()
        }
    }, [params, hr, uid])

    const loc = useLocation()
    const navigate = useNavigate()
    const { xs } = Grid.useBreakpoint()
    const [filesModal, setFilesModal] = useState(false)
    const [loadingUpload, setLoadingUpload] = useState(false)
    // const [fileUrls, setFileUrls] = useState<any>([])

    const handleCancelAddFiles = () => {
        setFilesModal(false)
    }
    // const handleUpdateAttendance = async (
    //     att: any,
    //     status: 'done' | 'absent'
    // ) => {
    //     if (!personnel) return
    //     let role: string = 'employee'
    //     if (personnel.role === 'assistant' || personnel.role === 'driver') {
    //         role = personnel.role
    //     }
    //     await updateDoc(
    //         doc(db, PERSONNEL_ATTENDANCE_REPOSITORY, att.id as string),
    //         {
    //             [`${role}Status`]: status,
    //             status,
    //         }
    //     )
    //     message.success('Successfully updated.')
    //     message.success(role)
    // }
    const [payslips, setPayslips] = useState<any[]>([])
    const [currentAttendance, setCurrentAttendance] = useState<
        Attendance | undefined
    >(undefined)
    const [openTimeInOut, setOpenTimeInOut] = useState(false)
    React.useEffect(() => {
        const id = params.personnelId || params.userId || uid
        if (!id) return
        const paylsips = async () => {
            const ref = collection(db, PAYSLIPS_REPOSITORY)
            const q = query(ref, where('employeeId', '==', id))

            const snapshots = await getDocs(q)

            const payslips = snapshots.docs.map((docSnap) => {
                // eslint-disable-next-line no-console
                // console.log(docSnap.data())
                return {
                    ...docSnap.data(),
                    id: docSnap.id,
                }
            })
            setPayslips(payslips)
        }
        paylsips()
    }, [params, uid])
    React.useEffect(() => {
        if (!personnel) return
        const id = personnel.id || params.personnelId || params.userId || uid
        if (!id) return
        const paylsips = async () => {
            // setNewFilesData(info.fileList)
            // const file = info.file
            const resp = await GetEmployeeFiles(id as string, activeTeam)
            // eslint-disable-next-line no-console
            console.log(resp)
            setFileList(resp)
        }
        paylsips()
    }, [personnel, activeTeam, params.personnelId, params.userId, uid])

    const handleCancelUpdateAttendance = () => {
        setOpenTimeInOut(false)
        attendanceForm.resetFields()
        setCurrentAttendance(undefined)
    }
    const handleShowUpdateAttendance = (att: Attendance) => {
        setOpenTimeInOut(true)
        attendanceForm.setFieldsValue({
            ...att,
            date: moment(att.date, 'MMM DD, YYYY'),
            timeIn: moment.unix(att.timeIn),
            timeOut: moment.unix(att.timeOut),
        })
        setCurrentAttendance(att)
    }
    const handleFinishV2 = async (data: any) => {
        if (!currentAttendance) return
        const currentDate = moment(data.date, 'MMMM DD, YYYY')
        const timeIn = moment(data.timeIn, 'h:mmA')
            .set('M', currentDate.month())
            .set('D', currentDate.date())
        const timeOut = moment(data.timeOut, 'h:mmA')
            .set('M', currentDate.month())
            .set('D', currentDate.date())

        // if (currentEmployee && !currentEmployee.salary) {
        //     message.destroy()
        //     message.error('Employee has no salary', 5)
        // }
        const timeLogs = {
            ...data,
            timeIn: timeIn.unix(),
            timeOut: timeOut.unix(),
            date: currentDate.format('MMM DD, YYYY'),
            modified: moment().unix(),
            salary: data.salary,
            incentives: data.incentives || 0,
            modifiedBy: email,
            overtimePay: data.overtimePay || 0,
            overtimeHours: data.overtimeHours || 0,
        }
        await updateDoc(
            doc(
                db,
                PERSONNEL_ATTENDANCE_REPOSITORY,
                currentAttendance.id as string
            ),
            timeLogs
        )

        message.destroy()
        message.success('Successfully clocked employee in', 5)
        handleCancelUpdateAttendance()
    }
    const onChangeValue = (values: any, vals: any) => {
        // const currentDate = moment().format('MM-DD-YYYY')
        if (!currentAttendance) return
        const salary = currentAttendance.salary

        const overtimeHours = vals['overtimeHours'] || 0
        // const overtimePay = vals['overtimePay'] || 0
        const overtimePayEquation = 1.25

        const overtimePay =
            Math.round((salary / 8) * overtimePayEquation) * overtimeHours
        attendanceForm.setFieldsValue({
            overtimePay,
        })
        if (vals.timeIn && vals.timeOut && vals.date) {
            const date = moment(vals.date, 'MMMM DD, YYYY')
            const timeIn = moment(vals.timeIn, 'h:mmA')
                .set('M', date.month())
                .set('D', date.date())
            const timeOut = moment(vals.timeOut, 'h:mmA')
                .set('M', date.month())
                .set('D', date.date())
            const resp = calculateWorkTime(
                timeIn.format('HH:mm'),
                timeOut.format('HH:mm'),
                hr
            )
            attendanceForm.setFieldsValue({
                minutesLate: resp.minutesLate,
                hoursRendered: parseFloat(resp.hours.toFixed(2)),
            })
            // eslint-disable-next-line no-console
            // console.log(values, resp)
        }
    }
    const [fileList, setFileList] = useState<any>([])
    const onUploadChange = (info: any) => {}

    return (
        <div style={{ maxWidth: xs ? undefined : 846, padding: 12 }}>
            <ModalForm
                form={attendanceForm}
                onFinishFailed={(err) => {
                    if (err.errorFields.length) {
                        const firstError = err.errorFields[0]
                        message.destroy()
                        message.error(firstError.errors)
                        attendanceForm.scrollToField(firstError.name, {
                            behavior: 'smooth',
                            block: 'center',
                        })
                    }
                }}
                open={openTimeInOut}
                onFinish={handleFinishV2}
                modalProps={{
                    onCancel: handleCancelUpdateAttendance,
                    centered: true,
                }}
                initialValues={{
                    date: moment(),
                }}
                submitter={{}}
                width={300}
                onValuesChange={onChangeValue}
            >
                <ProFormDatePicker
                    // disabled
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    name="date"
                    label={'Date'}
                    fieldProps={{
                        format: 'MMMM DD, YYYY',
                    }}
                />
                <ProFormTimePicker
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    name="timeIn"
                    label={'Time In'}
                    fieldProps={{
                        format: 'h:mmA',
                        use12Hours: true,
                    }}
                />
                <ProFormTimePicker
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label={'Time Out'}
                    name="timeOut"
                    fieldProps={{
                        format: 'h:mmA',
                        use12Hours: true,
                    }}
                />
                {/* <ProFormDigit
                        label={'Hours Rendered'}
                        name="hoursRendered"
                    />
                    <ProFormDigit
                        label={'Minutes Late'}
                        name="minutesLate"
                    /> */}
                <ProFormDigit label={'Salary'} name="salary" />
                <ProFormDigit label={'Incentives'} name="incentives" />
                <ProFormDigit label={'Overtime Hours'} name="overtimeHours" />
                <ProFormDigit label={'Overtime Pay'} name="overtimePay" />
            </ModalForm>
            <br />
            {loc.state && loc.state.main ? null : (
                <Row>
                    <Col span={8}>
                        <Button type="link" onClick={() => navigate(-1)}>
                            <ArrowLeftOutlined style={{ fontSize: 24 }} />
                        </Button>
                    </Col>
                </Row>
            )}
            {loc.state && loc.state.main ? null : <br />}
            <Card
                // extra={
                //     personnel && personnel.id ? (
                //         <Button
                //             type="link"
                //             onClick={() =>
                //                 navigate(`update`,
                //                     {
                //                         state: {
                //                             ...personnel,
                //                             id: personnel?.id,
                //                         },
                //                     }
                //                 )
                //             }
                //             icon={<EditOutlined style={{ fontSize: 24 }} />}
                //         />
                //     ) : null
                // }
                bordered={false}
                headStyle={{
                    borderBottom: 'none',
                }}
                title={
                    <div style={{ color: 'white' }}>
                        <Row align={'middle'} gutter={[12, 12]} wrap={false}>
                            <Col>
                                <div
                                    style={{
                                        height: 25,
                                        width: 10,
                                        borderRadius: 10,
                                        backgroundColor: styles.primary,
                                    }}
                                ></div>
                            </Col>
                            <Col>
                                <Typography.Title
                                    level={4}
                                    style={{ marginBottom: 0, color: 'white' }}
                                >
                                    Employee Details
                                </Typography.Title>
                            </Col>
                        </Row>
                    </div>
                }
                style={{
                    backgroundColor: '#272829',
                    border: 'none',
                    borderRadius: 12,
                }}
            >
                <Row gutter={[12, 12]}>
                    <Col>
                        {personnel?.idUrl ? (
                            <Avatar
                                style={{ width: 90, height: 90 }}
                                src={personnel?.idUrl}
                            />
                        ) : (
                            <Avatar
                                size={90}
                                icon={
                                    <UserOutlined style={{ color: 'black' }} />
                                }
                            />
                        )}
                    </Col>
                    <Col flex={1}>
                        <Typography.Title style={{ color: 'white' }} level={3}>
                            {[personnel?.displayName]}
                        </Typography.Title>
                        <Row
                            gutter={[24, 24]}
                            justify={'start'}
                            style={{
                                color: 'white',
                                letterSpacing: 1,
                            }}
                        >
                            <Col xs={12} lg={8}>
                                <Typography.Text
                                    style={{
                                        color: 'white',
                                        fontWeight: 'lighter',
                                        fontSize: 'smaller',
                                    }}
                                >
                                    Salary Rate
                                </Typography.Text>
                                <br />
                                <div
                                    style={{
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    ₱{personnel?.salary || 0}/day
                                </div>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Typography.Text
                                    style={{
                                        color: 'white',
                                        fontWeight: 'lighter',
                                        fontSize: 'smaller',
                                    }}
                                >
                                    Role
                                </Typography.Text>
                                <br />
                                <div
                                    style={{
                                        fontWeight: 'bolder',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {personnel?.role}
                                </div>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Typography.Text
                                    style={{
                                        color: 'white',
                                        fontWeight: 'lighter',
                                        fontSize: 'smaller',
                                    }}
                                >
                                    Phone Number
                                </Typography.Text>
                                <br />
                                <div style={{ fontWeight: 'bolder' }}>
                                    {personnel?.phone}
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={[24, 24]}>
                            <Col xs={12} lg={8}>
                                <Typography.Text
                                    style={{
                                        color: 'white',
                                        fontWeight: 'lighter',
                                        fontSize: 'smaller',
                                    }}
                                >
                                    Email
                                </Typography.Text>
                                <br />

                                <div
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    {personnel && personnel.email
                                        ? personnel?.email
                                        : '--'}
                                </div>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Typography.Text
                                    style={{
                                        color: 'white',
                                        fontWeight: 'lighter',
                                        fontSize: 'smaller',
                                    }}
                                >
                                    Birth Date
                                </Typography.Text>
                                <br />

                                <div
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    {personnel && personnel.birthdate
                                        ? moment
                                              .unix(personnel?.birthdate)
                                              .format('MMM DD, YYYY')
                                        : '--'}
                                </div>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Typography.Text
                                    style={{
                                        color: 'white',
                                        fontWeight: 'lighter',
                                        fontSize: 'smaller',
                                    }}
                                >
                                    Joined Date
                                </Typography.Text>
                                <br />

                                <div
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    {personnel?.startDate &&
                                        moment
                                            .unix(personnel?.startDate)
                                            .format('MMM DD, YYYY')}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <br />

            <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                    <Card
                        bordered={false}
                        headStyle={{
                            borderBottom: 'none',
                        }}
                        title={
                            <div style={{ color: 'white' }}>
                                <Row
                                    align={'middle'}
                                    gutter={[12, 12]}
                                    wrap={false}
                                >
                                    <Col>
                                        <div
                                            style={{
                                                height: 25,
                                                width: 10,
                                                borderRadius: 10,
                                                backgroundColor: styles.primary,
                                            }}
                                        ></div>
                                    </Col>
                                    <Col>
                                        <Typography.Title
                                            level={4}
                                            style={{
                                                marginBottom: 0,
                                                color: 'white',
                                            }}
                                        >
                                            Leave Balances
                                        </Typography.Title>
                                    </Col>
                                </Row>
                            </div>
                        }
                        style={{
                            backgroundColor: '#272829',
                            border: 'none',
                            borderRadius: 12,
                            height: '100%',
                        }}
                    >
                        <div>
                            <Descriptions
                                layout={'horizontal'}
                                colon={false}
                                style={{ textAlign: 'left' }}
                            >
                                <Descriptions.Item
                                    label="Sick Leave Balance:"
                                    span={24}
                                    labelStyle={{
                                        color: 'white',
                                        fontSize: 'smaller',
                                    }}
                                    contentStyle={{
                                        color: 'white',
                                        fontWeight: 'bolder',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {personnel?.sickLeaveBalance || '---'}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    span={24}
                                    label="Vacation Leave Balance:"
                                    labelStyle={{
                                        color: 'white',
                                        fontSize: 'smaller',
                                    }}
                                    contentStyle={{
                                        color: 'white',
                                        fontWeight: 'bolder',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {personnel?.vacationLeaveBalance || '---'}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        personnel?.gender === 'female'
                                            ? 'Maternity Leave Balance:'
                                            : 'Paternity Leave Balance:'
                                    }
                                    labelStyle={{
                                        color: 'white',
                                        fontSize: 'smaller',
                                    }}
                                    contentStyle={{
                                        color: 'white',
                                        fontWeight: 'bolder',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {personnel?.gender === 'female'
                                        ? personnel?.maternityLeaveBalance ||
                                          '---'
                                        : personnel?.paternityLeaveBalance ||
                                          '---'}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} md={12}>
                    <Card
                        bordered={false}
                        headStyle={{
                            borderBottom: 'none',
                        }}
                        title={
                            <div style={{ color: 'white' }}>
                                <Row
                                    align={'middle'}
                                    gutter={[12, 12]}
                                    wrap={false}
                                >
                                    <Col>
                                        <div
                                            style={{
                                                height: 25,
                                                width: 10,
                                                borderRadius: 10,
                                                backgroundColor: styles.primary,
                                            }}
                                        ></div>
                                    </Col>
                                    <Col>
                                        <Typography.Title
                                            level={4}
                                            style={{
                                                marginBottom: 0,
                                                color: 'white',
                                            }}
                                        >
                                            In case of emergency
                                        </Typography.Title>
                                    </Col>
                                </Row>
                            </div>
                        }
                        style={{
                            backgroundColor: '#272829',
                            border: 'none',
                            borderRadius: 12,
                            height: '100%',
                        }}
                    >
                        <div>
                            <Descriptions
                                layout={'horizontal'}
                                colon={false}
                                style={{ textAlign: 'left' }}
                            >
                                <Descriptions.Item
                                    label="Name:"
                                    span={24}
                                    labelStyle={{
                                        color: 'white',
                                        fontSize: 'smaller',
                                    }}
                                    contentStyle={{
                                        color: 'white',
                                        fontWeight: 'bolder',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {personnel?.emergencyName || '---'}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    span={24}
                                    label="Number:"
                                    labelStyle={{
                                        color: 'white',
                                        fontSize: 'smaller',
                                    }}
                                    contentStyle={{
                                        color: 'white',
                                        fontWeight: 'bolder',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {personnel?.emergencyPhone || '---'}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label="Address:"
                                    labelStyle={{
                                        color: 'white',
                                        fontSize: 'smaller',
                                    }}
                                    contentStyle={{
                                        color: 'white',
                                        fontWeight: 'bolder',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {personnel?.emergencyPhone || '---'}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row gutter={xs ? [8, 24] : [24, 24]}>
                <Col xs={24} md={12}>
                    <div
                        style={{
                            backgroundColor: '#272829',
                            border: 'none',
                            borderRadius: 12,
                            padding: '16px 24px 24px',
                            overflowY: 'scroll',
                            maxHeight: 300,
                        }}
                    >
                        <Row
                            align={'middle'}
                            gutter={[12, 12]}
                            style={{ paddingBottom: 40 }}
                        >
                            <Col>
                                <div
                                    style={{
                                        height: 25,
                                        width: 10,
                                        borderRadius: 10,
                                        backgroundColor: styles.primary,
                                    }}
                                ></div>
                            </Col>
                            <Col>
                                <Typography.Title
                                    level={4}
                                    style={{
                                        marginBottom: 0,
                                        color: 'white',
                                    }}
                                >
                                    Payslips
                                </Typography.Title>
                            </Col>
                        </Row>
                        {payslips.length ? (
                            <List
                                size="small"
                                dataSource={payslips}
                                style={{ color: 'white' }}
                                bordered
                                renderItem={(item, idx) => {
                                    // eslint-disable-next-line no-console
                                    // eslint-disable-next-line no-unused-vars
                                    let totalDeductions = 0
                                    if (item.deductions) {
                                        item.deductions.forEach(
                                            (deduction: any) => {
                                                if (
                                                    deduction.isChecked &&
                                                    item[deduction.key]
                                                ) {
                                                    totalDeductions +=
                                                        item[deduction.key]
                                                }
                                            }
                                        )
                                    }
                                    return (
                                        <List.Item
                                            style={{ position: 'relative' }}
                                        >
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 10,
                                                    right: 16,
                                                    zIndex: 99,
                                                }}
                                            >
                                                <PayslipPDF data={item} />
                                            </div>
                                            <Comment
                                                datetime={`${moment
                                                    .unix(item.start)
                                                    .format('MMMM DD')}-${moment
                                                    .unix(item.end)
                                                    .format('DD, YYYY')}`}
                                                content={
                                                    <div
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    >
                                                        <table>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        width: 100,
                                                                        fontWeight:
                                                                            'lighter',
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Gross Pay
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: 80,
                                                                        fontWeight: 500,
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {(
                                                                        (item.rate ||
                                                                            0) *
                                                                            (item.days ||
                                                                                0) +
                                                                        (item.incentives ||
                                                                            0) +
                                                                        (item.overtime ||
                                                                            0)
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: 12,
                                                                    }}
                                                                ></td>

                                                                <Tooltip
                                                                    trigger={[
                                                                        'click',
                                                                    ]}
                                                                    open={
                                                                        totalDeductions >
                                                                        0
                                                                            ? undefined
                                                                            : false
                                                                    }
                                                                    overlayInnerStyle={{
                                                                        backgroundColor:
                                                                            'black',
                                                                    }}
                                                                    overlay={
                                                                        <div
                                                                            style={{
                                                                                width: '200px',
                                                                            }}
                                                                        >
                                                                            <Typography.Title
                                                                                level={
                                                                                    5
                                                                                }
                                                                                style={{
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Deductions
                                                                            </Typography.Title>
                                                                            <table
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                {item.deductions &&
                                                                                    item.deductions.map(
                                                                                        (
                                                                                            deduction: any
                                                                                        ) => {
                                                                                            if (
                                                                                                !deduction.isChecked
                                                                                            )
                                                                                                return null
                                                                                            return (
                                                                                                <tr
                                                                                                    key={
                                                                                                        deduction.key
                                                                                                    }
                                                                                                >
                                                                                                    <td
                                                                                                        style={{
                                                                                                            fontSize: 12,
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            deduction.title
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td
                                                                                                        style={{
                                                                                                            textAlign:
                                                                                                                'right',
                                                                                                        }}
                                                                                                    >
                                                                                                        <Statistic
                                                                                                            value={
                                                                                                                item[
                                                                                                                    deduction
                                                                                                                        .key
                                                                                                                ]
                                                                                                            }
                                                                                                            precision={
                                                                                                                2
                                                                                                            }
                                                                                                            prefixCls="₱"
                                                                                                            prefix="₱"
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    )}
                                                                            </table>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <td
                                                                        style={{
                                                                            fontWeight:
                                                                                'lighter',
                                                                            fontSize: 12,
                                                                            textDecoration:
                                                                                totalDeductions >
                                                                                0
                                                                                    ? 'underline'
                                                                                    : undefined,
                                                                            cursor:
                                                                                totalDeductions >
                                                                                0
                                                                                    ? 'pointer'
                                                                                    : undefined,
                                                                        }}
                                                                    >
                                                                        Total
                                                                        Deduction
                                                                    </td>
                                                                </Tooltip>

                                                                <td
                                                                    style={{
                                                                        fontWeight: 500,
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    <Statistic
                                                                        value={
                                                                            totalDeductions
                                                                        }
                                                                        precision={
                                                                            2
                                                                        }
                                                                        prefixCls="₱"
                                                                        prefix="₱"
                                                                    />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        width: 100,
                                                                        fontWeight:
                                                                            'lighter',
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Net Pay
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: 80,
                                                                        fontWeight: 500,
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {(
                                                                        item.netPay ||
                                                                        0
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>

                                                                <td
                                                                    style={{
                                                                        width: 12,
                                                                    }}
                                                                ></td>
                                                                <td
                                                                    style={{
                                                                        width: 100,
                                                                        fontWeight:
                                                                            'lighter',
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Rounded
                                                                    Total
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: 80,
                                                                        fontWeight: 500,
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {formatNumberWithCommas(
                                                                        `${Math.round(
                                                                            item.netPay ||
                                                                                0
                                                                        )}`
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td
                                                                    style={{
                                                                        width: 150,
                                                                        fontWeight:
                                                                            'lighter',
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Basic
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: 100,
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    ₱{(item.rate || 0) * (item.days || 0)}

                                                                </td>
                                                            </tr> */}
                                                            {/* <tr>
                                                                <td
                                                                    style={{
                                                                        width: 150,
                                                                        fontWeight:
                                                                            'lighter',
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Incentives
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: 100,
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {item.incentives
                                                                        ? item.incentives
                                                                        : '---'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        width: 150,
                                                                        fontWeight:
                                                                            'lighter',
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    OT Pay/Hours
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: 100,
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {item.overtime
                                                                        ? item.overtime
                                                                        : 0}
                                                                    /
                                                                    {item.overtimeHours
                                                                        ? item.overtimeHours
                                                                        : 0}
                                                                    h
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        width: 150,
                                                                        fontWeight:
                                                                            'lighter',
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Gross Pay
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: 100,
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {item.salary
                                                                        ? item.salary
                                                                        : '---'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        width: 150,
                                                                        fontWeight:
                                                                            'lighter',
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Total Deductions
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: 100,
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {item.totalDeduction
                                                                        ? item.totalDeduction
                                                                        : '---'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        width: 150,
                                                                        fontWeight:
                                                                            'lighter',
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Net
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: 100,
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    ₱
                                                                    {item.netPay
                                                                        ? item.netPay
                                                                        : '---'}
                                                                </td>
                                                            </tr> */}
                                                        </table>
                                                    </div>
                                                }
                                                author={
                                                    <Typography.Title
                                                        level={5}
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    >
                                                        #000{idx + 1}
                                                    </Typography.Title>
                                                }
                                            />
                                        </List.Item>
                                    )
                                }}
                            />
                        ) : (
                            <Empty style={{ color: 'white' }} />
                        )}
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <Card
                        bordered={false}
                        headStyle={{
                            borderBottom: 'none',
                        }}
                        extra={
                            <Button
                                type="link"
                                icon={
                                    <MdAttachFile
                                        style={{
                                            fontSize: 24,
                                            color: '#1890ff',
                                            fontWeight: 'bold',
                                        }}
                                    />
                                }
                                onClick={() => setFilesModal(true)}
                            ></Button>
                        }
                        title={
                            <div style={{ color: 'white' }}>
                                <Row
                                    align={'middle'}
                                    gutter={[12, 12]}
                                    wrap={false}
                                >
                                    <Col>
                                        <div
                                            style={{
                                                height: 25,
                                                width: 10,
                                                borderRadius: 10,
                                                backgroundColor: styles.primary,
                                            }}
                                        ></div>
                                    </Col>
                                    <Col>
                                        <Typography.Title
                                            level={4}
                                            style={{
                                                marginBottom: 0,
                                                color: 'white',
                                            }}
                                        >
                                            Attachments
                                        </Typography.Title>
                                    </Col>
                                </Row>
                            </div>
                        }
                        style={{
                            backgroundColor: '#272829',
                            border: 'none',
                            height: '100%',
                            borderRadius: 12,
                            overflowY: 'scroll',
                            maxHeight: 300,
                        }}
                    >
                        <AddFilesModal
                            filesModal={filesModal}
                            setFilesModal={setFilesModal}
                            // userId={user.id || user.uid || ''}
                            loadingUpload={loadingUpload}
                            setLoadingUpload={setLoadingUpload}
                            folderId={'employee-records'}
                            onCancel={handleCancelAddFiles}
                            employeeId={personnel?.id || ''}
                        />
                        <div
                            style={{
                                overflowY: 'scroll',
                            }}
                        >
                            {fileList.length === 0 ? (
                                <div>
                                    <Empty style={{ color: 'white' }} />
                                </div>
                            ) : (
                                <List
                                    bordered
                                    dataSource={fileList}
                                    style={{ color: 'white' }}
                                    renderItem={(item: any) => (
                                        <List.Item title={item.name}>
                                            <Comment
                                                style={{
                                                    color: '#1890ff',
                                                }}
                                                content={
                                                    <Typography.Link
                                                        onClick={() =>
                                                            window.open(
                                                                item.fileUrl,
                                                                '_blank'
                                                            )
                                                        }
                                                    >
                                                        {item.name}
                                                    </Typography.Link>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            )}
                        </div>
                    </Card>
                </Col>
            </Row>
            <br />
            <Card
                title={
                    <div style={{ color: 'white' }}>
                        <Row justify={'space-between'}>
                            <Col>
                                <Row align={'middle'} gutter={[12, 12]}>
                                    <Col>
                                        <div
                                            style={{
                                                height: 25,
                                                width: 10,
                                                borderRadius: 10,
                                                backgroundColor: styles.primary,
                                            }}
                                        ></div>
                                    </Col>
                                    <Col>
                                        <Typography.Title
                                            level={4}
                                            style={{
                                                marginBottom: 0,
                                                color: 'white',
                                            }}
                                        >
                                            Current Attendance Logs
                                        </Typography.Title>
                                    </Col>
                                </Row>
                            </Col>
                            <Col></Col>
                        </Row>
                    </div>
                }
                headStyle={{ borderBottom: 'none' }}
                style={{
                    backgroundColor: '#272829',
                    border: 'none',
                    borderRadius: 12,
                }}
            >
                {!attendance.length && <Empty style={{ color: 'white' }} />}
                <Row gutter={[24, 24]}>
                    {attendance.map((att: Attendance) => {
                        return (
                            <Col key={att.id} xs={24} sm={12} md={8}>
                                <Card
                                    bordered={false}
                                    headStyle={{
                                        borderBottom: 'none',
                                    }}
                                    title={
                                        <div style={{ color: 'white' }}>
                                            <Row
                                                wrap={false}
                                                justify={'space-between'}
                                            >
                                                <Col>
                                                    <ClockCircleOutlined />{' '}
                                                    {att.date}
                                                </Col>
                                                <Col>
                                                    <Tag
                                                        color={
                                                            att.status ===
                                                            'pending'
                                                                ? 'darkorange'
                                                                : att.status ===
                                                                  'absent'
                                                                ? '#bd4c5f'
                                                                : 'darkgreen'
                                                        }
                                                        style={{
                                                            borderRadius: 10,
                                                        }}
                                                    >
                                                        {att.status ===
                                                        'pending'
                                                            ? 'Pending'
                                                            : att.status ===
                                                              'absent'
                                                            ? 'Absent'
                                                            : 'Done'}
                                                    </Tag>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    style={{
                                        backgroundColor: '#434343',
                                        border: 'none',
                                        borderRadius: 12,
                                    }}
                                    bodyStyle={{
                                        color: 'white',
                                        padding: '0 0 12px 0',
                                    }}
                                >
                                    <div style={{ padding: '0 12px 0 18px' }}>
                                        {att.employeeRole &&
                                        (att.employeeRole === 'driver' ||
                                            att.employeeRole ===
                                                'assistant') ? (
                                            <Row>
                                                <Col span={24}>
                                                    <Row
                                                        wrap={false}
                                                        style={{
                                                            fontSize: 'smaller',
                                                        }}
                                                    >
                                                        <Col span={12}>
                                                            Tandem
                                                        </Col>
                                                        <Col span={12}>
                                                            Unit Details
                                                        </Col>
                                                    </Row>
                                                    <Row
                                                        wrap={false}
                                                        style={{
                                                            marginBottom: 12,
                                                        }}
                                                    >
                                                        <Col
                                                            span={12}
                                                            style={{
                                                                fontWeight:
                                                                    'bolder',
                                                            }}
                                                        >
                                                            {att.title
                                                                ? att.title
                                                                : '---'}
                                                        </Col>
                                                        <Col
                                                            span={12}
                                                            style={{
                                                                fontWeight:
                                                                    'bolder',
                                                            }}
                                                        >
                                                            {att.routeName
                                                                ? `${att.routeName} - ${att.unit}`
                                                                : '---'}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ) : null}
                                        <Row wrap={false} style={{}}>
                                            <Col span={12}>
                                                <span style={{ fontSize: 12 }}>
                                                    Check In
                                                </span>
                                            </Col>
                                            <Col span={12}>
                                                <span style={{ fontSize: 12 }}>
                                                    Check Out
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row wrap={false}>
                                            <Col
                                                span={12}
                                                style={{ fontWeight: 'bolder' }}
                                            >
                                                {att.timeIn
                                                    ? moment
                                                          .unix(att.timeIn)
                                                          .format('hh:mm a')
                                                    : '---'}
                                            </Col>
                                            <Col
                                                span={12}
                                                style={{ fontWeight: 'bolder' }}
                                            >
                                                {att.timeOut
                                                    ? moment
                                                          .unix(att.timeOut)
                                                          .format('hh:mm a')
                                                    : '---'}
                                            </Col>
                                        </Row>
                                        {!att.rfid && (
                                            <Divider
                                                style={{ margin: '8px 0' }}
                                            />
                                        )}
                                        {att.rfid ? null : (
                                            <table>
                                                <tr>
                                                    <td
                                                        style={{
                                                            width: 150,
                                                            fontWeight:
                                                                'lighter',
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        Time Rendered
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: 100,
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {att.hoursRendered
                                                            ? att.hoursRendered
                                                            : '---'}
                                                        h
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            width: 150,
                                                            fontWeight:
                                                                'lighter',
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        Late
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: 100,
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {att.minutesLate
                                                            ? att.minutesLate
                                                            : '---'}
                                                        m
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            width: 150,
                                                            fontWeight:
                                                                'lighter',
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        Salary
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: 100,
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        ₱
                                                        {att.salary
                                                            ? att.salary
                                                            : '---'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            width: 150,
                                                            fontWeight:
                                                                'lighter',
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        Incentives
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: 100,
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        ₱
                                                        {att.incentives
                                                            ? att.incentives
                                                            : '---'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            width: 150,
                                                            fontWeight:
                                                                'lighter',
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        Overtime Pay
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: 100,
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        ₱
                                                        {att.overtimePay
                                                            ? att.overtimePay
                                                            : '---'}{' '}
                                                        / {att.overtimeHours}h
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <hr />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>Gross Pay</td>
                                                    <td>
                                                        ₱
                                                        {(att.incentives || 0) +
                                                            (att.salary || 0) +
                                                            (att.overtimePay ||
                                                                0)}
                                                    </td>
                                                </tr>
                                            </table>
                                        )}
                                    </div>

                                    <br />
                                    {att.status === 'pending' &&
                                        (role === 'hr_manager' ||
                                            role === 'chairman' ||
                                            role === 'general_manager') && (
                                            <Row
                                                justify="space-evenly"
                                                style={{
                                                    borderTop:
                                                        '1px solid rgba(50, 50, 50, .3)',
                                                    paddingTop: 12,
                                                }}
                                            >
                                                <Col>
                                                    <Tooltip title="Delete Attendance">
                                                        <Popconfirm
                                                            title={
                                                                "This can't be undone. Are you sure?"
                                                            }
                                                            onConfirm={async () => {
                                                                const ref = doc(
                                                                    db,
                                                                    PERSONNEL_ATTENDANCE_REPOSITORY,
                                                                    att.id as string
                                                                )
                                                                const newAtt = [
                                                                    ...attendance,
                                                                ]
                                                                const idx =
                                                                    newAtt.findIndex(
                                                                        (
                                                                            attendance
                                                                        ) =>
                                                                            attendance.id ===
                                                                            att.id
                                                                    )
                                                                if (idx >= 0) {
                                                                    newAtt.splice(
                                                                        idx,
                                                                        1
                                                                    )
                                                                    setAttendance(
                                                                        newAtt
                                                                    )
                                                                    await deleteDoc(
                                                                        ref
                                                                    )
                                                                    message.success(
                                                                        'Successfully deleted'
                                                                    )
                                                                }
                                                            }}
                                                        >
                                                            <Button
                                                                type="link"
                                                                key="edit"
                                                            >
                                                                <DeleteFilled
                                                                    style={{
                                                                        fontSize: 24,
                                                                        fontWeight:
                                                                            'bold',
                                                                        color: '#bd4c5f',
                                                                    }}
                                                                />
                                                            </Button>
                                                        </Popconfirm>
                                                    </Tooltip>
                                                </Col>
                                                {/* <Col>
                                                    <Tooltip title="Mark as Absent">
                                                        <Popconfirm
                                                            title={
                                                                "This can't be undone. Are you sure?"
                                                            }
                                                            onConfirm={() =>
                                                                handleUpdateAttendance(
                                                                    att,
                                                                    'absent'
                                                                )
                                                            }
                                                        >
                                                            <Button
                                                                type="link"
                                                                key="edit"
                                                            >
                                                                <CloseCircleFilled
                                                                    style={{
                                                                        fontSize: 24,
                                                                        fontWeight:
                                                                            'bold',
                                                                        color: '#bd4c5f',
                                                                    }}
                                                                />
                                                            </Button>
                                                        </Popconfirm>
                                                    </Tooltip>
                                                </Col> */}
                                                <Col>
                                                    <Tooltip
                                                        title={
                                                            'Update Attendance'
                                                        }
                                                    >
                                                        <Button
                                                            type="link"
                                                            onClick={() =>
                                                                handleShowUpdateAttendance(
                                                                    att
                                                                )
                                                            }
                                                        >
                                                            <EditFilled
                                                                style={{
                                                                    fontSize: 24,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </Col>
                                                {/* <Col>
                                                    <Tooltip title="Mark as Done">
                                                        <Popconfirm
                                                            title={
                                                                "This can't be undone. Are you sure?"
                                                            }
                                                            onConfirm={() =>
                                                                handleUpdateAttendance(
                                                                    att,
                                                                    'done'
                                                                )
                                                            }
                                                        >
                                                            <Button
                                                                type="link"
                                                                key="accept"
                                                            >
                                                                <CheckCircleFilled
                                                                    style={{
                                                                        fontSize: 24,
                                                                        fontWeight:
                                                                            'bold',
                                                                        color: '#5cb85c',
                                                                    }}
                                                                />
                                                            </Button>
                                                        </Popconfirm>
                                                    </Tooltip>
                                                </Col> */}
                                            </Row>
                                        )}
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Card>
            <br />
        </div>
    )
}

export default PersonnelDashboard
