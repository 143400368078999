import { Row, Col } from 'antd'
import { withTranslation } from 'react-i18next'
import { ContentBlockProps } from '../types'
import { Fade } from 'react-awesome-reveal'
import {
    LeftContentSection,
    Content,
    ContentWrapper,
    ServiceWrapper,
    MinTitle,
    MinPara,
} from './styles'

const LeftContentBlock = ({
    // icon,
    title,
    content,
    section,
    t,
    id,
}: ContentBlockProps) => {
    return (
        <LeftContentSection>
            <Fade direction="left">
                <Row justify="space-between" align="middle" id={id}>
                    {/* <Col lg={11} md={11} sm={12} xs={24}>
            <SvgIcon src={icon} width="100%" height="100%" />
          </Col> */}
                    <Col span={24}>
                        <ContentWrapper>
                            <h6 style={{ textAlign: 'left' }}>{t(title)}</h6>
                            <Content style={{ textAlign: 'left' }}>
                                {t(content)}
                            </Content>
                        </ContentWrapper>
                        <br />
                        <br />
                        <ServiceWrapper>
                            <Row justify="space-evenly" gutter={[12, 12]}>
                                {typeof section === 'object' &&
                                    section.map((item: any, id: number) => {
                                        return (
                                            <Col
                                                key={id}
                                                lg={6}
                                                sm={12}
                                                xs={24}
                                            >
                                                <Row justify={'center'}>
                                                    <Col>
                                                        <div>
                                                            <img
                                                                alt={item.title}
                                                                src={item.icon}
                                                                style={{
                                                                    borderRadius: 10,
                                                                    border: '3px solid black',
                                                                }}
                                                                width="100%"
                                                                height="auto"
                                                            />
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <MinTitle
                                                            style={{
                                                                textAlign:
                                                                    'left',
                                                            }}
                                                        >
                                                            {t(item.title)}
                                                        </MinTitle>
                                                        <MinPara
                                                            style={{
                                                                padding: 6,
                                                            }}
                                                        >
                                                            {t(item.content)}
                                                        </MinPara>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )
                                    })}
                            </Row>
                        </ServiceWrapper>
                    </Col>
                </Row>
            </Fade>
        </LeftContentSection>
    )
}

export default withTranslation()(LeftContentBlock)
