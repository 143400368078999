import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import { Row, Col, Button } from 'antd'
// import { useNavigate } from 'react-router-dom'
import { getTeam } from '../../../../app/redux/team/team.action'
import { updateTeamState } from '../../../../app/redux/team/team.slice'
import { useAppDispatch } from '../../../../app/store'
import ClientHeader from '../ClientLandingPage/ClientHeader'
// const searchEngine = 'Google';

const PeratiHeader = ({
    isFirstScreen,
    isMobile,
}: {
    isMobile: boolean
    isFirstScreen?: boolean
}) => {
    // const [menuVisible, setMenuVisible] = useState(false);
    // const navigate = useNavigate()
    // const onMenuVisibleChange = (visible) => {
    //   setMenuVisible(visible);
    // };

    // const handleShowMenu = () => {
    //   setMenuVisible(true);
    // };

    // const handleHideMenu = () => {
    //   setMenuVisible(false);
    // };

    // const handleSelectFilter = (value, option) => {
    //   const optionValue = option.props['data-label'];
    //   return (
    //     optionValue === searchEngine ||
    //     optionValue.indexOf(value.toLowerCase()) > -1
    //   );
    // };
    const dispatch = useAppDispatch()

    const split = window.location.hostname.split('.')
    const host = split[0]

    useEffect(() => {
        const getTeamData = async () => {
            // const queryParamsString = location.search.substring(1), // remove the "?" at the start
            //     searchParams = new URLSearchParams(queryParamsString),
            //     partnerCode = searchParams.get('partnerCode')
            // invitedBy = searchParams.get('invitedBy')

            const teamData = await getTeam(host)

            dispatch(
                updateTeamState({
                    selectedTeamData: teamData,
                })
            )
            // setInviter(teamData)
        }
        getTeamData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [host])
    const headerClassName = classNames({
        clearfix: true,
        'home-nav-white': !isFirstScreen,
    })

    return (
        <header
            id="header"
            className={headerClassName}
            style={{ padding: isMobile ? '0 12px' : undefined }}
        >
            <ClientHeader isMobile={isMobile} />
            {/* <nav
                style={{
                    height: 80,
                    padding: isMobile ? 0 : '0 40px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Row
                    justify="space-between"
                    style={{ width: '100%' }}
                    wrap={false}
                >
                    <Col xs={8}>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/')}
                        >
                            <img
                                src={'/logo192.png'}
                                width={isMobile ? '100%' : 'auto'}
                                height={isMobile ? '100%' : 60}
                                alt={'Company logo'}
                            />
                        </div>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                        <Row align="middle" wrap={false}>
                            <Col>
                                <Button
                                    size="large"
                                    shape="round"
                                    onClick={() => navigate('/login')}
                                >
                                    Log In
                                </Button>
                            </Col>
                            <Col>
                <Button size="large" shape="round" onClick={() => navigate('/register')}>
                  Sign up
                </Button>
              </Col>
                        </Row>
                    </Col>
                </Row>
            </nav> */}
        </header>
    )
}

PeratiHeader.propTypes = {
    isFirstScreen: PropTypes.bool,
    isMoblie: PropTypes.bool,
}

export default PeratiHeader
