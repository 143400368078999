import { positions } from '../../data/repositories/constants'

export const getUserRoute = (role: any): string => {
    const employeePositions = positions
    let val = 'err'
    employeePositions.forEach((position, idx) => {
        if (position.value === role) {
            val = `${idx}`
        }
    })
    return val
}
