import { useAppDispatch, useAppSelector } from '../../store'
import { useNavigate, useParams } from 'react-router-dom'
import { capitalize } from '../utils'
import { getMember, updateMember } from '../../redux/members/members.slice'
import { Button, Card, message } from 'antd'
import {
    ProForm,
    ProFormDatePicker,
    ProFormText,
} from '@ant-design/pro-components'
import React, { useEffect, useState } from 'react'
import { Member } from '../../../domain/entities/Member'
// import moment from 'moment'
import { shallowEqual } from 'react-redux'
export const memberStateInitialValues: Member = {
    id: '',
    firstName: '',
    lastName: '',
    // middleName: '',
    creation: 0,
    tags: [],
    name: '',
    modified: 0,
    modifiedBy: '',
    memberPreferredShares: 0,
    memberShares: 0,
    totalShare: 0,
    totalLoan: 0,
    totalIncomeContribution: 0,
    totalRemainingBalance: 0,
    currentSavingsBalance: 0,
    overallWithdrawals: 0,
    overallDeposits: 0,
    email: '',
    phoneNumber: '',
    accountNumber: '',
    // weightedMemberShare: {
    //     [moment().format('YYYY')]: {
    //         [moment().format('MMMM')]: 0,
    //         Annual: 0,
    //     },
    // },
    // memberShare: {
    //     [moment().format('YYYY')]: {
    //         [moment().format('MMMM')]: 0,
    //         Annual: 0,
    //     },
    // },
    // memberLoan: {
    //     [moment().format('YYYY')]: {
    //         [moment().format('MMMM')]: 0,
    //         Annual: 0,
    //     },
    // },
    // incomeContribution: {
    //     [moment().format('YYYY')]: {
    //         [moment().format('MMMM')]: 0,
    //         Annual: 0,
    //     },
    // },
    // memberLoanRemainingBalance: {
    //     [moment().format('YYYY')]: {
    //         [moment().format('MMMM')]: 0,
    //         Annual: 0,
    //     },
    // },
    isRemoved: false,
    dateOfBirth: 0,
    gender: 'male',
    phone: '',
    presentAddress: '',
    overallUnpaidInterest: 0,
    overallLoan: 0,
    monthlyShareCapitalDeductions: 0,
    sickLeaveBalance: 5,
    vacationLeaveBalance: 5,
    paternityLeaveBalance: 7,
    maternityLeaveBalance: 105,
    role: 'regular_member',
    partnerCode: '',
    displayName: '',
}
function UpdateMember() {
    const dispatch = useAppDispatch()
    const { styles, activeTeam } = useAppSelector(
        (state) => ({
            styles: state.styles,
            activeTeam: state.auth.user.activeTeam,
        }),
        shallowEqual
    )

    const navigate = useNavigate()
    const { memberId } = useParams()
    // const [loading, setLoading] = useState(false)
    const [form] = ProForm.useForm()
    const [values, setValues] = useState<Member>(memberStateInitialValues)
    const handleFinish = async (value: any) => {
        dispatch(
            updateMember({
                ...values,
                ...value,
                id: memberId,
                name: capitalize(`${value.firstName} ${value.lastName}`),
            })
        )
        message.success('successfully updated')
        navigate(`teams/${activeTeam}/members`, {
            replace: true,
        })
    }

    const handleGetMember = () => {
        if (!memberId) return
        const member = getMember(memberId)
        setValues({
            ...values,
            ...member,
        })
        form.setFieldsValue(member)
    }
    useEffect(() => {
        handleGetMember()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 20,
                height: '100%',
                backgroundColor: 'white',
            }}
        >
            <Card
                style={{
                    maxWidth: 500,

                    boxShadow: '0 20px 40px rgb(57 76 96 / 15%)',
                }}
            >
                <ProForm
                    form={form}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            form.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    autoFocusFirstInput
                    onFinish={handleFinish}
                    initialValues={{
                        ...values,
                    }}
                    submitter={false}
                    // submitter={{
                    //     render: ({ form, onSubmit }) => {
                    //         return [
                    //             <Button
                    //                 key="next"
                    //                 loading={loading}
                    //                 type="primary"
                    //                 onClick={() => {
                    //                     onSubmit?.(form.getFieldsValue())
                    //                 }}
                    //             >
                    //                 Update
                    //             </Button>,
                    //             <Button
                    //                 key="reset"
                    //                 onClick={() => {
                    //                     form?.resetFields()
                    //                 }}
                    //             >
                    //                 Reset
                    //             </Button>
                    //         ]
                    //     }
                    // }}
                >
                    {/*<ProForm.Group>*/}
                    {/*    <ProFormSelect*/}
                    {/*        rules={[*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*                message: 'This field is required.',*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*        options={[*/}
                    {/*            {*/}
                    {/*                value: 'complete',*/}
                    {/*                label: 'Complete',*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                value: 'incomplete',*/}
                    {/*                label: 'Incomplete',*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*        name="memberStatus"*/}
                    {/*        label={'Member Status'}*/}
                    {/*    />*/}
                    {/*    <ProFormSelect*/}
                    {/*        rules={[*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*                message: 'This field is required.',*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*        options={[*/}
                    {/*            {*/}
                    {/*                value: 'qualified',*/}
                    {/*                label: 'Qualified',*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                value: 'unqualified',*/}
                    {/*                label: 'Unqualified',*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*        name="loanStatus"*/}
                    {/*        label={'Loan Status'}*/}
                    {/*    />*/}
                    {/*</ProForm.Group>*/}
                    {/*<Divider style={{ margin: '12px 0' }} />*/}
                    <ProFormText
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        label="First Name"
                        name="firstName"
                        width={'md'}
                    />
                    <ProFormText
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        label="Last Name"
                        name="lastName"
                        width={'md'}
                    />

                    <ProFormDatePicker
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        name={'startDate'}
                        label="Start Date"
                        placeholder="May 23, 1990"
                        width={'sm'}
                        fieldProps={{
                            format: 'MMMM DD, YYYY',
                        }}
                    />

                    <Button
                        htmlType="submit"
                        size="large"
                        // loading={isLoading}
                        // disabled={password.length > 1 && email.length > 1 ? false : true}
                        style={{
                            fontWeight: 'bold',
                            width: '100%',
                            color: 'white',
                            backgroundColor: styles.primary,
                        }}
                    >
                        Update
                    </Button>
                </ProForm>
            </Card>
        </div>
    )
}

export default UpdateMember
