import React from 'react'
// import { shallowEqual } from 'react-redux'
// import { useAppSelector } from '../../../store'
import LoansReport from './LoansReport'
// import MemberLoansReport from './Members/MemberLoansReport'

const LoansContainer = () => {
    // const { isViewer } = useAppSelector(
    //     (state) => ({
    //         isViewer: state.team.currentTeamPermission.role === 'viewer',
    //     }),
    //     shallowEqual
    // )
    return <LoansReport />
}

export default LoansContainer
