import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    increment,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import moment from 'moment'
import { db } from '../../../app/boot'
import { Borrow, BorrowerPayment } from '../../../domain/entities/Borrow'
import { BorrowRepository } from '../../../domain/repositories/borrow.repository'
import {
    BORROWERS_REPOSITORY,
    PAYMENTS_REPOSITORY,
    TEAM_REPOSITORY,
} from '../constants'
import { TeamFirebaseImplementation } from '../team/team.firebase'

export class BorrowFirebaseImplementation
    extends TeamFirebaseImplementation
    implements BorrowRepository
{
    async borrow(payload: Borrow): Promise<Borrow> {
        const ref = collection(db, BORROWERS_REPOSITORY)
        const docRef = await addDoc(ref, payload)

        const yearOfLoan = moment.unix(payload.creation).format('YYYY')
        const monthOfLoan = moment.unix(payload.creation).format('MMMM')
        await this.addLoanToTeam(
            yearOfLoan,
            monthOfLoan,
            payload.partnerCode,
            payload.amount,
            payload.amount
        )
        payload.id = docRef.id
        return payload
    }
    async updateBorrower(id: string, payload: Partial<Borrow>) {
        const ref = doc(db, BORROWERS_REPOSITORY, id)
        await updateDoc(ref, payload)
        return {
            ...(payload as Borrow),
            id: id,
        }
    }
    async deleteBorrower(payload: Borrow) {
        const ref = doc(db, BORROWERS_REPOSITORY, payload.id as string)
        const payments = collection(
            db,
            BORROWERS_REPOSITORY,
            payload.id as string,
            PAYMENTS_REPOSITORY
        )

        const yearOfLoan = moment.unix(payload.creation).format('YYYY')
        const monthOfLoan = moment.unix(payload.creation).format('MMMM')
        const teamRef = doc(db, TEAM_REPOSITORY, payload.partnerCode)
        await updateDoc(teamRef, {
            [`overallLoan.${yearOfLoan}.Annual`]: increment(-payload.amount),
            [`overallLoan.${yearOfLoan}.${monthOfLoan}`]: increment(
                -payload.amount
            ),
            [`remainingBalance.${yearOfLoan}.Annual`]: increment(
                -payload.remainingBalance
            ),
            [`remainingBalance.${yearOfLoan}.${monthOfLoan}`]: increment(
                -payload.remainingBalance
            ),
        })
        if (payload.payments.length) {
            const getPayments = await getDocs(payments)
            const refs = []
            const promises = getPayments.docs.map(async (docSnap) => {
                refs.push(docSnap.ref)
                const paymentdata = docSnap.data()
                const yearOfPayment = moment
                    .unix(paymentdata.creation)
                    .format('YYYY')
                const monthOfPayment = moment
                    .unix(paymentdata.creation)
                    .format('MMMM')
                await this.addIncomeToTeam(
                    paymentdata.partnerCode,
                    yearOfPayment,
                    monthOfPayment,
                    -paymentdata.interest,
                    -paymentdata.principal,
                    0
                )
                await deleteDoc(docSnap.ref)
                return true
            })
            await Promise.all(promises)
        } else {
            // await this.addRemainingBalanceToSystem(
            //     payload.partnerCode,
            //     yearOfLoan,
            //     monthOfLoan,
            //     -payload.amount
            // )
        }

        // const ref = doc(db, TEAM_REPOSITORY, partnerCode)

        // if (getPayments.empty) {
        //     await updateDoc(teamRef, {
        //         [`overallLoan.${yearOfLoan}.Annual`]: increment(
        //             -payload.amount
        //         ),
        //         [`overallLoan.${yearOfLoan}.${monthOfLoan}`]: increment(
        //             -payload.amount
        //         ),
        //     })
        // }
        await deleteDoc(ref)
        return payload.id as string
    }
    async getBorrowers(
        payload: string | { partnerCode: string }
    ): Promise<Borrow[]> {
        if (typeof payload !== 'string') {
            const ref = query(
                collection(db, BORROWERS_REPOSITORY),
                where('partnerCode', '==', payload.partnerCode)
            )
            const docSnap = await getDocs(ref)
            const docMaps = docSnap.docs.map(
                (docData) =>
                    ({
                        ...docData.data(),
                        id: docData.id,
                    } as Borrow & {
                        id: string
                    })
            )

            return docMaps
        } else {
            const ref = doc(db, BORROWERS_REPOSITORY)
            const docSnap = await getDoc(ref)
            return [
                {
                    ...docSnap.data(),
                    id: docSnap.id,
                } as Borrow,
            ]
        }
    }

    async payBorrower(payload: BorrowerPayment): Promise<boolean> {
        const yearOfPayment = moment.unix(payload.creation).format('YYYY')
        const monthOfPayment = moment.unix(payload.creation).format('MMMM')
        const ref = doc(db, BORROWERS_REPOSITORY, payload.id)
        const borrowerPaymentCol = collection(
            db,
            BORROWERS_REPOSITORY,
            payload.id,
            PAYMENTS_REPOSITORY
        )
        const paymentRefDoc = await addDoc(borrowerPaymentCol, payload)

        await updateDoc(ref, {
            totalInterestPaid: increment(payload.interest),
            totalPrincipalPaid: increment(payload.principal),
            remainingBalance: increment(-payload.principal),
            payments: arrayUnion(paymentRefDoc.id),
        })
        await this.addIncomeToTeam(
            payload.partnerCode,
            yearOfPayment,
            monthOfPayment,
            payload.interest,
            payload.principal,
            -payload.principal
        )
        return true
    }

    async getPayments(id: string): Promise<BorrowerPayment[]> {
        const ref = collection(
            db,
            BORROWERS_REPOSITORY,
            id as string,
            PAYMENTS_REPOSITORY
        )
        const payments = await getDocs(ref)
        return payments.docs.map(
            (docSnap) =>
                ({
                    ...docSnap.data(),
                    id: docSnap.id,
                } as BorrowerPayment)
        )
    }

    async deleteBorrowerPayment(payload: {
        borrowerId: string
        payment: BorrowerPayment
    }): Promise<string> {
        const id = payload.payment.id
        const ref = doc(
            db,
            BORROWERS_REPOSITORY,
            payload.borrowerId as string,
            PAYMENTS_REPOSITORY,
            payload.payment.id
        )

        const borrowerRef = doc(
            db,
            BORROWERS_REPOSITORY,
            payload.borrowerId as string
        )

        const yearOfPayment = moment
            .unix(payload.payment.creation)
            .format('YYYY')
        const monthOfPayment = moment
            .unix(payload.payment.creation)
            .format('MMMM')

        await updateDoc(borrowerRef, {
            totalInterestPaid: increment(-payload.payment.interest),
            totalPrincipalPaid: increment(-payload.payment.principal),
            remainingBalance: increment(payload.payment.principal),
            payments: arrayRemove(payload.payment.id),
        })
        await this.addIncomeToTeam(
            payload.payment.partnerCode,
            yearOfPayment,
            monthOfPayment,
            -payload.payment.interest,
            -payload.payment.principal,
            payload.payment.principal
        )
        await deleteDoc(ref)
        return id
    }
}
