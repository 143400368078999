import React from 'react'
import { Modal, Row, Col } from 'antd'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'

interface PreviewFilesModalProps {
    previewModal: boolean
    eachFile: EachFileProps
    cancelHandler: (value: any) => void
}

export interface EachFileProps {
    creation: number
    id: string
    link: string
    modified?: number
    name: string
    size: number
    type: string
    fileUrl?: string
}

const PreviewFilesModal = ({
    previewModal,
    eachFile,
    cancelHandler,
}: PreviewFilesModalProps) => {
    // const splitted = eachFile && eachFile.link && eachFile.link.split(".")
    const fileType = eachFile.type || ''
    // eslint-disable-next-line no-console
    console.log(eachFile)
    const filedata =
        eachFile && eachFile.name.includes('.')
            ? eachFile.name
            : `${eachFile.name}.${fileType}`
    const docs = [
        {
            fileData: eachFile && filedata,
            fileType: eachFile && fileType,
            name: eachFile && eachFile.name,
            uid: eachFile && eachFile.id,
            uri: eachFile && (eachFile.fileUrl as string),
            url: eachFile && (eachFile.fileUrl as string),
        },
    ]

    return (
        <Modal
            width={936}
            visible={previewModal}
            footer={null}
            onCancel={cancelHandler}
            closeIcon={false}
            closable={false}
        >
            <Row>
                <Col span="24">
                    <DocViewer
                        documents={docs}
                        pluginRenderers={DocViewerRenderers}
                        style={{ height: '70vh' }}
                    />
                </Col>
            </Row>
        </Modal>
    )
}

export default PreviewFilesModal
