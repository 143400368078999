import { ProColumns } from '@ant-design/pro-components'
import moment from 'moment'
import { Loan } from '../../../../domain/entities/Loan'
import { Statistic, Typography } from 'antd'
import { MemberReceivable } from '../../../../domain/entities/MemberReceivable'
export const amortizationTableColumnYearly: ProColumns<any>[] = [
    {
        dataIndex: 'index',
        valueType: 'indexBorder',
        align: 'center',
        title: 'Month No.',
    },
    {
        title: 'Beginning Balance',
        dataIndex: 'beginningBalance',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `₱${text.toFixed(2)}`
        },
    },
    {
        title: 'Interest',
        dataIndex: 'interest',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `₱${text.toFixed(2)}`
        },
    },
    {
        title: 'Principal',
        dataIndex: 'principal',
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `₱${text.toFixed(2)}`
        },
    },
    {
        title: 'Ending Balance',
        dataIndex: 'endingBalance',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `₱${text.toFixed(2)}`
        },
    },
]
export const amortizationTableColumn: ProColumns<any>[] = [
    {
        dataIndex: 'index',
        valueType: 'indexBorder',
        align: 'center',
        title: 'No.',
    },

    {
        title: 'Due Date',
        dataIndex: 'due',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return moment.unix(text).format('MMM DD, YYYY')
        },
    },
    {
        title: 'Beginning Bal.',
        dataIndex: 'beginningBalance',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `₱${text.toFixed(2)}`
        },
    },

    {
        title: 'Monthly Due',
        dataIndex: 'loanDue',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `₱${text.toFixed(2)}`
        },
    },
    {
        title: 'Interest',
        dataIndex: 'interest',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `₱${text.toFixed(2)}`
        },
    },
    {
        title: 'Principal',
        dataIndex: 'principal',
        hideInSearch: true,
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `₱${text.toFixed(2)}`
        },
    },
    {
        title: 'Ending Bal.',
        dataIndex: 'endingBalance',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `₱${text.toFixed(2)}`
        },
    },
]
export const memberLoansColumn: ProColumns<Loan>[] = [
    {
        title: 'Balance',
        dataIndex: 'remainingBalance',
        width: 120,

        sorter: {
            compare: (a: any, b: any) =>
                b.remainingBalance - a.remainingBalance,
        },
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                text && (
                    <Statistic
                        value={text}
                        prefix={<Typography.Text>₱</Typography.Text>}
                        prefixCls={'₱'}
                        precision={2}
                    />
                )
            )
        },
    },
    {
        dataIndex: 'title',
        title: 'Title',
        width: 200,
    },
    {
        title: 'Due',
        dataIndex: 'due',
        defaultSortOrder: 'descend',
        width: 150,
        sorter: {
            compare: (a: any, b: any) => b.due - a.due,
        },
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return moment.unix(text).format('MMM DD, YYYY')
        },
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        sorter: {
            compare: (a: any, b: any) => b.amount - a.amount,
        },
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },

    // {
    //     title: 'Type',
    //     dataIndex: 'type',
    //     // sorter: {
    //     //     compare: (a: any, b: any) => b.interest - a.interest,
    //     // },

    //     // renderText: (
    //     //     text: any
    //     //     // record: MemberReceivable,
    //     //     // index: number,
    //     //     // action: ProCoreActionType
    //     // ) => {
    //     //     return (
    //     //         <Statistic
    //     //             value={text}
    //     //             suffix={<Typography.Text>%</Typography.Text>}
    //     //             prefixCls={'₱'}
    //     //         />
    //     //     )
    //     // },
    // },
    // {
    //     title: 'Interest Rate',
    //     dataIndex: 'interest',
    //     sorter: {
    //         compare: (a: any, b: any) => b.interest - a.interest,
    //     },

    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             <Statistic
    //                 value={text}
    //                 suffix={<Typography.Text>%</Typography.Text>}
    //                 prefixCls={'₱'}
    //             />
    //         )
    //     },
    // },
    // {
    //     title: 'Interest Paid',
    //     dataIndex: 'totalInterestPaid',
    //     sorter: true,

    //     renderText: (
    //         totalInterestPaid: any,
    //         record: any,
    //         index: any,
    //         action: any
    //     ) => {
    //         console.log(record)

    //         return (
    //             totalInterestPaid && (
    //                 <Statistic
    //                     value={totalInterestPaid}
    //                     prefix={<Typography.Text>₱</Typography.Text>}
    //                     prefixCls={'₱'}
    //                     precision={2}
    //                 />
    //             )
    //         )
    //     },
    // },
    // {
    //     title: 'Principal Paid',
    //     dataIndex: 'totalPrincipalPaid',
    //     sorter: {
    //         compare: (a: any, b: any) =>
    //             b.totalPrincipalPaid - a.totalPrincipalPaid,
    //     },

    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         return (
    //             text && (
    //                 <Statistic
    //                     value={text}
    //                     prefix={<Typography.Text>₱</Typography.Text>}
    //                     prefixCls={'₱'}
    //                     precision={2}
    //                 />
    //             )
    //         )
    //     },
    // },
    // {
    //     title: 'First Payment Date',
    //     dataIndex: 'startDate',
    //     align: 'right',
    //     filters: true,
    //
    //     renderText: (
    //         text: any
    //         // record: MemberReceivable,
    //         // index: number,
    //         // action: ProCoreActionType
    //     ) => {
    //         const newText =
    //             typeof text === 'string'
    //                 ? moment(text).format('MMM DD, YYYY')
    //                 : moment.unix(text).format('MMM DD, YYYY')
    //         return newText
    //     },
    // },
]

export const loanPayments: ProColumns<any>[] = [
    {
        title: 'Payment Date',
        dataIndex: 'creation',
        defaultSortOrder: 'ascend',
        sorter: {
            compare: (a: any, b: any) => b.creation - a.creation,
        },
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            const newText = moment.unix(text).format('MM/DD/YYYY h:mm a')
            return newText
        },
    },
    {
        title: 'Amount',
        dataIndex: 'amountPaid',
        defaultSortOrder: 'ascend',
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return `₱${text.toFixed(2)}`
        },
    },
    {
        title: 'Interest',
        dataIndex: 'interest',
        renderText: (
            text: any,
            record: MemberReceivable
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Principal',
        dataIndex: 'principal',
        hideInSearch: true,
        renderText: (
            text: any
            // record: any
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
    {
        title: 'Beginning Balance',
        dataIndex: 'beginningBalance',
        align: 'right',
        sorter: {
            compare: (a: any, b: any) =>
                b.beginningBalance - a.beginningBalance,
        },
        renderText: (
            text: any
            // record: MemberReceivable,
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },

    {
        title: 'Ending Balance',
        dataIndex: 'endingBalance',
        align: 'right',
        sorter: {
            compare: (a: any, b: any) => b.endingBalance - a.endingBalance,
        },
        renderText: (
            text: any
            // index: number,
            // action: ProCoreActionType
        ) => {
            return (
                <Statistic
                    value={text}
                    prefix={<Typography.Text>₱</Typography.Text>}
                    prefixCls={'₱'}
                    precision={2}
                />
            )
        },
    },
]

export const calculateAmortization = (
    amount: number,
    interest: number,
    monthsToPay: number
):
    | {
          monthlyPayment: number
          totalPayment: number
          totalInterest: number
      }
    | undefined => {
    if (!(monthsToPay && amount && interest)) return
    let loanAmount = amount
    const numberOfPayments = 12
    const interestRate = interest / 100
    const monthlyInterestRate = interestRate / numberOfPayments
    // const monthsToPay = termYears * numberOfPaymentsEveryYear + termMonths

    const i = monthlyInterestRate
    const u = i * (1 + i) ** monthsToPay
    const d = (1 + i) ** monthsToPay - 1
    const monthlyPayment = amount * (u / d)
    let totalInterest = 0

    for (let m = 1; m <= monthsToPay; m++) {
        const beginningLoanBalance = loanAmount
        let interestPayment = monthlyInterestRate * loanAmount
        let principalPayment = monthlyPayment - interestPayment

        loanAmount = loanAmount - principalPayment
        if (loanAmount < 0) {
            loanAmount = 0
            principalPayment = beginningLoanBalance
        }
        totalInterest += interestPayment
    }

    return {
        monthlyPayment,
        totalPayment: monthlyPayment * monthsToPay,
        totalInterest,
    }
}
