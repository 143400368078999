import { randomId } from '../../../app/pages/utils'
import {
    DeleteOutlined,
    DownOutlined,
    EditFilled,
    PlusCircleFilled,
} from '@ant-design/icons'
import {
    Button,
    Col,
    Input,
    InputNumber,
    Row,
    Tree,
    TreeProps,
    Typography,
} from 'antd'
import { DataNode } from 'antd/lib/tree'
import React, { useEffect, useState } from 'react'
import { BiCheck } from 'react-icons/bi'
const defaultKeys = ['earnings']
interface PayrollDeductionsProps {
    onChange: (data: any) => void
    data: any[]
}
function PayrollAdditionals({ onChange, data }: PayrollDeductionsProps) {
    const [earnings, setEarnings] = useState<any[]>(data)
    const [defaultData, setDefault] = useState<DataNode[]>([
        {
            title: (
                <Typography.Text style={{ fontWeight: 'bolder' }}>
                    Enable Additional Earnings
                </Typography.Text>
            ),
            key: 'earnings',
            // selectable: false,
            children: [],
        },
    ])
    const [expandedKeys, setExpandedKeys] = useState<any>(['earnings'])
    const [currentEditing, setCurrentEditing] = useState<any | undefined>(
        undefined
    )
    const handleDeleteEarnings = (key: string | number) => {
        // eslint-disable-next-line no-console
        // console.log(key, earnings)

        const newEarnings = [...earnings]
        const deductionIdx = newEarnings.findIndex(
            (deduction) => deduction.key === key
        )
        if (deductionIdx >= 0) {
            newEarnings.splice(deductionIdx, 1)
            setEarnings(newEarnings)
        }
    }
    const handleNewEarnings = () => {
        // const earnings = [...earnings]
        const id = randomId(5)
        setCurrentEditing({
            data: 0,
            value: '',
            key: id,
            isEditing: true,
            isChecked: false,
        })
        setEarnings([
            ...earnings,
            {
                title: '',
                data: 0,
                value: '',
                key: id,
                isEditing: true,
                isChecked: false,
            },
        ])
    }
    const onSelect: TreeProps['onCheck'] = (selectedKeys, info) => {
        // eslint-disable-next-line no-console
        // console.log('selected', selectedKeys, info)
        const key = info.node.key
        let newEarnings = [...earnings]
        // @ts-ignore
        setExpandedKeys([...new Set([...expandedKeys, ...selectedKeys])])
        const idx = newEarnings.findIndex((earning) => earning.key === key)
        if (idx >= 0) {
            let obj = {
                ...newEarnings[idx],
            }
            obj.isChecked = info.checked
            newEarnings[idx] = obj
            setEarnings(newEarnings)
        }
    }
    // eslint-disable-next-line no-console
    // console.log(earnings)
    const handleSave = (data: any) => {
        // eslint-disable-next-line no-console
        // console.log(data, earnings)
        let newEarnings = [...earnings]
        const idx = newEarnings.findIndex(
            (deduction) => deduction.key === data.key
        )
        if (idx >= 0) {
            let obj = {
                ...newEarnings[idx],
            }
            obj.title = data.value
            obj.isChecked = data.isChecked
            obj.value = data.value
            obj.data = data.data
            obj.isEditing = false
            newEarnings[idx] = obj
            setEarnings(newEarnings)
            setCurrentEditing(undefined)
        }
    }
    useEffect(() => {
        setDefault([
            {
                title: (
                    <Typography.Text style={{ fontWeight: 'bolder' }}>
                        Additional Earnings
                    </Typography.Text>
                ),
                key: 'earnings',
                // selectable: false,
                children: [
                    ...earnings,
                    {
                        checkable: false,
                        title: (
                            <Button
                                type="link"
                                icon={<PlusCircleFilled />}
                                onClick={handleNewEarnings}
                            >
                                {' '}
                                New
                            </Button>
                        ),
                        data: 'wash',
                        key: 'new',
                    },
                ],
            },
        ])
        onChange(earnings)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [earnings])
    useEffect(() => {
        const keys: any = []
        data.forEach((datum) => {
            if (datum.isChecked) {
                keys.push(datum.key)
            }
        })
        setExpandedKeys(keys)
    }, [data])
    return (
        <Tree
            checkable
            // selectable={false}
            showLine
            switcherIcon={<DownOutlined />}
            checkedKeys={expandedKeys}
            defaultExpandAll
            // defaultExpandedKeys={['deduction']}
            onCheck={onSelect}
            treeData={defaultData}
            titleRender={(node: any) => {
                const title: any = node.title

                if (
                    currentEditing &&
                    node.key === currentEditing.key &&
                    currentEditing.isEditing
                ) {
                    return (
                        <Row
                            key={node.key}
                            align={'middle'}
                            wrap={false}
                            gutter={[12, 12]}
                            onClick={function (e) {
                                e.stopPropagation()
                                e.preventDefault()
                            }}
                        >
                            <Col>
                                <Input
                                    placeholder={'Title'}
                                    value={currentEditing.value as string}
                                    onChange={(e) => {
                                        setCurrentEditing({
                                            ...currentEditing,
                                            value: e.target.value || '',
                                            title: e.target.value || '',
                                        })
                                    }}
                                />
                            </Col>
                            <Col>
                                <span style={{ fontSize: 24 }}>:</span>
                            </Col>
                            <Col>
                                <InputNumber
                                    placeholder={'Amount'}
                                    value={currentEditing.data as number}
                                    onFocus={function (e) {
                                        // @ts-ignore
                                        e.target.select()
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                    onClick={function (e) {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                    onChange={(e) => {
                                        setCurrentEditing({
                                            ...currentEditing,
                                            data: e || 0,
                                        })
                                    }}
                                />
                            </Col>
                            <Col>
                                <Row wrap={false} gutter={[6, 6]}>
                                    <Col>
                                        <Button
                                            onClick={() =>
                                                handleSave(currentEditing)
                                            }
                                            type="primary"
                                            style={{
                                                padding: 0,
                                                backgroundColor: 'green',
                                            }}
                                            icon={<BiCheck />}
                                        />
                                        {/* <Button type='link' onClick={() => handleSave(currentEditing)} size='large'><BiCheck /></Button> */}
                                    </Col>
                                    <Col>
                                        <Button
                                            onClick={() =>
                                                handleDeleteEarnings(node.key)
                                            }
                                            danger
                                            type="primary"
                                            style={{ padding: 0 }}
                                            icon={<DeleteOutlined />}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                }
                if (node.key === 'new') {
                    return (
                        <Button
                            type="link"
                            icon={<PlusCircleFilled />}
                            onClick={handleNewEarnings}
                        >
                            New
                        </Button>
                    )
                }
                if (defaultKeys.includes(node.key as string))
                    return (
                        <div
                            onClick={function (e) {
                                e.stopPropagation()
                                e.preventDefault()
                            }}
                        >
                            {title} {node.data ? ` : ₱${node.data}` : null}
                        </div>
                    )

                return (
                    <Row
                        onClick={function (e) {
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                    >
                        <Col>
                            {node.value || node.key}{' '}
                            {node.data ? ` : ₱${node.data}` : null}
                        </Col>
                        <Col>
                            <Button
                                onClick={() =>
                                    // handleSave(currentEditing)
                                    setCurrentEditing({
                                        data: node.data,
                                        value: node.value,
                                        key: node.key,
                                        isEditing: true,
                                    })
                                }
                                type="link"
                                icon={<EditFilled />}
                            />
                        </Col>
                    </Row>
                )
            }}
        />
    )
}

export default PayrollAdditionals
