import moment from 'moment'
import { Loan } from '../../../../domain/entities/Loan'
import { Frequency } from '../../../../domain/entities/SystemSettings'
import { randomId } from '../../utils'
import { AmortizationSchedule, PaymentSchedule } from './ApplyLoan'
// import { calculateMonthlyPayment } from "./loanHelper";
// interface AmortizationMock {
//     schedules: PaymentSchedule[]
//     totalInterest: number
//     monthlyPayment: number
//     totalPayment: number
// }
export function calculateAmortizationScheduleV2(
    loanData: Loan
): AmortizationSchedule {
    // Extract loan data
    const loanAmount = loanData.amount
    let interestRate = loanData.interest / 100
    const interestCalculationType =
        loanData?.loanInterestType || 'reducing-balance'
    if (interestCalculationType === 'reducing-balance') {
        interestRate = loanData.interest / 100
    }
    // const loanTerm = loanData.termMonths;
    const numberOfPayments = loanData.termMonths

    let amortizationSchedule: PaymentSchedule[] = []

    let due: number =
        loanData.due || moment.unix(loanData.creation).add(1, 'month').unix()
    // numberOfPayments = loanData.termMonths
    let annualInterest = loanAmount * interestRate
    // let yearNumber = 1
    let currentMonth = 1
    let monthlyPayment = 0

    let interestPayment = 0
    let totalInterest = 0
    let totalPayment = 0
    let principalPayment = loanAmount / numberOfPayments
    let remainingBalance = loanAmount
    let beginningBalance = loanAmount
    let loanDue = 0
    // const monthlyInterest = interestRate / 12
    // const r = monthlyInterest
    const n = numberOfPayments
    if (interestCalculationType === 'reducing-balance') {
        const i = interestRate
        const u = i * (1 + i) ** n
        const d = (1 + i) ** n - 1
        monthlyPayment = loanAmount * (u / d)
        loanDue = monthlyPayment
        // const totalPayment = monthlyPayment * numberOfPayments
    }

    // Generate amortization schedule
    for (let i = 1; i <= numberOfPayments; i++) {
        if (interestCalculationType === 'straight-line') {
            interestPayment = annualInterest / 12
            loanDue = principalPayment + interestPayment
            if (currentMonth === 12) {
                currentMonth = 1
                annualInterest = remainingBalance * interestRate
            } else {
                currentMonth += 1
            }
            monthlyPayment += loanDue
        }

        if (interestCalculationType === 'reducing-balance') {
            // const monthlyInterest = interestRate / 12
            interestPayment = remainingBalance * interestRate
            principalPayment = monthlyPayment - interestPayment
        }

        if (interestCalculationType === 'flat') {
            // const years = numberOfPayments / 12
            interestPayment = (loanAmount * interestRate) / 12
            monthlyPayment =
                (loanAmount + loanAmount * interestRate) / numberOfPayments
            principalPayment = loanAmount / numberOfPayments
            loanDue = monthlyPayment + interestPayment
        }

        remainingBalance -= principalPayment
        totalInterest += interestPayment
        totalPayment += loanDue
        amortizationSchedule.push({
            paymentNumber: i,
            interest: interestPayment,
            principal: principalPayment,
            endingBalance: remainingBalance,
            beginningBalance,
            loanDue: loanDue,
            due,
            id: randomId(5),
        })
        beginningBalance = remainingBalance
        due = moment.unix(due).add(1, 'month').unix()
    }

    if (interestCalculationType === 'straight-line') {
        monthlyPayment = monthlyPayment / numberOfPayments
    }
    return {
        schedules: amortizationSchedule,
        totalInterest,
        numberOfPayments: numberOfPayments,
        totalPayment,
        totalPrincipal: loanAmount,
        payback: loanData.payback,
        totalMonthlyPayment: monthlyPayment,
        creation: moment().unix(),
    }
}

export function calculateAmortizationSchedule(loan: Loan) {
    const {
        amount: loanAmount,
        interest,
        creation,
        termMonths,
        payback: paymentFrequency,
    } = loan
    const schedule = []
    const interestRate = interest / 100
    const numberOfPayments = calculateTotalPayments(
        termMonths,
        paymentFrequency
    )
    const paymentAmount = calculatePaymentAmount(
        loanAmount,
        interestRate,
        termMonths,
        paymentFrequency
    )
    // const payments: any = []
    let remainingBalance = loanAmount
    let dueDate = moment.unix(creation).add(1, 'M').unix()
    const interestRatePerPeriod = interestRate / numberOfPayments
    let paymentDate = dueDate
    for (let i = 1; i <= numberOfPayments || remainingBalance <= 0; i++) {
        // const dueDate = paymentDate
        // let paymentDate = dueDate; // Set the payment date to the due date initially

        // Check if a payment has been made and update the payment date accordingly
        // if (payments && payments.length >= i) {
        //     paymentDate = payments[i - 1].date;
        // }

        const currentInterest = remainingBalance * interestRatePerPeriod
        const principal = paymentAmount - currentInterest
        remainingBalance -= principal
        schedule.push({
            paymentNumber: i,
            dueDate: moment.unix(dueDate).format('MMMM DD, YYYY'),
            paymentDate: moment.unix(paymentDate).format('MMMM DD, YYYY'),
            paymentAmount,
            principal,
            interest: currentInterest,
            remainingBalance,
        })

        dueDate = moment.unix(paymentDate).add(1, 'M').unix()
        paymentDate = dueDate
    }

    return schedule
}

const calculateTotalPayments = (termMonths: number, payback: Frequency) => {
    let numberOfRepayments = 0
    switch (payback) {
        case 'monthly':
            numberOfRepayments = termMonths
            break
        // case 'quarterly':
        //     numberOfRepayments = Math.ceil(termMonths / 3);
        //     break;
        // case 'semiannually':
        //     numberOfRepayments = Math.ceil(termMonths / 6);
        //     break;
        case 'yearly':
            numberOfRepayments = Math.ceil(termMonths / 12)
            break
        default:
            // Handle unsupported payment frequency
            // eslint-disable-next-line no-console
            console.error('Unsupported payment frequency:', payback)
    }

    return numberOfRepayments
}
// function calculateCurrentInterest(loan: Loan, paymentDate: number) {
//     const { remainingBalance, compound: interestType, interest, due: dueDate, termMonths: loanTerm } = loan;
//     let currentInterest = 0;
//     const interestRate = interest / 100
//     if (interestType === 'fix') {
//         // Calculate the number of days between the due date and payment date (if provided)
//         const daysLate = paymentDate ? Math.floor((paymentDate - dueDate) / (1000 * 60 * 60 * 24)) : 0;

//         // Calculate the daily interest rate based on the loan term
//         const dailyInterestRate = interestRate / loanTerm;

//         // Calculate the current interest based on the remaining balance and days late
//         currentInterest = remainingBalance * dailyInterestRate * daysLate;
//     } else if (interestType === 'continuous') {
//         // Calculate the number of months between the due date and payment date (if provided)
//         const monthsLate = paymentDate ? Math.floor((paymentDate - dueDate) / (1000 * 60 * 60 * 24 * 30)) : 0;

//         // Calculate the effective interest rate
//         const effectiveInterestRate = Math.pow(1 + interestRate, monthsLate) - 1;

//         // Calculate the current interest based on the remaining balance and effective interest rate
//         currentInterest = remainingBalance * effectiveInterestRate;
//     }

//     return currentInterest;
// }
// // eslint-disable-next-line no-unused-vars
// export function calculateAmortizationSchedule(loanData: Loan) {
//     try {
//         const {
//             amount: loanAmount,
//             interest: interestRate,
//             termMonths: loanTerm,
//             // eslint-disable-next-line no-unused-vars
//             payback,
//             creation: paymentStartDate
//         } = loanData;
//         const paymentFrequency = 3
//         const monthlyInterestRate = interestRate / 12 / 100;
//         const totalPayments = loanTerm * paymentFrequency;
//         const paymentAmount = calculatePaymentAmount(
//             loanAmount,
//             monthlyInterestRate,
//             totalPayments
//         );

//         let remainingBalance = loanAmount;
//         let amortizationSchedule = [];

//         let currentDate = new Date(paymentStartDate);
//         for (let paymentNumber = 1; paymentNumber <= totalPayments; paymentNumber++) {
//             const interestPayment = remainingBalance * monthlyInterestRate;
//             const principalPayment = paymentAmount - interestPayment;
//             const endingBalance = remainingBalance - principalPayment;

//             const payment = {
//                 paymentNumber,
//                 paymentDate: currentDate.toISOString().split("T")[0],
//                 interestPayment,
//                 principalPayment,
//                 endingBalance
//             };

//             amortizationSchedule.push(payment);

//             currentDate = addPaymentInterval(currentDate, paymentFrequency);
//             remainingBalance = endingBalance;
//         }

//         return amortizationSchedule;
//     } catch (err) {
//         // eslint-disable-next-line no-console
//         console.log(err);

//     }
// }
function getNumberOfMonths(paymentFrequency: Frequency) {
    const paymentFrequencyMapping = {
        yearly: 12,
        semiannually: 6,
        quarterly: 3,
        monthly: 1,
        // Add more mappings if needed for other payment frequencies
    }

    return paymentFrequencyMapping[paymentFrequency]
}
// function calculateDueDate(paymentNumber: number, loanTerm: number, paymentFrequency: Frequency) {
//     const paymentInterval = 12 / getNumberOfMonths(paymentFrequency); // Calculate the interval between payments (e.g., monthly, quarterly)

//     const yearOffset = Math.floor((paymentNumber - 1) / paymentInterval); // Calculate the number of years to offset
//     const monthOffset = (paymentNumber - 1) % paymentInterval; // Calculate the number of months to offset

//     const dueYear = Math.ceil(loanTerm / paymentInterval); // Calculate the year of the final payment
//     const dueMonth = monthOffset * paymentInterval + 1; // Calculate the month of the payment

//     return {
//         year: dueYear + yearOffset,
//         month: dueMonth
//     };
// }
// function calculatePaymentAmount(loanAmount: any, monthlyInterestRate: any, totalPayments: any) {
//     const numerator = loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments);
//     const denominator = Math.pow(1 + monthlyInterestRate, totalPayments) - 1;
//     return numerator / denominator;
// }

function calculatePaymentAmount(
    loanAmount: number,
    interestRate: number,
    loanTerm: number,
    paymentFrequency: Frequency
): number {
    const numberOfMonths = getNumberOfMonths(paymentFrequency)
    const monthlyInterestRate = interestRate / 100 / 12
    const exponent = -numberOfMonths * loanTerm
    const discountFactor =
        (1 - Math.pow(1 + monthlyInterestRate, exponent)) / monthlyInterestRate

    const paymentAmount = loanAmount / discountFactor
    return parseInt(paymentAmount.toFixed(2))
}

// function addPaymentInterval(date: number, paymentFrequency: Frequency) {
//     const newDate = moment.unix(date);

//     switch (paymentFrequency) {
//         case "monthly":
//             newDate.add(1, 'month');
//             break;
//         case "quarterly":
//             newDate.add(3, 'months');
//             break;
//         case "semiannually":
//             newDate.add(6, 'months');
//             break;
//         case "yearly":
//             newDate.add(1, 'year');
//             break;
//         default:
//             break;
//     }

//     return newDate.format("MMMM DD, YYYY");
// }
