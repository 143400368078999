import React, { useEffect, useState } from 'react'
import {
    Chart,
    Interval,
    Tooltip,
    Axis,
    Coordinate,
    getTheme,
    Legend,
} from 'bizcharts'
import { LegendCfg } from 'bizcharts/lib/interface'
// import { expenseScript } from "./script";

export interface ChartProps {
    data: {
        item: string
        percent: number
    }[]
    title?: any
    legendTitle?: any
    legendX?: number
    legendY?: number
    legendPosition?: LegendCfg['position']
    level?: 1 | 2 | 3 | 4 | 5 | undefined
}
function PeratiChart({
    data,
    title,
    legendTitle,
    level,
    legendX,
    legendPosition,
    legendY,
}: ChartProps) {
    // const data = [
    // 	{ item: "A", percent: 0.4 },
    // 	{ item: "B", percent: 0.21 },
    // 	{ item: "C", percent: 0.17 },
    // 	{ item: "D", percent: 0.13 },
    // 	{ item: "E", percent: 0.09 },
    // ];

    const [dataArrs, setDataArrs] = useState<ChartProps['data']>([])
    const [colors, setColors] = useState([])
    const cols = {
        percent: {
            formatter: (val: any) => {
                val = (val * 100).toFixed(2) + '%'
                return val
            },
        },
    }

    useEffect(() => {
        setDataArrs(data)
        setColors(
            data.reduce((pre: any, cur: any, idx: any) => {
                pre[cur.item] = getTheme().colors10[idx]
                return pre
            }, {})
        )
    }, [data])

    useEffect(() => {
        const colors: any = dataArrs.reduce((pre: any, cur: any, idx: any) => {
            pre[cur.item] = getTheme().colors10[idx]
            return pre
        }, {})
        setColors(colors)
    }, [dataArrs])
    return (
        <div>
            {title}
            <Chart
                height={400}
                data={dataArrs}
                scale={cols}
                interactions={['element-active']}
                autoFit
            >
                <Legend
                    title={{
                        text: legendTitle || 'Expense Breakdown',
                    }}
                    slidable
                    position={legendPosition || 'left'}
                    layout="vertical"
                    offsetX={legendX || 12}
                    offsetY={legendY || 20}
                />
                <Coordinate type="theta" radius={0.6} />
                <Tooltip visible={false} />
                <Axis visible={false} />
                <Interval
                    position="percent"
                    adjust="stack"
                    color="item"
                    style={{
                        lineWidth: 1,
                        stroke: '#fff',
                    }}
                    label={[
                        'item',
                        (item) => {
                            return {
                                offset: 20,
                                content: (data) => {
                                    return `${data.item}\n ${(
                                        data.percent * 100
                                    ).toFixed(2)}%`
                                },
                                style: {
                                    fill: colors[item],
                                },
                            }
                        },
                    ]}
                />
            </Chart>
        </div>
    )
}

export default PeratiChart
