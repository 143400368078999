import { PERSONNEL_ATTENDANCE_REPOSITORY } from '../../../data/repositories/constants'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { Attendance, Reservation } from '../../../domain/entities/Attendance'
import moment from 'moment'
import { db } from '../../boot'

type NewRes = Reservation & {
    index: number
}
export const checkCurrentReservation = (
    reservations: Reservation[],
    date?: number
): NewRes | undefined => {
    const targetDate = date
        ? moment.unix(date).format('MMM DD, YYYY')
        : moment().format('MMM DD, YYYY')

    const filtered: NewRes[] = []
    reservations.forEach((reservation: Reservation, idx: number) => {
        if (
            reservation.date === targetDate &&
            reservation.driverId !== '' &&
            reservation.assistantId !== '' &&
            !reservation.color
        ) {
            filtered.push({
                ...reservation,
                index: idx,
            } as NewRes)
        }
    })

    if (!filtered[0]) return undefined

    return filtered[0]
}

export const getAttendance = async (
    driverId: string,
    assistantId: string,
    date: number
) => {
    const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)

    const currentDate = moment.unix(date)
    const q = query(
        ref,
        where('driverId', '==', driverId),
        where('assistantId', '==', assistantId),
        where('date', '==', currentDate.format('MMM DD, YYYY')),
        where('status', '==', 'pending')
    )

    const snapshot = await getDocs(q)
    // eslint-disable-next-line no-console
    const firstData = snapshot.docs[0]
    if (!firstData) return
    const data = firstData.data()
    if (!data) return

    return {
        ...data,
        id: firstData.id as string,
    } as Attendance
}
export function calculateTime(startUnix: number, endUnix: number) {
    // Create moment objects from the Unix timestamps
    const startTime = moment.unix(startUnix)
    const endTime = moment.unix(endUnix)

    // Calculate the number of hours between start and end time
    // eslint-disable-next-line no-console
    // console.log("Total Hours: ", hours);

    // Fixed times of 5:00 AM and 1:00 AM for the next day
    // const fixedStartTime = moment(startTime).set({hour: 5, minute: 0, second: 0});
    const fixedEndTime = moment(startTime).set({
        hour: 13,
        minute: 0,
        second: 0,
    })
    const duration = moment.duration(fixedEndTime.diff(startTime))
    const hours = duration.asHours()
    const otHours = moment.duration(endTime.diff(fixedEndTime)).asHours()
    // Calculate the number of minutes late from fixed starting point of 5:00 AM
    // const startLateDuration = moment.duration(startTime.diff(fixedStartTime));
    // const startLateHours = startLateDuration.asHours();
    // eslint-disable-next-line no-console
    // console.log("Minutes late from 5:00 AM: ", startLateMinutes, startTime.format("h:mmA"));

    // Calculate the number of minutes late from fixed ending time of 1:00 AM
    // const endLateDuration = moment.duration(endTime.diff(fixedEndTime));
    // const endLateMinutes = endLateDuration.asMinutes();
    // eslint-disable-next-line no-console
    // console.log("Minutes late from 1:00 PM: ", endLateMinutes, endTime.format("h:mmA"));

    // Determine if the time is undertime
    // const isUndertime = hours < 8; // because between 5:00 AM and 1:00 AM next day is 20 hours
    // const overtime = hours >= 8 ? hours - 8 : 0
    // eslint-disable-next-line no-console
    // console.log("Is Undertime:", isUndertime);
    const time = hours >= 8 ? 8 : hours
    return [time, otHours > 0 ? otHours : 0]
}
