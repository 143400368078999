import moment from 'moment'
import { keyValueDates } from './utils'

type getWeightReturn = number | undefined

export const getWeight = (date: number): getWeightReturn => {
    const monthOfShare = moment.unix(date).format('MMMM')
    // const yearOfShare = moment.unix(share.creation).format('YYYY')

    const weightIdx = keyValueDates.findIndex(
        (keyValueDate) => keyValueDate.label === monthOfShare
    )
    if (weightIdx < 0) return undefined

    const monthWeight = keyValueDates[weightIdx].value
    return monthWeight / 12
}

export const rainbowColors = [
    '0,0,255',
    '60,179,113',
    '255,0,0',
    '255,165,0',
    '143,0,255',
    '75,0,130',
    '255,255,0',
]

export const getRouteName = (): string => {
    const numberOfSlashes = window.location.pathname.match(/\//g)
    if (!numberOfSlashes) return 'Home'
    const slashes = numberOfSlashes.length
    const lastSlashIdx = window.location.pathname.lastIndexOf('/')
    let routeName = 'Home'
    if (slashes >= 3) {
        routeName = window.location.pathname.slice(
            lastSlashIdx + 1,
            window.location.pathname.length
        )
    }

    return routeName
}
