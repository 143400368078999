// import icon from '@ant-design/compatible/lib/icon'
import { Row, Col, Card } from 'antd'
// import { Content } from 'antd/lib/layout/layout'
// import { t } from 'i18next'
// import { title } from 'process'
import React from 'react'
import { Fade } from 'react-awesome-reveal'
import Marquee from 'react-fast-marquee'
// import { SvgIcon } from '../../common/SvgIcon'
import {
    LeftContentSection,
    MinTitle,
    MinPara,
} from '../../components/ContentBlock/LeftContentBlock/styles'
// import { ContentBlockProps } from '../../components/ContentBlock/types'
import TESTIMONIALS from '../../content/Testimonials.json'
import { withTranslation } from 'react-i18next'
import { EllipsisOutlined } from '@ant-design/icons'

const Testimonials = ({ t, id }: any) => {
    return (
        <LeftContentSection>
            <Fade direction="left">
                <Row align="middle" id={id}>
                    <Col span={24}>
                        <h6 style={{}}>{t('Client Testimonials')}</h6>
                        <br />
                        <br />
                        <Marquee pauseOnHover>
                            <Row gutter={[24, 24]} style={{ paddingLeft: 24 }}>
                                {TESTIMONIALS.page4.map(
                                    (item: any, id: number) => {
                                        return (
                                            <Col
                                                key={id}
                                                style={{
                                                    maxWidth: 440,
                                                    height: '100%',
                                                }}
                                            >
                                                <Card
                                                    style={{
                                                        width: 400,
                                                        height: '100%',
                                                        borderRadius: 10,
                                                        border: '3px solid black',
                                                    }}
                                                    title={
                                                        <div>
                                                            <EllipsisOutlined
                                                                style={{
                                                                    fontSize: 80,
                                                                    marginTop:
                                                                        -30,
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    headStyle={{
                                                        borderBottom:
                                                            '3px solid black',
                                                        height: 0,
                                                        backgroundColor:
                                                            'lightcyan',
                                                        borderRadius:
                                                            '10px 10px 0 0',
                                                        padding: '0 24px',
                                                    }}
                                                    bodyStyle={{
                                                        height: '100%',
                                                        padding: 32,
                                                    }}
                                                    // cover={
                                                    //     <Row>
                                                    //         <Col span={24}>
                                                    //             <Row
                                                    //                 justify={
                                                    //                     'center'
                                                    //                 }
                                                    //             >
                                                    //                 <Col
                                                    //                     style={{
                                                    //                         height: 200,
                                                    //                     }}
                                                    //                 >
                                                    //                     <img
                                                    //                         alt={
                                                    //                             'example'
                                                    //                         }
                                                    //                         src={
                                                    //                             item.avatar
                                                    //                         }
                                                    //                         height={
                                                    //                             '200px'
                                                    //                         }
                                                    //                         style={{
                                                    //                             width: 'auto',
                                                    //                         }}
                                                    //                     />
                                                    //                 </Col>
                                                    //             </Row>
                                                    //         </Col>
                                                    //     </Row>
                                                    // }
                                                >
                                                    <MinPara>
                                                        <span
                                                            style={{
                                                                color: 'black',
                                                                fontWeight:
                                                                    'bolder',
                                                                fontSize:
                                                                    'larger',
                                                            }}
                                                        >
                                                            <b>"</b>
                                                        </span>
                                                        {t(item.comment)}
                                                        <span
                                                            style={{
                                                                color: 'black',
                                                                fontWeight:
                                                                    'bolder',
                                                                fontSize:
                                                                    'larger',
                                                            }}
                                                        >
                                                            <b>"</b>
                                                        </span>
                                                    </MinPara>

                                                    <Card.Meta
                                                        description={
                                                            <div>
                                                                <MinTitle>
                                                                    {t(
                                                                        item.name
                                                                    )}
                                                                </MinTitle>
                                                                <MinPara>
                                                                    {t(
                                                                        item.profile
                                                                    )}
                                                                </MinPara>
                                                            </div>
                                                        }
                                                        style={{
                                                            textAlign: 'right',
                                                        }}
                                                    />
                                                </Card>
                                                {/* <SvgIcon
                                            src={item.avatar}
                                            width="60px"
                                            height="60px"
                                        />
                                        <MinTitle>{t(item.name)}</MinTitle>
                                        <MinPara>{t(item.profile)}</MinPara> */}
                                            </Col>
                                        )
                                    }
                                )}
                            </Row>
                        </Marquee>
                    </Col>
                </Row>
            </Fade>
        </LeftContentSection>
    )
}

export default withTranslation()(Testimonials)
