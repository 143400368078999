import React, { useEffect, useState } from 'react'
import {
    Col,
    Divider,
    Form,
    Input,
    message,
    Row,
    Space,
    Typography,
} from 'antd'
import moment from 'moment'
import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-components'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Expense } from '../../../../domain/entities/SystemSettings'
import { useAppDispatch, useAppSelector } from '../../../store'
import { updateSystemConfig } from '../../../redux/system.slice'
import { shallowEqual } from 'react-redux'
import { arrayRemove, doc, updateDoc } from 'firebase/firestore'
import {
    EXPENSE_REPOSITORY,
    TEAM_REPOSITORY,
} from '../../../../data/repositories/constants'
import { db } from '../../../boot'

interface UpdateExpenseProp {
    data: Expense
    onCancel: () => void
    onSuccess?: () => void
}
function UpdateExpense({
    data: propData,
    onCancel,
    onSuccess,
}: UpdateExpenseProp) {
    // @ts-ignore
    const { show, ...data } = propData
    const [expensesLabelKey, setExpensesLabelKey] = useState<any>([])
    const [selectedCategory, setSelectedCategory] = useState(data.category)
    const [name, setName] = useState('')

    const { expenseCategories, email, styles, activeTeam } = useAppSelector(
        (state) => ({
            expenseCategories: state.team.selectedTeamData.expenseCategories,
            email: state.auth.user.email,
            activeTeam: state.auth.user.partnerCode,
            styles: state.styles,
        }),
        shallowEqual
    )

    const dispatch = useAppDispatch()

    const handleAddExpenseCategory = (data: any) => {
        setName(data.target.value)
    }
    const [form] = Form.useForm()
    const handleSubmit = async (dt: Expense) => {
        try {
            const payload: Expense = {
                ...data,
                ...dt,
                requestDate: moment(dt.requestDate).unix(),
                releaseDate: moment(dt.releaseDate).unix(),
                modified: moment().unix(),
                modifiedBy: email,
            }

            await updateDoc(doc(db, EXPENSE_REPOSITORY, data.id as string), {
                ...payload,
            })

            message.success('Successfully updated expense.')
            onSuccess && onSuccess()
            onCancel()
        } catch (err) {
            if (err instanceof Error) {
                message.error(err.message)
            } else {
                message.error('An unknown error occurred.')
            }
        }
    }

    const handleAddItem = () => {
        if (!name) return
        if (expenseCategories.includes(name)) {
            message.error(`${name} already exists in expense categories.`)
            return
        }

        const expenseCategoriesCopy = [...expenseCategories, name]
        dispatch(
            updateSystemConfig({
                expenseCategories: expenseCategoriesCopy,
            })
        )
        setName('')
    }

    const handleDeleteCategory = async (e: any, key: string) => {
        e.preventDefault()
        e.stopPropagation()
        const newExpenseCategories = [...expenseCategories]
        const keyIdx = newExpenseCategories.indexOf(key)
        if (keyIdx >= 0) {
            newExpenseCategories.splice(keyIdx, 1)
            dispatch(
                updateSystemConfig({
                    expenseCategories: newExpenseCategories,
                })
            )
            await updateDoc(doc(db, TEAM_REPOSITORY, activeTeam), {
                expenseCategories: arrayRemove(key),
            })
            message.success('Successfully delete category.')
        }
    }

    useEffect(() => {
        const mapped = expenseCategories.map((expenseCategory: string) => ({
            label: expenseCategory,
            key: expenseCategory,
        }))
        setExpensesLabelKey(mapped)
    }, [expenseCategories])

    return (
        <ModalForm<Expense>
            form={form}
            onFinishFailed={(err) => {
                if (err.errorFields.length) {
                    const firstError = err.errorFields[0]
                    message.destroy()
                    message.error(firstError.errors)
                    form.scrollToField(firstError.name, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }}
            title={
                <Typography.Title level={3}>Update Expense</Typography.Title>
            }
            open
            autoFocusFirstInput
            modalProps={{
                onCancel: onCancel,
                okText: 'Update',
                centered: true,
            }}
            onFinish={handleSubmit}
            initialValues={{
                ...data,
                requestDate: moment.unix(data.requestDate),
                releaseDate: moment.unix(data.releaseDate),
            }}
            submitter={{
                submitButtonProps: {
                    style: {
                        backgroundColor: styles.primary,
                        color: 'white',
                    },
                },
            }}
            width={500}
            // onValuesChange={handleFieldChange}
        >
            <Row gutter={[12, 12]}>
                <Col span={12}>
                    <ProFormText
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        name="title"
                        label="Vendor"
                        placeholder="Please Enter"
                    />
                </Col>
                <Col span={12}>
                    <ProFormText
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        name="referenceNumber"
                        label="Reference No."
                        placeholder="Please Enter"
                    />
                </Col>
            </Row>

            <Row wrap={false} gutter={[24, 24]}>
                <Col span={'416px'}>
                    <ProForm.Group>
                        <ProFormDigit
                            rules={[
                                {
                                    required: true,
                                    type: 'number',
                                    message: 'This field is required',
                                },
                            ]}
                            width="sm"
                            name="amount"
                            label="Amount"
                            placeholder="Please Enter"
                        />
                        <ProFormSelect
                            style={{
                                width: 200,
                            }}
                            placeholder={'Select category'}
                            label={'Category'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                            name={'category'}
                            options={expensesLabelKey}
                            fieldProps={{
                                value: selectedCategory,
                                dropdownRender: (menu: any) => (
                                    <>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            align="center"
                                            style={{
                                                padding: '0 8px 4px',
                                            }}
                                        >
                                            <Input
                                                placeholder="Please enter item"
                                                value={name}
                                                onChange={
                                                    handleAddExpenseCategory
                                                }
                                            />
                                            <Typography.Link
                                                onClick={handleAddItem}
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                <PlusOutlined /> Add item
                                            </Typography.Link>
                                        </Space>
                                    </>
                                ),
                                optionItemRender: (item: any) => {
                                    return (
                                        <>
                                            <Row
                                                wrap={false}
                                                justify={'space-between'}
                                            >
                                                <Col
                                                    span={22}
                                                    onClick={() =>
                                                        setSelectedCategory(
                                                            item.key
                                                        )
                                                    }
                                                >
                                                    {item.label}
                                                </Col>
                                                <Col span={2}>
                                                    <DeleteOutlined
                                                        onClick={(e) =>
                                                            handleDeleteCategory(
                                                                e,
                                                                item.key
                                                            )
                                                        }
                                                        style={{
                                                            color: 'red',
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                },
                            }}
                        />
                    </ProForm.Group>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <ProFormDatePicker
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                        name={'requestDate'}
                        label={'Date'}
                        fieldProps={{
                            format: 'MMMM DD, YYYY',
                            style: {
                                width: '100%',
                            },
                        }}
                    />
                </Col>

                {/* <ProFormDatePicker
                    fieldProps={{
                        format: 'MMMM DD, YYYY',
                    }}
                    name={'approvedDate'}
                    label={'Approval Date'}
                    initialValue={moment()}
                />
                <ProFormDatePicker
                    fieldProps={{
                        format: 'MMMM DD, YYYY',
                    }}
                    name={'releaseDate'}
                    label={'Release Date'}
                    initialValue={moment()}
                /> */}
            </Row>
            <ProFormTextArea
                name={'purpose'}
                label={'Purpose'}
                style={{
                    width: '100%',
                }}
            />
        </ModalForm>
    )
}

export default UpdateExpense
