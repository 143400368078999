import React, { useEffect, useState } from 'react'

import {
    VictoryBar,
    VictoryAxis,
    VictoryChart,
    VictoryLabel,
    VictoryLegend,
    VictoryGroup,
} from 'victory'
import { calculateVehiclePerformance } from '../Expenses/utils'
import { Vehicle } from '@/domain/entities/Transport'
import { Input, Modal } from 'antd'
interface VehicleFinancialCalculatorProps {
    data: Vehicle
}

const VehicleFinancialCalculator = (props: VehicleFinancialCalculatorProps) => {
    const [open, setIsOpen] = useState(false)
    const [yrs, setYears] = useState(5)
    const [averageDaiyRevenue, setAverageDaiyRevenue] = useState(3000)
    const [datum, setDatum] = useState<any>([])
    const [, setDatum2] = useState<any>([])
    useEffect(() => {
        const performance = calculateVehiclePerformance(
            yrs,
            averageDaiyRevenue,
            props.data
        )
        setDatum(performance.timeLogs)
        setDatum2(performance.obj)
    }, [props.data, averageDaiyRevenue, yrs])

    //   const metrics = ['revenue', 'expenses', 'netIncome'];

    const years = datum.map((report: any) => report.index)

    // const revenueData = datum.map((report: any) => report.revenue);
    // const expensesData = datum.map((report: any) => report.expenses);
    // const netIncomeData = datum.map((report: any) => report.netIncome);
    return (
        <Modal open={open} onCancel={() => setIsOpen(false)} width={600}>
            <label>Years</label>
            <Input
                value={yrs}
                onChange={(e) => setYears(parseInt(e.target.value))}
            />
            <label>Average Daily Income</label>
            <Input
                value={averageDaiyRevenue}
                onChange={(e) =>
                    setAverageDaiyRevenue(parseInt(e.target.value || '0'))
                }
            />
            <VictoryChart width={600} height={400} domainPadding={20}>
                <VictoryLegend
                    x={10}
                    y={10}
                    orientation="horizontal"
                    gutter={20}
                    style={{ border: { stroke: 'black' } }}
                    data={[
                        { name: 'Revenue', symbol: { fill: 'blue' } },
                        { name: 'Expenses', symbol: { fill: 'red' } },
                        { name: 'Net Income', symbol: { fill: 'green' } },
                    ]}
                />
                <VictoryAxis tickValues={years} tickFormat={(t) => `${t}`} />
                <VictoryAxis
                    tickLabelComponent={<VictoryLabel dx={24} />}
                    dependentAxis
                    // tickFormat={(t) => `₱${t}`}
                />
                <VictoryGroup offset={20} colorScale={['blue', 'red', 'green']}>
                    <VictoryBar data={datum} x="index" y="revenue" />
                    <VictoryBar data={datum} x="index" y="expenses" />
                    <VictoryBar data={datum} x="index" y="netIncome" />
                </VictoryGroup>
            </VictoryChart>
        </Modal>
    )
}

export default VehicleFinancialCalculator
