import { Row, Col, Checkbox, Form } from 'antd'
import { withTranslation } from 'react-i18next'
import { Slide, Zoom } from 'react-awesome-reveal'
import { ContactProps, ValidationTypeProps } from './types'
import { useForm } from '../../common/utils/useForm'
import validate from '../../common/utils/validationRules'
import { Button } from '../../common/Button'
import Block from '../Block'
import Input from '../../common/Input'
import TextArea from '../../common/TextArea'
import { ContactContainer, Span, ButtonContainer } from './styles'
import { collection, addDoc } from 'firebase/firestore'
import { CONTACT_SUCCESS_REPOSITORY } from '../../../../../data/repositories/constants'
import { useNavigate } from 'react-router-dom'
import { db } from '../../../../boot'

const Contact = ({ title, content, id, t }: ContactProps) => {
    const { values, errors, handleChange } = useForm(validate) as any
    const ValidationType = ({ type }: ValidationTypeProps) => {
        const ErrorMessage = errors[type]
        return (
            <Zoom direction="left">
                <Span erros={errors[type]}>{ErrorMessage}</Span>
            </Zoom>
        )
    }
    const navigate = useNavigate()
    // eslint-disable-next-line no-unused-vars
    const handleSubmit1 = async (props: any) => {
        const payload = {
            ...props,
        }
        // eslint-disable-next-line no-console
        console.log(payload)
        try {
            const ref = collection(db, CONTACT_SUCCESS_REPOSITORY)
            await addDoc(ref, payload)
            navigate('/contact-success')
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err)
            navigate('/contact-failure')
        }
        // eslint-disable-next-line no-console
        console.log(payload)
    }
    return (
        <ContactContainer id={id}>
            <Row justify="space-between" align="middle">
                <Col lg={12} md={11} sm={24} xs={24}>
                    <Slide direction="left">
                        <Block title={title} content={content} />
                    </Slide>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                    <Slide direction="right">
                        <Form
                            autoComplete="off"
                            initialValues={{
                                receivePromotions: true,
                            }}
                            onFinish={handleSubmit1}
                        >
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Your Name"
                                        value={values.name || ''}
                                        onChange={handleChange}
                                        name={'name'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        name="email"
                                        placeholder="Your Email"
                                        value={values.email || ''}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="phone"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        name="phone"
                                        placeholder="Your Phone"
                                        value={values.phone || ''}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="message"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder="Your Message"
                                        value={values.message || ''}
                                        name="message"
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="receivePromotions"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Receive Promotions</Checkbox>
                                </Form.Item>
                                <ValidationType type="message" />
                            </Col>
                            <Form.Item>
                                <ButtonContainer>
                                    <Button name="submit" htmlType="submit">
                                        {t('Submit')}
                                    </Button>
                                </ButtonContainer>
                            </Form.Item>
                        </Form>
                    </Slide>
                </Col>
            </Row>
        </ContactContainer>
    )
}

export default withTranslation()(Contact)
