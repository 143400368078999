import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import moment from 'moment'
import {
    Button,
    Card,
    Col,
    Divider,
    Drawer,
    Row,
    Statistic,
    Tooltip,
    Typography,
    message,
} from 'antd'
import { useAppSelector } from '../../../app/store'
import { shallowEqual } from 'react-redux'
import { randomId } from '../utils'
import { Attendance, Reservation } from '@/domain/entities/Attendance'
import './calendar.css'
import {
    addDoc,
    collection,
    doc,
    onSnapshot,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'
import { db } from '../../boot'
import {
    PERSONNEL_ATTENDANCE_REPOSITORY,
    TRIPS_REPOSITORY,
} from '../../../data/repositories/constants'
import EmployeeAvatar from '../../../app/components/EmployeeAvatar'
import { Personnel, Trip } from '../../../domain/entities/Transport'
import { getUser } from '../../../app/redux/team/team.action'
import {
    ProForm,
    ProFormCheckbox,
    ProFormDateTimePicker,
    ProFormDigit,
    ProFormTextArea,
} from '@ant-design/pro-components'
// import ReactSignatureCanvas from 'react-signature-canvas'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
// import { ref, uploadString, getDownloadURL } from 'firebase/storage'
// import { MdOutlineChangeCircle } from 'react-icons/md'
// interface ShiftSchedulerProps {
//     vehicle: Vehicle
// }

function ShiftScheduler() {
    const {
        // // categories,
        email,
        // styles,
        activeTeam,
        vehicle,
        drivers,
        assistants,
        currentVehicleId,
    } = useAppSelector(
        (state) => ({
            showNewExpense: state.systemConfig.showNewExpense,
            // categories: state.systemConfig.tripCategories,
            email: state.auth.user.email,
            styles: state.styles,
            activeTeam: state.team.selectedTeamData.partnerCode,
            drivers: state.team.drivers,
            assistants: state.team.assistants,
            vehicle: state.vehicle.selectedVehicleId
                ? state.vehicle.vehicles.entities[
                      state.vehicle.selectedVehicleId
                  ]
                : undefined,
            currentVehicleId: state.vehicle.selectedVehicleId,

            // categories: state.team.selectedTeamData.tripCategories,
        }),
        shallowEqual
    )
    const [loading, setLoading] = useState(false)
    const [attendanceObj, setAttendanceObj] = useState(undefined)
    const [currentEvent, setCurrentEvent] = useState<
        | (Attendance & {
              driver?: Personnel
              assistant?: Personnel
          })
        | undefined
    >(undefined)
    const [isAssigning, setIsAssigning] = useState(false)
    const [dates, setDates] = useState<number[]>([])
    const [filterDates, setFilterDates] = useState<number[]>([])
    const calendarRef = useRef<FullCalendar>(null)
    const [reservations, setReservations] = useState<any[]>([])
    const [newReservations, setNewReservations] = useState<any[]>([])
    const [lastClickTime, setLastClickTime] = useState(0)
    const [isRemovingSched, setIsRemovingSched] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const doubleClickThreshold = 300 // Adjust this threshold as needed
    const [currentReservations, setCurrentReservations] = useState<any[]>([])

    const [short, setShort] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [totalExpense, setTotalExpense] = useState(0)

    const [expenses, setExpenses] = useState({
        gasCost: 0,
        miscellaneousExpenses: 0,
        driverSalary: 0,
        assistantSalary: 0,
        driverIncentives: 0,
        assistantIncentives: 0,
        driverOvertimePay: 0,
        assistantOvertimePay: 0,
    })
    // const signatureRef = useRef()
    const [endForm] = ProForm.useForm()
    // load external events

    const addEventToCalendar = (event: any) => {
        const calendarApi = calendarRef.current?.getApi()

        // Create an event object
        // const event = {
        //   title: 'New Event',
        //   start: '2023-09-15', // Replace with your desired start date
        //   end: '2023-09-16',   // Replace with your desired end date
        // };

        // Add the event to the calendar
        if (calendarApi) {
            calendarApi.addEvent(event)
        }
    }
    const handleEventReceive = (eventInfo: any) => {
        if (eventInfo.draggedEl.localName !== 'a') {
            eventInfo.revert()
        }
        if (!eventInfo.draggedEl.getAttribute('data-custom')) return
        const found = [...reservations, ...newReservations].find((ev: any) => {
            if (ev.custom === eventInfo.draggedEl.getAttribute('data-custom'))
                return true
            return false
        })
        if (found) return false
        const date = moment(eventInfo.event.startStr, 'YYYY-MM-DD').hours(5)
        const end = date.clone().add(8, 'hours')
        const newEvent = {
            id: randomId(8),
            title: eventInfo.draggedEl.getAttribute('title'),
            color: eventInfo.draggedEl.getAttribute('data-color'),
            start: date.format(),
            end: end.format(),
            className: 'new-assigning',
            custom:
                eventInfo.draggedEl.getAttribute('data-id') +
                `-${eventInfo.event.startStr}`,
        }
        if (!dates.includes(date.unix())) {
            setDates([...dates, date.unix()])
        }
        addEventToCalendar(newEvent)
        setNewReservations([...newReservations, newEvent])
        return true
    }

    const filterReservationsByDate = (
        reservations: any[],
        unixTimestamp: number
    ): Reservation | undefined => {
        const targetDate = moment.unix(unixTimestamp).startOf('day')
        let driverId: any
        let assistantId: any
        let driverName = ''
        let assistantName = ''
        let start = ''
        let end = ''
        const filtered = reservations.filter((reservation) => {
            const start = moment(reservation.start).startOf('day')
            const end = moment(reservation.end).startOf('day')
            return start.isSame(targetDate) || end.isSame(targetDate)
        })
        const dateFrmt = targetDate.format('-YYYY-MM-DD')

        filtered.forEach((res) => {
            if (res.color !== '#CAC601' && !driverId) {
                driverId = res.custom.replace(dateFrmt, '')
                start = res.start
                end = res.end

                driverName = res.title
            }
        })
        filtered.forEach((res) => {
            if (res.color === '#CAC601' && !assistantId) {
                assistantId = res.custom.replace(dateFrmt, '')
                assistantName = res.title
            }
        })
        if (!driverId) return undefined

        return {
            date: targetDate.format('MMM DD, YYYY'),
            driverId,
            assistantId,
            title: driverName + ' - ' + assistantName,
            assistantName,
            driverName,
            start,
            end,
        }
    }

    const onConfirmAssignment = async () => {
        setLoading(true)
        // @ts-ignore
        // const calendarApi = calendarRef.current?.getApi();
        const filtered: Reservation[] = []
        // if (isRemovingSched) {
        //     await updateDoc(
        //         doc(db, VEHICLE_REPOSITORY, currentVehicleId as string),
        //         {
        //             reservations: reservations,
        //         }
        //     )
        //     handelCancelRemoveSchedule()
        // }
        dates.forEach((date) => {
            const newReturned = filterReservationsByDate(newReservations, date)
            // eslint-disable-next-line no-console
            // console.log(newReturned);
            if (newReturned) {
                filtered.push(newReturned)
            }
        })

        filtered.forEach(async (res) => {
            const payload: Attendance = {
                ...res,
                withAssistant:
                    res.assistantId !== undefined || res.assistantId !== '',
                date: res.date ? moment(res.date).format('MMM DD, YYYY') : '',
                timeIn: moment(res.date).unix(),
                timeOut: 0,
                vehicleId: currentVehicleId,
                creation: moment().unix(),
                modified: moment().unix(),
                partnerCode: activeTeam,
                incentives: 0,
                salary: 0,
                employeeRole: '',
                dispatchingCost: 0,
                status: 'pending',
                driverSalary: 0,
                driverIncentives: 0,
                driverOvertimeHours: 0,
                driverOvertimePay: 0,
                assistantSalary: 0,
                assistantIncentives: 0,
                assistantOvertimeHours: 0,
                assistantOvertimePay: 0,
                currentTripId: vehicle?.activeTripId || '',
                shiftStartTime: 0,
                shiftEndTime: 0,
                modifiedBy: email,
                isRemoved: false,
                unit: vehicle?.title || '',
                routeName: vehicle?.routeName || '',
                totalCashReceived: 0,
                totalExpenses: 0,
                totalGasConsumed: 0,
                totalGasCost: 0,
                totalPassengers: 0,
                totalRevenue: 0,
                totalRoundTrips: 0,
                cardRevenue: 0,
                cashCollected: 0,
                signatureUrl: '',
                assistantSss: '',
                assistantPhilhealth: '',
                assistantPagibig: '',
                driverSss: '',
                driverPhilhealth: '',
                driverPagibig: '',
                overtimePay: 0,
                overtimeHours: 0,
                paymentStatus: 'pending',
                driverStatus: 'pending',
                assistantStatus: 'pending',
                employeeStatus: 'pending',
                isAssistantHalfDay: false,
                isDriverHalfDay: false,
                isEmployeeHalfDay: false,
            }

            const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)
            await addDoc(ref, payload)
            message.success(
                `Successfully created attendance for ${payload.title}`
            )
        })
        setLoading(false)
        // eslint-disable-next-line no-console
        setNewReservations([])
        setIsAssigning(false)
        message.success('Successfully assigned shifts to bus personnels.')
        // addEventToCalendar()
    }
    const handleDateDoubleClick = (info: any) => {
        // const clickedDate = info.date;
        const currentTime = new Date().getTime()

        if (currentTime - lastClickTime < doubleClickThreshold) {
            if (isAssigning) {
                message.error("Can't remove schedule while assigning.")
                return
            }
            if (info.event.title.includes('-')) {
                return
            }
            // Handle double-click action here
            // eslint-disable-next-line no-console
            // eslint-disable-next-line no-console
            let newReserves = [...newReservations]
            let reserves = [...reservations]
            const newReservationIdx = newReserves.findIndex(
                (res) =>
                    res.title === info.event.title &&
                    res.start === info.event.startStr &&
                    res.end === info.event.endStr
            )
            if (newReservationIdx >= 0) {
                info.event.remove()
                newReserves.splice(newReservationIdx, 1)
                setNewReservations(newReserves)
            }

            const reservationIdx = reserves.findIndex(
                (res) =>
                    res.title === info.event.title &&
                    res.start === info.event.startStr &&
                    res.end === info.event.endStr
            )
            if (reservationIdx >= 0) {
                info.event.remove()
                reserves.splice(reservationIdx, 1)
                setReservations(reserves)
            }
        } else {
            // Set the last click time for future comparisons
            setLastClickTime(currentTime)
            // eslint-disable-next-line no-console
            if (attendanceObj && attendanceObj[info.event.id]) {
                handleOpenDrawer(attendanceObj[info.event.id])
            }
        }
    }

    const handelCancelRemoveSchedule = () => {
        // setReservations(vehicle?.reservations || [])
        setIsRemovingSched(false)
    }

    const handelCancelRemoveNewSchedule = () => {
        // @ts-ignore
        const calendarApi = calendarRef.current?.getApi()
        if (calendarApi) {
            newReservations.forEach((res: any) => {
                const evt = calendarApi.getEventById(res.id)
                if (evt) {
                    evt.remove()
                }
            })
            setNewReservations([])
            setCurrentReservations(reservations)
            setIsAssigning(false)
            setIsRemovingSched(false)
        }
    }

    const handleNewShiftAssignment = () => {
        // setCurrentReservations([])
        setIsAssigning(true)
    }

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
    }

    const handleOpenDrawer = (event: any) => {
        // eslint-disable-next-line no-console
        console.log(
            event,
            reservations.filter((res) => res.id === event.id)
        )

        setCurrentEvent({
            ...event,
            withAssistant: event.assistant !== undefined,
        })
        endForm.resetFields()
        if (event.status === 'done') {
            endForm.setFieldsValue({
                ...event,
                shiftStartTime: moment.unix(event.timeIn),
                shiftEndTime:
                    event.status === 'done'
                        ? moment.unix(event.timeOut)
                        : undefined,
            })

            setShort(
                event.totalCashReceived -
                    event.cashCollected +
                    event.miscellaneousExpenses +
                    event.dispatchingCost
                // event.totalGasCost
            )
        } else {
            setShort(0)
            endForm.setFieldsValue({
                // ...event,
                shiftStartTime: moment.unix(event.timeIn),
                shiftEndTime: moment.isMoment(moment.unix(event.timeOut))
                    ? moment.unix(event.timeOut)
                    : undefined,
                driverSalary: event.driver.salary,
                assistantSalary: event?.assistant?.salary || 0,
            })
        }

        setTotalExpense(event.totalExpenses)
        setExpenses({
            ...event,
        })
        setOpenDrawer(true)
    }
    const handleDatesSet = (args: any) => {
        // eslint-disable-next-line no-console
        setFilterDates([
            moment(args.startStr).unix(),
            moment(args.endStr).unix(),
        ])
    }
    // const handleUploadChange = async (
    //     file: { name: string; file: any },
    //     _callback?: (_progress: number) => void
    // ) => {
    //     // eslint-disable-next-line no-console

    //     const logoRef = ref(
    //         storage,
    //         `/${activeTeam}/schedulingremittance/${moment().unix()}/${
    //             file.name + '.png'
    //         }`
    //     )
    //     const uploadTask = await uploadString(logoRef, file.file, 'data_url', {
    //         contentType: 'image/png',
    //     })

    //     const url = await getDownloadURL(uploadTask.ref)
    //     return url
    // }
    // const clearSignature = () => {
    //     // @ts-ignore
    //     signatureRef?.current?.clear()
    // }
    const onSubmitEndTrip = async (values: any) => {
        if (!currentEvent) return
        setLoading(true)
        try {
            const strt = moment(values?.shiftStartTime, 'MMMM DD, YYYY HH:mm')
            const end = moment(values?.shiftEndTime, 'MMMM DD, YYYY HH:mm')

            // const dataUrl = await handleUploadChange({
            //     name: 'end-shift-confirmation',
            //     // @ts-ignore
            //     file: signatureRef?.current.toDataURL(),
            // })

            const payload: Trip = {
                ...values,
                creation: moment().unix(),
                modified: moment().unix(),
                timeOut: '',
                startOvertime: '',
                endOvertime: '',
                // totalRoundTrips,
                paymentStatus: 'pending',
                status: 'done',
                amountCollected: 0,
                isRemoved: false,
                modifiedBy: email,
                partnerCode: activeTeam,
                timeIn: '',
                withAssistant:
                    currentEvent.assistant &&
                    currentEvent.assistantId !== '' &&
                    currentEvent.assistantId !== undefined
                        ? true
                        : false,
                date: strt.format('ddd, MMMM DD, YYYY'),
                driverId: currentEvent?.driver?.id as string,
                assistantId: currentEvent?.assistant?.id as string,
                vehicleId: vehicle?.id as string,
                notes: values.notes,
                driverPayoutStatus: 'pending',
                assistantPayoutStatus: 'pending',
                hours: 0,
                shiftStartTime: strt.unix(),
                // totalGasConsumed: totalGasConsumed + pendingGasConsumed,
                // totalGasCost: gasCost + pendingGasCost,
                distanceTraveled: 0,
                totalPassengers: 0,
                shiftEndTime: end.unix(),
                totalExpenses: totalExpense,
                // cashCollected: cash + pendingCashCollected,
                // miscellaneousExpenses,
                maintenanceFee: 0,
                totalRevenue,
                // cardRevenue: card + pendingCardRevenue,
                // driverSalary,
                // assistantSalary,
                // dailyQuotaIncentives: dailyQuotaIncentives + pendingIncentives,
                // dispatchingCost: dispatchingCost + pendingDispatchingCost,
                assistant: currentEvent?.assistant,
                driver: currentEvent?.driver,
                // driverAllowance,
                // assistantAllowance,
                shiftHistory: [],
                // totalCashReceived: totalCashReceived + pendingTotalCashReceived,
                title: `${vehicle?.title} - ${vehicle?.routeName}`,
                // driverAllowance: 0,
                // assistantAllowance: 0,
                // overtimeHours,
            }

            const attendanceUpdatePayload: any = {
                currentOdometer: payload.currentOdometer,
                totalCashReceived: payload.totalCashReceived || 0,
                totalExpenses: payload.totalExpenses || 0,
                totalGasConsumed: payload.totalGasConsumed || 0,
                totalGasCost: payload.totalGasCost || 0,
                totalPassengers: payload.totalPassengers || 0,
                totalRevenue: payload.totalRevenue || 0,
                totalRoundTrips: payload.totalRoundTrips || 0,
                driverIncentives: payload.driverIncentives || 0,
                driverOvertimeHours: payload.driverOvertimeHours || 0,
                driverOvertimePay: payload.driverOvertimePay || 0,
                driverSalary: payload.driverSalary || 0,
                assistantIncentives: payload.assistantIncentives || 0,
                assistantOvertimeHours: payload.assistantOvertimeHours || 0,
                assistantOvertimePay: payload.assistantOvertimePay || 0,
                assistantSalary: payload.assistantSalary || 0,
                cardRevenue: payload.cardRevenue || 0,
                cashCollected: payload.cashCollected || 0,
                dispatchingCost: payload.dispatchingCost || 0,
                timeIn: payload.shiftStartTime || 0,
                timeOut: payload.shiftEndTime || 0,
                miscellaneousExpenses: payload.miscellaneousExpenses || 0,
                partnerCode: payload.partnerCode,
                assistantSss: currentEvent?.assistant?.sss || '',
                assistantPhilhealth: currentEvent?.assistant?.philhealth || '',
                assistantPagibig: currentEvent?.assistant?.pagibig || '',
                driverSss: currentEvent?.driver?.sss || '',
                driverPhilhealth: currentEvent?.driver?.philhealth || '',
                driverPagibig: currentEvent?.driver?.pagibig || '',
                signatureUrl: payload.signatureUrl || '',
                driver: payload.driver,
                assistant: payload.assistant,
                withAssistant: payload.withAssistant,
                isAssistantHalfDay: payload.isAssistantHalfDay,
                isDriverHalfDay: payload.isDriverHalfDay,
                status: 'done',
            }
            setCurrentEvent({
                ...currentEvent,
                ...attendanceUpdatePayload,
            })
            const ref = doc(
                db,
                PERSONNEL_ATTENDANCE_REPOSITORY,
                currentEvent.id as string
            )
            await updateDoc(ref, {
                ...attendanceUpdatePayload,
            })
            // eslint-disable-next-line no-console
            // console.log(attendanceUpdatePayload, vehicle)

            // @ts-ignore
            // eslint-disable-next-line no-console
            // console.log(payload)
            await addDoc(collection(db, TRIPS_REPOSITORY), payload)

            // await updateDoc(
            //     doc(db, VEHICLE_REPOSITORY, vehicle?.id as string),
            //     {
            //         // ...values,
            //         status: 'available',
            //         currentDriverId: '',
            //         currentAssistantId: '',
            //         modified: moment().unix(),
            //         modifiedBy: email,
            //     }
            // )
            message.success('Successfully end trip')
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err)
        }
        setLoading(false)
        handleCloseDrawer()
    }
    useEffect(() => {
        if (!filterDates[0]) return
        let draggableEl = document.getElementById('external-events')
        if (!draggableEl) return
        new Draggable(draggableEl, {
            itemSelector: '.fc-event',
            eventData: function (eventEl) {
                let id = eventEl.dataset.id
                let title = eventEl.getAttribute('title')
                let color = eventEl.getAttribute('data-color')
                let custom = eventEl.dataset.custom

                return {
                    id: id,
                    title: title,
                    color: color,
                    custom: custom,
                    create: true,
                }
            },
        })

        const main = () => {
            const ref = collection(db, PERSONNEL_ATTENDANCE_REPOSITORY)
            // eslint-disable-next-line no-console
            // console.log(currentVehicleId);

            const q = query(
                ref,
                where('timeIn', '>=', filterDates[0]),
                where('timeIn', '<=', filterDates[1]),
                where('vehicleId', '==', currentVehicleId)
            )
            // const snaps = await getDocs(q)
            const snap2 = onSnapshot(q, async (snaps) => {
                const obj: any = {}
                const res = snaps.docs.map(async (docSnap) => {
                    const data = docSnap.data()
                    let driver = data?.driver
                    let withAssistant = data?.withAssistant
                    let assistant = data?.assistant
                    if (
                        data.status === 'pending' ||
                        !data.driver ||
                        !data.assistant
                    ) {
                        driver = await getUser(data.driverId)
                        if (data.assistantId) {
                            withAssistant = true
                            assistant = await getUser(data.assistantId)
                        }
                    }
                    if (!obj[docSnap.id]) {
                        obj[docSnap.id] = {}
                    }
                    if (!driver) return false
                    const title =
                        data.title ||
                        `${driver?.lastName}, ${driver?.firstName[0]} - ${assistant?.lastName}, ${assistant?.firstName[0]}.`
                    const payload = {
                        ...data,
                        driver,
                        start: moment(data.date, 'MMM DD, YYYY').format(),
                        withAssistant,
                        assistant,
                        title,
                        id: docSnap.id,
                        color:
                            data.status === 'pending'
                                ? 'orange'
                                : data.status === 'done'
                                ? 'green'
                                : 'yellow',
                        editable: false,
                    }
                    obj[docSnap.id] = payload
                    return payload
                })
                const promises = await Promise.all(res)
                // eslint-disable-next-line no-console
                // console.log(promises, filterDates)
                setReservations(promises.filter(Boolean))
                setAttendanceObj(obj)
            })
            return snap2
        }
        const snapper = main()
        return () => {
            // setReservations([])
            // setNewReservations([])
            snapper()
        }
    }, [currentVehicleId, filterDates])

    useEffect(() => {
        if (isAssigning) return
        setCurrentReservations([...reservations, ...newReservations])
    }, [reservations, newReservations, isAssigning, filterDates])

    // eslint-disable-next-line no-console
    // console.log(filterDates)

    return (
        <div className="App" key={currentVehicleId}>
            <Drawer
                destroyOnClose
                open={openDrawer}
                onClose={handleCloseDrawer}
                // extra={
                //     currentEvent &&
                //     currentEvent.status !== 'done' && (
                //         <Tooltip title="Change Driver/Assistant">
                //             <Button
                //                 shape="circle"
                //                 type="link"
                //                 size="large"
                //                 danger
                //             >
                //                 <MdOutlineChangeCircle
                //                     style={{ fontSize: 24 }}
                //                 />
                //             </Button>
                //         </Tooltip>
                //     )
                // }
                title={currentEvent ? currentEvent.title : ''}
            >
                {currentEvent && (
                    <div>
                        <Row gutter={[0, 12]}>
                            <Col xs={24} md={12}>
                                <EmployeeAvatar
                                    employee={currentEvent.driver as Personnel}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <EmployeeAvatar
                                    employee={
                                        currentEvent.assistant as Personnel
                                    }
                                />
                            </Col>
                        </Row>
                        <Divider />

                        <ProForm
                            form={endForm}
                            onFinishFailed={(err) => {
                                if (err.errorFields.length) {
                                    const firstError = err.errorFields[0]
                                    message.destroy()
                                    message.error(firstError.errors)
                                    endForm.scrollToField(firstError.name, {
                                        behavior: 'smooth',
                                        block: 'center',
                                    })
                                }
                            }}
                            disabled={currentEvent.status === 'done'}
                            onFinish={onSubmitEndTrip}
                            onValuesChange={(changed, vals) => {
                                // eslint-disable-next-line no-console
                                // console.log(changed, vals)
                                // const pendingCashReceived =
                                //     vehicle?.temporaryShiftDetails?.totalCashReceived ||
                                //     0
                                // const pendingCashCollected =
                                //     vehicle?.temporaryShiftDetails?.cashCollected || 0
                                // const pendingCashReceived = vehicle?.temporaryShiftDetails?.totalCashReceived

                                const driverSalary = vals['driverSalary'] || 0
                                const assistantSalary =
                                    vals['assistantSalary'] || 0
                                if (
                                    typeof vals['isAssistantHalfDay'] ===
                                        'boolean' &&
                                    currentEvent.driver &&
                                    currentEvent.driver.salary
                                ) {
                                    endForm.setFieldsValue({
                                        driverSalary: vals['isDriverHalfDay']
                                            ? currentEvent.driver.salary / 2
                                            : currentEvent.driver.salary,
                                        // driverSalary: currentEvent.driver.salary / 2,
                                    })
                                }
                                if (
                                    typeof vals['isAssistantHalfDay'] ===
                                        'boolean' &&
                                    currentEvent.assistant &&
                                    currentEvent.assistant.salary
                                ) {
                                    endForm.setFieldsValue({
                                        assistantSalary: vals[
                                            'isAssistantHalfDay'
                                        ]
                                            ? currentEvent.assistant.salary / 2
                                            : currentEvent.assistant.salary,
                                    })
                                }
                                // if(vals[])
                                const cashCollected = vals['cashCollected'] || 0
                                const cardRevenue = vals['cardRevenue'] || 0
                                const totalCashReceived =
                                    vals['totalCashReceived'] || 0
                                const driverOvertimeHours =
                                    vals['driverOvertimeHours'] || 0
                                const overtimePayEquation = 1.25
                                const driverOvertimePay =
                                    Math.round(
                                        (driverSalary / 8) * overtimePayEquation
                                    ) * driverOvertimeHours

                                endForm.setFieldsValue({
                                    driverOvertimePay,
                                })
                                const assistantOvertimeHours =
                                    vals['assistantOvertimeHours'] || 0
                                const assistantOvertimePay =
                                    Math.round(
                                        (assistantSalary / 8) *
                                            overtimePayEquation
                                    ) * assistantOvertimeHours

                                endForm.setFieldsValue({
                                    assistantOvertimePay,
                                })
                                const totalGasCost = vals['totalGasCost'] || 0
                                const dispatchingCost =
                                    vals['dispatchingCost'] || 0
                                // const dailyQuotaIncentives =
                                //     vals['dailyQuotaIncentives'] || 0
                                const miscellaneousExpenses =
                                    vals['miscellaneousExpenses'] || 0
                                const driverIncentives =
                                    vals['driverIncentives'] || 0
                                const assistantIncentives =
                                    vals['assistantIncentives'] || 0
                                setExpenses({
                                    driverIncentives,
                                    assistantIncentives,
                                    assistantSalary,
                                    driverSalary,
                                    gasCost: totalGasCost,
                                    miscellaneousExpenses,
                                    driverOvertimePay,
                                    assistantOvertimePay,
                                })
                                const totalIncentives =
                                    driverIncentives + assistantIncentives
                                // const pendingExpense =
                                //     vehicle?.temporaryShiftDetails?.totalExpenses || 0
                                // const pendingRevenue =
                                //     vehicle?.temporaryShiftDetails?.totalRevenue || 0
                                // const pendingShort =
                                //     pendingCashReceived - pendingCashCollected
                                setTotalExpense(
                                    totalGasCost +
                                        dispatchingCost +
                                        totalIncentives +
                                        miscellaneousExpenses +
                                        driverSalary +
                                        assistantSalary +
                                        assistantOvertimePay +
                                        driverOvertimePay
                                )
                                setTotalRevenue(cashCollected + cardRevenue)
                                // const currentExpense = dispatchingCost + dailyQuotaIncentives + miscellaneousExpenses
                                setShort(
                                    totalCashReceived -
                                        cashCollected +
                                        miscellaneousExpenses +
                                        dispatchingCost
                                )
                            }}
                        >
                            <ProForm.Item
                                label={
                                    <Typography.Text
                                        style={{ fontWeight: 'bolder' }}
                                    >
                                        Shift Details
                                    </Typography.Text>
                                }
                            >
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} md={12}>
                                        <ProFormDateTimePicker
                                            fieldProps={{
                                                format: 'MMMM DD, YYYY HH:mm',
                                                // use12Hours: true,
                                                style: {
                                                    width: '100%',
                                                },
                                            }}
                                            width={216}
                                            name={'shiftStartTime'}
                                            label={'Departed'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <ProFormDateTimePicker
                                            fieldProps={{
                                                format: 'MMMM DD, YYYY HH:mm',
                                                // use12Hours: true,
                                                style: {
                                                    width: '100%',
                                                },
                                            }}
                                            name={'shiftEndTime'}
                                            label={'Arrived'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            // addonBefore={'₱'}
                                            name="cashCollected"
                                            label="Cash Revenue"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            // addonBefore={<div>₱</div>}
                                            name="cardRevenue"
                                            label="Card Revenue"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                        />
                                    </Col>
                                </Row>

                                <Row gutter={[12, 0]}>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            // addonBefore={<div>₱</div>}
                                            name="totalCashReceived"
                                            label="Cash Received"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                            // addonAfter={'each'}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            // addonBefore={<div>₱</div>}
                                            name="totalRoundTrips"
                                            label="Total Round Trips"
                                            // addonAfter={'each'}
                                        />
                                    </Col>
                                </Row>

                                <Row gutter={[12, 0]}>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            // addonBefore={<div>₱</div>}
                                            name="totalPassengers"
                                            label="No. of Passengers"
                                            // addonAfter={'each'}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}></Col>
                                </Row>
                            </ProForm.Item>

                            <ProForm.Item
                                label={
                                    <Typography.Text
                                        style={{ fontWeight: 'bolder' }}
                                    >
                                        Unit Expense
                                    </Typography.Text>
                                }
                            >
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            // addonBefore={<div>₱</div>}
                                            name="totalGasCost"
                                            label="Gas Cost"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            // addonAfter={'Liter'}
                                            name="totalGasConsumed"
                                            label="Gas Equivalent in Liters"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                        />
                                    </Col>
                                </Row>

                                <Row gutter={[12, 0]}>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            // addonBefore={<div>₱</div>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                            name="dispatchingCost"
                                            label="Dispatching Cost"
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            // addonBefore={<div>₱</div>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                            name="miscellaneousExpenses"
                                            label="Miscellaneous Expenses"
                                            // addonAfter={'each'}
                                        />
                                    </Col>
                                </Row>

                                <Row gutter={[12, 0]}>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            // addonBefore={<div>₱</div>}
                                            name="currentOdometer"
                                            label="Current Odometer of Vehicle in KM"
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Item>
                            <ProForm.Item
                                label={
                                    <Typography.Text
                                        style={{ fontWeight: 'bolder' }}
                                    >
                                        Bus Driver Pay
                                    </Typography.Text>
                                }
                            >
                                <ProFormCheckbox
                                    name={'isDriverHalfDay'}
                                    bordered
                                >
                                    Driver is Half Day
                                </ProFormCheckbox>
                                <Row gutter={[12, 12]}>
                                    <Col span={12}>
                                        <ProFormDigit
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                            name="driverSalary"
                                            label="Driver Salary"
                                            // disabled
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ProFormDigit
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                            name="driverIncentives"
                                            label="Incentives"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                            // addonBefore={<div>₱</div>}
                                            name="driverOvertimeHours"
                                            label="Overtime Hours"
                                            // addonAfter={'each'}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <ProFormDigit
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required',
                                                },
                                            ]}
                                            // addonBefore={<div>₱</div>}
                                            name="driverOvertimePay"
                                            label="Overtime Pay"
                                            // addonAfter={'each'}
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Item>
                            {currentEvent.withAssistant && (
                                <ProForm.Item
                                    label={
                                        <Typography.Text
                                            style={{ fontWeight: 'bolder' }}
                                        >
                                            Bus Assistant Pay
                                        </Typography.Text>
                                    }
                                >
                                    <ProFormCheckbox
                                        name={'isAssistantHalfDay'}
                                        // fieldProps={currentEvent.status === 'done' ? {
                                        //     // @ts-ignore
                                        //     checked: currentEvent?.isAssistantHalfDay || false
                                        // } : undefined}
                                    >
                                        PAO is Half Day
                                    </ProFormCheckbox>
                                    <Row gutter={[12, 12]}>
                                        <Col span={12}>
                                            <ProFormDigit
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'This field is required',
                                                    },
                                                ]}
                                                name="assistantSalary"
                                                label="Assistant Salary"
                                                // disabled
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ProFormDigit
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'This field is required',
                                                    },
                                                ]}
                                                name="assistantIncentives"
                                                label="Incentives"
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]}>
                                        <Col xs={24} md={12}>
                                            <ProFormDigit
                                                // addonBefore={<div>₱</div>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'This field is required',
                                                    },
                                                ]}
                                                name="assistantOvertimeHours"
                                                label="Overtime Hours"
                                                // addonAfter={'each'}
                                            />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <ProFormDigit
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'This field is required',
                                                    },
                                                ]}
                                                // addonBefore={<div>₱</div>}
                                                name="assistantOvertimePay"
                                                label="Overtime Pay"
                                                // addonAfter={'each'}
                                            />
                                        </Col>
                                    </Row>
                                </ProForm.Item>
                            )}

                            <ProFormTextArea
                                name="notes"
                                label="Notes"
                                formItemProps={{
                                    style: {
                                        marginBottom: 0,
                                    },
                                }}
                            />
                            <ProForm.Item>
                                <Row>
                                    <Col xs={24} md={8}>
                                        <Card
                                            bordered={false}
                                            bodyStyle={{
                                                padding: 12,
                                            }}
                                        >
                                            <Statistic
                                                title={
                                                    <Typography.Text>
                                                        Short/Over
                                                    </Typography.Text>
                                                }
                                                value={short}
                                                precision={2}
                                                valueStyle={{
                                                    color:
                                                        short >= 0
                                                            ? '#3f8600'
                                                            : 'red',
                                                }}
                                                prefix={
                                                    <span>
                                                        {short > 0 ? (
                                                            <ArrowUpOutlined />
                                                        ) : short ===
                                                          0 ? null : (
                                                            <ArrowDownOutlined />
                                                        )}
                                                        ₱
                                                    </span>
                                                }
                                            />
                                        </Card>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Card
                                            bordered={false}
                                            bodyStyle={{
                                                padding: 12,
                                            }}
                                        >
                                            <Statistic
                                                title={
                                                    <Typography.Text
                                                    // style={{ color: '#1890ff' }}
                                                    // onClick={() =>
                                                    //     showRevenueBreakdown()
                                                    // }
                                                    >
                                                        Gross Revenue
                                                    </Typography.Text>
                                                }
                                                // style={{
                                                //     color: '#1890ff',
                                                // }}
                                                value={
                                                    currentEvent.status ===
                                                    'done'
                                                        ? currentEvent.totalRevenue
                                                        : totalRevenue
                                                }
                                                precision={2}
                                                // valueStyle={{
                                                //     color: '#3f8600',
                                                // }}
                                                prefix={'₱'}
                                            />
                                        </Card>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Card
                                            bordered={false}
                                            bodyStyle={{
                                                padding: 12,
                                            }}
                                        >
                                            <Tooltip
                                                title={
                                                    vehicle &&
                                                    currentEvent?.driver &&
                                                    currentEvent?.assistant && (
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <Typography.Text
                                                                            type="secondary"
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            Driver
                                                                            Salary
                                                                        </Typography.Text>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        <Typography.Text
                                                                            style={{
                                                                                color: 'white',
                                                                                fontWeight:
                                                                                    'bolder',
                                                                            }}
                                                                        >
                                                                            ₱
                                                                            {expenses.driverSalary ||
                                                                                '---'}
                                                                        </Typography.Text>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography.Text
                                                                            type="secondary"
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            Driver
                                                                            Incentives
                                                                        </Typography.Text>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        <Typography.Text
                                                                            style={{
                                                                                color: 'white',
                                                                                fontWeight:
                                                                                    'bolder',
                                                                            }}
                                                                        >
                                                                            ₱
                                                                            {expenses.driverIncentives ||
                                                                                '---'}
                                                                        </Typography.Text>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography.Text
                                                                            type="secondary"
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            Driver
                                                                            Overtime
                                                                            Pay
                                                                        </Typography.Text>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        <Typography.Text
                                                                            style={{
                                                                                color: 'white',
                                                                                fontWeight:
                                                                                    'bolder',
                                                                            }}
                                                                        >
                                                                            ₱
                                                                            {expenses.driverOvertimePay ||
                                                                                '---'}
                                                                        </Typography.Text>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography.Text
                                                                            type="secondary"
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            Assistant
                                                                            Salary
                                                                        </Typography.Text>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        <Typography.Text
                                                                            style={{
                                                                                color: 'white',
                                                                                fontWeight:
                                                                                    'bolder',
                                                                            }}
                                                                        >
                                                                            ₱
                                                                            {expenses.assistantSalary ||
                                                                                '---'}
                                                                        </Typography.Text>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography.Text
                                                                            type="secondary"
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            Assistant
                                                                            Incentives
                                                                        </Typography.Text>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        <Typography.Text
                                                                            style={{
                                                                                color: 'white',
                                                                                fontWeight:
                                                                                    'bolder',
                                                                            }}
                                                                        >
                                                                            ₱
                                                                            {expenses.assistantIncentives ||
                                                                                '---'}
                                                                        </Typography.Text>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography.Text
                                                                            type="secondary"
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            Assistant
                                                                            Overtime
                                                                            Pay
                                                                        </Typography.Text>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        <Typography.Text
                                                                            style={{
                                                                                color: 'white',
                                                                                fontWeight:
                                                                                    'bolder',
                                                                            }}
                                                                        >
                                                                            ₱
                                                                            {expenses.assistantOvertimePay ||
                                                                                '---'}
                                                                        </Typography.Text>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography.Text
                                                                            type="secondary"
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            Gas
                                                                            Cost
                                                                        </Typography.Text>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        <Typography.Text
                                                                            style={{
                                                                                color: 'white',
                                                                                fontWeight:
                                                                                    'bolder',
                                                                            }}
                                                                        >
                                                                            ₱
                                                                            {expenses.gasCost ||
                                                                                '---'}
                                                                        </Typography.Text>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography.Text
                                                                            type="secondary"
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            Miscellaneous
                                                                            Expenses
                                                                        </Typography.Text>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        <Typography.Text
                                                                            style={{
                                                                                color: 'white',
                                                                                fontWeight:
                                                                                    'bolder',
                                                                            }}
                                                                        >
                                                                            ₱
                                                                            {expenses.miscellaneousExpenses ||
                                                                                '---'}
                                                                        </Typography.Text>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    )
                                                }
                                            >
                                                <Statistic
                                                    title={
                                                        <Typography.Text>
                                                            Total Expenses
                                                        </Typography.Text>
                                                    }
                                                    value={totalExpense}
                                                    precision={2}
                                                    valueStyle={{
                                                        color: 'red',
                                                    }}
                                                    prefix={'₱'}
                                                />
                                            </Tooltip>
                                        </Card>
                                    </Col>
                                </Row>
                                {/* <Row justify={'center'}>
                                    <Col>
                                        <Button
                                            onClick={showRevenueBreakdown}
                                            shape="round"
                                        >
                                            Remittance History
                                        </Button>
                                    </Col>
                                </Row> */}
                            </ProForm.Item>
                            {/* <Row gutter={[12, 12]}>
                                <Col span={12}>
                                    <ProFormDigit
                                        name="totalRoundTrips"
                                        label="Total round trips"
                                    />
                                </Col>
                                <Col span={12}></Col>
                            </Row> */}
                            {/* <ProFormCheckbox name="driverPayoutStatus">
                                Check this box if the <b>Driver</b> has already been
                                paid.
                            </ProFormCheckbox>
                            <ProFormCheckbox name="assistantPayoutStatus">
                                Check this box if the <b>Assistant</b> has already been
                                paid.
                            </ProFormCheckbox> */}
                            {/* <ProForm.Item label={'Signature'}>
                                <div
                                    style={{
                                        border: `1px solid rgba(0,0,0, 0.2)`,
                                        position: 'relative',
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: 12,
                                            position: 'absolute',
                                        }}
                                    >
                                        <Button
                                            onClick={clearSignature}
                                            shape="round"
                                            type="primary"
                                        >
                                            Clear
                                        </Button>
                                    </div>
                                    <div>
                                        <ReactSignatureCanvas
                                            ref={signatureRef}
                                            penColor="green"
                                            canvasProps={{
                                                width: 500,
                                                height: 200,
                                                className: 'sigCanvas',
                                            }}
                                        />
                                    </div>
                                </div>
                            </ProForm.Item> */}
                        </ProForm>
                    </div>
                )}
            </Drawer>
            <br />
            <Row justify={'space-between'}>
                <Col>
                    <Typography.Title level={2}>
                        {vehicle?.title} - {vehicle?.routeName}
                    </Typography.Title>
                </Col>
                <Col>
                    <Row gutter={[12, 12]} justify={'end'}>
                        {!isRemovingSched && (
                            <>
                                {isAssigning ? (
                                    <Col>
                                        <Button
                                            size="large"
                                            danger
                                            onClick={
                                                handelCancelRemoveNewSchedule
                                            }
                                            loading={loading}
                                        >
                                            Cancel Assignment
                                        </Button>
                                    </Col>
                                ) : (
                                    <Col>
                                        <Button
                                            size="large"
                                            type="primary"
                                            onClick={handleNewShiftAssignment}
                                        >
                                            New Assignment
                                        </Button>
                                    </Col>
                                )}
                                {newReservations.length > 0 ? (
                                    <Col>
                                        <Button
                                            size="large"
                                            onClick={onConfirmAssignment}
                                            loading={loading}
                                            style={{
                                                backgroundColor: 'green',
                                                color: 'white',
                                            }}
                                        >
                                            Confirm New Assignment
                                        </Button>
                                    </Col>
                                ) : null}
                            </>
                        )}
                        {isRemovingSched && (
                            <Col>
                                <Button
                                    size="large"
                                    danger
                                    onClick={handelCancelRemoveSchedule}
                                    loading={loading}
                                >
                                    Cancel Remove Schedule
                                </Button>
                            </Col>
                        )}
                        {isRemovingSched && (
                            <Col>
                                <Button
                                    size="large"
                                    type="primary"
                                    onClick={onConfirmAssignment}
                                    loading={loading}
                                >
                                    Confirm Schedule Removal
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            <div
                style={{
                    display: isRemovingSched || !isAssigning ? 'none' : 'block',
                    border: '1px solid #f3f3f3',
                    padding: 24,
                    marginTop: 12,
                    backgroundColor: '#f6f6f6',
                }}
            >
                <Typography.Title level={3} style={{ marginBottom: 0 }}>
                    Available Drivers & Assistants
                </Typography.Title>
                <Typography.Paragraph>
                    Drag a driver/assistant and drop into selected date to
                    manage vehicle shifts.
                </Typography.Paragraph>
                <Row gutter={[6, 6]} id="external-events">
                    {drivers.map((event) => (
                        <Col
                            className="fc-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2"
                            key={event.id}
                            title={`${event.lastName}, ${event.firstName[0]}`}
                            data-id={event.id}
                            data-custom={event.id}
                            data-color={'#1890ff'}
                            style={{
                                // backgroundColor: event.color,
                                // borderColor: event.color,
                                minWidth: 90,
                                cursor: 'grab',
                            }}
                        >
                            <div
                                // data-color={event.}
                                // data-custom={event.custom}
                                key={event.id}
                                style={{
                                    backgroundColor: '#1890ff',
                                    borderColor: '#1890ff',
                                    color: 'white',
                                    cursor: 'grab',
                                    padding: '4px 6px',
                                }}
                            >
                                <div className="fc-event-main">
                                    <div>
                                        <strong>{`${event.lastName}, ${event.firstName[0]}.`}</strong>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                    {assistants.map((event) => (
                        <Col
                            className="fc-event  mb-1 fc-daygrid-event fc-daygrid-block-event p-2"
                            title={`${event.lastName}, ${event.firstName[0]}.`}
                            data-id={event.id}
                            data-color={'#CAC601'}
                            data-custom={event.id}
                            style={{
                                marginLeft: 0,
                                width: 90,
                                cursor: 'grab',
                            }}
                            key={event.id}
                        >
                            <div
                                style={{
                                    backgroundColor: '#CAC601',
                                    borderColor: '#CAC601',
                                    color: 'black',
                                    padding: '4px 6px',
                                }}
                            >
                                <div className="fc-event-main">
                                    <div>
                                        <strong>{`${event.lastName}, ${event.firstName[0]}.`}</strong>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <div style={{ width: '100%', marginTop: 12 }}>
                <FullCalendar
                    height={500}
                    ref={calendarRef}
                    key={currentVehicleId}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev,next',
                        center: 'title',
                        right: 'today',
                        // right: "dayGridMonth,"
                    }}
                    eventClick={handleDateDoubleClick}
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    // weekends={state.weekendsVisible}
                    fixedWeekCount
                    dayCount={14}
                    datesSet={handleDatesSet}
                    events={currentReservations}
                    droppable={true}
                    displayEventTime={false}
                    eventAllow={(dropLocation: any, draggedEvent: any) => {
                        // eslint-disable-next-line no-console

                        const date = moment(dropLocation.startStr, 'YYYY-MM-DD')
                        // const id = dropLocation.startStr
                        const employeeId =
                            (draggedEvent?.extendedProps?.custom || '').split(
                                '-'
                            )[0] + date.format('-YYYY-MM-DD')
                        let newEvents = [...newReservations]
                        const found = newEvents.find((ev: any,) => {
                            if (ev.custom === employeeId) {
                                return true
                            }
                            return false
                        })
                        if (found) return false

                        const idx = newEvents.findIndex(
                            (evt) => evt.id === draggedEvent.id
                        )
                        if (idx >= 0) {
                            const newObjs = {
                                ...newEvents[idx],
                                custom: employeeId,
                                start: date.hours(5).format(),
                                end: date.clone().add(8, 'hours').format(),
                            }
                            newEvents[idx] = newObjs
                            // if (!isReady) {
                            //     setIsReady(true)
                            // }
                            addEventToCalendar(newEvents)
                            setNewReservations(newEvents)
                            // setState({
                            //     ...state,
                            //     calendarEvents: newEvents,
                            // })
                        }
                        return true // or return false to disallow
                    }}
                    eventReceive={handleEventReceive}
                />
            </div>
        </div>
    )
}

export default ShiftScheduler
